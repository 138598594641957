import React, { useCallback, useState, useEffect } from "react";
import {
  setRequests,
  setTasks,
  setNewAsset,
} from "../../../redux/ducks/dashboard.duck";
import { useDispatch } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import { getAllPendingTasks } from "../../crud/taskManagementCrud";
import { listRequestsPublic } from "../../crud/requestCrud";
import { format_trello_data } from "./utils/data";

const SOCKET = process.env.REACT_APP_SOCKET_URL;

const SocketContainer = () => {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const getRequests = () => {
    setLoader(true);
    listRequestsPublic(1, 20, "cf_estatus=T")
      .then((res) => {
        if (res.status === 200) {
          dispatch(setRequests(res.data));
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setLoader(false));
  };

  const getTasks = () => {
    setLoader(true);
    getAllPendingTasks()
      .then((response) => {
        if (response.results.length) {
          let formatResponse = format_trello_data(response.results);
          dispatch(setTasks(formatResponse));
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setLoader(false));
  };

  const actionsByMessageType = useCallback((e) => {
    const message = JSON.parse(e.data);

    switch (message?.message?.message_type) {
      case "solicitud_agregar":
        return getRequests();
      case "solicitud_status_actualizar":
        return getRequests();
      case "tarea_status_actualizar":
        return getTasks();
      case "tarea_agregar":
        return getTasks();
      case "salida_articulos":
        return message?.message?.articulos.map((article) => {
          return dispatch(setNewAsset({ cf_cve_articulo: article }));
        });
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const socket = new WebSocket(SOCKET);
    socket.onopen = () => console.log("Web Socket Iniciado...");

    socket.onmessage = (e) => {
      actionsByMessageType(e);
    };

    socket.onerror = (error) => console.log(error);

    return () => {
      socket.onclose = () => console.log("Web Socket Cerrado...");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loader && <LinearProgress color="secondary" />;
};

export default SocketContainer;
