import { formatDate } from "../../utils/dates";

let now = new Date();
let firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

export const defaultFilter = {
  fecha_inicio: formatDate("dd/MM/yyyy", firstDayOfMonth),
  fecha_fin: formatDate("dd/MM/yyyy"),
};

export const filterForm = (fetchDetails) => [
  {
    label: "Fecha de inicio",
    name: "fecha_inicio",
    type: "date",
  },
  {
    label: "Fecha de fin",
    name: "fecha_fin",
    type: "date",
  },
  {
    type: "button",
    label: "Filtrar",
    color: "primary",
    onClick: fetchDetails,
  },
];

export const defaultDetails = {
  entradas: 0,
  bienes_dados_alta: 0,
  inventario_cambio_area: 0,
  inventario_con_levantamiento: 0,
  inventario_con_levantamiento_resguardo: 0,
  inventario_pediente_levantamiento: 0,
  levantamientos: 0,
  total_bienes_con_inventario: 0,
  total_bienes_registrados: 0,

  inventario_cambio_area_ids: [],
  inventario_con_levantamiento_resguardo_ids: [],
  levantamientos_ids: [],
  total_bienes_con_inventario_ids: [],
  bienes_dados_alta_ids: [],
  entradas_ids: [],
};
