import * as reports from "../../app/crud/reportsCrud";
import { getOptionsLevamtamiento } from "../crud/catalogsCrud";
const random_colors = ["blue", "pink", "green"];

export const listReports = (dropdownOptions = []) => [
  {
    id: 1,
    name: "Reporte de existencias",
    color: random_colors[Math.floor(Math.random() * random_colors.length) + 1],
    formInputs: [
      {
        name: "fecha_fin",
        label: "Seleccionar fecha de fin del reporte",
        placeholder: "Fecha de Fin",
        type: "date",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          {
            value: "PDF",
            text: "Archivo PDF",
          },
          {
            value: "EXCEL",
            text: "Archivo Excel",
          },
        ],
      },
      {
        name: "tipo_reporte",
        label: "Tipo de reporte",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          { value: 1, text: "Todos los articulos" },
          { value: 2, text: "Almacen y Mantenimiento" },
          { value: 3, text: "Almacen" },
          { value: 4, text: "Mantenimiento" },
          { value: 5, text: "Recurrentes" },
        ],
      },
    ],
    formValues: {
      formato: "PDF",
      fecha_fin: null,
      tipo_reporte: 1,
    },
    requestFunction: reports.existenciasActual,
  },
  {
    id: 2,
    name: "Reporte detalle de consumos",
    color: random_colors[Math.floor(Math.random() * random_colors.length) + 1],
    formInputs: [
      {
        name: "fecha_inicio",
        label: "Seleccionar fecha de inicio del reporte",
        placeholder: "Fecha de Inicio",
        type: "date",
      },
      {
        name: "fecha_fin",
        label: "Seleccionar fecha final del reporte",
        placeholder: "Fecha de Término",
        type: "date",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          {
            value: "PDF",
            text: "Archivo PDF",
          },
          {
            value: "EXCEL",
            text: "Archivo Excel",
          },
        ],
      },
    ],
    formValues: {
      fecha_inicio: "",
      fecha_fin: "",
      formato: "PDF",
    },
    requestFunction: reports.detalleConsumos,
  },
  {
    id: 3,
    name: "Reporte consumo global por partida presupuestal",
    color: random_colors[Math.floor(Math.random() * random_colors.length) + 1],
    formInputs: [
      {
        name: "fecha_inicio",
        label: "Seleccionar fecha de inicio del reporte",
        placeholder: "Fecha de Inicio",
        type: "date",
        // width: "100%",
      },
      {
        name: "fecha_fin",
        label: "Seleccionar fecha final del reporte",
        placeholder: "Fecha de Término",
        type: "date",
        // width: "100%",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          {
            value: "PDF",
            text: "Archivo PDF",
          },
          {
            value: "EXCEL",
            text: "Archivo Excel",
          },
        ],
      },
    ],
    formValues: {
      fecha_inicio: "",
      fecha_fin: "",
      mes: "",
      anio: "",
      formato: "PDF",
    },
    requestFunction: reports.consumosGlobalPartida,
  },
  {
    id: 4,
    name: "Reporte consumo global por centro de costos",
    color: random_colors[Math.floor(Math.random() * random_colors.length) + 1],
    formInputs: [
      {
        name: "fecha_inicio",
        label: "Seleccionar fecha de inicio del reporte",
        placeholder: "Fecha de Inicio",
        type: "date",
        // width: "100%",
      },
      {
        name: "fecha_fin",
        label: "Seleccionar fecha final del reporte",
        placeholder: "Fecha de Término",
        type: "date",
        // width: "100%",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          {
            value: "PDF",
            text: "Archivo PDF",
          },
          {
            value: "EXCEL",
            text: "Archivo Excel",
          },
        ],
      },
    ],
    formValues: {
      fecha_inicio: "",
      fecha_fin: "",
      formato: "PDF",
    },
    requestFunction: reports.consumosGlobalCostos,
  },
  {
    id: 5,
    name: "Reporte entradas global por partida presupuestal",
    color: random_colors[Math.floor(Math.random() * random_colors.length) + 1],
    formInputs: [
      {
        name: "fecha_inicio",
        label: "Seleccionar fecha de inicio del reporte",
        placeholder: "Fecha de Inicio",
        type: "date",
        // width: "100%",
      },
      {
        name: "fecha_fin",
        label: "Seleccionar fecha final del reporte",
        placeholder: "Fecha de Término",
        type: "date",
        // width: "100%",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          {
            value: "PDF",
            text: "Archivo PDF",
          },
          {
            value: "EXCEL",
            text: "Archivo Excel",
          },
        ],
      },
    ],
    formValues: {
      fecha_inicio: "",
      fecha_fin: "",
      formato: "PDF",
    },
    requestFunction: reports.entradasGlobalPartida,
  },
  {
    id: 6,
    name: "Reporte entradas global",
    color: random_colors[Math.floor(Math.random() * random_colors.length) + 1],
    formInputs: [
      {
        name: "fecha_inicio",
        label: "Seleccionar fecha de inicio del reporte",
        placeholder: "Fecha de Inicio",
        type: "date",
        // width: "100%",
      },
      {
        name: "fecha_fin",
        label: "Seleccionar fecha final del reporte",
        placeholder: "Fecha de Término",
        type: "date",
        // width: "100%",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          {
            value: "PDF",
            text: "Archivo PDF",
          },
          {
            value: "EXCEL",
            text: "Archivo Excel",
          },
        ],
      },
    ],
    formValues: {
      fecha_inicio: "",
      fecha_fin: "",
      formato: "PDF",
    },
    requestFunction: reports.entradasGlobal,
  },
  {
    id: 7,
    name: "Reporte detalle de entradas",
    color: random_colors[Math.floor(Math.random() * random_colors.length) + 1],
    formInputs: [
      {
        name: "fecha_inicio",
        label: "Seleccionar fecha de inicio del reporte",
        placeholder: "Fecha de Inicio",
        type: "date",
        // width: "100%",
      },
      {
        name: "fecha_fin",
        label: "Seleccionar fecha final del reporte",
        placeholder: "Fecha de Término",
        type: "date",
        // width: "100%",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          {
            value: "PDF",
            text: "Archivo PDF",
          },
          {
            value: "EXCEL",
            text: "Archivo Excel",
          },
        ],
      },
    ],
    formValues: {
      fecha_inicio: "",
      fecha_fin: "",
      formato: "PDF",
    },
    requestFunction: reports.detalleEntradas,
  },
  {
    id: 8,
    name: "Kardex por Articulo",
    color: random_colors[Math.floor(Math.random() * random_colors.length) + 1],
    formInputs: [
      {
        name: "cve_articulo",
        label: "Clave de articulo",
        placeholder: "Clave de articulo",
        type: "text",
        // width: "100%",
      },
      {
        name: "fecha_inicio",
        label: "Seleccionar fecha de inicio del reporte",
        placeholder: "Fecha de Inicio",
        type: "date",
        // width: "100%",
      },
      {
        name: "fecha_fin",
        label: "Seleccionar fecha final del reporte",
        placeholder: "Fecha de Término",
        type: "date",
        // width: "100%",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          {
            value: "PDF",
            text: "Archivo PDF",
          },
          {
            value: "EXCEL",
            text: "Archivo Excel",
          },
        ],
      },
    ],
    formValues: {
      cve_articulo: "",
      fecha_inicio: "",
      fecha_fin: "",
      formato: "PDF",
    },
    requestFunction: reports.kardex,
  },
  {
    id: 9,
    name: "Salidas UNL",
    color: random_colors[Math.floor(Math.random() * random_colors.length) + 1],
    formInputs: [
      {
        name: "fecha_inicio",
        label: "Seleccionar fecha de inicio del reporte",
        placeholder: "Fecha de Inicio",
        type: "date",
        // width: "100%",
      },
      {
        name: "fecha_fin",
        label: "Seleccionar fecha final del reporte",
        placeholder: "Fecha de Término",
        type: "date",
        // width: "100%",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          {
            value: "UNL",
            text: "Archivo UNL",
          },
        ],
      },
    ],
    formValues: {
      fecha_inicio: "",
      fecha_fin: "",
      formato: "UNL",
    },
    requestFunction: reports.salidasUnl,
  },
  {
    id: 10,
    name: "CATMAT UNL",
    color: random_colors[Math.floor(Math.random() * random_colors.length) + 1],
    formInputs: [
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          {
            value: "UNL",
            text: "Archivo UNL",
          },
        ],
      },
    ],
    formValues: {
      formato: "UNL",
    },
    requestFunction: reports.carmat,
  },
  {
    id: 11,
    name: "Reporte de consumo por áreas",
    color: random_colors[Math.floor(Math.random() * random_colors.length) + 1],
    formInputs: [
      {
        name: "fecha_inicio",
        label: "Seleccionar fecha de inicio del reporte",
        type: "date",
        // width: "100%",
      },
      {
        name: "fecha_fin",
        label: "Seleccionar fecha final del reporte",
        type: "date",
        // width: "100%",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          { text: "PDF", value: "PDF" },
          { text: "EXCEL", value: "EXCEL" },
        ],
      },
      {
        name: "cve_area",
        label: "Clave de área",
        type: "text",
        // width: "100%",
      },
    ],
    formValues: {
      fecha_inicio: "",
      fecha_fin: "",
      formato: "PDF",
    },
    requestFunction: reports.getReportAreas,
  },
  {
    id: 12,
    name: "Reporte de consumo por artículos",
    color: random_colors[Math.floor(Math.random() * random_colors.length) + 1],
    formInputs: [
      {
        name: "fecha_inicio",
        label: "Seleccionar fecha de inicio del reporte",
        type: "date",
        // width: "100%",
      },
      {
        name: "fecha_fin",
        label: "Seleccionar fecha final del reporte",
        type: "date",
        // width: "100%",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          { text: "PDF", value: "PDF" },
          { text: "EXCEL", value: "EXCEL" },
        ],
      },
    ],
    formValues: {
      fecha_inicio: "",
      fecha_fin: "",
      formato: "PDF",
    },
    requestFunction: reports.getReportArticle,
  },
  {
    id: 13,
    name: "Reporte de levantamiento de inventario",
    color: random_colors[Math.floor(Math.random() * random_colors.length) + 1],
    formInputs: [
      {
        name: "numero_levantamiento",
        label: "Selecciona el número de levantamiento",
        placeholder: "Número de levantamiento",
        type: "search",
        searchFunction: getOptionsLevamtamiento,
        width: "100%",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          { text: "PDF", value: "PDF" },
          { text: "EXCEL", value: "EXCEL" },
        ],
      },
    ],
    formValues: {
      numero_levantamiento: "",
      formato: "PDF",
    },
    requestFunction: reports.reporteLevantamientoInventario,
  },
  // {
  //   id: 14,
  //   name: "Bienes y mas debe ir otra info",
  //   formInputs: [
  //     {
  //       name: "fecha_fin",
  //       label: "Seleccionar fecha del inventario",
  //       placeholder: "Fecha de Fin",
  //       type: "date",
  //     },
  //     {
  //       name: "formato",
  //       label: "Formato",
  //       placeholder: "Formato",
  //       type: "dropdown",
  //       options: [{ text: "PDF", value: "PDF" }],
  //     },
  //   ],
  //   formValues: {
  //     formato: "PDF",
  //     fecha_fin: "",
  //     tipo_reporte: 1,
  //   },
  //   requestFunction: reports.inventarioBienes,
  // },
  {
    id: 15,
    name: "Reporte de saldos de partida presupuestal",
    formInputs: [
      {
        name: "fecha_fin",
        label: "Seleccionar fin del reporte",
        placeholder: "Fecha de Fin",
        type: "date",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          { text: "PDF", value: "PDF" },
          { text: "EXCEL", value: "EXCEL" },
        ],
      },
    ],
    formValues: {
      formato: "PDF",
      fecha_fin: "",
      tipo_reporte: 1,
    },
    requestFunction: reports.saldosPartidaPresupuestal,
  },
  {
    id: 16,
    name: "Reporte vales de solicitudes",
    formInputs: [
      {
        name: "fecha_inicio",
        label: "Seleccionar fecha de inicio del reporte",
        type: "date",
        // width: "100%",
      },
      {
        name: "fecha_fin",
        label: "Seleccionar fecha final del reporte",
        type: "date",
        // width: "100%",
      },
      {
        name: "formato",
        label: "Formato",
        placeholder: "Formato",
        type: "dropdown",
        options: [
          { text: "PDF", value: "PDF" },
          { text: "EXCEL", value: "EXCEL" },
        ],
      },
    ],
    formValues: {
      fecha_inicio: "",
      fecha_fin: "",
      formato: "PDF",
    },
    requestFunction: reports.valesSolicitudes,
  },
];
