import React from "react";
import SimpleCheckbox from "../../../components/SimpleCheckbox";
import BatchTable from "../../../components/BatchTable";
import Accordion from "../../../components/Accordion";
import notice from "../../../components/Notice";
import { isAValidAmount } from "../../../utils/inputValidations";

const StockMaterialAccordion = ({
  i,
  item,
  columns,
  handleChange,
  handleSaveQuantity,
  disabled,
}) => {
  const original_to_deliver = item.requested_amount - item.item_total_delivered_amount;

  const handleBatch = (j, column, value, index) => {
    // j indice de item
    // index indice de fila de tabla
    if (isAValidAmount(value)) {
      if (value <= original_to_deliver) {
        let updatedState = item;
        updatedState[column] = Number(value);
        updatedState.subtotal = Number(value) * Number(item["cf_precio_unit"]);
        handleChange(
          { target: { name: "requisition_item_details", value: updatedState } },
          i
        );
      } else
        notice(
          "El número de bienes entregados no puede superar los solicitados por articulo"
        );
    } else notice("Solo se puede agregar valores numericos");
  };

  const handleStatus = (e, i) => {
    const { value } = e.target;
    if (value) handleBatch(null, "cf_cantidad", original_to_deliver, null, item);
    else handleBatch(null, "cf_cantidad", 0, null, item);

    let updatedState = item;
    updatedState["status"] = value;
    handleChange(
      { target: { name: "requisition_item_details", value: updatedState } },
      i
    );
  };

  return (
    <Accordion
      header={
        <div className="d-flex justify-content-between align-items-center">
          <SimpleCheckbox
            disabled={
              disabled ||
              item.item_total_delivered_amount === item.requirement_item_total_amount
            }
            labelPos="end"
            label={
              <>
                <h6>Clave: {item.cf_cve_articulo}</h6>
                <span className="text-muted">{item.cf_articulo?.trim()}</span>
              </>
            }
            value={item.status}
            name="status"
            onChange={(e) => handleStatus(e, i)}
          />
          <div>
            {`Total de entregados ${
              isNaN(Number(item.item_total_delivered_amount))
                ? "-"
                : Number(item.item_total_delivered_amount)
            } de ${item.requirement_item_total_amount ?? "-"}`}
            <br />
            {`Entrega actual ${
              isNaN(Number(item.cf_cantidad)) ? "-" : Number(item.cf_cantidad)
            }`}
          </div>
        </div>
      }
    >
      <>
        <BatchTable
          i={i}
          items={[item]}
          columns={columns}
          disabled={
            disabled ||
            item.item_total_delivered_amount === item.requirement_item_total_amount
          }
          handleChange={(j, column, value, index) => handleBatch(j, column, value, index)}
          handleEdit={() =>
            handleSaveQuantity(item.warehouse_entry_detail, {
              cf_cantidad: item.cf_cantidad,
            })
          }
          editOption={
            !(
              disabled ||
              item.item_total_delivered_amount === item.requirement_item_total_amount
            )
          }
          disableAddItem
        />
        {/* <SimpleInput
          name="observations"
          label="Observaciones"
          width="100%"
          InputProps={{className: "mb-4"}}
          value={item.observations}
          onChange={e => handleChange(e, i)}
        />
        <FileButton
          index={i}
          value={item.foto}
          handleChange={(file, i) => handleChange({target: { name: "foto", value: file }}, i)}
        /> */}
      </>
    </Accordion>
  );
};

export default StockMaterialAccordion;
