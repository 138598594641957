import * as columnFormatters from "../components/column-formatters";
import { inputFormatDate } from "./dates";

export const columns = (onEye, onLock, Permisos) => [
  {
    dataField: "id",
    text: "No. de entrada",
    sort: true,
  },
  {
    dataField: "qty_inventory",
    text: "Cantidad de bienes",
    sort: true,
  },
  // {
  //   dataField: "cf_folio_entrada_almacen",
  //   text: "Folio de entrada en almácen",
  //   sort: true,
  // },
  {
    dataField: "cf_fecha_actualizacion",
    text: "Fecha de captura",
    sort: true,
  },
  {
    dataField: "cf_fecha_remision",
    text: "Fecha de remisión",
    sort: true,
  },
  {
    dataField: "cf_no_remision",
    text: "No. de remisión",
    sort: true,
  },
  {
    dataField: "cf_numero_factura",
    text: "No. de factura",
    sort: true,
  },
  {
    dataField: "cf_fecha_factura",
    text: "Fecha de factura",
    sort: true,
  },
  {
    dataField: "cf_no_almacen",
    text: "Recibido en",
    sort: true,
  },
  {
    dataField: "user",
    text: "Usuario",
    sort: true,
  },
  {
    dataField: "status",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.StatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ActionsColumnFormatter,
    formatExtraData: {
      onEye,
      onLock,
      Permisos,
    },
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "100px",
    },
    csvExport: false,
  },
];

export const request_columns = (
  { sendMail, onDetail, onCancel, onFile, onTrashDraft, onDraft, onPrintBarcode },
  mode = "public",
  pathname
) =>
  mode === "admin"
    ? [
        {
          dataField: "id",
          text: "No. de solicitud",
          sort: false,
          // order: "desc",
        },
        {
          dataField: "area.cf_adscri6",
          text: "Área",
          sort: false,
        },
        {
          dataField: "area.cf_adscri7",
          text: "Responsable",
          sort: false,
        },
        {
          dataField: "cf_fecha_solicitud",
          text: "Fecha de solicitud",
          formatter: (value) => inputFormatDate(value),
          sort: false,
        },
        {
          dataField: "cf_fecha_entrega",
          text: "Fecha de entrega",
          formatter: (value) => inputFormatDate(value),
          sort: false,
        },
        {
          dataField: "cf_fecha_cancelacion",
          text: "Fecha de cancelación",
          formatter: (value) => inputFormatDate(value),
          sort: false,
        },
        {
          dataField: "collect_extension",
          text: "Extensión",
          sort: false,
        },
        {
          dataField: "collect_email",
          text: "Email",
          sort: false,
        },
        {
          dataField: "cf_estatus",
          text: "Estado",
          sort: false,
          formatter: columnFormatters.RequestStatusColumnFormatter,
        },
        {
          dataField: "action",
          text: "Acciones",
          formatter: columnFormatters.RequestActionsColumnFormatter,
          formatExtraData: {
            onFile,
            sendMail,
            onDetail,
            onCancel,
            onTrashDraft,
            onDraft,
            onPrintBarcode,
            pathname,
          },
          csvExport: false,
        },
      ]
    : [
        {
          dataField: "id",
          text: "No. de solicitud",
          sort: false,
        },
        {
          dataField: "warehouse_request_details",
          text: "Materiales solicitados",
          sort: false,
          formatter: (cell) => cell?.map((x) => x.asset_category?.desc_corta).join(", "),
        },
        {
          dataField: "cf_fecha_solicitud",
          text: "Fecha",
          sort: false,
        },
        {
          dataField: "cf_estatus",
          text: "Estado",
          sort: false,
          formatter: columnFormatters.RequestStatusColumnFormatter,
        },
        {
          dataField: "action",
          text: "Acciones",
          formatter: columnFormatters.RequestActionsColumnFormatter,
          formatExtraData: {
            onFile,
            sendMail,
            onDetail,
            onCancel,
            onTrashDraft,
            onDraft,
            onPrintBarcode,
            pathname,
          },
          csvExport: false,
        },
      ];

export const consumptionItems_columns = (
  { sendMail, onDetail, onCancel, onFile, onTrashDraft, onDraft, onPrintBarcode },
  mode = "public",
  DetailType = 0
) => [
  // {
  //   dataField: "id",
  //   text: "Clave de articulo",
  //   sort: false,
  // },

  {
    dataField: "adscripcion",
    text: "Adscripción",
    sort: false,
  },
  {
    dataField: "area",
    text: "Area",
    sort: false,
  },
  {
    dataField: "deatil1",
    text: "Titular",
    sort: false,
    formatter: columnFormatters.ConsumptionItemColumnFormatter_Titular,
  },

  {
    dataField: "deatil1",
    text: "No. de solicitud",
    sort: false,
    formatter:
      DetailType === 1 || DetailType === 2
        ? columnFormatters.ConsumptionItemColumnFormatter_Id
        : columnFormatters.ConsumptionItemColumnFormatter_Id,
  },
  {
    dataField: "cf_fecha_solicitud",
    text: "Fecha de solicitud",
    sort: false,
    formatter:
      DetailType === 1 || DetailType === 2
        ? columnFormatters.ConsumptionItemColumnFormatter_FechaSolicitud
        : (value) => inputFormatDate(value),
  },
  {
    dataField: "cf_fecha_entrega",
    text: "Fecha de entrega",
    sort: false,
    formatter:
      DetailType === 1 || DetailType === 2
        ? columnFormatters.ConsumptionItemColumnFormatter_FechaEntrega
        : (value) => inputFormatDate(value),
  },
  {
    dataField: "cf_fecha_cancelacion",
    text: "Fecha de cancelación",
    sort: false,
    formatter:
      DetailType === 1 || DetailType === 2
        ? columnFormatters.ConsumptionItemColumnFormatter_FechaCancelacion
        : (value) => inputFormatDate(value),
  },
  //  {
  //    dataField: "warehouse_request_details",
  //    text: "Cantidad entregada",
  //    sort: false,
  //    formatter: (cell) => cell[0]?.cf_cantidad_entregada || "0",
  //  },
  {
    dataField: "warehouse_request_details",
    text: "Solicitado",
    sort: false,
    formatter:
      DetailType === 1 || DetailType === 2
        ? columnFormatters.ConsumptionItemColumnFormatter_CantidadSolicitada
        : (cell) => cell[0]?.cf_cantidad_solicitada || "0",
  },
  {
    dataField: "warehouse_request_details",
    text: "Comprometido",
    sort: false,
    formatter:
      DetailType === 1 || DetailType === 2
        ? columnFormatters.ConsumptionItemColumnFormatter_CantidadComprometido
        : (cell) => cell[0]?.asset_category?.existencias?.cf_exi_compromx || "0",
  },
  {
    dataField: "warehouse_request_details",
    text: "Entregado",
    sort: false,
    formatter:
      DetailType === 1 || DetailType === 2
        ? columnFormatters.ConsumptionItemColumnFormatter_CantidadEntregada
        : (cell) => cell[0]?.cf_cantidad_entregada || "0",
  },
  {
    dataField: "cf_estatus",
    text: "Estado",
    sort: false,
    formatter:
      DetailType === 1 || DetailType === 2
        ? columnFormatters.ConsumptionItemColumnFormatter_Estatus
        : columnFormatters.ConsumptionItemColumnFormatter,
  },
  {
    dataField: "SumTotal",
    text: "Total",
    sort: false,
  },

  // {
  //   dataField: "action",
  //   text: "Acciones",
  //   formatter: columnFormatters.RequestActionsColumnFormatter,
  //   formatExtraData: {
  //     onFile,
  //     sendMail,
  //     onDetail,
  //     onCancel,
  // 		onTrashDraft,
  //     onDraft,
  //     onPrintBarcode,
  //   },
  //   csvExport: false,
  // }
];

export const request_drafts_columns = (onDetail, onTrashDraft) => [
  {
    dataField: "warehouse_request_details",
    text: "Materiales solicitados",
    sort: false,
    formatter: (cell) => cell?.map((x) => x.desc_corta).join(", "),
  },
  {
    dataField: "cf_estatus",
    text: "Estado",
    sort: false,
    formatter: columnFormatters.RequestStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.DraftsActionsColumnFormatter,
    formatExtraData: {
      onDetail,
      onTrashDraft,
    },
    csvExport: false,
  },
];
