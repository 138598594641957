import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import FullTable from "../../components/FullTable";
import BaseModal from "../../components/BaseModal";
import notice from "../../components/Notice";
import {
  SEND_EMAIL,
  updateRequest,
  DELETE_REQUEST,
  GET_REQUESTS,
  GET_REQUEST_PDF,
  setSelectedRequest,
  setSelectedItems,
  setRequestsIds,
  setClearSelectedRequest,
  resetFieldsFilter,
  CANCEL_REQUEST,
} from "../../../redux/ducks/request.duck";
import { request_columns } from "../../utils/tableData";
import { listRequests, singleRequest } from "../../crud/requestCrud";
import { useSearchMode } from "../../hooks/useSearchMode";
import {
  defaultFilter,
  getColourizedLabelStatus,
  requestFilters,
} from "../../utils/requestUtils";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";

const RequestPage = () => {
  const moduleAdminRoute = "inventario-bienes-almacen-general";
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { authToken, user } = useSelector((store) => store.auth);
  const inventoryModule = user.app_modules.find(
    (module) => module.name === moduleAdminRoute
  );
  const requestModule =
    inventoryModule && inventoryModule.submodules.length > 0
      ? inventoryModule.submodules.find((module) => module.name === "solicitudes")
      : undefined;
  const mode = !pathname.includes(moduleAdminRoute)
    ? "public"
    : requestModule
    ? "admin"
    : "public";
  const [deleteModal, setDeleteModal] = useState(false);
  const [cancelItem, setCancelItem] = useState("");
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(50);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState(defaultFilter(mode));

  const getFilterParams = useCallback(() => {
    let params = {
      ...filter,
      cf_fecha_entrega_cancelado:
        filter.cf_fecha_entrega_cancelado_inicio && filter.cf_fecha_entrega_cancelado_fin
          ? `${filter.cf_fecha_entrega_cancelado_inicio}:${filter.cf_fecha_entrega_cancelado_fin}`
          : "",
    };
    delete params.cf_fecha_entrega_cancelado_fin;
    delete params.cf_fecha_entrega_cancelado_inicio;
    console.log(params);
    return filterParams(clearEmptyStringsObject(cleanObject(params)));
  }, [filter]);

  useEffect(() => {
    listRequests(page, per_page, getFilterParams())
      .then((res) => {
        if (res.status === 200) {
          setResults(res.data.results);
          setPage(page);
          setPer_page(res.data.per_page);
          setCount(res.data.count);
        }
      })
      .catch(console.log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, per_page]);

  const handleFilterChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const onConfirmFilter = () => {
    listRequests(page, per_page, getFilterParams())
      .then((res) => {
        if (res.status === 200) {
          setResults(res.data.results);
          setPage(page);
          setPer_page(res.data.per_page);
          setCount(res.data.count);
        }
      })
      .catch(console.log);
  };

  const onClearFilter = () => {
    setFilter(defaultFilter(mode));

    listRequests(page, per_page, mode === "admin" ? "cf_estatus=D,G" : "")
      .then((res) => {
        if (res.status === 200) {
          setResults(res.data.results);
          setPage(page);
          setPer_page(res.data.per_page);
          setCount(res.data.count);
        }
      })
      .catch(console.log);
  };

  useSearchMode(
    mode === "admin"
      ? "/inventario-bienes-almacen-general/solicitudes/busqueda"
      : "/solicitudes/lista"
  );

  const handleAdd = () => {
    dispatch(setClearSelectedRequest());
    dispatch(resetFieldsFilter());
    //history.push(mode === 'admin' ? "/inventario-bienes-almacen-general/solicitudes/nueva-solicitud" : "/solicitudes/nueva-solicitud")
    history.push("/solicitudes/nueva-solicitud");
  };

  const sendMail = (id) => {
    dispatch(SEND_EMAIL(id))
      .then(unwrapResult)
      .then((res) => {
        dispatch(updateRequest({ id, cf_estatus: "E" }));
        notice(res?.message || "Se ha enviado el email", "success");
      })
      .catch((error) => {
        notice("No se pudo enviar el email, contacta al administrador");
      });
  };

  const onDetail = (id) => {
    singleRequest(id)
      .then((res) => {
        const newSelectedRequest = {
          ...res,
          label_status: getColourizedLabelStatus(res.cf_estatus),
        };
        dispatch(setRequestsIds([]));
        dispatch(resetFieldsFilter());
        dispatch(setSelectedRequest(newSelectedRequest));
        dispatch(
          setSelectedItems(
            newSelectedRequest.items.map((item) => ({
              ...item,
              disabled: true,
            }))
          )
        );
        history.push(
          mode === "admin"
            ? `/inventario-bienes-almacen-general/solicitudes/resultados/${id}`
            : `/solicitudes/resultados/${id}`
        );
      })
      .catch(console.log);
  };

  const onDraft = (id) => {
    history.push(`/solicitudes/resultados/${id}`);
  };

  const onCancel = (id) => {
    setCancelItem(id);
  };

  const onFile = (id) => {
    dispatch(GET_REQUEST_PDF(id))
      .then(unwrapResult)
      .then((result) => {
        if (result?.attachment_url) window.open(result.attachment_url);
      })
      .catch((res) => {
        notice("No se pudo cargar el PDF, contacta al administrador");
      });
    // history.push('/solicitudes/salidas');
  };

  const onTrashDraft = (id) => {
    setSelectedRequestId(id);
    setDeleteModal(true);
  };

  const handleCancelItem = () => {
    dispatch(CANCEL_REQUEST(cancelItem))
      .then((res) => {
        setCancelItem("");
        notice(res.payload.message, "success");
        dispatch(GET_REQUESTS({ page, per_page }));
      })
      .catch((err) => {
        console.log("err", err);
        notice("Ha ocurrido un error, favor de intentar mas tarde");
      });
  };

  const handleDeleteItem = () => {
    dispatch(DELETE_REQUEST(selectedRequestId))
      .then((res) => {
        if (res.payload === "") {
          setDeleteModal(false);
          notice("Borrador borrado exitosamente", "success");
          dispatch(GET_REQUESTS({ page, per_page }));
        } else {
          notice("Ha ocurrido un error borrando el borrador", "error");
        }
      })
      .catch((err) => console.log("err", err));
  };

  const handleSelectAllRows = (isSelect, rows) =>
    isSelect ? setSelectedRequests(rows.map((r) => r.id)) : setSelectedRequests([]);

  const handleSelectRow = (row, isSelect) => {
    if (row && row.id) {
      if (!isSelect) {
        const newSelectedEntries = selectedRequests.filter((id) => id !== row.id);
        setSelectedRequests(newSelectedEntries);
      } else {
        setSelectedRequests([...selectedRequests, row.id]);
      }
    }
  };

  const handleReqSelected = () => {
    dispatch(setRequestsIds(selectedRequests));
    singleRequest(selectedRequests[0])
      .then((res) => {
        dispatch(resetFieldsFilter());
        dispatch(setSelectedRequest(res));
        dispatch(
          setSelectedItems(res.items.map((item) => ({ ...item, disabled: true })))
        );
        history.push(
          mode === "admin"
            ? `/inventario-bienes-almacen-general/solicitudes/resultados/${res.id}`
            : `/solicitudes/resultados/${res.id}`
        );
      })
      .catch(console.log);
  };

  const onPrintBarcode = (id) => {
    const a = document.createElement("a");
    a.href = `nextwmsftp://?token=${authToken}?ids=${id}?type=request`;
    a.click();
  };

  return (
    <>
      <FullTable
        title="Solicitudes"
        has_button={mode === "public"}
        buttonTitle="Nueva solicitud"
        onClickButton={handleAdd}
        entities={results}
        columns={request_columns(
          {
            sendMail,
            onDetail,
            onCancel,
            onFile,
            onTrashDraft,
            onDraft,
            onPrintBarcode,
          },
          mode,
          pathname
        )}
        page={page}
        setPage={(pageNumber) => pageNumber > 0 && setPage(pageNumber)}
        sizePerPage={per_page}
        setSizePerPage={(pageSize) => setPer_page(pageSize)}
        count={count}
        search_button={mode === "admin"}
        onClickSearch={() => {
          dispatch(resetFieldsFilter());
          history.push(
            mode === "admin"
              ? "/inventario-bienes-almacen-general/solicitudes/busqueda"
              : "/solicitudes/busqueda"
          );
        }}
        // SELECTED REQUESTS ACTIONS
        has_button2={mode === "admin"}
        buttonDisabled2={selectedRequests.length < 1}
        buttonTitle2={`Consultar Todos${
          selectedRequests.length > 1 ? `(${selectedRequests.length.toString()})` : ""
        }`}
        onSelectRow={handleSelectRow}
        onSelectAllRows={handleSelectAllRows}
        onClickButton2={handleReqSelected}
        nonSelectableRows={mode === "public" ? results.map(({ id }) => id) : []}
        hasReportIcons={mode === "admin"}
        // FILTERS
        has_filter
        formFilters={filter}
        onFilterChange={handleFilterChange}
        filterInputs={requestFilters(mode)}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
      />
      <BaseModal
        open={deleteModal}
        title="¿Borrar Borrador?"
        onClose={() => setDeleteModal(false)}
        onCancel={() => setDeleteModal(false)}
        onConfirm={handleDeleteItem}
        confirmLabel="Si, eliminar la solicitud"
        cancelLabel="No"
        maxWidth="sm"
        color="secondary"
      >
        <p>¿Estás seguro de que deseas borrar el Borrador</p>
      </BaseModal>
      <BaseModal
        open={Boolean(cancelItem)}
        title="¿Cancelar solicitud?"
        onClose={() => setCancelItem("")}
        onCancel={() => setCancelItem("")}
        onConfirm={handleCancelItem}
        confirmLabel="Si, cancelar la solicitud"
        cancelLabel="No"
        maxWidth="sm"
        color="secondary"
      >
        <p>¿Estás seguro de que deseas cancelar la solicitud</p>
      </BaseModal>
    </>
  );
};

export default RequestPage;
