import { ListItem, ListItemIcon, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import React, { useState, useMemo } from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MoveToFolderCollapse from './MoveToFolderCollapse';

const initialState = {
  mouseX: null,
  mouseY: null,
};

const DigitalVaultMenuContext = ({ children, folders, onClickFolderMenuContext }) => {
  const [state, setState] = useState(initialState);
  const [currentMoveOptionFolders, setCurrentMoveOptionFolders] = useState([folders]);
  const [collapse1Open, setCollapse1Open] = useState(false);
  const handleClick = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX + 20,
      mouseY: event.clientY - 4,
    });
    setCollapse1Open(false);
  };

  const handleClose = () => {
    setState(initialState);
  };

  const handleOpenMoveMenu = () => {
    setCollapse1Open(true);
    setState({ ...state, mouseY: 250 });
  };

  const handleOpenFolder = (subfolders) => {
    // Add subfolders to the array of folder's history
    setCurrentMoveOptionFolders(prevFolders => ([...prevFolders, subfolders]));
  };

  const handleBackFolder = () => {
    // Removes last element from folder's history
    setCurrentMoveOptionFolders(prevFolders => (prevFolders.slice(0, prevFolders.length - 1)))
  }

  const isMainFolder = useMemo(() => currentMoveOptionFolders.length === 1, [currentMoveOptionFolders]);

  const collapseFolders = useMemo(() => currentMoveOptionFolders.slice(-1)[0], [currentMoveOptionFolders]);
  const contextMenuOpen = state.mouseY !== null;

  return (
    <div onContextMenu={handleClick} >
      {children}
      <Menu
        keepMounted
        open={contextMenuOpen}
        onClose={handleClose}
        anchorReference={state.mouseY !== null && state.mouseX !== null ? "anchorPosition" : "none"}
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : { top: null, left: null }
        }
        className="d-flex flex-row"
      >
        <span className="d-flex">
          <MenuList >
            <ListItem button onMouseEnter={handleOpenMoveMenu}>
              <MenuItem className="d-flex justify-content-between">
                <Typography variant="inherit">Mover a</Typography>
                <ListItemIcon>
                  <ArrowRightIcon fontSize="small" className="ml-auto mt-auto mb-auto mr-0" />
                </ListItemIcon>
              </MenuItem>
            </ListItem>
          </MenuList>
          <MoveToFolderCollapse
            in={collapse1Open}
            folders={collapseFolders}
            onClickFolder={folder => onClickFolderMenuContext(folder, handleClose)}
            onClickIcon={handleOpenFolder}
            withArrow
            isMainFolder={isMainFolder}
            onClickBack={handleBackFolder}
          />
        </span>
      </Menu>
    </div>

  )
};

export default DigitalVaultMenuContext;