import React, { useState } from "react";
import BaseModal from "../../../components/BaseModal";
import notice from "../../../components/Notice";
import {
  deleteDigitalVaultFile,
  fetchDigitalVaultFolders,
} from "../../../crud/digitalVaultCrud";
import { deleteFileFromList } from "../../../../redux/ducks/digitalVault.duck";
import { useDispatch } from "react-redux";

const DigitalVaultDeleteFileModal = ({ open, onClose, file }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleOnConfirm = () => {
    const splitId = file.split("_");
    setLoading(true);
    deleteDigitalVaultFile(splitId[1], splitId[0])
      .then((res) => {
        notice("Archivo eliminado con éxito", "success");
        dispatch(deleteFileFromList(file));
      })
      .catch((e) => notice("Imposible eliminar", "info"))
      .finally(() => {
        setLoading(false);
        onClose();
        fetchDigitalVaultFolders();
      });
  };

  return (
    <BaseModal
      title="Estás seguro de remover este archivo?"
      children={`Eliminara permanentemente el archivo seleccionado`}
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleOnConfirm}
      confirmLabel="Aceptar"
      cancelLabel="Cancelar"
      maxWidth="sm"
      color="secondary"
      loading={loading}
      disableOnConfirm={loading}
    />
  );
};

export default DigitalVaultDeleteFileModal;
