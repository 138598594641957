import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'

const FolderLockColumnIcon = ({ onClick, tooltip="Ver", color="primary", disabled }) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip id='products-edit-tooltip'>{tooltip}</Tooltip>}
    >
      <button
        className={`btn btn-icon btn-${color} btn-sm btn-hover-${color}`}
        onClick={onClick}
        disabled={disabled}
      >
        <span className={`svg-icon svg-icon-md svg-icon-hover-${color}`}>
					<SVG src={toAbsoluteUrl('/media/svg/icons/Files/Locked-folder.svg')} />
				</span>
      </button>
    </OverlayTrigger>
  )
}

export default FolderLockColumnIcon
