import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, IconButton } from "@material-ui/core";
import {
  Clear,
  DoneAll,
  EventNote,
  Group,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import CustomButton from "../../../components/CustomButton";
import SimpleInput from "../../../components/SimpleInput";
import ToDoUsersOnBoard from "./ToDoUsersOnBoard";
import notice from "../../../components/Notice";
import {
  setAllTasks,
  setSearchTasks,
  setTasksForTrello,
} from "../../../../redux/ducks/taskManagement.duck";
import {
  clearFinishedTasks,
  getAllTasks,
} from "../../../crud/taskManagementCrud";
import { setLoader } from "../../../../redux/ducks/loader.duck";

const ToDoSubHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user } = useSelector((store) => store.auth);
  const { isWarehousePage, isInstrumentalGoodsPage } = useSelector(
    (store) => store.pathname
  );
  const {
    selectedBoard,
    tasks: { resultsByBoard, resultsForTrello, searchTasks },
  } = useSelector((store) => store.taskManagement);
  const [usersModal, setUsersModal] = useState(false);
  const [searchIcon, setSearchIcon] = useState(false);
  const isToDoBoardPage = pathname.includes("/tableros");
  const isBoardMainPage =
    (pathname.includes("/administrador-tareas") &&
      !pathname.includes("/administrador-tareas/t") &&
      !pathname.includes("/administrador-tareas/calendario")) ||
    (pathname.includes("/administracion-tareas") &&
      !pathname.includes("/administracion-tareas/"));

  // const onChange = ({target}) => setFilter({...filter, [target.name]: target.value})

  const changeBoardView = (isBoard) => {
    let route;
    if (isWarehousePage)
      route =
        "/inventario-bienes-almacen-general/administracion-tareas/tableros";
    else if (isInstrumentalGoodsPage)
      route = "/bienes-instrumentales/administracion-tareas/tableros";
    else route = "/administrador-tareas/tableros";
    isBoard
      ? history.push(`${route}/columnas/${selectedBoard.id}`)
      : history.push(`${route}/calendario/${selectedBoard.id}`);
  };

  const handleFinishedTasks = () => {
    const ids = resultsByBoard
      .filter((task) => task.column_state === 4)
      .map((task) => task.id);
    dispatch(setLoader(true));
    clearFinishedTasks({ ids })
      .then((res) => {
        const updatedTasks = resultsForTrello;
        updatedTasks[3].cards = [];
        dispatch(setTasksForTrello(updatedTasks));
        notice("Tareas limpiadas", "success");
      })
      .catch(() => notice("Error al limpiar las tareas finalizadas"))
      .finally(() => dispatch(setLoader(false)));
  };

  const handleSearch = () => {
    if (searchIcon) {
      dispatch(setLoader(true));
      getAllTasks(searchTasks?.toUpperCase())
        .then(({ results }) => {
          setSearchIcon(false);
          dispatch(setAllTasks(results));
        })
        .catch((e) => notice("Error buscando los archivos"))
        .finally(() => dispatch(setLoader(false)));
    } else {
      dispatch(setAllTasks([]));
      dispatch(setSearchTasks(""));
    }
  };

  const goToTasksManagementPage = () => {
    let route;
    if (isWarehousePage)
      route = "/inventario-bienes-almacen-general/administracion-tareas";
    else if (isInstrumentalGoodsPage)
      route = "/bienes-instrumentales/administracion-tareas";
    else route = "/administrador-tareas";
    history.push(route);
  };

  return (
    <>
      <div
        className={`w-100 d-flex align-items-center justify-content-${
          isBoardMainPage ? "end" : "between"
        }`}
      >
        {isToDoBoardPage && (
          <div className="d-flex">
            {selectedBoard.users.map((user) => (
              <Avatar key={user.id} className="bg-info">
                {user.first_name
                  ? `${user.first_name[0]}${user.last_name[0]}`
                  : user.username[0]}
              </Avatar>
            ))}
            <Avatar
              style={{ cursor: "pointer" }}
              onClick={() => setUsersModal(true)}
            >
              <Group />
            </Avatar>
          </div>
        )}
        {!isBoardMainPage && <h6>{selectedBoard?.name}</h6>}
        <div className="d-flex align-items-center">
          {!isBoardMainPage && (
            <CustomButton
              title="Ver tableros"
              onClick={() =>
                pathname.includes("bienes")
                  ? history.push("/bienes-instrumentales/administracion-tareas")
                  : pathname.includes("/inventario")
                  ? history.push(
                      "/inventario-bienes-almacen-general/administracion-tareas"
                    )
                  : history.push("/administrador-tareas/")
              }
              color="info"
            />
          )}
          {isToDoBoardPage && (
            <CustomButton
              title={<EventNote />}
              outline={pathname.includes("columnas")}
              color="primary"
              className="btn-icon btn-sm"
              onClick={() => changeBoardView(false)}
            />
          )}
          {isToDoBoardPage && (
            <CustomButton
              title={<ViewColumn />}
              outline={!pathname.includes("columnas")}
              color="dark"
              className="btn-icon btn-sm"
              onClick={() => changeBoardView(true)}
            />
          )}
          {isBoardMainPage && (
            <>
              <form
                className="d-flex align-items-center"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    handleSearch();
                  }
                }}
              >
                <SimpleInput
                  name="search"
                  value={searchTasks}
                  onChange={({ target }) => {
                    dispatch(setSearchTasks(target.value));
                    setSearchIcon(true);
                  }}
                  placeholder="Búsqueda de tareas"
                  width={350}
                />
                <IconButton onClick={handleSearch}>
                  {searchIcon ? <Search /> : <Clear />}
                </IconButton>
              </form>
              {pathname.includes("calendario") && (
                <CustomButton
                  onClick={() => {
                    goToTasksManagementPage();
                  }}
                  title="Regresar al tablero"
                  color="light"
                />
              )}
            </>
          )}
          {isToDoBoardPage && (
            <CustomButton
              title={<DoneAll />}
              color="success"
              className="btn-icon btn-sm"
              disabled={
                !resultsByBoard.some((task) => task.column_state === 4) ||
                !(
                  user.id === selectedBoard?.user_in_charge ||
                  user.id === selectedBoard?.user_in_charge2
                )
              }
              onClick={handleFinishedTasks}
              tooltip="Limpiar tareas terminadas"
            />
          )}
        </div>
      </div>
      <ToDoUsersOnBoard open={usersModal} setOpen={setUsersModal} />
    </>
  );
};

export default ToDoSubHeader;
