import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  clearContract,
  setContractIds,
} from "../../../redux/ducks/contracts.duck";
import FullTable from "../../components/FullTable";
import notice from "../../components/Notice";
import { getContractList } from "../../crud/contractsCrud";
import {
  columns,
  contractsListFixed,
  defaultContractFilter,
  filterInputs,
} from "../../utils/contractsData";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";

const ContractsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [contracts, setContracts] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [filter, setFilter] = useState(defaultContractFilter);

  const handleFilterChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const getFilterParams = useCallback(
    () =>
      filterParams(
        clearEmptyStringsObject(
          cleanObject({ ...filter, supplier_number: filter.supplier?.text2 })
        )
      ),
    [filter]
  );

  // const lastAndFirstDayOfTheMonth = () => {
  //   const currentYear = new Date().getFullYear();
  //   return {
  //     firstDay: setFormatDate(new Date(currentYear, 0, 1), "dd/MM/yyyy"),
  //     lastDay: setFormatDate(new Date(currentYear, 11, 31), "dd/MM/yyyy"),
  //   };
  // };

  const onConfirmFilter = () => {
    setLoading(true);
    getContractList(sizePerPage, page, getFilterParams())
      .then((res) => {
        setCount(res.data.count);
        const newContracts = contractsListFixed(res.data.results);
        setContracts(newContracts);
      })
      .catch((err) => {
        console.log("err", err);
        notice("Ha ocurrido un error de conexión con el servidor", "error");
      })
      .finally(() => setLoading(false));
  };

  const onClearFilter = () => {
    setFilter(defaultContractFilter);
    setLoading(true);
    getContractList(sizePerPage, page)
      .then((res) => {
        setCount(res.data.count);
        const newContracts = contractsListFixed(res.data.results);
        setContracts(newContracts);
      })
      .catch((err) => {
        console.log("err", err);
        notice("Ha ocurrido un error de conexión con el servidor", "error");
      })
      .finally(() => setLoading(false));
  };

  const handleonClickSearch = () => {
    dispatch(clearContract());
    history.push("/inventario-bienes-almacen-general/contratos/busqueda");
  };

  const handleAdd = () => {
    dispatch(clearContract());
    history.push("/inventario-bienes-almacen-general/contratos/nuevo");
  };

  const onEye = (id) => {
    dispatch(clearContract());
    setTimeout(
      () => history.push(`/inventario-bienes-almacen-general/contratos/${id}`),
      1000
    );
  };

  const handleSelectAllRows = (isSelect, rows) =>
    isSelect
      ? setSelectedContracts(rows.map((r) => r.id))
      : setSelectedContracts([]);

  const handleSelectRow = (row, isSelect) => {
    if (row && row.id) {
      if (!isSelect) {
        const newSelected = selectedContracts.filter((id) => id !== row.id);
        setSelectedContracts(newSelected);
      } else {
        setSelectedContracts([...selectedContracts, row.id]);
      }
    }
  };

  const handleReqSelected = () => {
    dispatch(setContractIds(selectedContracts));
    history.push(
      `/inventario-bienes-almacen-general/contratos/${selectedContracts[0]}`
    );
  };

  useEffect(() => {
    setLoading(true);
    getContractList(
      sizePerPage,
      page,
      filterParams({
        // date_start: currentDate.firstDay,
        // date_end: currentDate.lastDay,
        current_year: true,
      })
    )
      .then((res) => {
        setCount(res.data.count);
        const newContracts = contractsListFixed(res.data.results);
        setContracts(newContracts);
      })
      .catch((err) => {
        console.log("err", err);
        notice("Ha ocurrido un error de conexión con el servidor", "error");
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sizePerPage]);

  return (
    <>
      <FullTable
        title="Contratos"
        buttonTitle="Nuevo contrato"
        onClickButton={handleAdd}
        onClickSearch={handleonClickSearch}
        columns={columns(onEye)}
        entities={contracts}
        page={page}
        setPage={(page) => page > 0 && setPage(page)}
        sizePerPage={sizePerPage}
        setSizePerPage={setSizePerPage}
        loading={loading}
        count={count}
        // SELECTED VEHICULES ACTIONS
        has_button2
        buttonDisabled2={selectedContracts.length < 1}
        buttonTitle2={`Consultar Todos${
          selectedContracts.length > 1
            ? `(${selectedContracts.length.toString()})`
            : ""
        }`}
        onSelectRow={handleSelectRow}
        onSelectAllRows={handleSelectAllRows}
        onClickButton2={handleReqSelected}
        // FILTERS
        has_filter
        formFilters={filter}
        onFilterChange={handleFilterChange}
        filterInputs={filterInputs}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
      />
    </>
  );
};

export default ContractsPage;
