import PropTypes from "prop-types";
import React from "react";
import { Chip, makeStyles, Tooltip } from "@material-ui/core";
import { Add, AccessTime, PinDrop } from "@material-ui/icons";
import Board from "react-trello";
import SimpleCard from "./SimpleCard";
import CustomButton from "./CustomButton";
import { dateToText } from "../utils/dates";

const useStyles = makeStyles((theme) => ({
  chipDate: {
    backgroundColor: `rgb(255, 235, 230)`,
    color: `rgb(222, 53, 11)`,
  },
  avatar: {
    fontSize: 8,
    color: "white",
    backgroundColor: "#d63384",
    width: "100%",
  },
  avatarCollaborator: {
    fontSize: 8,
    color: "white",
    backgroundColor: "#198754",
    width: "100%",
  },
}));

const CustomCard = ({
  id,
  title,
  date,
  status,
  label,
  folio_id,
  description,
  badges = [],
  in_charge_area,
  collaborators = [],
  onNew = () => {},
  onClick = () => {},
  draggable,
  ...other
}) => {
  const classes = useStyles();

  if (id === "CardPlus")
    return (
      <CustomButton
        title={<Add />}
        style={{ width: 227 }}
        color={"secondary"}
        outline
        className="mb-5 font-weight-bold"
        onClick={() => onNew(true)}
      />
    );

  return (
    <SimpleCard
      draggable={draggable}
      className="mb-2"
      style={{
        cursor: draggable ? "grab" : "pointer",
        borderLeft: `6px solid ${
          status === "Alta"
            ? "#dc3545"
            : status === "Media"
            ? "#ffc107"
            : "#0dcaf0"
        }`,
      }}
      titleClasses="font-weight-bold font-size-h6 text-wrap"
      contentClasses="py-2"
      title={`${label} - ${folio_id}`}
      subtitle={title}
      onClickCard={() => onClick(id)}
    >
      {description}
      {/* <div className="d-flex justify-content-between mt-3 mb-3">
          {badges.map((b, key) => <Tooltip key={key} title={b.name}><Avatar className={classes.avatar} key={key}>{b.badge}</Avatar></Tooltip>)}
          {collaborators.map((b, key) => <Tooltip key={key} title={b.name}><Avatar className={classes.avatarCollaborator} key={key}>{b.badge}</Avatar></Tooltip>)}
      </div> */}
      <div className="d-flex justify-content-between mt-3 mb-3">
        <div>
          {badges.map((b, key) => (
            <div key={key} style={{ maxWidth: 100 }} className="mb-2">
              <Tooltip title={b.name}>
                <Chip size="small" label={b.name} className={classes.avatar} />
              </Tooltip>
            </div>
          ))}
        </div>
        <div>
          {collaborators.map((b, key) => (
            <div key={key} style={{ maxWidth: 100 }} className="mb-2">
              <Tooltip title={b.name}>
                <Chip
                  size="small"
                  label={b.name}
                  className={classes.avatarCollaborator}
                />
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex mb-3">
        <Chip
          size="small"
          icon={<AccessTime style={{ color: `rgb(222, 53, 11)` }} />}
          label={dateToText(date)}
          className={`${classes.chipDate} mr-2`}
        />
        {in_charge_area && (
          <Chip
            size="small"
            icon={<PinDrop />}
            label={in_charge_area}
            color="secondary"
          />
        )}
      </div>
    </SimpleCard>
  );
};

const TrelloBoard = ({
  data = [],
  onCardMove = () => {},
  onDragEnd = () => {},
}) => {
  return (
    <Board
      data={{ lanes: data }}
      laneDraggable={false}
      onCardMoveAcrossLanes={onCardMove}
      className="h-100"
      style={{ background: "transparent" }}
      components={{
        Card: CustomCard,
      }}
      handleDragEnd={onDragEnd}
    />
  );
};

TrelloBoard.propTypes = {
  data: PropTypes.array.isRequired,
  onCardMove: PropTypes.func.isRequired,
};

export default TrelloBoard;
