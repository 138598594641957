import * as columnFormatters from "../../components/column-formatters";
import {
  getFolios,
  getOtherDocuments,
  getPriorities,
  getShifts,
} from "../../crud/catalogsCrud";

export const columns = (
  onSelectBoard,
  onStore,
  onSeeTask,
  user,
  isVisitedStoredBoards
) => [
  {
    dataField: "name",

    text: "Nombre",
    sort: true,
    formatter: columnFormatters.ToDoBoardColumnName,
    formatExtraData: {
      onSelectBoard,
    },
  },
  {
    dataField: "description",
    text: "Descripción",
    sort: true,
  },
  {
    dataField: "created_at",
    text: "Fecha de creación",
    sort: false,
  },
  {
    dataField: "leader",
    text: "Lider de tablero",
    sort: true,
    formatter: columnFormatters.ToDoBoardLeaderColumn,
  },
  {
    dataField: "id",
    text: " ",
    formatter: columnFormatters.ToDoBoardActionsColumn,
    classes: "d-flex",
    formatExtraData: {
      onStore,
      onSeeTask,
      onSelectBoard,
      user,
      isVisitedStoredBoards,
    },
    csvExport: false,
  },
];

export const defaultBoard = {
  name: "",
  description: "",
  users: [],
};

export const boardForm = (users) => [
  {
    name: "name",
    label: "Nombre del tablero *",
    type: "text",
    required: true,
  },
  {
    name: "description",
    label: "Descripción del tablero",
    type: "text",
    multiline: true,
    rows: 6,
  },
  {
    name: "users",
    label: "Usuarios con acceso al tablero *",
    type: "search-multiple",
    options: users,
    showAvatars: true,
  },
];

export const finished_task_columns = (onEye) => [
  {
    dataField: "name",
    text: "Tarea",
    sort: true,
  },
  {
    dataField: "reception_date",
    text: "Fecha de entrega",
    sort: false,
  },
  {
    dataField: "folio",
    text: "Folio",
    sort: false,
    formatter: (value) => value?.name,
  },
  // {
  //   dataField: "area",
  //   text: "Área solicitante",
  //   sort: true,
  // },
  {
    dataField: "signed",
    text: "Firmante",
    sort: true,
  },
  {
    dataField: "shift",
    text: "Turno",
    sort: true,
    formatter: (value) => value?.name,
  },
  {
    dataField: "id",
    text: "Acciones",
    formatter: columnFormatters.ToDoFinishActionsColumn,
    headerClasses: "text-center",
    classes: "text-center",
    formatExtraData: {
      onEye,
    },
    csvExport: false,
  },
];

export const format_callendar_data = (data, openDetail) => {
  const x = data.map((obj) => {
    let reformatObject = {};
    reformatObject["id"] = obj.id;
    reformatObject["title"] = obj.name;
    reformatObject["date"] = obj.date_input;
    reformatObject["status"] = obj.priority?.name;
    reformatObject["priority_id"] =
      obj.priority?.name === "Alta" ? 1 : obj.priority?.name === "Media" ? 2 : 3;
    reformatObject["label"] = obj.folio.name;
    reformatObject["column_state"] = obj.column_state;
    reformatObject["folio"] = obj.folio?.name;
    reformatObject["folio_id"] = obj.folio_number;
    reformatObject["in_charge_area"] = obj.in_charge_area;
    reformatObject["reception_date"] = obj.reception_date;
    reformatObject["onClick"] = openDetail;
    reformatObject["backgroundColor"] =
      obj.column_state === 1
        ? "#33658A"
        : obj.column_state === 2
        ? "#7FB7BE"
        : obj.column_state === 3
        ? "#D3F3EE"
        : obj.column_state === 4
        ? "#C49991"
        : "#7D1538";

    return reformatObject;
  });

  return x;
};

export const format_trello_data = (data, openDetail, user_in_charge) => {
  const x = data.map((obj) => {
    let reformatObject = {};
    reformatObject["id"] = obj.id;
    reformatObject["title"] = obj.name;
    reformatObject["date"] = obj.date_input;
    reformatObject["status"] = obj.priority?.name;
    reformatObject["priority_id"] =
      obj.priority?.name === "Alta" ? 1 : obj.priority?.name === "Media" ? 2 : 3;
    reformatObject["label"] = obj.folio.name;
    reformatObject["badges"] = obj.users.map((user) => {
      return {
        badge: `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`,
        name: `${user.first_name} ${user.last_name}`,
      };
    });
    reformatObject["collaborators"] = obj.users_collaborators.map((user) => {
      return {
        badge: `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`,
        name: `${user.first_name} ${user.last_name}`,
      };
    });
    reformatObject["draggable"] =
      user_in_charge === obj.board?.user_in_charge ||
      user_in_charge === obj.board?.user_in_charge2
        ? true
        : !obj.blocked;
    reformatObject["users"] = obj.users;
    reformatObject["users_collaborators"] = obj.users_collaborators;
    reformatObject["user_in_charge"] = obj?.board?.user_in_charge;
    reformatObject["user_in_charge2"] = obj?.board?.user_in_charge2;
    reformatObject["column_state"] = obj.column_state;
    reformatObject["area"] = obj.request_area;
    reformatObject["user"] = obj.signed;
    reformatObject["folio"] = obj.folio?.name;
    reformatObject["folio_id"] = obj.folio_number;
    reformatObject["department"] = obj.shift?.name;
    reformatObject["in_charge_area"] = obj.in_charge_area;
    reformatObject["reception_date"] = obj.reception_date;
    reformatObject["onClick"] = openDetail;
    reformatObject["backgroundColor"] =
      obj.column_state === 1
        ? "#33658A"
        : obj.column_state === 2
        ? "#7FB7BE"
        : obj.column_state === 3
        ? "#D3F3EE"
        : obj.column_state === 4
        ? "#C49991"
        : "#7D1538";

    return reformatObject;
  });

  return x;
};

const laneStyle = {
  backgroundColor: "#f8f8f8",
  boxShadow: "0 1px 5px rgba(0,0,0,0.10), 0 0 0 rgba(0,0,0,0.24)",
  color: "#525252",
  width: 280,
};

const laneStyleTaskFinished = {
  backgroundColor: "#20c997",
  boxShadow: "0 1px 5px rgba(0,0,0,0.10), 0 0 0 rgba(0,0,0,0.24)",
  color: "white",
  width: 280,
};

export const trello_data = (todoTasks = [], setNewTaskModal) => [
  {
    id: "lane1",
    title: `Por hacer (${todoTasks.filter((card) => card.column_state === 1).length})`,
    style: laneStyle,
    cards: [
      {
        id: "CardPlus",
        title: "+",
        onNew: setNewTaskModal,
        draggable: false,
        column_state: 1,
      },
      ...todoTasks
        .filter((card) => card.column_state === 1)
        .sort((x, y) => x.priority_id - y.priority_id),
    ],
  },
  {
    id: "lane2",
    title: `En proceso (${todoTasks.filter((card) => card.column_state === 2).length})`,
    cardStyle: { color: "green" },
    style: laneStyle,
    cards: todoTasks
      .filter((card) => card.column_state === 2)
      .sort((x, y) => x.priority_id - y.priority_id),
  },
  {
    id: "lane3",
    title: `Aseguramiento de calidad (${
      todoTasks.filter((card) => card.column_state === 3).length
    })`,
    style: laneStyle,
    cards: todoTasks
      .filter((card) => card.column_state === 3)
      ?.sort((x, y) => x.priority_id - y.priority_id),
  },
  {
    id: "lane4",
    title: `Terminadas (${todoTasks.filter((card) => card.column_state === 4).length})`,
    style: laneStyleTaskFinished,
    cards: todoTasks
      .filter((card) => card.column_state === 4)
      .sort((x, y) => x.priority_id - y.priority_id),
  },
];

export const defaultTask = {
  name: "",
  folio: "",
  folio_number: "",
  reception_date: "",
  request_area: "",
  signed: "",
  shift: "",
  description: "",
  users: [],
  users_collaborators: [],
  priority: "",
  date_input: "",
  blocked: false,
  column_state: 1,
  attachments: [],
};

export const taskForm = (operativeUsers) => [
  {
    name: "name",
    label: "Nombre de la tarea",
    type: "text",
    width: "33%",
  },
  {
    name: "folio",
    label: "Folio",
    type: "dropdown-request",
    width: "20%",
    requestFunction: getFolios,
  },
  {
    name: "folio_number",
    label: "Número de folio",
    type: "text",
    width: "20%",
  },
  {
    name: "reception_date",
    label: "Fecha de recepción",
    type: "date",
    width: "20%",
  },
  {
    name: "request_area",
    label: "Área solicitante",
    type: "text",
    width: "48.3%",
  },
  {
    name: "in_charge_area",
    label: "Área responsable",
    type: "dropdown",
    options: [
      { text: "Almacén", value: "Almacen" },
      { text: "Inventarios", value: "Inventarios" },
      { text: "Sin definir", value: "" },
    ],
    width: "48.3%",
  },
  {
    name: "signed",
    label: "Firmante",
    type: "text",
    width: "48.3%",
  },
  {
    name: "shift",
    label: "Turno",
    type: "dropdown-request",
    width: "40%",
    requestFunction: getShifts,
  },
  {
    name: "priority",
    label: "Prioridad",
    type: "dropdown-request",
    width: "27.5%",
    requestFunction: getPriorities,
  },
  {
    name: "date_input",
    label: "Fecha de entrega",
    type: "date",
    width: "27.5%",
  },
  {
    name: "description",
    label: "Descripción",
    type: "text",
    multiline: true,
    rows: 3,
    width: "100%",
  },
  {
    name: "users",
    label: "Responsable",
    type: "search-multiple",
    showAvatars: true,
    width: "48.3%",
    options: operativeUsers,
  },
  {
    name: "users_collaborators",
    label: "Colaboradores",
    type: "search-multiple",
    showAvatars: true,
    width: "48.3%",
    options: operativeUsers,
  },
];

export const groupButtonOptions = [
  { value: 1, label: "Tarea por hacer" },
  { value: 2, label: "Tareas en proceso" },
  { value: 3, label: "Aseguramiento de calidad" },
  { value: 4, label: "Tareas terminadas", disabled: true },
  { value: 5, label: "Tareas archivadas", disabled: true },
];

export const groupUserInChargeButtonOptions = [
  { value: 1, label: "Tarea por hacer", disabled: true },
  { value: 2, label: "Tareas en proceso" },
  { value: 3, label: "Aseguramiento de calidad" },
  { value: 4, label: "Tareas terminadas" },
  { value: 5, label: "Tareas archivadas", disabled: true },
];

export const defaultFinishedTask = {
  folio: "",
  folio_number: "",
  // aware: false,
  other_document: "",
  document_number: "",
  attachments: [],
};

export const detailTaskForm = (operativeUsers = [], inputDisabled) => [
  {
    name: "folio_number",
    label: "Número de folio",
    type: "text",
    disabled: inputDisabled,
  },
  {
    name: "request_area",
    label: "Area solicitante",
    type: "text",
    disabled: inputDisabled,
  },
  {
    name: "in_charge_area",
    label: "Área responsable",
    type: "dropdown",
    options: [
      { text: "Almacén", value: "Almacen" },
      { text: "Inventarios", value: "Inventarios" },
      { text: "Sin definir", value: "" },
    ],
    disabled: inputDisabled,
    toUpperCase: false,
  },
  {
    name: "signed",
    label: "Firmante",
    type: "text",
    disabled: inputDisabled,
  },
  {
    name: "priority",
    label: "Prioridad",
    type: "dropdown-request",
    requestFunction: getPriorities,
    disabled: inputDisabled,
  },
  {
    name: "users",
    label: "Responsables",
    type: "search-multiple",
    showAvatars: true,
    limitTags: 1,
    options: operativeUsers,
    disabled: inputDisabled,
  },
  {
    name: "users_collaborators",
    label: "Colaboradores",
    type: "search-multiple",
    showAvatars: true,
    limitTags: 1,
    options: operativeUsers,
    disabled: inputDisabled,
  },
  {
    name: "reception_date",
    label: "Fecha de recepción",
    type: "date",
    disabled: inputDisabled,
  },
  {
    name: "date_input",
    label: "Fecha de entrega",
    type: "date",
    disabled: inputDisabled,
  },
];

export const finishedTaskForm = [
  {
    width: "50%",
    name: "folio_name",
    label: "Folio",
    type: "text",
    disabled: true,
  },
  {
    width: "30%",
    name: "folio_number",
    label: "Número de folio",
    type: "text",
    disabled: true,
  },
  // {
  //   width: "10%",
  //   name: "aware",
  //   label: "Enterado",
  //   type: "checkbox",
  //   labelPos: "end"
  // },
  {
    width: "50%",
    name: "other_document",
    label: "Otros documentos",
    type: "dropdown-request",
    requestFunction: getOtherDocuments,
  },
  {
    width: "30%",
    name: "document_number",
    label: "Número de documento",
    type: "text",
  },
];

export const format_calendar = (data = []) =>
  data.map((item) => ({
    ...item,
    start: item.date,
    end: item.date,
    status:
      item.column_state === 1
        ? "Por hacer"
        : item.column_state === 2
        ? "En progreso"
        : item.column_state === 3
        ? "Aseguramiento de calidad"
        : item.column_state === 4
        ? "Terminada"
        : "Archivada",
  }));

export const all_task_columns = (onEye) => [
  {
    dataField: "name",
    text: "Tarea",
    sort: true,
  },
  {
    dataField: "board",
    text: "Tablero",
    sort: true,
    formatter: (value) => value?.name,
  },
  {
    dataField: "column_state",
    text: "Tablero",
    sort: true,
    formatter: (value) =>
      groupButtonOptions.find((op) => op.value === value)?.label || "Archivada",
  },
  {
    dataField: "date_input",
    text: "Fecha de entrega",
    sort: false,
  },
  {
    dataField: "folio",
    text: "Folio",
    sort: false,
    formatter: (value) => value?.name,
  },
  {
    dataField: "signed",
    text: "Firmante",
    sort: true,
  },
  {
    dataField: "shift",
    text: "Turno",
    sort: true,
    formatter: (value) => value?.name,
  },
  {
    dataField: "updated_at",
    text: "Fecha de actualización",
    sort: true,
  },
  {
    dataField: "id",
    text: "Acciones",
    formatter: columnFormatters.ToDoFinishActionsColumn,
    headerClasses: "text-center",
    classes: "text-center",
    formatExtraData: {
      onEye,
    },
    csvExport: false,
  },
];
