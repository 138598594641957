import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionType = {
    SetPledgedGoods: '[PLEDGED_GOODS] SET_PLEDGED_GOODS',
    SetReload: '[PLEDGED_GOODS] SET_RELOAD',
    SetPage: "[PLEDGED_GOODS] SET_PAGE",
    SetSizePerPage: "[PLEDGED_GOODS] SET_SIZE_PER_PAGE"
};

export const initialState = {
    pledgedGoods: {
      results: [],
      count: 0,
      page: 1,
      sizePerPage: 50
    },
    reload: false,
};

export const reducer = persistReducer(
  { storage, key: 'requests', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetPledgedGoods:
        return {
          ...state,
          pledgedGoods: {
            ...state.pledgedGoods,
            ...action.payload
          }
        };

      case actionType.SetReload:
        return { ...state, reload: !state.reload };

      case actionType.SetPage:
        return {
          ...state,
          pledgedGoods:{
            ...state.pledgedGoods,
            page: action.payload
          }
        };
      case actionType.SetSizePerPage:
        return {
          ...state,
          pledgedGoods:{
            ...state.pledgedGoods,
            sizePerPage: action.payload
          }
        };

      default:
        return state
    };
  }
);

export const setPledgedGoods = pledgedGoods => ({ type: actionType.SetPledgedGoods, payload: pledgedGoods });

export const setReload = () => ({ type: actionType.SetReload });

export const setPage = (page) => ({type: actionType.SetPage, payload: page});

export const setSizePerPage = (sizePage) => ({type: actionType.SetSizePerPage, payload: sizePage});
