import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { ContentRoute } from '../../../_metronic/layout';
import ToDoBoard from './ToDoBoard';
import ToDoCalendar from './ToDoCalendar';
import ToDoFinished from './ToDoFinished';
import ToDoTrelloBoard from './ToDoTrelloBoard';

const ToDoWrapper = () => {
  const { isWarehousePage, isInstrumentalGoodsPage } = useSelector(store => store.pathname);

  if (isInstrumentalGoodsPage)
  return (
    <Switch>
      <ContentRoute path="/bienes-instrumentales/administracion-tareas/terminadas/:id" component={ToDoFinished} />
      <ContentRoute path="/bienes-instrumentales/administracion-tareas/tableros/columnas/:id" component={ToDoTrelloBoard} />
      <ContentRoute path="/bienes-instrumentales/administracion-tareas/tableros/calendario/:id" component={ToDoCalendar} />
      <ContentRoute path="/bienes-instrumentales/administracion-tareas/calendario" component={ToDoCalendar} />
      <ContentRoute path="/bienes-instrumentales/administracion-tareas" component={ToDoBoard} />
    </Switch>
  )
  if (isWarehousePage)
  return (
    <Switch>
      <ContentRoute path="/inventario-bienes-almacen-general/administracion-tareas/terminadas/:id" component={ToDoFinished} />
      <ContentRoute path="/inventario-bienes-almacen-general/administracion-tareas/tableros/columnas/:id" component={ToDoTrelloBoard} />
      <ContentRoute path="/inventario-bienes-almacen-general/administracion-tareas/tableros/calendario/:id" component={ToDoCalendar} />
      <ContentRoute path="/inventario-bienes-almacen-general/administracion-tareas/calendario" component={ToDoCalendar} />
      <ContentRoute path="/inventario-bienes-almacen-general/administracion-tareas" component={ToDoBoard} />
    </Switch>
  )

  return (
    <Switch>
      <ContentRoute path="/administrador-tareas/terminadas/:id" component={ToDoFinished} />
      <ContentRoute path="/administrador-tareas/tableros/columnas/:id" component={ToDoTrelloBoard} />
      <ContentRoute path="/administrador-tareas/tableros/calendario/:id" component={ToDoCalendar} />
      <ContentRoute path="/administrador-tareas/calendario" component={ToDoCalendar} />
      <ContentRoute path="/administrador-tareas" component={ToDoBoard} />
    </Switch>
  );
};

export default ToDoWrapper;
