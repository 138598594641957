import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setClearState, setPresentation } from '../../../../redux/ducks/presentations.duck'
import { getSinglePresentation } from '../../../crud/presentationsCrud'
import notice from '../../../components/Notice'
import PageLayout from '../../../components/PageLayout'
import TabsButton from '../../../components/TabsButton'
import UtilInputs from '../../../components/UtilInputs'
import SimpleInput from '../../../components/SimpleInput'
import AutocompleteInput from '../../../components/AutocompleteInput'
import { presTypesForm } from '../../../utils/configData'
import {Grid} from "@material-ui/core";
import { getArticles } from '../../../crud/catalogsCrud'

const ConfigPresentationTypeDetail = () => {
  const { presentation, selectedPresentations } = useSelector(state => state.presentations)
  const dispatch = useDispatch()
  const history = useHistory()

  const onValuesChange = (e) => {
    if(e && e.target) {
        const { value, name } = e.target;
        if (name === "articulo") dispatch(setPresentation({...presentation, unidad: value?.uni_distribuye, [name]: value}))
        
        else dispatch(setPresentation({...presentation, [name]: value}))
    };
  };

  const handleClickTab = (id) => {
    getSinglePresentation(id)
      .then(res => {
        dispatch(setPresentation(res))
        history.push(`/inventario-bienes-almacen-general/configuracion/articulos/tipos-presentacion/resultados/${id}`)
      }).catch(e => {
        dispatch(setClearState())
        notice("Error al mostrar la presentacion")
        history.push('/inventario-bienes-almacen-general/configuracion/articulos/tipos-presentacion/nuevo')
      })
  }

  return (
    <PageLayout
    minHeight="100%"
    title={presentation.id ? "Presentacion" : "Nueva presentacion"}
    header={selectedPresentations.length > 1 && <TabsButton
        list={selectedPresentations} selected={presentation.id} handleClickTab={handleClickTab}
      />
    }
    >
      <Grid container spacing={2} style={{width: '100%'}}>
        <Grid item sm={6} md={6} lg={6}>
          <AutocompleteInput
            width='100%'
            name="articulo"
            label="Articulo"
            searchFunction={getArticles}
            onChange={onValuesChange}
            value={presentation["articulo"]}
          />
        </Grid>
        <Grid item sm={6} md={6} lg={6}>
          <SimpleInput
            width='100%'
            label="Descripción"
            value={presentation["descripcion"]}
            name="descripcion"
            onChange={onValuesChange}
          />
        </Grid>
      </Grid>
      <UtilInputs
        width={200}
        inputValues={presTypesForm}
        formValues={presentation}
        onValuesChange={onValuesChange}
      />
    </PageLayout>
  )
}

export default ConfigPresentationTypeDetail
