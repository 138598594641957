import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import VehiculesForm from "../../components/VehiculesForm";
import ReportContent from "../../components/Reports";
import BaseModal from "../../components/BaseModal";
import notice from "../../components/Notice";
import Labels from "../../components/Labels";
import {
  setFilteredVehicule,
  setNewVehicule,
  setSelectedVehicule,
  setEnableInputs,
  setResetVehiclesForm,
  setClearVehicles,
  setVehiculesIds,
  clearFilteredVehicule,
  clearNewVehicule,
} from "../../../redux/ducks/vehicules.duck";
import { getNames, getTipoBaja } from "../../crud/stockCrud";
import { downLoaderWithURL } from "../../utils/downloaderWithURL";
import { isConciliated } from "../../utils/batchesUtils";
import { getCABMSKey } from "../../utils/cabmsAndKey";
import { formatDate } from "../../utils/dates";
import { useEnterSearch } from "../../hooks/useEnterSearch";
import { isAValidAmount } from "../../utils/inputValidations";
import useSubheaderAction from "../../hooks/useSubheaderAction";
import {
  clearFilter,
  defaultFilters,
  handlePayload,
  inputsLabelNameToCopy,
  validateIntValules,
  validations,
} from "../../utils/vehiculesData";
import {
  setCurrentMode,
  setIsEditDisabled,
  setLabel,
  setOnReportClickFunction,
  setOnLabelClick,
  setOnFormatGuardClick,
} from "../../../redux/ducks/subHeaderActions.duck";
import {
  createVehicule,
  listVehicules,
  postZplVehicle,
  singleVehicule,
  updateVehicule,
  vehiclesReports,
  singleVehiculeDrop,
  unSuscribeId,
  updateMultipleVehiclesFields,
  searchVehicle,
  searchDropVehicle,
  fetchGuardVehicleList,
  unSuscribeReason,
} from "../../crud/vehiculesCrud";
import {
  cleanObject,
  cleanObjectWithZero,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";
import { setLoader } from "../../../redux/ducks/loader.duck";
import UtilInputs from "../../components/UtilInputs";
import moment from "moment";
import { modeOptions } from "../../utils/subheaderIconsData";
moment.locale("es");
const VehiculesDetailPage = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { authToken } = useSelector((store) => store.auth);
  const {
    selectedVehicule,
    filteredVehicule,
    newVehicule,
    selectedVehiculeIds,
  } = useSelector((state) => state.vehicules);
  const {
    onSearchClick2,
    onNewClick2,
    onCancelClick2,
    onEditClick2,
    onSaveClick2,
    onSearchClick3,
    onNewClick3,
    onCancelClick3,
    onEditClick3,
    onReportClick,
    onLabelClick,
    unSuscribe,
    onFormatGuardClick,
  } = useSelector((store) => store.subHeaderActions);
  const { isDetailsPage, isSearchPage, isResultsPage, isNewPage } = useSelector(
    (store) => store.pathname
  );
  const [originalVehicle] = useState(selectedVehicule);
  const isDropPage = pathname.includes("bajas");

  // Configuration to print
  const [firstLetterPartida, setFirstLetterPartida] = useState("");

  const [printConfiguration, setPrintConfiguration] = useState("current");
  const [printLabel, setPrintLabel] = useState(false);
  const [reportsModal, setReportsModal] = useState(false);

  const [vehiculesDataAvailableToCopy, setVehiculesDataAvailableToCopy] = useState({});
  const [openModalToCopy, setOpenModalToCopy] = useState(false);
  const [dataToCopy, setDataToCopy] = useState([]);
  const [tipoBajaList, setTipoBajaList] = useState([]);

  const handlePrintConfiguration = (event) => {
    setPrintConfiguration(event.target.value);
  };

  useEffect(() => {
    if (onReportClick) {
      setReportsModal(true);
    }
  }, [onReportClick]);

  //?BAJAS VEHICULO

  const [disableBaja, setDisableBaja] = useState(true);
  const [openBajaModal, setOpenBajaModal] = useState(false);
  const [bajaForm, setBajaForm] = useState({});

  const inputsBaja = [
    {
      label: "Motivo de baja",
      name: "motivo_baja",
      type: "text",
    },
    {
      label: "Tipo de baja",
      name: "tipo_baja",
      type: "dropdown",
      options: tipoBajaList,
    },
    {
      label: "Fecha de baja",
      name: "fecha_baja",
      type: "date",
    },
  ];
  useEffect(() => {
    if (bajaForm.motivo_baja && bajaForm.tipo_baja) {
      setDisableBaja(false);
    } else {
      setDisableBaja(true);
    }
  }, [bajaForm]);
  const closeFunction = () => setOpenBajaModal(false);

  const handleChangeBaja = (e) => {
    setBajaForm({ ...bajaForm, [e.target.name]: e.target.value });
  };

  const unSuscribeFunction = () => {
    setOpenBajaModal(true);
  };
  //------------------------
  const handleCopyToAllSelectedVehicules = () => {
    dispatch(setLoader(true));
    let values = dataToCopy
      .map((item) => vehiculesDataAvailableToCopy[item])
      .reduce((obj, item) => ((obj[item.name] = item.value), obj), {});
    const requestedData = selectedVehiculeIds.map((id) => ({
      id,
      ...values,
    }));
    updateMultipleVehiclesFields(requestedData)
      .then((res) => {
        notice("Se han editado los vehículos exitosamente", "success");
      })
      .finally(() => {
        dispatch(setCurrentMode(null));
        setVehiculesDataAvailableToCopy({});
        setOpenModalToCopy(false);
        dispatch(setLoader(false));
      });
  };

  const onChangeDataToCopy = (data) => {
    setDataToCopy(data);
  };

  const copyDataModal = () => {
    setOpenModalToCopy(true);
    setDataToCopy(Object.keys(vehiculesDataAvailableToCopy));
  };
  //-----------------------
  const handleChange = ({ target }) => {
    const { name, value } = target;
    let nameInput = name;
    let labelName = inputsLabelNameToCopy.find(({ name }) => name === nameInput);
    if (labelName && !isSearchPage) {
      setVehiculesDataAvailableToCopy({
        ...vehiculesDataAvailableToCopy,
        [labelName.name]: {
          name: target.name,
          label: labelName.label,
          value: target.value,
        },
      });
    }
    const formObj = isSearchPage
      ? filteredVehicule
      : isResultsPage
      ? selectedVehicule
      : newVehicule;

    const amountToNumber = (str) => parseFloat(str.replace(",", "").replace(" ", ""));
    let newData;
    if (name === "cf_cve_partida") {
      const cveFirstLetter = value.text2?.toString().charAt(0) || "";
      setFirstLetterPartida(cveFirstLetter);
      const cf_conciliado = isConciliated(cveFirstLetter, formObj);
      const cf_no_cuenta = value.no_cuenta || "0";
      const cf_no_scuenta = value.no_scuenta || "0";
      const desc_partida = value.text;
      newData = {
        ...formObj,
        [name]: value,
        cf_no_cuenta,
        cf_no_scuenta,
        cf_conciliado,
        desc_partida,
      };
    } else if (name === "cabms_item" && value.text) {
      newData = {
        ...formObj,
        [name]: value.text,
        cabms_key: getCABMSKey(value),
      };
    } else if (name === "cf_ingreso") {
      if (value <= 32767) newData = { ...formObj, [name]: value };
      else notice("El ingreso no debe ser mayor a 32767");
    } else if (name === "cf_importe") {
      if (amountToNumber(value) <= 999999999.99)
        newData = {
          ...formObj,
          [name]: amountToNumber(value),
        };
      else notice("El importe no debe ser mayor a 999999999.99");
    } else if (name === "cf_no_poliza") {
      const cf_conciliado = isConciliated(firstLetterPartida, formObj);
      if (value <= 2147483647) newData = { ...formObj, [name]: value, cf_conciliado };
      else notice("El número de póliza no debe ser mayor a 2147483647");
    } else if (name === "cf_fecha_poliza") {
      const cf_conciliado = isConciliated(firstLetterPartida, formObj);
      newData = { ...formObj, [name]: value, cf_conciliado };
    } else if (name === "cf_tipo_poliza") {
      const cf_conciliado = isConciliated(firstLetterPartida, formObj);
      newData = { ...formObj, [name]: value, cf_conciliado };
    } else if (name === "cf_anio_resguardo") {
      if (value <= 3000) newData = { ...formObj, [name]: value };
      else notice("Ingresar un año valido");
    } else if (name === "item" && value.text) {
      newData = {
        ...formObj,
        [name]: value.text,
        key: getCABMSKey(value),
      };
    } else if (name === "cf_area") {
      const {
        area_attendant,
        centros_costos,
        num_resguardo: cf_no_resguardo /*g, sg, ssg*/,
      } = value;
      const {
        edificio: cf_ubicacion,
        piso: cf_piso,
        oficina: cf_oficina,
        soficina: cf_suboficina,
      } = value?.ubicacion ?? {};
      const cf_responsables = getNames(area_attendant);
      const cf_fecha_resguardo = formatDate("yyyy-MM-dd");
      const cf_centros_costos = centros_costos?.cos_nombre;
      newData = {
        ...formObj,
        [name]: value,
        cf_responsables,
        cf_centros_costos,
        cf_ubicacion,
        cf_no_resguardo,
        cf_fecha_resguardo,
        cf_piso,
        cf_oficina,
        cf_suboficina,
      };
    } else if (validateIntValules.includes(name)) {
      if (isAValidAmount(value)) newData = { ...formObj, [name]: value };
    } else if (name === "cf_cve_partida") {
      const cveFirstLetter = value.text2?.toString().charAt(0) || "";
      const cf_conciliado = isConciliated(cveFirstLetter, formObj);
      const cf_no_cuenta = value.no_cuenta || "0";
      const cf_no_scuenta = value.no_scuenta || "0";
      newData = {
        ...formObj,
        [name]: value,
        cf_no_cuenta,
        cf_no_scuenta,
        cf_conciliado,
      };
    } else if (isSearchPage && name === "cf_cve_partida") {
      newData = { ...formObj, [name]: value };
    } else {
      newData = { ...formObj, [name]: value };
    }
    if (newData) {
      if (isSearchPage) dispatch(setFilteredVehicule(newData));
      if (isNewPage) dispatch(setNewVehicule(newData));
      if (isResultsPage) dispatch(setSelectedVehicule(newData));
    }
  };
  const handleSubmit = (requestFunction) => {
    if (validations(isResultsPage ? selectedVehicule : newVehicule)) {
      const payload = clearEmptyStringsObject(
        handlePayload(isResultsPage ? selectedVehicule : newVehicule)
      );
      requestFunction(payload)
        .then(() => {
          isNewPage && dispatch(clearNewVehicule());
          notice(
            `Vehículo ${isNewPage ? "guardado" : "modificado"} con exito`,
            "success"
          );
          dispatch(setCurrentMode());
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const guardFormat = async () => {
    dispatch(setLoader(true));
    let id = selectedVehiculeIds;
    try {
      const filename = `Resguardo-${moment().format("YYYY-MM-DD")}`;
      const res = await fetchGuardVehicleList(id);
      const formatAURL = res.data.attachment_url;
      downLoaderWithURL(formatAURL, filename + ".pdf");
    } catch (error) {
      console.log("error", error?.response);
      notice(
        `No se ha podido obtener el documento de resguardo desde el servidor`,
        "error"
      );
    } finally {
      dispatch(setOnFormatGuardClick(null));
      dispatch(setLoader(false));
    }
  };

  const handleSearch = () => {
    dispatch(setLoader(true));
    let payload = filterParams(
      cleanObject(clearEmptyStringsObject(clearFilter(handlePayload(filteredVehicule))))
    );

    return listVehicules(1, 100, payload, "asc")
      .then((res) => {
        if (res.results[0]?.id) {
          dispatch(setVehiculesIds(res.ids));
          dispatch(setFilteredVehicule(defaultFilters));
          history.push(`/bienes-instrumentales/vehiculos/resultados/${res.ids[0]}`);
        }
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  };

  const handleSearchDropedVehicules = async () => {
    try {
      dispatch(setLoader(true));
      let payload = filterParams(
        cleanObject(clearEmptyStringsObject(clearFilter(handlePayload(filteredVehicule))))
      );
      let queryArgs = {};
      let serachParams = new URLSearchParams(payload);
      serachParams.forEach((value, key) => {
        queryArgs[key] = value;
      });

      if (queryArgs?.motivo_baja) {
        queryArgs["vehiculo__motivo_baja"] = queryArgs["motivo_baja"];
        delete queryArgs["motivo_baja"];
      }

      if (queryArgs?.tipo_baja_descripcion) {
        queryArgs["vehiculo__tipo_baja__descripcion"] =
          queryArgs["tipo_baja_descripcion"];
        delete queryArgs["tipo_baja_descripcion"];
      }
      if (queryArgs?.fecha_baja) {
        queryArgs["vehiculo__fecha_baja"] = queryArgs["fecha_baja"];
        delete queryArgs["fecha_baja"];
      }
      payload = queryArgs;
      const res = await searchDropVehicle(1, 100, payload, "asc");

      if (res.data.length > 0) {
        dispatch(setVehiculesIds(res.data.map((vehicle) => vehicle.id)));
        dispatch(setFilteredVehicule(defaultFilters));
        history.push(
          `/bienes-instrumentales/vehiculos/bajas/resultados/${res.data[0].id}`
        );
        dispatch(setCurrentMode(null));
      } else {
        notice(
          "No se encontraron bajas de vehículos que coincidan con su búsqueda",
          "info"
        );
        clearForms();
      }
    } catch (error) {
      notice("Ocurrió un error", "error");
      clearForms();
    } finally {
      dispatch(setLoader(false));
      clearForms();
      dispatch(clearFilteredVehicule());
    }
  };
  const handleSearchVehicle = () => {
    dispatch(setLoader(true));
    let payload = filterParams(
      cleanObject(clearEmptyStringsObject(clearFilter(handlePayload(filteredVehicule))))
    );
    let queryArgs = {};
    let serachParams = new URLSearchParams(payload);
    serachParams.forEach((value, key) => {
      queryArgs[key] = value;
    });
    payload = queryArgs;

    if (filteredVehicule.cf_responsables.length !== 0) {
      payload.custom_responsables = filteredVehicule.cf_responsables.map(
        (responsable) => responsable.value
      );
      delete payload.cf_responsables;
    }

    if (Object.hasOwn(payload, "cf_cve_area")) {
      delete payload.cf_fecha_resguardo;
      delete payload.cf_no_resguardo;
      delete payload.cf_oficina;
      delete payload.cf_piso;
      delete payload.cf_responsables;
      delete payload.cf_ubicacion;
    }

    console.log(payload);
    return searchVehicle(1, 100, payload, "asc")
      .then((res) => {
        if (res.data.length > 0 && res.status === 200) {
          dispatch(setVehiculesIds(res.data.map((vehicle) => vehicle.id)));
          dispatch(setFilteredVehicule(defaultFilters));
          history.push(`/bienes-instrumentales/vehiculos/resultados/${res.data[0].id}`);
          dispatch(setCurrentMode(null));
        } else {
          notice("No se encontraron vehículos que coincidan con su búsqueda", "info");
          history.push(`/bienes-instrumentales/vehiculos/busqueda`);
        }
      })
      .catch((e) => notice(e, "error"))
      .finally(() => dispatch(setLoader(false)));
  };
  useEnterSearch(handleSearch);

  useEffect(() => {
    if (isResultsPage && selectedVehicule?.id)
      dispatch(setLabel(`Vehículo ${selectedVehicule?.id}`));
  }, [dispatch, isResultsPage, selectedVehicule?.id]);

  const clearForms = () => {
    if (isSearchPage) {
      dispatch(clearFilteredVehicule());
    } else if (isNewPage) {
      dispatch(clearNewVehicule());
    } else if (isResultsPage) {
      dispatch(setSelectedVehicule(originalVehicle));
    }
  };

  const getVehicleData = () => {
    if (pathname.includes("bajas/resultados")) {
      dispatch(setLoader(true));
      dispatch(setEnableInputs(false));
      singleVehiculeDrop(params.id)
        .then((res) => {
          selectedVehiculeIds?.length === 0 && dispatch(setVehiculesIds([res.id]));
          dispatch(setSelectedVehicule(res));
          dispatch(setResetVehiclesForm(res));
        })
        .catch(console.log)
        .finally(() => dispatch(setLoader(false)));
    } else if (pathname.includes("vehiculos/resultados")) {
      if (params?.id) {
        dispatch(setLoader(true));
        dispatch(setEnableInputs(false));
        singleVehicule(params.id)
          .then((res) => {
            selectedVehiculeIds?.length === 0 && dispatch(setVehiculesIds([res.id]));
            dispatch(
              setSelectedVehicule({
                ...res,
                cf_no_cuenta: res?.partida?.no_cuenta || "",
                cf_no_scuenta: res?.partida?.no_scuenta || "",
              })
            );
            dispatch(
              setResetVehiclesForm({
                ...res,
                cf_no_cuenta: res?.partida?.no_cuenta || "",
                cf_no_scuenta: res?.partida?.no_scuenta || "",
              })
            );
          })
          .catch(console.log)
          .finally(() => dispatch(setLoader(false)));
      }
    }
    getTipoBaja().then((res) => {
      const listTipoBaja = res.results.map((tipo) => ({
        value: tipo.id,
        text: tipo.nombre,
      }));
      setTipoBajaList(listTipoBaja);
    });
  };
  useEffect(() => {
    getVehicleData();
  }, [dispatch, params.id, selectedVehiculeIds?.length]);

  useEffect(() => {
    return () => dispatch(setClearVehicles());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setIsEditDisabled(!selectedVehicule?.id));
  }, [selectedVehicule.id, dispatch]);

  const functionBaja = () => {
    dispatch(setLoader(true));
    if (pathname.includes("baja")) {
      unSuscribeReason(params.id, bajaForm)
        .then((res) => {
          notice("Se edito la baja del vehículo", "success");
          getVehicleData();
          setOpenBajaModal(false);
        })
        .catch((error) => {
          notice("Ha ocurrido un error al editar la obra de Arte" + params.id, "error");
        })
        .finally(() => dispatch(setLoader(false)));
    } else {
      unSuscribeId(params.id, bajaForm)
        .then((res) => {
          notice("Se ha dado de baja el vehículo", "success");
          setTimeout(() => {
            history.push(`/bienes-instrumentales/vehiculos/bajas`);
          }, 2000);
        })
        .catch((error) => {
          notice("Ha ocurrido un eliminar la Obra de Arte" + params.id, "error");
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };

  useSubheaderAction({
    action: onSaveClick2,
    func: () => handleSubmit(isResultsPage ? updateVehicule : createVehicule),
    isInPage: true,
    path: "/bienes-instrumentales/vehiculos/nuevo",
  });

  useSubheaderAction({
    action: onSearchClick2,
    isInPage: pathname.includes("/busqueda"),
    path: isDropPage
      ? "/bienes-instrumentales/vehiculos/bajas/busqueda"
      : "/bienes-instrumentales/vehiculos/busqueda",
  });
  useSubheaderAction({
    action: onSearchClick3,
    // func: handleSearch,
    func: isDropPage ? handleSearchDropedVehicules : handleSearchVehicle,
    isInPage: pathname.includes("/busqueda"),
    path: isDropPage
      ? "/bienes-instrumentales/vehiculos/bajas/busqueda"
      : "/bienes-instrumentales/vehiculos/busqueda",
  });

  useSubheaderAction({
    action: onNewClick2,
    isInPage: pathname.includes("/nuevo"),
    path: "/bienes-instrumentales/vehiculos/nuevo",
  });

  useSubheaderAction({
    action: onNewClick3,
    isInPage: pathname.includes("/nuevo"),
    path: "/bienes-instrumentales/vehiculos/nuevo",
  });

  useSubheaderAction({
    action: onEditClick2,
    isInPage: pathname.includes("/resultados"),
    path: selectedVehicule.id
      ? `/bienes-instrumentales/vehiculos/resultados/${selectedVehicule.id}`
      : false,
  });

  useSubheaderAction({
    action: onEditClick3,
    isInPage: pathname.includes("/resultados"),
    path: selectedVehicule.id
      ? `/bienes-instrumentales/vehiculos/resultados/${selectedVehicule.id}`
      : false,
  });

  useSubheaderAction({
    action: onCancelClick2,
    func: clearForms,
    isInPage: isDetailsPage,
  });

  useSubheaderAction({
    action: onCancelClick3,
    func: clearForms,
    isInPage: isDetailsPage,
  });

  useSubheaderAction({
    action: onFormatGuardClick,
    func: guardFormat,
    isInPage: true,
  });

  useSubheaderAction({
    action: unSuscribe,
    func: unSuscribeFunction,
    isInPage: true,
  });

  useEffect(() => {
    if (onLabelClick) {
      setPrintLabel(true);
    }
  }, [onLabelClick]);

  const printZpl = () => {
    let ids =
      printConfiguration === "current" ? selectedVehicule.id : selectedVehiculeIds.join();

    const a = document.createElement("a");
    a.href = `nextwmsftp://?token=${authToken}?ids=${ids}?type=vehicle`;
    a.click();
    setTimeout(() => {
      setPrintLabel(false);
      setPrintConfiguration("current");
    }, 3000);
    dispatch(setOnLabelClick(""));
  };

  return (
    <>
      <VehiculesForm
        formValues={
          isSearchPage ? filteredVehicule : isResultsPage ? selectedVehicule : newVehicule
        }
        handleChange={handleChange}
        handleCopyToAllSelectedVehicules={handleCopyToAllSelectedVehicules}
        setVehiculesDataAvailableToCopy={setVehiculesDataAvailableToCopy}
        vehiculesDataAvailableToCopy={vehiculesDataAvailableToCopy}
        openModalToCopy={openModalToCopy}
        setOpenModalToCopy={setOpenModalToCopy}
        dataToCopy={dataToCopy}
        setDataToCopy={setDataToCopy}
        onChangeDataToCopy={onChangeDataToCopy}
        copyDataModal={copyDataModal}
      />

      <BaseModal
        title={
          <span>
            Etiqueta del bien <strong>{selectedVehicule.id}</strong>
          </span>
        }
        open={printLabel}
        onClose={() => {
          setPrintLabel(false);
          dispatch(setOnLabelClick(""));
        }}
        onConfirm={printZpl}
        confirmLabel="Imprimir"
        onCancel={() => {
          setPrintLabel(false);
          dispatch(setOnLabelClick(""));
        }}
        cancelLabel="Cerrar"
        maxWidth="sm"
        color="secondary"
        disableOnConfirm={false}
      >
        <Labels
          ids={selectedVehiculeIds}
          item={selectedVehicule}
          handlePrintConfiguration={handlePrintConfiguration}
          printConfiguration={printConfiguration}
          fetchZpl={postZplVehicle}
        />
      </BaseModal>
      <BaseModal
        open={reportsModal}
        onClose={() => {
          setReportsModal(false);
          dispatch(setOnReportClickFunction(""));
        }}
        disableOnConfirm={false}
        title="DESCARGAR REPORTE"
      >
        <ReportContent
          report={"reports-vehicles"}
          data={
            selectedVehiculeIds.length === 0 ? selectedVehicule.id : selectedVehiculeIds
          }
          reportRequest={vehiclesReports}
        />
      </BaseModal>
      <BaseModal
        open={openBajaModal}
        onClose={closeFunction}
        disableOnConfirm={false}
        onConfirm={functionBaja}
        onCancel={closeFunction}
        cancelLabel="Cerrar"
        title="Baja"
      >
        <UtilInputs
          inputValues={inputsBaja}
          onValuesChange={handleChangeBaja}
          formValues={bajaForm}
          width={200}
        />
      </BaseModal>
    </>
  );
};

export default VehiculesDetailPage;
