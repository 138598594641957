import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";
import { useMediaQuery } from "@material-ui/core";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
// import {QuickPanel} from "./extras/offcanvas/QuickPanel";
// import {QuickUser} from "./extras/offcanvas/QuickUser";
import { ScrollTop } from "./extras/ScrollTop";
import SplashLoader from "../../../app/components/SplashLoader";
import FilterWindow from "../../../app/components/FilterWindow";
import { setMenuItems } from "../../../redux/ducks/window.duck";
import { instrumentalGoodsItems, warehpuseItems } from "../../../app/utils/catalogsData";

export function Layout({ children }) {
  const dispatch = useDispatch();
  const { permisos, currentModule } = useSelector((state) => state.auth);
  const {
    isInstrumentalGoodsPage,
    isWarehousePage,
    isDetailsPage,
    isEntryDetailsPage,
  } = useSelector((store) => store.pathname);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const uiService = useHtmlClassService();
  // Layout settings (cssClasses/cssAttributes)
  const hideSidebar = !currentModule;

  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(uiService.config, "header.self.fixed.desktop"),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended"),
    };
  }, [uiService]);

  useEffect(() => {
    if (isInstrumentalGoodsPage) {
      dispatch(setMenuItems(instrumentalGoodsItems(permisos?.permisos)));
    }

    if (isWarehousePage) dispatch(setMenuItems(warehpuseItems));
  }, [dispatch, isInstrumentalGoodsPage, isWarehousePage, permisos?.permisos]);

  return layoutProps.selfLayout !== "blank" ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && !hideSidebar && <Aside />}
          {/*begin::Wrapper*/}
          <div
            style={{
              paddingTop:
                isInstrumentalGoodsPage && isEntryDetailsPage && !isDetailsPage && 70,
            }}
            className={`d-flex flex-column flex-row-fluid wrapper ${
              hideSidebar ? "hidden-sidebar" : ""
            }`}
            id="kt_wrapper"
          >
            <Header hideSidebar={hideSidebar} />
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid p-3`}
              style={{
                marginTop: isMobile && 20,
              }}
            >
              {layoutProps.subheaderDisplay && <SubHeader hideSidebar={hideSidebar} />}
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className="px-4 w-100">{children}</div>
                  {/*end::Container*/}
                </div>
              )}
              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      {/* <QuickUser/> */}
      {/* <QuickPanel/> */}
      <SplashLoader />
      <ScrollTop />
      {/*end::Main*/}
      <LayoutInit />
      {/* CATALOGS MENU */}
      {(isInstrumentalGoodsPage || isWarehousePage) && <FilterWindow />}
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
