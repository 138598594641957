import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles( theme => ({
    title: {
        color: theme?.palette?.primary?.light,
        padding: ({ padding }) => padding,
        margin: ({ margin }) => margin,
    },
}));

const Subtitle = ({ title, margin, padding="0px 0px 25px 0px" }) => {
    const classes = useStyles({ margin, padding });
    return <Typography className={classes.title} variant="h6">{title}</Typography>;
};

export default Subtitle;