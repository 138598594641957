export const goodTypes = [
	{ text: 'Seleccionar tipo de bien', value: '' },
	{ text: 'Mueble', value: 'M' },
	{ text: 'Equipo Eléctrico/Electrónico', value: 'E' },
];

export const conciliedTypes = [
	{ text: 'Seleccione un tipo', value: '' },
	{ text: 'Si', value: 'S' },
	{ text: 'No', value: 'N' },
	{ text: 'No requiere', value: 'R' },
];

export const labelTypeOptions = [
	{value: "NM-MD", text: "NO METÁLICA MEDIANA"},
	{value: "MT-MD", text: "METÁLICA MEDIANA"},
	{value: "NM-CH", text: "NO METÁLICA CHICA"},
	{value: "MT-CH", text: "METÁLICA CHICA"},
];

// Backend accepts only values with max length of 20 characters
export const labelStatusOptions = [
	{value: "PENDING", text: "Pendiente"},
	{value: "PRINTED", text: "Etiqueta impresa"},
	{value: "PLACED", text: "Etiqueta colocada"},
];

export const stockMaterialOptions = [
	{ value: 'METAL', text: 'METAL' },
	{ value: 'MADERA', text: 'MADERA' },
	{ value: 'PLÁSTICO', text: 'PLÁSTICO' },
	{ value: 'MIXTO', text: 'MIXTO' }
];

export const medioAcquisitionOptions = [
	{ text: "PEDIDO", value: "PEDIDO" },
	{ text: "CONTRATO", value: "CONTRATO" },
	{ text: "FONDO REVOLVENTE", value: "FONDO REVOLVENTE" },
	{ text: "DONACIÓN", value: "DONACIÓN" },
	{ text: "ACTA", value: "ACTA" }
];

export const getInputChipMessage = name => {
	switch(name){
		// Main form
		case 'item':
			return 'Asignar mismo Artículo';
		case 'cf_ingreso':
			return 'Asignar mismo Ingreso';
		case 'cabms_item':
			return 'Asignar mismo Artículo CABMS';
		case 'cf_descripcion':
			return 'Asignar misma Descripción';
		case 'cf_marca':
			return 'Asignar misma Marca';
		case 'cf_modelo':
			return 'Asignar mismo Modelo';
		case 'cf_condicion_uso':
			return 'Asignar misma Condición de uso';
		case 'cf_descripcion_uso':
			return 'Asignar misma Condición de operación';
		case 'cf_clasificacion':
			return 'Asignar misma Clasificación';
		case 'cf_aniolocalizacion':
			return 'Asignar mismo Año de localización';
		case 'cf_observaciones':
			return 'Asignar mismas Observaciones';
		// Textareas
		case 'cf_ns_recepcion':
			return 'Asignar mismo NS Recepción';
		case 'cf_clavens':
			return 'Asignar misma Clave NS';
		case 'cf_clasificacion_sicaf':
			return 'Asignar misma Clasificación SICAF';
		case 'cf_tipo_registro':
			return 'Asignar mismo Tipo de registro';
		case 'cf_eninventario':
			return 'Asignar mismo En inventario';
		case 'cf_seguros':
			return 'Asignar mismo Seguros';
		case 'cf_bseguros':
			return 'Asignar mismo B-Seguros';
		case 'cf_acontabilidad':
			return 'Asignar misma A-Contabilidad';
		case 'cf_bcontabilidad':
			return 'Asignar misma B-Contabilidad';
		case 'cf_rpnt':
			return 'Asignar mismo R-PNT';
		case 'cf_bpnt':
			return 'Asignar mismo B-PNT';
		case 'cf_libroinv':
			return 'Asignar mismo Libro inv';
		case 'cf_infreportada':
			return 'Asignar misma Inf. Reportada';
		case 'cf_mised':
			return 'Asignar mismo MISED';

		// Guard Tab
		case 'nom_area':
			return 'Asignar misma Área resguardante';
		// Finantial tab
		case 'cf_fecinv':
			return 'Asignar misma Fecha de inventario';
		case 'cf_inve_material_type':
			return 'Asignar mismo Tipo de material'
		case 'cf_tipobien':
			return 'Asignar mismo Tipo de bien'		;
		case 'cf_anio_resguardo':
			return 'Asignar mismo Año resguardo';
		case 'cve_partida':
			return 'Asignar misma Partida presupuestal';
		case 'cf_no_cuenta':
			return 'Asignar mismo No. De cuenta';
		case 'cf_no_scuenta':
			return 'Asignar mismo No. De subcuenta';
		case 'cf_cve_prove':
			return 'Asignar mismo Proveedor';
		case 'cf_num_factura':
			return 'Asignar mismo No. Factura';
		case 'invoice_date':
			return 'Asignar misma Fecha de la factura';
		case 'cf_fecha_recepcion_factura':
			return 'Asignar misma Fecha recepción Factura DCI';
		case 'reference_number':
			return 'Asignar mismo No. De remisión';
		case 'reference_date':
			return 'Asignar misma Fecha de la remisión';
		case 'cf_fecha_recepcion_remision':
			return 'Asignar misma Fecha Recep. Remisión DCI';
		case 'cf_importe':
			return 'Asignar mismo Importe';
		case 'cf_no_poliza':
			return 'Asignar mismo No. De póliza';
		case 'cf_fecha_poliza':
			return 'Asignar misma Fecha de póliza';
		case 'cf_tipo_poliza':
			return 'Asignar mismo Tipo de póliza';
		case 'cf_fecha_actuali':
			return 'Asignar misma Fecha de captura'		;
		case 'cf_medioadquisicion':
			return 'Asignar mismo Medio de adquisición';

		default:
			return 'Asignar mismo valor';
	}
};
