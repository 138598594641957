import React from "react";
import { useSelector } from "react-redux";
import { artWorkAssignmentDataTabInputs } from "../utils/artWorksData";
import { fieldsToTypeText } from "../utils/objectsFunctions";
import UtilInputs from "./UtilInputs";
import { toggleEnableInputs } from "../utils/newEntryData";

const ArtWorkAssignmentDataTab = ({ formValues, onValuesChange = () => {} }) => {
  const { isSearchPage } = useSelector((store) => store.pathname);
  const { currentMode } = useSelector((store) => store.subHeaderActions);
  let inputValues = isSearchPage
    ? fieldsToTypeText(
        artWorkAssignmentDataTabInputs(currentMode),
        "obras-arte/busqueda",
        currentMode
      )
    : artWorkAssignmentDataTabInputs(currentMode);
  inputValues = !currentMode
    ? toggleEnableInputs(inputValues, false, "obras-arte")
    : inputValues;
  return (
    <div style={{ maxWidth: isSearchPage && 700 }}>
      <UtilInputs
        formValues={formValues}
        inputValues={inputValues}
        onValuesChange={onValuesChange}
      />
    </div>
  );
};

export default ArtWorkAssignmentDataTab;
