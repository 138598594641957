import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllProviders } from "../../../crud/catalogsCrud";
import {
  entryData,
  entryData2,
  entryData3,
  entryData5,
  toggleEnableInputs,
  searchEntryData,
  initialDataAvailableForRequest,
  initialDataAvailableForContract,
  initialDataAvailableForRevolvingFound,
  initialDataAvailableForDonationAndActa,
  dataAvailableForRequest,
  dataAvailableForContract,
  dataAvailableForRevolvingFound,
  dataAvailableForDonationAndActa,
} from "../../../utils/newEntryData";
import { entryData4 } from "../../../utils/newEntryInitialValues";
import UtilInputs from "../../../components/UtilInputs";
import { fieldsToTypeText } from "../../../utils/objectsFunctions";
import update from "immutability-helper";
import {
  setNewEntryResultsForm,
  setNewEntryForm,
} from "../../../../redux/ducks/entry.duck";

export const NewEntryFormFirst = React.memo(
  ({
    formEntryData,
    setFormEntryData,
    selectedResults,
    setFormEntryResult,
    formEntryResult,
  }) => {
    const dispatch = useDispatch();
    //inputs para BUSQUEDA y NUEVO
    const { isResultsPage, isSearchPage, isNewPage } = useSelector(
      (store) => store.pathname
    );
    const [acquisitionResults, setAcquisitionResults] = useState(entryData4);
    const [acquisition, setAcquisition] = useState(entryData4);

    const { currentMode } = useSelector((store) => store.subHeaderActions);
    const [providers, setProviders] = useState([]);
    //obtener proveedores
    useEffect(() => {
      getAllProviders()
        .then((res) => res.length > 0 && setProviders(res))
        .catch(() => setProviders([]));
    }, []);

    const idEntryField = useMemo(
      () => ({
        label: "No. de entrada",
        placeholder: "No. de entrada",
        name: "id",
        type: "text",
        disabled: true,
        focus: true,
      }),
      []
    );
    const getNewFields = (fields) =>
      entryData4.map((field) => ({
        ...field,
        disabled: fields.includes(field.name),
      }));

    useEffect(() => {
      let newFields = "";
      const currentMedioAdquisicion = isResultsPage
        ? selectedResults?.cf_medioadquisicion
        : isNewPage && formEntryData?.cf_medioadquisicion;
      if (isResultsPage) {
        newFields = getNewFields([]);
        setAcquisitionResults(newFields);
      } else {
        switch (currentMedioAdquisicion) {
          case "PEDIDO":
            setAcquisition(getNewFields(dataAvailableForRequest));
            break;
          case "CONTRATO":
            setAcquisition(getNewFields(dataAvailableForContract));
            break;
          case "FONDO REVOLVENTE":
            setAcquisition(getNewFields(dataAvailableForRevolvingFound));
            break;
          case "DONACIÓN":
            setAcquisition(getNewFields(dataAvailableForDonationAndActa));
            break;
          case "ACTA":
            setAcquisition(getNewFields(dataAvailableForDonationAndActa));

            break;
          default:
            break;
        }
      }
      return () => {
        if (isResultsPage || isNewPage) {
          setAcquisition(getNewFields(dataAvailableForRevolvingFound));
        }
      };
    }, [
      selectedResults?.cf_medioadquisicion,
      formEntryData.cf_medioadquisicion,
      isNewPage,
      isResultsPage,
    ]);

    const inputsForm = isResultsPage
      ? [idEntryField, ...entryData]
      : isSearchPage
      ? searchEntryData(providers)
      : entryData;

    const memoEntryData2 = isSearchPage ? fieldsToTypeText(entryData2) : entryData2;
    const memoEntryData3 = isSearchPage ? fieldsToTypeText(entryData3) : entryData3;
    const memoEntryData4 = isSearchPage
      ? fieldsToTypeText(entryData4)
      : isResultsPage
      ? acquisitionResults
      : acquisition;
    const memoEntryData5 = currentMode ? fieldsToTypeText(entryData5) : entryData5;

    const allEntryData = [
      inputsForm,
      memoEntryData2,
      memoEntryData3,
      memoEntryData4,
      memoEntryData5,
    ];
    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === "cf_medioadquisicion") {
        if (value === "PEDIDO") {
          setFormEntryData({
            ...formEntryData,
            [name]: value,
            ...initialDataAvailableForRequest,
          });
        } else if (value === "CONTRATO") {
          setFormEntryData({
            ...formEntryData,
            [name]: value,
            ...initialDataAvailableForContract,
          });
        } else if (value === "FONDO REVOLVENTE") {
          setFormEntryData({
            ...formEntryData,
            [name]: value,
            ...initialDataAvailableForRevolvingFound,
          });
        } else if (["DONACIÓN", "ACTA"]) {
          setFormEntryData({
            ...formEntryData,
            [name]: value,
            ...initialDataAvailableForDonationAndActa,
          });
        }
      } else if (name === "formatos_f" || name === "formatos_r") {
        if (value.id) {
          const newFormValues = update(formEntryData, {
            attachment_pdfs: { $push: [value] },
          });
          setFormEntryResult({
            ...newFormValues,
            [name]: { url: value.url, name: value.title, id: value.id },
          });
          setFormEntryData({
            ...newFormValues,
            [name]: { url: value.url, name: value.title, id: value.id },
          });
          setNewEntryResultsForm({
            ...newFormValues,
            [name]: { url: value.url, name: value.title, id: value.id },
          });
        } else {
          if (isNewPage) {
            dispatch(setNewEntryForm({ ...formEntryData, [name]: value }));
            setFormEntryData({ ...formEntryData, [name]: value });
          } else if (isResultsPage) {
            dispatch(
              setNewEntryResultsForm({
                ...formEntryResult,
                [name]: value,
              })
            );
            setFormEntryResult({
              ...formEntryResult,
              [name]: value,
            });
            setFormEntryData({ ...formEntryData, [name]: value });
          }
        }
      } else {
        setFormEntryData({ ...formEntryData, [name]: value });
      }
    };

    const handleSearch = (e) => {
      const { name, value } = e.target;
      setFormEntryData({ ...formEntryData, [name]: value });
    };
    return (
      <div>
        {allEntryData.map((entryData, index) => {
          return (
            <UtilInputs
              formValues={formEntryData}
              onValuesChange={isSearchPage ? handleSearch : handleChange}
              inputValues={
                !currentMode ? toggleEnableInputs(entryData, false) : entryData
              }
              key={index}
              width={200}
            />
          );
        })}
      </div>
    );
  }
);
