import * as columnFormatters from "../components/column-formatters";
import { getAlmacenes, getBatches } from "../crud/catalogsCrud";
import { formatMoney } from "./expressions";

export const columns = (onEye) => [
  {
    dataField: "id",
    text: "Consecutivo",
    sort: true,
  },
  {
    dataField: "cve_articulo",
    text: "Cve artículo",
    sort: true,
  },
  {
    dataField: "desc_amplia",
    text: "Descripción",
    sort: true,
    style: { minWidth: 200 },
  },
  {
    dataField: "desc_corta",
    text: "Descripción corta",
    sort: true,
  },
  {
    dataField: "uni_distribuye",
    text: "Tipo de presentación",
    sort: true,
  },
  {
    dataField: "precio_unitario",
    text: "Costo",
    sort: true,
    formatter: (value) => formatMoney(value),
    style: {
      whiteSpace: "nowrap",
    },
  },
  {
    dataField: "precio_unitario",
    text: "Precio",
    sort: true,
    formatter: (value) => formatMoney(value),
    style: {
      whiteSpace: "nowrap",
    },
  },
  {
    dataField: "exi_minimax",
    text: "Stock mínimo",
    sort: true,
  },
  {
    dataField: "exi_maximax",
    text: "Stock máximo",
    sort: true,
  },
  {
    dataField: "stock",
    text: "Existencia",
    formatter: (col) => col?.existencia_total,
    sort: true,
  },
  {
    dataField: "tipo_articulo",
    text: "Tipo de bien",
    sort: true,
    formatter: columnFormatters.ArticlesGoodTypeColumnFormatter,
  },
  {
    dataField: "state",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.ArticlesStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ArticlesActionsColumnFormatter,
    classes: "text-center",
    formatExtraData: {
      onEye,
    },
    csvExport: false,
  },
];

export const defaultFilter = {
  cve_articulo: "",
  tipo_articulo: "",
};

export const filterArticleForm = [
  {
    label: "Clave de artículo",
    name: "cve_articulo",
    type: "text",
  },
  {
    label: "Tipo de bien",
    name: "tipo_articulo",
    type: "dropdown-chip",
    options: [
      { value: "", text: "Seleccione un tipo", color: "dark" },
      { value: "STOCK", text: "Stock", color: "primary" },
      { value: "RECURRENTE", text: "Recurrente", color: "info" },
      { value: "ORDINARIO", text: "Ordinario", color: "danger" },
    ],
  },
];

export const defaultExport = {
  formato: "",
  num_seccion: "",
  num_almacen: "",
};

export const exportArticlesForm = (sections = []) => [
  {
    label: "Seleccione el tipo de archivo",
    name: "formato",
    type: "radio",
    row: true,
    options: [
      { value: "PDF", label: "PDF" },
      { value: "EXCEL", label: "Excel" },
    ],
  },
  {
    type: "line-break",
  },
  {
    label: "Clave de almacén",
    name: "num_almacen",
    type: "dropdown-request",
    requestFunction: getAlmacenes,
  },
  {
    label: "Clave de seccón",
    name: "num_seccion",
    type: "dropdown",
    options: [{ value: "", text: "Seleccione un tipo" }, ...sections],
  },
];

export const defaultArticleForm = {
  cve_articulo: "",
  num_almacen: "",
  num_seccion: "",
  fecalta: "",
  state: true,
  cve_usuario: ``,
  fecha_actuali: "",
  desc_corta: "",
  desc_amplia: "",
  tipo_articulo: "",
  label: "",
  cf_label_type: "",
  uni_distribuye: "",
  partida_presu: "",
  precio_unitario: "0",
  iva_unitario: "0",
  inventariable: "0",
  cf_variante1: "",
  cf_variante2: "",
  exi_minimat: "",
  exi_maximat: "",
  cf_variants1: [],
  cf_variants2: [],
  presentaciones: [],
};

export const validateIntValules = ["exi_minimat", "exi_maximat"];

export const generalForm = (id = false, secciones = [], isResultsPage) => [
  // {
  //   label: "Clave del almacén",
  //   name: "num_almacen",
  //   type: "dropdown-request",
  //   requestFunction: getAlmacenes,
  //   disabled: isResultsPage,
  // },
  {
    label: "Tipo de bien",
    name: "tipo_articulo",
    type: "dropdown-chip",
    disabled: id,
    options: [
      { value: "", text: "Seleccione un tipo", color: "dark" },
      { value: "STOCK", text: "Stock", color: "primary" },
      { value: "RECURRENTE", text: "Recurrente", color: "info" },
      // { value: "ORDINARIO", text: "Ordinario", color: "danger" },
    ],
  },
  {
    label: "Clave de la sección",
    name: "num_seccion",
    type: "dropdown",
    required: true,
    options: [{ value: "", text: "Seleccione un tipo" }, ...secciones],
    disabled: isResultsPage,
  },
  {
    label: "Fecha de alta",
    name: "fecalta",
    type: "date",
    required: true,
  },
  {
    label: "Estado",
    name: "state",
    type: "dropdown-chip",
    options: [
      { value: true, text: "Activo", color: "success" },
      { value: false, text: "Baja", color: "danger" },
    ],
  },

  {
    label: "Tamaño de etiqueta",
    name: "label",
    type: "dropdown",
    options: [
      { value: "", text: "Seleccione un tipo" },
      { value: "NM-MD", text: "NO METÁLICA MEDIANA" },
      { value: "MT-MD", text: "METÁLICA MEDIANA" },
      { value: "NM-CH", text: "NO METÁLICA CHICA" },
      { value: "MT-CH", text: "METÁLICA CHICA" },
    ],
  },
  {
    label: "Tipo de etiqueta",
    name: "cf_label_type",
    type: "dropdown",
    options: [
      { value: "", text: "Seleccione un tamaño" },
      { value: "POR_ARTICULO", text: "POR ARTICULO" },
      { value: "POR_GRUPO", text: "POR GRUPO" },
    ],
  },
];

export const generalForm2 = [
  {
    label: "Unidad de medida",
    name: "uni_distribuye",
    type: "dropdown",
    required: true,
    options: [
      { value: "PAQUETE", text: "Paquete" },
      { value: "SOBRE", text: "Sobre" },
      { value: "PIEZA", text: "Pieza" },
      { value: "UNIDAD", text: "Unidad" },
      { value: "PAR", text: "Par" },
      { value: "KILO", text: "Kilo" },
      { value: "LITRO", text: "Litro" },
    ],
  },
  {
    label: "Partida presupuestal",
    name: "partida_presu",
    type: "search",
    required: true,
    searchFunction: getBatches,
    width: 400,
    // hideText: true
  },
  {
    label: "IVA",
    name: "iva_unitario",
    type: "dropdown",
    required: true,
    options: [
      { value: 1, text: "SI" },
      { value: 0, text: "NO" },
    ],
  },
  {
    label: "Inventariable",
    name: "inventariable",
    type: "dropdown",
    required: true,
    options: [
      { value: true, text: "SI" },
      { value: false, text: "NO" },
    ],
  },
  {
    type: "line-break",
  },
  // {
  // 	label: 'Variante 1',
  // 	name: 'cf_variante1',
  // 	type: 'dropdown-request',
  // 	requestFunction: getVariantsList,
  // },
  // {
  // 	label: 'Variante 2',
  // 	name: 'cf_variante2',
  // 	type: 'dropdown-request',
  // 	requestFunction: getVariantsList,
  // },
  {
    label: "Stock mínimo",
    name: "exi_minimax",
    type: "text",
    required: true,
  },
  {
    label: "Stock máximo",
    name: "exi_maximax",
    type: "text",
    required: true,
  },
];

export const variantColumns = [
  {
    text: "Variante 1",
    value: "cf_variant_type",
    type: "text",
  },
  {
    text: "",
    value: "delete",
    type: "icon",
  },
];

export const variantColumns2 = [
  {
    text: "Variante 2",
    value: "cf_variant_type",
    type: "text",
  },
  {
    text: "",
    value: "delete",
    type: "icon",
  },
];

export const presentationColumns = (id) => [
  {
    label: "Presentación del proveedor",
    name: "descripcion",
    type: "text",
  },
  {
    label: "Equivalencia en stock",
    name: "equivalencia",
    type: "text",
  },
  {
    label: "Estatus",
    name: "estatus",
    type: "checkbox",
    disabled: id,
  },
];

export const cleanSearch = (form) => {
  let payload = {
    page: 1,
    limit: 100,
    ...form,
  };
  delete payload.state; // maybe have to return
  delete payload.precio_unitario;
  delete payload.iva_unitario;
  delete payload.costo;
  delete payload.cf_variants1;
  delete payload.cf_variants2;
  delete payload.presentaciones;

  return payload;
};
