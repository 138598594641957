import React from "react";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Icon = ({
  src,
  onClick,
  color = "danger",
  label = "",
  disabled,
  style = {},
  onMouseEnter,
  subLabel = "",
  disableTooltip = false,
  iconSize = "md",
  hide,
}) => {
  if (disableTooltip)
    return (
      <button
        className={`btn btn-icon btn-${color} btn-sm`}
        onClick={onClick}
        disabled={disabled}
        style={{ display: hide, cursor: disabled && "not-allowed", ...style }}
        onMouseEnter={onMouseEnter}
      >
        <span className={`svg-icon svg-icon-md`}>
          <SVG src={src} width={500} height="auto" />
          {subLabel !== "" && <p style={{ marginBottom: -5 }}>{subLabel}</p>}
        </span>
      </button>
    );
  else
    return (
      <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">{label}</Tooltip>}>
        <button
          className={`btn btn-icon btn-${color} btn-sm`}
          onClick={onClick}
          disabled={disabled}
          style={{ cursor: disabled && "not-allowed", ...style }}
          onMouseEnter={onMouseEnter}
        >
          <span className={`svg-icon svg-icon-${iconSize}`}>
            <SVG src={src} height="auto" />
            {subLabel !== "" && <p style={{ marginBottom: -5 }}>{subLabel}</p>}
          </span>
        </button>
      </OverlayTrigger>
    );
};

export default Icon;
