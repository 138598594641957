import notice from "../components/Notice"
import { ASSTETS_STOCK } from "./helpers/routes"
import { getMultipartParams, getParams, handleError, handleResponse } from "./helpers/validate"

const REQ_URL_MIN = ASSTETS_STOCK + "/request-min"
const REQ_URL = ASSTETS_STOCK + "/request"
const OUTBOUND_URL = ASSTETS_STOCK + "/outbound"
const OUTBOUND_REQ_URL = ASSTETS_STOCK + "/outbound-request"
const DEP_REC_URL = ASSTETS_STOCK + "/outbound-recurrent"
const ORD_REC_URL = ASSTETS_STOCK + "/outbound-ordinary"

export const listReqDepartures = (page, limit, filters = "") => {
  return fetch(`${REQ_URL_MIN}?page=${page}&limit=${limit}&${filters}`, getParams("GET"))
  .then(handleResponse)
  .catch(e => {
    notice("Error cargando el listado de salidas")
    throw e
  })
}

export const singleReqDeparture = (id) => {
  return fetch(`${REQ_URL}/${id}`, getParams("GET"))
  .then(handleResponse)
  .then(res => ({
    ...res,
    area: res.area.cf_adscri6,
    responsible: res.area.cf_adscri7,
    warehouse_request_details: res.warehouse_request_details.map(x => ({...x, desc_corta: x.asset_category.desc_corta, uni_distribuye: x.asset_category.uni_distribuye}))
  }))
  .catch(e => {
    notice("Error cargando el listado de salidas")
    throw e
  })
}

export const createDepartureById = (id, data) => {
  return fetch(`${OUTBOUND_REQ_URL}/${id}/`, getParams("POST", data))
  .then(handleResponse)
  .catch(e => {
    notice("Error al crear la salida")
    throw e
  })
}

export const createDepartureByEpc = (epc, data) => {
  return fetch(`${OUTBOUND_REQ_URL}-epc/${epc}/`, getParams('POST', data))
  .then(handleResponse)
  .catch(e => {
    notice("Error al crear la salida")
    throw e
  })
}

// RECURRENT CRUD

export const getRecurrentList = (page=1, limit=20, params="") => {
  return fetch(`${DEP_REC_URL}?page=${page}&limit=${limit}&${params}`, getParams("GET"))
  .then(handleResponse)
  .catch(handleError)
}

export const getOneRecurrent = (id) => {
  return fetch(`${DEP_REC_URL}/${id}/`, getParams("GET"))
  .then(handleResponse)
  .catch(handleError)
}

export const createRecurrentDeparture = (data) => {
  return fetch(DEP_REC_URL + "/", getParams("POST", data))
  .then(handleResponse)
  .catch(handleError)
}

export const getOutboundPdf = id => {
  return fetch(`${OUTBOUND_URL}-pdf/${id}/`, getParams('GET'))
  .then(handleResponse)
  .catch(handleError)
}

// ORDINARY CRUD

export const getOrdinaryList = (page=1, limit=20, params="") => {
  return fetch(`${ORD_REC_URL}?page=${page}&limit=${limit}&${params}`, getParams("GET"))
  .then(handleResponse)
  .catch(handleError)
}

export const getOneOrdinary = (id) => {
  return fetch(`${ORD_REC_URL}/${id}/`, getParams("GET"))
  .then(handleResponse)
  .catch(handleError)
}

export const createOrdinaryDeparture = (data) => {
  return fetch(ORD_REC_URL + "/", getParams("POST", data))
  .then(handleResponse)
  .catch(handleError)
}

export const departureRevert = (id) => {
  return fetch(`${OUTBOUND_URL}-request-revert/${id}/`, getMultipartParams('PATCH'))
  .then(handleResponse)
  .catch(handleError)
}

export const cancelDispatch = (id) => {
  return fetch(`${REQ_URL}/${id}/cancel-to-dispatch`, getMultipartParams('PUT'))
  .then(handleResponse)
  .catch(handleError)
}