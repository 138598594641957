import React, { useMemo } from "react";
import objectPath from "object-path";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMediaQuery, Divider, Box } from "@material-ui/core";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { toAbsoluteUrl } from "../../../_helpers";
import { useSubheader } from "../../_core/MetronicSubheader";
import { actions } from "../../../../app/modules/Auth/_redux/authRedux";
import { formatDate } from "../../../../app/utils/dates";
import Chip from "../../../../app/components/Chip";
import Notifications from "../../../../app/components/Notifications";

export function Topbar() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const history = useHistory();
  const uiService = useHtmlClassService();
  const subheader = useSubheader();
  const layoutProps = useMemo(() => {
    return {
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  const handleClickLogo = async () => {
    await dispatch(actions.clearModule());
    history.push("/modulos");
  };

  return (
    <div
      className="topbar d-flex justify-content-between align-center w-100"
      style={{ height: isMobile && 75 }}
    >
      <div className="d-flex">
        <img
          src={toAbsoluteUrl("/media/logos/logo-senado.png")}
          alt="Senado logo"
          className="topbar-logo"
          onClick={handleClickLogo}
        />
        <p style={{ margin: "auto", marginLeft: 15 }}>{subheader.title}</p>
      </div>
      <div className="d-flex align-items-center">
        <Chip
          label={formatDate("PPPP")}
          color="#3699FF"
          style={
            isMobile
              ? {
                  maxHeight: 50,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  margin: 3,
                }
              : {}
          }
        />
        {layoutProps.viewUserDisplay && (
          <Box sx={{ display: "flex", margin: "0 0.8rem" }}>
            <Divider orientation="vertical" flexItem />
            <Notifications />
            <Divider orientation="vertical" flexItem />
          </Box>
        )}
        {layoutProps.viewUserDisplay && <QuickUserToggler />}
      </div>
    </div>
  );
}
