export const columns = [
  'ID',
  'Folio',
  'Fecha de entrada',
  'Fecha de remisión',
  'No. de remisión',
  'No. de factura',
  'Fecha de factura',
  'Recibido en',
  'Usuario',
  'Estatus',
  'Acciones'
];

export const filterInputs = [
    {
        label: 'Fecha de remisión',
        placeholder: 'Dd/mm/aaaa',
        name: 'cf_fecha_remision',
        type: 'date',
    },
    {
        label: 'No. de remisión',
        placeholder: 'No. de remisión',
        name: 'cf_no_remision',
        type: 'text',
    },
    {
        label: 'No Factura',
        placeholder: 'No. Factura',
        name: 'cf_numero_factura',
        type: 'text',
    },
    {
        label: 'Fecha de factura',
        placeholder: 'Dd/mm/aaaa',
        name: 'cf_fecha_factura',
        type: 'date',
    },
    {
        label: 'Recepción',
        name: 'cf_no_almacen',
        type: 'dropdown',
        options: [
          { value: '1', text: 'Área' },
          { value: '2', text: 'Almacén' }
        ],
    },
    {
        label: 'Nombre de usuario',
        name: 'user__first_name',
        type: 'text',
    },
    {
        label: 'Apellido de usuario',
        name: 'user__last_name',
        type: 'text',
    },
];