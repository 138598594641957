import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FullTable from "../../components/FullTable";
import notice from "../../components/Notice";
import { cleanObject, clearEmptyStringsObject, filterParams } from "../../utils/objectsFunctions";
import { columns, defaultFilter, filterInputs } from '../../utils/usersData';
import { setClearUser, setUsers } from "../../../redux/ducks/users.duck";
import { getUsers } from "../../crud/usersCrud";

const UsersPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { list } = useSelector(state => state.users);
  const { currentModule } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(defaultFilter)
  const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(20);

  useEffect(() => {
    setLoading(true);
    getUsers(page, sizePerPage)
      .then(res => dispatch(setUsers(res)))
      .catch(err => {
        console.log('err', err);
        notice("Ha ocurrido un error de conexión con el servidor", "error");
      })
      .finally(() => setLoading(false));
  }, [dispatch, page, sizePerPage]);

  /**
   * `handleFilterChange` is a function that takes an event object as an argument, and then sets the
   * filter state to the value of the event object's target's name and value
   */
  const handleFilterChange = ({ target }) => {
    const { name, value } = target
    setFilter({...filter, [name]: value})
  }

  /**
   * It gets the users from the server, and if it's successful, it sets the users in the redux store,
   * otherwise it shows an error message
   */
  const onConfirmFilter = () => {
    setLoading(true);
    getUsers(page, sizePerPage, filterParams(clearEmptyStringsObject(cleanObject(filter))))
      .then(res => dispatch(setUsers(res)))
      .catch(err => {
        console.log('err', err);
        notice("Ha ocurrido un error de conexión con el servidor", "error");
      })
      .finally(() => setLoading(false));
  }

  /**
   * It sets the loading state to true, sets the filter to the default filter, gets the users from the
   * server, dispatches the users to the redux store, and finally sets the loading state to false
   */
  const onClearFilter = () => {
    setLoading(true);
    setFilter(defaultFilter)
    getUsers(page, sizePerPage)
      .then(res => dispatch(setUsers(res)))
      .catch(err => {
        console.log('err', err);
        notice("Ha ocurrido un error de conexión con el servidor", "error");
      })
      .finally(() => setLoading(false));
  }

  /**
   * It dispatches the setClearUser action to clear the user state, and then pushes the current pathname
   * to the history object, concatenating the string '/nuevo' to it
   */
  const addUser = () => {
    dispatch(setClearUser());
    history.push(history.location.pathname.concat('/nuevo'));
  };

  /**
   * It pushes the current pathname of the history object, concatenated with the id of the selected item,
   * to the history object
   * @param id - The id of the survey
   */
  const onEye = (id) => {
    history.push(history.location.pathname.concat(`/resultados/${id}`));
  };

  /**
   * It pushes the current pathname to the history object, and then concatenates the string '/busqueda'
   * to it
   */
  const filterUsers = () => {
    history.push(history.location.pathname.concat('/busqueda'));
  };

  return (
    <FullTable
      loading={loading}
      search_button={false}
      title={"Usuarios"}
      buttonTitle={'Nuevo usuario'}
      onClickButton={addUser}
      onClickSearch={filterUsers}
      columns={columns(onEye, currentModule.name)}
      entities={list.results}
      count={list.count}
      page={page}
      sizePerPage={sizePerPage}
      setPage={page => page > 0 && setPage(page)}
      setSizePerPage={setSizePerPage}
      hideCheckbox
      // FILTERS
      has_filter
      formFilters={filter}
      onFilterChange={handleFilterChange}
      filterInputs={filterInputs(currentModule.name)}
      onConfirmFilter={onConfirmFilter}
      onClearFilter={onClearFilter}
    />
  );
};

export default UsersPage;
