import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { entryData4, initialInventoryForm, initialNewEntryForm, initialNewEntrySearchForm } from "../../app/utils/newEntryInitialValues";
import _ from 'lodash';
import { getFlattenObject } from "../../app/utils/attachmentsUtils";
import update from 'immutability-helper';


export const actionType = {
    SetEntries: '[Entry] SET_ENTRIES',
    SetReload: '[Entry] SET_RELOAD',
    SelectEntry: '[Entry] SELECT_ENTRY',
    SelectEntries: '[Entry] SELECT_ENTRIES',
    ClearEntry: '[Entry] CLEAR_ENTRY',
    SetLoading: '[Entry] SET_LOADING',
    SetNewEntryForm: '[Entry] SET_NEW_ENTRY_FORM',
    SetNewEntryInventory: '[Entry] SET_NEW_ENTRY_INVENTORY',
    SetSelectedEntryInventory: '[Entry] SET_SELECTED_ENTRY_INVENTORY',
    SetUpdateRef: '[Entry] SET_UPDATE_REF',
    SetUsername: '[Entry] SET_USERNAME',
    SetAcquisitionFields: '[Entry] SET_ACQUISITION_FIELDS',
    SetNextNavigationEntry: '[Entry] SET_NEXT_NAVIGATION_ENTRY',
    SetPreviousNavigationEntry: '[Entry] SET_PREVIOUS_NAVIGATION_ENTRY',
    SetCurrentEntryTab: '[Entry] SET_CURRENT_ENTRY_TAB',
    SetNewEntrySearchForm: '[Entry] SET_NEW_ENTRY_SEARCH_FORM',
    SetNewEntryResultsForm: '[Entry] SET_NEW_ENTRY_EDITING_RESULT_FORM',
    SetAcquisitionResultsFields: '[Entry] SET_ACQUISITION_RESULTS_FIELDS',
    ClearNewEntrySearchForm: '[Entry] CLEAR_NEW_ENTRY_RESULTS_FORM',
    SetSelectedEntry: '[Entry] SET_SELECTED_ENTRY',
    SetEnableInputs: "[Entry] SET_ENABLE_INPUTS",
    SetResetEntryForm: "[Entry] SET_RESET_ENTRY_FORM",
    SetDefaultResultEntryForm: "[Entry] SET_DEFAULT_RESULT_ENTRY_FORM",
    ClearNewEntryForm: "[Entry] CLEAR_NEW_ENTRY_FORM",
    SetShouldFetch: "[Entry] SET_SHOULD_FETCH",
    SetSelectedEntries: "[Entry] SET_SELECTED_ENTRIES",
    SetPaginateInfoSelectedEntries: '[Entry] SET_PAGINATE_INFO_SELECTED_ENTRIES',
    SetEditInventoryList: '[Entry] SET_EDIT_LIST_INVENTORY',
    SetEditInventoryListAction: '[Entry] SET_EDIT_LIST_INVENTORY_ACTION'
};

export const initialState = {
    entries: {
      results: [],
      count: 0
    },
    reload: false,
    selectedEntry: null,
    selectedEntries: [],
    paginateInfo: {},
    loading: false,
    newEntryForm: initialNewEntryForm,
    inventory: [],
    updateRef: false,
    acquisitionFields: entryData4,
    navigation:{
      hasNext: true,
      hasPrevious: false
    },
    isSearchTab: false,
    isResultsTab: false,
    isNewTab: false,
    newEntrySearchForm: initialNewEntrySearchForm,
    newEntryResultsForm: initialNewEntryForm,
    acquisitionResultsFields: entryData4,
    inventoryResults: [],
    enableInputs: true,
    resetEntryForm: {},
    defaultResultEntryForm: initialNewEntryForm,
    inventoryResultsForm: initialInventoryForm,
    shouldFetch: true,
    editInventoryList: [],
};

const removedFiles = ['Formato Almacen R.pdf', 'Formato Almacen F.pdf'];

export const reducer = persistReducer(
  { storage, key: 'entry', whitelist: ["selectedEntry", "selectedEntries", "paginateInfo"] },
  (state = initialState, action) => {
    let currentIndex = state.selectedEntries.findIndex((currentValue, index) => currentValue === state.selectedEntry?.id);
    let formatos_r = null;
    let formatos_f = null;
    let formatAppends = {};
    let newAttach = null;
    switch (action.type) {
      case actionType.SetEntries:
        return { ...state, entries: action.payload };

      case actionType.SetPaginateInfoSelectedEntries:
        return { ...state, paginateInfo: action.payload };

      case actionType.SetReload:
        return { ...state, reload: action.payload !== null ? action.payload : !state.reload };

      case actionType.SelectEntry:
        const { inventory, ...rest } = action.payload;
        //intercept attachment_pdfs for fill formatos_f and formatos_r
        //Order by id for prevent repeat files
        formatos_f = _(rest.attachment_pdfs)
          .filter(['title','Formato Almacen F.pdf'])
          .orderBy([(f) => f.id], ['desc'])
          .head();
        formatos_r = _(rest.attachment_pdfs)
          .filter(['title','Formato Almacen R.pdf'])
          .orderBy([(f) => f.id], ['desc'])
          .head();
        if (formatos_f) formatAppends = { ...formatAppends, formatos_f: { name: formatos_f.title, url: formatos_f.url, id: formatos_f.id }};
        if (formatos_r) formatAppends = { ...formatAppends, formatos_r: { name: formatos_r.title, url: formatos_r.url, id: formatos_r.id }};

        return { 
          ...state, 
          selectedEntry: action.payload, 
          inventoryResults: inventory, 
          editInventoryList: inventory.map( ( i, key ) => key.toString() ), 
          newEntryResultsForm: {...rest, ...formatAppends }, 
        };

      case actionType.SelectEntries:
        // Fixes attachments from an objects of arrays to array. Also removes Formato R/F Almacen
        action.payload = action.payload.map(entry => ({ ...entry, attachment_pdfs: !entry.all_attachments ? [] : getFlattenObject(entry.all_attachments).filter(attach => !removedFiles.includes(attach.title)) }));

        //intercept attachment_pdfs for fill formatos_f and formatos_r
        //Order by id for prevent repeat files
        formatos_f = _(action.payload[0].attachment_pdfs)
          .filter(['title','Formato Almacen F.pdf'])
          .orderBy([(f) => f.id], ['desc'])
          .head();
        formatos_r = _(action.payload[0].attachment_pdfs)
          .filter(['title','Formato Almacen R.pdf'])
          .orderBy([(f) => f.id], ['desc'])
          .head();
        if (formatos_f) formatAppends = {...formatAppends, formatos_f: {name: formatos_f.title, url: formatos_f.url}};
        if (formatos_r) formatAppends = {...formatAppends, formatos_r: {name: formatos_r.title, url: formatos_r.url}};

        //Remove all Formato Almacen R and F from newEntryForm.
        newAttach = _.filter(action.payload[0].attachment_pdfs, (file) => !removedFiles.includes(file.title));

        return {
          ...state,
          selectedEntries: action.payload,
          selectedEntry: action.payload[0],
          inventoryResults: action.payload[0].inventory,
          newEntryResultsForm: {
            ...action.payload[0],
            cf_username: action.payload[0].user?.username,
            ...formatAppends,
          }
        };

      case actionType.ClearEntry:
        return state = initialState;

      case actionType.SetLoading:
        return { ...state, loading: action.payload };

      case actionType.SetNewEntryForm:
        let updatedEntries = null;

        //If id exist into action.payload, then needs to update selectedEntries, because navigate on each page,
        // values not save.
        if(action.payload?.id){
          const currentEntryId = _(state.selectedEntries).findIndex((entry) => entry === action.payload.id);
          updatedEntries = update(
            state.selectedEntries,
            {
              [currentEntryId]: { $set: { ...state.selectedEntries[currentEntryId], ...action.payload } }
            }
          );
        }

        return updatedEntries
          ? { ...state, selectedEntries: updatedEntries, newEntryForm: { ...state.newEntryForm, ...action.payload } }
          : { ...state, newEntryForm: { ...state.newEntryForm, ...action.payload } };

      case actionType.SetNewEntryInventory:
        return { ...state, inventory: action.payload };

      case actionType.SetSelectedEntryInventory:
        return { ...state, inventoryResults: action.payload };

      case actionType.SetUpdateRef:
        return { ...state, updateRef: action.payload };

      case actionType.SetUsername:
        return { ...state, newEntryForm: { ...state.newEntryForm, cf_username: action.payload } };

      case actionType.SetAcquisitionFields:
        return { ...state, acquisitionFields: action.payload };

      case actionType.SetNextNavigationEntry:
        let hasNext = false;

        if (state.selectedEntries[(currentIndex+1)]){
          currentIndex++;
          if (state.selectedEntries[(currentIndex+1)]) hasNext = true;
        }

        //intercept attachment_pdfs for fill formatos_f and formatos_r
        //Order by id for prevent repeat files
        formatos_f = _(state.selectedEntries[currentIndex].attachment_pdfs)
          .filter(['title','Formato Almacen F.pdf'])
          .orderBy([(f) => f.id], ['desc'])
          .head();
        formatos_r = _(state.selectedEntries[currentIndex].attachment_pdfs)
          .filter(['title','Formato Almacen R.pdf'])
          .orderBy([(f) => f.id], ['desc'])
          .head();
        if (formatos_f) formatAppends = {...formatAppends, formatos_f: {name: formatos_f.title, url: formatos_f.url}};
        if (formatos_r) formatAppends = {...formatAppends, formatos_r: {name: formatos_r.title, url: formatos_r.url}};

        return {
          ...state,
          selectedEntry: state.selectedEntries[currentIndex],
          newEntryResultsForm: {
            ...state.selectedEntries[currentIndex],
            cf_username: state.selectedEntries[currentIndex].user?.username,
            ...formatAppends,
          },
          inventoryResults: state.selectedEntries[currentIndex].inventory,
          navigation:{
            ...state.navigation,
            hasNext,
            hasPrevious: true
          }
        };

      case actionType.SetPreviousNavigationEntry:
        let hasPrevious = false;
        if (state.selectedEntries[(currentIndex-1)]){
          currentIndex--;
          if (state.selectedEntries[(currentIndex-1)]) hasPrevious = true
        }

        //intercept attachment_pdfs for fill formatos_f and formatos_r
        //Order by id for prevent repeat files
        formatos_f = _(state.selectedEntries[currentIndex].attachment_pdfs)
          .filter(['title','Formato Almacen F.pdf'])
          .orderBy([(f) => f.id], ['desc'])
          .head();
        formatos_r = _(state.selectedEntries[currentIndex].attachment_pdfs)
          .filter(['title','Formato Almacen R.pdf'])
          .orderBy([(f) => f.id], ['desc'])
          .head();
        if (formatos_f) formatAppends = {...formatAppends, formatos_f: {name: formatos_f.title, url: formatos_f.url}};
        if (formatos_r) formatAppends = {...formatAppends, formatos_r: {name: formatos_r.title, url: formatos_r.url}};

        //Remove all Formato Almacen R and F from newEntryForm.
        newAttach = _.filter(state.selectedEntries[currentIndex].attachment_pdfs, (file) => !removedFiles.includes(file.title));
        return {
          ...state,
          selectedEntry: state.selectedEntries[currentIndex],
          newEntryResultsForm: {
            ...state.selectedEntries[currentIndex],
            cf_username: state.selectedEntries[currentIndex].user?.username,
            ...formatAppends,
            attachment_pdfs: newAttach
          },
          inventoryResults: state.selectedEntries[currentIndex].inventory,
          navigation:{
            ...state.navigation,
            hasPrevious,
            hasNext: true
          }
        };

      case actionType.SetCurrentEntryTab:
        return { ...state, ...action.payload };

      case actionType.SetNewEntrySearchForm:
        return { ...state, newEntrySearchForm: action.payload };

      case actionType.SetNewEntryResultsForm:
        return { ...state, newEntryResultsForm: action.payload };

      case actionType.SetAcquisitionResultsFields:
        return { ...state, acquisitionResultsFields: action.payload };

      case actionType.ClearNewEntrySearchForm:
        return { ...state, newEntrySearchForm: initialNewEntrySearchForm }

      case actionType.SetSelectedEntry:
        return { ...state, newEntryResultsForm: action.payload, inventoryResults: action.payload?.inventory };

      case actionType.SetEnableInputs:
        return {
          ...state,
          enableInputs: action.payload
        };

      case actionType.SetResetEntryForm:
        return {
          ...state,
          resetEntryForm: action.payload
        };

      case actionType.SetDefaultResultEntryForm:
        return { ...state, defaultResultEntryForm: action.payload };

      case actionType.ClearNewEntryForm:
        return { ...state, newEntryForm: initialNewEntryForm };

      case actionType.SetShouldFetch:
        return { ...state, shouldFetch: action.payload };

      case actionType.SetSelectedEntries:
        return { ...state, selectedEntries: action.payload };
        
      case actionType.SetEditInventoryList:
        return { ...state, editInventoryList: action.payload }
      case actionType.SetEditInventoryListAction:
        return { ...state, editInventoryList: !state.editInventoryList.find(item => item === action.payload) 
          ? [...state.editInventoryList, action.payload]
          : [ ...state.editInventoryList.filter(item => item !== action.payload) ]  }
      default:
        return state;
    }
  }
)

export const setEntries = entries => ({ type: actionType.SetEntries, payload: entries });

export const setReload = (value = null) => ({ type: actionType.SetReload, payload: value });

export const selectEntry = entry => ({ type: actionType.SelectEntry, payload: entry });

export const selectEntries = data => ({ type: actionType.SelectEntries, payload: data });

export const setPaginateInfoSelectedEntries = paginateInfo => ({ type: actionType.SetPaginateInfoSelectedEntries, payload: paginateInfo });

export const clearSelectedEntry = () => ({ type: actionType.ClearEntry });

export const setLoading = isLoading => ({ type: actionType.SetLoading, payload: isLoading });

export const setNewEntryForm = form => ({ type: actionType.SetNewEntryForm, payload: form });

export const setNewEntryInventory = inventory => ({ type: actionType.SetNewEntryInventory, payload: inventory });

export const setSelectedEntryInventory = inventory => ({ type: actionType.SetSelectedEntryInventory, payload: inventory });

export const setUpdateRef = shouldUpdate => ({ type: actionType.SetUpdateRef, payload: shouldUpdate });

export const setUsername = username => ({ type: actionType.SetUsername, payload: username });

export const setAcquisitionFields = fields => ({ type: actionType.SetAcquisitionFields, payload: fields });

export const setNextNavigationEntry = () => ({type: actionType.SetNextNavigationEntry});

export const setPreviousNavigationEntry = () => ({type: actionType.SetPreviousNavigationEntry});

export const setCurrentEntryTab = newTab => ({ type: actionType.SetCurrentEntryTab, payload: newTab });

export const setNewEntrySearchForm = form => ({ type: actionType.SetNewEntrySearchForm, payload: form });

export const setNewEntryResultsForm = form => ({ type: actionType.SetNewEntryResultsForm, payload: form });

export const setAcquisitionResultsFields = fields => ({ type: actionType.SetAcquisitionResultsFields, payload: fields });

export const clearNewEntrySearchForm = () => ({ type: actionType.ClearNewEntrySearchForm });

export const setSelectedEntry = entry => ({ type: actionType.SetSelectedEntry, payload: entry });

export const setEnableInputs = enable => ({type: actionType.SetEnableInputs, payload: enable});

export const setResetEntryForm = formData => ({type: actionType.SetResetEntryForm, payload: formData});

export const setDefaultResultEntryForm = form => ({ type: actionType.SetDefaultResultEntryForm, payload: form });

export const clearNewEntryForm = () => ({ type: actionType.ClearNewEntryForm });

export const setShouldFetch = shouldFetch => ({ type: actionType.SetShouldFetch, payload: shouldFetch });

export const setSelectedEntries = entries => ({ type: actionType.SetSelectedEntries, payload: entries });

export const setEditInventoryList = invetories => ({ type:actionType.SetEditInventoryList, payload: invetories });

export const setEditInventoryListAction = (inventory) => ({ type: actionType.SetEditInventoryListAction, payload: inventory });