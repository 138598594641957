import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import FullTable from "../../components/FullTable";
import notice from "../../components/Notice";
import { columns, defaultFilter, entranceFilterInputs } from "../../utils/entranceData";
import { getEntranceList } from "../../crud/entrancesStockCrud";
import { setClearState, setEntrances } from "../../../redux/ducks/entrance.duck";
import {
  cleanFilter,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";

const EntrancesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { list, entrance } = useSelector((store) => store.entrance);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [filter, setFilter] = useState(defaultFilter);

  const { permisos } = useSelector((state) => state.auth);
  const getFilterParams = useCallback(
    () =>
      filterParams(
        clearEmptyStringsObject(
          cleanFilter({ ...filter, proveedor: filter.proveedor?.text2 })
        )
      ),
    [filter]
  );

  useEffect(() => {
    setLoading(true);
    getEntranceList(page, sizePerPage, getFilterParams())
      .then((res) => dispatch(setEntrances(res)))
      .catch((e) => notice("Error cargando la lista de entradas"))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, sizePerPage]);

  const handleFilterChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const onConfirmFilter = () => {
    setLoading(true);
    getEntranceList(page, sizePerPage, getFilterParams())
      .then((res) => dispatch(setEntrances(res)))
      .catch((e) => notice("Error cargando la lista de entradas"))
      .finally(() => setLoading(false));
  };

  const onClearFilter = () => {
    setFilter(defaultFilter);
    setLoading(true);
    getEntranceList(page, sizePerPage)
      .then((res) => dispatch(setEntrances(res)))
      .catch((e) => notice("Error cargando la lista de entradas"))
      .finally(() => setLoading(false));
  };

  const onEye = async (id, isRefund) => {
    await dispatch(setClearState());
    isRefund
      ? history.push(
          `/inventario-bienes-almacen-general/entradas/devolucion/resultados/${id}`
        )
      : history.push(`/inventario-bienes-almacen-general/entradas/resultados/${id}`);
  };

  const onLock = (id) => console.log("lock", id);

  const handleClickButton = async () => {
    await dispatch(setClearState());
    history.push(`/inventario-bienes-almacen-general/entradas/nuevo`);
  };

  const handleClickReturn = async () => {
    await dispatch(setClearState());
    history.push("/inventario-bienes-almacen-general/entradas/devolucion/nuevo");
  };

  const handleSearch = async () => {
    await dispatch(setClearState());
    history.push("/inventario-bienes-almacen-general/entradas/busqueda");
  };

  let isUserBorrador = permisos.permisos.includes("a-entrada-borrador");
  return (
    <>
      <FullTable
        hasReportIcons={false}
        title="Entradas"
        buttonTitle="Nueva entrada"
        onClickButton={handleClickButton}
        has_button={!isUserBorrador}
        // FILTERS
        has_filter
        formFilters={filter}
        onFilterChange={handleFilterChange}
        filterInputs={entranceFilterInputs(true)}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
        onClickSearch={handleSearch}
        // TABLE LIST DATA
        columns={columns({ onLock, onEye })}
        entities={list.results}
        count={list.count}
        page={page}
        setPage={(page) => page > 0 && setPage(page)}
        sizePerPage={sizePerPage}
        setSizePerPage={setSizePerPage}
        loading={loading}
        has_button3={!isUserBorrador}
        buttonTitle3="Devolución"
        onClickButton3={handleClickReturn}
        hideCheckbox
      />
    </>
  );
};

export default EntrancesPage;
