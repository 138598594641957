import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { listReports } from "../../app/utils/reportsData";

const actionType = {
  SetModal: "[REPORTS] SET_MODAL",
  HandleFormChange: "[REPORTS] HANDLE_FORM_CHANGE",
  SetLoading: "[REPORTS] SET_LOADING",
  SetCurrentReport: "[REPORTS] SET_CURRENT_REPORT",
  ResetCurrentReport: "[REPORTS] RESET_CURRENT_REPORT"
};

const initialState = {
  availableReports: listReports,
  modalOpen: false,
  formValues: null,
  formInputs: null,
  currentReport: null,
  loading: false,
  // requestFunction: () => {}
};

export const reducer = persistReducer(
  { storage, key: "reports", whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetModal: {
        return {
          ...state,
          modalOpen: action.payload
        };
      }
      case actionType.HandleFormChange: {
        return {
          ...state,
          formValues: {
            ...state.formValues,
            ...action.payload
          }
        };
      }
      case actionType.SetLoading: {
        return {
          ...state,
          loading: action.payload
        };
      }
      case actionType.SetCurrentReport: {
        const { formValues, formInputs, currentReport } = action.payload;
        return {
          ...state,
          formValues,
          formInputs,
          currentReport
        };
      }
      case actionType.ResetCurrentReport: {
        return {
          ...state,
          formValues: initialState.formValues,
          formInputs: initialState.formInputs,
          currentReport: initialState.currentReport
        };
      }
      default: {
        return state;
      }
    }
  }
);

export const setModal = (open) => ({ type: actionType.SetModal, payload: open });

export const setCurrentReport = (formValues, formInputs, currentReport) => ({
  type: actionType.SetCurrentReport,
  payload: { formValues, formInputs, currentReport }
});

export const setLoading = (isLoading) => ({ type: actionType.SetLoading, payload: isLoading });

export const resetCurrentReport = () => ({ type: actionType.ResetCurrentReport });

export const handleFormChange = (field, value) => ({type: actionType.HandleFormChange, payload: {[field]: value}});
