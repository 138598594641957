import axios from "axios";
import { filterParams } from "../utils/objectsFunctions";
import {
  CATALOGS_URL,
  ARTICLES,
  PLEDGED_ARTICLES,
  ASSTETS_STOCK,
} from "./helpers/routes";

export const getArticlesList = (params) => {
  const filteredParams = filterParams(params);
  const url = `${ARTICLES}?${filteredParams}`;
  return axios.get(url);
};

export const createNewArticle = (body) => {
  return axios.post(ARTICLES, body);
};

export const deleteArticle = (id) => {
  return axios.delete(`${ARTICLES}/${id}`);
};

export const editArticle = (body, id) => {
  return axios.put(`${ARTICLES}/${id}`, body);
};

export const getOneArticle = (id) => {
  return axios.get(`${ARTICLES}/${id}`);
};

export const getArticleZpl = (id, consecutive = false) => {
  return axios.get(
    `${CATALOGS_URL}/articulos/${id}/zpl_code${
      consecutive ? "_reprint?consecutivos=1" : ""
    }`
  );
};

export const postArticleZpl = (id) => {
  return axios.post(`${CATALOGS_URL}/articulos/zpl_codes_post/`, { ids: [id] });
};

export const getPledgedGoodArticles = (page = 1, limit = 50, params = "") => {
  return axios.get(`${PLEDGED_ARTICLES}?page=${page}&limit=${limit}&${params}`);
};

export const requestExportArticles = (data) => {
  return axios.post(`${ASSTETS_STOCK}/reporte-catalos-articulos`, data);
};
