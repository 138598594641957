/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import EyeColumnIcon from "./list-icons/EyeColumnIcon";


export const UsersActionsColumnFormatter = (cellContent,row,rowIndex,{ onEye }) => (
	<div className="d-flex justify-content-center">
		<EyeColumnIcon onClick={() => onEye(row.id)}/>
	</div>
);
