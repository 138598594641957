import * as columnFormatters from "../components/column-formatters";
import notice from "../components/Notice";
import {
  getAreas,
  getBatches,
  getItemCABMSAndKey,
  getProviders,
  getResposableByName,
} from "../crud/catalogsCrud";
import { getGuardFileVehicule, getVehiculesBrands } from "../crud/vehiculesCrud";
import { formatDateDDMMYYYY } from "./dates";
import { conciliedTypes, goodTypes } from "./StockFormData";
import { IdsFormatter } from "../components/column-formatters/IdsFormatter";

export const columns = (onEye, onDrop, isDropPage, Permisos) => [
  {
    dataField: "id",
    text: "No. Inventario",
    sort: true,
    formatter: (cell, row) => IdsFormatter(cell, row, ""),
  },
  isDropPage && {
    dataField: "motivo_baja",
    text: "Motivo",
    sort: true,
  },
  isDropPage && {
    dataField: "tipo_baja",
    text: "Tipo de baja",
    sort: true,
    formatter: (cell) =>
      [
        { value: 1, text: "Perdida" },
        { value: 2, text: "Defecto" },
        { value: 3, text: "Obsolencia" },
      ].find((x) => x.value === cell)?.text,
  },
  {
    dataField: "marca_nombre",
    text: "Marca",
    sort: true,
    formatter: (cell) => cell?.desc_marca?.trim(),
  },
  {
    dataField: "smarca_nombre",
    text: "Submarca",
    sort: true,
    formatter: (cell) => cell?.desc_smarca?.trim(),
  },
  {
    dataField: "type_nombre",
    text: "Tipo de vehículo",
    sort: true,
    formatter: (cell) => cell?.desc_tipov?.trim(),
  },
  {
    dataField: "cf_modelo",
    text: "Modelo",
    sort: true,
  },
  {
    dataField: "cf_cve_area",
    text: "Clave área",
    sort: true,
  },
  {
    dataField: "datos_resguardos",
    text: "Responsable",
    sort: true,
    formatter: (cell) => cell?.responsable?.trim(),
  },
  {
    dataField: "cf_usuario",
    text: "Usuario",
    sort: true,
  },
  {
    dataField: "id",
    text: "Acciones",
    formatter: isDropPage
      ? columnFormatters.DropActionColumn
      : columnFormatters.VehiculesActionsColumnFormatter,
    formatExtraData: {
      onEye,
      onDrop,
      Permisos,
    },
    classes: "d-flex justify-content-between",
    style: { minWidth: 100 },
    csvExport: false,
  },
];

export const defaultFilter = {
  cf_num_inventario: "",
  cf_marca: null,
  cf_smarca: "",
  cf_modelo: "",
  area: null,
  cf_usuario: "",
  cf_typev: "",
};

export const filterInputs = (disableSubbrands, subbrands = []) => [
  {
    label: "No de inventario",
    name: "cf_num_inventario",
    type: "text",
  },
  {
    label: "Marca",
    placeholder: "Marca",
    name: "cf_marca",
    type: "search",
    required: true,
    searchFunction: getVehiculesBrands,
    width: 300,
  },
  {
    label: "Submarca",
    placeholder: "Submarca",
    name: "cf_smarca",
    type: "dropdown",
    required: true,
    options: subbrands,
    disabled: disableSubbrands,
    width: 220,
  },
  {
    label: "Tipo",
    placeholder: "Tipo",
    name: "cf_typev",
    type: "dropdown",
    required: true,
    width: 220,
    options: [
      {
        value: "",
        text: "Seleccione un tipo de vehiculo",
      },
      {
        value: "1",
        text: "Ambulancia",
      },
      {
        value: "2",
        text: "Camioneta de carga",
      },
      {
        value: "4",
        text: "Camioneta de pasajeros",
      },
      {
        value: "5",
        text: "Motocicleta",
      },
      {
        value: "6",
        text: "Sedan",
      },
      {
        value: "0",
        text: "Ninguna",
      },
    ],
  },
  {
    label: "Modelo",
    name: "cf_modelo",
    type: "text",
  },
  {
    label: "Área",
    placeholder: "Área",
    name: "area",
    type: "search",
    searchFunction: getAreas,
    width: 300,
  },
  {
    label: "Usuario",
    name: "cf_usuario",
    type: "text",
  },
];

export const defaultFilters = {
  id: "",
  cabms_item: null,
  cabms_key: "",
  item: "",
  key: "",
  cf_ingreso: "",
  cf_num_inventario: "",
  cf_no_inventario_ant: "",
  cf_marca: null,
  cf_smarca: "",
  cf_typev: "",
  cf_modelo: "",
  cf_usuario: "",
  cf_no_motor: "",
  cf_serie: "",
  cf_color: "",
  cf_placas: "",
  cf_inciso: "",
  cf_tarj_circ: "",
  cf_poliza_seg: "",
  cf_factura: false,
  cf_manual: "",
  cf_placas_y_calcs: "",
  cf_no_resguardo: "",
  cf_fecha_resguardo: "",
  cf_area: null,
  cf_centros_costos: "",
  cf_responsables: [],
  cf_ubicacion: "",
  cf_piso: "",
  cf_oficina: "",
  cf_suboficina: "",
  cf_fecinv: "",
  cf_cve_partida: null,
  desc_partida: "",
  cf_conciliado: "",
  cf_no_cuenta: "",
  cf_no_scuenta: "",
  cf_anio_resguardo: "",
  cf_proveedor: null,
  cf_importe: "",
  cf_num_factura: "",
  cf_fecha_poliza: "",
  cf_no_poliza: "",
  cf_tipo_poliza: "",
  cf_fecha_alta: "",
  cf_ns_recepcion: "",
  cf_clavens: "",
  cf_clasificacion_sicaf: "",
  cf_tipo_registro: "",
  cf_eninventario: "",
  cf_seguros: "",
  cf_bseguros: "",
  cf_acontabilidad: "",
  cf_bcontabilidad: "",
  cf_rpnt: "",
  cf_bpnt: "",
  cf_libroinv: "",
  cf_infreportada: "",
  cf_mised: "",
  cf_observaciones: "",
  cf_inve_material_type: "",
  cf_tipobien: "",
  cf_fecha_recepcion_factura: "",
  cf_fecha_recepcion_remision: "",
  cf_medioadquisicion: "",
  cf_estatus_etiqueta: null,
  invoice_date: "",
  reference_number: "",
  reference_date: "",
  motivo_baja: "",
  fecha_baja: "",
  tipo_baja_descripcion: "",
};

export const defaultVehicule = {
  id: "",
  cabms_item: null,
  cabms_key: "",
  item: "",
  key: "",
  cf_ingreso: "",
  cf_num_inventario: "",
  cf_no_inventario_ant: "",
  cf_marca: null,
  cf_smarca: "",
  cf_typev: "",
  cf_modelo: "",
  cf_fecha_actuali: formatDateDDMMYYYY(),
  cf_usuario: "",
  cf_no_motor: "",
  cf_serie: "",
  cf_color: "",
  cf_placas: "",
  cf_inciso: "",
  cf_tarj_circ: "",
  cf_poliza_seg: "",
  cf_factura: false,
  cf_manual: "",
  cf_placas_y_calcs: "",
  cf_no_resguardo: "",
  cf_fecha_resguardo: "",
  cf_area: null,
  cf_centro_costos: "",
  cf_responsable: "",
  cf_ubicacion: "",
  cf_piso: "",
  cf_oficina: "",
  cf_suboficina: "",
  cf_fecinv: "",
  cf_cve_partida: null,
  desc_partida: "",
  cf_conciliado: "",
  cf_no_cuenta: "",
  cf_no_scuenta: "",
  cf_anio_resguardo: "",
  cf_proveedor: null,
  cf_importe: "",
  cf_num_factura: "",
  cf_fecha_poliza: "",
  cf_no_poliza: "",
  cf_tipo_poliza: "",
  cf_fecha_alta: "",
  cf_ns_recepcion: "",
  cf_clavens: "",
  cf_clasificacion_sicaf: "",
  cf_tipo_registro: "",
  cf_eninventario: "",
  cf_seguros: "",
  cf_bseguros: "",
  cf_acontabilidad: "",
  cf_bcontabilidad: "",
  cf_rpnt: "",
  cf_bpnt: "",
  cf_libroinv: "",
  cf_infreportada: "",
  cf_mised: "",
  cf_observaciones: "",
  cf_fecha_ingreso: formatDateDDMMYYYY(),
  cf_inve_material_type: "",
  cf_tipobien: "",
  cf_fecha_recepcion_factura: "",
  cf_fecha_recepcion_remision: "",
  cf_medioadquisicion: "",
  cf_estatus_etiqueta: null,
  invoice_date: "",
  reference_number: "",
  reference_date: "",
  motivo_baja: "",
  fecha_baja: "",
  tipo_baja_descripcion: "",
};

export const validateIntValules = [
  "cf_ingreso",
  "cf_num_inventario",
  "cf_no_inventario_ant",
  "cf_no_resguardo",
  "cf_no_cuenta",
  "cf_no_scuenta",
  "cf_anio_resguardo",
  "cf_importe",
  "cf_no_poliza",
];

export const iconsActions = (id) => [
  {
    label: "Etiqueta",
    image: "/media/svg/icons/Shopping/Barcode.svg",
    tooltip: "Etiqueta",
    onClick: () => {},
  },
  {
    label: "Resguardo",
    image: "/media/svg/icons/Files/File.svg",
    tooltip: "Resguardo",
    style: { margin: "0 30px" },
    onClick: () => getGuardFileVehicule(id),
  },
];

export const generalForm = (subbrands, disableSubbrands = true) => [
  {
    label: "Artículo CABMS",
    placeholder: "Artículo CABMS",
    name: "cabms_item",
    type: "search",
    required: true,
    hasRef: true,
    searchFunction: getItemCABMSAndKey,
    width: 500,
  },
  {
    label: "Clave CABMS",
    placeholder: " · · · · ",
    name: "cabms_key",
    type: "text",
    disabled: true,
    uppercase: true,
  },
  {
    label: "Artículo",
    placeholder: "Artículo",
    name: "item",
    type: "key",
    required: true,
    width: 500,
  },
  {
    label: "Clave",
    placeholder: " · · · · ",
    name: "key",
    type: "text",
    disabled: true,
    uppercase: true,
  },
  {
    label: "Ingreso",
    placeholder: "Ingreso",
    name: "cf_ingreso",
    type: "text",
    required: true,
  },
  {
    label: "No. inventario anterior",
    placeholder: "No. inventario anterior",
    name: "cf_num_inventario",
    type: "text",
    maxLength: 60,
  },
  {
    label: "Marca",
    placeholder: "Marca",
    name: "cf_marca",
    type: "search",
    required: true,
    searchFunction: getVehiculesBrands,
    width: 300,
  },
  {
    label: "Submarca",
    placeholder: "Submarca",
    name: "cf_smarca",
    type: "dropdown",
    required: true,
    options: subbrands,
    disabled: disableSubbrands,
    width: 220,
  },
  {
    label: "Tipo",
    placeholder: "Tipo",
    name: "cf_typev",
    type: "dropdown",
    required: true,
    width: 220,
    options: [
      {
        value: "",
        text: "Seleccione un tipo de vehiculo",
      },
      {
        value: "1",
        text: "Ambulancia",
      },
      {
        value: "2",
        text: "Camioneta de carga",
      },
      {
        value: "4",
        text: "Camioneta de pasajeros",
      },
      {
        value: "5",
        text: "Motocicleta",
      },
      {
        value: "6",
        text: "Sedan",
      },
      {
        value: "0",
        text: "Ninguna",
      },
    ],
  },
  {
    label: "Modelo",
    placeholder: "Modelo",
    name: "cf_modelo",
    type: "text",
    width: 200,
    maxLength: 60,
  },
];

export const dataTabForm = [
  {
    label: "No de motor",
    placeholder: "No de motor",
    name: "cf_no_motor",
    type: "text",
    maxLength: 60,
  },
  {
    type: "line-break",
  },
  {
    label: "Serie",
    placeholder: "Serie",
    name: "cf_serie",
    type: "text",
    maxLength: 60,
  },
  {
    type: "line-break",
  },
  {
    label: "color",
    placeholder: "color",
    name: "cf_color",
    type: "text",
    maxLength: 60,
  },
  {
    type: "line-break",
  },
  {
    label: "No de placas",
    placeholder: "No de placas",
    name: "cf_placas",
    type: "text",
    maxLength: 60,
  },
  {
    type: "line-break",
  },
  {
    label: "Inciso",
    placeholder: "Inciso",
    name: "cf_inciso",
    type: "text",
    maxLength: 25,
  },
  {
    type: "line-break",
  },
  {
    label: "Tarjeta de circulación",
    name: "cf_tarj_circ",
    type: "dropdown",
    options: [
      { text: "Sí", value: "1" },
      { text: "No", value: "0" },
    ],
  },
  {
    label: "Póliza de seguro",
    name: "cf_poliza_seg",
    type: "dropdown",
    options: [
      { text: "Sí", value: "1" },
      { text: "No", value: "0" },
    ],
  },
  {
    label: "Manual de conductor",
    name: "cf_manual",
    type: "dropdown",
    options: [
      { text: "Sí", value: "1" },
      { text: "No", value: "0" },
    ],
  },
  {
    label: "Placas y calcomanías de identificación",
    name: "cf_placas_y_calcs",
    type: "dropdown",
    options: [
      { text: "Sí", value: "1" },
      { text: "No", value: "0" },
    ],
  },
];

export const guardTabForm = (currentMode) => [
  {
    label: "Área",
    placeholder: "Área",
    name: "cf_area",
    type: "search",
    required: true,
    searchFunction: getAreas,
    width: 600,
    hideText: true,
  },
  {
    type: "line-break",
  },
  {
    label: "Responsables",
    placeholder: "Responsables",
    name: "cf_responsables",
    type: currentMode === "search" ? "search-multiple" : "text",
    width: 600,
    disabled: currentMode === "search" ? false : true,
    searchFunction: getResposableByName,
  },
  {
    type: "line-break",
  },
  {
    label: "No de resguardo",
    placeholder: "No de resguardo",
    name: "cf_no_resguardo",
    type: "text",
    disabled: true,
    width: 190,
  },
  {
    label: "Fecha de resguardo",
    name: "cf_fecha_resguardo",
    type: "date",
    disabled: true,
    width: 190,
  },
  {
    label: "Fecha de actualización",
    name: "cf_fecha_actuali",
    type: "date",
    disabled: true,
    width: 190,
  },
  {
    type: "line-break",
  },

  // {
  //   label: 'Fecha de actualización',
  //   name: 'cf_fecha_actuali',
  //   type: 'date',
  //   disabled: true,
  //   width: 190
  // },

  {
    label: "Edificio",
    placeholder: "Ubicación",
    name: "cf_ubicacion",
    type: "text",
    disabled: true,
    width: 139,
  },
  {
    label: "Piso",
    placeholder: "Piso",
    name: "cf_piso",
    type: "text",
    disabled: true,
    width: 139,
  },
  {
    label: "Oficina",
    placeholder: "Oficina",
    name: "cf_oficina",
    type: "text",
    disabled: true,
    width: 139,
  },
  {
    label: "Suboficina",
    placeholder: "Suboficina",
    name: "cf_suboficina",
    type: "text",
    disabled: true,
    width: 139,
  },
];

// export const vehiculesFinantialTabInputs = (disabled, isSearchPage) => {
//   return [
//     {
//       label: "Fecha de inventario",
//       name: "cf_fecinv",
//       type: "date",
//       disabled,
//     },
//     {
//       label: "Tipo de material",
//       name: "cf_inve_material_type",
//       type: "dropdown",
//       width: 200,
//       disabled,
//       options: [
//         { text: "Seleccionar tipo de material", value: "" },
//         { text: "Metal", value: "METAL" },
//         { text: "Madera", value: "Madera" },
//         { text: "Plastico", value: "Plastico" },
//       ],
//     },
//     {
//       label: "Tipo de bien",
//       name: "cf_tipobien",
//       type: "dropdown",
//       width: 200,
//       options: goodTypes,
//       disabled,
//     },
//     {
//       label: "Año de resguardo",
//       placeholder: "Año de resguardo",
//       name: "cf_anio_resguardo",
//       type: "text",
//       required: true,
//     },
//     {
//       label: "Partida presupuestal",
//       placeholder: "Partida presupuestal",
//       name: "cf_cve_partida",
//       type: "search",
//       required: true,
//       width: 200,
//       searchFunction: getBatches,
//       hideText: true,
//       disabled,
//     },
//     {
//       label: "Descripción partida",
//       placeholder: "Descripción partida",
//       name: "desc_partida",
//       type: "text",
//       width: 200,
//       disabled: true,
//     },
//     {
//       label: "No de cuenta",
//       placeholder: "No de cuenta",
//       name: "cf_no_cuenta",
//       type: "text",
//       disabled: true,
//     },
//     {
//       label: "No de subcuenta",
//       placeholder: "No de subcuenta",
//       name: "cf_no_scuenta",
//       type: "text",
//       disabled: true,
//     },
//     {
//       label: "Proveedor",
//       placeholder: "Proveedor",
//       name: "cf_proveedor",
//       type: "search",
//       required: true,
//       disabled,
//       searchFunction: getProviders,
//       width: "500px",
//     },
//     {
//       label: "No de factura",
//       placeholder: "No de factura",
//       name: "cf_num_factura",
//       type: "text",
//       maxLength: 10,
//       width: 250,
//       disabled,
//     },
//     {
//       label: "Fecha de factura",
//       name: "invoice_date",
//       type: "date",
//       disabled,
//     },
//     {
//       label: "Fecha recepción factura DCI",
//       name: "cf_fecha_recepcion_factura",
//       type: "date",
//       disabled,
//     },
//     {
//       label: "No de remisión",
//       placeholder: "No de remisión",
//       name: "reference_number",
//       type: "text",
//       maxLength: 255,
//       disabled,
//     },
//     {
//       label: "Fecha de remisión",
//       name: "reference_date",
//       type: "date",
//       disabled,
//     },
//     {
//       label: "Fecha Rec. de remisión DCI",
//       name: "cf_fecha_recepcion_remision",
//       type: "date",
//       disabled,
//     },
//     {
//       label: "Importe",
//       placeholder: "Importe",
//       name: "cf_importe",
//       type: "text",
//       required: true,
//       width: 200,
//       isPrice: true,
//       disabled,
//     },
//     {
//       label: "No de póliza",
//       placeholder: "No de póliza",
//       name: "cf_no_poliza",
//       type: "text",
//       required: true,
//       width: 200,
//       disabled,
//     },
//     {
//       label: "Fecha de póliza",
//       name: "cf_fecha_poliza",
//       type: "date",
//       disabled,
//     },
//     {
//       label: "Tipo de póliza",
//       placeholder: "Tipo de póliza",
//       name: "cf_tipo_poliza",
//       type: "text",
//       maxLength: 8,
//       disabled,
//     },
//     {
//       label: "Conciliado",
//       placeholder: "Conciliado",
//       name: "cf_conciliado",
//       type: "dropdown",
//       options: conciliedTypes,
//       disabled: disabled || !isSearchPage,
//     },
//     {
//       label: "Fecha de alta",
//       name: "cf_fecha_alta",
//       type: "date",
//       required: true,
//       disabled,
//     },
//     {
//       label: "Medio de adquisición",
//       placeholder: "Medio de adquisición",
//       name: "cf_medioadquisicion",
//       type: "dropdown",
//       width: 200,
//       disabled,
//       options: [
//         { text: "Seleccionar medio de adquisición", value: "" },
//         { text: "Pedido", value: "PEDIDO" },
//         { text: "Contrato", value: "CONTRATO" },
//         { text: "Fondo revolvente", value: "FONDO REVOLVENTE" },
//         { text: "Donación", value: "DONACIÓN" },
//         { text: "Acta", value: "ACTA" },
//       ],
//     },
//     {
//       label: "Estado de etiqueta",
//       placeholder: "Estado de etiqueta",
//       name: "cf_estatus_etiqueta",
//       type: "dropdown",
//       width: 200,
//       disabled,
//       options: [
//         { text: "Seleccionar estado para al etiqueta", value: null },
//         { text: "Pendiente", value: "PENDING" },
//         { text: "Etiqueta imprimida", value: "PRINTED" },
//         { text: "Etiqueta colocada", value: "PLACED" },
//       ],
//     },
//   ];
// };
export const vehiculesFinantialTabInputs = (disabled, isSearchPage, isResultsPage) => [
  {
    label: "Fecha de inventario",
    name: "cf_fecinv",
    type: "date",
    disabled,
  },
  {
    label: "Tipo de material",
    name: "cf_inve_material_type",
    type: "dropdown",
    width: 200,
    disabled,
    options: [
      { text: "Seleccionar tipo de material", value: "" },
      { text: "Metal", value: "METAL" },
      { text: "Madera", value: "Madera" },
      { text: "Plastico", value: "Plastico" },
    ],
  },
  {
    label: "Tipo de bien",
    name: "cf_tipobien",
    type: "dropdown",
    width: 200,
    options: goodTypes,
    disabled,
  },
  {
    label: "Año de resguardo",
    placeholder: "Año de resguardo",
    name: "cf_anio_resguardo",
    type: "text",
    required: true,
  },
  {
    label: "Partida presupuestal",
    placeholder: "Partida presupuestal",
    name: "cf_cve_partida",
    type: "search",
    required: true,
    width: 200,
    searchFunction: getBatches,
    hideText: true,
    disabled,
  },
  {
    label: "Descripción partida",
    placeholder: "Descripción partida",
    name: "desc_partida",
    type: "text",
    width: 200,
    disabled: true,
  },
  {
    label: "No de cuenta",
    placeholder: "No de cuenta",
    name: "cf_no_cuenta",
    type: "text",
    disabled: true,
  },
  {
    label: "No de subcuenta",
    placeholder: "No de subcuenta",
    name: "cf_no_scuenta",
    type: "text",
    disabled: true,
  },
  {
    label: "Proveedor",
    placeholder: "Proveedor",
    name: "cf_proveedor",
    type: "search",
    required: true,
    disabled,
    searchFunction: getProviders,
    width: "500px",
  },
  {
    label: "No de factura",
    placeholder: "No de factura",
    name: "cf_num_factura",
    type: "text",
    maxLength: 10,
    width: 250,
    disabled,
  },
  {
    label: "Fecha de factura",
    name: "invoice_date",
    type: "date",
    disabled,
  },
  {
    label: "Fecha recepción factura DCI",
    name: "cf_fecha_recepcion_factura",
    type: "date",
    disabled,
  },
  {
    label: "No de remisión",
    placeholder: "No de remisión",
    name: "reference_number",
    type: "text",
    maxLength: 255,
    disabled,
  },
  {
    label: "Fecha de remisión",
    name: "reference_date",
    type: "date",
    disabled,
  },
  {
    label: "Fecha Rec. de remisión DCI",
    name: "cf_fecha_recepcion_remision",
    type: "date",
    disabled,
  },
  {
    label: "Importe",
    placeholder: "Importe",
    name: "cf_importe",
    type: "text",
    required: true,
    width: 200,
    isPrice: true,
    disabled,
  },
  {
    label: "No de póliza",
    placeholder: "No de póliza",
    name: "cf_no_poliza",
    type: "text",
    required: true,
    width: 200,
    disabled,
  },
  {
    label: "Fecha de póliza",
    name: "cf_fecha_poliza",
    type: "date",
    disabled,
  },
  {
    label: "Tipo de póliza",
    placeholder: "Tipo de póliza",
    name: "cf_tipo_poliza",
    type: "text",
    maxLength: 8,
    disabled,
  },
  {
    label: "Conciliado",
    placeholder: "Conciliado",
    name: "cf_conciliado",
    type: "dropdown",
    options: conciliedTypes,
    disabled: disabled || !isSearchPage,
  },
  {
    label: "Fecha de alta ",
    name: "cf_fecha_alta",
    type: "date",
    required: true,
    disabled: isResultsPage,
  },
  {
    label: "Medio de adquisición",
    placeholder: "Medio de adquisición",
    name: "cf_medioadquisicion",
    type: "dropdown",
    width: 200,
    disabled,
    options: [
      { text: "Seleccionar medio de adquisición", value: "" },
      { text: "Pedido", value: "PEDIDO" },
      { text: "Contrato", value: "CONTRATO" },
      { text: "Fondo revolvente", value: "FONDO REVOLVENTE" },
      { text: "Donación", value: "DONACIÓN" },
      { text: "Acta", value: "ACTA" },
    ],
  },
  {
    label: "Estado de etiqueta",
    placeholder: "Estado de etiqueta",
    name: "cf_estatus_etiqueta",
    type: "dropdown",
    width: 200,
    disabled,
    options: [
      { text: "Seleccionar estado para al etiqueta", value: null },
      { text: "Pendiente", value: "PENDING" },
      { text: "Etiqueta imprimida", value: "PRINTED" },
      { text: "Etiqueta colocada", value: "PLACED" },
    ],
  },
];

export const reportInputs = [
  {
    label: "NS Recepción",
    name: "cf_ns_recepcion",
  },
  {
    label: "Clave NS",
    name: "cf_clavens",
  },
  {
    label: "Clasificación SICAF",
    name: "cf_clasificacion_sicaf",
  },
  {
    label: "Tipo de registro",
    name: "cf_tipo_registro",
  },
  {
    label: "En inventario",
    name: "cf_eninventario",
  },
  {
    label: "Seguros",
    name: "cf_seguros",
  },
  {
    label: "B-Seguros",
    name: "cf_bseguros",
  },
  {
    label: "A-Contabilidad",
    name: "cf_acontabilidad",
  },
  {
    label: "B-Contabilidad",
    name: "cf_bcontabilidad",
  },
  {
    label: "R-PNT",
    name: "cf_rpnt",
  },
  {
    label: "B-PNT",
    name: "cf_bpnt",
  },
  {
    label: "Libro inv.",
    name: "cf_libroinv",
  },
  {
    label: "Inf. Reportada",
    name: "cf_infreportada",
  },
  {
    label: "MISED",
    name: "cf_mised",
  },
];

export const finantialExtraDataForm = [
  {
    label: "NS Recepción",
    placeholder: "NS Recepción",
    name: "cf_ns_recepcion",
    type: "text",
    hasChip: true,
    maxLength: 120,
  },
  {
    label: "Clave NS",
    placeholder: "Clave NS",
    name: "cf_clavens",
    type: "text",
    hasChip: true,
    maxLength: 255,
  },
  {
    label: "Clasificación SICAF",
    placeholder: "Clasificación SICAF",
    name: "cf_clasificacion_sicaf",
    type: "text",
    hasChip: true,
    maxLength: 255,
  },
  {
    label: "Tipo de registro",
    placeholder: "Tipo de registro",
    name: "cf_tipo_registro",
    type: "text",
    hasChip: true,
    maxLength: 255,
  },
  {
    label: "En inventario",
    placeholder: "En inventario",
    name: "cf_eninventario",
    type: "text",
    hasChip: true,
    maxLength: 255,
  },
  {
    label: "Seguros",
    placeholder: "Seguros",
    name: "cf_seguros",
    type: "text",
    hasChip: true,
    maxLength: 120,
  },
  {
    label: "B-Seguros",
    placeholder: "B-Seguros",
    name: "cf_bseguros",
    type: "text",
    hasChip: true,
    maxLength: 255,
  },
  {
    label: "A-Contabilidad",
    placeholder: "A-Contabilidad",
    name: "cf_acontabilidad",
    type: "text",
    hasChip: true,
    maxLength: 255,
  },
  {
    label: "B-Contabilidad",
    placeholder: "B-Contabilidad",
    name: "cf_bcontabilidad",
    type: "text",
    hasChip: true,
    maxLength: 255,
  },
  {
    label: "R-PNT",
    placeholder: "R-PNT",
    name: "cf_rpnt",
    type: "text",
    hasChip: true,
    maxLength: 255,
  },
  {
    label: "B-PNT",
    placeholder: "B-PNT",
    name: "cf_bpnt",
    type: "text",
    hasChip: true,
    maxLength: 255,
  },
  {
    label: "Libro inv.",
    placeholder: "Libro inv.",
    name: "cf_libroinv",
    type: "text",
    hasChip: true,
    maxLength: 255,
  },
  {
    label: "Inf. Reportada",
    placeholder: "Inf. Reportada",
    name: "cf_infreportada",
    type: "text",
    hasChip: true,
    maxLength: 255,
  },
  {
    label: "MISED",
    placeholder: "MISED",
    name: "cf_mised",
    type: "text",
    hasChip: true,
    maxLength: 255,
  },
];

export const inputsLabelNameToCopy = [
  {
    label: "No de inventario",
    name: "cf_num_inventario",
  },
  {
    label: "Artículo CABMS",
    name: "cabms_item",
  },
  {
    label: "Clave CABMS",
    name: "cabms_key",
  },
  {
    label: "Artículo",
    name: "item",
  },
  {
    label: "Clave",
    name: "key",
  },
  {
    label: "Ingreso",
    name: "cf_ingreso",
  },
  {
    label: "No. inventario anterior",
    name: "cf_num_inventario",
  },
  {
    label: "Marca",
    name: "cf_marca",
  },
  {
    label: "Submarca",
    name: "cf_smarca",
  },
  {
    label: "Tipo",
    name: "cf_typev",
  },
  {
    label: "Modelo",
    name: "cf_modelo",
  },
  {
    label: "Área",
    name: "area",
  },
  {
    label: "Observaciones",
    name: "cf_observaciones",
  },
  {
    label: "No de motor",
    name: "cf_no_motor",
  },
  {
    label: "Serie",
    name: "cf_serie",
  },
  {
    label: "color",
    name: "cf_color",
  },
  {
    label: "No de placas",
    name: "cf_placas",
  },
  {
    label: "Inciso",
    name: "cf_inciso",
  },
  {
    label: "Usuario",
    name: "cf_usuario",
  },
  {
    label: "Área",
    name: "cf_area",
  },
  {
    label: "Responsable",
    name: "cf_responsables",
  },
  {
    label: "No de resguardo",
    name: "cf_no_resguardo",
  },
  {
    label: "Fecha de resguardo",
    name: "cf_fecha_resguardo",
  },
  {
    label: "Edificio",
    name: "cf_ubicacion",
  },
  {
    label: "Piso",
    name: "cf_piso",
  },
  {
    label: "Oficina",
    name: "cf_oficina",
  },
  {
    label: "Suboficina",
    name: "cf_suboficina",
  },
  {
    label: "NS Recepción",
    name: "cf_ns_recepcion",
  },
  {
    label: "Clave NS",
    name: "cf_clavens",
  },
  {
    label: "Clasificación SICAF",
    name: "cf_clasificacion_sicaf",
  },
  {
    label: "Tipo de registro",
    name: "cf_tipo_registro",
  },
  {
    label: "En inventario",
    name: "cf_eninventario",
  },
  {
    label: "Seguros",
    name: "cf_seguros",
  },
  {
    label: "B-Seguros",
    name: "cf_bseguros",
  },
  {
    label: "A-Contabilidad",
    name: "cf_acontabilidad",
  },
  {
    label: "B-Contabilidad",
    name: "cf_bcontabilidad",
  },
  {
    label: "R-PNT",
    name: "cf_rpnt",
  },
  {
    label: "B-PNT",
    name: "cf_bpnt",
  },
  {
    label: "Libro inv.",
    name: "cf_libroinv",
  },
  {
    label: "Inf. Reportada",
    name: "cf_infreportada",
  },
  {
    label: "MISED",
    name: "cf_mised",
  },

  {
    label: "Fecha de inventario",
    name: "cf_fecinv",
  },
  {
    label: "Tipo de material",
    name: "cf_inve_material_type",
  },
  {
    label: "Tipo de bien",
    name: "cf_tipobien",
  },
  {
    label: "Año de resguardo",
    name: "cf_anio_resguardo",
  },
  {
    label: "Partida presupuestal",
    name: "cf_cve_partida",
  },
  {
    label: "Descripción partida",
    name: "desc_partida",
  },
  {
    label: "No de cuenta",
    name: "cf_no_cuenta",
  },
  {
    label: "No de subcuenta",
    name: "cf_no_scuenta",
  },
  {
    label: "Proveedor",
    name: "cf_proveedor",
  },
  {
    label: "No de factura",
    name: "cf_num_factura",
  },
  {
    label: "Fecha de factura",
    name: "invoice_date",
  },
  {
    label: "Fecha recepción factura DCI",
    name: "cf_fecha_recepcion_factura",
  },
  {
    label: "No de remisión",
    name: "reference_number",
  },
  {
    label: "Fecha de remisión",
    name: "reference_date",
  },
  {
    label: "Fecha Rec. de remisión DCI",
    name: "cf_fecha_recepcion_remision",
  },
  {
    label: "Importe",
    name: "cf_importe",
  },
  {
    label: "No de póliza",
    name: "cf_no_poliza",
  },
  {
    label: "Fecha de póliza",
    name: "cf_fecha_poliza",
  },
  {
    label: "Tipo de póliza",
    name: "cf_tipo_poliza",
  },
  {
    label: "Conciliado",
    name: "cf_conciliado",
  },
  {
    label: "Fecha de alta",
    name: "cf_fecha_alta",
  },
  {
    label: "Medio de adquisición",
    name: "cf_medioadquisicion",
  },
  {
    label: "Estado de etiqueta",
    name: "cf_estatus_etiqueta",
  },
];
export const vehiclesDetailsTabs = [
  { value: 0, label: "Datos del Vehículo", color: "#3699FF" },
  { value: 1, label: "Datos de Resguardo", color: "#1BC5BD" },
  { value: 2, label: "Datos Financieros", color: "#7337EE" },
  { value: 3, label: "Documentos", color: "#F64E60" },
];

export const handlePayload = (obj) => {
  console.log(obj);
  return {
    ...obj,
    // cf_responsables:
    //   cf_responsables.length !== 0
    //     ? cf_responsables.map((responsable) => responsable.value)
    //     : cf_responsable,
    cf_grupo: obj.key.split("·")[0],
    cf_subgrupo: obj.key.split("·")[1],
    cf_clase: obj.key.split("·")[2],
    cf_subclase: obj.key.split("·")[3],
    cf_tipo: obj.key.split("·")[4],
    cf_articulo: obj.item,

    cf_tarj_circ: obj.cf_tarj_circ,
    cf_poliza_seg: obj.cf_poliza_seg,
    cf_factura: obj.cf_factura ? "1" : "0",
    cf_manual: obj.cf_manual,
    cf_placas_y_calcs: obj.cf_placas_y_calcs,
    cf_marca: obj?.cf_marca?.id,
    cf_cve_area: obj.cf_area?.text2,
    cf_cve_partida: obj.cf_cve_partida?.text2,
    cf_cve_prove: obj.cf_proveedor?.text2,

    cabms: obj.cabms_key &&
      obj.cabms_key.split("·")[0] && {
        cf_grupoc: obj.cabms_key.split("·")[0],
        cf_subgrupoc: obj.cabms_key.split("·")[1],
        cf_clasec: obj.cabms_key.split("·")[2],
        cf_subclasec: obj.cabms_key.split("·")[3],
        cf_tipoc: obj.cabms_key.split("·")[4],
      },
  };
};

export const clearFilter = (obj) => {
  for (var propName in obj) {
    if (propName === "cf_fecha_ingreso" || propName === "cf_fecha_actuali") {
      delete obj[propName];
    }
  }
  return obj;
};

export const validations = (form) => {
  if (!form.key) notice("Es necesario seleccionar un artículo");
  else if (!form.cabms_key) notice("Es necesario seleccionar un artículo CABMS");
  else if (!form.cf_ingreso) notice("Es necesario ingresar el ingreso");
  else if (!form.cf_marca) notice("Es necesario seleccionar una marca");
  else if (!form.cf_smarca) notice("Es necesario seleccionar una submarca");
  else if (!form.cf_typev) notice("Es necesario seleccionar un tipo de vehículo");
  else if (!form.cf_area) notice("Es necesario seleccionar un área ");
  else if (!form.cf_fecinv) notice("Es necesario ingresar una fecha de inventario");
  else if (!form.cf_cve_partida)
    notice("Es necesario seleccionar una partida presupuestal");
  else if (!form.cf_anio_resguardo) notice("Es necesario ingresar el año de resguardo");
  else if (!form.cf_proveedor) notice("Es necesario seleccionar un proveedor");
  else if (!form.cf_importe) notice("Es necesario ingresar el importe");
  // else if (selectedVehicule.cf_importe > selectedVehicule.cf_ingreso) notice("El importe debe ser menor al ingreso")
  else if (!form.cf_fecha_poliza) notice("Es necesario ingresar una fecha de póliza ");
  else if (!form.cf_no_poliza) notice("Es necesario ingresar el numero de póliza ");
  else if (!form.cf_fecha_alta) notice("Es necesario ingresar una fecha de alta");
  else return true;
};
