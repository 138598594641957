import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import notice from "../../components/Notice";
import {
  clear_inventory_take_assets,
  payloadResponse,
  payloadRequest,
  initialInventoryValues,
  editRequest,
} from "../../utils/stockSurveyData";
import {
  SetInventory,
  SetInventorysIds,
} from "../../../redux/ducks/inventory.duck";
import {
  setCurrentMode,
  setFormats,
  setIsEditDisabled,
  setLabel,
  setOnFormatGuardClick,
  setOnGetFormatA,
  setOnGetFormatB,
  setOnGetFormatC,
  setOnGetFormatD,
} from "../../../redux/ducks/subHeaderActions.duck";
import useSubheaderAction from "../../hooks/useSubheaderAction";
import { setLoader } from "../../../redux/ducks/loader.duck";
import {
  createNewInventory,
  editInventory,
  getFormat,
} from "../../crud/inventoryCrud";
import { getOneInventory } from "../../crud/inventoryCrud";
import { inventoryArea } from "../../crud/inventoryCrud";
import StockSurveyForm from "./StockSurveyForm";
import BaseModal from "../../components/BaseModal";
import StockGuardFormat from "./StockGuardFormat";
import { getTodayDate } from "../../utils/dates";
import { downLoaderWithURL } from "../../utils/downloaderWithURL";
import { fetchSurveyArtWork, fetchSurveyGuard } from "../../crud/stockCrud";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const StockSurveyDetailPage = () => {
  const dispatch = useDispatch();
  const [formatos, setFormatos] = useState([]);
  const history = useHistory();
  const { pathname } = useLocation();
  const { id } = useParams();
  const {
    onNewClick2,
    onCancelClick2,
    onEditClick2,
    onSaveClick2,
    onNewClick3,
    onCancelClick3,
    onEditClick3,
    onGetFormatAClick,
    onGetFormatBClick,
    onGetFormatCClick,
    onGetFormatDClick,
    onFormatGuardClick,
  } = useSelector((store) => store.subHeaderActions);
  const { isDetailsPage, isResultsPage, isNewPage } = useSelector(
    (store) => store.pathname
  );
  const { selectedInventory, selectedInventorysIds } = useSelector(
    (state) => state.inventory
  );
  const [modalFormatA, setModalFormatA] = useState(false);
  const [modalFormatB, setModalFormatB] = useState(false);
  const [modalFormatC, setModalFormatC] = useState(false);
  const [modalFormatD, setModalFormatD] = useState(false);
  const [optionA, setOptionA] = useState([]);
  const [optionB, setOptionB] = useState([]);
  const [optionC, setOptionC] = useState([]);
  const [optionD, setOptionD] = useState([]);
  const [formatGuardModal, setFormatGuardModal] = useState(false);

  const [guardFormatType, setGuardFormatType] = useState("vehicles");
  // eslint-disable-next-line no-unused-vars
  const [reload, setReload] = useState(false);

  const handleChangeGuardFormatType = (event) => {
    setGuardFormatType(event.target.value);
  };

  const peticion = useCallback(
    function() {
      if (isResultsPage && id) {
        dispatch(setLoader(true));
        getOneInventory(id)
          .then((res) => {
            if (res.status === 200) {
              const payload = payloadResponse(res.data);
              const newData = {};
              const forma = payload.formatos;
              for (const key in forma) {
                if (forma.hasOwnProperty(key)) {
                  newData[key] = [];
                  for (const subKey in forma[key]) {
                    if (forma[key].hasOwnProperty(subKey)) {
                      newData[key].push({
                        name: subKey,
                        checked: false,
                        [subKey]: forma[key][subKey],
                      });
                    }
                  }
                }
              }
              setOptionA(newData["A"]);
              setOptionB(newData["B"]);
              setOptionC(newData["C"]);
              setOptionD(newData["D"]);
              console.log(newData);
              setFormatos(forma);
              dispatch(setFormats(res.data.formatos));
              dispatch(setCurrentMode());
              dispatch(SetInventory(payload));
              dispatch(SetInventorysIds([id]));
              dispatch(setLabel(`Inventario ${id}`));
              history.push(
                `/bienes-instrumentales/levantamiento-inventario/resultados/${id}`
              );
            }
          })
          .catch((e) => {
            history.push(
              "/bienes-instrumentales/levantamiento-inventario/nuevo"
            );
            dispatch(setCurrentMode("new"));
            dispatch(setLabel());
            console.log(e);
          })
          .finally(() => dispatch(setLoader(false)));
      }
    },
    [dispatch, history, id, isResultsPage]
  );

  const peticion2 = useCallback(
    function() {
      if (isNewPage && !selectedInventory.id) {
        if (selectedInventory.areas_cve.length > 0)
          inventoryArea({ cve_area: selectedInventory?.areas_cve })
            .then((res) => {
              if (res.status === 200) {
                const payload = clear_inventory_take_assets(res.data);
                dispatch(
                  SetInventory({
                    ...selectedInventory,
                    inventory_take_assets: payload,
                  })
                );
              } else return;
            })
            .catch(console.log);
        else
          dispatch(
            SetInventory({ ...selectedInventory, inventory_take_assets: [] })
          );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch, isNewPage, selectedInventory.areas_cve]
  );

  useEffect(() => {
    peticion();
  }, [peticion]);

  useEffect(() => {
    peticion2();
  }, [peticion2]);

  useEffect(() => {
    dispatch(setIsEditDisabled(!selectedInventorysIds[0]));
  }, [dispatch, selectedInventorysIds]);

  useEffect(() => {
    if (isNewPage) dispatch(SetInventory(initialInventoryValues));
  }, [dispatch, isNewPage]);

  const handleSubmit = () => {
    if (
      selectedInventory.inventory_take_assets.every(
        (item) => item.status || item.status2
      )
    ) {
      const payload = payloadRequest(selectedInventory);
      dispatch(setLoader(true));
      createNewInventory(payload)
        .then((res) => {
          if (res.status === 201) {
            history.push(
              `/bienes-instrumentales/levantamiento-inventario/resultados/${res.data.id}`
            );
            dispatch(setCurrentMode());
            notice("Inventario levantado correctamente", "success");
          } else {
            notice("Ha ocurrido un error");
          }
        })
        .catch(console.log)
        .finally(() => dispatch(setLoader(false)));
    } else notice("Todos los items deben tener un estatus seleccionado");
  };

  const handleEdit = async () => {
    dispatch(setLoader(true));
    const payload = editRequest(selectedInventory);
    await editInventory(id, payload)
      .then((res) => {
        dispatch(setCurrentMode());
        notice("Inventario levantado correctamente", "success");
      })
      .catch((e) => notice(e.error || "Error actualizando el levantamiento"))
      .finally(() => dispatch(setLoader(false)));
  };

  const clearForms = () => {
    if (isNewPage) {
      dispatch(SetInventory(initialInventoryValues));
      history.push(`/bienes-instrumentales/levantamiento-inventario/nuevo`);
    } else if (isResultsPage) {
      history.push(
        `/bienes-instrumentales/levantamiento-inventario/resultados/${selectedInventorysIds[0]}`
      );
    }
  };

  useSubheaderAction({
    action: onSaveClick2,
    func: () => (isResultsPage ? handleEdit() : handleSubmit()),
    isInPage: true,
    path: "/bienes-instrumentales/levantamiento-inventario/nuevo",
  });

  useSubheaderAction({
    action: onNewClick2,
    isInPage: pathname.includes("/nuevo"),
    path: "/bienes-instrumentales/levantamiento-inventario/nuevo",
  });

  useSubheaderAction({
    action: onNewClick3,
    isInPage: pathname.includes("/nuevo"),
    path: "/bienes-instrumentales/levantamiento-inventario/nuevo",
  });

  useSubheaderAction({
    action: onEditClick2,
    isInPage: pathname.includes("/resultados"),
    path: selectedInventorysIds[0]
      ? `/bienes-instrumentales/levantamiento-inventario/resultados/${selectedInventorysIds[0]}`
      : false,
  });

  useSubheaderAction({
    action: onEditClick3,
    isInPage: pathname.includes("/resultados"),
    path: selectedInventorysIds[0]
      ? `/bienes-instrumentales/levantamiento-inventario/resultados/${selectedInventorysIds[0]}`
      : false,
  });

  useSubheaderAction({
    action: onCancelClick2,
    func: clearForms,
    isInPage: isDetailsPage,
  });

  useSubheaderAction({
    action: onCancelClick3,
    func: clearForms,
    isInPage: isDetailsPage,
  });

  useEffect(() => {
    if (onGetFormatAClick) {
      console.log("here");
      setModalFormatA(true);
      /*  getFormat(id, "A"); */
      dispatch(setOnGetFormatA(null));
    }
    // eslint-disable-next-line
  }, [onGetFormatAClick]);

  useEffect(() => {
    if (onGetFormatBClick) {
      setModalFormatB(true);
      dispatch(setOnGetFormatB(null));
    }
    // eslint-disable-next-line
  }, [onGetFormatBClick]);

  useEffect(() => {
    if (onGetFormatCClick) {
      setModalFormatC(true);
      dispatch(setOnGetFormatC(null));
    }
    // eslint-disable-next-line
  }, [onGetFormatCClick]);
  console.log(onGetFormatBClick);
  useEffect(() => {
    if (onGetFormatDClick) {
      setModalFormatD(true);
      dispatch(setOnGetFormatD(null));
    }
    // eslint-disable-next-line
  }, [onGetFormatDClick]);

  useEffect(() => {
    if (onFormatGuardClick) {
      setFormatGuardModal(true);
    }
    // eslint-disable-next-line
  }, [onFormatGuardClick]);
  const guardFormat = async () => {
    setReload(true);
    if (guardFormatType === "vehicles") {
      try {
        const filename = `Resguardo-${id}-${getTodayDate()}`;
        const res = await fetchSurveyGuard(id, filename);
        if (res.data.attachment_url) {
          const formatAURL = res?.data?.attachment_url;
          downLoaderWithURL(formatAURL, filename + ".pdf");
        }
      } catch (error) {
        console.log("error", error);
        notice(
          `No se ha podido obtener el documento de resguardo desde el servidor`,
          "error"
        );
      } finally {
        dispatch(setOnFormatGuardClick(null));
        setReload(false);
        setFormatGuardModal(false);
      }
    } else {
      try {
        const filename = `Resguardo-${id}-${getTodayDate()}`;
        const res = await fetchSurveyArtWork(id, filename);
        if (res.data.attachment_url) {
          const formatAURL = res?.data?.attachment_url;
          downLoaderWithURL(formatAURL, filename + ".pdf");
        }
        if (res?.data?.message) {
          notice(res.data.message);
        }
      } catch (error) {
        console.log("error", error);
        notice(
          `No se ha podido obtener el documento de resguardo desde el servidor`,
          "error"
        );
      } finally {
        dispatch(setOnFormatGuardClick(null));
        setReload(false);
        setFormatGuardModal(false);
      }
    }
  };
  const handleCheckboxChange = (event, type) => {
    const { name, checked } = event.target;
    console.log(name);

    const optionsMap = {
      A: optionA,
      B: optionB,
      C: optionC,
      D: optionD,
    };

    if (optionsMap[type]) {
      const updatedOptions = optionsMap[type].map((option) => ({
        ...option,
        checked: option.name === name ? checked : false,
      }));

      switch (type) {
        case "A":
          setOptionA(updatedOptions);
          break;
        case "B":
          setOptionB(updatedOptions);
          break;
        case "C":
          setOptionC(updatedOptions);
          break;
        case "D":
          setOptionD(updatedOptions);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <StockSurveyForm />
      <BaseModal
        open={formatGuardModal}
        onClose={() => {
          setFormatGuardModal(false);
          dispatch(setOnFormatGuardClick(""));
        }}
        onConfirm={guardFormat}
        disableOnConfirm={false}
        title={`Documento de Resguardo - Inventario ${selectedInventory.id}`}
      >
        <StockGuardFormat
          loader={reload}
          value={guardFormatType}
          handleChange={handleChangeGuardFormatType}
        />
      </BaseModal>

      <BaseModal
        open={modalFormatA}
        onClose={() => {
          setModalFormatA(false);
        }}
        onConfirm={() => {
          const itemChecked = optionA.find((item) => item.checked === true);
          getFormat(id, "A", itemChecked.name);
        }}
        disableOnConfirm={optionA.every((value) => !value.checked)}
        title={`Elegir para que tipo de formato`}
      >
        {optionA.map((item) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={item.checked}
                onChange={(e) => handleCheckboxChange(e, "A")}
              />
            }
            label={item.name === "artwork" ? "Obras de arte" : "Inventario"}
            name={item.name.toString()}
            disabled={!item[item.name]}
          />
        ))}
      </BaseModal>
      <BaseModal
        open={modalFormatB}
        onClose={() => {
          setModalFormatB(false);
        }}
        onConfirm={() => {
          const itemChecked = optionB.find((item) => item.checked === true);
          getFormat(id, "B", itemChecked.name);
        }}
        disableOnConfirm={optionB.every((value) => !value.checked)}
        title={`Elegir para que tipo de formato`}
      >
        {optionB.map((item) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={item.checked}
                onChange={(e) => handleCheckboxChange(e, "B")}
              />
            }
            label={item.name === "artwork" ? "Obras de arte" : "Inventario"}
            name={item.name.toString()}
            disabled={!item[item.name]}
          />
        ))}
      </BaseModal>
      <BaseModal
        open={modalFormatC}
        onClose={() => {
          setModalFormatC(false);
        }}
        onConfirm={() => {
          const itemChecked = optionC.find((item) => item.checked === true);
          getFormat(id, "C", itemChecked.name);
        }}
        disableOnConfirm={optionC.every((value) => !value.checked)}
        title={`Elegir para que tipo de formato`}
      >
        {optionC.map((item) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={item.checked}
                onChange={(e) => handleCheckboxChange(e, "C")}
              />
            }
            label={item.name === "artwork" ? "Obras de arte" : "Inventario"}
            name={item.name.toString()}
            disabled={!item[item.name]}
          />
        ))}
      </BaseModal>
      <BaseModal
        open={modalFormatD}
        onClose={() => {
          setModalFormatD(false);
        }}
        onConfirm={() => {
          const itemChecked = optionD.find((item) => item.checked === true);
          getFormat(id, "D", itemChecked.name);
        }}
        disableOnConfirm={optionD.every((value) => !value.checked)}
        title={`Elegir para que tipo de formato`}
      >
        {optionD.map((item) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={item.checked}
                onChange={(e) => handleCheckboxChange(e, "D")}
              />
            }
            label={item.name === "artwork" ? "Obras de arte" : "Inventario"}
            name={item.name.toString()}
            disabled={!item[item.name]}
          />
        ))}
      </BaseModal>
    </>
  );
};

export default StockSurveyDetailPage;
