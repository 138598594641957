import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../_metronic/_helpers';

const ComponentsPage = () => {
	const { currentModule } = useSelector(state => state.auth)
	const { submodules } = currentModule
  const history = useHistory()
	
	return (
    <Grid container spacing={2}>
      {submodules.flatMap(sm => sm.submodules.map(ssm => ({...ssm, icon: sm.icon}))).map(sm => (
        <Grid key={sm.id} item xs={12} sm={4} md={3} lg={2}>
					<div className="card text-center">
						<div className="card-body">
							<h5 className="card-title">{sm.display_text}</h5>
							<h1>
								<span className='badge badge-pill badge-success text-light'>
									<SVG src={toAbsoluteUrl(`/media/svg/icons/${sm.icon}`)} color="white" />
								</span>
							</h1>
							<Button disableElevation variant="contained" color="secondary" onClick={() => history.push("/"+sm.slug)}>
								Ver
							</Button>
						</div>
					</div>
        </Grid>
      ))}
    </Grid>
	);
};

export default ComponentsPage;