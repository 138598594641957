import React from 'react'
import { ProgressBar } from "react-bootstrap"

const ProgressBarLabel = ({ label, progress=0, showProgress }) => {
  const color = progress < 40 ? "danger" : progress < 60 ? "warning" : "success"
  
  return (
    <div>
      <p className="mb-0">{label}</p>
      <ProgressBar
        now={progress}
        variant={color}
        srOnly={!showProgress}
        label={<span className="h6 font-weight-bold mb-0">{`${progress}%`}</span>}
        style={{ height: 36, minWidth: 170 }}
      />
    </div>
  )
}

export default ProgressBarLabel
