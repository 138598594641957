import React, { useState, useEffect, useCallback } from "react";
import TrelloBoard from "../../components/TrelloBoard";
import ToDoDetailTaskModal from "./components/ToDoDetailTaskModal";
import ToDoNewTaskModal from "./components/ToDoNewTaskModal";
import ToDoFinishedTaskModal from "./components/ToDoFinishedTaskModal";
import { trello_data, format_trello_data } from "./utils";
import {
  getTasksbyBoard,
  updateTaskState,
} from "../../crud/taskManagementCrud";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setTasksByBoard,
  setTasksForTrello,
  setUpdateFromModal,
} from "../../../redux/ducks/taskManagement.duck";
import { setLoader } from "../../../redux/ducks/loader.duck";
import notice from "../../components/Notice";

const ToDoTrelloBoard = () => {
  const queryParam = useLocation().search;
  const taskIdParam = new URLSearchParams(queryParam).get("tarea");

  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    tasks: { resultsForTrello },
    updateFromModal,
  } = useSelector((store) => store.taskManagement);
  const { user } = useSelector((store) => store.auth);
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [detailTaskModal, setDetailTaskModal] = useState(false);
  const [finishedTaskModal, setFinishedTaskModal] = useState(false);
  const [taskDetailId, setTaskDetailId] = useState(0);
  const [taskDetail, setTaskDetail] = useState({});
  const [isDeletedATask, setIsDeletedATask] = useState(false);

  useEffect(() => {
    if (taskIdParam) {
      openDetail(taskIdParam);
    }
  }, [taskIdParam]);

  const openDetail = (id) => {
    setTaskDetailId(id);
    setDetailTaskModal(true);
  };

  const fetchTasksByBoard = useCallback(() => {
    dispatch(setLoader(true));
    return getTasksbyBoard(id)
      .then((response) => {
        dispatch(setUpdateFromModal({}));
        let formatResponse = format_trello_data(response, openDetail, user.id);
        dispatch(setTasksByBoard(formatResponse));
        dispatch(
          setTasksForTrello(trello_data(formatResponse, setNewTaskModal))
        );
        return response;
      })
      .catch((e) => console.log(e))
      .finally(() => dispatch(setLoader(false)));
  }, [dispatch, id, user.id]);

  useEffect(() => {
    fetchTasksByBoard();
  }, [fetchTasksByBoard]);

  useEffect(() => {
    if (isDeletedATask) {
      fetchTasksByBoard();
    }
    setIsDeletedATask(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeletedATask, setIsDeletedATask]);

  const handleUpdateState = (id, column_state) => {
    updateTaskState(id, { column_state, blocked: column_state === 3 })
      .then((res) => {
        notice("Tarjeta actualizada", "success");
        fetchTasksByBoard(id);
      })
      .catch((e) => notice("Ha ocurrido un error al actualizar la tarjeta"));
  };

  const handleCardMode = (fromLaneId, toLaneId, cardId, index) => {
    let updatedBoard = resultsForTrello;
    let selectedCard;
    let selectedCardIx;
    let prevI = resultsForTrello.findIndex((it) => it.id === fromLaneId);

    if (prevI >= 0) {
      selectedCard = resultsForTrello[prevI].cards.find(
        (card) => card.id === cardId
      );
      selectedCardIx = resultsForTrello[prevI].cards.findIndex(
        (card) => card.id === cardId
      );
    }

    let newI = resultsForTrello.findIndex((it) => it.id === toLaneId);

    if (newI >= 0) {
      if (toLaneId === "lane3") {
        setFinishedTaskModal(true);
        setTaskDetail({
          folio: selectedCard.folio,
          folio_id: selectedCard.folio_id,
          title: selectedCard.title,
        });
        setTaskDetailId(selectedCard.id);
        updatedBoard[newI].cards.splice(index, 0, {
          ...selectedCard,
          draggable: false,
        });
        updatedBoard[prevI].cards.splice(selectedCardIx, 1);
        // handleUpdateState(selectedCard.id, 3);
      } else {
        handleUpdateState(selectedCard.id, toLaneId.substring(4));
        updatedBoard[newI].cards.splice(index, 0, {
          ...selectedCard,
          draggable: true,
        });
        updatedBoard[prevI].cards.splice(selectedCardIx, 1);
      }
    }
    dispatch(setTasksForTrello(updatedBoard));
  };

  useEffect(() => {
    if (updateFromModal.column_state === 3) {
      setDetailTaskModal(false);
      setFinishedTaskModal(true);
    }
  }, [updateFromModal, setDetailTaskModal, setFinishedTaskModal]);

  const onDragEndValidations = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    const resp = cardDetails.users?.find((us) => us.id === user.id);
    const coll = cardDetails.users_collaborators?.find(
      (us) => us.id === user.id
    );

    const inCharge1 = cardDetails.user_in_charge;
    const inCharge2 = cardDetails.user_in_charge2;
    const inChargeOf = cardDetails.users;

    if (sourceLaneId === targetLaneId) return false;
    else if (sourceLaneId === "lane4") {
      console.log(user.id);
      console.log(targetLaneId);
      if (user.id === 310 && targetLaneId === "lane3") {
        return true;
      } else {
        return false;
      }
    } else if (
      !resp &&
      !coll &&
      user.id === cardDetails.user_in_charge &&
      (targetLaneId === "lane1" || sourceLaneId === "lane1")
    ) {
      return false;
    } else if (
      !resp &&
      !coll &&
      user.id === cardDetails.user_in_charge &&
      (targetLaneId === "lane3" || sourceLaneId === "lane2")
    ) {
      return false;
    } else if (
      !resp &&
      !coll &&
      user.id === cardDetails.user_in_charge2 &&
      (sourceLaneId === "lane1" || sourceLaneId === "lane2")
    ) {
      return false;
    } else if (
      !resp &&
      !coll &&
      user.id === cardDetails.user_in_charge2 &&
      (targetLaneId === "lane1" || targetLaneId === "lane3")
    ) {
      return false;
    }

    // PREVENT NORMAL USERS
    else if (
      Boolean(inChargeOf.find((user) => user.id === inCharge1)) &&
      (targetLaneId === "lane4" || sourceLaneId === "lane3")
    ) {
      return true;
    } else if (
      Boolean(inChargeOf.find((user) => user.id === inCharge2)) &&
      (targetLaneId === "lane4" || sourceLaneId === "lane3")
    ) {
      return true;
    } else if (
      resp?.id &&
      resp?.id !== cardDetails.user_in_charge2 &&
      (targetLaneId === "lane4" || sourceLaneId === "lane3")
    ) {
      return false;
    } else if (
      coll?.id &&
      coll?.id !== cardDetails.user_in_charge &&
      (targetLaneId === "lane4" || sourceLaneId === "lane3")
    ) {
      return false;
    } else if (
      coll?.id &&
      coll?.id !== cardDetails.user_in_charge2 &&
      (targetLaneId === "lane4" || sourceLaneId === "lane3")
    ) {
      return false;
    }
  };

  return (
    <>
      <TrelloBoard
        data={resultsForTrello}
        onCardMove={handleCardMode}
        onDragEnd={onDragEndValidations}
      />
      <ToDoNewTaskModal
        open={newTaskModal}
        setOpen={setNewTaskModal}
        fetchTasksByBoard={fetchTasksByBoard}
      />
      <ToDoDetailTaskModal
        open={detailTaskModal}
        setOpen={setDetailTaskModal}
        detailId={taskDetailId}
        fetchTasksByBoard={fetchTasksByBoard}
        setIsDeletedATask={setIsDeletedATask}
      />
      <ToDoFinishedTaskModal
        open={finishedTaskModal}
        setOpen={setFinishedTaskModal}
        detailId={taskDetailId}
        detail={taskDetail}
        fetchTasksByBoard={fetchTasksByBoard}
      />
    </>
  );
};

export default ToDoTrelloBoard;
