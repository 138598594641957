import React from "react";
import SimpleCheckbox from "../../../components/SimpleCheckbox";
import CustomButton from "../../../components/CustomButton";
import UtilInputs from "../../../components/UtilInputs";
import Accordion from "../../../components/Accordion";
import notice from "../../../components/Notice";
import Chip from "../../../components/Chip";
import Icon from "../../../components/Icon";
import uuid from "react-uuid";
import { formGeneralRecurrent, formRecurrent } from "../../../utils/entranceData";
import { isAValidAmount } from "../../../utils/inputValidations";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const RecurrentMaterialAccordion = ({ i, item, handleChange, entrance }) => {
  const original_to_deliver = item.requested_amount - item.item_total_delivered_amount;

  const handleInputChange = ({ target }, index) => {
    const { name, value } = target;

    if (name === "cf_cantidad") {
      if (isAValidAmount(value)) {
        if (value <= item.requested_amount) {
          let updatedState = item;
          updatedState[name] = Number(value);
          updatedState.subtotal = Number(value) * Number(item["cf_precio_unit"]);

          handleChange(
            { target: { name: "requisition_item_details", value: updatedState } },
            i
          );
          return;
        } else notice("El número de bienes entregados no puede superar los solicitados");
      }
    } else {
      let updatedState = item;
      updatedState[name] = value;
      handleChange(
        { target: { name: "requisition_item_details", value: updatedState } },
        i
      );
    }
    return;
  };

  const handleStatus = (e, i) => {
    const { value } = e.target;
    if (value) {
      handleInputChange(
        { target: { name: "cf_cantidad", value: original_to_deliver } },
        i
      );
    } else {
      handleInputChange({ target: { name: "cf_cantidad", value: 0 } }, i);
    }

    let updatedState = item;
    updatedState["status"] = value;
    handleChange(
      { target: { name: "requisition_item_details", value: updatedState } },
      i
    );
  };

  const handleChangeAssets = ({ target }, aIndex) => {
    const { name, value } = target;
    let updatedState = item;
    updatedState["recurrent_assets"][aIndex] = {
      ...updatedState["recurrent_assets"][aIndex],
      [name]: value,
    };
    handleChange(
      { target: { name: "requisition_item_details", value: updatedState } },
      i
    );
  };

  const handleAddItem = () => {
    let updatedState = item;
    updatedState["recurrent_assets"] = [
      ...item.recurrent_assets,
      {
        id: uuid(),
        no_inventario: "",
        marca: item.articulo_data.articulo_marca,
        serie: "",
        modelo: item.articulo_data.articulo_modelo,
        descripcion: item.articulo_data.desc_amplia,
      },
    ];
    handleChange(
      { target: { name: "requisition_item_details", value: updatedState } },
      i
    );
  };

  const handleRemoveItem = (aIndex) => {
    let updatedState = item;
    updatedState["recurrent_assets"].splice(aIndex, 1);
    handleChange(
      { target: { name: "requisition_item_details", value: updatedState } },
      i
    );
  };

  return (
    <Accordion
      disableOpenHeader
      header={
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <SimpleCheckbox
              labelPos="end"
              label={
                <>
                  <h6>Clave: {item.cf_cve_articulo}</h6>
                  <span className="text-muted">{item.cf_articulo?.trim()}</span>
                </>
              }
              value={item.status}
              name="status"
              onChange={(e) => handleStatus(e, i)}
              disabled={
                entrance.entrada_cerrada ||
                item.item_total_delivered_amount === item.requirement_item_total_amount
              }
            />
            <Chip
              color={item.inventariable ? "#1BC5BD" : "#F64E60"}
              label={item.inventariable ? "Inventariable" : "No inventariable"}
              style={{ padding: "5px 10px" }}
            />
          </div>
          <div>
            {`Total de entregados ${
              isNaN(Number(item.item_total_delivered_amount))
                ? "-"
                : Number(item.item_total_delivered_amount)
            } de ${item.requirement_item_total_amount ?? "-"}`}
            <br />
            {`Entrega actual ${
              isNaN(Number(item.cf_cantidad) ?? "-")
                ? "-"
                : Number(item.cf_cantidad) ?? "-"
            }`}
          </div>
        </div>
      }
    >
      <>
        <UtilInputs
          formValues={item}
          onValuesChange={(e) => handleInputChange(e, i)}
          inputValues={formGeneralRecurrent(entrance.entrada_cerrada)}
          width={200}
        />
        {item.recurrent_assets?.map((rec, index) => (
          <div key={rec.id}>
            <hr />
            <div className="d-flex align-items-end">
              <UtilInputs
                formValues={rec}
                onValuesChange={(e) => handleChangeAssets(e, index)}
                inputValues={formRecurrent(item.inventariable, entrance.entrada_cerrada)}
                width={200}
              />
              {item.inventariable !== false && (
                <Icon
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                  label="Eliminar"
                  onClick={() => handleRemoveItem(index)}
                  disabled={entrance.entrada_cerrada}
                />
                )}
            </div>
          </div>
        ))}
        {item.inventariable && (
          <CustomButton
            color="primary"
            title="Agregar"
            onClick={handleAddItem}
            disabled={
              entrance.entrada_cerrada || item.cf_cantidad <= item.recurrent_assets.length
            }
            style={{ justifyContent: "center", margin: "20px 0" }}
          />
        )}
      </>
    </Accordion>
  );
};

export default RecurrentMaterialAccordion;
