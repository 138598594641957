import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Tooltip } from "@material-ui/core";

const CustomButton = ({ onClick=()=>{}, title="", color="secondary", outline=false, style={}, disabled=false, loading = false, className="", tooltip="", tooltipPos="top" }) => {
  if (tooltip && !disabled) return (
    <Tooltip title={tooltip} placement={tooltipPos}>
      <button className={`btn  ${ outline ? `btn-outline-${disabled ? "secondary" : color}` : `btn-${disabled ? "secondary" : color}` } mx-2 ${disabled ? "disabled" : ""} ${className}`} onClick={onClick} style={{...style, cursor: disabled && 'not-allowed' }} disabled={disabled}>
        <span>{title}</span>
        {
          loading && (
            <CircularProgress size={14} color={'primary'}/>
          )
        }
      </button>
    </Tooltip>
  )
  return (
    <button className={`btn  ${ outline ? `btn-outline-${disabled ? "secondary" : color}` : `btn-${disabled ? "secondary" : color}` } mx-2 ${disabled ? "disabled" : ""} ${className}`} onClick={onClick} style={{...style, cursor: disabled && 'not-allowed' }} disabled={disabled}>
      <span>{title}</span>
      {
        loading && (
          <CircularProgress size={14} color={'primary'}/>
        )
      }
    </button>
  );
};

CustomButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.node,
  tooltip: PropTypes.string,
  tooltipPos: PropTypes.string
}

export default CustomButton;
