import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { formatDateDDMMYYYY, inputFormatDate } from "../../../utils/dates";
import SimpleInput from "../../../components/SimpleInput";
import SimpleDate from "../../../components/SimpleDate";

export default function NewEntryFormSec({ selectedResults }) {
  //este módulo solo sirve para moostrar información, EN BUSQUEDA
  const { currentMode } = useSelector((store) => store.subHeaderActions);
  const [dataSecond, setDataSecond] = useState({
    updated_at: "",
    user: "",
    cf_usuario_anterior: "",
  });
  useEffect(() => {
    setDataSecond({
      updated_at: selectedResults ? selectedResults.updated_at : "",
      first_name: selectedResults ? selectedResults?.user?.username : "",
      cf_usuario_anterior: selectedResults ? selectedResults.cf_usuario_anterior : "",
    });
  }, [selectedResults]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ mr: 2 }}>
        <SimpleDate
          label="Fecha de actualización"
          placeholder="dd/mm/yyyy"
          name="updated_at"
          value={
            currentMode === "search"
              ? ""
              : dataSecond.updated_at
              ? dataSecond.updated_at && inputFormatDate(dataSecond.updated_at)
              : formatDateDDMMYYYY(new Date())
          }
          disabled
        />
      </Box>
      <Box sx={{ mr: 2 }}>
        <SimpleInput
          disabled
          label="Nombre de Usuario"
          placeholder="Nombre de usuario"
          name="first_name"
          width={350}
          value={dataSecond.first_name}
          inputProps={{
            style: {
              textTransform: "uppercase",
            },
            maxLength: 70,
          }}
        />
      </Box>
      {dataSecond.cf_usuario_anterior && (
        <Box sx={{ mr: 2 }}>
          <SimpleInput
            disabled
            label="Nombre de Usuario Anterior"
            placeholder="Nombre de usuario Anterior"
            name="first_name"
            width={350}
            value={dataSecond.cf_usuario_anterior}
            inputProps={{
              style: {
                textTransform: "uppercase",
              },
              maxLength: 70,
            }}
          />
        </Box>
      )}
    </Box>
  );
}
