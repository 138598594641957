import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export const ToDoBoardColumnName = (value, row, rowIndex, { onSelectBoard }) => {
  return (
    <>
      <span className={"mr-3 label label-xl label-" + row.color}>
        <span className="svg-icon svg-icon-md svg-icon-light">
          <SVG
            src={toAbsoluteUrl(row.icon)}
            onClick={() => onSelectBoard(row.id)}
            style={{ cursor: "pointer" }}
          />
        </span>
      </span>
      {value}
    </>
  );
};
