import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  generalForm,
  generalForm2,
  presentationColumns,
  variantColumns,
  variantColumns2,
} from "../../utils/articlesData";
import PageLayout from "../../components/PageLayout";
import SimpleInput from "../../components/SimpleInput";
import UtilInputs from "../../components/UtilInputs";
import ItemsList from "../../components/ItemsList";
import IconButton from "../../components/IconButton";
import TabsButton from "../../components/TabsButton";
import Icon from "../../components/Icon";
import { getSecciones } from "../../crud/catalogsCrud";
import { setLabel } from "../../../redux/ducks/subHeaderActions.duck";

const ArticlesForm = ({
  formValues,
  handleChange,
  handleChangeRow,
  addRow,
  handleRemoveItem,
  addPresentation,
  onLabelClick,
}) => {
  const { selectedArticleIds, selectedArticle } = useSelector(
    (state) => state.article
  );
  const { isSearchPage, isResultsPage, isNewPage } = useSelector(
    (store) => store.pathname
  );
  const { user } = useSelector((state) => state.auth);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [sections, setSections] = useState([]);
  const total =
    Number(formValues.precio_unitario) + Number(formValues.iva_unitario);
  useEffect(() => {
    //if (formValues.num_almacen)
    getSecciones(formValues.tipo_articulo == "STOCK" ? 2 : 0)
      .then((res) => {
        if (res.length !== 0) setSections(res);
        else {
          setSections([]);
        }
      })
      .catch(console.log);
  }, [formValues.num_almacen, formValues.tipo_articulo]);

  const handleClickTab = (id) => {
    push(`/inventario-bienes-almacen-general/articulos/resultados/${id}`);
  };

  useEffect(() => {
    if (isResultsPage && selectedArticle.cve_articulo)
      dispatch(
        setLabel(
          selectedArticle.cve_articulo === ""
            ? "Artículo sin clave"
            : `Clave de artículo ${selectedArticle.cve_articulo}`
        )
      );
  }, [dispatch, isResultsPage, selectedArticle]);

  return (
    <PageLayout
      title={
        <div className="w-100 d-flex justify-content-between align-items-center">
          <Typography variant="h6">
            {isSearchPage
              ? "Búsqueda de artículo"
              : isResultsPage
              ? "Datos de artículo"
              : "Nuevo Bien"}
          </Typography>
          {isResultsPage && (
            <Icon
              disableTooltip
              src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode.svg")}
              color="dark"
              subLabel="Etiqueta"
              onClick={onLabelClick}
              style={{
                width: 55,
                height: 50,
                margin: "0px 2px",
                fontSize: 10,
              }}
            />
          )}
        </div>
      }
      header={
        isResultsPage &&
        selectedArticleIds.length > 0 &&
        formValues.id &&
        selectedArticleIds.length > 1 && (
          <TabsButton
            list={selectedArticleIds}
            selected={formValues.id}
            handleClickTab={handleClickTab}
          />
        )
      }
    >
      <h6 className="text-muted my-3">
        Datos del bien para requerimientos y solicitudes
      </h6>
      <UtilInputs
        width={200}
        formValues={{ ...formValues, cve_usuario: user.username }}
        inputValues={generalForm(
          Boolean(formValues.id),
          sections,
          isResultsPage
        )}
        onValuesChange={handleChange}
      />
      <div className="py-5">
        <SimpleInput
          label="Descripción corta"
          name="desc_corta"
          width="100%"
          value={formValues.desc_corta}
          onChange={handleChange}
        />
        <SimpleInput
          label="Descripción amplia"
          name="desc_amplia"
          width="100%"
          value={formValues.desc_amplia}
          onChange={handleChange}
        />
      </div>
      <UtilInputs
        width={200}
        formValues={{ ...formValues, total }}
        inputValues={generalForm2}
        onValuesChange={handleChange}
      />
      {!isSearchPage && (
        <>
          {formValues.cf_variante1 && (
            <ItemsList
              items={formValues.cf_variants1}
              columns={variantColumns}
              handleChange={(column, index, e) =>
                handleChangeRow(e.target, "cf_variants1", index)
              }
              handleRemoveItem={(item) =>
                handleRemoveItem(item, "cf_variants1")
              }
            />
          )}
          {formValues.cf_variante1 && (
            <div style={{ width: 120 }}>
              <IconButton
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Plus.svg")}
                label="Agregar item"
                onClick={() => addRow("cf_variants1")}
              />
            </div>
          )}
          {formValues.cf_variante2 && (
            <ItemsList
              items={formValues.cf_variants2}
              columns={variantColumns2}
              handleChange={(column, index, e) =>
                handleChangeRow(e.target, "cf_variants2", index)
              }
              handleRemoveItem={(item) =>
                handleRemoveItem(item, "cf_variants2")
              }
            />
          )}
          {formValues.cf_variante2 && (
            <div style={{ width: 120 }}>
              <IconButton
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Plus.svg")}
                label="Agregar item"
                onClick={() => addRow("cf_variants2")}
              />
            </div>
          )}
          <h6 className="text-muted mt-5 mb-3">
            Presentaciones de los proveedores
          </h6>
          {formValues.presentaciones.map((pres, i) => (
            <div key={pres.id + i} className="d-flex align-items-center">
              <UtilInputs
                width={200}
                formValues={pres}
                inputValues={presentationColumns(Boolean(formValues.id))}
                onValuesChange={(e) =>
                  handleChangeRow(e.target, "presentaciones", i)
                }
              />
              {!formValues.id && (
                <Icon
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                  color="danger"
                  label="Eliminar"
                  onClick={() => handleRemoveItem(pres, "presentaciones")}
                />
              )}
            </div>
          ))}
          {isNewPage && (
            <div style={{ width: 180 }}>
              <IconButton
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Plus.svg")}
                label="Agregar presentación"
                onClick={addPresentation}
              />
            </div>
          )}
        </>
      )}
    </PageLayout>
  );
};

export default ArticlesForm;
