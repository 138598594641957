import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createPresentation, getSinglePresentation, updatePresentation } from '../crud/presentationsCrud'
import { setPresentation } from '../../redux/ducks/presentations.duck'
import { defaultPresentation } from '../utils/configData'
import EntryActionIcons from './EntryActionIcons'
import ModeTabs from './ModeTabs'
import notice from './Notice'

const ConfigPresentationsActions = () => {
  const { presentation, selectedPresentations } = useSelector(state => state.presentations)
  const history = useHistory()
  const dispatch = useDispatch()
  const [actionDisable, setActionDisable] = useState(false)

  const validations = () => {
    if (!presentation.articulo) notice("Es necesario ingresar un articulo a la presentación")
    else if (!presentation.descripcion) notice("Es necesario ingresar una descripción a la presentación")
    else if (!presentation.equivalencia) notice("Es necesario ingresar una equivalencia a la presentación")
    else return true
  }

  const handleSubmit = (requestFunciton) => {
    if (validations()) {
      setActionDisable(true)
      requestFunciton({...presentation, articulo: presentation.articulo.id})
      .then(res => {
        console.log(res)
        history.push("/inventario-bienes-almacen-general/configuracion/articulos/tipos-presentacion")
      })
      .catch(e => notice("Error al guardar la presentación"))
      .finally(() => setActionDisable(false))
    }
  }

  const handleResults = () => {
    if (presentation.id) {
      history.push(`/inventario-bienes-almacen-general/configuracion/articulos/tipos-presentacion/resultados/${presentation.id}`)
    } else if (selectedPresentations[0]) {
      getSinglePresentation(selectedPresentations[0])
      .then(res => {
        dispatch(setPresentation((res)))
        history.push(`/inventario-bienes-almacen-general/configuracion/articulos/tipos-presentacion/resultados/${res.id}`)
      })
      .catch(console.log)
    } else {
      notice("No se encontraron registros guardados", "info")
      onNewRegister()
    }
  }

  const onNewRegister = () => {
    history.push("/inventario-bienes-almacen-general/configuracion/articulos/tipos-presentacion/nuevo")
    if (presentation.id) dispatch(setPresentation((defaultPresentation)));
  };

  return (
    <>
     <ModeTabs onNew={onNewRegister} onResults={handleResults} showIcons={{search: false, results: true, newIcon: true}} />
      <EntryActionIcons
        showIcons={{
          save: !presentation.id,
          edit: presentation.id,
        }}
        handleSubmit={() => handleSubmit(createPresentation)}
        handleEdit={() => handleSubmit(updatePresentation)}
        disabledIcons={{
          save: actionDisable,
          edit: actionDisable,
        }}
      /> 
    </>
  )
}

export default ConfigPresentationsActions
