import React, { useState } from "react";
import {
  makeStyles,
  List,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListSubheader,
  ListItemText,
  Button,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ImageIcon from "@material-ui/icons/Image";
import DescriptionIcon from "@material-ui/icons/Description";
import AttachmentIcon from "@material-ui/icons/Attachment";
import DeleteIcon from "@material-ui/icons//Delete";
import { downLoaderWithURL } from "../utils/downloaderWithURL";
import { deleteDigitalDocumentFile } from "../crud/digitalVaultCrud";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../redux/ducks/loader.duck";
import notice from "./Notice";
import { SetInventory } from "../../redux/ducks/inventory.duck";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
  },
}));

const DocumentsList = ({ data, setListDocs, module }) => {
  const dispatch = useDispatch();
  const { selectedInventory } = useSelector((state) => state.inventory);
  const classes = useStyles();
  const typeOfDocuments = Object.getOwnPropertyNames(data);

  const downloadFile = (route, name) => {
    downLoaderWithURL(route, name);
  };

  const deleteFile = (files, id) => {
    dispatch(setLoader(true));
    let filteredFiles = {};
    deleteDigitalDocumentFile(id)
      .then((res) => {
        Object.entries(files).map((file) => {
          let filtered_files_with_format = [];
          const [format, filename_arr] = file;

          filename_arr.map((file) => {
            if (file.id !== id) {
              filtered_files_with_format.push(file);
            }
          });

          if (filtered_files_with_format.length > 0) {
            filteredFiles[format] = filtered_files_with_format;
          }
        });
        if (module !== "area") {
          dispatch(
            SetInventory({
              ...selectedInventory,
              all_attachments: filteredFiles,
            })
          );
        }
        setListDocs(filteredFiles);
        notice("Archivo eliminado con éxito", "success");
      })
      .catch((e) => notice("Imposible eliminar", "info"))
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
  const typeOfIcons = (type) => {
    switch (type) {
      case "png":
      case "jpg":
        return <ImageIcon />;
      case "pdf":
        return <PictureAsPdfIcon />;
      case "doc":
      case "xlsx":
        return <DescriptionIcon />;
      default:
        return <AttachmentIcon />;
    }
  };

  return (
    <>
      {typeOfDocuments.map((type) => (
        <List
          subheader={<ListSubheader>{type.toLocaleUpperCase()}</ListSubheader>}
          className={classes.root}
        >
          {data[type].map((attachment) => (
            <ListItem>
              <ListItemIcon>{typeOfIcons(type)}</ListItemIcon>
              {module === "area" ? (
                <ListItemText
                  primary={attachment?.title}
                  secondary={
                    <>
                      <p>{attachment?.created_at}</p>
                      <p>{attachment?.description}</p>
                    </>
                  }
                />
              ) : (
                <ListItemText
                  primary={attachment?.title}
                  secondary={attachment?.created_at}
                />
              )}
              {/* <ListItemText secondary={attachment?.description} /> */}
              <ListItemSecondaryAction>
                <Button
                  startIcon={<CloudDownloadIcon />}
                  disableElevation
                  variant="text"
                  color="primary"
                  onClick={() => downloadFile(attachment.url, attachment.title)}
                >
                  Descargar
                </Button>

                <Button
                  startIcon={<DeleteIcon />}
                  disableElevation
                  variant="text"
                  color="primary"
                  onClick={() => deleteFile(data, attachment.id)}
                >
                  Borrar
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ))}
    </>
  );
};

export default DocumentsList;
