import { axios } from "../../../../redux/setupAxios";

const URL = process.env.REACT_APP_API_URL;

export const LOGIN_URL =  URL + "/api/token/";

export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = URL + "/api/users/me/";
export const REFRESH_URL = URL + "/api/token/refresh/";

const USER_VERIFICATION = `${URL}/api/almacen-general/user-verify`;
const CREATE_USER = `${URL}/api/almacen-general/user-create`;

export function login(username, password) {
  return axios.post(LOGIN_URL, { username, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function getTokenByRefresh(refresh) {
  return axios.post(REFRESH_URL, { refresh });
}

export function validateUser(username) {
  return axios.post(USER_VERIFICATION, { username });
};

export function createUser(username, password){
  return axios.post(CREATE_USER, { username, password });
};