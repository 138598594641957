import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionType = {
  SetNotification: "[Notification] SET_NOTIFICATION",
  DeleteNotifications: "[Notification] DELETE_NOTIFICATIONS",
  ViewNotification: "[Notification] VIEW_NOTIFICATION",
};

export const initialState = {
  notifications: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: "notifications",
    whitelist: ["notifications"],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetNotification:
        return {
          ...state,
          notifications: [...state.notifications, action.payload],
        };

      case actionType.DeleteNotifications:
        return {
          ...state,
          notifications: initialState.notifications,
        };

      case actionType.ViewNotification:
        return {
          ...state,
          notifications: state.notifications.filter(
            (notification) => notification.id !== action.payload
          ),
        };

      default:
        return state;
    }
  }
);

export const setNotification = (data) => ({
  type: actionType.SetNotification,
  payload: data,
});

export const deleteNotifications = () => ({
  type: actionType.DeleteNotifications,
});

export const viewNotification = (notification) => ({
  type: actionType.ViewNotification,
  payload: notification,
});
