import { formatDate } from "./dates";

export const formatNotificationData = (data) => {
  let formatObject = {};

  formatObject["id"] = data.task_id || data.tarea.id;
  formatObject["name"] = data.task_title;
  formatObject["message"] = data.message_type;
  formatObject["status"] = data.status;
  formatObject["board_name"] = data.board_title;
  formatObject["board_id"] = data.board_id;
  formatObject["content_message"] = formatMessageType(data.message_type);
  formatObject["content_status"] = formatNotificacionTaskStatus(data.status);
  formatObject["date"] = formatDate("PPpp");

  return formatObject;
};

const formatMessageType = (messageType) => {
  switch (messageType) {
    case "tarea_status_actualizar":
      return "Tarea actualizada";
    case "tarea_agregar":
      return "Nueva tarea";
    default:
      break;
  }
};

const formatNotificacionTaskStatus = (status) => {
  switch (status) {
    case 1:
      return "por hacer";
    case 2:
      return "en proceso";
    case 3:
      return "aseguramiento de calidad";
    case 4:
      return "terminada";
    default:
      break;
  }
};
