import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

const useSubheaderAction = ({
    action,
    func=()=>{},
    path,
    isInPage=true,
}) => {
    const isLoaded = useRef(false);
    const history = useHistory();

	 useEffect(() => {
         if(isLoaded.current){
             if(!isInPage){
                path && history.push(path);
            }else if(action){
                func();
            };
        }else
             isLoaded.current = true;
             // eslint-disable-next-line
	 }, [action]);
};
export default useSubheaderAction;