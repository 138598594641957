import React, { useEffect, useCallback } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > *": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    "& .MuiPaginationItem-sizeLarge": {
      fontWeight: 500,
      padding: `0 25px`,
      height: 45,
      fontSize: '.95rem'
    },
    "& .MuiPaginationItem-ellipsis": {
      padding: `10px 25px`,
    }
  },
  textIndicator: {
    marginRight: '1.5rem'
  }
}));

const SimplePagination = ({ data, current, handleClickItem = () => {} }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);

  const dataValue = (item, data) => {
    return data[item.page - 1];
  };

  const onChangeItem = (e, value) => {
    setPage(value);
    handleClickItem(value);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyDown = useCallback((e) => {
    if ( e.ctrlKey && e.altKey && (e.key === "ArrowLeft" || e.key === "ArrowRight")) {
      if (e.key === "ArrowLeft") {
        let newPage = page - 1;
        setPage(newPage);
        handleClickItem(newPage);
      }
      if (e.key === "ArrowRight") {
        let newPage = page + 1;
        setPage(newPage);
        handleClickItem(newPage);
      }
    }
  });

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [handleKeyDown])

  return (
    <div className={classes.root}>
      <Typography className={ classes.textIndicator } variant="subtitle1">
        Registro {page} de {data.length}
      </Typography>
      <Pagination
        size="large"
        showFirstButton
        showLastButton
        onChange={onChangeItem}
        page={page}
        count={data.length}
        variant="outlined"
        color="secondary"
        renderItem={(item) => (
          <PaginationItem {...item} page={dataValue(item, data)} />
        )}
      />
    </div>
  );
};

export default SimplePagination;
