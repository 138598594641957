import { getFileExtension } from "../utils/files";
import {
  DELETE_DOCUMENT,
  DIGITAL_VAULT_URL,
  DOWNLOAD_FILES_URL,
  UPLOAD_FILE_URL,
} from "./helpers/routes";
import {
  getMultipartParams,
  getParams,
  handleError,
  handleResponse,
} from "./helpers/validate";

export const getDigitalVaultFiles = (id) => {
  // console.log("entrar");
  return fetch(`${DIGITAL_VAULT_URL}/file-attachment/${id}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const fetchDigitalVaultFolders = () => {
  return fetch(`${DIGITAL_VAULT_URL}/folders`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const fetchDigitalVaultFolder = (id) => {
  return fetch(`${DIGITAL_VAULT_URL}/folders/${id}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const fetchDigitalVaultFiles = (name = "") => {
  return fetch(`${DIGITAL_VAULT_URL}/files/?name=${name}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const deleteDigitalVaultFile = (id, typeFile) => {
  const fileName = typeFile === "folder" ? "folders" : "file";
  return fetch(`${DIGITAL_VAULT_URL}/${fileName}/${id}/`, getParams("DELETE"))
    .then(handleResponse)
    .catch(handleError);
};
export const deleteDigitalDocumentFile = (id) => {
  return fetch(`${DELETE_DOCUMENT}/${id}/`, getParams("DELETE"))
    .then(handleResponse)
    .catch(handleError);
};

export const createNewFolder = (body) => {
  return fetch(`${DIGITAL_VAULT_URL}/folders/`, getParams("POST", body))
    .then(handleResponse)
    .catch(handleError);
};

export const addDigitalVaultFiles = async (
  file = [],
  folder_id = null,
  attach_model = "file"
) => {
  let fileArr = file instanceof Array ? file[0] : file;
  const body = {
    attachments: [
      {
        id: fileArr.id,
        title: fileArr.name,
        file: fileArr,
        field: fileArr.name?.split(".").pop(),
      },
    ],
    folder_id,
    attach_model,
  };
  const formData = new FormData();

  for (const name in body) {
    const loopValue = body[name];
    if (!Array.isArray(loopValue)) formData.append(name, loopValue);
    else {
      for (let i = 0; i < loopValue.length; i++) {
        for (const subname in loopValue[i]) {
          const loopSubvalue = loopValue[i][subname];
          formData.append(`${name}[]${subname}`, loopSubvalue);
        }
      }
    }
  }

  const res = await fetch(UPLOAD_FILE_URL, getMultipartParams("POST", formData));
  const data = res.json();
  return data;

  // console.log(formData);
};

export const downloadFiles = (body) => {
  return fetch(`${DOWNLOAD_FILES_URL}/`, getParams("POST", body))
    .then((res) => res.blob())
    .then((data) => {
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.target = "_blank";
      a.click();
    })
    .catch((error) => console.log("error", error));
};

export const digitalizationWithModuleAreaAndResponsable = async (
  attach_model,
  attach_id,
  attachments = [],
  description
) => {
  const formData = new FormData();
  formData.append("attach_model", attach_model);
  formData.append("attach_id", attach_id);
  attachments.forEach((item) => formData.append("attachments[]title", item["name"]));
  attachments.forEach((item) => {
    formData.append("attachments[]file", item);
  });
  attachments.forEach((item) =>
    formData.append("attachments[]field", getFileExtension(item["name"]))
  );
  attachments.forEach((item) => formData.append("attachments[]description", description));

  return fetch(UPLOAD_FILE_URL, getMultipartParams("POST", formData))
    .then(handleResponse)
    .catch(handleError);
};
export const digitalizationWithModule = async (
  attach_model,
  attach_id,
  attachments = []
) => {
  const formData = new FormData();
  formData.append("attach_model", attach_model);
  formData.append("attach_id", attach_id);
  attachments.forEach((item) => formData.append("attachments[]title", item["name"]));
  attachments.forEach((item) => formData.append("attachments[]file", item));
  attachments.forEach((item) =>
    formData.append("attachments[]field", getFileExtension(item["name"]))
  );

  return fetch(UPLOAD_FILE_URL, getMultipartParams("POST", formData))
    .then(handleResponse)
    .catch(handleError);
};

export const moveToFolder = (fileToMoveId, folderId, typeId) => {
  const type = typeId.includes("folder") ? "folders" : "file";
  const url = `${DIGITAL_VAULT_URL}/${type}/${fileToMoveId}/`;
  return fetch(
    url,
    getParams("PATCH", {
      [type === "file" ? "folder" : "parent"]: folderId,
    })
  );
};

export const renameFoldersFile = (fileToRenameId, isFile, nameFoldersFile) => {
  const type = isFile === true ? "file" : "folders";
  const url = `${DIGITAL_VAULT_URL}/${type}/${fileToRenameId}/`;
  return fetch(
    url,
    getParams("PATCH", {
      name: nameFoldersFile,
    })
  );
};
