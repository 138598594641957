import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoader } from '../../../../redux/ducks/loader.duck'
import EntryActionIcons from '../../../components/EntryActionIcons'
import notice from '../../../components/Notice'
import { updateConfRequest } from '../../../crud/configCrud'

const ConfigRequestActions = () => {
  const dispatch = useDispatch()
  const { request } = useSelector(state => state.config)

  const handleEdit = () => {
    dispatch(setLoader(true))
    updateConfRequest(request)
    .then(res => notice("Solicitud actualizada", "success"))
    .catch(e => notice("Error realizando la operación"))
    .then(() => dispatch(setLoader(false)))
  }

  return (
    <div className='w-100 d-flex justify-content-end'>
      <EntryActionIcons
        showIcons={{
          save: false,
          edit: true,
          search:false,
        }}
        handleEdit={handleEdit}
      />
    </div>
  )
}

export default ConfigRequestActions
