import axios from "axios";
import { ASSTETS_STOCK, BASE_URL } from "./helpers/routes";

export const existenciasActual = (body) =>
  new Promise(async (resolve, reject) => {
    const response = await axios.post(
      `${ASSTETS_STOCK}/reporte-existencias-actual`,
      body
    );
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const detalleConsumos = (body) =>
  new Promise(async (resolve, reject) => {
    const response = await axios.post(
      `${ASSTETS_STOCK}/reporte-detalle-cconsumos`,
      body
    );
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const consumosGlobalPartida = (body) =>
  new Promise(async (resolve, reject) => {
    const response = await axios.post(
      `${ASSTETS_STOCK}/reporte-consumos-global-partida`,
      body
    );
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const consumosGlobalCostos = (body) =>
  new Promise(async (resolve, reject) => {
    const response = await axios.post(
      `${ASSTETS_STOCK}/reporte-consumos-global-ccostos`,
      body
    );
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const entradasGlobalPartida = (body) =>
  new Promise(async (resolve, reject) => {
    const response = await axios.post(
      `${ASSTETS_STOCK}/reporte-entradas-global-partida`,
      body
    );
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const reporteLevantamientoInventario = (body) =>
  new Promise(async (resolve, reject) => {
    const payload = {
      ...body,
      numero_levantamiento: body.numero_levantamiento.id,
    };

    const response = await axios.post(
      `${ASSTETS_STOCK}/inventory-take/reporte-inventario`,
      payload
    );

    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);

      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const getInventoryCatalog = () => {
  return axios
    .get(`${ASSTETS_STOCK}/inventory-take-catalog`)
    .then((res) => {
      if (res && res.status && res.status === 200) {
        const { data } = res;
        const options = data.levantamientos.map((levantamiento) => {
          return { value: levantamiento, text: levantamiento };
        });
        return options;
      } else return false;
    })
    .catch((e) => {
      console.log("e", e);
      return false;
    });
};

export const entradasGlobal = (body) =>
  new Promise(async (resolve, reject) => {
    const response = await axios.post(
      `${ASSTETS_STOCK}/reporte-entradas-global`,
      body
    );
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const detalleEntradas = (body) =>
  new Promise(async (resolve, reject) => {
    const response = await axios.post(
      `${ASSTETS_STOCK}/reporte-detalle-entradas`,
      body
    );
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const kardex = (body) =>
  new Promise(async (resolve, reject) => {
    const response = await axios.post(`${ASSTETS_STOCK}/kardex`, body);
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const carmat = (body) =>
  new Promise(async (resolve, reject) => {
    const response = await axios.post(`${ASSTETS_STOCK}/carmat-unl`, body);
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const salidasUnl = (body) =>
  new Promise(async (resolve, reject) => {
    const response = await axios.post(`${ASSTETS_STOCK}/salidas-unl`, body);
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const getReportAreas = (body) =>
  new Promise(async (resolve, reject) => {
    const response = await axios.post(
      `${ASSTETS_STOCK}/reporte-consumo-areas`,
      body
    );
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const getReportArticle = (body) =>
  new Promise(async (resolve, reject) => {
    const response = await axios.post(
      `${ASSTETS_STOCK}/reporte-consumo-xarticulo`,
      body
    );
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const inventarioBienes = (ids, filename, body) =>
  new Promise(async (resolve, reject) => {
    ids = ids.join(",");
    const response = await axios.get(
      `${BASE_URL}/api/bienes-instrumentales/inventory/resguardo-format?inventories_ids=${ids}&fecha_resguardo_impresion=${body.fecha_fin}`,
      body
    );
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const saldosPartidaPresupuestal = async (ids, filename) =>
  new Promise(async (resolve, reject) => {
    const body = {
      fecha_fin: ids.fecha_fin,
      formato: ids.formato,
    };

    const response = await axios.post(
      `${BASE_URL}/api/inventario-bienes-almacen-general/reporte-saldos-partida-presupuestal`,
      body
    );
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });

export const valesSolicitudes = async (ids, filename) =>
  new Promise(async (resolve, reject) => {
    const body = {
      fecha_inicio: ids.fecha_inicio,
      fecha_fin: ids.fecha_fin,
      formato: ids.formato,
    };
    const response = await axios.post(
      `${BASE_URL}/api/inventario-bienes-almacen-general/reporte-vales-solicitudes`,
      body
    );
    if (response?.status === 200 || response?.status === 201) {
      resolve(response?.data);
      //@formatter:off
    } else reject(response?.request?.statusText ?? "Error");
    //@formatter:on
  });
