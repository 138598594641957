import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import update from "immutability-helper";
import uuid from 'react-uuid';
import _ from "lodash";
import {
    CANCEL_REQUEST,
    GET_REQUEST_PDF,
    resetFieldsFilter,
    SEND_EMAIL,
    setCurrentRequestTab,
    setFilterRequest,
    setGoodQuantities,
    setNewRequest,
    setSelectedItems,
    setNewItems,
    setSelectedRequest,
    setReload,
    setSelectedDraft,
} from '../../../redux/ducks/request.duck';
import NewRequestForm from './NewRequestForm';
import BaseModal from '../../components/BaseModal';
import notice from '../../components/Notice';
import { defaultRequestFilter, materialColumns } from '../../utils/materialRequestData';
import { singleRequest, updateMaterialRequest } from '../../crud/requestCrud';
import { getColourizedLabelStatus } from '../../utils/requestUtils';

const NewRequestPage = () => {
    const { pathname } = useLocation();
    const { id } = useParams();
    const moduleAdminRoute = 'inventario-bienes-almacen-general';
    const { user } = useSelector(store => store.auth);
    const inventoryModule = user.app_modules.find(module => module.name === moduleAdminRoute);
    const requestModule = inventoryModule && inventoryModule.submodules.length > 0 ? inventoryModule.submodules.find(module => module.name === 'solicitudes') : undefined;
    const mode = !pathname.includes(moduleAdminRoute) ? "public" : requestModule ? "admin" : "public";
    const dispatch = useDispatch();
    const { selectedRequest, selectedItems, currentRequestInputsFields, isSearchTab, isResultsTab, isNewTab, filter, newRequest, newItems, selectedDraft, isDraftTab } = useSelector(store => store.request);
    const [items, setItems] = useState([]);
    const [time, setTime] = useState({});
    const [cancelModal, setCancelModal] = useState(false);

    const setRequestFormFunction = isSearchTab ? setFilterRequest : isResultsTab ? setSelectedRequest : isNewTab && setNewRequest;
    const requestForm = isDraftTab ? selectedDraft : isSearchTab ? filter : isResultsTab ? selectedRequest : isNewTab ? newRequest : defaultRequestFilter;
    const setItemsFunction = isResultsTab || isDraftTab ? setSelectedItems : isNewTab && setNewItems;
    const itemsVariable = isResultsTab || isDraftTab ? selectedItems : isNewTab ? newItems : [];

    useEffect(() => {
        if (isResultsTab && id) {
            dispatch(setReload(true));
            singleRequest(id)
                .then(res => {
                    const newSelectedRequest = {
                        ...res,
                        label_status: getColourizedLabelStatus(res.cf_estatus),
                        area: res?.area?.nom_area,
                        nom_sarea: res?.area?.nom_sarea,
                        isDraft: res.cf_estatus === "X",
                    }
                    //dispatch(setRequestsIds([]))
                    dispatch(resetFieldsFilter())
                    dispatch(setSelectedRequest(newSelectedRequest));
                    // console.log('newSelectedRequest.items', newSelectedRequest.items);
                    dispatch(
                        setItemsFunction(
                            newSelectedRequest.items.map(item => ({
                                ...item,
                                disabled: true,
                                // if cf_estatus is G (Abierta) or H (Surtiendo) then must be editable
                                actions: (mode === "public" && isNewTab) || res.cf_estatus === "X" || res.cf_estatus === "D" ? ["edit"] : ['H', 'G'].includes(requestForm.cf_estatus) && mode === "admin" ? ["edit"] : [],
                            }))
                        )
                    );
                })
                .catch(console.log)
                .finally(() => dispatch(setReload(false)));
        };
    }, [isResultsTab, dispatch, id, setItemsFunction]); // eslint-disable-line

    const clearQuantityAlert = (itemIndex) => {
        setTime(setTimeout(() => {
            const newItems = [...items];
            newItems[itemIndex] = {
                ...items[itemIndex],
                danger: false,
            };
            setItems(newItems);
        }, 1000));
    };

    const handleAddItem = id => {
        console.log('id', id)
        const itemToAdd = items.find(item => item.id === id);
        if (!itemToAdd)
            return;
        if (Number(itemToAdd.cf_cantidad_solicitada) === 0 || itemToAdd.cf_cantidad_solicitada === '') {
            const itemIndex = items.findIndex(item => item.id === id);
            let newItems = [...items];
            newItems[itemIndex] = { ...items[itemIndex], danger: true };
            setItems(newItems);
            clearQuantityAlert(itemIndex);
            return;
        };
        let newSelectedItems = [...itemsVariable];
        const isAdded = !!newSelectedItems.find(item => item.id === id);
        console.log({ isAdded });
        newSelectedItems = [
            ...newSelectedItems,
            { ...itemToAdd, actions: mode === "public" ? ['edit'] : ['edit'], disabled: true, id: uuid() },
        ];
        dispatch(setItemsFunction(newSelectedItems));
        const newItems = items.filter(item => item.id !== id);
        setItems(newItems);
    };

    const handleEditItem = (id, index) => {
        let newItems = [...itemsVariable];
        newItems[index] = { ...itemsVariable[index], disabled: false, actions: mode === "public" ? ['check'] : ['check'] }
        dispatch(setItemsFunction(newItems));
    };

    const handleDeleteItem = id => {
        const newItems = items.filter(item => item.id !== id);
        setItems(newItems);
    };

    const handleDeleteSelectedItem = id => {
        const newSelectedItems = itemsVariable.filter(item => item.id !== id);
        dispatch(setItemsFunction(newSelectedItems));
    };

    const handleCheckItem = (id, index) => {
        let newItems = [...itemsVariable];
        newItems[index] = { ...itemsVariable[index], disabled: true, actions: mode === "public" ? ['edit'] : ['edit'] }
        dispatch(setItemsFunction(newItems));
        updateItemsRequestFunction(id);
    };

    const handleQuantityChange = (e, index) => {
        if (e && e.target) {
            const { value } = e.target;
            const newItems = items.map((item, idx) => idx === index ? { ...item, cf_cantidad_solicitada: value, danger: false } : item);
            clearTimeout(time);
            setItems(newItems);
        };
    };

    const handleSelectedQuantityChange = (e, index) => {
        console.log('index', index);
        if (requestForm.cf_estatus === "B")
            return notice("No se pueden editar solicitudes canceladas", "error");
        if (e && e.target) {
            const { value, name } = e.target;
            let {
                cf_cantidad_solicitada,
                cf_cantidad_surtida,
                cf_cantidad_negada,
            } = itemsVariable[index];
            let newSelectedItems = itemsVariable;
            switch (name) {
                case 'cf_cantidad_solicitada':
                    if (Number(value) < Number(cf_cantidad_surtida)) {
                        cf_cantidad_surtida = value;
                        cf_cantidad_negada = 0
                    }
                    else
                        cf_cantidad_surtida = Number(value) - Number(cf_cantidad_negada);
                    cf_cantidad_negada = Number(value) - cf_cantidad_surtida;
                    newSelectedItems = itemsVariable.map((item, idx) => idx === index ? { ...item, [name]: value, cf_cantidad_surtida, cf_cantidad_negada } : item);
                    dispatch(setItemsFunction(newSelectedItems));
                    break;
                case 'cf_cantidad_surtida':
                    if (Number(value) > Number(cf_cantidad_solicitada))
                        return;
                    cf_cantidad_negada = Number(cf_cantidad_solicitada) - Number(value);
                    newSelectedItems = itemsVariable.map((item, idx) => idx === index ? { ...item, [name]: value, cf_cantidad_negada } : item);
                    dispatch(setItemsFunction(newSelectedItems));
                    break;

                case 'cf_cantidad_negada':
                    if (Number(value) > Number(cf_cantidad_solicitada))
                        return;
                    cf_cantidad_surtida = Number(cf_cantidad_solicitada) - Number(value);
                    newSelectedItems = itemsVariable.map((item, idx) => idx === index ? { ...item, [name]: value, cf_cantidad_surtida } : item);
                    dispatch(setItemsFunction(newSelectedItems));
                    break;

                default:
                    break;
            };
        };
    };

    const checkIfIsCompleted = itemsVariable => itemsVariable.filter(item => item.cf_cantidad_surtida.toString() !== "0" || item.cf_cantidad_negada.toString() !== "0").length === itemsVariable.length;

    const updateItemsRequestFunction = (id) => {
        if (mode !== "admin") return;
        dispatch(setReload(true));
        const { request_detail_id, cf_cantidad_surtida, cf_cantidad_negada } = itemsVariable?.find(item => item?.id === id);
        const payload = {
            cf_cantidad_surtida: Number(cf_cantidad_surtida),
            cf_cantidad_negada: Number(cf_cantidad_negada),
        };
        updateMaterialRequest(payload, request_detail_id)
            .then(res => {
                if (res.response?.data?.error)
                    return notice(res.response?.data?.error, 'error');
            }).catch(error => {
                notice('No se ha podido actualizar el material', 'error');
            }).finally(() => {
                dispatch(setReload(false));
            })
    }

    useEffect(() => {
        if (!isResultsTab)
            return;
        if (
            requestForm.cf_estatus === 'G' &&
            itemsVariable.length > 0 &&
            !!itemsVariable?.find(
                item =>
                    item.cf_cantidad_surtida?.toString() !== '0' ||
                    item.cf_cantidad_negada?.toString() !== '0'
            ) &&
            currentRequestInputsFields?.find(
                field => field.name === 'label_status'
            ).value.text !== 'Surtiendo'

        ) {
            console.log(itemsVariable);
            console.log(currentRequestInputsFields);

            const isCompleted = checkIfIsCompleted(itemsVariable);
            const label_status = { color: 'warning', text: 'Surtiendo' };
            dispatch(
                setRequestFormFunction({
                    ...requestForm,
                    label_status,
                    isCompleted,
                    cf_estatus: mode === 'public' ? requestForm.cf_estatus : 'H',
                })
            );
            return;
        } else {
            const isCompleted = checkIfIsCompleted(itemsVariable);
            dispatch(setRequestFormFunction({ ...requestForm, isCompleted }));
        };
        const {
            cf_cantidad_solicitada: requestedGoodsQuantity,
            cf_cantidad_surtida: selectedGoodsQuantity,
            cf_cantidad_negada: deniedGoods,
        } = itemsVariable.reduce((a, b) => ({
            cf_cantidad_solicitada: Number(a.cf_cantidad_solicitada) + Number(b.cf_cantidad_solicitada),
            cf_cantidad_surtida: Number(a.cf_cantidad_surtida) + Number(b.cf_cantidad_surtida),
            cf_cantidad_negada: Number(a.cf_cantidad_negada) + Number(b.cf_cantidad_negada),
        }), 0);
        const newGoodQuantities = {
            requestedGoodsQuantity,
            selectedGoodsQuantity,
            deniedGoods,
        };
        dispatch(setGoodQuantities(newGoodQuantities));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsVariable, dispatch, isResultsTab]);

    const handleForm = e => {
        if (e && e.target) {
            const { name, value } = e.target;
            if (name === 'area') {
                dispatch(isDraftTab ? setSelectedDraft({ ...requestForm, [name]: value, nom_sarea: value.nom_sarea }) : setRequestFormFunction(({ ...requestForm, [name]: value, nom_sarea: value.nom_sarea })));
                return;
            }
            dispatch(isDraftTab ? setSelectedDraft({ ...requestForm, [name]: value }) : setRequestFormFunction(({ ...requestForm, [name]: value })));
        };
    };

    const handleOption = ({ target }) => {
        const { variant_asset_id_1, variant_asset_id_2 } = target.value;
        const index = _(itemsVariable).findIndex((item) => variant_asset_id_1 ? (
            item.variant_asset_id_1 === variant_asset_id_1 && item.variant_asset_id_2 === variant_asset_id_2
        ) : item.cve_articulo === target?.value?.cve_articulo);
        if (index !== -1) {
            const sumCantidades = _.toNumber(itemsVariable[index].cf_cantidad_solicitada) + _.toNumber(target.value.cf_cantidad_solicitada);
            dispatch(setItemsFunction(update(itemsVariable, {
                [index]: { cf_cantidad_solicitada: { $set: sumCantidades } }
            })));
        } else dispatch(setItemsFunction([
            ...itemsVariable,
            {
                ...target.value,
                key: target.value.id,
                cve_articulo: target.value.cve_articulo,
                desc_corta: target.value.text,
                uni_distribuye: target.value.unit,
                cf_cantidad_solicitada: target.value.cf_cantidad_solicitada,
                cf_cantidad_surtida: 0,
                cf_cantidad_negada: 0,
                actions: ['edit'],
                id: uuid(),
                disabled: false,
            },
        ]));
    };

    const handleCancelItem = () => {
        dispatch(CANCEL_REQUEST(selectedRequest.id))
            .then(res => {
                setCancelModal(false)
                notice(res.payload.message, "success")
                handleForm({ target: { name: "cf_estatus", value: "B" } })
            })
            .catch(err => {
                console.log('err', err)
                notice("Ha ocurrido un error, favor de intentar mas tarde")
            });
    };

    const onFile = () => {
        dispatch(GET_REQUEST_PDF(selectedRequest.id))
            .then(({ payload }) => {
                if (payload?.attachment_url) window.open(payload.attachment_url);
            })
            .catch((res) => {
                notice('No se pudo cargar el PDF, contacta al administrador');
            })
        // history.push('/solicitudes/salidas');
    };

    const sendMail = () => {
        dispatch(SEND_EMAIL(selectedRequest.id))
            .then(({ payload }) => {
                handleForm({ target: { name: "cf_estatus", value: "E" } })
                notice(payload?.message || 'Se ha enviado el email', 'success');
            }).catch((error) => {
                notice('No se pudo enviar el email, contacta al administrador');
            });
    };

    useEffect(() => {
        const newTabs = pathname.includes("borrador") ?
            { isSearchTab: false, isResultsTab: false, isNewTab: false, isDraftTab: true }
            : pathname.includes('busqueda') ?
                { isSearchTab: true, isResultsTab: false, isNewTab: false, isDraftTab: false }
                : pathname.includes('nueva') ? { isSearchTab: false, isResultsTab: false, isNewTab: true, isDraftTab: false }
                    : { isSearchTab: false, isResultsTab: true, isNewTab: false, isDraftTab: false };
        dispatch(setCurrentRequestTab(newTabs));
    }, [pathname, dispatch]);

    return (
        <>
            <NewRequestForm
                materialColumns={materialColumns(mode)}
                materialData={items}
                selectedMaterialData={itemsVariable}
                onAddItem={handleAddItem}
                onQuantityChange={handleQuantityChange}
                onSelectedQuantitiesChange={handleSelectedQuantityChange}
                form={requestForm}
                handleForm={handleForm}
                onSave={() => { }}
                onEditItem={handleEditItem}
                onDeleteItem={handleDeleteItem}
                onDeleteSelectedItem={handleDeleteSelectedItem}
                onCheckItem={handleCheckItem}
                handleOption={handleOption}
                onSaveDraft={() => { }}
                onCancel={setCancelModal}
                onFile={onFile}
                onEmail={sendMail}
            />
            {/* {reload && <SplashLoader/> } */}
            <BaseModal
                open={cancelModal}
                title='¿Cancelar solicitud?'
                onClose={() => setCancelModal(false)}
                onCancel={() => setCancelModal(false)}
                onConfirm={handleCancelItem}
                confirmLabel='Si, cancelar la solicitud'
                cancelLabel='No'
                maxWidth='sm'
                color='secondary'
            ><p>¿Estás seguro de que deseas cancelar la solicitud</p></BaseModal>
        </>
    );
};

export default NewRequestPage;
