import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setClearState, setVariant } from '../../../../redux/ducks/variants.duck'
import { getSingleVariant } from '../../../crud/variantsCrud'
import notice from '../../../components/Notice'
import PageLayout from '../../../components/PageLayout'
import TabsButton from '../../../components/TabsButton'
import UtilInputs from '../../../components/UtilInputs'
import { variantsForm } from '../../../utils/configData'

const ConfigVariantDetail = () => {
  const { variant, selectedVariants } = useSelector(state => state.variants)
  const dispatch = useDispatch()
  const history = useHistory()

  const onValuesChange = (e) => {
    if(e && e.target) {
        const { value, name } = e.target;
        
        dispatch(setVariant({...variant, [name]: value}))
    };
  };

  const handleClickTab = (id) => {
    getSingleVariant(id)
      .then(res => {
        dispatch(setVariant(res))
        history.push(`/inventario-bienes-almacen-general/configuracion/articulos/variantes/resultados/${id}`)
      }).catch(e => {
        dispatch(setClearState())
        notice("Error al mostrar la variante")
        history.push('/inventario-bienes-almacen-general/configuracion/articulos/variantes/nuevo')
      })
  }

  return (
    <PageLayout
    minHeight="100%"
    title={variant.id ? "Variante" : "Nueva variante"}
    header={
      selectedVariants.length > 1 && <TabsButton
        list={selectedVariants} selected={variant.id} handleClickTab={handleClickTab}
      />
    }
    >
      <UtilInputs
        inputValues={variantsForm}
        formValues={variant}
        onValuesChange={onValuesChange}
      />
    </PageLayout>
  )
}

export default ConfigVariantDetail
