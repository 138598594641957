import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RequestMaterialList from '../../components/RequestMaterialList';
import CardColorContainer from '../../components/CardColorContainer';
import PDFUploaderTab from "../../components/PDFUploaderTab";
import IconActions from '../../components/IconActions';
import SimpleInput from '../../components/SimpleInput';
import UtilInputs from '../../components/UtilInputs';
import TabsButton from '../../components/TabsButton';
import PageLayout from '../../components/PageLayout'
import Subtitle from "../../components/Subtitle";
import notice from '../../components/Notice';
import { formInputs, material_columns, collectInformationFormInputs, iconsActions, departureFilterInputs } from '../../utils/departureData';
import { setDeparture, setSearchDeparture } from '../../../redux/ducks/departure.duck';
import { GET_REQUEST_PDF } from '../../../redux/ducks/request.duck';
import { cancelDispatch, departureRevert, singleReqDeparture } from '../../crud/departuresCrud';
import { setLoader } from '../../../redux/ducks/loader.duck';
import CustomButton from '../../components/CustomButton';
import BaseModal from '../../components/BaseModal';

const NewDeparturePage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { departure, selectedIds, searchDeparture } = useSelector(state => state.departure)
  const { isSearchPage } = useSelector(store => store.pathname);
  const [statusAlert, setStatusAlert] = useState(false)

  const REQUIRED_ITEMS = departure.warehouse_request_details.reduce((acc, x) => acc + x.cf_cantidad_solicitada, 0)
  const DELIVERED_ITEMS = departure.warehouse_request_details.reduce((acc, x) => acc + x.cf_cantidad_surtida, 0)
  const DENIED_ITEMS  = departure.warehouse_request_details.reduce((acc, x) => acc + x.cf_cantidad_negada, 0)

  const cardItems = [
    { title: "Bienes requeridos", value: REQUIRED_ITEMS, icon: '/media/svg/icons/Shopping/Box2.svg', color: "primary" },
    { title: "Bienes entregados", value: DELIVERED_ITEMS, icon: '/media/svg/icons/Shopping/Cart1.svg', color: "success" },
    { title: "Bienes negados", value: DENIED_ITEMS, icon: '/media/svg/icons/Code/Stop.svg', color: "danger" },
  ]

  const handleForm = e => {
		const { name, value } = e.target;
		dispatch(isSearchPage ? setSearchDeparture({...searchDeparture, [name]: value}) : setDeparture({ ...departure, [name]: value }));
	};

  const handleClickTab = (id) => {
    dispatch(setLoader(true))
    singleReqDeparture(id)
    .then(res => {
      dispatch(setDeparture(res))
      history.push(`/inventario-bienes-almacen-general/salidas/resultados/${id}`)
    })
    .catch(console.log)
    .finally(() => dispatch(setLoader(false)))
  };

  const onSuccessUploadPDFs = responses => {
    const newPDFAttachments = responses.map(res => res.data);
    const newData = { ...departure, all_attachments: { ...departure.all_attachments, pdfs: [...departure.all_attachments.pdfs, ...newPDFAttachments] } };
    dispatch(setDeparture(newData));
  }


  const onSuccessRemovePDFs = deletedId => {
    const newPDFAttachments = departure.all_attachments.pdfs.filter(attachment => attachment.id !== deletedId);
    const newData = { ...departure, all_attachments: { ...departure.all_attachments, pdfs: newPDFAttachments } };
    dispatch(setDeparture(newData));
  };

  const onFile = () => {
    dispatch(GET_REQUEST_PDF(departure.id))
      .then(({ payload }) => {
        if (payload?.attachment_url) window.open(payload.attachment_url);
      })
      .catch((res) => {
        notice('No se pudo cargar el PDF, contacta al administrador');
      })
  // history.push('/solicitudes/salidas');
  };

  const changeDepartureStatus = request => {
    dispatch(setLoader(true))
    request(departure.id)
    .then(res => {
      notice(res.message, "success")
      history.push(`/inventario-bienes-almacen-general/solicitudes/resultados/${departure.id}`)
      .then(res => dispatch(setDeparture(res)))
      .catch(console.log)
    })
    .catch(console.log)
    .finally(() => {
      dispatch(setLoader(false))
      setStatusAlert(false)
    })
  }
  
  return (
    <>
    <PageLayout
      title={<div className="d-flex justify-content-between">
        <div className="d-flex">
          {isSearchPage && "Busqueda de"} {`${!departure.id ? "Nueva " : ""}Salida`}
          {!isSearchPage && <IconActions
            icons={iconsActions(onFile)}
          />}
        </div>
        {(departure.cf_estatus === "T" || departure.cf_estatus === "B") && 
        <CustomButton
          color='primary'
          title={`Cambiar estado de ${departure.cf_estatus === "T" ? "entregada" : "cancelada"} a despachada`}
          onClick={() => setStatusAlert(true)}
        />}
      </div>
      }
      header={departure.id && <>
      {selectedIds.length > 1 && (
        <TabsButton
          list={selectedIds}
          selected={departure.id}
          handleClickTab={handleClickTab}
        />)}
      </>}
    >
      {!isSearchPage && <div className="d-flex justify-content-between flex-column">
        <Subtitle
          title='Datos de salida'
          padding='10px 0px 20px 0px'
        />
      </div>}
      <UtilInputs
        width={250}
        formValues={isSearchPage ? searchDeparture : departure}
        onValuesChange={handleForm}
        inputValues={isSearchPage ? departureFilterInputs : formInputs(Boolean(departure.id), departure.cf_estatus)}
      />
      {!isSearchPage && <>
      <div className="d-flex flex-column">
        <Subtitle
          title='Información de recolección'
          padding='10px 0px 20px 0px'
        />
        <UtilInputs
          width={250}
          formValues={departure}
          onValuesChange={handleForm}
          inputValues={collectInformationFormInputs(Boolean(departure.id))}
        />
        <SimpleInput
          label='Observaciones'
          placeholder='Observaciones'
          name='observaciones'
          onChange={handleForm}
          width="100%"
          value={departure.observaciones}
          multiline={true}
          rows={4}
          disabled={Boolean(departure.id)}
        />
      </div>
      <div className="my-5">
        <Subtitle
          title='Información de bienes requeridos'
          padding='10px 0px 20px 0px'
        />
        <CardColorContainer cards={cardItems} />
      </div>
      <Typography variant='h6' className="text-muted">
        Artículos
      </Typography>
      <RequestMaterialList
        columns={material_columns}
        items={departure.warehouse_request_details}
        disabled={false}
			/>
      <div className='pt-10'>
        <PDFUploaderTab
          field='pdfs'
          id={departure.id}
          attach_model='warehouse_request'
          attachment_pdfs={departure.attachment_pdfs}
          pdfTitleName='la salida'
          onSuccessUpload={onSuccessUploadPDFs}
          onSucessRemove={onSuccessRemovePDFs}
        />
      </div>
      </>}
    </PageLayout>
    <BaseModal
      open={statusAlert}
      onClose={() => setStatusAlert(false)}
      onCancel={() => setStatusAlert(false)}
      onConfirm={() => changeDepartureStatus(departure.cf_estatus === "T" ? departureRevert : cancelDispatch)}
      title={`Estas seguro que deseas cambiar el estado de la solicitud de ${departure.cf_estatus === "T" ? "ENTREGADA a DESPACHADA" : "CANCELADA a DESPACHADA"}`}
    />
    </>
  )
}

export default NewDeparturePage
