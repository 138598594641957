import React, { useMemo, useState, useEffect } from "react";
import { Badge, makeStyles, Paper, Typography, useMediaQuery } from "@material-ui/core";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SVG from "react-inlinesvg";
import "jspdf-autotable";
import CustomButton from "./CustomButton";
import UtilInputs from "./UtilInputs";
import FilterResults from "./FilterResults";
import TableLoader from "./TableLoader";
import { Pagination } from "../../_metronic/_partials/controls";
import * as uiHelpers from "../utils/columnFormattersHelpers";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { useLocation } from "react-router-dom";

import "./styles/_fullTable.scss";

const useStyles = makeStyles({
  headerRight: {
    alignItems: "center",
    display: "flex",
    flexDirection: ({ isMobile }) => isMobile && "column",
    "&>*": {
      margin: ({ isMobile }) => (isMobile ? "0px 0px 5px 0px" : "0px 0px 0px 15px"),
    },
    //margin: ({ isMobile }) => isMobile ? '-25px 20px auto auto' : 'auto 0px auto auto'
  },
  table: {
    marginTop: 15,
  },
  divider: {
    margin: "10px 0px",
  },
});

const FullTable = ({
  title = "",
  subtitle = "",
  onClickSearch = () => {},
  onClickButton = () => {},
  formFilters = {},
  filterInputs = [],
  onFilterChange = () => {},
  onConfirmFilter = () => {
    alert("Filters button");
  },
  onClearFilter = () => {
    alert("Clear filters");
  },
  entities = [],
  columns = [],
  page = 1,
  setPage = () => {},
  sizePerPage = 5,
  setSizePerPage = () => {},
  count = 0,
  buttonTitle = "",
  search_button = true,
  buttonDisabled = false,
  badgeContent = [],
  loading,
  enableRedBackground = false,
  has_button = true,
  onKeyDown = () => {},
  onSelectRow = () => {},
  has_button2 = false,
  buttonDisabled2 = true,
  buttonTitle2 = "",
  onClickButton2 = () => {},
  onSelectAllRows = () => {},
  nonSelectableRows = [],
  hideCheckbox = false,
  has_button3 = false,
  buttonTitle3 = "",
  onClickButton3 = () => {},
  buttonDisabled3 = false,
  has_filter = false,
  hide_pagination = false,
  Permisos = [],
  rowEvents = {},
  selectedRows = false,
  containerClassName = "",
  isMonitoring = false,
  open_filter = false,
}) => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [filterSelected, setFilterSelected] = useState(false);
  const [sortBy, setSortBy] = useState({});
  useEffect(() => {
    if (open_filter) {
      setFilterSelected(open_filter);
    }
  }, [open_filter]);
  const [queryParams, setQueryParams] = useState({
    filter: {
      model: "",
      manufacture: "",
      VINCode: "",
    },
    sortOrder: "asc", // asc||desc
    sortField: "VINCode",
    pageNumber: 1,
    pageSize: 10,
  });
  const [ids, setIds] = useState([]);
  const productsUIProps = useMemo(() => {
    return {
      ids: ids,
      setIds: setIds,
      queryParams: queryParams,
      setQueryParams: setQueryParams,
      openEditProductPage: () => console.log("edit product page"),
      openDeleteProductDialog: () => console.log("delete product dialog"),
    };
  }, [ids, setIds, queryParams, setQueryParams]);
  const classes = useStyles({ isMobile });
  const [csvFileName] = useState("reporte.xlsx");

  const getHandlerTableChange = (setQueryParams) => {
    if (pathname.includes("boveda-digital")) {
      return (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        let sortCol = {};
        if (type === "sort") {
          if (sortBy[sortField]) {
            sortCol = {
              ...sortBy,
              [sortField]: {
                ...sortBy[sortField],
                order: sortBy[sortField].order === "asc" ? "desc" : "asc",
              },
            };
            setSortBy(sortCol);
          } else {
            const sortObj = {
              dataField: sortField,
              order: sortOrder,
            };
            sortCol = { ...sortBy, [sortField]: sortObj };
            setSortBy(sortCol);
          }
          let result;
          for (const config in sortCol) {
            const dataField = sortCol[config].dataField;
            if (sortCol[config].order === "asc") {
              result = data.sort((a, b) => {
                if (a[dataField] > b[dataField]) {
                  return 1;
                } else if (b[dataField] > a[dataField]) {
                  return -1;
                }
                return 0;
              });
            } else {
              result = data.sort((a, b) => {
                if (a[dataField] > b[dataField]) {
                  return -1;
                } else if (b[dataField] > a[dataField]) {
                  return 1;
                }
                return 0;
              });
            }
          }
        }
      };
    } else {
      return (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        const pageNumber = page || 1;
        setQueryParams((prev) =>
          type === "sort"
            ? { ...prev, sortOrder, sortField }
            : type === "pagination"
            ? { ...prev, pageNumber, pageSize: sizePerPage }
            : prev
        );
      };
    }
  };

  const paginationOptions = {
    custom: true,
    totalSize: count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage,
    page,
    onPageChange: setPage,
    onSizePerPageChange: setSizePerPage,
    checkboxEnabled: false,
  };

  const selectedRowsProp = selectedRows ? { selected: selectedRows } : {};
  return (
    <Paper className={`h-100 p-5 ${containerClassName}`} style={{ overflow: "auto" }}>
      {filterSelected && (
        <div>
          <form autoComplete="off" onKeyDown={(e) => e.key === "Enter" && onKeyDown()}>
            <UtilInputs
              width={150}
              formValues={formFilters}
              onValuesChange={onFilterChange}
              inputValues={filterInputs}
            />
          </form>
          <div className="mt-6">
            <FilterResults
              findingsNumber={count}
              onClickSeeButton={onConfirmFilter}
              onClickClearButton={onClearFilter}
            />
          </div>
        </div>
      )}
      {loading ? (
        <TableLoader />
      ) : (
        <ToolkitProvider
          keyField="id"
          data={
            entities === null
              ? []
              : entities.map((x) => {
                  let newData = { ...x };
                  Object.entries(newData).forEach(([key, value]) => {
                    // New entry page
                    if (key === "is_closed") {
                      newData.status = value ? "Cerrada" : "Abierta";
                    } else if (key === "datos_resguardos") {
                      // Needed for artwork table
                      newData.datos_resguardos = newData.datos_resguardos?.responsable;
                    } else if (
                      ["marca_nombre", "smarca_nombre", "type_nombre"].includes(key)
                    ) {
                      switch (key) {
                        // Needed in vehicles page
                        case "marca_nombre":
                          newData.marca_nombre = newData.marca_nombre?.desc_marca;
                          break;
                        case "smarca_nombre":
                          newData.smarca_nombre = newData.smarca_nombre?.desc_smarca;
                          break;
                        case "type_nombre":
                          newData.type_nombre = newData.type_nombre?.desc_tipov;
                          break;
                        default:
                          break;
                      }
                    } else if (value === null || value === undefined) {
                      // Cleans all value and undefined values
                      newData[key] = "";
                    } else if (key === "warehouse_request_details") {
                      newData.warehouse_request_details = newData.warehouse_request_details
                        ?.map((x) => x.asset_category?.desc_corta)
                        .join(", ");
                    }
                    if (key === "stock_max") {
                      console.log("key", key, "value", value);
                    }
                  });
                  return newData;
                })
          }
          columns={columns}
          exportCSV={{
            fileName: csvFileName,
          }}
        >
          {(toolkitProps) => {
            delete toolkitProps.baseProps.data;
            return (
              <>
                {(title ||
                  subtitle ||
                  has_filter ||
                  has_button ||
                  has_button2 ||
                  has_button3) && (
                  <div className="d-flex justify-content-between flex-wrap">
                    {title && (
                      <Typography
                        variant="h5"
                        style={{
                          flex: 1,
                          display: "flex",
                          margin: "auto auto auto 25px",
                        }}
                      >
                        {title}
                      </Typography>
                    )}
                    {subtitle && (
                      <Typography
                        variant="subtitle2"
                        style={{
                          flex: 1,
                          display: "flex",
                          margin: "auto auto auto 5px",
                        }}
                      >
                        {subtitle}
                      </Typography>
                    )}
                    <div className={classes.headerRight}>
                      {has_filter && (
                        <CustomButton
                          color="info"
                          title={
                            <span className="svg-icon svg-icon-md svg-icon-hover-info">
                              <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")}
                              />
                              Filtrar tabla
                            </span>
                          }
                          onClick={() => setFilterSelected(!filterSelected)}
                        />
                      )}
                      {search_button && (
                        <CustomButton
                          color="info"
                          title="Busqueda compuesta"
                          onClick={onClickSearch}
                        />
                      )}
                      {pathname === "/bienes-instrumentales/entradas"
                        ? Permisos.includes("i-crear-entrada") === true &&
                          has_button && (
                            <Badge badgeContent={badgeContent.length} color="error">
                              <CustomButton
                                title={buttonTitle}
                                onClick={onClickButton}
                                disabled={buttonDisabled}
                                style={{
                                  minWidth: isMobile && 125,
                                  justifyContent: "center",
                                }}
                                color="primary"
                              />
                            </Badge>
                          )
                        : has_button && (
                            <Badge badgeContent={badgeContent.length} color="error">
                              <CustomButton
                                title={buttonTitle}
                                onClick={onClickButton}
                                disabled={buttonDisabled}
                                style={{
                                  minWidth: isMobile && 125,
                                  justifyContent: "center",
                                }}
                                color="primary"
                              />
                            </Badge>
                          )}
                      {has_button2 && (
                        <CustomButton
                          color="primary"
                          title={buttonTitle2}
                          onClick={onClickButton2}
                          disabled={buttonDisabled2}
                          style={{ minWidth: isMobile && 125 }}
                        />
                      )}
                      {has_button3 && (
                        <CustomButton
                          color="success"
                          title={buttonTitle3}
                          onClick={onClickButton3}
                          disabled={buttonDisabled3}
                          style={{ minWidth: isMobile && 125 }}
                        />
                      )}
                    </div>
                  </div>
                )}
                {(title || has_filter || has_button || has_button2 || has_button3) && (
                  <hr />
                )}
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                  {({ paginationProps, paginationTableProps }) => {
                    //delete toolkitProps.baseProps.data;
                    return (
                      <Pagination
                        isLoading={false}
                        paginationTableProps={paginationTableProps}
                        paginationProps={paginationProps}
                        show={!hide_pagination}
                      >
                        <BootstrapTable
                          remote
                          bootstrap4
                          bordered={false}
                          wrapperClasses="table-responsive"
                          classes="table table-head-custom table-vertical-center"
                          headerClasses="tableHeader"
                          bodyClasses="tableBody text-uppercase"
                          data={entities === null ? [] : entities}
                          columns={columns}
                          defaultSorted={uiHelpers.defaultSorted}
                          onTableChange={getHandlerTableChange(
                            productsUIProps.setQueryParams
                          )}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          checkboxEnabled={false}
                          checkbox={false}
                          rowStyle={(row, index) =>
                            row.isDraft
                              ? { backgroundColor: "#fceced" }
                              : enableRedBackground &&
                                (row.box || row.cf_fecha_resguardo === null)
                              ? { backgroundColor: "#fceced" }
                              : isMonitoring
                              ? { fontSize: 16, fontWeight: 500 }
                              : {}
                          }
                          formatExtraData={console.log}
                          selectRow={{
                            mode: "checkbox",
                            onSelect: onSelectRow,
                            onSelectAll: onSelectAllRows,
                            nonSelectable: nonSelectableRows,
                            hideSelectAll: hideCheckbox,
                            hideSelectColumn: hideCheckbox,
                            ...selectedRowsProp,
                          }}
                          rowEvents={rowEvents}
                          noDataIndication={"Sin resultados en la búsqueda"}
                        />
                      </Pagination>
                    );
                  }}
                </PaginationProvider>
              </>
            );
          }}
        </ToolkitProvider>
      )}
    </Paper>
  );
};

export default FullTable;
