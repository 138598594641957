import React from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Badge,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
  withStyles,
  Divider,
  Avatar,
  Button,
  ListItemSecondaryAction,
  Box,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentIcon from "@material-ui/icons/Assignment";
import NotificationsNoneRoundedIcon from "@material-ui/icons/NotificationsNoneRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNotifications,
  viewNotification,
} from "../../redux/ducks/notification.duck";
import { useHistory } from "react-router-dom";

const ITEM_HEIGHT = 68;

const CustomMenu = withStyles({
  paper: {
    maxHeight: ITEM_HEIGHT * 4.5,
    width: "60ch",
  },
})((props) => (
  <Menu
    elevation={2}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const CustomMenuItem = withStyles()(MenuItem);

export default function Notifications() {
  const useStyles = makeStyles((theme) => ({
    iconButtonMargin: {
      margin: "0 0.3rem",
    },
    title: {
      padding: theme.spacing(2),
    },
    lowPriority: {
      color: theme.palette.common.white,
      backgroundColor: "#0dcaf0",
    },
    mediumPriority: {
      color: theme.palette.common.white,
      backgroundColor: "#ffc107",
    },
    highPriority: {
      color: theme.palette.common.white,
      backgroundColor: "#dc3545",
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { notifications } = useSelector((state) => state.notifications);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleToggle = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const onClickViewNotification = (notification, board) => {
    setAnchorEl(null);
    dispatch(viewNotification(notification));
    history.push(
      `/administrador-tareas/tableros/columnas/${board}?tarea=${notification}`
    );
  };

  return (
    <div>
      <IconButton
        aria-haspopup="true"
        color="primary"
        onClick={handleToggle}
        className={classes.iconButtonMargin}
      >
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsNoneRoundedIcon />
        </Badge>
      </IconButton>
      <CustomMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="subtitle1" className={classes.title}>
            Notificaciones
          </Typography>
          {notifications.length > 0 && (
            <Tooltip title="Eliminar notificaciones">
              <IconButton onClick={() => dispatch(deleteNotifications())}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Divider />
        {notifications.length > 0 ? (
          notifications.map((notification, key) => (
            <CustomMenuItem key={key}>
              <ListItemIcon>
                <Avatar className={classes.lowPriority}>
                  <AssignmentIcon fontSize="small" />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Tooltip
                    title={` ${notification.content_message} a ${notification.content_status} `}
                  >
                    <Typography noWrap>
                      {notification.name} se movió a{" "}
                      {notification.content_status}
                    </Typography>
                  </Tooltip>
                }
                secondary={`${notification.content_message} en ${notification.board_name}. ${notification.date}`}
              />
              <ListItemSecondaryAction>
                <Button
                  onClick={() =>
                    onClickViewNotification(
                      notification.id,
                      notification.board_id
                    )
                  }
                  color="secondary"
                >
                  Ver más
                </Button>
              </ListItemSecondaryAction>
            </CustomMenuItem>
          ))
        ) : (
          <Typography className={classes.title} variant="body2" color="error">
            No tienes notificaciones
          </Typography>
        )}
      </CustomMenu>
    </div>
  );
}
