import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import axios from "axios";
import ReportContent from "../../components/Reports";
import BaseModal from "../../components/BaseModal";
import notice from "../../components/Notice";
import Labels from "../../components/Labels";
import { setLoader } from "../../../redux/ducks/loader.duck";
import {
  setSearchStockForm,
  setSelectedStock,
  setSelectedStocks,
  setIdsFilterResult,
  clearFieldsToUpdate,
  clearStockFormDataAvailableToCopy,
  setStockFieldsToUpdate,
} from "../../../redux/ducks/stock.duck";
import {
  setCurrentMode,
  setIsEditDisabled,
  setLabel,
  setOnFormatGuardClick,
  setOnGetFormatA,
  setOnGetFormatB,
  setOnGetFormatC,
  setOnGetFormatD,
  setOnLabelClick,
  setOnReportClickFunction,
} from "../../../redux/ducks/subHeaderActions.duck";
import {
  fetchFormat,
  fetchGuardFormat,
  fetchStock,
  searchInvetoryFilter,
  postZpl,
  stockReports,
  updateMultipleStockFields,
  unSuscribeId,
  searchDropedInvetoryFilter,
  getTipoBaja,
  unSuscribeReason,
} from "../../crud/stockCrud";
import {
  getFixedInventoryFromRequest,
  getFixedSingleInventoryFromRequest,
  getFixedSingleDropInventoryFromRequest,
  initialStockValues,
} from "../../utils/stockData";
import {
  cleanObjectWithZero,
  clearEmptyStringsObject,
} from "../../utils/objectsFunctions";
import { downLoaderWithURL } from "../../utils/downloaderWithURL";
import useSubheaderAction from "../../hooks/useSubheaderAction";
import { getKeyData } from "../../utils/cabmsAndKey";
import { getTodayDate } from "../../utils/dates";
import { StockForm } from "./StockForm";
import { BASE_URL } from "../../crud/helpers/routes";
import { priceToDouble } from "../../utils/inputValidations";
import UtilInputs from "../../components/UtilInputs";
import moment from "moment";
moment.locale("es");
const StockDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    currentMode,
    unSuscribe,
    onSearchClick2,
    onCancelClick2,
    onCancelClick3,
    onEditClick2,
    onSaveClick2,
    onSearchClick3,
    onReportClick,
    onLabelClick,
    onFormatGuardClick,
    onGetFormatAClick,
    onGetFormatBClick,
    onGetFormatCClick,
    onGetFormatDClick,
  } = useSelector((store) => store.subHeaderActions);
  const {
    selectedStocks,
    selectedStockForm,
    searchStockForm,
    idsFilterResult,
    stockFieldsToUpdate,
  } = useSelector((store) => store.stock);
  const { isResultsPage, isSearchPage, isDetailsPage } = useSelector(
    (store) => store.pathname
  );
  const { authToken, user } = useSelector((store) => store.auth);
  const [originalStock, setOriginalStock] = useState(selectedStockForm);
  const [printLabel, setPrintLabel] = useState(false);
  const [reportsModal, setReportsModal] = useState(false);
  const [openBajaModal, setOpenBajaModal] = useState(false);
  const [bajaForm, setBajaForm] = useState({});

  const [tipoBajaList, setTipoBajaList] = useState([]);

  const [printConfiguration, setPrintConfiguration] = useState("current");

  const isDropPage = pathname.includes("bajas");

  const getStockBaja = () => {
    if (isResultsPage && id) {
      dispatch(setLoader(true));
      fetchStock(id, isDropPage)
        .then((res) => {
          let newSelectedStock = isDropPage
            ? getFixedSingleDropInventoryFromRequest(res.data)
            : getFixedSingleInventoryFromRequest(res.data);
          dispatch(
            setSelectedStock({
              ...newSelectedStock,
              no_inventa: `I - ${id}`,
              username: `${newSelectedStock?.changed_by?.first_name} ${newSelectedStock?.changed_by?.last_name}`,
            })
          );
          dispatch(setLabel(`No. Inventario I - ${id}`));
          setOriginalStock({
            ...newSelectedStock,
            no_inventa: `I - ${id}`,
            username: `${user?.first_name} ${user?.last_name}`,
          });
          dispatch(
            setStockFieldsToUpdate({
              ...stockFieldsToUpdate,
              [id]: {
                ...stockFieldsToUpdate[id],
                cabms_grupo: newSelectedStock.cabms_grupo,
                cabms_subgrupo: newSelectedStock.cabms_subgrupo,
                cabms_clase: newSelectedStock.cabms_clase,
                cabms_subclase: newSelectedStock.cabms_subclase,
                cabms_tipo: newSelectedStock.cabms_tipo,
              },
            })
          );
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => dispatch(setLoader(false)));
    }
    getTipoBaja().then((res) => {
      const listTipoBaja = res.results.map((tipo) => ({
        value: tipo.id,
        text: tipo.nombre,
      }));
      setTipoBajaList(listTipoBaja);
    });
  };
  useEffect(() => {
    getStockBaja();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, isResultsPage, selectedStocks, user?.first_name, user?.last_name]);

  const handleSearchDropedInventory = () => {
    dispatch(setLoader(true));
    dispatch(clearStockFormDataAvailableToCopy());
    let key = searchStockForm.cabms_item?.text2
      ? getKeyData(searchStockForm.cabms_item?.text2)
      : null;
    delete searchStockForm.cf_conciliado;
    /*  const filter_params = filterParams(
      clearEmptyStringsObject(cleanObject({ ...searchStockForm, ...key }))
    );*/
    const filter_params = clearEmptyStringsObject(
      cleanObjectWithZero({ ...searchStockForm, ...key })
    );
    if (
      searchStockForm.cf_responsables !== "" &&
      searchStockForm.cf_responsables.length > 0
    ) {
      let responsables = [];
      const cf_responsables = searchStockForm.cf_responsables.map((responsable) =>
        responsables.push(responsable.value)
      );
      filter_params["custom_responsables"] = responsables;
    }

    if (searchStockForm.cf_cve_prove !== "" && searchStockForm.cf_cve_prove.length > 0) {
      let proveedores = "";
      let arrProveedores = [];

      searchStockForm.cf_cve_prove.map((proveedor) =>
        arrProveedores.push(proveedor.value)
      );

      proveedores = arrProveedores.join("|");
      filter_params["cf_cve_prove"] = proveedores;
    }

    if (searchStockForm.cve_partida !== "" && searchStockForm.cve_partida.length > 0) {
      let partidas = "";
      let arrPartidas = [];
      searchStockForm.cve_partida.map((partida) => arrPartidas.push(partida.value));

      partidas = arrPartidas.join("|");
      filter_params["cve_partida"] = partidas;
    }

    if (
      searchStockForm.relation_area_edificio__cve_edificio !== "" &&
      searchStockForm.relation_area_edificio__cve_edificio.length > 0
    ) {
      let edificios = "";
      let arrEdificios = [];

      searchStockForm.relation_area_edificio__cve_edificio.map((edificio) =>
        arrEdificios.push(edificio.value)
      );

      edificios = arrEdificios.join("|");
      filter_params["relation_area_edificio__cve_edificio"] = edificios;
    }

    if (filter_params?.asset_acquisition_fecha_actualizacion) {
      filter_params[
        "asset_acquisition_details__asset_acquisition__cf_fecha_actualizacion"
      ] = filter_params["asset_acquisition_fecha_actualizacion"];
      delete filter_params["asset_acquisition_fecha_actualizacion"];
    }

    if (filter_params?.motivo_baja) {
      filter_params["inventario__motivo_baja"] = filter_params["motivo_baja"];
      delete filter_params["motivo_baja"];
    }

    if (filter_params?.tipo_baja_descripcion) {
      filter_params["inventario__tipo_baja__descripcion"] =
        filter_params["tipo_baja_descripcion"];
      delete filter_params["tipo_baja_descripcion"];
    }
    if (filter_params?.fecha_baja) {
      filter_params["inventario__fecha_baja"] = filter_params["fecha_baja"];
      delete filter_params["fecha_baja"];
    }

    searchDropedInvetoryFilter(filter_params)
      .then((res) => {
        const { data } = res;
        if (res && data.length > 0) {
          dispatch(setSelectedStocks(getFixedInventoryFromRequest(data)));
          dispatch(setIdsFilterResult(data.map((d) => d.id)));
          dispatch(setSearchStockForm(initialStockValues));
          dispatch(setCurrentMode(null));
          history.push(
            `/bienes-instrumentales/inventario-detalles/bajas/resultados/${data[0].id}`
          );
        } else {
          notice("Sin resultados de búsqueda", "info");
        }
      })
      .catch((err) => {
        dispatch(setSearchStockForm(initialStockValues));
        notice(
          "No se han podido obtener los registros del inventario del servidor, contacta al adminstrador",
          "error"
        );
      })
      .finally(() => dispatch(setLoader(false)));
  };
  const handleSearch = () => {
    dispatch(setLoader(true));
    dispatch(clearStockFormDataAvailableToCopy());
    let key = searchStockForm.cabms_item?.text2
      ? getKeyData(searchStockForm.cabms_item?.text2)
      : null;
    delete searchStockForm.cf_conciliado;
    /*  const filter_params = filterParams(
      clearEmptyStringsObject(cleanObject({ ...searchStockForm, ...key }))
    );*/
    const filter_params = clearEmptyStringsObject(
      cleanObjectWithZero({ ...searchStockForm, ...key })
    );
    if (
      searchStockForm.cf_responsables !== "" &&
      searchStockForm.cf_responsables.length > 0
    ) {
      let responsables = [];
      const cf_responsables = searchStockForm.cf_responsables.map((responsable) =>
        responsables.push(responsable.value)
      );
      filter_params["custom_responsables"] = responsables;
    }

    if (searchStockForm.cf_cve_prove !== "" && searchStockForm.cf_cve_prove.length > 0) {
      let proveedores = "";
      let arrProveedores = [];

      searchStockForm.cf_cve_prove.map((proveedor) =>
        arrProveedores.push(proveedor.value)
      );

      proveedores = arrProveedores.join("|");
      filter_params["cf_cve_prove"] = proveedores;
    }

    if (searchStockForm.cve_partida !== "" && searchStockForm.cve_partida.length > 0) {
      let partidas = "";
      let arrPartidas = [];
      searchStockForm.cve_partida.map((partida) => arrPartidas.push(partida.value));

      partidas = arrPartidas.join("|");
      filter_params["cve_partida"] = partidas;
    }

    if (
      searchStockForm.relation_area_edificio__cve_edificio !== "" &&
      searchStockForm.relation_area_edificio__cve_edificio.length > 0
    ) {
      let edificios = "";
      let arrEdificios = [];

      searchStockForm.relation_area_edificio__cve_edificio.map((edificio) =>
        arrEdificios.push(edificio.value)
      );

      edificios = arrEdificios.join("|");
      filter_params["relation_area_edificio__cve_edificio"] = edificios;
    }

    if (filter_params?.asset_acquisition_fecha_actualizacion) {
      filter_params[
        "asset_acquisition_details__asset_acquisition__cf_fecha_actualizacion"
      ] = filter_params["asset_acquisition_fecha_actualizacion"];
      delete filter_params["asset_acquisition_fecha_actualizacion"];
    }

    searchInvetoryFilter(filter_params)
      .then((res) => {
        const { data } = res;
        if (res && data.length > 0) {
          dispatch(setSelectedStocks(getFixedInventoryFromRequest(data)));
          dispatch(setIdsFilterResult(data.map((d) => d.id)));
          dispatch(setSearchStockForm(initialStockValues));
          // dispatch(setCurrentMode(modeOptions.result));

          history.push(
            `/bienes-instrumentales/inventario-detalles/resultados/${data[0].id}`
          );
        } else {
          notice("Sin resultados de búsqueda", "info");
          dispatch(setSearchStockForm(initialStockValues));
        }
      })
      .catch((err) => {
        dispatch(setSearchStockForm(initialStockValues));
        notice(
          "No se han podido obtener los registros del inventario del servidor, contacta al adminstrador",
          "error"
        );
      })
      .finally(() => dispatch(setLoader(false)));
  };
  const handleEdit = () => {
    dispatch(setLoader(true));
    let newData = Object.keys(stockFieldsToUpdate).map((it) => ({
      ...stockFieldsToUpdate[it],
      id: it,
    }));
    let isValidDate = false;
    newData.forEach((element) => {
      if (element.hasOwnProperty("cf_importe")) {
        element.cf_importe = priceToDouble(element.cf_importe);
      }
      if (element.hasOwnProperty("cf_fecinv")) {
        const today = moment().format("YYYY/MM/DD");
        const selectedDate = moment(element.cf_fecinv, "DD/MM/YYYY").format("YYYY/MM/DD");
        isValidDate = moment(selectedDate).isAfter(today);
      }
    });

    if (isValidDate) {
      notice("La fecha de inventario no debe ser mayor al día de hoy", "error");
      return;
    } else {
      updateMultipleStockFields(newData)
        .then((res) => {
          notice(
            `${
              selectedStocks.length > 1
                ? "Se han editado los bienes exitosamente"
                : "Se ha editado el bien exitosamente"
            }`,
            "success"
          );
          dispatch(setCurrentMode(null));
          dispatch(clearFieldsToUpdate());
          dispatch(clearStockFormDataAvailableToCopy());
          dispatch(
            setSelectedStock({
              ...selectedStockForm,
              cf_fecha_actuali: res[0]?.cf_fecha_actuali,
            })
          );
          // setOriginalStock(selectedStockForm)
        })
        .catch((e) => {
          if (e.error) notice(e.error);
          else {
            const errors = Object.keys(e);
            if (errors[0] === "inventory_cabms") {
              return notice("Campos CABMS no pueden estar vacíos", "warning");
            } else notice("Ha ocurrido un error con la solicitud", "error");
          }
          throw e;
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };
  const clearForms = () => {
    dispatch(
      isSearchPage
        ? setSearchStockForm(initialStockValues)
        : setSelectedStock(originalStock)
    );
    dispatch(clearStockFormDataAvailableToCopy());
  };

  const printZpl = async () => {
    if (printConfiguration === "all") {
      let IDS = {
        ids: idsFilterResult,
      };
      const peticion = await axios.post(
        `${BASE_URL}/api/bienes-instrumentales/inventory/validate-print`,
        IDS
      );
      if (peticion.data.response === true) {
        let a = document.createElement("a");
        a.href = `nextwmsftp://?token=${authToken}?ids=${idsFilterResult.join()}?type=stock`;
        a.click();
        setTimeout(() => {
          setPrintLabel(false);
          setPrintConfiguration("current");
        }, 3000);
        dispatch(setOnLabelClick(""));
      } else {
        notice(
          "No se puede realizar la impresión ya que uno o más bienes no son de el mismo tipo de etiqueta"
        );
      }
    } else {
      let a = document.createElement("a");
      a.href = `nextwmsftp://?token=${authToken}?ids=${selectedStockForm?.id}?type=stock`;
      a.click();
      setTimeout(() => {
        setPrintLabel(false);
        setPrintConfiguration("current");
      }, 3000);
      dispatch(setOnLabelClick(""));
    }
  };

  const guardFormat = async () => {
    try {
      dispatch(setLoader(true));
      const filename = `Resguardo-${
        idsFilterResult.length === 1 ? idsFilterResult.join() : "multiple"
      }-${getTodayDate()}`;
      const res = await fetchGuardFormat(idsFilterResult, filename);
      const formatAURL = res.data.attachment_url;
      downLoaderWithURL(formatAURL, filename + ".pdf");
    } catch (error) {
      notice(
        `No se ha podido obtener el documento de resguardo desde el servidor`,
        "error"
      );
    } finally {
      dispatch(setOnFormatGuardClick(null));
      dispatch(setLoader(false));
    }
  };

  const getFormat = async (format) => {
    if (selectedStocks.length === 0)
      return notice(
        "Debes consultar 1 o más inventarios para poder descargar los formatos",
        "info"
      );
    dispatch(setLoader(true));
    try {
      const filename = `Formato-${
        idsFilterResult.length === 1 ? idsFilterResult.join() : "multiple"
      }-${getTodayDate()}`;
      const res = await fetchFormat(format, idsFilterResult);
      const formatAURL = res.data.attachment_url;
      downLoaderWithURL(formatAURL, filename + ".pdf");
    } catch (error) {
      notice(
        `No se ha podido obtener el Formato ${format.toUpperCase()} desde el servidor`,
        "error"
      );
    } finally {
      dispatch(setLoader(false));
    }
  };

  const handlePrintConfiguration = (event) => {
    setPrintConfiguration(event.target.value);
  };
  const unSuscribeFunction = () => {
    setOpenBajaModal(true);
  };
  // HEADER BUTTON ACTIONS
  useEffect(() => {
    // if there is a result, then enables edit button
    dispatch(setIsEditDisabled(!selectedStockForm?.id));
  }, [selectedStockForm?.id, dispatch]);

  useEffect(() => {
    if (onReportClick) {
      setReportsModal(true);
    }
  }, [onReportClick]);

  useEffect(() => {
    if (onLabelClick) {
      setPrintLabel(true);
    }
  }, [onLabelClick]);

  useEffect(() => {
    if (onFormatGuardClick) {
      guardFormat();
    }

    // eslint-disable-next-line
  }, [onFormatGuardClick]);

  useEffect(() => {
    if (onGetFormatAClick) {
      getFormat("A");
      dispatch(setOnGetFormatA(null));
    }
    // eslint-disable-next-line
  }, [dispatch, onGetFormatAClick]);

  useEffect(() => {
    if (onGetFormatBClick) {
      getFormat("B");
      dispatch(setOnGetFormatB(null));
    }
    // eslint-disable-next-line
  }, [dispatch, onGetFormatBClick]);

  useEffect(() => {
    if (onGetFormatCClick) {
      getFormat("C");
      dispatch(setOnGetFormatC(null));
    }
    // eslint-disable-next-line
  }, [dispatch, onGetFormatCClick]);

  useEffect(() => {
    if (onGetFormatDClick) {
      getFormat("D");
      dispatch(setOnGetFormatD(null));
    }
    // eslint-disable-next-line
  }, [dispatch, onGetFormatDClick]);

  useSubheaderAction({
    action: onSearchClick2,
    func: clearForms,
    isInPage: pathname.includes("/busqueda"),
    path: isDropPage
      ? "/bienes-instrumentales/inventario-detalles/bajas/busqueda"
      : "/bienes-instrumentales/inventario-detalles/busqueda",
  });

  useSubheaderAction({
    action: onSearchClick3,
    func: isDropPage ? handleSearchDropedInventory : handleSearch,
    isInPage: pathname.includes("/busqueda"),
    path: isDropPage
      ? "/bienes-instrumentales/inventario-detalles/bajas/busqueda"
      : "/bienes-instrumentales/inventario-detalles/busqueda",
  });

  useSubheaderAction({
    action: onEditClick2,
    //func: '',
    isInPage: pathname.includes("/resultados"),
    path: id ? `/bienes-instrumentales/inventario-detalles/resultados/${id}` : false,
  });

  useSubheaderAction({
    action: onSaveClick2,
    func: handleEdit,
    isInPage: true,
  });

  useSubheaderAction({
    action: onCancelClick2,
    func: clearForms,
    isInPage: isDetailsPage,
  });

  useSubheaderAction({
    action: onCancelClick3,
    func: clearForms,
    isInPage: isDetailsPage,
  });

  useSubheaderAction({
    action: unSuscribe,
    func: unSuscribeFunction,
    isInPage: true,
  });
  const inputsBaja = [
    {
      label: "Motivo de baja",
      name: "motivo_baja",
      type: "text",
    },
    {
      label: "Tipo de baja",
      name: "tipo_baja",
      type: "dropdown",
      options: tipoBajaList,
    },
    {
      label: "Fecha de baja",
      name: "fecha_baja",
      type: "date",
    },
  ];
  const [disableBaja, setDisableBaja] = useState(true);

  const handleChangeBaja = (e) => {
    setBajaForm({ ...bajaForm, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (bajaForm.motivo_baja && bajaForm.tipo_baja) {
      setDisableBaja(false);
    } else {
      setDisableBaja(true);
    }
  }, [bajaForm]);

  const functionBaja = () => {
    dispatch(setLoader(true));
    if (pathname.includes("baja")) {
      unSuscribeReason(id, bajaForm)
        .then((res) => {
          notice("Se edito la baja del inventario", "success");
          getStockBaja();
          setOpenBajaModal(false);
        })
        .catch((error) => {
          notice("Ha ocurrido un error al editar el inventario" + id, "error");
        })
        .finally(() => dispatch(setLoader(false)));
    } else {
      unSuscribeId(id, bajaForm)
        .then((res) => {
          notice("Se ha dado de baja el inventario", "success");

          setTimeout(() => {
            history.push(`/bienes-instrumentales/inventario/bajas`);
          }, 2000);
        })
        .catch((error) => {
          notice("Ha ocurrido dar de baja el inventario" + id, "error");
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };
  const closeFunction = () => setOpenBajaModal(false);
  return (
    <>
      <StockForm />
      <BaseModal
        title={
          <span>
            Etiqueta del bien <strong>{selectedStockForm?.id}</strong>
          </span>
        }
        open={printLabel}
        onClose={() => {
          setPrintLabel(false);
          dispatch(setOnLabelClick(""));
        }}
        onConfirm={printZpl}
        confirmLabel="Imprimir"
        onCancel={() => {
          setPrintLabel(false);
          dispatch(setOnLabelClick(""));
        }}
        cancelLabel="Cerrar"
        maxWidth="sm"
        color="secondary"
        disableOnConfirm={false}
      >
        <Labels
          ids={selectedStocks}
          item={selectedStockForm}
          handlePrintConfiguration={handlePrintConfiguration}
          printConfiguration={printConfiguration}
          fetchZpl={postZpl}
        />
      </BaseModal>
      <BaseModal
        open={reportsModal}
        onClose={() => {
          setReportsModal(false);
          dispatch(setOnReportClickFunction(""));
        }}
        disableOnConfirm={false}
        title="DESCARGAR REPORTE"
      >
        <ReportContent
          report={"reports"}
          data={idsFilterResult}
          reportRequest={stockReports}
        />
      </BaseModal>
      <BaseModal
        open={openBajaModal}
        onClose={closeFunction}
        disableOnConfirm={false}
        onConfirm={functionBaja}
        onCancel={closeFunction}
        cancelLabel="Cerrar"
        title="Baja"
      >
        <UtilInputs
          inputValues={inputsBaja}
          onValuesChange={handleChangeBaja}
          formValues={bajaForm}
          width={200}
        />
      </BaseModal>
    </>
  );
};

export default StockDetail;
