import React from "react";
import { useSelector } from "react-redux";
import { fieldsToTypeText } from "../utils/objectsFunctions";
import { dataTabForm } from "../utils/vehiculesData";
import UtilInputs from "./UtilInputs";
import { toggleEnableInputs } from "../utils/newEntryData";

const VehiculesDataTab = ({ formValues, handleChange }) => {
  const { enableInputs } = useSelector((store) => store.vehicules);
  return (
    <div className="d-flex justify-content-between">
      <div style={{ maxWidth: 300 }}>
        <UtilInputs
          width="100%"
          formValues={formValues}
          onValuesChange={handleChange}
          inputValues={
            enableInputs
              ? fieldsToTypeText(dataTabForm)
              : toggleEnableInputs(dataTabForm, enableInputs)
          }
        />
      </div>
    </div>
  );
};

export default VehiculesDataTab;
