import React from 'react'
import uuid from 'react-uuid';
import { useDispatch, useSelector } from 'react-redux';
import { defaultArtwork, defaultMaterial, material_columns2, material_columns3 } from '../../../utils/stockSurveyData';
import { SetInventory } from '../../../../redux/ducks/inventory.duck';
import BatchTable from '../../../components/BatchTable';

export const SurveyReassignment = () => {
  const dispatch = useDispatch()
  const { selectedInventory } = useSelector(state => state.inventory)
  const { currentMode } = useSelector(store => store.subHeaderActions);

  const checkType = (current) => {
    let type = ""
    if (current.inventory_area_destination)
      type = "Sobrante"
    else {
      if (current.inventory_area_found_cve)
        type = "Fuera de lugar"
      else 
        type = "Escaneados"
    }

    return type
  }

  const handleBatches = (i, field, value, aIndex = '') => {
    if (field === "inventory") {
      let updatedState = selectedInventory.inventory_reas
      updatedState[aIndex][field] = value?.text
      updatedState[aIndex]["inventory_name"] = value?.text2
      updatedState[aIndex]["inventory_area_cve"] = value?.cf_cve_area?.trim()
      dispatch(SetInventory({ ...selectedInventory, inventory_reas: updatedState }));
    } else if (field === "inventory_area_destination") {
      let updatedState = selectedInventory.inventory_reas
      updatedState[aIndex][field] = value?.num_area?.trim()
      updatedState[aIndex]["type"] = checkType(updatedState[aIndex])
      dispatch(SetInventory({ ...selectedInventory, inventory_reas: updatedState }));
    } else if (field === "inventory_area_found_cve") {
      let updatedState = selectedInventory.inventory_reas
      updatedState[aIndex][field] = value?.num_area?.trim()
      updatedState[aIndex]["type"] = checkType(updatedState[aIndex])
      dispatch(SetInventory({ ...selectedInventory, inventory_reas: updatedState }));
    }
    else return
  };

  const onSearchTextChange = (i, field, value, aIndex) => {
    let state = selectedInventory.inventory_reas
    if (!value?.text) {
      if (field === "inventory") {
        state[aIndex][field] = value;
        dispatch(SetInventory({ ...selectedInventory, inventory_reas: state }));
      } else if (field === "inventory_area_destination") {
        state[aIndex][field] = value;
        dispatch(SetInventory({ ...selectedInventory, inventory_reas: state }));
      } else if (field === "inventory_area_found_cve") {
        state[aIndex][field] = value;
        dispatch(SetInventory({ ...selectedInventory, inventory_reas: state }));
      } else return
    } else return
  };

  const addArticle = (i) =>
    dispatch(SetInventory({ ...selectedInventory, inventory_reas: [...selectedInventory.inventory_reas, { ...defaultMaterial, id: uuid() }] }));
  
  const deleteArticle = (item, index) => {
    let updatedState = selectedInventory.inventory_reas
    updatedState.splice(index, 1)
    dispatch(SetInventory({ ...selectedInventory, inventory_reas: updatedState }));
  };
  //      HANDLERS FOR ARTWORK ITEMS TABLE
  const handleBatchAW = (i, field, value, aIndex = '') => {
    if (field === "artwork") {
      let updatedState = selectedInventory.artworks_reas
      updatedState[aIndex][field] = value?.text
      updatedState[aIndex]["inventory_name"] = value?.text2
      updatedState[aIndex]["inventory_area_cve"] = value?.cf_num_area?.trim()
      dispatch(SetInventory({ ...selectedInventory, artworks_reas: updatedState }));
    } else if (field === "inventory_area_destination") {
      let updatedState = selectedInventory.artworks_reas
      updatedState[aIndex][field] = value?.num_area?.trim()
      updatedState[aIndex]["type"] = checkType(updatedState[aIndex])
      dispatch(SetInventory({ ...selectedInventory, artworks_reas: updatedState }));
    } else if (field === "inventory_area_found_cve") {
      let updatedState = selectedInventory.artworks_reas
      updatedState[aIndex][field] = value?.num_area?.trim()
      updatedState[aIndex]["type"] = checkType(updatedState[aIndex])
      dispatch(SetInventory({ ...selectedInventory, artworks_reas: updatedState }));
    }
    else return
  }

  const onSearchArtwork = (i, field, value, aIndex) => {
    let state = selectedInventory.artworks_reas
    if (!value?.text) {
      if (field === "artwork") {
        state[aIndex][field] = value;
        dispatch(SetInventory({ ...selectedInventory, artworks_reas: state }));
      } else if (field === "inventory_area_destination") {
        state[aIndex][field] = value;
        dispatch(SetInventory({ ...selectedInventory, artworks_reas: state }));
      } else if (field === "inventory_area_found_cve") {
        state[aIndex][field] = value;
        dispatch(SetInventory({ ...selectedInventory, artworks_reas: state }));
      } else return
    } else return
  };

  const addArtwork = (i) =>
    dispatch(SetInventory({ ...selectedInventory, artworks_reas: [...selectedInventory.artworks_reas, { ...defaultArtwork, id: uuid() }] }));

  const deleteArtwork = (item, index) => {
    let updatedState = selectedInventory.artworks_reas
    updatedState.splice(index, 1)
    dispatch(SetInventory({ ...selectedInventory, artworks_reas: updatedState }));
  };

  return (
    <>
      <h6 className="mt-7">Reasignación de bienes</h6>
      <br />
      <BatchTable
        i={0}
        items={selectedInventory.inventory_reas}
        columns={material_columns2(Boolean(selectedInventory.id), Boolean(currentMode), true)}
        handleChange={handleBatches}
        addRow={addArticle}
        onSearchTextChange={onSearchTextChange}
        deleteRow={deleteArticle}
      />
      <br />
      <h6>Reasignación de obras</h6>
      <br />
      <BatchTable
        i={0}
        items={selectedInventory.artworks_reas}
        columns={material_columns3(Boolean(selectedInventory.id), Boolean(currentMode), true)}
        handleChange={handleBatchAW}
        addRow={addArtwork}
        onSearchTextChange={onSearchArtwork}
        deleteRow={deleteArtwork}
      />
    </>
  )
}
