import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { initialInventoryValues } from "../../app/utils/stockSurveyData";


const actionType = {
  SetInventory: '[INVENTORY] SET_INVENTORY',
  SetInventorys: '[INVENTORY] SET_INVENTORYS',
  SetInventorysIds: '[INVENTORY] SET_INVENTORYS_IDS',
  SetClearInventorys: '[INVENTORY] SET_CLEAR_INVENTORYS',
  SetInventoryCurrentTab: '[INVENTORY] SET_INVENTORY_CURRENT_TAB',
  SetSearchInventoryForm: '[INVENTORY] SET_SEARCH_INVENTORY_FORM',
};

const initialState = {
  list: {
    results: [],
    count: 0
  },
  selectedInventory: initialInventoryValues,
  selectedInventorysIds: [],
  isSearchPage: false,
  isResultsPage: false,
  isNewPage: false,
  searchInventoryForm: initialInventoryValues,
};

export const reducer = persistReducer(
  { storage, key: 'inventory', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetInventorys:
          return { ...state, list: action.payload }
      
      case actionType.SetInventory:
        return { ...state, selectedInventory: action.payload };
      
      case actionType.SetInventorysIds:
      return { ...state, selectedInventorysIds: action.payload };
      
      case actionType.SetClearInventorys:
        return initialState;

      case actionType.SetInventoryCurrentTab:
        return {
          ...state,
          ...action.payload,
        };
  
      case actionType.SetSearchInventoryForm:
        return { ...state, searchInventoryForm: action.payload };
      
      default:
        return state
    }
  }
)

export const SetInventorys = data => ({ type: actionType.SetInventorys, payload: data })
export const SetInventorysIds = data => ({ type: actionType.SetInventorysIds, payload: data })
export const SetInventory = data => ({ type: actionType.SetInventory, payload: data })
export const SetClearInventorys = () => ({ type: actionType.SetClearInventorys })
export const SetInventoryCurrentTab = newTab => ({ type: actionType.SetInventoryCurrentTab, payload: newTab});
export const setSearchInventoryForm = data => ({ type: actionType.SetSearchInventoryForm , payload: data });