import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageLayout from '../../../components/PageLayout'
import UtilInputs from '../../../components/UtilInputs'
import notice from '../../../components/Notice'
import { getConfRequest } from "../../../crud/configCrud"
import { setLoader } from '../../../../redux/ducks/loader.duck'
import { setRequest } from '../../../../redux/ducks/config.duck'
import { requestForm } from './utils'

const ConfigRequestPage = () => {
  const dispatch = useDispatch()
  const { request } = useSelector(state => state.config)

  useEffect(() => {
    dispatch(setLoader(true))
    getConfRequest()
    .then(res => dispatch(setRequest(res)))
    .catch(e => notice("Ha ocurrido un error con la solicitud"))
    .finally(() => dispatch(setLoader(false)))
  }, [dispatch])

  const handleChange = ({ target }) => 
    dispatch(setRequest({...request, [target.name]: target.value}))

  return (
    <PageLayout title="Solicitud de usuario">
      <UtilInputs
        formValues={request}
        onValuesChange={handleChange}
        inputValues={requestForm}
        width={200}
      />
    </PageLayout>
  )
}

export default ConfigRequestPage
