import React, { useEffect, useState } from 'react';
import { makeStyles, MenuItem, Typography, useMediaQuery, Select } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: ({ isMobile, width }) => width ? width : isMobile ? 200 : 130,
		"& > .MuiTypography-root": {
      marginLeft: 5,
      fontWeight: 500,
      fontSize: 14
		}
	},
	root: {
		minHeight: 25,
		padding: "5.5px 14px",
		backgroundColor: ({ disabled }) => disabled ? "#dedede" : "#d6eaff",
		display: "flex",
		alignItems: "center",
		whiteSpace: 'pre-wrap', 
		overflowWrap: 'break-word'
	},
	label: {
		marginLeft: 5,
		fontWeight: 500,
    fontSize: 14,
		color: ({ disabled, touched, hover }) => (touched || hover) && !disabled ? theme.palette.secondary.main : "black",
	},
}));

const SimpleDropdownRequest = ({ label, name, onChange, value, requestFunction, width=false, disabled }) => {
	const isMobile = useMediaQuery('(max-width: 768px)');
	const [touched, setTouched] = useState(false)
	const [hover, setHover] = useState(false)
	const classes = useStyles({ isMobile, width, disabled, touched, hover });
  const [options, setOptions] = useState([])

  useEffect(() => {
    requestFunction(1, 100)
    .then(setOptions)
    .catch(console.log)
  }, [requestFunction])

	return (
		<div className={classes.container}>
			<Typography className={classes.label}>{label}</Typography>
			<Select
				fullWidth
				displayEmpty
				value={value}
				onChange={onChange}
				classes={classes}
				color="secondary"
				variant="outlined"
				size="small"
				name={name}
				disabled={disabled}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				onBlur={() => setTouched(false)}
				onFocus={e => setTouched(true)}
			>
				<MenuItem value="">
					Seleccione una opción
				</MenuItem>
				{options.map(option => (
					<MenuItem key={option.value} value={option.value} >
						{option.text}
					</MenuItem>
				))}
			</Select>
		</div>
	);
};

export default SimpleDropdownRequest;
