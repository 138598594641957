export const getFixedInventory = (inventory) =>
	inventory.map(({ item, proveedor, cabmsItem, id, uuid, inventory_cf_fecha_ingreso, foto_data, ...rest }) => ({
		...rest,
		inventory_foto_data: foto_data,
	}));

export const getFixedPayload = (inv = [], formValues) => {
	const payload = {
		...formValues,
		cf_fecha_contrato: formValues.cf_fecha_contrato,
		cf_fecha_factura: formValues.cf_fecha_factura,
		cf_fecha_remision: formValues.cf_fecha_remision || '',
		cf_fecha_recepcion_documento: formValues.cf_fecha_recepcion_documento,
		cf_fecha_requisicion: formValues.cf_fecha_requisicion,
		cf_fecha_recepcion_almacen: formValues.cf_fecha_recepcion_almacen,
		is_closed: false,
		acquisition_date: formValues.acquisition_date,
		// cf_fecha_recibido_formato_almacen: null,
		cf_iva_factura: formValues.cf_iva_factura,
		cf_no_proveedor: formValues.proveedor?.text2,
		cf_fecha_actuali: formValues.cf_fecha_actuali,
	};
	delete payload.item;
	delete payload.cabmsItem;
	delete payload.proveedor;
	delete payload.cf_username;
	delete payload.formatos_f;
	delete payload.formatos_r
	return inv.length > 0 ? { ...payload, inventory: inv.map(x => ({...x, inventory_cf_medioadquisicion: formValues.cf_medioadquisicion})) } : payload;
};
