import React from 'react'
import { NavLink } from 'react-router-dom';
import { useLocation } from "react-router";
import SVG from "react-inlinesvg";
import { checkIsActive, toAbsoluteUrl } from '../../../../_helpers'
import { SubMenu } from './SubMenu';

export const MenuOption = ({ item }) => {
  const location = useLocation();
  const { pathname } = location;

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };
  // if(item.slug.charAt(-1) === '/')
  //   item.slug.substring(0, item.slug.length -1);
  
  return (
    <li
      className={`menu-item${item.submodules && item.submodules.length > 0 ? getMenuItemActive(item.name, true) : getMenuItemActive(item.slug, false)}`}
      data-menu-toggle={item.submodules && item.submodules.length > 0 && "hover"}
    >
      <NavLink className={`${'menu-link menu-toggle'} ${pathname.includes(item.name) || pathname.substring(1) === item.slug ? 'activeItemMenu' : ''}`}  to={`/${item.slug}`}>
        <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl(`/media/svg/icons/${item.icon}`)}/>
        </span>
        <span className="menu-text">{item.display_text}</span>
        {item.submodules && item.submodules.length > 0 && <i className="menu-arrow"/>}
      </NavLink>
      {item.submodules && item.submodules.length > 0 && <SubMenu submenu={item.submodules} />}
    </li>
  )
}
