import React, { useState } from "react";
import { useSelector } from "react-redux";
import UtilInputs from "../../../components/UtilInputs";
import {
  toggleEnableInputs,
  inventoryFields,
} from "../../../utils/newEntryData";
import { isAValidInteger } from "../../../utils/inputValidations";
import { getCABMSKey, getKeyData } from "../../../utils/cabmsAndKey";
import { Box, Typography } from "@material-ui/core";
import CustomButton from "../../../components/CustomButton";
import SimpleInput from "../../../components/SimpleInput";
import AssetsIcon from "@material-ui/icons/Assignment";
import notice from "../../../components/Notice";
import uuid from "react-uuid";
import { initialInventoryForm } from "../../../utils/newEntryInitialValues";

export const NewEntryFormBienes = React.memo(
  ({
    setTablaNew,
    tablaNew,
    dataBienes,
    setDataBienes,
    setDataImport,
    dataImport,
  }) => {
    const { currentMode } = useSelector((store) => store.subHeaderActions);
    const [imagesPreview, setImagesPreview] = useState([]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      if (
        ["cf_no_scuenta", "cf_no_cuenta"].includes(name) &&
        !isAValidInteger(value)
      )
        return;
      const typeKeyInputs = ["cabms_item"];
      if (typeKeyInputs.includes(name) && value.text) {
        setDataBienes({
          ...dataBienes,
          [name]: value.text,
          [name.replace("item", "key")]: getCABMSKey(value),
          cf_articulo: value.text,
        });
      } else if (name === "desc_partida") {
        const newConcilied =
          value.text2 && value.text2.toString().charAt(0) === "5"
            ? "N"
            : value.text2 && value.text2.toString().charAt(0) !== "5"
            ? "R"
            : "";
        // If "Partida" has no no_cuenta or no_scuenta values then sets it to zero
        const new_no_cuenta = value.no_cuenta || "0";
        const new_no_scuenta = value.no_scuenta || "0";
        const partida = value.text2;
        setDataBienes({
          ...dataBienes,
          [name]: value,
          descripcion_partida: value.text,
          conciliado: newConcilied,
          cf_no_cuenta: new_no_cuenta,
          cf_no_scuenta: new_no_scuenta,
          partida,
        });
      } else if (
        [
          "cantidad_descuento",
          "descuento",
          "precio_unitario",
          "cantidad",
        ].includes(name)
      ) {
        if (isNaN(Number(value))) return;
        let parsedValue = null;
        let newValue = value
          ? value.startsWith("0") && !value.includes(".")
            ? Math.abs(parseFloat(value))
            : parseFloat(value) < 0
            ? Math.abs(value)
            : value
          : "";
        switch (name) {
          case "cantidad_descuento":
            const descuento =
              Number(dataBienes.precio_unitario) > 0 && Number(newValue) > 0
                ? (100 * Number(newValue)) / Number(dataBienes.precio_unitario)
                : Number(newValue);
            const newInventoryForm = {
              ...dataBienes,
              [name]: newValue,
              descuento: descuento.toFixed(2).toString(),
            };
            let parsedUnitPrice = !isNaN(parseFloat(dataBienes.precio_unitario))
              ? parseFloat(dataBienes.precio_unitario)
              : 0;
            parsedValue = !isNaN(parseFloat(newValue))
              ? parseFloat(newValue)
              : 0;
            if (
              parsedValue <= parsedUnitPrice &&
              /^\d+\.?\d{0,2}$|^\s*$/g.test(newValue)
            )
              setDataBienes(newInventoryForm);
            break;

          case "descuento":
            const cantidad_descuento =
              Number(dataBienes.precio_unitario) > 0 && Number(newValue) > 0
                ? (Number(dataBienes.precio_unitario) * Number(newValue)) / 100
                : Number(newValue);
            let newInventoryForm2 = { ...dataBienes, [name]: newValue };
            if (Number(dataBienes.precio_unitario) > 0)
              newInventoryForm2 = {
                ...newInventoryForm2,
                cantidad_descuento: cantidad_descuento.toFixed(2).toString(),
              };
            parsedValue = !isNaN(parseFloat(newValue))
              ? parseFloat(newValue)
              : 0;
            if (parsedValue <= 100 && /^\d+\.?\d{0,2}$|^\s*$/g.test(newValue))
              setDataBienes(newInventoryForm2);
            break;

          case "precio_unitario":
            const descuento2 =
              Number(dataBienes.cantidad_descuento) > 0 && Number(newValue) > 0
                ? (Number(dataBienes.cantidad_descuento) / Number(newValue)) *
                  100
                : 0;
            const newInventoryForm3 = {
              ...dataBienes,
              [name]: newValue,
              descuento: descuento2.toFixed(2).toString(),
            };
            let parsedQuantityDisc = !isNaN(
              parseFloat(dataBienes.cantidad_descuento)
            )
              ? parseFloat(dataBienes.cantidad_descuento)
              : 0;
            parsedValue = !isNaN(parseFloat(newValue))
              ? parseFloat(newValue)
              : 0;
            if (
              parsedValue >= parsedQuantityDisc &&
              /^\d+\.?\d{0,2}$|^\s*$/g.test(newValue)
            ) {
              setDataBienes(newInventoryForm3);
            } else if (/^\d+\.?\d{0,2}$|^\s*$/g.test(newValue))
              setDataBienes({
                ...dataBienes,
                [name]: newValue,
                descuento: 0,
                cantidad_descuento: 0,
              });
            break;

          case "cantidad":
            setDataBienes({
              ...dataBienes,
              [name]: Math.floor(newValue),
            });
            break;
          default:
            break;
        }
      } else {
        setDataBienes({ ...dataBienes, [name]: value });
      }
    };

    const disabledAddInventoryButton =
      dataBienes.cabms_item === "" ||
      dataBienes.cabms_key === "" ||
      dataBienes.cf_articulo === "" ||
      dataBienes.description === "" ||
      dataBienes.marca === "" ||
      dataBienes.modelo === "" ||
      dataBienes.serie === "" ||
      dataBienes.partida === "" ||
      dataBienes.cf_no_cuenta === "" ||
      dataBienes.cf_no_scuenta === "" ||
      dataBienes.inventory_cf_inve_material_type === "" ||
      Number(dataBienes.precio_unitario) === 0;

    const addInventoryData = () => {
      const {
        cf_grupo: cf_grupo_cabms,
        cf_subgrupo: cf_subgrupo_cabms,
        cf_clase: cf_clase_cabms,
        cf_subclase: cf_subclase_cabms,
        cf_tipo: cf_tipo_cabms,
      } = getKeyData(dataBienes.cabms_key);

      let newInventories = [];
      for (let i = 0; i < Number(dataBienes.cantidad); i++) {
        const newInventory = [
          {
            cabmsItem: dataBienes.cabms_item,
            inventory_cabms_grupo: cf_grupo_cabms,
            inventory_cabms_subgrupo: cf_subgrupo_cabms,
            inventory_cabms_clase: cf_clase_cabms,
            inventory_cabms_subclase: cf_subclase_cabms,
            inventory_cabms_tipo: cf_tipo_cabms,
            inventory_cf_num_inventario_anterior: dataBienes.folio,
            inventory_cf_articulo: dataBienes.cf_articulo,
            inventory_cf_descripcion: dataBienes.description,
            inventory_cf_modelo: dataBienes.modelo,
            inventory_cf_marca: dataBienes.marca,
            inventory_cf_serie: dataBienes.serie,
            inventory_cf_cve_partida: dataBienes.partida,
            inventory_cf_conciliado: dataBienes.conciliado,
            inventory_cf_no_cuenta: dataBienes.cf_no_cuenta,
            inventory_cf_no_scuenta: dataBienes.cf_no_scuenta,
            inventory_detail_cf_cantidad: 1,
            inventory_detail_cf_precio_unitario: dataBienes.precio_unitario,
            inventory_detail_cf_cantidad_descuento:
              dataBienes.cantidad_descuento,
            inventory_detail_cf_descuento: dataBienes.descuento,
            inventory_cf_label_type: dataBienes.cf_label_type,
            inventory_cf_label_status: dataBienes.cf_label_status,
            inventory_cf_tipobien: dataBienes.cf_tipobien,
            inventory_cf_inve_material_type:
              dataBienes.inventory_cf_inve_material_type,
            foto_data: dataBienes.foto_bien,
            id: "",
            uuid: uuid(),
          },
        ];

        newInventories = [...newInventories, ...newInventory];
      }
      const IVA = 16;

      const cf_sub_total = newInventories.reduce(
        (acc, value) =>
          value.inventory_detail_cf_cantidad > 0
            ? acc +
              (value.inventory_detail_cf_cantidad *
                value.inventory_detail_cf_precio_unitario -
                value.inventory_detail_cf_cantidad_descuento)
            : acc,
        0
      );
      const cf_importe_neto = cf_sub_total + cf_sub_total * (IVA / 100);
      const cf_iva_factura = cf_sub_total * (IVA / 100);

      setDataImport({
        cf_sub_total: cf_sub_total.toFixed(2),
        cf_iva_factura: cf_iva_factura.toFixed(2),
        cf_importe_neto: cf_importe_neto.toFixed(2),
      });
      setTablaNew([...tablaNew, ...newInventories]);
      notice("Se agregaron los bienes a la entrada", "success", 4000, "bottom");
    };

    return (
      <div>
        <Box mt={4} style={{ maxWidth: "100%" }}>
          <Box mb={2}>
            <Typography variant="subtitle1">
              <AssetsIcon /> Bienes{" "}
              {/* {inventoryResults.length > 0 && <span>({inventoryResults.length})</span>} */}
            </Typography>
          </Box>
        </Box>{" "}
        <UtilInputs
          inputValues={
            !currentMode
              ? toggleEnableInputs(inventoryFields, false)
              : inventoryFields
          }
          formValues={dataBienes}
          onValuesChange={handleChange}
          width={200}
          imagesPreview={imagesPreview}
          setImagesPreview={setImagesPreview}
        />
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
            padding: "10px 0px 20px 0px",
            gap: 20,
          }}
        >
          <SimpleInput
            placeholder="Cantidad"
            label="Cantidad"
            name="cantidad"
            value={dataBienes.cantidad}
            onChange={handleChange}
            disabled={!currentMode}
          />
          <CustomButton
            onClick={() => {
              addInventoryData();
              setDataBienes(initialInventoryForm);
            }}
            title="Agregar bienes"
            color="primary"
            disabled={disabledAddInventoryButton || !currentMode}
          />
        </div>
      </div>
    );
  }
);
