import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { setLoader } from "../../../redux/ducks/loader.duck";
import { setTasksByBoard } from "../../../redux/ducks/taskManagement.duck";
import Calendar from "../../components/Calendar";
import { getTasksbyBoard, getAllTasks } from "../../crud/taskManagementCrud";
import ToDoDetailTaskModal from "./components/ToDoDetailTaskModal";
import {
  format_calendar,
  format_callendar_data,
  format_trello_data,
} from "./utils";

const ToDoCalendar = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { resultsByBoard } = useSelector((store) => store.taskManagement.tasks);
  const [detailTaskModal, setDetailTaskModal] = useState(false);
  const [taskDetailId, setTaskDetailId] = useState(0);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [isDeletedATask, setIsDeletedATask] = useState(false);

  const fetchAllTasks = () => {
    setLoadingCalendar(true);
    getAllTasks()
      .then((response) => {
        const { results } = response;
        if (results.length) {
          let formatResponse = format_callendar_data(results, openDetail);
          dispatch(setTasksByBoard(formatResponse));
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoadingCalendar(false);
      });
  };

  const fetchTasksByBoard = () => {
    dispatch(setLoader(true));
    getTasksbyBoard(id)
      .then((response) => {
        if (response.length) {
          let formatResponse = format_trello_data(response, openDetail);
          dispatch(setTasksByBoard(formatResponse));
        }
      })
      .catch(console.log)
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    if (id === null) {
      fetchAllTasks();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (isDeletedATask) {
      fetchTasksByBoard();
    }
    setIsDeletedATask(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeletedATask, setIsDeletedATask]);

  const openDetail = (eventData) => {
    setTaskDetailId(eventData.event._def.publicId);
    setDetailTaskModal(true);
  };

  return (
    <>
      {loadingCalendar && <LinearProgress color="secondary" />}
      <Calendar
        data={format_calendar(resultsByBoard)}
        eventClick={openDetail}
      />
      <ToDoDetailTaskModal
        open={detailTaskModal}
        setOpen={setDetailTaskModal}
        detailId={taskDetailId}
        fetchTasksByBoard={fetchTasksByBoard}
        setIsDeletedATask={setIsDeletedATask}
      />
    </>
  );
};

export default ToDoCalendar;
