import { toAbsoluteUrl } from "../../_metronic/_helpers";
import * as columnFormatters from "../components/column-formatters";

export const MODULES = [
  {
    title: "Artículos",
    route: "/inventario-bienes-almacen-general/configuracion/articulos",
    submodules: [
      {
        title: "Variantes",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route: "/inventario-bienes-almacen-general/configuracion/articulos/variantes",
      },
      {
        title: "Presentaciones de proveedores",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route:
          "/inventario-bienes-almacen-general/configuracion/articulos/tipos-presentacion",
      },
    ],
  },
  {
    title: "Usuarios",
    route: "/inventario-bienes-almacen-general/configuracion/usuarios",
    submodules: [
      {
        title: "Grupos de usuarios",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route: "/inventario-bienes-almacen-general/configuracion/usuarios/grupos",
      },
      {
        title: "Permisos",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route: "/inventario-bienes-almacen-general/configuracion/usuarios/permisos",
      },
    ],
  },
  {
    title: "Almacén",
    route: "/inventario-bienes-almacen-general/configuracion/almacen",
    submodules: [
      {
        title: "Claves de almacén",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route: "/inventario-bienes-almacen-general/configuracion/almacen",
      },
      {
        title: "Claves de sección",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route: "/inventario-bienes-almacen-general/configuracion/almacen",
      },
      {
        title: "Solicitudes de usuarios",
        icon: toAbsoluteUrl("/media/svg/icons/Communication/RSS.svg"),
        route: "/inventario-bienes-almacen-general/configuracion/almacen/solicitudes",
      },
    ],
  },
  {
    title: "Cátalogos",
    route: "/bienes-instrumentales/configuracion/catalogos",
    submodules: [
      {
        title: "Tipo Documento",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route: "/bienes-instrumentales/configuracion/catalogos/tipo-de-documento",
      },
      {
        title: "Almacen",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route: "/bienes-instrumentales/configuracion/catalogos/almacen",
      },
      {
        title: "Partida Presupuestal",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route: "/bienes-instrumentales/configuracion/catalogos/partida-presupuestal",
      },
      {
        title: "Condicion de uso",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route: "/bienes-instrumentales/configuracion/catalogos/condicion-de-uso",
      },
      {
        title: "Condicion de operacion",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route: "/bienes-instrumentales/configuracion/catalogos/condicion-de-operacion",
      },
      {
        title: "Clasificacion",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route: "/bienes-instrumentales/configuracion/catalogos/clasificacion",
      },
      {
        title: "Tipo de Bien",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route: "/bienes-instrumentales/configuracion/catalogos/tipo-de-bien",
      },
      {
        title: "Tipo de Obra",
        icon: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
        route: "/bienes-instrumentales/configuracion/catalogos/tipo-de-obra",
      },
    ],
  },
];

export const DocumentTypeTableColumns = (onEye) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "name",
    text: "Nombre",
    sort: true,
    width: 400,
  },
  {
    dataField: "state",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.ArticlesStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ConfigActionColumn,
    className: "text-center",
    formatExtraData: {
      onEye,
    },
  },
];

export const DocumentTypeForm = (currentMode) => [
  {
    label: "Nombre",
    name: "name",
    type: "text",
    width: 400,
    disabled: currentMode === "result" ? true : false,
  },
  {
    label: "Estado",
    name: "state",
    type: "dropdown",
    hasChip: true,
    disabled: currentMode === "result" ? true : false,

    options: [
      { value: true, text: "Activo" },
      { value: false, text: "Baja" },
    ],
  },
];

//no se usa
export const defaultDocumentType = {
  nombre: "",
  estatus: false,
};

export const StockTableColumns = (onEye) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "nombre",
    text: "Nombre",
    sort: true,
    width: 400,
  },
  {
    dataField: "estatus",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.ArticlesStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ConfigActionColumn,
    className: "text-center",
    formatExtraData: {
      onEye,
    },
  },
];

export const StockForm = [
  {
    label: "Nombre",
    name: "nombre",
    type: "text",
    width: 400,
  },
  {
    label: "Estado",
    name: "state",
    type: "dropdown",
    hasChip: true,
    options: [
      { value: true, text: "Activo" },
      { value: false, text: "Baja" },
    ],
  },
];

export const defaultStock = {
  nombre: "",
  estatus: true,
};

export const BudgetItemTableColumns = (onEye) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "numero-partida",
    text: "Número Partida",
    sort: true,
  },
  {
    dataField: "titulo",
    text: "Título",
    sort: true,
    width: 300,
  },
  {
    dataField: "numero-capitulo",
    text: "Número Capítulo",
    sort: true,
  },
  {
    dataField: "numero-generico",
    text: "Número genérico",
    sort: true,
  },
  {
    dataField: "numero-cuenta",
    text: "Número de cuenta",
    sort: true,
  },
  {
    dataField: "numero-subcuenta",
    text: "Número de subcuenta",
    sort: true,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ConfigActionColumn,
    className: "text-center",
    formatExtraData: {
      onEye,
    },
  },
];

export const BudgetItemForm = [
  {
    label: "Número partida",
    name: "partida",
    type: "text",
    width: 200,
  },
  {
    label: "Título",
    name: "titulo",
    type: "text",
    width: 400,
  },
  {
    label: "Número capítulo",
    name: "capitulo",
    type: "text",
    width: 200,
  },
  {
    label: "Número genérico",
    name: "generico",
    type: "text",
    width: 200,
  },
  {
    label: "Número especifico",
    name: "especifico",
    type: "text",
    width: 200,
  },
  {
    label: "Número de cuenta",
    name: "cuenta",
    type: "text",
    width: 200,
  },
  {
    label: "Número de subcuenta",
    name: "subcuenta",
    type: "text",
    width: 200,
  },
];

export const defaultBudgetItem = {
  partida: "",
  titulo: "",
  capitulo: "",
  generico: "",
  especifico: "",
  cuenta: "",
  subcuenta: "",
};

export const ConditionOfUseTableColumns = (onEye) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "nombre",
    text: "Nombre",
    sort: true,
    width: 400,
  },
  {
    dataField: "estatus",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.ArticlesStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ConfigActionColumn,
    className: "text-center",
    formatExtraData: {
      onEye,
    },
  },
];

export const ConditionOfUseForm = [
  {
    label: "Nombre",
    name: "nombre",
    type: "text",
    width: 400,
  },
  {
    label: "Estado",
    name: "estatus",
    type: "dropdown",
    hasChip: true,
    options: [
      { value: true, text: "Activo" },
      { value: false, text: "Baja" },
    ],
  },
];

export const defaultConditionOfUse = {
  nombre: "",
  estatus: true,
};

export const OperatingConditionTableColumns = (onEye) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "nombre",
    text: "Nombre",
    sort: true,
    width: 400,
  },
  {
    dataField: "estatus",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.ArticlesStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ConfigActionColumn,
    className: "text-center",
    formatExtraData: {
      onEye,
    },
  },
];

export const OperatingConditionForm = [
  {
    label: "Nombre",
    name: "nombre",
    type: "text",
    width: 400,
  },
  {
    label: "Estado",
    name: "estatus",
    type: "dropdown",
    hasChip: true,
    options: [
      { value: true, text: "Activo" },
      { value: false, text: "Baja" },
    ],
  },
];

export const OperatingConditionUseDefault = {
  nombre: "",
  estatus: true,
};

export const ClassificationTableColumns = (onEye) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "nombre",
    text: "Nombre",
    sort: true,
    width: 400,
  },
  {
    dataField: "estatus",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.ArticlesStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ConfigActionColumn,
    className: "text-center",
    formatExtraData: {
      onEye,
    },
  },
];

export const ClassificationForm = [
  {
    label: "Nombre",
    name: "nombre",
    type: "text",
    width: 400,
  },
  {
    label: "Estado",
    name: "estatus",
    type: "dropdown",
    hasChip: true,
    options: [
      { value: true, text: "Activo" },
      { value: false, text: "Baja" },
    ],
  },
];

export const ClassificationDefault = {
  nombre: "",
  estatus: true,
};

export const TypeOfPropertyTableColumns = (onEye) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "nombre",
    text: "Nombre",
    sort: true,
    width: 400,
  },
  {
    dataField: "estatus",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.ArticlesStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ConfigActionColumn,
    className: "text-center",
    formatExtraData: {
      onEye,
    },
  },
];

export const TypeOfPropertyForm = [
  {
    label: "Nombre",
    name: "nombre",
    type: "text",
    width: 400,
  },
  {
    label: "Estado",
    name: "estatus",
    type: "dropdown",
    hasChip: true,
    options: [
      { value: true, text: "Activo" },
      { value: false, text: "Baja" },
    ],
  },
];

export const TypeOfPropertyDefault = {
  nombre: "",
  estatus: true,
};

export const TypeOfWorkTableColumns = (onEye) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "nombre",
    text: "Nombre",
    sort: true,
    width: 400,
  },
  {
    dataField: "estatus",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.ArticlesStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ConfigActionColumn,
    className: "text-center",
    formatExtraData: {
      onEye,
    },
  },
];

export const TypeOfWorkForm = [
  {
    label: "Nombre",
    name: "nombre",
    type: "text",
    width: 400,
  },
  {
    label: "Estado",
    name: "estatus",
    type: "dropdown",
    hasChip: true,
    options: [
      { value: true, text: "Activo" },
      { value: false, text: "Baja" },
    ],
  },
];

export const TypeOfWorkDefault = {
  nombre: "",
  estatus: true,
};

export const variantsTableColumns = (onEye) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "nombre",
    text: "Nombre",
    sort: true,
  },
  {
    dataField: "descripcion",
    text: "Descripción",
    sort: true,
    style: { minWidth: 400 },
  },
  {
    dataField: "estatus",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.ArticlesStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ConfigActionColumn,
    className: "text-center",
    formatExtraData: {
      onEye,
    },
  },
];

export const variantsForm = [
  {
    label: "Nombre",
    name: "nombre",
    type: "text",
  },
  {
    label: "Estado",
    name: "estatus",
    type: "dropdown",
    hasChip: true,
    options: [
      { value: true, text: "Activo" },
      { value: false, text: "Baja" },
    ],
  },
  {
    label: "Descripción",
    name: "descripcion",
    type: "text",
    width: 400,
  },
];

export const defaultVariant = {
  name: "",
  descripcion: "",
  estatus: true,
};

export const presentTypesTableColumns = (onEye) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "articulo.desc_corta",
    text: "Articulo",
    sort: true,
    style: { minWidth: 200 },
  },
  {
    dataField: "descripcion",
    text: "Descripción",
    sort: true,
    style: { minWidth: 400 },
  },
  {
    dataField: "equivalencia",
    text: "Equivalencia",
    sort: true,
    classes: "text-center",
  },
  {
    dataField: "estatus",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.ArticlesStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ConfigActionColumn,
    classes: "text-center",
    headerClasses: "text-center",
    formatExtraData: {
      onEye,
    },
  },
];

export const presTypesForm = [
  {
    label: "Unidad de medida",
    name: "unidad",
    type: "text",
    hasChip: true,
    disabled: true,
  },
  {
    label: "Equivalencia en stock",
    name: "equivalencia",
    type: "text",
  },
  {
    label: "Estado",
    name: "estatus",
    type: "dropdown",
    hasChip: true,
    options: [
      { value: true, text: "Activo" },
      { value: false, text: "Baja" },
    ],
  },
];

export const defaultPresentation = {
  articulo: null,
  descripcion: "",
  equivalencia: "",
  estatus: true,
  unidad: "",
};

export const groupsTableColumns = (onEye) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "name",
    text: "Nombre",
    sort: true,
  },
  {
    dataField: "description",
    text: "Descripción",
    sort: true,
    style: { minWidth: 400 },
  },
  {
    dataField: "status",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.ArticlesStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ConfigActionColumn,
    className: "text-center",
    formatExtraData: {
      onEye,
    },
  },
];

export const groupsForm = [
  {
    label: "Grupo",
    name: "name",
    type: "text",
    width: 400,
  },
  {
    label: "Descripción",
    name: "description",
    type: "text",
    width: 400,
  },
  {
    label: "Estado",
    name: "status",
    type: "dropdown",
    options: [
      { value: true, text: "Activo" },
      { value: false, text: "Baja" },
    ],
  },
];

export const defaultGroup = {
  name: "",
  description: "",
  status: "",
  permissions: [],
};

export const permitsTableColumns = (onEye) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "name",
    text: "Permiso",
    sort: true,
    style: { minWidth: 200 },
  },
  {
    dataField: "codename",
    text: "Codename",
    sort: true,
  },
  {
    dataField: "description",
    text: "Descripción",
    sort: true,
    style: { minWidth: 400 },
  },
  {
    dataField: "status",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.ArticlesStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ConfigActionColumn,
    className: "text-center",
    formatExtraData: {
      onEye,
    },
  },
];

export const permitForm = [
  {
    label: "Nombre",
    name: "name",
    type: "text",
    width: 400,
  },
  {
    label: "Codename",
    name: "codename",
    type: "text",
    width: 400,
  },
  {
    label: "Descripción",
    name: "description",
    type: "text",
    width: 400,
  },
  {
    label: "Estado",
    name: "status",
    type: "dropdown",
    hasChip: true,
    options: [
      { value: true, text: "Activo" },
      { value: false, text: "Baja" },
    ],
  },
];

export const defaultPermit = {
  name: "",
  codename: "",
  description: "",
  status: "",
};

export const PAGINATION_LIMIT = 5;
