import * as columnFormatters from "../components/column-formatters";

export const defaultFilter = {
  id: 0,
  entries: [],
  usuario: {
    id: 0,
    username: "",
    first_name: "",
    last_name: "",
  },
  state: null,
  created_at: "",
  updated_at: "",
  deleted_at: null,
  folio: null,
  observaciones: null,
};

export const filterInputs = [
  {
    label: "No. de concentrado",
    name: "id",
    type: "text",
  },
];

export const entrancesConcentrate_Columns = ({ onDetail, onFile }, mode = "public") => [
  {
    dataField: "id",
    text: "No. de concentrado",
    sort: false,
  },
  {
    dataField: "created_at",
    text: "Fecha de creación",
    sort: false,
  },
  {
    dataField: "entries",
    text: "No. de registro",
    sort: false,
    formatter: (value) => value?.map((entry) => entry).join(", "),
  },
  {
    dataField: "provider",
    text: "Proveedor",
    sort: false,
  },
  {
    dataField: "usuario",
    text: "Creado por",
    sort: false,
    formatter: (value) =>
      value?.username + " " + value?.first_name + " " + value?.last_name,
  },

  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.EntranceConcentrateColumnsFormatter,
    formatExtraData: {
      onDetail,
      onFile,
    },
    csvExport: false,
  },
];
