import React, { useCallback, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { ArrowBack, ArrowForward, FirstPage, LastPage } from '@material-ui/icons';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import TabButton from './TabButton'

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
  },
  slideContainer: {
    maxWidth: 640,
    margin: "0 35px 0 20px"
  }
});

const TabsButton = ({ list=[], selected, handleClickTab=() => {} }) => {
  const ref = useRef(null);
  const classes = useStyles()
  const currentIdx = list.findIndex(x => Number(x) === Number(selected))
  const settings = {
    dots: false,
    infinite: false,
		arrows: false,
    slidesToShow: list.length < 7 ? list.length : 7,
    slidesToScroll: 1,
    adaptiveHeight: true,
    draggable: false,
  }

  const setGoTo = useCallback((pos) => {
    handleClickTab(list[Number(currentIdx) + Number(pos)])
    ref?.current?.slickGoTo(Number(currentIdx) + Number(pos))
  }, [currentIdx, handleClickTab, list])

  const handleKeyDown = useCallback((e) => {
		if (e.ctrlKey && e.altKey && (e.key === "ArrowLeft" || e.key === "ArrowRight")) {
		  e.preventDefault();
      if (e.key === "ArrowLeft" && list[currentIdx] !== list[0]) setGoTo(-1)
      if (e.key === "ArrowRight" && list[currentIdx] !== list[list.length -1]) setGoTo(1)
    }
	}, [currentIdx, list, setGoTo]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [handleKeyDown])

  return (
    <div className={classes.container}>
      <Tooltip title="Ir al principio" arrow>
        <span>
        <IconButton
          disabled={list[currentIdx] === list[0]}
          onClick={() => setGoTo(-currentIdx)}
          disableRipple
          disableFocusRipple
          aria-label="first"
          color="secondary"
          className={`border border-${list[currentIdx] === list[0] ? "secondary" : "primary"}`}
        >
          <FirstPage />
        </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Anterior" arrow>
        <span>
        <IconButton
		      disabled={list[currentIdx] === list[0]}
          onClick={() => setGoTo(-1)}
          aria-label="previous"
          disableRipple
          disableFocusRipple
          color="secondary"
          className={`border border-${list[currentIdx] === list[0] ? "secondary" : "primary"} ml-3`}
          >
          <ArrowBack />
        </IconButton>
        </span>
      </Tooltip>
      <div className={classes.slideContainer}>
        <Slider ref={(slider) => ref.current = slider} {...settings}>
          {list.map(item => <TabButton
            key={item}
            onClick={() => handleClickTab(item)}
            isSelected={item.toString() === selected?.toString()}
            label={item.toString()}
            cursor='pointer'
            width={80}
            height={30}
            bold
          />)}
        </Slider>
      </div>
      <Tooltip title="Siguiente" arrow>
        <span>
        <IconButton
          disabled={list[currentIdx] === list[list.length -1]}
          onClick={() => setGoTo(+1)}
          aria-label="next"
          disableRipple
          disableFocusRipple
          color="secondary"
          className={`border border-${list[currentIdx] === list[list.length -1] ? "secondary" : "primary"} mr-3`}
        >
          <ArrowForward />
        </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Ir al último" arrow>
        <span>
        <IconButton
          disabled={list[currentIdx] === list[list.length -1]}
          onClick={() => setGoTo((list.length -1) - currentIdx)}
          aria-label="last"
          disableRipple
          disableFocusRipple
          color="secondary"
          className={`border border-${list[currentIdx] === list[list.length -1] ? "secondary" : "primary"}`}
        >
          <LastPage />
        </IconButton>
        </span>
      </Tooltip>
      <h3 className="ml-2">Registro {currentIdx +1} de {list.length}</h3>
    </div>
  )
}

export default TabsButton
