import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BaseModal from '../../components/BaseModal';
import FullTable from '../../components/FullTable'
import UtilInputs from '../../components/UtilInputs';
import notice from '../../components/Notice';
import { getArticlesList, requestExportArticles } from '../../crud/articlesCrud';
import { columns, defaultExport, defaultFilter, exportArticlesForm, filterArticleForm } from '../../utils/articlesData';
import { setArticles, setArticlesIds } from '../../../redux/ducks/articles.duck';
import { setLoader } from '../../../redux/ducks/loader.duck';
import { clearEmptyStringsObject } from '../../utils/objectsFunctions';
import { isAValidInteger } from '../../utils/inputValidations';
import { useSearchMode } from '../../hooks/useSearchMode';
import { getSecciones } from '../../crud/catalogsCrud';

const ArticlesPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { list } = useSelector(state => state.article)
  const { results, count } = list;
  const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(20);
  const [loading, setLoading] = useState(true);
  const [exportModal, setExportModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([])
  const [filter, setFilter] = useState(defaultFilter)
  const [exportForm, setExportForm] = useState(defaultExport)
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (exportForm.num_almacen)
      getSecciones(exportForm.num_almacen)
        .then((res) => {
          if (res.length !== 0) setSections(res);
          else {
            setSections([]);
          }
        })
        .catch(console.log);
  }, [exportForm.num_almacen]);

  useSearchMode("/inventario-bienes-almacen-general/articulos/busqueda")

  useEffect(() => {
    setLoading(true);
    getArticlesList({ page, limit: sizePerPage, ...filter })
      .then(res => {
        if (res.status === 200 && res.data)
          dispatch(setArticles(res.data));
      })
      .catch(err => console.log('err', err))
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, sizePerPage])

  const handleFilterChange = ({ target }) => {
    const { name, value } = target
    setFilter({...filter, [name]: value})
  }

  const onConfirmFilter = () => {
    setLoading(true);
    getArticlesList({page, limit: sizePerPage, ...filter })
    .then(res => {
      if (res.status === 200 && res.data)
        dispatch(setArticles(res.data));
    })
    .catch(err => console.log('err', err))
    .finally(() => setLoading(false));
  }

  const onClearFilter = () => {
    setLoading(true);
    setFilter(defaultFilter)
    getArticlesList({ page, limit: sizePerPage })
      .then(res => {
        if (res.status === 200 && res.data)
          dispatch(setArticles(res.data));
      })
      .catch(err => console.log('err', err))
      .finally(() => setLoading(false));
  }

  const handleonClickSearch = () => {
    history.push('/inventario-bienes-almacen-general/articulos/busqueda')
  }

  const handleNewVehicle = () => {
    history.push('/inventario-bienes-almacen-general/articulos/nuevo')
  }
  
  const onEye = async (id) => {
    await dispatch(setArticlesIds([]))
    history.push(`/inventario-bienes-almacen-general/articulos/resultados/${id}`)
  }

  const handleSelectAllRows = (isSelect, rows) => isSelect ? setSelectedIds(rows.map(r => r.id)) : setSelectedIds([])

  const handleSelectRow = (row, isSelect) => {
		if(row && row.id){
			if(!isSelect){
				const newSelected = selectedIds.filter(id => id !== row.id);
				setSelectedIds(newSelected);
			}else{
				setSelectedIds([...selectedIds, row.id]);
			};
		};	
	};

  const handleReqSelected = () => {
    dispatch(setArticlesIds(selectedIds))
    history.push(`/inventario-bienes-almacen-general/articulos/resultados/${selectedIds[0]}`)
  }

  const handleExportChange = ({ target }) => {
    const { name, value } = target
    if (name.includes("num")) {
      if (isAValidInteger(value))
        setExportForm({...exportForm, [name]: value})
    }
    else
      setExportForm({...exportForm, [name]: value})
  }

  const onConfirmExport = () => {
    dispatch(setLoader(true))
    requestExportArticles(clearEmptyStringsObject(exportForm))
    .then(({status, data}) => {
      if (status === 200 && data.attachment_url) {
        window.open(data.attachment_url, "_blank");
        setExportForm(defaultExport);
        setExportModal(false);
      } else notice("Error cargando el documento")
    })
    .catch(console.log)
    .finally(() => dispatch(setLoader(false)))
  }

  return (
    <>
    <FullTable
      title='Artículos del almacén'
      // NEW VEHICULE TRIGGER
      buttonTitle='Nuevo Artículo'
      onClickButton={handleNewVehicle}
      // TABLE ENTITIES
      page={page}
      loading={loading}
      count={count}
      entities={results}
      columns={columns(onEye)}
      sizePerPage={sizePerPage}
      setPage={page => page > 0 && setPage(page)}
      setSizePerPage={setSizePerPage}
      // SEARCH ACTIONS
			onClickSearch={handleonClickSearch}
      // SELECTED Ids ACTIONS
      has_button2
      buttonDisabled2={selectedIds.length < 1}
      buttonTitle2={`Consultar Todos${selectedIds.length > 1 ? `(${selectedIds.length.toString()})` : ''}`}
      onSelectRow={handleSelectRow}
			onSelectAllRows={handleSelectAllRows}
			onClickButton2={handleReqSelected}
      // FILTERS
      has_filter
      formFilters={filter}
      onFilterChange={handleFilterChange}
      filterInputs={filterArticleForm}
      onConfirmFilter={onConfirmFilter}
      onClearFilter={onClearFilter}
      // EXPORT
      has_button3
      buttonTitle3="Exportar artículos"
      onClickButton3={() => setExportModal(true)}
		/>
    <BaseModal
      title="Exportar artículos"
      open={exportModal}
      onClose={() => {setExportModal(false); setExportForm(defaultExport)}}
      onCancel={() => {setExportModal(false); setExportForm(defaultExport)}}
      onConfirm={onConfirmExport}
      disableOnConfirm={(!exportForm.formato || !exportForm.num_almacen)}
    >
      <UtilInputs
        width={200}
        formValues={exportForm}
				inputValues={exportArticlesForm(sections)}
				onValuesChange={handleExportChange}
      />
    </BaseModal>
    </>
  )
}

export default ArticlesPage
