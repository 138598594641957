import React from "react";
import { columnStatusCssClasses } from "../../utils/columnFormattersHelpers";
import "../styles/_departureStatus.scss";

export const EntrancesTypeColumnFormatter = (cellContent, row) => {
  console.log(cellContent);
  const STATUS = {
    // eslint-disable-line
    stock: 4,
    recurrente: 0,
    ordinaria: 3,
  };
  return (
    <span
      className={`label label-lg label-light-${
        columnStatusCssClasses[
          row.devolucion
            ? 1
            : cellContent === "GASTOSCOMPROBAR"
            ? 1
            : row.limpieza
            ? 2
            : STATUS[cellContent?.toLowerCase()]
        ]
      } label-inline`}
    >
      {row.devolucion
        ? "DEVOLUCION"
        : row.limpieza
        ? "LIMPIEZA"
        : cellContent === "GASTOSCOMPROBAR"
        ? "GASTOS A COMPROBAR"
        : cellContent === "FONDOREVOLVENTE"
        ? "FONDO REVOLVENTE"
        : cellContent}
    </span>
  );
};
