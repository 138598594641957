import React from "react";
import FileColumnIcon from "./list-icons/FileColumnIcon";
import EyeColumnIcon from "./list-icons/EyeColumnIcon";

export const EntranceConcentrateColumnsFormatter = (
  cellContent,
  row,
  rowIndex,
  { onDetail, onFile }
) => (
  <div className={`d-flex justify-content-${row.isDraft ? "center" : "start"}`}>
    <span className="mr-3">
      <EyeColumnIcon onClick={() => onDetail(row.id)} />
    </span>
    <span className="mr-3">
      <FileColumnIcon tooltip="Ver PDF" onClick={() => onFile(row.id)} />
    </span>
  </div>
);
