import React from "react";
import TrashColumnIcon from "./list-icons/TrashColumnIcon";
import FileColumnIcon from "./list-icons/FileColumnIcon";
import SendColumnIcon from "./list-icons/SendColumnIcon";
import CloseColumnIcon from "./list-icons/CloseColumnIcon";
import DraftColumnIcon from "./list-icons/DraftColumnIcon";
import EyeColumnIcon from "./list-icons/EyeColumnIcon";
import BarcodeColumnIcon from "./list-icons/BarcodeColumnIcon";

export const RequestActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    onFile,
    sendMail,
    onDetail,
    onCancel,
    onTrashDraft,
    onDraft,
    onPrintBarcode,
    pathname,
  }
) => {
  return (
    <div className={`d-flex justify-content-${row.isDraft ? "center" : "start"}`}>
      {row.cf_estatus === "X" ? (
        <DraftColumnIcon onClick={() => onDraft(row.id)} />
      ) : row.isDraft || !row.cf_estatus ? (
        <TrashColumnIcon onClick={() => onTrashDraft(row.id)} />
      ) : (
        <>
          <span className="mr-3">
            <EyeColumnIcon onClick={() => onDetail(row.id)} />
          </span>
          {pathname.includes("/solicitudes/lista") ? (
            <></>
          ) : (
            <span className="mr-3">
              <BarcodeColumnIcon
                onClick={() => onPrintBarcode(row.id)}
                disabled={row.cf_estatus !== "D"}
              />
            </span>
          )}
          <span className="mr-3">
            <FileColumnIcon tooltip="Ver PDF" onClick={() => onFile(row.id)} />
          </span>
          {window.location.pathname.includes("/inventario-bienes-almacen-general") && (
            <>
              <span className="mr-3">
                <SendColumnIcon
                  disabled={row.cf_estatus === "B"}
                  onClick={() => sendMail(row.id)}
                />
              </span>
              {row.cf_estatus !== "B" && (
                <CloseColumnIcon
                  tooltip="Cancelar"
                  onClick={() => onCancel(row.id)}
                  disabled={row.cf_estatus === "B"}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
