import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import notice from "../../components/Notice";
import Subtitle from "../../components/Subtitle";
import TabsButton from "../../components/TabsButton";
import UtilInputs from "../../components/UtilInputs";
import BatchTable from "../../components/BatchTable";
import SimpleInput from "../../components/SimpleInput";
import update from "immutability-helper";
import PDFUploaderTab from "../../components/PDFUploaderTab";
import { isAValidAmount, startsWithZero } from "../../utils/inputValidations";
import { useSearchMode } from "../../hooks/useSearchMode";
import { fieldsToTypeText } from "../../utils/objectsFunctions";
import {
  articlesColumns,
  batchInputs,
  requirementForm,
  intialFooterData,
  searchInputs,
  amountInputs,
} from "../../utils/requirementsData";
import {
  setNewRequirement,
  setSelectedRequirement,
} from "../../../redux/ducks/requirement.duck";
import { setFiles } from "../../../redux/ducks/files.duck";
import { NewRequirementTable } from "./NewRequirementTable";

const useStyles = makeStyles({
  layout: {
    paddingBottom: 40,
  },
  header: {
    position: "relative",
    margin: "auto 0px auto auto",
  },
});

const NewRequirementForm = ({
  onChangeFormValues = () => {},
  formValues = {},
  contracts = [],
}) => {
  const { selectedIds, isSearchTab, isResultsTab } = useSelector(
    (store) => store.requirement
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [footer, setFooter] = useState([]);
  //This loading is for document file util inputs.

  useSearchMode("/inventario-bienes-almacen-general/requerimiento/busqueda");

  const currentFormFunction =
    !isSearchTab && isResultsTab ? setSelectedRequirement : setNewRequirement;

  const handleBatches = (i, field, value, aIndex = "") => {
    if (field === "requested_amount") {
      if (
        isAValidAmount(value) &&
        !startsWithZero(value) &&
        !value.startsWith("-")
      ) {
        let newState = formValues.contract_set;
        const prev = newState[i].set_item[aIndex][field];
        const prevTotal = newState[i].set_item[aIndex].total_price;

        //Valida si el valor previo es igual al nuevo
        if (prev === value) {
          return;
        }

        newState = update(newState, {
          [i]: {
            set_item: {
              [aIndex]: {
                [field]: { $set: value },
                total_price: {
                  $set: newState[i].set_item[aIndex].unit_price * value,
                },
              },
            },
          },
        });
        const { available_quantity } = newState[i];
        const total = newState[i].set_item.reduce(
          (befVal, currVal, i, arr) => befVal + arr[i]["total_price"],
          0
        );
        if (total > available_quantity) {
          notice(
            `El precio total de la partida ${newState[i]?.partida_number} sobre pasa la cantidad disponible`,
            "warning",
            7000
          );
          newState = update(newState, {
            [i]: {
              set_item: {
                [aIndex]: {
                  [field]: { $set: prev },
                  total_price: { $set: prevTotal },
                },
              },
            },
          });
        }
        // _.toNumber(curr.minimum_amount)
        const subTotal = newState.reduce(
          (acc, contract) =>
            acc +
            contract.set_item.reduce(
              (sum, item) => Number(sum + Number(item.total_price.toFixed(2))),
              0
            ),
          0
        );
        const iva = newState.reduce((acc, contract) => {
          // If at least one item on set_item items have iva, then iva its added to all item from the set_item
          const hasIva = contract.set_item.some((e) => e?.iva);

          return (
            acc +
            contract.set_item.reduce(
              (sum, item) => sum + (hasIva ? item.total_price * 0.16 : 0),
              0
            )
          );
          // const maxSubTotal = newState.reduce((acc, curr) => (acc + _.toNumber(curr.maximum_amount)), 0);
        }, 0);

        dispatch(
          currentFormFunction(
            update(formValues, {
              contract_set: { $set: newState },
              subtotal: { $set: subTotal },
              iva: { $set: iva },
              total: { $set: subTotal + iva },
            })
          )
        );
      }
    }
  };

  const handleClickTab = (id) =>
    history.push(
      `/inventario-bienes-almacen-general/requerimientos/resultados/${id}`
    );

  useEffect(() => {
    if (formValues?.contract_set) {
      const { contract_set } = formValues;
      const totals = contract_set.flatMap((c, i) => {
        let total = c.set_item
          .map(({ total_price }) => Number(total_price))
          .reduce((antV, currV) => antV + currV, 0);
        return total;
      });
      const f = totals.map((t) =>
        intialFooterData.map((item) => {
          if (item.column === "total_price") item.value = t;
          return { ...item }; // item have to sread data for footer
        })
      );
      setFooter(f);
    }
  }, [formValues, formValues?.contract_set]);

  const onSuccessUploadPDFs = (responses) => {
    const newAttachments = responses.map((res) => res.data);
    const newSelectedRequirement = {
      ...formValues,
      all_attachments: [...formValues.all_attachments, ...newAttachments],
    };
    dispatch(currentFormFunction(newSelectedRequirement));
  };

  const onSucessRemovePDF = (deletedPDFId) => {
    const newAttachments = formValues.all_attachments.filter(
      (attachment) => attachment.id !== deletedPDFId
    );
    const newSelectedRequirement = {
      ...formValues,
      all_attachments: newAttachments,
    };
    dispatch(currentFormFunction(newSelectedRequirement));
  };

  const onSuccessUploadWithotId = (files) => {
    dispatch(setFiles(files));
  };

  const handleBatchUtilInputs = (e, index) => {
    const { name, value } = e.target;
    switch (name) {
      case "date_end": {
        dispatch(
          currentFormFunction(
            update(formValues, {
              contract_set: {
                [index]: {
                  date_end: { $set: value },
                },
              },
            })
          )
        );
        break;
      }
      default: {
        return;
      }
    }
  };

  const onChangeValue = (Field, numberRow, newValue, number_Table) => {
    handleBatches(number_Table, Field, newValue, numberRow);
    //i, field, value
  };
  return (
    <PageLayout
      title={`${formValues?.id ? "" : "Nuevo"} Requerimiento`}
      className={classes.layout}
      header={
        formValues.id &&
        selectedIds.length > 1 && (
          <TabsButton
            list={selectedIds}
            selected={formValues.id}
            handleClickTab={handleClickTab}
          />
        )
      }
    >
      <Subtitle
        title={formValues?.id ? "Información del requerimiento" : ""}
        padding="0px 0px 10px 0px"
        margin="0px"
      />
      <UtilInputs
        formValues={formValues}
        onValuesChange={onChangeFormValues}
        inputValues={
          isSearchTab ? searchInputs : requirementForm(contracts, formValues.id)
        }
        width="200px"
      />
      <SimpleInput
        label="Observaciones"
        placeholder="Observaciones"
        name="observations"
        onChange={onChangeFormValues}
        width="95%"
        value={formValues.observations}
      />
      {!isSearchTab && (
        <>
          <Subtitle
            title="Selección de artículos de acuerdo al contrato"
            padding="10px 0px"
          />
          {formValues.contract_set?.map((batch, i) => {
  
            let subtotal = 0;

          
            for (const item of batch.set_item) {
              subtotal += item.total_price;
            }

            return (
              <Fragment key={batch.id}>
                <Typography variant="h6">
                  Partida {batch.partida_number}
                </Typography>
                <UtilInputs
                  formValues={batch}
                  inputValues={batchInputs}
                  onValuesChange={(e) => handleBatchUtilInputs(e, i)}
                />
                <div
                  className="mt-4 pr-3"
                  style={{ width: "100%", overflow: "auto" }}
                >
                  <NewRequirementTable
                    columns={articlesColumns}
                    items={batch.set_item}
                    onChangeValue={onChangeValue}
                    index={i}
                    focus
                  ></NewRequirementTable>
                </div>
                <section
                  style={{
                    width: "100%",
                    height: "50px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <label
                    for={`subtotal${i}`}
                    style={{
                      marginRight: "10px",
                      fontWeight: "bold",
                      width: "8%",
                    }}
                  >
                    Subtotal:
                  </label>
                  <input
                    type="text"
                    id={`subtotal${i}`}
                    name={`subtotal${i}`}
                    placeholder="Ingrese el subtotal"
                    style={{ height: "25px" }}
                    value={subtotal.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    disabled
                  />
                </section>
              </Fragment>
            );
          })}

          <Subtitle title="Importe del requerimiento" padding="10px 0px" />
          <UtilInputs
            formValues={formValues}
            onValuesChange={onChangeFormValues}
            inputValues={
              isSearchTab ? fieldsToTypeText(amountInputs()) : amountInputs()
            }
            width="200px"
          />
          <Subtitle title="Documentos" padding="10px 0px" />
          <PDFUploaderTab
            enableSelectType={false}
            pdfTitleName="requerimiento"
            attachment_pdfs={formValues.all_attachments}
            attach_model="requisition"
            field="pdfs"
            id={formValues.id}
            onSuccessUpload={
              formValues.id ? onSuccessUploadPDFs : onSuccessUploadWithotId
            }
            onSucessRemove={onSucessRemovePDF}
          />
        </>
      )}
    </PageLayout>
  );
};

export default NewRequirementForm;
