import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import UtilInputs from '../../components/UtilInputs';
import { requestSelector, setCurrentRequestTab, setFieldFilter } from '../../../redux/ducks/request.duck';
import PageLayout from '../../components/PageLayout';
import { requestFilters } from '../../utils/materialRequestData';
import { useLocation } from 'react-router-dom';

const FilterRequestPage = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch()
  const { filter } = useSelector(requestSelector);

  const handleForm = e => {
    if(e && e.target){
      const { name, value } = e.target;
      dispatch(setFieldFilter({...filter, [name]: value }));
    };
  };

	useEffect(() => {
		const newTabs =  pathname.includes('busqueda') ? 
			{ isSearchTab: true, isResultsTab: false, isNewTab: false } 
			: pathname.includes('nueva') ? { isSearchTab: false, isResultsTab: false, isNewTab: true } 
			: { isSearchTab: false, isResultsTab: true, isNewTab: false };
		dispatch(setCurrentRequestTab(newTabs));
	}, [pathname, dispatch]);

  return (
    <PageLayout
      title="Búsqueda de solicitudes"
    >
      <UtilInputs
        width={300}
        formValues={filter}
        inputValues={requestFilters}
        onValuesChange={handleForm}
      />
    </PageLayout>
  )
}

export default FilterRequestPage
