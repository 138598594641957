import React, { useState, useCallback, useEffect } from "react";
import {
  Avatar,
  Button,
  Badge,
  Grid,
  Typography,
  Divider,
  Box,
  makeStyles,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import notice from "../../../components/Notice";
import BaseModal from "../../../components/BaseModal";
import FileButton from "../../../components/FileButton";
import FileIconImg from "../../../components/FileIconImg";
import SimpleInput from "../../../components/SimpleInput";
import CustomButton from "../../../components/CustomButton";
import RichTextEditor from "../../../components/RichTextEditor";
import SimpleButtonGroup from "../../../components/SimpleButtonGroup";
import {
  getTask,
  createComment,
  updateTask,
  deleteAttachmentFromTask,
  deleteTask,
} from "../../../crud/taskManagementCrud";
import { setLoader } from "../../../../redux/ducks/loader.duck";
import {
  detailTaskForm,
  groupButtonOptions,
  groupUserInChargeButtonOptions,
} from "../utils";
import UtilInputs from "../../../components/UtilInputs";
import { getOperativeUsers } from "../../../crud/usersCrud";
import AutocompleteMultipleInput from "../../../components/AutocompleteMultipleInput";
import { Delete, AttachFile, Subject, Chat, Clear } from "@material-ui/icons";
import { setUpdateFromModal } from "../../../../redux/ducks/taskManagement.duck";
import AttachmentsList from "../../../components/AttachmentsList";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.red.main,
  },
}));

const defaultDetail = {
  attachment: [],
  blocked: false,
  board: null,
  column_state: "",
  created_at: "",
  date_input: "",
  description: "",
  folio: null,
  folio_number: "",
  name: "",
  priority: null,
  reception_date: "",
  request_area: "",
  shift: null,
  signed: "",
  task_comments: [],
  updated_at: "",
  users: [],
};

const AVATAR_WIDTH = 25;
const ToDoDetailTaskModal = ({
  detailId,
  open,
  setOpen,
  setIsDeletedATask,
  fetchTasksByBoard = () => {},
}) => {
  const classes = useStyles();
  const { user } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [detail, setDetail] = useState(defaultDetail);
  const [isLoading, setIsLoading] = useState(false);
  const [operativeUsers, setOperativeUser] = useState([]);
  const [updateTaskState, setUpdateTaskState] = useState(0);
  const [isUpdatedTaskState, setIsUpdateTaskState] = useState(false);
  const [deleteAttachment, setDeleteAttachment] = useState(false);
  const [attachmentSelected, setAttachmentSelected] = useState(0);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    getOperativeUsers()
      .then((res) =>
        setOperativeUser(
          res.results.map((opUs) => ({
            ...opUs,
            value: opUs.id,
            text: `${opUs.first_name} ${opUs.last_name}`,
          }))
        )
      )
      .catch(console.log);
  }, []);

  const fetchTask = useCallback(
    async (id) => {
      setIsLoading(true);
      if (open && id !== 0) {
        getTask(id)
          .then((response) => {
            setDetail({
              ...response,
              inputDisabled:
                response.column_state === 4 || response.column_state === 5
                  ? true
                  : false,
              shift: response.shift?.id,
              folio: response.folio?.id,
              folio_number: response?.folio_number,
              label: response.folio?.name,
              priority: response.priority?.id,
              priority_name: response.priority?.name,
              users: response.users.map((us) => ({
                ...us,
                value: us.id,
                text: us.first_name
                  ? `${us.first_name} ${us.last_name}`
                  : us.username,
              })),
              users_collaborators: response.users_collaborators.map((us) => ({
                ...us,
                value: us.id,
                text: us.first_name
                  ? `${us.first_name} ${us.last_name}`
                  : us.username,
              })),
            });
          })
          .catch((e) => {
            setDetail(defaultDetail);
            console.log(e);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [open]
  );

  useEffect(() => {
    fetchTask(detailId);
  }, [detailId, fetchTask]);

  // FORM HANDLER
  const handleChange = ({ target }) =>
    setDetail({ ...detail, [target.name]: target.value });

  // COMMENTS HANDLERS
  const handleComments = ({ target }) => {
    console.log(target.value.length);
    setComment(target.value);
  };
  const handleAttachments = (files) => setAttachments(files);

  const handleUpdateState = (value) => {
    setUpdateTaskState(value);
    if (value === 3) {
      setIsUpdateTaskState(true);
    }
  };

  const handleUpdateTask = () => {
    const updateTaskValues =
      updateTaskState === 0 ? detail.column_state : updateTaskState;

    const dataToUpdate = {
      ...detail,
      column_state: updateTaskValues,
      blocked: updateTaskValues === 3,
      board: detail.board?.id,
      users: detail.users.map((us) => us.id),
      users_collaborators: detail.users_collaborators.map((us) => us.id),
    };

    if (!isUpdatedTaskState || dataToUpdate.column_state !== 3) {
      dispatch(setLoader(true));
      return updateTask(detail.id, dataToUpdate)
        .then((res) => {
          fetchTask(detailId);
          fetchTasksByBoard();
          notice(`${res.name} actualizada con éxito`, "success");
          setIsUpdateTaskState(false);
        })
        .catch((e) => notice("Ha ocurrido un error al actualizar la tarea"))
        .finally(() => {
          dispatch(setLoader(false));
          setOpen(false);
        });
    } else {
      setIsUpdateTaskState(false);
      return dispatch(setUpdateFromModal(dataToUpdate));
    }
  };

  const handleCreateComment = () => {
    dispatch(setLoader(true));
    createComment({ comment, task: detailId, attachments })
      .then((res) => {
        setComment("");
        setAttachments([]);
        fetchTask(detailId);
        notice("Comentario agregado", "success");
      })
      .catch((e) => notice("Ha ocurrido un error al crear su comentario"))
      .finally(() => dispatch(setLoader(false)));
  };

  const handleRemoveAttachment = (index) => {
    const updatedAtt = attachments;
    updatedAtt.splice(index, 1);
    setAttachments([...updatedAtt]);
  };

  const onRemoveAttachmentFile = () => {
    const recentAttachments = detail.attachment.filter(
      (attachment) => attachment.id !== attachmentSelected
    );
    return deleteAttachmentFromTask(attachmentSelected)
      .then((res) => {
        setDeleting(true);
        setDetail({ ...detail, attachment: recentAttachments });
      })
      .catch((e) => {
        notice("No se puede eliminar este archivo", "info");
      })
      .finally(() => {
        setDeleting(false);
        setDeleteAttachment(false);
      });
  };

  const handleCancel = () => {
    setOpen(false);
    setAttachments([]);
  };

  const handleDeleteTask = () => {
    return deleteTask(detail.id)
      .then((response) => {
        if (response.message === "Eliminado correctamente.") {
          notice("Tarea eliminada correctamente", "success");
          setOpen(false);
          setAttachments([]);
        } else {
          notice("Intente de nuevo.", "info");
        }
      })
      .catch((e) => notice("Ha ocurrido un error en el servidor", "warning"))
      .finally(() => {
        setOpen(false);
        setIsDeletedATask(true);
      });
  };

  return (
    <BaseModal
      dividers
      maxWidth="md"
      title={
        isLoading ? (
          <Skeleton width="80%" />
        ) : (
          <Box>
            <Typography variant="subtitle1">
              {detail?.label} - {detail?.folio_number}
            </Typography>
            <Typography variant="subtitle2">{detail?.name}</Typography>
          </Box>
        )
      }
      open={open}
      onClose={handleCancel}
      onConfirm={detail.column_state === 4 ? handleCancel : handleUpdateTask}
      onCancel={
        user.id === detail?.board?.user_in_charge ||
        user.id === detail?.board?.user_in_charge2
          ? handleDeleteTask
          : handleCancel
      }
      confirmLabel={detail.column_state === 4 ? "Aceptar" : "Actualizar"}
      cancelLabel={
        user.id === detail?.board?.user_in_charge ||
        user.id === detail?.board?.user_in_charge2 ? (
          <Typography className={classes.deleteButton}>
            <Delete /> Eliminar
          </Typography>
        ) : (
          "Cancelar"
        )
      }
    >
      {isLoading ? (
        <>
          <Skeleton style={{ marginBottom: 6 }} />
          <Skeleton style={{ marginBottom: 6 }} />
          <Skeleton style={{ marginBottom: 6 }} />
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="body1">
              <Subject /> Descripción
            </Typography>
            {user.id === detail?.board?.user_in_charge ||
            user.id === detail?.board?.user_in_charge2 ? (
              <SimpleInput
                rows={5}
                width="100%"
                multiline
                name="description"
                value={detail.description}
                disabled={detail.inputDisabled}
                onChange={handleChange}
              />
            ) : (
              <Typography variant="body2">{detail?.description}</Typography>
            )}
            <Box className="mb-3 mt-15">
              <Typography variant="body1">
                <AttachFile /> Adjuntos ({detail.attachment?.length})
              </Typography>
            </Box>
            <div className="d-flex">
              {detail.attachment?.map((file) => {
                return (
                  <Badge
                    badgeContent={
                      <IconButton
                        onClick={() => {
                          setDeleteAttachment(true);
                          setAttachmentSelected(file.id);
                        }}
                      >
                        <Clear />
                      </IconButton>
                    }
                  >
                    <div
                      key={file.url}
                      style={{ maxWidth: 100, cursor: "pointer" }}
                      onClick={() => window.open(file.url, "_blank")}
                    >
                      <FileIconImg
                        name={file.title}
                        type={file.type}
                        width={50}
                      />
                    </div>
                  </Badge>
                );
              })}
            </div>
            {
              <>
                <Box className="mb-3 mt-15">
                  <Typography variant="body1">
                    <Chat /> Comentarios ({detail?.task_comments?.length})
                  </Typography>
                </Box>
                <div
                  style={{ maxHeight: 300 }}
                  className="mb-3 p-3 overflow-auto"
                >
                  {detail?.task_comments.map((comment, key) => (
                    <div
                      key={key}
                      className="mb-3 p-4 bg-light-secondary rounded"
                    >
                      <div className="d-flex">
                        <Avatar className="bg-info">{`${comment?.user?.first_name?.charAt(
                          0
                        )}${comment?.user?.last_name?.charAt(0)}`}</Avatar>
                        <div className="ml-4">
                          <p className="font-weight-bold">
                            {`${comment?.user?.first_name} ${comment?.user?.last_name}`}
                          </p>
                          <span className="font-size-xs">
                            {comment.created_at}
                          </span>
                        </div>
                      </div>
                      <hr />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `${comment.comment}`,
                        }}
                      />
                      <div className="d-flex">
                        {comment.attachment?.map((file) => {
                          return (
                            <div
                              key={file.url}
                              style={{ maxWidth: 100, cursor: "pointer" }}
                              onClick={() => window.open(file.url, "_blank")}
                            >
                              {file.title.includes(".jpg") ||
                              file.title.includes(".jpeg") ||
                              file.title.includes(".png") ? (
                                <img
                                  src={file.url}
                                  alt={file.title}
                                  width="100%"
                                />
                              ) : (
                                <FileIconImg
                                  name={file.title}
                                  type={file.type}
                                  width={50}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            }
            <div className="d-flex flex-column align-items-end">
              <RichTextEditor
                placeholder={"Escriba aquí su mensaje"}
                value={comment}
                name="comment"
                onChange={handleComments}
              />
              <div className="d-flex">
                <FileButton
                  disabled={detail.inputDisabled}
                  multiple
                  accept="*"
                  buttonTitle="Cargar archivos"
                  handleChange={handleAttachments}
                />
                <CustomButton
                  disabled={detail.inputDisabled || comment.length <= 9}
                  title="Enviar"
                  color="success"
                  className="mt-3"
                  onClick={handleCreateComment}
                />
              </div>
              <div className="d-flex mt-5">
                <AttachmentsList
                  attachments={attachments}
                  onClick={(e, i) => handleRemoveAttachment(i)}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="mt-4 p-5">
              <SimpleButtonGroup
                className="mb-4"
                disabled={
                  (user.id === detail?.board?.user_in_charge ||
                    user.id === detail?.board?.user_in_charge2) &&
                  detail.column_state === 3
                    ? false
                    : (detail?.users?.find((us) => us.id === user.id) ||
                        detail?.users_collaborators?.find(
                          (coll) => coll.id === user.id
                        )) &&
                      (detail.column_state === 1 || detail.column_state === 2)
                    ? false
                    : true
                }
                options={
                  user.id === detail?.board?.user_in_charge ||
                  user.id === detail?.board?.user_in_charge2
                    ? groupUserInChargeButtonOptions
                    : groupButtonOptions
                }
                selected={detail.column_state - 1}
                onSelect={handleUpdateState}
              />
            </div>
            <Divider />
            <div
              style={{
                background:
                  user.id === detail?.board?.user_in_charge ||
                  user.id === detail?.board?.user_in_charge2
                    ? "#ebedf3"
                    : "#43425d",
              }}
              className="mt-4 p-5"
            >
              <h6
                className={`text-${
                  user.id === detail?.board?.user_in_charge ||
                  user.id === detail?.board?.user_in_charge2
                    ? "dark"
                    : "white"
                }`}
              >
                Detalles
              </h6>
              {user.id === detail?.board?.user_in_charge ||
              user.id === detail?.board?.user_in_charge2 ? (
                <UtilInputs
                  formValues={detail}
                  onValuesChange={handleChange}
                  inputValues={detailTaskForm(
                    operativeUsers,
                    detail.inputDisabled
                  )}
                  width="100%"
                />
              ) : (
                <div style={{ flexBasis: "minContent" }} className="text-white">
                  <div className="d-flex justify-content-between my-5">
                    <p>Folio</p>
                    <p>{detail?.folio_number}</p>
                  </div>
                  <div className="d-flex justify-content-between my-5">
                    <p>Área solicitante</p>
                    <p>{detail?.request_area}</p>
                  </div>
                  <div className="d-flex justify-content-between my-5">
                    <p>Firmante</p>
                    <p>{detail?.signed}</p>
                  </div>
                  <div className="d-flex justify-content-between my-5">
                    <p>Prioridad</p>
                    <p>{detail?.priority_name}</p>
                  </div>
                  <div className="d-flex justify-content-between my-5">
                    <p>Responsables</p>
                    <p>
                      {detail?.users?.map((resp) => (
                        <span
                          key={resp.id}
                          className="d-flex align-items-center"
                        >
                          <Avatar
                            className="bg-info"
                            style={{
                              width: AVATAR_WIDTH,
                              height: AVATAR_WIDTH,
                            }}
                          >
                            <span className="font-size-sm">
                              {`${
                                resp.first_name
                                  ? resp.first_name?.charAt(0) +
                                    resp.last_name?.charAt(0)
                                  : resp.username[0]
                              }`}
                            </span>
                          </Avatar>
                          <span className="font-size-xs">
                            {` ${
                              resp.first_name
                                ? `${resp.first_name} ${resp.last_name}`
                                : resp.username
                            }`}
                          </span>
                          <br />
                        </span>
                      ))}
                    </p>
                  </div>
                  <div className="my-5">
                    <p>Colaboradores</p>
                    <AutocompleteMultipleInput
                      limitTags={-1}
                      width="100%"
                      value={detail.users_collaborators}
                      name="users_collaborators"
                      onChange={handleChange}
                      options={operativeUsers}
                      disabled={detail.inputDisabled}
                    />
                  </div>
                  <div className="d-flex justify-content-between my-5">
                    <p>Fecha de entrega</p>
                    <p>{detail.date_input}</p>
                  </div>
                  <div className="d-flex justify-content-between my-5">
                    <p>Última actualización</p>
                    <p>{detail.updated_at}</p>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={deleteAttachment}
        onClose={() => setDeleteAttachment(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Está seguro que desea eliminar el archivo?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteAttachment(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              onRemoveAttachmentFile();
            }}
            color="primary"
            variant="contained"
            autoFocus
            disabled={deleting}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </BaseModal>
  );
};

export default ToDoDetailTaskModal;
