import React from "react";
import FileColumnIcon from "./list-icons/FileColumnIcon";
import CloseColumnIcon from "./list-icons/CloseColumnIcon";
import EyeColumnIcon from "./list-icons/EyeColumnIcon";

export const RequirementActionColumnFormatter = (
					cellContent,
					row,
					rowIndex,
					{ onFile, sendMail, onDetail, onCancel, onTrashDraft, onDraft }
				) => (
					<div className={`d-flex justify-content-between`} style={{ minWidth: 120 }}>
            <EyeColumnIcon onClick={() => onDetail(row.id)}/>
            <FileColumnIcon
              tooltip='Ver PDF'
              onClick={() => onFile(row.id)}
            />
            <CloseColumnIcon
              tooltip='Cancelar'
              onClick={() => onCancel(row.id)}
              disabled={row.cf_estatus === "B"}
            />
					</div>
				);
