import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import {
  setArtWorkDrops,
  setArtWorks,
  setFilteredArtWork,
  setLoading,
  setSelectedArtWork,
  setSelectedArtWorkIds,
} from "../../../redux/ducks/artWorks.duck";
import DropModal from "../../components/DropModal";
import FullTable from "../../components/FullTable";
import notice from "../../components/Notice";
import {
  createArtworkDrop,
  getArtWorkDropsList,
  getArtWorksList,
} from "../../crud/artWorksCrud";
import {
  artWorkDropsColumns,
  artWorksColumns,
  defaultFilter,
  filterInputs,
  initialSelectedArtWork,
} from "../../utils/artWorksData";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";
import { defaultDrop } from "../../utils/catalogsData";
import { setCurrentMode } from "../../../redux/ducks/subHeaderActions.duck";
import { modeOptions } from "../../utils/subheaderIconsData";

const ArtWorksPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { loading, artWorks, artWorkDrops } = useSelector((store) => store.artWork);
  const { user, permisos } = useSelector((store) => store.auth);
  const [selectedArtWorks, setSelectedArtWorks] = useState([]);
  const [dropAW, setDropAW] = useState(defaultDrop);
  const isDropPage = pathname.includes("bajas");
  const [filter, setFilter] = useState(defaultFilter);

  const handleFilterChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const getFilterParams = useCallback(
    () =>
      filterParams(
        clearEmptyStringsObject(
          cleanObject({ ...filter, cf_num_area: filter.area?.text2 })
        )
      ),
    [filter]
  );

  const onConfirmFilter = () => {
    dispatch(setLoading(true));
    getArtWorksList(artWorks.page, artWorks.per_page, getFilterParams())
      .then((res) => dispatch(setArtWorks(res)))
      .catch((error) => {
        notice("Ha ocurrido un error de conexixón cargando las Obras de Arte", "error");
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const onClearFilter = () => {
    setFilter(defaultFilter);
    dispatch(setLoading(true));
    getArtWorksList(artWorks.page, artWorks.per_page)
      .then((res) => dispatch(setArtWorks(res)))
      .catch((error) => {
        console.log("error", error);
        notice("Ha ocurrido un error de conexixón cargando las Obras de Arte", "error");
      })
      .finally(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    if (isDropPage)
      getArtWorkDropsList(artWorkDrops.page, artWorkDrops.per_page)
        .then((res) => {
          if ([200, 201].includes(res.status))
            dispatch(setArtWorkDrops({ ...res.data, page: artWorkDrops.page }));
          // eslint-disable-next-line no-throw-literal
          else throw { error: res.statusText };
        })
        .catch((error) => {
          console.log("error", error);
          notice("Ha ocurrido un error de conexixón cargando las Obras de Arte", "error");
        })
        .finally(() => dispatch(setLoading(false)));
    else
      getArtWorksList(artWorks.page, artWorks.per_page, getFilterParams())
        .then((res) => dispatch(setArtWorks(res)))
        .catch((error) => {
          console.log("error", error);
          notice("Ha ocurrido un error de conexixón cargando las Obras de Arte", "error");
        })
        .finally(() => dispatch(setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    artWorkDrops.page,
    artWorkDrops.per_page,
    artWorks.page,
    artWorks.per_page,
    dispatch,
    isDropPage,
  ]);

  const onEdit = (id) => {
    dispatch(setFilteredArtWork(initialSelectedArtWork));
    dispatch(setSelectedArtWorkIds([id]));
    dispatch(setCurrentMode(null));
    if (isDropPage) {
      history.push(`/bienes-instrumentales/obras-arte/resultados/bajas/${id}`);
    } else {
      history.push(`/bienes-instrumentales/obras-arte/resultados/${id}`);
    }
  };

  const onDrop = (id) => {
    setDropAW({
      artwork: id,
      motivo_baja: "",
      tipo_baja: "",
    });
  };

  const onConfirmDrop = () => {
    createArtworkDrop(dropAW)
      .then((res) => {
        setDropAW(defaultDrop);
        notice("Obra de arte fue dada de baja", "success");
        getArtWorksList(artWorks.page, artWorks.per_page, getFilterParams())
          .then((res) => dispatch(setArtWorks(res)))
          .catch((error) => {
            console.log("error", error);
            notice(
              "Ha ocurrido un error de conexión cargando las Obras de Arte",
              "error"
            );
          })
          .finally(() => dispatch(setLoading(false)));
      })
      .catch((e) => {
        console.log(e);
        notice("Ha ocurrido un error al dar de baja, favor de volver a intentar");
      });
  };

  const onDropValuesChange = ({ target }) => {
    const { name, value } = target;
    setDropAW({ ...dropAW, [name]: value });
  };

  const handleNewArtWork = () => {
    dispatch(setCurrentMode(modeOptions.new));

    dispatch(setFilteredArtWork(initialSelectedArtWork));
    dispatch(setSelectedArtWork({ ...initialSelectedArtWork, user: user.first_name }));
    dispatch(setSelectedArtWorkIds([]));
    dispatch(setCurrentMode(modeOptions.new));
    history.push("/bienes-instrumentales/obras-arte/nuevo");
  };

  const handleSelectRow = (row, isSelect) => {
    if (row && row.id) {
      if (!isSelect) {
        const newSelectedEntries = selectedArtWorks.filter((id) => id !== row.id);
        setSelectedArtWorks(newSelectedEntries);
      } else {
        setSelectedArtWorks([...selectedArtWorks, row.id]);
      }
    }
  };

  const handleSelectAllRows = (isSelect, rows) =>
    isSelect ? setSelectedArtWorks(rows.map((r) => r.id)) : setSelectedArtWorks([]);

  const handleReqSelected = () => {
    dispatch(setCurrentMode(null));

    dispatch(setSelectedArtWorkIds(selectedArtWorks));
    history.push(`/bienes-instrumentales/obras-arte/resultados/${selectedArtWorks[0]}`);
  };

  const handleonClickSearch = () => {
    dispatch(setSelectedArtWork(initialSelectedArtWork));
    dispatch(setFilteredArtWork(initialSelectedArtWork));
    dispatch(setSelectedArtWorkIds([]));
    dispatch(setCurrentMode(modeOptions.search));

    if (isDropPage) {
      history.push("/bienes-instrumentales/obras-arte/busqueda/bajas");
    } else {
      history.push("/bienes-instrumentales/obras-arte/busqueda");
    }
  };

  const setPage = (page) => {
    setLoading(true);
    return (
      page > 0 &&
      dispatch(
        isDropPage
          ? setArtWorkDrops({ ...artWorkDrops, page: page })
          : setArtWorks({ ...artWorks, page: page })
      )
    );
  };

  const setSizePage = (size) => {
    setLoading(true);
    dispatch(
      isDropPage
        ? setArtWorks({ ...artWorkDrops, per_page: size })
        : setArtWorks({ ...artWorks, per_page: size })
    );
  };

  return (
    <>
      <FullTable
        title="Obras de arte"
        // NEW ARTWORK TRIGGER
        has_button={!isDropPage}
        buttonTitle="Nueva obra de arte"
        onClickButton={handleNewArtWork}
        // TABLE ENTITIES
        entities={isDropPage ? artWorkDrops.results : artWorks.results}
        columns={
          isDropPage
            ? artWorkDropsColumns(onEdit, onDrop, permisos.permisos)
            : artWorksColumns(onEdit, onDrop, permisos.permisos)
        }
        page={isDropPage ? artWorkDrops.page : artWorks.page}
        setPage={setPage}
        sizePerPage={isDropPage ? artWorkDrops.per_page : artWorks.per_page}
        setSizePerPage={setSizePage}
        count={isDropPage ? artWorkDrops.count : artWorks.count}
        loading={loading}
        // SELECTED ARTWORKS ACTIONS
        has_button2
        buttonDisabled2={selectedArtWorks.length < 1}
        buttonTitle2={`Consultar Todos${
          selectedArtWorks.length > 1 ? `(${selectedArtWorks.length.toString()})` : ""
        }`}
        onSelectRow={handleSelectRow}
        onClickButton2={handleReqSelected}
        onSelectAllRows={handleSelectAllRows}
        // ON FILTER ACTION
        onClickSearch={handleonClickSearch}
        // FILTERS
        has_filter={!isDropPage}
        formFilters={filter}
        onFilterChange={handleFilterChange}
        filterInputs={filterInputs}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
      />
      <DropModal
        open={Boolean(dropAW.artwork)}
        onConfirm={onConfirmDrop}
        onCancel={() => setDropAW(defaultDrop)}
        title="Dar de baja la obra de arte"
        onValuesChange={onDropValuesChange}
        formValues={dropAW}
      />
    </>
  );
};

export default ArtWorksPage;
