import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionType = {
  SetFiles: "[FILES] SET_FILES",
};

export const initialState = {
  files: [],
};

export const reducer = persistReducer(
  { storage, key: 'files', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {

      case actionType.SetFiles:
        return {...state, files: action.payload}

      default:
        return state
    }
  }
)

export const setFiles = (files) => ({type: actionType.SetFiles, payload: files});
