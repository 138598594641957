import axios from "axios";
import { getFlattenObject } from "../utils/attachmentsUtils";
import { ASSTETS_STOCK, REQUIREMENT_URL, UPLOAD_FILE_URL } from "./helpers/routes";
import {
  getParams,
  handleError,
  handleFormData,
  handleResponse,
} from "./helpers/validate";

export const listRequirements = async (page, sizePerPage, params = "") => {
  return axios.get(
    `${ASSTETS_STOCK}/requisition?page=${page}&limit=${sizePerPage}&${params}`
  );
};

export const createRequirement = (payload) => {
  return axios.post(`${ASSTETS_STOCK}/requisition`, payload);
};

export const updateRequirement = (payload) => {
  return axios.put(`${ASSTETS_STOCK}/requisition/${payload.id}/`, payload);
};

export const addRequirementFile = async (
  file = null,
  attach_id,
  title = "",
  attach_model = "requisition",
  field = "pdfs"
) => {
  const body = {
    attach_id,
    attach_model,
    image_data: file,
    field,
    title,
  };

  return new axios.post(UPLOAD_FILE_URL, handleFormData(body));
};

export const getSingleReqirement = (id) =>
  fetch(`${ASSTETS_STOCK}/requisition/${id}`, getParams("GET"))
    .then(handleResponse)
    .then((res) => {
      const payload = {
        id: res.id,
        supplier: {
          id: res.supplier?.id,
          text: res.supplier.razon_social?.trim(),
          text2: res.supplier?.num_prove,
        },
        contrato: res.contract_id,
        date_requisition: res.date_requisition, // format date
        max_delivery_date: res.max_delivery_date, // format date
        name_off: res.name_off,
        num_req: res.num_req,
        observations: res.observations,
        requisition_items: res.requisition_item_details,
        all_attachments: getFlattenObject(res.all_attachments),
        contract_set: [],
        requisition_contract_number: res.requisition_contract_number,
      };
      return payload;
    })
    .catch(handleError);

export const getRequirementFile = (id) => {
  return fetch(`${REQUIREMENT_URL}/${id}/get-pdf`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
