export const isConciliated = (cveFirstLetter, formValues) => {
  // S = Si, N = No, R = No requiere

  let siRequiere =
    !!formValues.cf_no_poliza &&
    formValues.cf_no_poliza !== "" &&
    !!formValues.cf_fecha_poliza &&
    formValues.cf_fecha_poliza !== "" &&
    !!formValues.cf_tipo_poliza &&
    formValues.cf_tipo_poliza !== "";

  return cveFirstLetter === "2"
    ? "R"
    : cveFirstLetter === "5" && siRequiere
    ? "S"
    : cveFirstLetter === "5"
    ? "N"
    : "R";
};
