import React from 'react'
import FileColumnIcon from './list-icons/FileColumnIcon'

export const DepartureRecurrentActionsColumnFormatter = (cellContent, row, rowIndex, { onFile, onEye, onCross }) => {
  return (
    <>
		<FileColumnIcon onClick={() => onFile(cellContent)}/>
	</>
  )
}
