import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  IconButton,
  withStyles,
  Chip,
  colors,
  Menu,
  MenuItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText,
  Typography,
  Box,
  Card,
  Modal,
  CardContent,
  CardHeader as MuiCardHeader,
} from "@material-ui/core";
import {
  AddRounded as AddIcon,
  CloseRounded as CloseIcon,
  Minimize as MinimizeIcon,
  Menu as MenuIcon,
} from "@material-ui/icons";
import className from "classnames";
import Draggable from "react-draggable";
import { useSelector, useDispatch } from "react-redux";
import {
  setTabTarget,
  addNewTab,
  removeTab,
  setTabMinimize,
  windowSelector,
  setTabPosition,
} from "../../redux/ducks/window.duck";
import FilterForm from "./FilterForm";
import { useComponentVisible } from "../hooks/useComponentVisible";

const BootstrapButton = withStyles({
  root: {
    height: "100%",
    background: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
})(IconButton);

const Container = withStyles({
  root: {
    height: 40,
    "&.active": {
      // borderTop: "1px black solid",
      backgroundColor: colors.grey["100"],
    },
  },
})(Grid);

const ListItemIcon = withStyles({
  root: {
    minWidth: 25,
  },
})(MuiListItemIcon);

export const Tab = withStyles({
  root: {
    borderRadius: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    backgroundColor: colors.grey["100"],
    "&:hover": {
      cursor: "pointer",
      backgroundColor: colors.grey["100"],
    },
    "&.target": {
      backgroundColor: colors.grey["400"],
    },
    "&:not(.target):not(.prev)": {
      "&:after": {
        content: "''",
        right: 0,
        position: "absolute",
        width: 1,
        height: "50%",
        background: colors.grey["400"],
      },
    },
  },
  deleteIcon: {
    width: 15,
    height: 15,
  },
  clickable: {
    cursor: "pointer",
    "&:hover, &:focus": {
      backgroundColor: colors.grey["100"],
      "&.target": {
        backgroundColor: colors.grey["400"],
      },
    },
    "&:active": {
      backgroundColor: colors.grey["100"],
    },
  },
})(Chip);

const Window = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: "5px 5px 5px 24px",
    backgroundColor: "#1e1e2d",
    cursor: "move",
  },
  closeButton: {
    color: "#FFF",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  minButton: {
    color: "#FFF",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  dialogTitleText: {
    color: "#FFF",
  },
}))(
  ({
    classes,
    onClose,
    onMinimize,
    show,
    title,
    children,
    onDrag,
    position,
    onStop,
    modalRef,
  }) => {
    return (
      <Modal
        hideBackdrop
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={show}
      >
        <Draggable
          position={position}
          grid={[25, 25]}
          handle={"#filter-dialog-window"}
          onDrag={onDrag}
          onStop={onStop}
        >
          <Card ref={modalRef} style={{ width: 720 }} tabIndex={-1} raised>
            <MuiCardHeader
              titleTypographyProps={{
                variant: "h6",
                classes: { root: classes.dialogTitleText },
              }}
              id={"filter-dialog-window"}
              className={classes.root}
              action={
                <div>
                  {onMinimize && (
                    <IconButton
                      disableRipple
                      disableFocusRipple
                      // edge={"end"}
                      aria-label={"minimize"}
                      className={classes.minButton}
                      onClick={() => onMinimize()}
                    >
                      <MinimizeIcon />
                    </IconButton>
                  )}
                  {onClose && (
                    <IconButton
                      aria-label={"close"}
                      className={classes.closeButton}
                      onClick={() => onClose()}
                      disableRipple
                      disableFocusRipple
                      edge={"start"}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>
              }
              title={title}
            />
            <CardContent>{children}</CardContent>
          </Card>
        </Draggable>
      </Modal>
    );
  }
);

const MainMenu = ({ anchorEl, open, onClose, onClickItem, selectedItems = [] }) => {
  const {
    configuration: { menuItems },
  } = useSelector(windowSelector);
  return (
    <Menu
      anchorEl={anchorEl}
      id={open ? "window-popover" : undefined}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {menuItems.map((module) => (
        <MenuItem
          onClick={() => onClickItem(module)}
          key={module.id}
          disabled={module.disabled || selectedItems.includes(module.id)}
        >
          <ListItemIcon>{module.icon}</ListItemIcon>
          <ListItemText primary={module.name} />
        </MenuItem>
      ))}
    </Menu>
  );
};

const FilterWindow = () => {
  const dispatch = useDispatch();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(
    true,
    "mousedown"
  );
  const {
    configuration: { tabs, activeTab },
  } = useSelector(windowSelector);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [anchor, setAnchor] = useState(null);

  useEffect(() => {
    if (Boolean(activeTab)) {
      setPosition({ x: activeTab.x, y: activeTab.y });
    }
  }, [activeTab]);

  const handleDrag = useCallback((e, data) => {
    const { lastX, lastY } = data;
    setPosition({ x: lastX, y: lastY });
  }, []);

  const handleMinimize = useCallback(() => {
    if (activeTab?.id) {
      dispatch(setTabPosition(activeTab.id, position));
      dispatch(setTabMinimize(activeTab.id));
    }
  }, [activeTab?.id, dispatch, position]);

  useEffect(() => {
    if (!isComponentVisible) handleMinimize();
  }, [handleMinimize, isComponentVisible]);
  return (
    <>
      <Window
        modalRef={ref}
        position={position}
        show={Boolean(activeTab) && isComponentVisible}
        title={activeTab?.name}
        onClose={() => {
          if (activeTab) {
            dispatch(removeTab(activeTab.id));
          }
        }}
        onMinimize={handleMinimize}
        onDrag={handleDrag}
        onStop={(e, data) => {
          if (activeTab?.id) {
            const { lastX, lastY } = data;
            dispatch(setTabPosition(activeTab.id, { x: lastX, y: lastY }));
          }
        }}
      >
        <FilterForm NameCatalogo={activeTab?.id} />
      </Window>
      <Box
        boxShadow={3}
        style={{
          position: "fixed",
          zIndex: 9999,
          left: 0,
          bottom: 0,
          width: tabs.length ? "100%" : "auto",
        }}
      >
        <MainMenu
          onClose={() => setAnchor(null)}
          open={Boolean(anchor)}
          anchorEl={anchor}
          selectedItems={tabs.map((window) => window.id)}
          onClickItem={(module) => {
            setIsComponentVisible(true);
            dispatch(addNewTab(module));
            setAnchor(null);
          }}
        />
        <Container
          container
          className={className("", {
            active: true,
          })}
        >
          <Grid item container md={12} spacing={1} style={{ display: "flex" }}>
            <Grid
              item
              style={{ display: "flex" }}
              onClick={(event) => setAnchor(anchor ? null : event.currentTarget)}
            >
              <BootstrapButton disableRipple size={"small"}>
                <MenuIcon size={"small"} />
                <Typography variant="button">Catalogos</Typography>
              </BootstrapButton>
              <BootstrapButton disableRipple size={"small"}>
                <AddIcon />
              </BootstrapButton>
            </Grid>
            {tabs.length > 0 && (
              <Grid item container md={10}>
                {tabs.map((tab, key) => {
                  return (
                    <Grid item key={tab.name}>
                      <Tab
                        style={{ height: "100%" }}
                        deleteIcon={<CloseIcon />}
                        className={className({
                          target: tab.target,
                          prev: tabs[key + 1]?.target,
                        })}
                        label={tab.name}
                        onDelete={
                          tab.target ? undefined : () => dispatch(removeTab(tab.id))
                        }
                        //onDelete={() => dispatch(removeTab(tab.id))}
                        onClick={() => {
                          if (activeTab?.id) dispatch(setTabMinimize(activeTab.id));
                          dispatch(setTabTarget(tab.id));
                          setIsComponentVisible(true);
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default FilterWindow;
