import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import uuid from "react-uuid";
import DropInputsForm from "../../components/DropInputsForm";
import HistorialTable from "../../components/HistorialTable";
import PDFUploaderTab from "../../components/PDFUploaderTab";
import CustomButton from "../../components/CustomButton";
import PageLayout from "../../components/PageLayout";
import FileButton from "../../components/FileButton";
import BaseModal from "../../components/BaseModal";
import UtilInputs from "../../components/UtilInputs";
import SimplePagination from "../../components/SimplePagination";
import CheckboxList from "../../components/CheckboxList";
import AntTabs from "../../components/AntTabs";
import notice from "../../components/Notice";
import GuardTab from "./Tabs/GuardTab";
import ReportsTab from "./Tabs/ReportsTab";
import LastSeenTab from "./Tabs/LastSeenTab";
import { FinantialTab } from "./Tabs/FinantialTab";
import {
  setSearchStockForm,
  setSelectedStock,
  setStockDataAvailableToCopy,
  clearStockFormDataAvailableToCopy,
  setStockFieldsToUpdate,
} from "../../../redux/ducks/stock.duck";
import {
  handleChangeValidations,
  historialTableColumns,
  stockInputs,
  inputsLabelNameToCopy,
} from "../../utils/stockData";
import {
  getGuardHistory,
  updateFile,
  updateMultipleStockFields,
} from "../../crud/stockCrud";
import { setLoader } from "../../../redux/ducks/loader.duck";
import { setCurrentMode } from "../../../redux/ducks/subHeaderActions.duck";
import DigitalizationListDocs from "../DigitalVault/components/common/DigitalizationListDocs";
import { isConciliated } from "../../utils/batchesUtils";

export const StockForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isDropPage = pathname.includes("bajas");
  const { currentMode } = useSelector((store) => store.subHeaderActions);
  const { isSearchPage, isResultsPage } = useSelector((store) => store.pathname);
  const { permisos } = useSelector((store) => store.auth);

  const {
    selectedStockForm,
    searchStockForm,
    selectedStocks,
    idsFilterResult,
    stockFormAvailableToCopy,
    stockFieldsToUpdate,
  } = useSelector((store) => store.stock);

  const selectedStock = isSearchPage ? searchStockForm : selectedStockForm;
  const [imagesPreview, setImagesPreview] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [historial, setHistorial] = useState([]);
  const [openModalToCopy, setOpenModalToCopy] = useState(false);
  const [dataToCopy, setDataToCopy] = useState([]);
  const [firstLetterPartida, setFirstLetterPartida] = useState("");

  const handleForm = (e) => {
    if (e && e.target) {
      let newData = handleChangeValidations(selectedStock, e.target, currentMode);
      const formData = handleChangeValidations(selectedStock, e.target, currentMode);

      // const data = generateDataToMultipleEdit( selectedStock?.id, e.target, currentMode );

      let labelName = inputsLabelNameToCopy.find(({ name }) => name === e.target.name);
      const { name, value } = e.target;

      if (name === "cf_cve_area") {
        if (value.includes(" ")) {
          newData = { ...formData, [name]: value.replaceAll(" ", "|") };
        }
      }

      if (labelName) {
        if (name === "cve_partida") {
          const cveFirstLetter = value.text2?.toString().charAt(0) || "";
          setFirstLetterPartida(cveFirstLetter);
          const cf_conciliado = isConciliated(cveFirstLetter, formData);
          newData = { ...formData, [name]: value, cf_conciliado };
        } else if (name === "cf_no_poliza") {
          const cf_conciliado = isConciliated(firstLetterPartida, formData);
          newData = { ...formData, [name]: value, cf_conciliado };
        } else if (name === "cf_fecha_poliza") {
          const cf_conciliado = isConciliated(firstLetterPartida, formData);
          newData = { ...formData, [name]: value, cf_conciliado };
        } else if (name === "cf_tipo_poliza") {
          const cf_conciliado = isConciliated(firstLetterPartida, formData);
          newData = { ...formData, [name]: value, cf_conciliado };
        }

        dispatch(
          setStockDataAvailableToCopy({
            name: e.target.name,
            label: labelName.label,
            value: e.target.value,
          })
        );
        if (selectedStock.id)
          dispatch(
            setStockFieldsToUpdate({
              ...stockFieldsToUpdate,
              [selectedStock.id]: {
                ...stockFieldsToUpdate[selectedStock.id],
                [e.target.name]: e.target.value,
              },
            })
          );
        newData &&
          dispatch(
            isSearchPage ? setSearchStockForm(newData) : setSelectedStock(newData)
          );
      }
    }
  };

  const handleClickItem = async (index) => {
    if (isDropPage) {
      history.push(
        `/bienes-instrumentales/inventario-detalles/bajas/resultados/${
          idsFilterResult[index - 1]
        }`
      );
    } else {
      history.push(
        `/bienes-instrumentales/inventario-detalles/resultados/${
          idsFilterResult[index - 1]
        }`
      );
    }
  };

  const onSuccessUploadPDFs = (responses) => {
    const newAttachments = responses.map((res) => res.data);
    const newSelectedStock = {
      ...selectedStock,
      attachment_pdfs: [...selectedStock?.attachment_pdfs, ...newAttachments],
    };
    dispatch(setSelectedStock(newSelectedStock));
  };

  const onSucessRemovePDF = (deletedPDFId) => {
    const newAttachments = selectedStock?.attachment_pdfs.filter(
      (attachment) => attachment.id !== deletedPDFId
    );
    const newSelectedStock = {
      ...selectedStock,
      attachment_pdfs: newAttachments,
    };
    dispatch(setSelectedStock(newSelectedStock));
  };

  useEffect(() => {
    if (selectedTab === 4 && selectedStock?.id) {
      getGuardHistory(selectedStock?.id)
        .then((res) => {
          const guardHistorial = res.data.map(({ resguardado_el, ...historial }) => ({
            ...historial.new_area_detail,
            cf_responsables:
              historial.new_area_detail?.area_attendant.filter(
                (area) => area?.person !== null
              ).length === 1
                ? historial.new_area_detail?.area_attendant
                    .map((area) => area?.person?.nombre)
                    .join(" ")
                : historial.new_area_detail?.area_attendant
                    .map((area) => area?.person?.nombre)
                    .join(", "),
            id: uuid(),
            resguardado_el,
          }));
          return setHistorial(guardHistorial);
        })
        .catch((error) => {
          console.log("err", error);
          notice("Ha ocurrido un error obteniendo el historial ");
        });
    }
  }, [selectedTab, selectedStock?.id, dispatch]);

  const copyDataModal = () => {
    setOpenModalToCopy(true);
    setDataToCopy(Object.keys(stockFormAvailableToCopy));
  };

  const onChangeDataToCopy = (data) => {
    setDataToCopy(data);
  };

  const handleCopyToAllSelectedStocks = () => {
    dispatch(setLoader(true));
    // eslint-disable-next-line no-sequences
    let values = dataToCopy
      .map((item) => stockFormAvailableToCopy[item])
      // eslint-disable-next-line no-sequences
      .reduce((obj, item) => ((obj[item.name] = item.value), obj), {});
    const requestedData = idsFilterResult.map((id) => ({ id, ...values }));
    updateMultipleStockFields(requestedData)
      .then((res) => {
        notice(
          `${
            selectedStocks.length > 1
              ? "Se han editado los bienes exitosamente"
              : "Se ha editado el bien exitosamente"
          }`,
          "success"
        );
        dispatch(setCurrentMode(null));
        setOpenModalToCopy(false);
        dispatch(clearStockFormDataAvailableToCopy());
        dispatch(
          setSelectedStock({
            ...selectedStock,
            cf_fecha_actuali: res[0]?.cf_fecha_actuali,
          })
        );
        // setOriginalStock(selectedStockForm)
      })
      .catch((e) => {
        if (e.error) notice(e.error);
        else notice("Ha ocurrido un error con la solicitud", "error");

        throw e;
      })
      .finally(() => dispatch(setLoader(false)));
  };
  if (selectedStock?.cf_cve_partida) {
    const cf_cve_partida = selectedStock["cf_cve_partida"].toString()[0];
    selectedStock["cf_conciliado"] = isConciliated(cf_cve_partida, selectedStock);
  }
  return (
    <PageLayout
      header={
        <div className="w-100">
          <div className="d-flex justify-content-between mb-3 w-100">
            <h6>
              {pathname.includes("bajas")
                ? "Inventario de bajas en Bienes Instrumentales"
                : "Inventario de Bienes Instrumentales"}
            </h6>
            {!isDropPage && isResultsPage && (
              <div className="d-flex">
                {permisos.permisos.includes("i-subir-archivo-inventario") && (
                  <FileButton
                    handleChange={(file) => {
                      if (file) {
                        updateFile(file, selectedStock?.id, "inventory", file.name).then(
                          (res) =>
                            handleForm({
                              target: { name: "foto_data", value: res },
                            })
                        );
                      }
                    }}
                    value={selectedStock?.attachment}
                    imagesPreview={imagesPreview}
                    setImagesPreview={setImagesPreview}
                  />
                )}
                <CustomButton
                  title={
                    <>
                      <i className="flaticon2-search mr-2" />
                      Ver foto
                    </>
                  }
                  disabled={!selectedStock?.foto_data}
                  color="info"
                  outline
                  onClick={() => window.open(selectedStock?.foto_data?.url, "_blank")}
                />
                {selectedStocks.length > 1 && (
                  <CustomButton
                    outline
                    title={
                      <>
                        <i className="flaticon2-copy mr-2" />
                        {`Copiar (${Object.keys(stockFormAvailableToCopy).length})`}
                      </>
                    }
                    disabled={Object.keys(stockFormAvailableToCopy).length === 0}
                    color="info"
                    onClick={() => {
                      copyDataModal();
                    }}
                  />
                )}
              </div>
            )}
          </div>
          {isResultsPage && idsFilterResult.length > 1 && (
            <>
              <SimplePagination
                current={selectedStockForm?.id}
                data={idsFilterResult}
                handleClickItem={handleClickItem}
              />
            </>
          )}
        </div>
      }
    >
      {isDropPage && (
        <DropInputsForm formValues={selectedStock} onValuesChange={handleForm} />
      )}
      <UtilInputs
        inputValues={stockInputs(!currentMode, isSearchPage)}
        formValues={selectedStock}
        onValuesChange={handleForm}
        width={200}
      />
      <br />
      <AntTabs
        color="primary"
        tabs={
          isResultsPage
            ? [
                { label: "Datos del resguardo", value: 0, color: "#3699FF" },
                { label: "Datos financieros", value: 1, color: "#1BC5BD" },
                { label: "Datos Reportados", value: 2, color: "#7337EE" },
                { label: "Movimientos", value: 3, color: "#F64E60" },
                { label: "Historial de resguardo", value: 4, color: "#FFA800" },
                {
                  label: "Historial de inventario",
                  value: 5,
                  color: "#521332",
                },
              ]
            : [
                { label: "Datos del resguardo", value: 0, color: "#3699FF" },
                { label: "Datos financieros", value: 1, color: "#1BC5BD" },
                { label: "Datos Reportados", value: 2, color: "#7337EE" },
              ]
        }
        selectedValue={selectedTab}
        onChangeTab={(e, newTab) => setSelectedTab(newTab)}
      />
      {selectedTab === 0 ? (
        <GuardTab
          onChange={handleForm}
          selectedStock={selectedStock}
          currentMode={currentMode}
        />
      ) : selectedTab === 1 ? (
        <FinantialTab
          onChange={handleForm}
          selectedStock={selectedStock}
          currentMode={currentMode}
        />
      ) : selectedTab === 2 ? (
        <ReportsTab
          onChange={handleForm}
          selectedStock={selectedStock}
          currentMode={currentMode}
        />
      ) : isResultsPage && selectedTab === 3 ? (
        <PDFUploaderTab
          disabled={!currentMode}
          attachment_pdfs={selectedStock?.attachment_pdfs}
          id={selectedStock?.id}
          idList={selectedStocks}
          pdfTitleName="inventario"
          attach_model="inventory"
          field="pdfs"
          onSuccessUpload={onSuccessUploadPDFs}
          onSucessRemove={onSucessRemovePDF}
          limitFiles={10}
        />
      ) : isResultsPage && selectedTab === 4 ? (
        <HistorialTable columns={historialTableColumns} data={historial} />
      ) : (
        isResultsPage &&
        selectedTab === 5 && (
          <LastSeenTab onChange={handleForm} selectedStock={selectedStock} />
        )
      )}
      {selectedStockForm.all_attachments && (
        <DigitalizationListDocs
          data={selectedStockForm.all_attachments}
          newDigitalization
          module={"inventory"}
          attachTo={selectedStockForm.id}
        />
      )}
      <BaseModal
        title={<span>Seleccione los campos</span>}
        open={openModalToCopy}
        onClose={() => setOpenModalToCopy(false)}
        onConfirm={handleCopyToAllSelectedStocks}
        confirmLabel="Guardar y copiar"
        onCancel={() => setOpenModalToCopy(false)}
        cancelLabel="Cerrar"
        maxWidth="sm"
        color="secondary"
        disableOnConfirm={false}
      >
        <CheckboxList data={stockFormAvailableToCopy} onChangeData={onChangeDataToCopy} />
      </BaseModal>
    </PageLayout>
  );
};
