import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import EntryActionIcons from "../../components/EntryActionIcons";
import ModeTabs from "../../components/ModeTabs";
import notice from "../../components/Notice";
import {
  clearContract,
  setContract,
  setContractIds,
  setFilteredContract,
} from "../../../redux/ducks/contracts.duck";

import {
  createContract,
  editContract,
  getContractList,
  addContractFile,
} from "../../crud/contractsCrud";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";
import {
  defaultContractsFilter,
  defaultContractsForm,
  getEditPayload,
  getSubmitPayload,
} from "../../utils/contractsData";
import { setLoader } from "../../../redux/ducks/loader.duck";

const ContractActions = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {
    contract,
    filteredContract,
    selectedContractIds,
    filesToUpload,
  } = useSelector((store) => store.contracts);
  const { user } = useSelector((store) => store.auth);
  const [actionDisable, setActionDisable] = useState(false);

  const handleEdit = () => {
    setActionDisable(true);
    dispatch(setLoader(true));
    let payload = getEditPayload(contract);
    const contractId = payload.id;
    payload = {
      // ...payload,
      // supplier_number: payload.supplier_number,
      // contract_type: payload.contract_type,
      // contract_number: payload.contract_number,
      // date_start: payload.date_start,
      // date_end: payload.date_end,
      // observations: payload.observations,
      // subtotal: payload.subtotal,
      // iva: payload.iva,
      // total: payload.total,
      // user: user.id,
      // leader: payload.leader,
      // in_charge: payload.in_charge,
      // contract_sets: payload.contract_set.map(({ set_item, available_quantity, progress, ...contract }) => ({
      //   // ...contract,
      //   partida_number:contract.budget_start,
      //   id: contract.id,
      //   minimum_amount: contract.minimum_amount,
      //   maximum_amount: contract.maximum_amount,
      //   set_items: set_item.map(item => {
      //     return {
      //     // ...item,
      //     id: contract.id ,
      //     contract_consecutive: item.cons,
      //     cve_assets_category: item.cve_article ? item.cve_article : undefined,
      //     name_assets_category: item.article.text || item.article.toUpperCase(),
      //     unit_measurement: item.unit_measurement,
      //     unit_price: item.unit_price,
      //     articulo_marca: item.articulo_marca,
      //     articulo: item?.articulo,
      //     presentacion: item?.presentacion?.id ?? null
      //   }})
      // }))
      legal_representative: payload.legal_representative,
      contract_sets: payload.contract_set.map(({ set_item, ...contract }) => {
        return {
          id: contract.id,
          maximum_amount: contract.maximum_amount,
          set_items: set_item.map((item) => {
            return {
              id: contract.id,
              articulo_marca: item.articulo_marca,
              articulo_modelo: item.articulo_modelo,
              articulo_descripcion:
                item.articulo_descripcion === undefined
                  ? ""
                  : item.articulo_descripcion,
            };
          }),
        };
      }),
    };
    delete payload.contract_set;
    editContract(contractId, payload)
      .then((res) => {
        if (res && [200, 201].includes(res.status)) {
          notice("¡El contrato se ha editado exitosamente!", "success");
          history.push("/inventario-bienes-almacen-general/contratos");
          dispatch(clearContract());
        } else {
          setActionDisable(false);
          setActionDisable(false);
          if (res?.response?.data?.date_start)
            notice("Debes ingresar una Fecha de inicio válida", "error");
          else if (res?.response?.data?.date_end)
            notice("Debes ingresar una Fecha fin válida", "error");
          else throw new Error("Error");
        }
      })
      .catch((error) => {
        console.log("error", error?.response);
        notice("Ha ocurrido un error editando el contrato", "error");
      })
      .finally(() => {
        setActionDisable(false);
        dispatch(setLoader(false));
      });
  };

  const handleSubmit = () => {
    const payload = getSubmitPayload(contract, user);
    console.log(payload);
    // noinspection JSAnnotator
    if (!payload.supplier_number) {
      notice("Debes agregar un Proveedor", "warning");
      return;
    } else if (
      payload.contract_sets.some(
        (set) => set.maximum_amount < set.minimum_amount
      )
    ) {
      notice(
        "Importe máximo debe ser mayor o igual al importe mínimo",
        "warning"
      );
      return;
    } else if (!payload.contract_number) {
      notice("Debes agregar un Contrato", "warning");
      return;
    } else if (!payload.contract_type) {
      notice("Debes seleccionar un tipo de contrato", "warning");
      return;
    } else if (!payload.date_start) {
      notice("Debes agregar Fecha de inicio", "warning");
      return;
    } else if (!payload.date_end) {
      notice("Debes agregar Fecha fin", "warning");
      return;
    } else if (payload.contract_sets.length === 0) {
      notice("Debes agregar al menos 1 partida", "warning");
      return;
    } else if (
      payload.contract_sets.some(({ budget_start }) => budget_start === "")
    ) {
      notice("Debes asignar un numero a todas las partidas.", "warning");
      return;
    } else if (
      payload.contract_sets.some((set1, index, sets) =>
        sets.some(
          (set2, index2) =>
            index !== index2 && set1.budget_start === set2.budget_start
        )
      )
    ) {
      notice("No pueden haber numeros de partida duplicados.", "warning");
      return;
    }
    let error = null;
    payload.contract_sets.forEach((contract, index) => {
      if (error) return;
      // if(!contract.minimum_amount){
      //   error = `Debes agregar el Importe mínimo de la partida ${index+1}`;
      //   return;
      // }
      else if (!contract.maximum_amount) {
        error = `Debes agregar el Importe máximo de la partida ${contract.budget_start}`;
        return;
      }
      contract.set_items.forEach((item, itemIndex, array) => {
        if (!item.contract_consecutive) {
          error = `La partida ${contract.budget_start} no puede contener consecutivos vacios`;
          return;
        } else if (
          array.some(
            ({ contract_consecutive }, idx) =>
              contract_consecutive === item.contract_consecutive &&
              idx !== itemIndex
          )
        ) {
          error = `La partida ${contract.budget_start} no puede contener consecutivos duplicados`;
          return;
        } else if (!item.name_assets_category) {
          error = `Debes agregar el Nombre de artículo del item ${itemIndex +
            1} de la partida ${contract.budget_start}`;
          return;
        } else if (!item.unit_measurement) {
          error = `Debes agregar el Artículo del item ${itemIndex +
            1} de la partida ${contract.budget_start}`;
          return;
        } else if (!item.unit_price) {
          error = `Debes agregar el Precio unitario del item ${itemIndex +
            1} de la partida ${contract.budget_start}`;
          return;
        } else if (
          payload.contract_type === "RECURRENTE" &&
          item.cve_assets_category < 9000
        ) {
          error = `Algunos articulos no pertenecen a este grupo`;
          return;
        } else if (
          payload.contract_type === "STOCK" &&
          item.cve_assets_category >= 9000
        ) {
          error = `Algunos articulos no pertenecen a este grupo`;
          return;
        }
      });
    });
    if (error) {
      notice(error, "error", 5000);
      return;
    }
    dispatch(setLoader(true));
    setActionDisable(true);
    createContract(payload)
      .then(async (res) => {
        if ([200, 201].includes(res?.status)) {
          //Upload PDF
          let message = {
            status: "success",
            message: ".",
          };
          if (res?.data?.id) {
            const uploadContracts = await Promise.all(
              filesToUpload.map((contract) =>
                addContractFile(contract, res.data.id, contract.name)
              )
            );
            if (
              uploadContracts.some((response) =>
                [500, 422].includes(response?.response?.status)
              )
            ) {
              message.status = "info";
              message.message =
                ", alguno de los archivos, no se pudieron subir";
            }
          }
          history.push("/inventario-bienes-almacen-general/contratos");
          notice(
            `Se creo el contrato exitosamente${message.message}`,
            message?.status ?? "success"
          );
        } else {
          setActionDisable(false);
          if (res?.response?.data?.contract_number) {
            notice(res.response.data.contract_number, "error");
          } else {
            notice("No se ha podido crear el contacto", "error");
          }
        }
      })
      .catch((e) => {
        notice("No se ha podido crear el contacto", "error");
      })
      .finally(() => {
        setActionDisable(false);
        dispatch(setLoader(false));
      });
  };

  const onNewRegister = () => {
    history.push("/inventario-bienes-almacen-general/contratos/nuevo");
    if (contract.id) dispatch(setContract(defaultContractsForm));
  };

  const handleResults = () => {
    if (contract.id) {
      history.push(
        `/inventario-bienes-almacen-general/contratos/${contract.id}`
      );
    } else if (selectedContractIds[0]) {
      history.push(
        `/inventario-bienes-almacen-general/contratos/${selectedContractIds[0]}`
      );
    } else {
      notice(
        "No se encontraron registros guardados, pruebe realizando una búsqueda",
        "info"
      );
      dispatch(setFilteredContract(defaultContractsForm));
      history.push("/inventario-bienes-almacen-general/contratos/busqueda");
    }
  };

  const handleSearch = () => {
    setActionDisable(true);
    const payload = filterParams(
      clearEmptyStringsObject(cleanObject(filteredContract))
    );
    getContractList(100, 1, payload)
      .then((res) => {
        if (res.data?.results[0]?.id) {
          dispatch(setFilteredContract(defaultContractsFilter));
          dispatch(setContractIds(res.data?.results.map((x) => x.id)));
          history.push(
            `/inventario-bienes-almacen-general/contratos/${res.data.results[0].id}`
          );
        } else
          notice(
            "No se encontraron contratos que coincidan con su búsqueda",
            "info"
          );
      })
      .catch(console.log)
      .finally(() => setActionDisable(false));
  };

  return (
    <>
      <ModeTabs
        onSearch={() =>
          history.push("/inventario-bienes-almacen-general/contratos/busqueda")
        }
        onNew={onNewRegister}
        onResults={handleResults}
      />
      <EntryActionIcons
        showIcons={{
          save: !location.pathname.includes("busqueda") && !contract.id,
          edit: !location.pathname.includes("busqueda") && contract.id,
          search: location.pathname.includes("busqueda"),
        }}
        labels={{
          save: "Crear contrato",
          edit: "Guardar cambios",
        }}
        handleSubmit={handleSubmit}
        handleEdit={handleEdit}
        handleSearch={handleSearch}
        disabledIcons={{
          save: actionDisable,
          edit: actionDisable,
          search: actionDisable,
        }}
      />
    </>
  );
};

export default ContractActions;
