import React from 'react'
import uuid from 'react-uuid';
import { useDispatch, useSelector } from 'react-redux';
import { defaultArtwork, defaultMaterial, material_columns2, material_columns3 } from '../../../utils/stockSurveyData';
import { SetInventory } from '../../../../redux/ducks/inventory.duck';
import BatchTable from '../../../components/BatchTable';

export const SurveySurplus = () => {

  const isSurveyPlus = true;

  const dispatch = useDispatch()
  const { selectedInventory } = useSelector(state => state.inventory)
  const { currentMode } = useSelector(store => store.subHeaderActions);

  //      HANDLERS FOR ARTICLES ITEMS TABLE
  const handleBatches = (i, field, value, aIndex = '') => {
    if (field === "inventory") {
      let updatedState = selectedInventory.inventory_surplus
      updatedState[aIndex][field] = value?.text
      updatedState[aIndex]["inventory_name"] = value?.text2
      updatedState[aIndex]["inventory_area_cve"] = value?.cf_cve_area
      dispatch(SetInventory({ ...selectedInventory, inventory_surplus: updatedState }));
    } else if (field === "inventory_area_destination") {
      let updatedState = selectedInventory.inventory_surplus
      updatedState[aIndex][field] = value?.num_area
      dispatch(SetInventory({ ...selectedInventory, inventory_surplus: updatedState }));
    } else if (field === "inventory_area_found_cve") {
      let updatedState = selectedInventory.inventory_surplus
      updatedState[aIndex][field] = value?.num_area
      dispatch(SetInventory({ ...selectedInventory, inventory_surplus: updatedState }));
    }
    else return
  };

  const onSearchTextChange = (i, field, value, aIndex) => {
    let state = selectedInventory.inventory_surplus
    if (!value?.text) {
      if (field === "inventory") {
        state[aIndex][field] = value;
        dispatch(SetInventory({ ...selectedInventory, inventory_surplus: state }));
      } else if (field === "inventory_area_destination") {
        state[aIndex][field] = value;
        dispatch(SetInventory({ ...selectedInventory, inventory_surplus: state }));
      } else if (field === "inventory_area_found_cve") {
        state[aIndex][field] = value;
        dispatch(SetInventory({ ...selectedInventory, inventory_surplus: state }));
      } else return
    } else return
  };

  const addArticle = (i) =>
    dispatch(SetInventory({ ...selectedInventory, inventory_surplus: [...selectedInventory.inventory_surplus, { ...defaultMaterial, id: uuid() }] }));
  
  const deleteArticle = (item, index) => {
    let updatedState = selectedInventory.inventory_surplus
    updatedState.splice(index, 1)
    dispatch(SetInventory({ ...selectedInventory, inventory_surplus: updatedState }));
  };
  //      HANDLERS FOR ARTWORK ITEMS TABLE
  const handleBatchAW = (i, field, value, aIndex = '') => {
    if (field === "artwork") {
      let updatedState = selectedInventory.artworks_surplus
      updatedState[aIndex][field] = value?.text
      updatedState[aIndex]["inventory_name"] = value?.text2
      updatedState[aIndex]["inventory_area_cve"] = value?.cf_num_area
      dispatch(SetInventory({ ...selectedInventory, artworks_surplus: updatedState }));
    } else if (field === "inventory_area_destination") {
      let updatedState = selectedInventory.artworks_surplus
      updatedState[aIndex][field] = value?.num_area
      dispatch(SetInventory({ ...selectedInventory, artworks_surplus: updatedState }));
    } else if (field === "inventory_area_found_cve") {
      let updatedState = selectedInventory.artworks_surplus
      updatedState[aIndex][field] = value?.num_area
      dispatch(SetInventory({ ...selectedInventory, artworks_surplus: updatedState }));
    }
    else return
  }

  const onSearchArtwork = (i, field, value, aIndex) => {
    let state = selectedInventory.artworks_surplus
    if (!value?.text) {
      if (field === "artwork") {
        state[aIndex][field] = value;
        dispatch(SetInventory({ ...selectedInventory, artworks_surplus: state }));
      } else if (field === "inventory_area_destination") {
        state[aIndex][field] = value;
        dispatch(SetInventory({ ...selectedInventory, artworks_surplus: state }));
      } else if (field === "inventory_area_found_cve") {
        state[aIndex][field] = value;
        dispatch(SetInventory({ ...selectedInventory, artworks_surplus: state }));
      } else return
    } else return
  };

  const addArtwork = (i) =>
    dispatch(SetInventory({ ...selectedInventory, artworks_surplus: [...selectedInventory.artworks_surplus, { ...defaultArtwork, id: uuid() }] }));

  const deleteArtwork = (item, index) => {
    let updatedState = selectedInventory.artworks_surplus
    updatedState.splice(index, 1)
    dispatch(SetInventory({ ...selectedInventory, artworks_surplus: updatedState }));
  };

  return (
    <>
      <h6>Bienes sobrantes</h6>
      <br />
      <BatchTable
        i={0}
        items={selectedInventory.inventory_surplus}
        columns={material_columns2(Boolean(selectedInventory.id), Boolean(currentMode), false, isSurveyPlus)}
        handleChange={handleBatches}
        addRow={addArticle}
        onSearchTextChange={onSearchTextChange}
        deleteRow={deleteArticle}
        disableAddItem
      />
      <br />
      <h6>Obras de arte sobrantes</h6>
      <br />
      <BatchTable
        i={0}
        items={selectedInventory.artworks_surplus}
        columns={material_columns3(Boolean(selectedInventory.id), Boolean(currentMode))}
        handleChange={handleBatchAW}
        addRow={addArtwork}
        onSearchTextChange={onSearchArtwork}
        deleteRow={deleteArtwork}
        disableAddItem
      />
    </>
  )
}
