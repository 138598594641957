import React from 'react'
import PropTypes from 'prop-types'
import CustomButton from './CustomButton'
import { Typography } from '@material-ui/core'

const FilterResults = ({
    findingsLabel = 'Resultados encontrados:',
    findingsNumber = 0,
    onClickSeeButton = () => {},
    titleSeeButton = 'Consultar todos',
    bgColorSeeButton = '#dceefd',
    textColorSeeButton = '#1997f7',
    onClickClearButton = () => {},
    titleClearButton = 'Limpiar filtros',
    bgColorClearButton = '#dc0714',
    textColorClearButton = '#ffffff',
}) => {

    const style= {
        buttonSee: {
            fontWeight: '500',
            marginRight:'.5rem',
            color: textColorSeeButton,
            borderColor: bgColorSeeButton,
            backgroundColor: bgColorSeeButton
        },
        buttonClear: {
            fontWeight: '500',
            color: textColorClearButton,
            borderColor: bgColorClearButton,
            backgroundColor: bgColorClearButton
        }
    }

    return (
        <>
            <div>
                <Typography>
                    {findingsLabel + ' '} 
                    <b>{findingsNumber}</b>
                </Typography>
            </div>
            <div className='d-flex mt-2'>
                <CustomButton
                    variant='primary'
                    title={titleSeeButton}
                    style={style.buttonSee}
                    onClick={onClickSeeButton}
                />
                <CustomButton
                    variant='primary'
                    title={titleClearButton}
                    style={style.buttonClear}
                    onClick={onClickClearButton}
                />
            </div>
        </>
    )
}

FilterResults.propTypes = {
    findingsLabel: PropTypes.string,
    findingsNumber: (props, propName, componentName) => {
        if (props[propName] && isNaN(Number(props[propName]))) {
          return new Error(
            'Invalid prop `' + propName + '` supplied to' +
            ' `' + componentName + '`. Must be an integer.'
          );
        }
    },
    onClickSeeButton: PropTypes.func,
    titleSeeButton: PropTypes.string,
    bgColorSeeButton: PropTypes.string,
    textColorSeeButton: PropTypes.string,
    onClickClearButton: PropTypes.func,
    titleClearButton: PropTypes.string,
    bgColorClearButton: PropTypes.string,
    textColorClearButton: PropTypes.string,
}

export default FilterResults