/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import BoxColumnIcon from "./list-icons/BoxColumnIcon";
import CloseColumnIcon from "./list-icons/CloseColumnIcon";
import EyeColumnIcon from './list-icons/EyeColumnIcon';
import LockColumnIcon from './list-icons/LockColumnIcon';

export const StockActionsColumnFormatter = (
	cellContent,
	row,
	rowIndex,
	{ onEye, onBox, onDrop, guardItems = [], Permisos} = {}
) => {
	return(
	<div
		className='d-flex justify-content-between'
	>
		{Permisos.includes("i-ver-inventario")  === true && row.id &&
			row.cf_fecha_resguardo && (
				<EyeColumnIcon onClick={() => onEye(row.id)} />
			)}
	
		{Permisos.includes("i-resguardar-inventario") === true &&
		row.id &&
			!row.cf_fecha_resguardo &&
			(guardItems.some(x => x.id === row.id) ? (
				<LockColumnIcon
					// disabled={row.asset_acquisition_is_closed}
					onClick={() => onBox(row.id)}
					tooltip={
						row.cf_fecha_resguardo
						? 'Resguardar'
						: 'No se ha cerrado la entrada'
					}
					color="info"
				/>
			) : (
				<BoxColumnIcon
					// disabled={row.asset_acquisition_is_closed}
					onClick={() => onBox(row.id)}
					tooltip={
						!row.cf_fecha_resguardo
						? 'Resguardar'
						: 'No se ha cerrado la entrada'
					}
				/>
			))}
			{Permisos.includes("i-crear-baja-inventario") === true  &&
					<CloseColumnIcon onClick={() => onDrop(row.id)} tooltip="Dar de baja" />
				}
	</div>
)
};
