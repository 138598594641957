import React from 'react'
import { compareCurrentDate, inputFormatDate } from '../../utils/dates'

export const DeparturesDateColumnFormatter = (cell) => {
  return (
    <span className={`text-${compareCurrentDate(cell).less ? "danger" : ""}`}>
      {inputFormatDate(cell)}
    </span>
  )
}
