import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FullTable from "../../components/FullTable";
import * as columnFormatters from "../../components/column-formatters";
import { SetListEntriesForConcentrate } from "../../../redux/ducks/entranceConcentrate.duck";
import { formatMoney } from "../../utils/expressions";

const EntranceConcentrateTable = ({ data, actionForm, handleAddTableInfo }) => {
  const dispatch = useDispatch();
  Array.prototype.remove = function() {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L && this.length) {
      what = a[--L];
      while ((ax = this.indexOf(what)) !== -1) {
        this.splice(ax, 1);
      }
    }
    return this;
  };
  const remove = (value) => {
    data.remove(value);
    dispatch(SetListEntriesForConcentrate(data));
  };

  let columns = [
    {
      dataField: "cf_proveedor",
      text: "Proveedor",
      sort: false,
    },
    {
      dataField: "id",
      text: "No registro",
      sort: false,
    },
    {
      dataField: "cf_ord_proveedor",
      text: "Proveedor",
      sort: false,
    },
    {
      dataField: "cf_ord_fecha_registro",
      text: "Fecha de registro",
      sort: false,
    },
    {
      dataField: "cf_fecha_entrada",
      text: "Fecha de recibido",
      sort: false,
    },
    {
      dataField: "cf_ord_no_contrato",
      text: "Pedidio y o contrato (No. Contrato)",
      sort: false,
    },
    {
      dataField: "ordinaria_descripcion",
      text: "Descripción",
      sort: false,
    },
    {
      dataField: "cf_ord_area_solicitante",
      text: "Area solicitante Ej. Direccion de control Patrimonial",
      sort: false,
    },
    {
      dataField: "cf_no_remision",
      text: "No. Remisión",
      sort: false,
    },
    {
      dataField: "cf_no_factura",
      text: "No. Factura",
      sort: false,
    },
    {
      dataField: "cf_importe_neto",
      text: "Importe",
      sort: false,
      formatter: (value) => formatMoney(value),
      style: {
        whiteSpace: "nowrap",
      },
    },
    {
      dataField: "tipo_entrada",
      text: "Tipo de entrada",
      sort: false,
    },
    {
      dataField: "cf_recibido",
      text: "Recibido",
      sort: false,
    },

    // Recibido

    //   {
    //     dataField: "action",
    //     text: "Acciones",
    //     formatter: columnFormatters.RequestActionsColumnFormatter,
    //     formatExtraData: {
    //       onFile,
    //       sendMail,
    //       onDetail,
    //       onCancel,
    //       onTrashDraft,
    //       onDraft,
    //       onPrintBarcode,
    //     },
    //     csvExport: false,
    //   },
  ];

  let obj = {
    dataField: "action",
    text: "Remover registro",
    formatter: columnFormatters.EntranceConcentrateColumnoFormatterNew,
    formatExtraData: {
      remove,
    },
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "100px",
    },
    csvExport: false,
  };

  if (actionForm === "isNew") {
    columns.unshift(obj);
  }
  return (
    <div>
      <FullTable
        // title="Concentrado de entradas"
        has_button={false}
        search_button={false}
        hideCheckbox={true}
        hide_pagination={true}
        entities={data}
        columns={columns}
      ></FullTable>
    </div>
  );
};

export default EntranceConcentrateTable;
