import React, { useCallback, useEffect, useState } from 'react';
import { createUser, login, validateUser } from '../_redux/authCrud';
import notice from '../../../components/Notice';
import { actions as auth } from '../_redux/authRedux';
import { useDispatch, useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers/AssetsHelpers';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

function Login(props) {
	const dispatch = useDispatch();
	const history = useHistory()
	const { loading } = useSelector(store => store.auth);
	const [loginForm, setLoginForm] = useState({
		user: '',
		password1: '',
		password2: '',
	});
	const [validating, setValidating] = useState(false);
	const { user, password1, password2 } = loginForm;
	const [userStatus, setUserStatus] = useState({
		code: null,
		detail: null,
	});
	const { code, detail } = userStatus;
	const [errors, setErrors] = useState({
		login: null,
		createUser: null,
	});

	const someFunction = useCallback(() => {
		validateUser(user)
			.then(res => {
				if (res.status !== 200) return;
				setUserStatus(res.data);
			})
			.catch(err => {
				notice(
					'No se ha podido verificar al usuario, error de conexión',
					'error'
				);
			})
			.finally(() => setValidating(false));
	}, [user, setUserStatus]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (user !== '') someFunction();
		}, 1200);
		return () => clearTimeout(delayDebounceFn);
	}, [user, someFunction]);

	const handleSubmit = e => {
		e.preventDefault();
		dispatch(auth.setLoading(true));
		// If user exists
		if (code === 100) {
			login(user, password1)
				.then(async res => {		
					if(res?.response?.status === 401){
						dispatch(auth.setLoading(false));
						notice('La contraseña que ingresaste es incorrecta',"error")
						return setErrors({
							...errors,
							login: 'Contraseña incorrecta',
						});
					}else if(res?.response?.status === 400){
						dispatch(auth.setLoading(false));
						return setErrors({
							...errors,
							login: 'La contraseña no puede ir vacía',
						});
					}else if(res?.response?.status === 403){
						dispatch(auth.setLoading(false));
						notice("El acceso esta bloqueado temporalmente")
					}else{
						const { data, status } = res;
						if(!data) return;
						const { access, refresh } = data;
						if (status !== 401) {
							await	dispatch(auth.login(access, refresh));
							history.push("/modulos")
						}
					};
					
				})
				.catch(err => {
					console.log('err', err);
					notice(
						'Ha ocurrido un error de conexión al iniciar sesión, intente nuevamente',
						'error'
					);
				})
			// If user is valid but doesnt existsj
		} else if (code === 200) {
			if (password1 !== password2){
				dispatch(auth.setLoading(false));
				return setErrors({ ...errors, createUser: 'Las contraseñas deben coincidir'});
			}else if(password1 === "") {
				dispatch(auth.setLoading(false));
				return setErrors({ ...errors, createUser: 'La contraseña no puede ir vacía'});				
			};
			createUser(user, password1)
				.then(res => {
					const { access, refresh } = res.data;
					dispatch(auth.login(access, refresh));
				})
				.catch(() => {
					notice(
						'Ha ocurrido un error de conexión al crear el usuario, intente nuevamente',
						'error'
					);
				});
		} else {
			dispatch(auth.setLoading(false));
		};
	};

	const handleChange = e => {
		const { name, value } = e.target;
		if (name === 'user' && !validating) {
			setValidating(true);
		}
		setLoginForm({ ...loginForm, [name]: value });
	};

	return (
		<div className='login-form login-signin' id='kt_login_signin_form'>
			{/* begin::Head */}
			<div className='text-center'>
				<img
					src={toAbsoluteUrl('/media/logos/logo-senado.png')}
					width='200'
					alt='Senado logo'
				/>
			</div>
			<p
				className='text-muted text-uppercase text-center mb-20 font-weight-bold'
				style={{ fontSize: 15 }}
			>
				Sistema de control de bienes instrumentales e inventario de bienes
				controlados por el almacén general
			</p>
			<div className='text-center mb-10 mb-lg-20'>
				<h3 className='font-size-h1'>
					<FormattedMessage id='AUTH.LOGIN.TITLE' data-testid='login-title' />
				</h3>
				<p className='text-muted font-weight-bold'>
					Ingrese su usuario y contraseña
				</p>
			</div>
			{/* end::Head */}

			{/*begin::Form*/}
			<form
				onSubmit={handleSubmit}
				className='form fv-plugins-bootstrap fv-plugins-framework'
			>
				{false ? (
					<div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
						<div className='alert-text font-weight-bold'>{''}</div>
					</div>
				) : (
					<></>
				)}

				<div className='form-group fv-plugins-icon-container d-flex  align-items-center'>
					<input
						placeholder='Usuario'
						type='text'
						className={`form-control-lg form-control form-control-solid h-auto py-5 px-6 position-absolute`}
						name='user'
						data-testid='input-user'
						onChange={handleChange}
						value={user}
						style={{
							width: 400,
						}}
					/>
					{validating && (
						<div className='spinner-border my-auto ml-auto mr-5' role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
					)}
					{false ? (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>{'234'}</div>
						</div>
					) : null}
				</div>
				<div style={{ minHeight: 10 }}></div>
				{code && (
					<div
						className={`alert alert-custom alert-dismissible ${
							code === 100
								? 'alert-light-success'
								: code === 200
								? 'alert-light-primary'
								: 'alert-light-danger'
						}`}
					>
						<div className='alert-text font-weight-bold'>{detail}</div>
					</div>
				)}
				{code === 100 && (
					<div className='form-group fv-plugins-icon-container'>
						<input
							placeholder='Ingresa tu contraseña'
							type='password'
							className={`form-control-lg form-control form-control-solid h-auto py-5 px-6`}
							name='password1'
							data-testid='input-password'
							onChange={handleChange}
							value={password1}
						/>
						{errors?.login && 
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>{errors.login}</div>
							</div>
						}
					</div>
				)}
				{code === 200 && (
					<div className='form-group fv-plugins-icon-container'>
						<input
							placeholder='Escribe una contraseña nueva'
							type='password'
							className={`form-control-lg form-control form-control-solid h-auto py-5 px-6`}
							name='password1'
							data-testid='input-password'
							onChange={handleChange}
							value={password1}
						/>
						{false ? (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>{''}</div>
							</div>
						) : null}
						<input
							placeholder='Repite la contraseña nueva'
							type='password'
							className={`form-control-lg form-control form-control-solid h-auto py-5 px-6 mt-5`}
							name='password2'
							data-testid='input-password'
							onChange={handleChange}
							value={password2}
						/>
						{errors?.createUser &&
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>{errors.createUser}</div>
							</div>
						}
					</div>
				)}
				<div style={{ minHeight: 10 }}></div>
				<div className='form-group d-flex flex-wrap justify-content-end align-items-center'>
					<button
						id='kt_login_signin_submit'
						type='submit'
						disabled={loading}
						className={`btn btn-primary font-weight-bold px-9 py-4 my-3 rounded-lg`}
						data-testid='button-login'
					>
						<span>Iniciar sesión</span>
						{loading && <span className='ml-3 spinner spinner-white'></span>}
					</button>
				</div>
			</form>
			{/*end::Form*/}
		</div>
	);
}

export default Login;
