import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Save } from "@material-ui/icons";
import SVG from "react-inlinesvg";
import EntryActionIcons from "../../components/EntryActionIcons";
import CustomButton from "../../components/CustomButton";
import BaseModal from "../../components/BaseModal";
import ModeTabs from "../../components/ModeTabs";
import notice from "../../components/Notice";
import Icon from "../../components/Icon";
import {
  clearState,
  resetFieldsFilter,
  setCurrentRequestTab,
  setRequestsIds,
  setSelectedItems,
  setSelectedRequest,
  setNewRequest,
  setNewItems,
  setDrafts,
} from "../../../redux/ducks/request.duck";
import { useEnterSearch } from "../../hooks/useEnterSearch";
import {
  createNewRequest,
  editRequest,
  listRequests,
  postRequestZpl,
  singleRequest,
} from "../../crud/requestCrud";
import {
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";
import { getColourizedLabelStatus } from "../../utils/requestUtils";
import { useSearchMode } from "../../hooks/useSearchMode";
import { defaultRequestFilter } from "../../utils/materialRequestData";
import { isAValidEmail } from "../../utils/inputValidations";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { zplRequest } from "../../utils/zplRequest";

const RequestActions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { authToken, user } = useSelector((store) => store.auth);
  const {
    selectedRequest,
    selectedItems,
    filter,
    reload,
    isResultsTab,
    isNewTab,
    isSearchTab,
    newRequest,
    selectedRequestIds,
    newItems,
    isDraftTab,
    selectedDraft,
    drafts,
  } = useSelector((store) => store.request);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [labelModal, setLabelModal] = useState("");
  const isInventory = pathname.includes("inventario-bienes-almacen-general");

  const requestForm = isSearchTab
    ? filter
    : isResultsTab
    ? selectedRequest
    : isNewTab
    ? newRequest
    : isDraftTab
    ? selectedDraft
    : defaultRequestFilter;
  const [isTryingDraft, setIsTryingDraft] = useState(
    isResultsTab ? requestForm.cf_estatus === "X" : false
  );

  const moduleAdminRoute = "inventario-bienes-almacen-general";
  const inventoryModule = user.app_modules.find(
    (module) => module.name === moduleAdminRoute
  );
  const requestModule =
    inventoryModule && inventoryModule.submodules.length > 0
      ? inventoryModule.submodules.find(
          (module) => module.name === "solicitudes"
        )
      : undefined;
  const mode = !pathname.includes(moduleAdminRoute)
    ? "public"
    : requestModule
    ? "admin"
    : "public";

  const itemsVariable =
    isResultsTab || isDraftTab ? selectedItems : isNewTab ? newItems : [];

  useEffect(() => {
    if (isResultsTab) {
      setIsTryingDraft(requestForm.cf_estatus === "X");
    }
  }, [requestForm.cf_estatus, isResultsTab]);

  const handleValidateRequest = (shouldSetIsDraft) => {
    if (!shouldSetIsDraft) {
      if (itemsVariable.length === 0) {
        notice("Necesitas seleccionar al menos 1 material", "error");
      } else if (requestForm?.collect_name === "") {
        notice("Necesitas ingresar el Nombre de quién recoge", "error");
      } else if (requestForm?.collect_extension === "") {
        notice("Necesitas ingresar la Extensión de quién recoge", "error");
      } else if (requestForm?.collect_email === "") {
        notice(
          "Necesitas ingresar el correo electrónico de quién recoge",
          "error"
        );
      } else if (!isAValidEmail(requestForm?.collect_email)) {
        notice("El correo ingresado no es válido", "error");
      } else {
        setIsTryingDraft(shouldSetIsDraft);
        setConfirmModal(true);
      }
    } else {
      setIsTryingDraft(shouldSetIsDraft);
      setConfirmModal(true);
    }
  };

  const handleRequest = (form) => ({
    id: form.id,
    collect_name: form.collect_name,
    collect_extension: form.collect_extension,
    collect_email: form.collect_email,
    cf_estatus: isTryingDraft ? "X" : "G",
    // Doesnt needed on new request
    // cf_fecha_solicitud: requestForm.cf_fecha_solicitud,
    // cf_cve_area: requestForm.area?.num_area,
    // cf_no_folio: requestForm.num_solicitud,

    warehouse_request_details: itemsVariable.map((item) => ({
      cf_cve_articulo: item.cve_articulo,
      cf_num_seccion: item.num_seccion,
      cf_num_almacen: item.num_almacen,
      cf_partida_presupuestaria: item.partida_presu,
      cf_cantidad_solicitada: item.cf_cantidad_solicitada,
      cf_cantidad_surtida: item.cf_cantidad_surtida,
      cf_cantidad_negada: item.cf_cantidad_negada,
      cf_estatus: isTryingDraft ? "X" : "G",
      variant_asset_category_1:
        item.variant_asset_id_1 && item.variant_asset_id_1,
      variant_asset_category_2:
        item.variant_asset_id_2 && item.variant_asset_id_2,
      desc_corta: item.desc_corta,
      uni_distribuye: item.uni_distribuye,
    })),
  });

  const handleConfirmSave = () => {
    const newRequest = handleRequest(requestForm);

    isTryingDraft
      ? handleDraft(newRequest)
      : createNewRequest(newRequest)
          .then((res) => {
            if (res.status === 201) {
              if (isDraftTab) {
                const newDrafts = drafts.filter(
                  (x) => x.id !== selectedDraft.id
                );
                dispatch(setDrafts(newDrafts));
              }
              notice("Solicitud de material realizada exitosamente", "success");
              dispatch(clearState());
              setConfirmModal(false);
              dispatch(setNewRequest(defaultRequestFilter));
              dispatch(setNewItems([]));
              history.push(
                isInventory
                  ? "/inventario-bienes-almacen-general/solicitudes"
                  : "/solicitudes/lista"
              );
            }
          })
          .catch((error) => {
            console.log(error);
            notice("Ha ocurrido un error, contacta al administrador");
          });
  };

  const handleDraft = (request) => {
    const index = drafts.findIndex((x) => Number(x.id) === Number(request.id));
    let newData;
    let parsedDrafts = JSON.parse(JSON.stringify(drafts));
    if (index === -1)
      newData = [
        ...parsedDrafts,
        {
          ...request,
          id: parsedDrafts[parsedDrafts.length - 1]?.id
            ? parsedDrafts[parsedDrafts.length - 1]?.id + 1
            : 1,
        },
      ];
    else {
      newData = parsedDrafts;
      newData[index] = request;
    }
    setConfirmModal(false);
    dispatch(setDrafts(newData));
    dispatch(clearState());
    history.push("/solicitudes/borradores");
    notice("Borrador guardado exitosamente", "success");
  };

  const handleTrash = () => {
    const newDrafts = drafts.filter((x) => x.id !== selectedDraft.id);
    dispatch(setDrafts(newDrafts));
    setDeleteModal(false);
    notice("Borrador borrado exitosamente", "success");
  };

  const handleTrashIcon = () => {
    setDeleteModal(true);
  };

  const handleSearch = () => {
    return listRequests(
      1,
      100,
      filterParams(
        clearEmptyStringsObject(JSON.parse(JSON.stringify(requestForm)))
      )
    )
      .then((res) => {
        if (res.data.results[0]?.id) {
          dispatch(setRequestsIds(res.data.results.map((x) => x.id)));
          return singleRequest(res.data.results[0]?.id)
            .then((data) => {
              const newSelectedRequest = {
                ...data,
                label_status: getColourizedLabelStatus(data.cf_estatus),
              };
              dispatch(setSelectedRequest(newSelectedRequest));
              dispatch(
                setSelectedItems(
                  data.items.map((item) => ({ ...item, disabled: true }))
                )
              );
              history.push(
                isInventory
                  ? `/inventario-bienes-almacen-general/solicitudes/resultados/${data.id}`
                  : `/solicitudes/resultados/${res.id}`
              );
            })
            .catch(console.log)
            .finally(() => dispatch(resetFieldsFilter()));
        } else
          notice(
            "No se encontraron solicitudes que coincidan con su búsqueda",
            "info"
          );
      })
      .catch((err) => {
        notice("No se obtuvieron resultados", "info");
      });
  };

  useEnterSearch(handleSearch);

  const handleEdit = () => {
    const {
      collect_name,
      collect_extension,
      collect_email,
      cf_no_folio,
      cf_no_almacen,
      cf_cve_usuario,
      user,
      cf_fecha_solicitud,
    } = requestForm;
    const warehouse_request_details = itemsVariable.map((item) => ({
      cf_cve_articulo: item?.cve_articulo,
      cf_num_seccion: item?.num_seccion,
      cf_num_almacen: item?.num_almacen,
      cf_partida_presupuestaria: item?.partida_presu,
      cf_cantidad_solicitada: item?.cf_cantidad_solicitada,
      cf_cantidad_surtida: item?.cf_cantidad_surtida,
      cf_cantidad_negada: item?.cf_cantidad_negada,
      variant_asset_category_1:
        item.variant_asset_category_1 && item.variant_asset_category_1,
      variant_asset_category_2:
        item.variant_asset_category_2 && item.variant_asset_category_2,
    }));

    const payload = {
      collect_name,
      collect_extension,
      collect_email,
      cf_no_folio,
      cf_no_almacen,
      cf_cve_usuario,
      user,
      cf_fecha_solicitud,
      warehouse_request_details,
      cf_estatus: isTryingDraft ? "X" : "G",
    };
    editRequest(payload, requestForm.id)
      .then((res) => {
        if (res?.status === 200) {
          notice(
            isTryingDraft
              ? "El borrador se ha editado correctamente"
              : "La solicitud se ha solicitado correctamente",
            "success"
          );
          history.push(
            isInventory
              ? "/inventario-bienes-almacen-general/solicitudes"
              : "/solicitudes/lista"
          );
        } else {
          notice(
            "Ha ocurrido un error editando la solicitud, contacta al administrador",
            "error"
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        notice(
          "Ha ocurrido un error editando la solicitud, contacta al administrador",
          "error"
        );
      });
  };

  const handleResults = () => {
    if (selectedRequest.id) {
      history.push(
        isInventory
          ? `/inventario-bienes-almacen-general/solicitudes/resultados/${selectedRequest.id}`
          : `/solicitudes/resultados/${selectedRequest.id}`
      );
    } else if (selectedRequestIds[0]) {
      singleRequest(selectedRequestIds[0])
        .then((res) => {
          dispatch(setSelectedRequest(res));
          dispatch(
            setSelectedItems(
              res.items.map((item) => ({ ...item, disabled: true }))
            )
          );
          history.push(
            isInventory
              ? `/inventario-bienes-almacen-general/solicitudes/resultados/${res.id}`
              : `/solicitudes/resultados/${res.id}`
          );
        })
        .catch(console.log);
    } else {
      dispatch(resetFieldsFilter());
      notice(
        "No se encontraron registros guardados, pruebe realizando una búsqueda",
        "info"
      );
      history.push(
        isInventory
          ? "/inventario-bienes-almacen-general/solicitudes/busqueda"
          : "/solicitudes/busqueda"
      );
    }
  };

  const onSearchTab = () => {
    const newTabs = { isSearchTab: true, isResultsTab: false, isNewTab: false };
    dispatch(setCurrentRequestTab(newTabs));
    history.push(
      isInventory
        ? "/inventario-bienes-almacen-general/solicitudes/busqueda"
        : "/solicitudes/busqueda"
    );
  };

  const onLabelClick = () => {
    postRequestZpl(requestForm.id)
      .then((res) => {
        if (res.data) {
          zplRequest(res.data, "2x4")
            .then(async (res) => {
              const blob = await res.blob();
              let reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function() {
                let base64data = reader.result;
                setLabelModal(base64data ? base64data : true);
              };
            })
            .catch(console.log);
        }
      })
      .catch((err) => {
        console.log("err", err);
        notice(
          "No se ha podido obtener la Etiqueta desde el servidor",
          "error"
        );
      });
  };

  const printZpl = () => {
    const id = selectedRequest.id;
    const ids = selectedRequestIds;
    const a = document.createElement("a");
    a.href = `nextwmsftp://?token=${authToken}?ids=${
      id ? id : ids.join(",")
    }?type=request`;
    a.click();
    setLabelModal("");
  };

  useSearchMode(onSearchTab);

  return (
    <>
      {mode === "admin" && (
        <>
          <ModeTabs
            onSearch={onSearchTab}
            // onNew={onNewRegister}
            onResults={handleResults}
            showIcons={{ search: true, results: true, newIcon: false }}
          />
          {requestForm.id && (
            <Icon
              src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode.svg")}
              subLabel="Etiqueta"
              color="dark"
              onClick={onLabelClick}
              disableTooltip
              style={{
                width: 55,
                height: 50,
                margin: "0px 2px",
                fontSize: 10,
              }}
            />
          )}
        </>
      )}
      {mode === "public" && (isNewTab || isDraftTab) ? (
        <div className="w-100 d-flex justify-content-between">
          <button
            className="btn btn-secondary"
            onClick={() => handleValidateRequest(true)}
          >
            Crear Borrador
            <span className={`svg-icon svg-icon-md svg-icon-secondary ml-2`}>
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Design/Eraser.svg")}
                width={500}
                height="auto"
              />
            </span>
          </button>

          <button
            className="btn btn-primary"
            onClick={() => handleValidateRequest(false)}
            disabled={user.solicitudes_creacion_deshabilitada}
          >
            Crear solicitud <Save />
          </button>
        </div>
      ) : (
        <EntryActionIcons
          showIcons={
            isNewTab || isDraftTab
              ? {
                  save: true,
                  draft: true,
                  trash: isDraftTab,
                }
              : isResultsTab
              ? {
                  //edit: isResultsTab && mode === "admin",
                  edit: false,
                  save:
                    mode === "public" &&
                    (isNewTab || requestForm.cf_estatus === "X"),
                  trash: false,
                }
              : isSearchTab && {
                  search: true,
                }
          }
          labels={{
            save: "Crear Solicitud",
            draft: isNewTab
              ? "Guardar Borrador"
              : (isResultsTab || isDraftTab) && "Editar Borrador",
            trash: "Borrar Borrador",
          }}
          handleSubmit={() => handleValidateRequest(false)}
          handleDraft={() => handleValidateRequest(true)}
          handleTrash={handleTrashIcon}
          handleSearch={handleSearch}
          disabledIcons={{
            save: reload,
            edit: reload,
            search: reload,
          }}
          handleEdit={handleEdit}
        />
      )}

      <BaseModal
        open={confirmModal}
        title="¿Estás seguro de que toda la información es correcta?"
        onClose={() => setConfirmModal(false)}
        onConfirm={() =>
          isNewTab
            ? handleConfirmSave()
            : isResultsTab && !isTryingDraft
            ? handleEdit()
            : isDraftTab
            ? handleConfirmSave()
            : null
        }
        confirmLabel={
          isTryingDraft && isNewTab
            ? "Si, crear borrador"
            : isTryingDraft && isDraftTab
            ? "Si, editar borrador"
            : (isNewTab || isDraftTab) && !isTryingDraft
            ? "Si, continuar con esta creación"
            : isResultsTab && !isTryingDraft
            ? "Si, continuar con esta creación"
            : ""
        }
        onCancel={() => setConfirmModal(false)}
        cancelLabel="No"
        maxWidth="sm"
        color="secondary"
      />
      <BaseModal
        open={deleteModal}
        title="¿Estás seguro de que deseas borrar este Borrador?"
        onClose={() => setDeleteModal(false)}
        onCancel={() => setDeleteModal(false)}
        onConfirm={handleTrash}
        confirmLabel="Si, borrar este Borrador"
        color="primary"
        maxWidth="sm"
        cancelLabel="No borrar"
      />
      {labelModal && (
        <BaseModal
          title={
            <span>
              Etiqueta del artículo <strong>{requestForm?.id}</strong>
            </span>
          }
          open={Boolean(labelModal)}
          onClose={() => setLabelModal("")}
          onConfirm={printZpl}
          confirmLabel="Imprimir"
          onCancel={() => setLabelModal("")}
          cancelLabel="Cerrar"
          maxWidth="sm"
          color="secondary"
          disableOnConfirm={false}
        >
          <div className="text-center">
            <div style={{ height: 300, overflow: "hidden" }}>
              <img className="img-fluid" src={labelModal} alt="label" />
            </div>
            <div className="d-flex justify-content-center m-5">
              <CustomButton
                onClick={printZpl}
                title="Imprimir etiquetas"
                color="secondary"
              />
            </div>
          </div>
        </BaseModal>
      )}
    </>
  );
};

export default RequestActions;
