/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import notice from "../../../../../app/components/Notice";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../dropdowns";
export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);
  // const Myuser = storage.getItem(keyName);
  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-dark font-size-base  d-md-inline mr-1">
            Hola,
          </span>
          <span className="text-dark font-weight-bolder font-size-base d-md-inline mr-3">
            {user.first_name} {user.last_name}
          </span>
          <span className="">
            <span
              className="badge badge-dark px-3 py-4"
              style={{ fontSize: 14 }}
            >
              {`${user.first_name ? user.first_name[0] : ""}${
                user.last_name ? user.last_name[0] : ""
              }`}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/misc/bg-1.jpg"
                )})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {user.first_name ? user.first_name[0] : ""}
                </span>
                {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
              </div>
              {}
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user.first_name} {user.last_name}
              </div>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <div className="navi-footer  px-20 py-5">
            <Link
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
              onClick={() => notice("Se ha cerrado la sesion")}
            >
              Cerrar sesión
            </Link>
            {(user.main_module === "INSTRUMENTAL" || user.operativo) && (
              <Link
                to={`/usuarios/EditarPerfil/${user.id}`}
                className="btn btn-light-primary font-weight-bold"
              >
                Editar Perfil
              </Link>
            )}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
