import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { reducer as entry } from "./ducks/entry.duck";
import { reducer as stock } from "./ducks/stock.duck";
import { reducer as notifications } from "./ducks/notification.duck";
import request from "./ducks/request.duck";
import { reducer as entrance } from "./ducks/entrance.duck";
import { reducer as pledgedGood } from "./ducks/pledgedGood.duck";
import { reducer as contracts } from "./ducks/contracts.duck";
import { reducer as vehicules } from "./ducks/vehicules.duck";
import { reducer as artWork } from "./ducks/artWorks.duck";
import { reducer as article } from "./ducks/articles.duck";
import { reducer as config } from "./ducks/config.duck";
import { reducer as groups } from "./ducks/groups.duck";
import { reducer as permits } from "./ducks/permits.duck";
import { reducer as presentations } from "./ducks/presentations.duck";
import { reducer as variants } from "./ducks/variants.duck";
import { reducer as units } from "./ducks/units.duck";
import requirement from "./ducks/requirement.duck";
import qrView from "./ducks/qrview.duck";
import qrViewArtwork from "./ducks/qrviewArtwork.duck";
import window from "./ducks/window.duck";
import { reducer as users } from "./ducks/users.duck";
import { reducer as subHeaderActions } from "./ducks/subHeaderActions.duck";
import { reducer as pathname } from "./ducks/pathname.duck";
import { reducer as departure } from "./ducks/departure.duck";
import { reducer as reports } from "./ducks/reports.duck";
import { reducer as files } from "./ducks/files.duck";
import { reducer as loader } from "./ducks/loader.duck";
import { reducer as inventory } from "./ducks/inventory.duck";
import { reducer as digitalVault } from "./ducks/digitalVault.duck";
import { reducer as taskManagement } from "./ducks/taskManagement.duck";
import { reducer as dashboard } from "./ducks/dashboard.duck";
import { reducer as entranceConcentrate } from "./ducks/entranceConcentrate.duck";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  entry,
  stock,
  request,
  entrance,
  pledgedGood,
  contracts,
  vehicules,
  requirement,
  artWork,
  article,
  config,
  groups,
  permits,
  presentations,
  variants,
  units,
  qrView,
  qrViewArtwork,
  window: window,
  users,
  subHeaderActions,
  pathname,
  departure,
  reports,
  files,
  loader,
  inventory,
  notifications,
  digitalVault,
  taskManagement,
  dashboard,
  entranceConcentrate,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
