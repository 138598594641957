import { getArticles } from "../crud/catalogsCrud";
import { dateTo_DDMMYYYY, AddDays } from "./dates";

const getLabelStatusFromLetter = (letter) => {
  let cf_estatus_letter;
  switch (letter) {
    case "Abierta":
      cf_estatus_letter = "Abierta";
      break;
    case "G":
      cf_estatus_letter = "Solicitada";
      break;
    case "E":
      cf_estatus_letter = "Correo envíado";
      break;
    case "D":
      cf_estatus_letter = "Despachada";
      break;
    case "B":
      cf_estatus_letter = "Cancelada";
      break;
    case "H":
      cf_estatus_letter = "Procesada (afectada)";
      break;
    case "S":
      cf_estatus_letter = "Surtiendo";
      break;
    default:
      cf_estatus_letter = "Surtiendo";
      break;
  }
  return cf_estatus_letter;
};

const getLabelStatusFromWord = (status) => {
  let cf_estatus;
  switch (status) {
    case "Solicitada":
      cf_estatus = "G";
      break;
    case "Correo enviado":
      cf_estatus = "E";
      break;
    case "Despachada":
      cf_estatus = "D";
      break;
    case "Cancelada":
      cf_estatus = "B";
      break;
    case "Surtiendo":
      cf_estatus = "H";
      break;
    case "Borrador":
      cf_estatus = "X";
      break;
    default:
      cf_estatus = "S";
      break;
  }
  return cf_estatus;
};

export const getLabelStatus = (status) =>
  status.length === 1
    ? getLabelStatusFromLetter(status)
    : getLabelStatusFromWord(status);

export const getColourizedLabelStatus = (status) => {
  let label_status;
  switch (status) {
    case "Abierta":
      label_status = { color: "success", text: "Abierta" };
      break;
    case "Solicitada":
      label_status = { color: "success", text: "Solicitada" };
      break;
    case "Correo envíado":
      label_status = { color: "success", text: "Correo envíado" };
      break;
    case "Despachada":
      label_status = { color: "warning", text: "Despachada" };
      break;
    case "Cancelada":
      label_status = { color: "danger", text: "Cancelada" };
      break;
    case "Procesada (afectada)":
      label_status = { color: "success", text: "Procesada (afectada)" };
      break;
    case "Surtiendo":
      label_status = { color: "success-50", text: "Surtiendo" };
      break;
    case "G":
      label_status = { color: "success", text: "Abierta" };
      break;
    case "E":
      label_status = { color: "primary", text: "Correo enviado" };
      break;
    case "D":
      label_status = { color: "warning", text: "Despachada" };
      break;
    case "B":
      label_status = { color: "danger", text: "Cancelada" };
      break;
    case "H":
      label_status = { color: "success", text: "Surtiendo" };
      break;
    case "S":
      label_status = { color: "info", text: "Borrador" };
      break;
    case "A":
      label_status = { color: "danger", text: "Abierta" };
      break;
    case "T":
      label_status = { color: "dark", text: "Entregado" };
      break;

    default:
      label_status = { color: "danger", text: "Abierta" };
      break;
  }
  return label_status;
};

export const defaultFilter = (mode) => {
  return {
    cf_estatus: mode === "admin" ? "D,G,H" : "",
    cf_fecha_solicitud_entregado: "",
    cve_articulo: null,
    cve_area: "",
    cf_fecha_solicitud_entregado_inicio: "",
    cf_fecha_solicitud_entregado_fin: "",
    responsable: "",
    adscripcion: "",
  };
};

export const consumptionFilters = (mode) => [
  {
    label: "Nombre del artículo",
    value: "cve_articulo",
    name: "cve_articulo",
    type: "search",
    searchFunction: getArticles,
    uppercase: false,
    width: 230,
    required: true,
  },
  {
    label: "Adscripción",
    name: "adscripcion",
    type: "text",
  },
  {
    label: "Área",
    name: "cve_area",
    type: "text",
    required: true,
  },
  {
    label: "Titular",
    name: "responsable",
    type: "text",
  },
  // {
  //   label: "Estatus",
  //   name: "cf_estatus",
  //   type: "dropdown",
  //   width: 200,
  //   options: [
  //     {
  //       value: mode === "admin" ? "D,G,H" : "",
  //       text: "Seleccione un estatus",
  //     },
  //     { value: "G", text: "Abierta" },
  //     { value: "D", text: "Despachada" },
  //     { value: "B", text: "Cancelada" },
  //     { value: "H", text: "Surtiendo" },
  //     { value: "T", text: "Entregado" },
  //     { value: "G,D,B,H,T,G", text: "Todos" },
  //   ],
  // },
  {
    label: "Fecha inicio",
    name: "cf_fecha_solicitud_entregado_inicio",
    placeholder: "dd/mm/yy",
    type: "date",
    width: 200,
  },
  {
    label: "Fecha fin",
    name: "cf_fecha_solicitud_entregado_fin",
    placeholder: "dd/mm/yy",
    type: "date",
    width: 200,
  },
  //  {
  //    label: "Fecha",
  //    name: "cf_fecha_solicitud_entregado",
  //    placeholder: "dd/mm/yy",
  //    type: "date",
  //    width: 200,
  //  },
];
