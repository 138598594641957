import { Close } from '@material-ui/icons';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CloseColumnIcon = ({ onClick, disabled, tooltip="Cerrar", color="danger" }) => {
    return (
        <OverlayTrigger
            overlay={<Tooltip id='request-tooltip'>{tooltip}</Tooltip>}
        >
            <button
                className={`btn btn-icon btn-${color} btn-sm btn-hover-${color}`}
                onClick={onClick}
                disabled={disabled}
            >
                <span className={`svg-icon svg-icon-md svg-icon-hover-${color}`}>
                    <Close />
                </span>
            </button>
        </OverlayTrigger>
    );
};

export default CloseColumnIcon;