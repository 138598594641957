import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultPermit } from "../../app/utils/configData"

const actionType = {
  SetPermits: '[CONFIG] SET_PERMITS',
  SetPermit: '[CONFIG] SET_PERMIT',
  SetSelectedPermits: '[CONFIG] SET_SELECTED_PERMITS',
  ClearState: '[CONFIG] CLEAR_STATE',
};

const initialState = {
  permits: {
    results: [],
    count: 0
  },
  permit: defaultPermit,
  selectedPermits: [],
};

export const reducer = persistReducer(
  { storage, key: 'config', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetPermits:
        return { ...state, permits: action.payload }
      
      case actionType.SetPermit:
        return { ...state, permit: action.payload }
      
      case actionType.SetSelectedPermits:
        return { ...state, selectedPermits: action.payload }

      case actionType.ClearState:
        return state = initialState
      
      default:
        return state
    }
  }
)

export const setPermits = data => ({ type: actionType.SetPermits, payload: data })

export const setPermit = data => ({ type: actionType.SetPermit, payload: data })

export const setSelectedPermits = data => ({ type: actionType.SetSelectedPermits, payload: data })

export const setClearState = data => ({ type: actionType.ClearState, payload: data })