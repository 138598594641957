import { Grid, Box } from "@material-ui/core";
import React from "react";
import Calendar from "../../components/Calendar";
import FullTable from "../../components/FullTable";
import { columns_task } from "./utils/data";

const MonitoringTask = ({ requests, tasks }) => {
  return (
    <Box sx={{ height: "calc(100vh - 180px)" }}>
      <Grid style={{ height:'100%' }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <FullTable
            hideCheckbox
            title="Solicitudes recientes"
            columns={columns_task}
            entities={requests}
            count={requests.length}
            search_button={false}
            has_button={false}
            hide_pagination={true}
            isMonitoring
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Calendar
            data={tasks}
            initialView="listMonth"
            calendarViews=""
            title="Tareas pendientes"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MonitoringTask;
