import store from "../../../redux/store";
/* eslint-disable no-throw-literal */
import notice from "../../components/Notice";
export function getToken() {
  return store.getState().auth.authToken;
}

export function getParams(method, body = null) {
  return {
    method: method,
    body: body ? JSON.stringify(body) : null,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
}

export function getMultipartParams(method, body = null) {
  return {
    method: method,
    body: body,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
}

export const handleFormData = (data) => {
  const formData = new FormData();

  for (const name in data) {
    formData.append(name, data[name]);
  }

  return formData;
};

export async function handleResponse(response) {
  if (!response.ok) {
    if (response.status === 401) {
      store.dispatch({ type: "[Logout] Action" });
      notice("Session expired. Please log in again");
      throw { errors: "Session expired" };
      // } else if (response.status === 422 || response.status === 400){
    } else if (response.status >= 400 && response.status < 500) {
      throw await response.json();
    }
    throw { errors: response.statusText };
  }
  return response.statusText === "No Content" ? {} : response.json();
}

export const handleError = (e) => {
  console.log(e);
  throw e;
};
