import React from 'react'
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom'
import { clearSelectedEntry } from '../../../../../redux/ducks/entry.duck';
import { setCurrentMode } from "../../../../../redux/ducks/subHeaderActions.duck";
import { modeOptions } from "../../../../../app/utils/subheaderIconsData";

export const SubMenu = ({ submenu }) => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const ordered = submenu.sort((a, b) => a.seq < b.seq && -1);

  return (
		<div className='menu-submenu'>
			<ul className='menu-subnav'>
				{ordered.map(sItem => (
					<li key={sItem.id} className='menu-item'>
						<NavLink
							className={`${'menu-link menu-toggle'} ${
								sItem.slug === pathname.substring(1) ? 'activeItemMenu' : ''
							}`}
							to={`/${sItem.slug}`}
							onClick={() => {
								if (sItem.name.includes('entrada')) {
									dispatch(clearSelectedEntry())
									dispatch(setCurrentMode(modeOptions.new))
								}
							}}
						>
							<i className='menu-bullet menu-bullet-dot'>
								<span />
							</i>
							<span className='menu-text'>{sItem.display_text}</span>
							{sItem.submodules && sItem.submodules.length > 0 && (
								<i className='menu-arrow' />
							)}
						</NavLink>
						{sItem.submodules && sItem.submodules.length > 0 && (
							<SubMenu submenu={sItem.submodules} />
						)}
					</li>
				))}
			</ul>
		</div>
	);
}
