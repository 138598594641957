import uuid from "react-uuid";
import * as columnFormatters from "../components/column-formatters";
import {
  getArticles,
  getArticlesRecurrent,
  getProviders,
  getProvidersAlmacen,
} from "../crud/catalogsCrud";
import { formatDate, inputFormatDate } from "./dates";
import { formatMoney } from "./expressions";

export const columns = (onEye) => [
  {
    dataField: "proveedor",
    text: "Proveedor",
    sort: true,
  },
  {
    dataField: "contract_number",
    text: "No. de contrato",
    sort: true,
  },
  {
    dataField: "date_start",
    text: "Fecha de Inicio",
    sort: true,
    formatter: (value) => inputFormatDate(value),
  },
  {
    dataField: "date_end",
    text: "Fecha de Término",
    sort: true,
    formatter: (value) => inputFormatDate(value),
  },
  {
    dataField: "min_amount",
    text: "Presupuesto mínimo",
    sort: true,
    formatter: (value) => formatMoney(value),
  },
  {
    dataField: "max_amount",
    text: "Presupuesto máximo",
    sort: true,
    formatter: (value) => formatMoney(value),
  },
  {
    dataField: "presupuesto_ejercido",
    text: "Presupuesto ejercido",
    sort: true,
    formatter: (value) => formatMoney(value),
  },
  {
    dataField: "presupuesto_minimo_faltante",
    text: "Presupuesto Mínimo faltante",
    sort: true,
    formatter: (value) => formatMoney(value),
  },
  {
    dataField: "presupuesto_maximo_faltante",
    text: "Presupuesto Máximo faltante",
    sort: true,
    formatter: (value) => formatMoney(value),
  },
  {
    dataField: "presupuesto_ejercido_porcentaje",
    text: "Porcentaje ejercido",
    headerClasses: "text-center",
    classes: "text-center",
    sort: false,
    formatter: columnFormatters.ContractsPercentageColumnFormatter,
  },
  {
    dataField: "id",
    text: "Acciones",
    formatter: columnFormatters.ContractsActionsColumnFormatter,
    formatExtraData: {
      onEye,
    },
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "100px",
    },
    csvExport: false,
  },
];

export const filterInputs = [
  {
    label: "Proveedor",
    name: "supplier",
    type: "search",
    searchFunction: getProvidersAlmacen,
    width: 170,
  },
  {
    label: "Contrato",
    placeholder: "No. de contrato",
    name: "contract_number",
    type: "text",
  },
  {
    label: "Fecha de inicio",
    placeholder: "Dd/mm/aaaa",
    name: "date_start",
    type: "date",
  },
  {
    label: "Fecha fin",
    placeholder: "Dd/mm/aaaa",
    name: "date_end",
    type: "date",
  },
];

export const defaultContractFilter = {
  supplier: null,
  contract_number: "",
  date_start: "",
  date_end: "",
};

export const defaultContractsForm = {
  contract_name: "",
  legal_representative: "",
  public_tender: "",
  supplier: null,
  contract_number: "",
  contract_type: "",
  date_start: "",
  date_end: "",
  observations: "",
  subtotal: 0,
  iva: 0,
  total: 0,
  // By default always start with 1 contract_set (partida), only if action is a brand new contract
  contract_set: [
    {
      id: uuid(),
      budget_start: "",
      minimum_amount: 0,
      maximum_amount: 0,
      min_import: 0,
      max_import: 0,
      progress: 0,
      available_quantity: 0,
      set_item: [
        {
          id: uuid(),
          cons: 1,
          cve_article: "",
          article: "",
          unit_measurement: "",
          unit_price: "",
          articulo: "",
          presentaciones: [],
          articulo_marca: "",
          stock_available: null,
        },
      ],
    },
  ],
};

export const defaultContractsFilter = {
  version: null,
  supplier: null,
  contract_number: "",
  date_start: "",
  date_end: "",
  observations: "",
  subtotal: 0,
  iva: 0,
  total: 0,
};

export const filterDetailContractInputs = (disabled, VersionSelected, Versiones) => {
  return [
    {
      label: "Versión de contrato",
      name: "version",
      type: "dropdown",
      placeholder: "Contratos",
      options: Versiones === false ? [] : Versiones,
      disabled: Versiones === false,
    },
    {
      label: "Proveedor",
      placeholder: "Proveedor",
      name: "supplier",
      type: "search",
      required: true,
      searchFunction: getProvidersAlmacen,
      width: 415,
      disabled,
    },
    {
      label: "Contrato",
      placeholder: "Contratos",
      name: "contract_number",
      type: "text",
      required: true,
      disabled,
    },
    {
      label: "Tipo de contrato",
      name: "contract_type",
      type: "dropdown",
      disabled,
      options: [
        { value: "", text: "Seleccione tipo de contrato" },
        { value: "STOCK", text: "STOCK" },
        { value: "RECURRENTE", text: "RECURRENTE" },
      ],
    },
    {
      label: "Fecha de inicio",
      placeholder: "Dd/mm/aaaa",
      name: "date_start",
      type: "date",
      disabled,
    },
    {
      label: "Fecha fin",
      placeholder: "Dd/mm/aaaa",
      name: "date_end",
      type: "date",
      disabled,
    },
    {
      label: "Nombre del contrato",
      placeholder: "Nombre del contrato",
      name: "contract_name",
      type: "text",
      disabled,
      width: 600,
      multiline: true,
    },
    {
      label: "Representante legal",
      placeholder: "Representante legal",
      name: "legal_representative",
      type: "text",
      multiline: true,
      disabled: VersionSelected === null ? false : VersionSelected !== 1 ? true : false,
    },
    {
      label: "Licitación Pública",
      placeholder: "Licitación Pública",
      name: "public_tender",
      type: "text",
      disabled,
      width: 600,
    },
  ];
};

export const defaultBatch = {
  id: 1,
  min_import: "",
  max_import: "",
  progress: 60,
  available_quantity: "",
  articles: [],
};

export const batchInputs = (isNewContract, VersionSelected) => {
  return [
    {
      label: "Importe mínimo (Antes de IVA)",
      placeholder: "Importe mínimo",
      name: "minimum_amount",
      type: "text",
      isPrice: true,
      disabled: !isNewContract,
      autoSelect: true,
      width: 350,
    },
    {
      label: "Importe máximo (Antes de IVA)",
      placeholder: "Importe máximo",
      name: "maximum_amount",
      type: "text",
      disabled: VersionSelected !== 1 && !isNewContract,
      isPrice: true,
      autoSelect: true,
      width: 350,
      helperText: "Mayor o igual al importe mínimo",
    },
    {
      label: "% de presupuesto",
      name: "progress",
      type: "progress",
      showProgress: true,
    },
    {
      label: "Cantidad disponible",
      placeholder: "Cantidad disponible",
      name: "available_quantity",
      type: "text",
      isPrice: true,
      disabled: true,
    },
  ];
};

export const defaultArticle = {
  id: 1,
  cons: 1,
  cve_article: "",
  articulo: null,
  unit_measurement: "",
  unit_price: "",
  //TODO: Will be need to change value name, and type of input. At this moment for mockup task, this will be here
  presentacion: null,
  presentaciones: [],
  articulo_marca: null,
  stock_available: "",
};
// If is new contract then article and unit price are not disabled
export const articlesColumns = (isNewContract, contract_type, VersionSelected) => {
  return [
    {
      text: "No. Cons.",
      value: "cons",
      type: "text",
      disabled: !isNewContract,
      width: 100,
    },
    {
      text: "Clave Art.",
      value: "cve_article",
      type: "search",
      searchFunction: contract_type === "RECURRENTE" ? getArticlesRecurrent : getArticles,
      width: 100,
      disabled: !isNewContract || !contract_type,
    },
    {
      text: "Nombre del artículo",
      value: "article",
      type: "search",
      searchFunction: contract_type === "RECURRENTE" ? getArticlesRecurrent : getArticles,
      uppercase: false,
      width: 600,
      disabled: !isNewContract || !contract_type,
    },
    {
      text: "Presentación del proveedor",
      value: "presentacion",
      type: "dropdown2",
      options: [],
      disabled: !isNewContract || contract_type === "",
    },
    {
      text: "Equivalencia en stock",
      value: "stock_available",
      type: "text",
      disabled: true,
    },
    {
      text: "Precio unitario (Antes de IVA)",
      value: "unit_price",
      type: "text",
      isPrice: true,
      disabled: !isNewContract || contract_type === "",
    },
    //TODO: Will be need to change value name, and type of input. At this moment for mockup task, this will be here
    {
      text: "Marca",
      value: "articulo_marca",
      type: "text",
      width: 350,
      disabled: !isNewContract,
    },
    contract_type === "RECURRENTE" && {
      text: "Modelo",
      value: "articulo_modelo",
      type: "text",
      width: 350,
      maxLength: 250,
      disabled: (!isNewContract && VersionSelected !== 1) || contract_type === "",
    },
    {
      text: "Descripcion",
      value: "articulo_descripcion",
      type: "text",
      width: 600,
      isTextArea: true,
      multiline: true,
      rows: 4,
      maxLength: 500,
      disabled: !isNewContract,
    },
    {
      text: "Acciones",
      type: "icon",
      value: "delete",
      disabled: !isNewContract,
    },
  ];
};

export const articlesColumnsDetails = [
  {
    text: "No. Cons.",
    value: "cons",
    type: "text",
    disabled: true,
  },
  {
    text: "Clave Art.",
    value: "cve_article",
    type: "text",
  },
  {
    text: "Nombre del artículo",
    value: "article",
    type: "select",
  },
  {
    text: "Unidad de medida",
    value: "unit_measurement",
    type: "text",
  },
  {
    text: "Precio unitario",
    value: "unit_price",
    type: "text",
    width: 70,
  },
  {
    text: "Cantidad entregada",
    value: "delivered_quantity",
    type: "text",
    disabled: true,
  },
  {
    text: "Presupuesto ejercido",
    value: "perform_budget",
    type: "text",
    disabled: true,
  },
  {
    text: "Entradas",
    value: "entries",
    type: "text",
    disabled: true,
  },
];

export const amountInputs = (isSearch) => [
  {
    label: "Subtotal",
    placeholder: "Subtotal",
    name: "subtotal",
    type: "text",
    isPrice: true,
    disabled: isSearch ? false : true,
  },
  {
    label: "IVA",
    placeholder: "IVA",
    name: "iva",
    type: "text",
    isPrice: true,
    disabled: true,
  },
  {
    label: "Importe neto",
    placeholder: "Importe neto",
    name: "total",
    type: "text",
    isPrice: true,
    disabled: isSearch ? false : true,
  },
];

export const contractsListFixed = (res) =>
  res.map((contract) => {
    const materialsArray = contract.contract_set
      .map((set) => set.set_item.map((item) => item?.id?.toString()).join(","))
      .filter((el) => el.length > 0);
    const materialQuantity =
      materialsArray?.toString().length === 0
        ? 0
        : materialsArray?.toString().split(",").length;
    let min_amount = 0,
      max_amount = 0,
      exercised_amount = 0,
      missing_min_amount = 0,
      missing_max_amount = 0;
    for (let i = 0; i < contract.contract_set.length; i++) {
      min_amount += contract.contract_set[i].minimum_amount;
      max_amount += contract.contract_set[i].maximum_amount;
      for (let j = 0; j < contract.contract_set[i].set_item.length; j++) {
        exercised_amount += contract.contract_set[i].set_item[j].unit_price;
      }
    }
    // CALCULO DE PRESUPUESTO MINNNIMO Y MAXIMO FALTANTE
    if (min_amount > exercised_amount) {
      missing_min_amount = min_amount - exercised_amount;
    } else if (max_amount > exercised_amount) {
      missing_max_amount = max_amount - exercised_amount;
    }

    return {
      ...contract,
      proveedor: contract.supplier?.razon_social,
      supplier: contract.supplier_number.toString(),
      materials: materialsArray.join(","),
      applicant_num_materials: materialQuantity,
      min_amount,
      max_amount,
      exercised_amount,
      missing_min_amount,
      missing_max_amount,
    };
  });

export const getEditPayload = (contract) => {
  let payload = { ...contract };
  Object.entries(payload).forEach(([field, value]) => {
    if (field.includes("date") && !!value) {
      // TODO: check what does formatDate is doing with our value
      payload[field] = formatDate(value);
      if (payload[field].includes("/") && payload[field].split("/")[2].length === 2) {
        const splitedDate = payload[field].split("/");
        payload[field] = `${splitedDate[0]}/${splitedDate[1]}/20${splitedDate[2]}`;
      }
    }
  });
  return payload;
};

export const getSubmitPayload = (contract, user) => {
  let payload = getEditPayload(contract);
  payload = {
    ...payload,
    subtotal: payload.subtotal,
    iva: payload.iva,
    total: payload.total,
    contract_sets: payload.contract_set.map(
      ({ set_item, available_quantity, progress, ...contract }) => ({
        ...contract,
        partida_number: contract.budget_start,
        set_items: set_item.map((item) => ({
          contract_consecutive: item.cons,
          cve_assets_category: item.cve_article ? item.cve_article : undefined,
          name_assets_category: item.article.text || item.article.toUpperCase(),
          unit_measurement: item.unit_measurement,
          unit_price: item.unit_price,
          articulo_marca: item.articulo_marca,
          articulo_modelo: item.articulo_modelo,
          articulo_descripcion: item.articulo_descripcion,
          articulo: item.articulo,
          presentacion: item?.presentacion?.id ?? null,
        })),
      })
    ),
    user: user.id,
  };
  delete payload.contract_set;
  delete payload.max_import;
  delete payload.min_import;
  return payload;
};
