import React from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { reportInputs, vehiculesFinantialTabInputs } from "../utils/vehiculesData";
import SimpleInput from "./SimpleInput";
import UtilInputs from "./UtilInputs";
import { toggleEnableInputs } from "../utils/newEntryData";
const useStyles = makeStyles({
  textFields: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "2rem",
    "&>*": {
      margin: "10px 15px 0px 0px",
    },
  },
});

const VehiculesFinantialTab = ({ formValues, handleChange }) => {
  const classes = useStyles();
  const { enableInputs } = useSelector((store) => store.vehicules);
  const { isSearchPage } = useSelector((store) => store.pathname);
  const { isResultsPage } = useSelector((store) => store.pathname);

  const formValues2 = () => {
    if (isResultsPage) {
      if (
        formValues["cf_proveedor"]?.id === undefined ||
        formValues["cf_cve_partida"]?.id === undefined
      ) {
        return {
          ...formValues,
          cf_area: `${formValues["cf_area"]}`,
          cf_no_cuenta: formValues.cf_no_cuenta || "",
          cf_no_scuenta: formValues.cf_no_scuenta || "",
        };
      } else {
        return {
          ...formValues,
          cf_proveedor: {
            value: formValues.cf_proveedor.id,
            text: `${formValues.cf_proveedor.text2} | ${formValues.cf_proveedor.id}`,
          },
          cf_cve_partida: `${formValues["cf_cve_partida"].id}`,
          cf_no_cuenta: formValues.cf_no_cuenta || "",
          cf_no_scuenta: formValues.cf_no_scuenta || "",
        };
      }
    } else {
      return formValues;
    }
  };
  return (
    <>
      <div className={classes.textFields}>
        {toggleEnableInputs(reportInputs, enableInputs).map((input) => (
          <SimpleInput
            disabled={input.disabled}
            key={input.name}
            label={input.label}
            placeholder={input.placeholder}
            name={input.name}
            multiline={true}
            rows={3}
            width={250}
            value={formValues[input.name]}
            onChange={handleChange}
            inputProps={{ maxLength: input.maxLength }}
          />
        ))}
      </div>
      <UtilInputs
        formValues={formValues2()}
        inputValues={
          isSearchPage
            ? vehiculesFinantialTabInputs(!enableInputs, isSearchPage)
            : toggleEnableInputs(
                vehiculesFinantialTabInputs("", "", isResultsPage),
                enableInputs
              )
        }
        onValuesChange={handleChange}
      />
    </>
  );
};

export default VehiculesFinantialTab;
