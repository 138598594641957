import React, { memo, Suspense, lazy } from "react";
import { makeStyles, Typography, Paper, lighten } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
const ItemEdit = lazy(() => import("../../../components/ItemEdit"));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    whiteSpace: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    width: "100%",
  },
  mainCard: {
    display: "flex",
    flexDirection: "column",
  },
  card1: {
    minHeight: 35,
    padding: "5px 20px 5px 15px",
    boxShadow: "none",
    border: `solid 1px ${lighten(theme.palette?.gray?.main, 0.5)}`,
    borderRadius: 0,
    transition: "border 0.1s ease",
    "&>.MuiCardContent-root:last-child": {
      padding: 0,
    },
    "& input": {
      fontWeight: 500,
      color: "#000",
    },
    "&[class*='card1'] > p": {
      fontWeight: 500,
      color: "#000",
    },
    "&[class*='card2'] > input": {
      fontWeight: 500,
      color: "#000",
    },
  },
}));

const ItemsList = memo(
  ({
    allItems,
    columns = [],
    items,
    handleChange,
    handlePhoto,
    handleChangeQuantity,
    handleRemoveItem,
    handleSaveInventory,
    disabled,
    onSearchTextChange = () => {},
    footer,
    disableIndex,
    disableColumns,
    editOption = false,
    handleEditAction,
    itemsOptions,
    checkedEncontrado,
    setCheckedEncontrado,
    checkedLocalizado,
    setCheckedLocalizado,
    handleCheckEncontrado,
    handleCheckLocalizado,
    currentMode,
    ...props
  }) => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        {columns.map(
          (column, i) =>
            column && (
              <div
                className={classes.mainCard}
                key={column.value + i}
                style={{
                  width: column?.width ?? "auto",
                }}
              >
                <Paper
                  elevation={2}
                  className={classes.card1}
                  style={{
                    width: column?.width ?? "auto",
                  }}
                >
                  <Typography>{column.text}</Typography>
                </Paper>
                <Suspense
                  fallback={<Skeleton animation="wave" width={160} height={70} />}
                >
                  {items.map((item, index) => {
                    let disabledIndex =
                      disableIndex?.findIndex((it) => it === index.toString()) !== -1;
                    let disabledColumn =
                      disableColumns?.findIndex((col) => col === column.value) !== -1;
                    const disableCell = disabledIndex && disabledColumn;
                    return (
                      <div key={`item-edit-${index}`}>
                        <ItemEdit
                          key={`${item.uuid || item.id}_${index}`}
                          index={index}
                          editOption={editOption}
                          column={column.value}
                          maxLength={column.maxLength}
                          handlePhoto={handlePhoto}
                          value={item[column.value]}
                          handleChange={handleChange}
                          type={column.type}
                          hasLink={column.hasLink}
                          item={item}
                          options={(() => {
                            if (itemsOptions) {
                              return itemsOptions(item, column, index);
                            }
                            let def = [];
                            if (column?.options) {
                              def = column.options;
                            }

                            if (column.value === "presentacion") {
                              def =
                                item?.presentaciones?.map((pre) => ({
                                  name: pre.descripcion,
                                  id: pre.id,
                                })) ?? [];
                            }
                            return def;
                          })()}
                          handleChangeQuantity={handleChangeQuantity}
                          disabled={disabled || column.disabled || disableCell}
                          activeEditOption={disableCell}
                          handleRemoveItem={handleRemoveItem}
                          handleSaveInventory={handleSaveInventory}
                          searchFunction={column.searchFunction}
                          uppercase={column.uppercase}
                          onSearchTextChange={onSearchTextChange}
                          isPrice={column.isPrice}
                          isTextArea={column.isTextArea}
                          decimals={column.decimals}
                          requestFunction={column.requestFunction}
                          handleEditAction={handleEditAction}
                          label={column.label}
                          onClick={column.onClick}
                          color={column.color}
                          fitText={column.fitText}
                        />
                      </div>
                    );
                  })}
                </Suspense>
              </div>
            )
        )}
      </div>
    );
  }
);

export default ItemsList;
