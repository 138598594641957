import {
  getContracts,
  getProviders,
  getProvidersAlmacen,
} from "../crud/catalogsCrud";
import * as columnFormatters from "../components/column-formatters";
import { inputFormatDate } from "./dates";

export const defaultBatch = {
  id: 1,
  min_import: "",
  max_import: "",
  progress: 60,
  available_quantity: "",
  articles: [],
};

export const articlesColumns = [
  {
    text: "No. Cons.",
    value: "cons",
    type: "text",
    disabled: true,
    fitText: true,
  },
  {
    text: "Clave Art.",
    value: "cve_articulo",
    type: "text",
    disabled: true,
    fitText: true,
  },
  {
    text: "Nombre del artículo",
    value: "article",
    type: "text",
    disabled: true,
    fitText: true,
  },
  {
    text: "Unidad de medida",
    value: "unit_measurement",
    type: "text",
    disabled: true,
  },
  {
    text: "Presentación del proveedor",
    value: "presentacion",
    type: "text",
    disabled: true,
  },
  {
    text: "Equivalencia en stock",
    value: "stock_available",
    type: "text",
    disabled: true,
  },
  {
    text: "Precio unitario (Antes de IVA)",
    value: "unit_price",
    type: "text",
    disabled: true,
    isPrice: true,
  },
  {
    text: "Cantidad solicitada",
    value: "requested_amount",
    type: "text",
  },
  {
    text: "Precio total (Antes de IVA)",
    value: "total_price",
    type: "text",
    disabled: true,
    isPrice: true,
  },
  {
    text: "Marca",
    value: "articulo_marca",
    type: "text",
    disabled: true,
    fitText: true,
  },
];

export const articlesColumnsDetails = [
  {
    text: "No. Cons.",
    value: "cons",
    type: "text",
    disabled: true,
  },
  {
    text: "Clave Art.",
    value: "cve_article",
    type: "text",
  },
  {
    text: "Nombre del artículo",
    value: "article",
    type: "select",
  },
  {
    text: "Unidad de medida",
    value: "unit_measurement",
    type: "text",
  },
  {
    text: "Precio unitario (Antes de IVA)",
    value: "unit_price",
    type: "text",
  },
  {
    text: "Cantidad entregada",
    value: "delivered_quantity",
    type: "text",
    disabled: true,
  },
  {
    text: "Presupuesto ejercido",
    value: "perform_budget",
    type: "text",
    disabled: true,
  },
  {
    text: "Entradas",
    value: "entries",
    type: "text",
    disabled: true,
  },
];

export const batchInputs = [
  {
    label: "Importe mínimo",
    placeholder: "Importe mínimo",
    name: "min_import",
    type: "text",
    isPrice: true,
    disabled: true,
  },
  {
    label: "Importe máximo",
    placeholder: "Importe máximo",
    name: "max_import",
    type: "text",
    isPrice: true,
    disabled: true,
  },
  // {
  // 	label: '% de presupuesto',
  // 	name: 'progress',
  // 	type: 'progress',
  // 	showProgress: true,
  // },
  {
    label: "Cantidad disponible",
    placeholder: "Cantidad disponible",
    name: "available_quantity",
    type: "text",
    isPrice: true,
    disabled: true,
    width: 165,
  },
  {
    label: "Fecha de entrega de materiales",
    name: "date_end",
    type: "date",
    // disabled: false
    width: 250,
  },
];

export const searchInputs = [
  {
    label: "Proveedor",
    name: "supplier",
    type: "text",
    width: 500,
  },
  {
    label: "Contrato",
    name: "contrato",
    type: "text",
  },
  {
    label: "Fecha de requerimiento",
    placeholder: "Dd/mm/aaaa",
    name: "date_requisition",
    type: "text",
    required: true,
  },
  {
    label: "Fecha de entrega de materiales",
    placeholder: "Dd/mm/aaaa",
    name: "max_delivery_date",
    type: "text",
    required: true,
  },
  {
    label: "Nombre del oficio",
    name: "name_off",
    type: "text",
    required: true,
  },
  {
    label: "No. de requerimiento contrato",
    name: "num_req",
    type: "text",
    disabled: true,
  },
];

export const requirementForm = (contracts = [], id = false) => [
  {
    label: "Proveedor",
    name: "supplier",
    type: "search",
    searchFunction: getProvidersAlmacen,
    required: true,
    width: 500,
  },
  {
    label: "Contrato",
    name: "contrato",
    type: "dropdown",
    required: true,
    options: contracts,
    disabled: Boolean(contracts.length === 0),
  },
  {
    label: "Fecha de requerimiento",
    placeholder: "Dd/mm/aaaa",
    name: "date_requisition",
    type: "date",
    required: true,
  },
  {
    label: "Fecha de entrega de materiales",
    placeholder: "Dd/mm/aaaa",
    name: "max_delivery_date",
    type: "date",
    required: true,
    width: 250,
  },
  {
    label: "Nombre del oficio",
    name: "name_off",
    type: "text",
    required: true,
  },
  id && {
    label: "No. de requerimiento",
    name: "requisition_contract_number",
    type: "text",
    disabled: true,
  },
];

export const requirements_columns = ({
  sendMail,
  onDetail,
  onCancel,
  onFile,
  onEditDraft,
  onTrashDraft,
}) => [
  {
    dataField: "supplier.razon_social",
    text: "Proveedor",
    sort: false,
  },
  {
    dataField: "contract_number",
    text: "Contrato",
    sort: false,
  },
  {
    dataField: "date_requisition",
    text: "Fecha del requerimiento",
    formatter: (value) => inputFormatDate(value),
    sort: false,
  },
  {
    dataField: "max_delivery_date",
    text: "Vigencia del requerimiento",
    formatter: (value) => inputFormatDate(value),
    sort: false,
  },
  {
    dataField: "name_off",
    text: "No. Oficio",
    sort: false,
  },
  // Entradas is hidden atm
  //   {
  //     dataField: "entrance",
  //     text: "Entradas",
  //     sort: false
  //   },
  // Neto asked to change Entrada to Entradas and remove Fecha de entrada on 14/jul/21
  //   {
  //     dataField: "entrance_date",
  //     text: "Fecha de entrada",
  //     sort: false,
  //   },
  {
    dataField: "requerimiento_cerrado",
    text: "Estatus",
    sort: false,
    formatter: columnFormatters.RequirementStatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.RequirementActionColumnFormatter,
    formatExtraData: {
      onFile,
      sendMail,
      onDetail,
      onCancel,
      onEditDraft,
      onTrashDraft,
    },
    csvExport: false,
  },
];

export const amountInputs = (isSearch) => [
  {
    label: "Subtotal",
    placeholder: "Subtotal",
    name: "subtotal",
    type: "text",
    isPrice: true,
    disabled: isSearch ? false : true,
  },
  {
    label: "IVA",
    placeholder: "IVA",
    name: "iva",
    type: "text",
    isPrice: true,
    disabled: true,
  },
  {
    label: "Importe neto",
    placeholder: "Importe neto",
    name: "total",
    type: "text",
    isPrice: true,
    disabled: isSearch ? false : true,
  },
];

export const initialSelectedRequirement = {
  supplier: null,
  contrato: "",
  date_requisition: "",
  max_delivery_date: "",
  name_off: "",
  observations: "",
  total: 0,
  iva: 0,
  subtotal: 0,
  contract_set: [],
  //TODO: this created default value is only for demonstration. when defines the real value it will be removed.
  num_req: Math.floor(Math.random() * 100),
  all_attachments: [],
};

export const defaultRequirementFilter = {
  supplier: null,
  contrato: null,
  date_requisition: "",
  max_delivery_date: "",
  observations: "",
  name_off: "",
  num_req: "",
  contract_set: [],
  subtotal: "0",
  iva: "0",
  total: "0",
};

export const requirementsFilters = [
  {
    label: "Proveedor",
    placeholder: "Proveedor",
    name: "supplier",
    type: "search",
    searchFunction: getProvidersAlmacen,
    width: 450,
  },
  {
    label: "Contrato",
    placeholder: "Contrato",
    name: "contract",
    type: "search",
    searchFunction: getContracts,
    width: 250,
  },
  {
    label: "Fecha de requerimiento",
    placeholder: "Dd/mm/aaaa",
    name: "date_requisition",
    type: "date",
    width: 250,
  },
];

export const defaultFilter = {
  supplier: null,
  contract: null,
  date_requisition: "",
};

export const intialFooterData = [
  {
    column: "cons",
    type: "text",
    disabled: true,
    width: 76,
  },
  {
    column: "cve_articulo",
    type: "text",
    disabled: true,
    width: 96,
  },
  {
    column: "article",
    type: "text",
    disabled: true,
    width: 350,
  },
  {
    column: "unit_measurement",
    type: "text",
    disabled: true,
  },
  {
    column: "presentacion",
    type: "text",
    disabled: true,
  },
  {
    column: "stock_available",
    type: "text",
    disabled: true,
  },
  {
    column: "unit_price",
    type: "text",
    disabled: true,
    isPrice: true,
  },
  {
    column: "requested_amount",
    type: "text",
    disabled: true,
  },
  {
    column: "total_price",
    type: "text",
    value: 0,
    isPrice: true,
  },
  {
    column: "articulo_marca",
    type: "text",
    disabled: true,
  },
];

export const handleResponseContract = (data, res) => {
  const contractSet = data.contract_set.map((contract, i) => {
    const {
      maximum_amount,
      minimum_amount,
      set_item,
      id,
      delivery_date,
    } = contract;
    const setItem = set_item
      .filter((item) =>
        res.requisition_items.find((x) => x.contract_items_set === item.id)
      )
      .map((item) => {
        const requested = res.requisition_items.find(
          (x) => x.contract_items_set === item.id
        );
        //update each item to set cons, and article information
        return {
          ...item,
          id: requested.id,
          cons: item?.contract_consecutive,
          cve_article: item.articulo?.cve_articulo,
          article: item.name_assets_category,
          requested_amount: requested?.requested_amount,
          articulo_marca: item?.articulo_marca,
          total_price: requested?.requested_amount * item.unit_price,
          presentacion: item?.unit_measurement,
          stock_available: item?.presentacion?.equivalencia,
          iva: item?.iva,
          contract_items_set: requested?.contract_items_set,
        };
      });
    //update contract to set progress and available_quantity
    return {
      id,
      partida_number: contract?.partida_number,
      date_end: delivery_date,
      min_import: minimum_amount,
      max_import: maximum_amount,
      progress: (minimum_amount / maximum_amount) * 100,
      available_quantity:
        maximum_amount -
        set_item.reduce(
          (befVal, currVal, i, arr) => befVal + arr[i]["unit_price"],
          0
        ),
      set_item: setItem,
    };
  });
  const subtotal = contractSet.reduce(
    (acc, contract) =>
      acc +
      contract.set_item.reduce(
        (sum, item) => Number(sum + Number(item.total_price.toFixed(2))),
        0
      ),
    0
  );
  const iva = contractSet.reduce((acc, contract) => {
    // If at least one item on set_item items have iva, then iva its added to all item from the set_item
    const hasIva = contract.set_item.some((e) => e?.iva);

    return (
      acc +
      contract.set_item.reduce(
        (sum, item) => sum + (hasIva ? 0.16 * item.total_price : 0),
        0
      )
    );
    // const maxSubTotal = newState.reduce((acc, curr) => (acc + _.toNumber(curr.maximum_amount)), 0);
  }, 0);
  const payload = {
    ...res,
    subtotal,
    iva,
    total: subtotal + iva,
    contract_set: contractSet,
  };
  delete payload.requisition_items;
  return payload;
};
