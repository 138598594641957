import React from 'react';
import SVG from "react-inlinesvg";

const IconTab = ({ src, onClick, color="danger", label="", disabled, selected, loading=false }) => {
    return (
        <button
            className={`mr-2 btn btn-icon ${selected ? 'btn-secondary' : 'btn-light'} btn-hover-${color} p-2`}
            onClick={onClick}
            disabled={disabled}
            style={{ cursor: disabled && 'not-allowed', minWidth: 100, width: 'auto' }}
        >
          {
              loading ? (
                <div className="spinner-border text-dark spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <span className={`svg-icon svg-icon-md svg-icon-${color}`}>
                <SVG src={src} />
            </span>
              )
          }
            <p style={{margin: 'auto'}}>{label}</p>
        </button>
    );
};

export default IconTab;
