import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as service from "../../app/crud/qrviewArtworkCrud";

const initialState = {
  reload: false,
  currentRequestId: null,
  qrArtworkData: {
    cf_articulo: "",
    cve_area: "",
    inventory: null,
    cabms: "",
    ingreso: "",
    partida: null,
    responsable: "",
    area: "",
    sarea: "",
    sssarea: "",
    edificio: ""
  }
};

export const CONSULTA_QR_ARTWORK = createAsyncThunk(
  "[QRVIEWARTWORK]/CONSULTA_QR_ARTWORK",
  async (id, { rejectWithValue }) => {
    let payload = null;
    try {
      const result = (await service.consultaQRArtwork(id));
      if (result?.status === 200) {
        payload = result.data;
      } else payload = rejectWithValue({ error: "Error" });
    } catch (e) {
      console.log(e);
    }
    return payload;
  }
);

export const qrViewArtworkSlice = createSlice({
  name: "[QRVIEWARTWORK]",
  initialState,
  reducers: {},
  extraReducers: {
    [CONSULTA_QR_ARTWORK.pending]: (state, action) => {
      const { requestId } = action.meta;
      state.reload = true;
      state.currentRequestId = requestId;
    },
    [CONSULTA_QR_ARTWORK.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if(requestId === state.currentRequestId){
        state.reload = false;
        state.qrArtworkData = {...action.payload};
        state.currentRequestId = initialState.currentRequestId;
      }
    },
    [CONSULTA_QR_ARTWORK.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if(requestId === state.currentRequestId){
        state.reload = false;
        state.currentRequestId = initialState.currentRequestId;
      }
    }
  }
});

export const qrViewArtworkSelector = (store) => store.qrViewArtwork;

export default persistReducer({ storage, key: "requests", whitelist: [] }, qrViewArtworkSlice.reducer);