import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { Grid } from "@material-ui/core";
import { setModule } from "../../../redux/ducks/config.duck";
import CardAction from "../../components/CardAction";
import { MODULES } from "../../utils/configData";

const ConfigurationPage = () => {
  const { module } = useSelector((state) => state.config);
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const m = MODULES.find((x) => x.route === location.pathname);

  useEffect(() => {
    if (m) dispatch(setModule(m));
  }, [dispatch, m]);

  const SearchInModule = (title) => {
    const buscador = user.groups.filter((element) => {
      return (
        element.description.toLowerCase().replace("ver ", "") === title.toLowerCase()
      );
    });
    return buscador.length === 0;
  };

  return (
    <Grid container spacing={2}>
      {module?.submodules.map((sm) => {
        return (
          ["Tipo Documento", "Solicitudes de usuarios"].includes(sm.title) && (
            <Grid key={sm.title} item xs={12} sm={4} md={3} lg={2}>
              <CardAction
                disabled={
                  sm.title === "Solicitudes de usuarios"
                    ? false
                    : SearchInModule(sm.title)
                }
                title={sm.title}
                actionButtonTitle={"Editar"}
                onClickActionButton={() => history.push(sm.route)}
                isMaterialIcon={false}
                IconComponent={
                  <path d="M15.9497475,3.80761184 L13.0246125,6.73274681 C12.2435639,7.51379539 12.2435639,8.78012535 13.0246125,9.56117394 L14.4388261,10.9753875 C15.2198746,11.7564361 16.4862046,11.7564361 17.2672532,10.9753875 L20.1923882,8.05025253 C20.7341101,10.0447871 20.2295941,12.2556873 18.674559,13.8107223 C16.8453326,15.6399488 14.1085592,16.0155296 11.8839934,14.9444337 L6.75735931,20.0710678 C5.97631073,20.8521164 4.70998077,20.8521164 3.92893219,20.0710678 C3.1478836,19.2900192 3.1478836,18.0236893 3.92893219,17.2426407 L9.05556629,12.1160066 C7.98447038,9.89144078 8.36005124,7.15466739 10.1892777,5.32544095 C11.7443127,3.77040588 13.9552129,3.26588995 15.9497475,3.80761184 Z"></path>
                }
                colorIcon={"success"}
              />
            </Grid>
          )
        );
      })}
    </Grid>
  );
};

export default ConfigurationPage;
