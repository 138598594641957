import { CATALOGS_URL } from "./helpers/routes"
import { getParams, handleError, handleResponse } from "./helpers/validate"
const PRESENTATIONS_URL = `${CATALOGS_URL}/presentacion-articulo`

export const getPresentationsList = (page, limit) => {
  return fetch(`${PRESENTATIONS_URL}?page=${page}&limit=${limit}`, getParams("GET"))
  .then(handleResponse)
  .catch(handleError)
}

export const getSinglePresentation = (id) => {
  return fetch(`${PRESENTATIONS_URL}/${id}`, getParams("GET"))
  .then(handleResponse)
  .then(res => ({...res, articulo: {id: res.articulo?.id, text: res.articulo?.desc_corta, text2: res.articulo?.cve_articulo}, unidad: res.articulo?.uni_distribuye}))
  .catch(handleError)
}

export const createPresentation = (data) => {
  return fetch(PRESENTATIONS_URL, getParams("POST", data))
  .then(handleResponse)
  .catch(handleError)
}

export const updatePresentation = (data) => {
  console.log(data)
  return fetch(`${PRESENTATIONS_URL}/${data.id}`, getParams("PUT", data))
  .then(handleResponse)
  .catch(handleError)
}

export const deletePresentation = (id) => {
  return fetch(`${PRESENTATIONS_URL}/${id}`, getParams("DELETE"))
  .then(handleResponse)
  .catch(handleError)
}