import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../../components/PageLayout";
import UtilInputs from "../../components/UtilInputs";
import { setOrdinary } from "../../../redux/ducks/departure.duck";
import {
  odinaryForm,
  ordinaryDepartureColumns,
} from "../../utils/departureData";
import BatchTable from "../../components/BatchTable";
import { Box } from "@material-ui/core";

const DepartureOrdinary = () => {
  const dispatch = useDispatch();
  const { ordinary } = useSelector((state) => state.departure);

  const handleForm = (e) => {
    const { name, value } = e.target;
    let namePerson;
    if (value.includes("Lic")) {
      namePerson = value.replace("Lic. ", "");
    } else {
      namePerson = value;
    }
    const updateField = (fieldName) => {
      dispatch(
        setOrdinary({
          ...ordinary,
          [fieldName]: namePerson,
          [`puesto_${fieldName}`]: "Lic",
          [`${fieldName}_drop`]: value,
        })
      );
    };

    if (name === "responsable_entrega_drop") {
      updateField("responsable_entrega");
    } else if (name === "responsable_recibe_drop") {
      updateField("responsable_recibe");
    } else {
      dispatch(setOrdinary({ ...ordinary, [name]: value }));
    }
  };

  const handleAddItem = () => {
    dispatch(
      setOrdinary({
        ...ordinary,
        items: [
          ...ordinary.items,
          { description: "", measure_unit: "", delivery_quantity: "" },
        ],
      })
    );
  };

  const onRemoveItem = (recItem, index) => {
    const updatedItems = [...ordinary.items];
    updatedItems.splice(index, 1);
    dispatch(
      setOrdinary({
        ...ordinary,
        items: updatedItems,
      })
    );
  };

  const handleTable = (i, column, value, index) => {
    if (column === "delivery_quantity") {
      if (isNaN(value)) {
        return;
      }
    }

    const updatedItems = [...ordinary.items];
    updatedItems[index][column] = value;
    dispatch(
      setOrdinary({
        ...ordinary,
        items: updatedItems,
      })
    );
  };
  const onHandlechecks = (e) => {
    const { name, checked } = e.target;
    dispatch(
      setOrdinary({
        ...ordinary,
        [name]: checked,
      })
    );
  };

  useEffect(() => {
    if (ordinary.atiende_folio_dgrmsg === false) {
      dispatch(
        setOrdinary({
          ...ordinary,
          folio_dgrmsg: "",
        })
      );
    }
  }, [ordinary.atiende_folio_dgrmsg]);

 
  useEffect(() => {
    dispatch(
      setOrdinary({
        ...ordinary,
        items: [],
      })
    );
  }, []);

  return (
    <PageLayout title="Salida ordinaria">
      <UtilInputs
        width={300}
        formValues={ordinary}
        onValuesChange={handleForm}
        inputValues={odinaryForm(
          ordinary.responsable_entrega,
          ordinary.responsable_recibe,
          ordinary.atiende_folio_dgrmsg
        )}
      />
      <section
        style={{
          display: "flex",
          fontWeight: "bold",
          gap: "10px",
          margin: "10px 0px",
        }}
      >
        <div>
          <label style={{ marginRight: "10px" }}>
            <input
              type="checkbox"
              name="atiende_folio_dgrmsg"
              style={{ marginRight: "10px" }}
              onChange={(e) => onHandlechecks(e)}
              value={ordinary.atiende_folio_dgrmsg}
            />
            ATIENDE A FOLIO DE LA DGRMSG.
          </label>
        </div>

        <div>
          <label style={{ marginRight: "10px" }}>
            <input
              type="checkbox"
              name="ccp_instrumentales"
              style={{ marginRight: "10px" }}
              onChange={(e) => onHandlechecks(e)}
              value={ordinary.ccp_instrumentales}
            />
            C.C.P. Jefe de Departamento de Control de Inventarios
          </label>
        </div>
      </section>
      <Box className="mt-5">
        <BatchTable
          i={0}
          items={ordinary.items ?? []}
          columns={ordinaryDepartureColumns}
          handleChange={(i, column, value, index) => {
            handleTable(i, column, value, index);
          }}
          addRow={handleAddItem}
          deleteRow={onRemoveItem}
        />
      </Box>
    </PageLayout>
  );
};

export default DepartureOrdinary;
