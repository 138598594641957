import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultArticleForm } from "../../app/utils/articlesData";

const actionType = {
  SetArticle: "[ARTICLES] SET_ARTICLE",
  SetArticles: "[ARTICLES] SET_ARTICLES",
  SetArticlesIds: "[ARTICLES] SET_ARTICLES_IDS",
  SetClearArticles: "[ARTICLES] SET_CLEAR_ARTICLES",
  SetSearchArticleForm: "[ARTICLES] SET_SEARCH_ARTICLE_FORM",
  SetNewArticleForm: "[ARTICLES] SET_NEW_ARTICLE_FORM",
};

const initialState = {
  list: {
    results: [],
    count: 0,
  },
  selectedArticle: defaultArticleForm,
  selectedArticleIds: [],
  searchArticleForm: defaultArticleForm,
  newArticleForm: defaultArticleForm,
};

export const reducer = persistReducer(
  { storage, key: "article", whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetArticles:
        return { ...state, list: action.payload };

      case actionType.SetArticle:
        return { ...state, selectedArticle: action.payload };

      case actionType.SetArticlesIds:
        return { ...state, selectedArticleIds: action.payload };

      case actionType.SetClearArticles:
        return initialState;

      case actionType.SetSearchArticleForm:
        return { ...state, searchArticleForm: action.payload };

      case actionType.SetNewArticleForm:
        return { ...state, newArticleForm: action.payload };

      default:
        return state;
    }
  }
);

export const setArticles = (data) => ({
  type: actionType.SetArticles,
  payload: data,
});
export const setArticlesIds = (data) => ({
  type: actionType.SetArticlesIds,
  payload: data,
});
export const setArticle = (data) => ({
  type: actionType.SetArticle,
  payload: data,
});
export const setClearArticles = () => ({ type: actionType.SetClearArticles });
export const setSearchArticleForm = (searchArticuleForm) => ({
  type: actionType.SetSearchArticleForm,
  payload: searchArticuleForm,
});
export const setNewArticleForm = (newArticuleForm) => ({
  type: actionType.SetNewArticleForm,
  payload: newArticuleForm,
});
