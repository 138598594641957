import React from 'react'

export const ContractsPercentageColumnFormatter = (cellContent, row, rowIndex) => {
  return (
    <span
			className={`badge badge-pill badge-${
				cellContent >= 80 ? 'danger' : cellContent >= 40 ? 'warning' : 'success'
			} label-inline`}
		>
			{cellContent} %
		</span>
  )
}
