import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Badge,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";
import DocumentsList from "../../../../components/DocumentsList";
import FileButton from "../../../../components/FileButton";
import FileIconImg from "../../../../components/FileIconImg";
import notice from "../../../../components/Notice";
import { defaultDoc } from "../../utils";
import {
  digitalizationWithModule,
  digitalizationWithModuleArea,
  digitalizationWithModuleAreaAndResponsable,
} from "../../../../crud/digitalVaultCrud";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../../../redux/ducks/loader.duck";
import SearchIcon from "@material-ui/icons/Search";
import { searchModuleAreaYear } from "../../../../crud/areasFilterCrud";
import { useLocation } from "react-router";

const DigitalizationListDocs = ({
  data,
  newDigitalization = false,
  module = "",
  attachTo = "",
  onlyPdf = false,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const { idsFilterResult } = useSelector((store) => store.stock);
  const dispatch = useDispatch();
  const [description, setDescription] = useState();
  const [listDocs, setListDocs] = useState([]);
  const [multipleIds, setMultipleIds] = useState(false);
  const [doc, setDoc] = useState(defaultDoc);
  const [year, setYear] = useState();
  const [errorSearch, setErrorSearch] = useState(false);

  const [errorUp, setErrorUp] = useState(false);

  const [buttonDisable, setButtonDisable] = useState(true);
  const [buttonDisableYear, setButtonDisableYear] = useState(true);

  const [emptyDoc, setEmptyDoc] = useState();
  useEffect(() => {
    if (data) {
      setListDocs(data);
      setEmptyDoc(false);
    }
  }, [data]);

  const handleAttachments = (files) => {
    if (onlyPdf) {
      for (const file of files) {
        if (onlyPdf && !file.type.includes("pdf")) {
          notice("Solo se permiten archivos PDF", "error");
          return;
        } else {
          setDoc({ ...doc, attachments: files });
        }
      }
    } else {
      setDoc({ ...doc, attachments: files });
    }
  };

  const handleRemoveAttachment = (index) => {
    const updatedAtt = doc.attachments;
    updatedAtt.splice(index, 1);
    setDoc({ ...doc, attachments: [...updatedAtt] });
  };

  const updateListDocs = (newDocs) => {
    const newDocsObject =
      module === "area"
        ? { "Recientemente subido": newDocs }
        : { "Recientemente digitalizados": newDocs };
    setListDocs({ ...newDocsObject, ...listDocs });
  };
  //bsucr por año
  const handleChangeYear = (e) => {
    if (e.target.value === "") {
      setEmptyDoc(false);
      setListDocs(data);
      setErrorSearch(false);
      setButtonDisable(true);
    }

    if (e.target.value > 2999) {
      setErrorSearch(true);
      setButtonDisable(true);
    } else if (e.target.value < 1900) {
      setErrorSearch(true);
      setButtonDisable(true);
    } else {
      setErrorSearch(false);
      setButtonDisable(false);
    }

    setYear(e.target.value);
    if (e.target.value.length === 4) {
      handleSearchYear(true, e.target.value);
    }
  };

  const handleSearchYear = async (type = false, value = "") => {
    const payload = type ? value : year;
    return searchModuleAreaYear(attachTo, payload).then((res) => {
      let objEmpty = Object.entries(res).length === 0;
      if (objEmpty) {
        setEmptyDoc(true);
        setListDocs(res);
      } else {
        setListDocs(res);
        setEmptyDoc(false);
      }
    });
  };

  //description
  const handleChange = (e) => {
    // if (e.target.value.length === 4) {
    //   setButtonDisable(false);
    // } else {
    //   setButtonDisable(true);
    // }

    if (e.target.value > 2999) {
      setErrorUp(true);
      setButtonDisableYear(true);
    } else if (e.target.value < 1900) {
      setButtonDisableYear(true);
      setErrorUp(true);
    } else {
      setButtonDisableYear(false);
      setErrorUp(false);
    }
    setDescription(e.target.value);
  };
  //subir archivos en la secci;on catalogos area
  const handeSubmitAreaAndResponsable = () => {
    const request = digitalizationWithModuleAreaAndResponsable(
      module,
      attachTo,
      doc.attachments,
      description
    );
    dispatch(setLoader(true));
    request
      .then((res) => {
        updateListDocs(res);

        setDoc(defaultDoc);

        notice("Se han cargado los archivos con éxito", "success");
      })
      .catch((e) => {
        if (e.hasOwnProperty("url")) {
          notice("Debe seleccionar al menos un archivo", "info");
        } else {
          notice("Ha ocurrido un error al cargar sus archivos");
        }
      })
      .finally(() => dispatch(setLoader(false)));
  };

  useEffect(() => {
    if (
      (pathname.includes("inventario-detalles/resultados") ||
        pathname.includes("entradas/resultados")) &&
      idsFilterResult.length > 1
    ) {
      setMultipleIds(true);
    }
  }, []);
  const onSubmit = (attachAll) => {
    if (attachAll && multipleIds) {
      const listIds = idsFilterResult.toString().replaceAll(",", "|");
      const request = digitalizationWithModule(
        module,
        listIds,
        doc.attachments
      );
      dispatch(setLoader(true));
      request
        .then((res) => {
          updateListDocs(res);
          setDoc(defaultDoc);

          notice("Se han cargado los archivos con éxito", "success");
        })
        .catch((e) => {
          if (e.hasOwnProperty("url")) {
            notice("Debe seleccionar al menos un archivo", "info");
          } else {
            notice("Ha ocurrido un error al cargar sus archivos");
          }
        })
        .finally(() => dispatch(setLoader(false)));
    } else {
      const request = digitalizationWithModule(
        module,
        attachTo,
        doc.attachments
      );
      dispatch(setLoader(true));
      request
        .then((res) => {
          updateListDocs(res);
          setDoc(defaultDoc);

          notice("Se han cargado los archivos con éxito", "success");
        })
        .catch((e) => {
          if (e.hasOwnProperty("url")) {
            notice("Debe seleccionar al menos un archivo", "info");
          } else {
            notice("Ha ocurrido un error al cargar sus archivos");
          }
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };
  // let objEmpty = Object.entries(listDocs).length === 0;
  const handleOnBlur = () => {
    if (year === "") {
      setErrorSearch(false);
    } else if (year > 2999) {
      setErrorSearch(true);
    } else if (year < 1900) {
      setErrorSearch(true);
    } else {
      setErrorSearch(false);
    }
  };
  const handleOnBlurYear = () => {
    if (year === "") {
      setErrorUp(false);
    } else if (year > 2999) {
      setErrorUp(true);
    } else if (year < 1900) {
      setErrorUp(true);
    } else {
      setErrorUp(false);
    }
  };

  return (
    <Box mb={2} mt={5}>
      {listDocs.length === 0 ? (
        <h1>Cargando</h1>
      ) : (
        <>
          {module === "area" || module === "person" ? (
            <Box
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography
                  color={listDocs.length ? "primary" : "secondary"}
                  variant="subtitle1"
                  style={{ position: "static" }}
                >
                  {Object.keys(listDocs).length
                    ? " Resguardos"
                    : " Sin resguardos"}
                </Typography>

                {newDigitalization && (
                  <FileButton
                    isIconButton
                    multiple
                    accept="*"
                    buttonTitle="Seleccionar nuevos archivos"
                    handleChange={handleAttachments}
                  />
                )}
              </div>
              {/* <div> */}
              {/* {!objEmpty && ( */}
              {module === "area" && (
                <FormControl
                  style={{
                    width: "25ch",
                    marginTop: 15,
                    height: 50,
                  }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Buscar por año
                  </InputLabel>
                  <OutlinedInput
                    onBlur={handleOnBlur}
                    error={errorSearch}
                    id="outlined-adornment-password"
                    label="Buscar por año"
                    type="text"
                    value={year}
                    onChange={handleChangeYear}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleSearchYear}
                          disabled={buttonDisable}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errorSearch && (
                    <FormHelperText
                      style={{
                        color: "red",
                        textAlign: "left",
                        marginLeft: 0,
                      }}
                    >
                      Número inválido
                    </FormHelperText>
                  )}
                </FormControl>
              )}

              {/* )} */}

              {/* </div> */}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                color={listDocs.length ? "primary" : "secondary"}
                variant="subtitle1"
              >
                {Object.keys(listDocs).length
                  ? " Documentos digitalizados"
                  : " Sin documentos digitalizados"}
              </Typography>
              {newDigitalization && (
                <FileButton
                  isIconButton
                  multiple
                  accept="*"
                  buttonTitle="Seleccionar nuevos archivos"
                  handleChange={handleAttachments}
                />
              )}
            </Box>
          )}

          {newDigitalization && (
            <div
              className="d-flex mt-4"
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {doc.attachments?.map((att, i) => (
                <Badge
                  badgeContent="X"
                  color="error"
                  key={att.name}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRemoveAttachment(i)}
                >
                  <FileIconImg name={att.name} type={att.type} width={50} />
                </Badge>
              ))}
              {doc.attachments?.length > 0 && (
                <div style={{ marginTop: 10 }}>
                  {(module === "area" || module === "person") && (
                    <TextField
                      error={errorUp}
                      label="Año"
                      size="small"
                      variant="outlined"
                      type="number"
                      onChange={handleChange}
                      onBlur={() => handleOnBlurYear()}
                      value={description}
                      style={{
                        marginLeft: 30,
                        width: 150,
                      }}
                      helperText={errorUp && "  Número inválido"}
                    />
                  )}
                  {module === "area" ? (
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => handeSubmitAreaAndResponsable()}
                      disabled={buttonDisableYear}
                    >
                      Subir
                    </Button>
                  ) : module === "person" ? (
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => handeSubmitAreaAndResponsable()}
                    >
                      Subir
                    </Button>
                  ) : multipleIds ? (
                    <div>
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => {
                          onSubmit(true);
                        }}
                      >
                        {`Digitalizar todos [${idsFilterResult.map((x) => x)}]`}
                        `
                      </Button>
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => {
                          onSubmit(false);
                        }}
                      >
                        {`Digitalizar ${attachTo}`}`
                      </Button>
                    </div>
                  ) : (
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => onSubmit()}
                    >
                      {`Digitalizar ${attachTo}`}`
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
          <DocumentsList
            data={listDocs}
            setListDocs={setListDocs}
            module={module}
          />
          {emptyDoc && <p>No se encontraron archivos de ese año</p>}
        </>
      )}
    </Box>
  );
};

export default DigitalizationListDocs;
