import format from "date-fns/format";
import esLocale from "date-fns/locale/es";

// Function dateToString shuld accept an argument as type date and returns its as text like 2021-06-19
export const dateToString = (date) =>
  date instanceof Date && !isNaN(date.getTime())
    ? date.toISOString().split("T")[0]
    : undefined;

// unused
//export const stringToDate = string => new Date(string);

// unused
//export const dateToGMT = date => date === null ? undefined : new Date(date) instanceof Date ? new Date(new Date(date).toGMTString().replace(" GMT", "")) : undefined;

// Function formatDate should return current date as the format requested, example: formatDate('yyyy-MM-dd') returns 2021-06-19
export const formatDate = (form, date = new Date()) =>
  format(date, form, { locale: esLocale })
    .split(" ")
    .map((x) => x[0].toUpperCase() + x.substring(1))
    .join(" ");

export const setFormatDate = (date, form) =>
  format(new Date(date), form, { locale: esLocale })
    .split(" ")
    .map((x) => x[0].toUpperCase() + x.substring(1))
    .join(" ");

// Function inputFormatDate transforms inputs like 2021-06-19 to 06/19/2021
export const inputFormatDate = (date) => {
  if (date){
    if (!date?.includes('-'))
      return date
    const dateParts = date.split("-");
    return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
  } else return ''

};

export const requestFormatDate = (date) => {
  const dateParts = date.split("/");

  return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
};

// MM/DD/YYYY
export const requestFormatDateToMMDDYYYY = (date) => {
  const dateParts = date.split("/");
  return `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
};

export const dateTo_DDMMYYYY = (date) => {
  let _day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  let _month =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`;
  let _year =
    date.getFullYear() < 10 ? `0${date.getFullYear()}` : date.getFullYear();

  _month = _month === "12" ? "00" : _month;

  return `${_day}/${_month}/${_year}`;
};

export const AddDays = (date, numberDays) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + numberDays
  );
};

function padLeft(n) {
  return ("00" + n).slice(-2);
}

// Function formatDateDDMMYYYY should return current date with this format: 25/12/2021
export const formatDateDDMMYYYY = () => {
  var d = new Date(),
    dformat = [
      padLeft(d.getDate()),
      padLeft(d.getMonth() + 1),
      d.getFullYear(),
    ].join("/");
  return dformat;
};

// Returns today day, in format 19/06/2021 (used on downloads filenames)
export const getTodayDate = () => {
  return formatDateDDMMYYYY().replaceAll("/", "");
};

export const compareCurrentDate = (date) => {
  const comparitions = {
    same: false,
    less: false,
    major: false,
  };
  let d1 = new Date(date).getTime() + 18000000;
  let d2 = new Date().getTime();
  comparitions.same = d1 === d2;
  comparitions.less = d1 < d2;
  comparitions.major = d1 > d2;

  return comparitions;
};

// Retorna por ejemplo: 19 NOV
export const dateToText = (date) => {
  const dateElements = date.split("-");

  const formatDate = new Date(
    dateElements[0],
    dateElements[1] - 1,
    dateElements[2]
  );
  let month = formatDate.toLocaleString("es-MX", { month: "short" });
  month = month.substring(0, month.length).toUpperCase();
  return `${formatDate.getDate()} ${month}`;
};
