import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as service from "../../app/crud/qrviewCrud";

const initialState = {
  reload: false,
  currentRequestId: null,
  qrData: {
    cf_articulo: "",
    cve_area: "",
    inventory: null,
    entrada: "",
    cabms: "",
    ingreso: "",
    partida: null,
    responsable: "",
    area: "",
    sarea: "",
    sssarea: "",
    edificio: ""
  }
};

export const CONSULTA_QR = createAsyncThunk(
  "[QRVIEW]/CONSULTA_QR",
  async (id, { rejectWithValue }) => {
    let payload = null;
    try {
      const result = (await service.consultaQR(id));
      if (result?.status === 200) {
        payload = result.data;
      } else payload = rejectWithValue({ error: "Error" });
    } catch (e) {
      console.log(e);
    }
    return payload;
  }
);

export const qrViewSlice = createSlice({
  name: "[QRVIEW]",
  initialState,
  reducers: {},
  extraReducers: {
    [CONSULTA_QR.pending]: (state, action) => {
      const { requestId } = action.meta;
      state.reload = true;
      state.currentRequestId = requestId;
    },
    [CONSULTA_QR.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if(requestId === state.currentRequestId){
        state.reload = false;
        state.qrData = {...action.payload};
        state.currentRequestId = initialState.currentRequestId;
      }
    },
    [CONSULTA_QR.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if(requestId === state.currentRequestId){
        state.reload = false;
        state.currentRequestId = initialState.currentRequestId;
      }
    }
  }
});

export const qrViewSelector = (store) => store.qrView;

export default persistReducer({ storage, key: "requests", whitelist: [] }, qrViewSlice.reducer);