import { handleFormData } from "../crud/helpers/validate"

export const zplRequest = (zpl, size = "6x8") => {
  const data = handleFormData({file: zpl})
  return fetch(`https://api.labelary.com/v1/printers/8dpmm/labels/${size}/0/`, {  // MAXIMUM SIZE 15X15 INCHES
    encoding: null,
    method: "post",
    body: data,
    headers: { 'Accept': 'image/*' },
  })
}