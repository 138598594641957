import { Visibility } from '@material-ui/icons';
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const EyeColumnIcon = ({ onClick, tooltip="Ver", color="dark", disabled }) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip id='products-edit-tooltip'>{tooltip}</Tooltip>}
    >
      <button
        className={`btn btn-icon btn-${color} btn-sm btn-hover-${color}`}
        onClick={onClick}
        disabled={disabled}
      >
        <Visibility fontSize="small" />
      </button>
    </OverlayTrigger>
  )
}

export default EyeColumnIcon
