import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { routesPaths } from '../utils/routesPaths';

const useCheckRoutes = (routes) => {
    if(typeof routes === 'string')
        routes = [routes];
    const { pathname } = useLocation();
    const [isInRouteResults, setIsInRouteResults] = useState([]);

    useEffect(() => {
        
            let results = [];
            for(let i=0;i<routes.length;i++){
                let isInPath = false;
                const routeToValidate = routes[i];            
                let validRoutes = routesPaths[routeToValidate];
                if(!validRoutes || validRoutes.length === 0) return;
                if(typeof validRoutes === 'string')
                    validRoutes = [validRoutes];
                for(let j=0;j<validRoutes.length;j++){
                    if(isInPath)
                        j=validRoutes.length;
                    const path = validRoutes[j];
                    if(pathname.includes(path))
                        isInPath = true;
                }
                results = [...results, isInPath];
            };
            setIsInRouteResults(results);
        
    }, [pathname, routes]);

    return isInRouteResults;
};

export { useCheckRoutes };