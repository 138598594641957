import React, { useState } from 'react';
import { Fragment } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ url }) => {
	const [numPages, setNumPages] = useState(null);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};
	return (
		<Document
			file={url}
			onLoadSuccess={onDocumentLoadSuccess}
			//onLoadError={console.log}
			options={{
			  cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
			  cMapPacked: true,
			}}
			loading="Espere. Cargando PDF..."
			error="No se pudo cargar el archivo"
			noData="No se pudo obtener el archivo"
		>
			{Array.from(new Array(numPages), (el, index) => (
				<Fragment>
					<Page key={`page_${index + 1}`} pageNumber={index + 1} />

					<p className="text-center">Página {index + 1} de {numPages}</p>
				</Fragment>
			))}
		</Document>
	);
};

export default PDFViewer;
