import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import FullTable from "../../components/FullTable";
import BaseModal from "../../components/BaseModal";
import notice from "../../components/Notice";
import {
  SEND_EMAIL,
  updateRequest,
  DELETE_REQUEST,
  GET_REQUESTS,
  GET_REQUEST_PDF,
  setSelectedRequest,
  setSelectedItems,
  setRequestsIds,
  setClearSelectedRequest,
  resetFieldsFilter,
  CANCEL_REQUEST,
} from "../../../redux/ducks/request.duck";
import { consumptionItems_columns } from "../../utils/tableData";
import { listConsumptionItem } from "../../crud/ConsumptionItemCrud";
import { useSearchMode } from "../../hooks/useSearchMode";
import { defaultFilter, consumptionFilters } from "../../utils/ConsumptionItemUtils";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";

import ConfigStockDetail from "../Configuration/Catalogos/ConfigStockDetail";
import { dateTo_DDMMYYYY, AddDays } from "../../utils/dates";
import { setLoader } from "../../../redux/ducks/loader.duck";

const ConsumptionItemPage = () => {
  const moduleAdminRoute = "inventario-bienes-almacen-general";
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { authToken, user } = useSelector((store) => store.auth);
  const inventoryModule = user.app_modules.find(
    (module) => module.name === moduleAdminRoute
  );
  const requestModule =
    inventoryModule && inventoryModule.submodules.length > 0
      ? inventoryModule.submodules.find(
          (module) => module.name === "Consumo por articulo"
        )
      : undefined;
  const mode = !pathname.includes(moduleAdminRoute)
    ? "public"
    : requestModule
    ? "admin"
    : "public";
  const [deleteModal, setDeleteModal] = useState(false);
  const [cancelItem, setCancelItem] = useState("");
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(50);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState(defaultFilter(mode));
  const [detailType, setDetailType] = useState(0);
  const getFilterParams = useCallback(() => {
    let params = {
      ...filter,
      cf_fecha_solicitud_entregado:
        filter.cf_fecha_solicitud_entregado_inicio &&
        filter.cf_fecha_solicitud_entregado_fin
          ? `${filter.cf_fecha_solicitud_entregado_inicio}:${filter.cf_fecha_solicitud_entregado_fin}`
          : "",
      cve_articulo: filter.cve_articulo?.cve_articulo,
      cve_area: filter.cve_area ? `*${filter.cve_area}*` : "",
      responsable: filter.responsable ? `*${filter.responsable}*` : "",
      adscripcion: filter.adscripcion ? `*${filter.adscripcion}*` : "",
    };
    delete params.cf_fecha_solicitud_entregado_inicio;
    delete params.cf_fecha_solicitud_entregado_fin;

    // console.log(params);
    return filterParams(clearEmptyStringsObject(cleanObject(params)));
  }, [filter]);

  const handleFilterChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const onConfirmFilter = () => {
    let _validate_cve_articulo = getFilterParams().includes("cve_articulo");
    if (!_validate_cve_articulo) {
      return notice('EL campo "Nombre del artículo" es requerido.');
    } else {
      dispatch(setLoader(true));

      listConsumptionItem(page, per_page, getFilterParams())
        .then((res) => {
          if (res.status === 200) {
            setResults(onApplyFilters(res.data.results, getFilterParams()));
            setPage(page);
            setPer_page(res.data.per_page);
            setCount(res.data.count);
          }
        })
        .catch(console.log)
        .finally(() => dispatch(setLoader(false)));
    }
  };
  // console.log("RESULTS", results);

  const onApplyFilters = (infoArticulos, filters) => {
    //   dataField: "cf_cve_area",
    //   dataField: "area",
    //   dataField: "area.cf_adscri6",
    //   dataField: "deatil1",
    //   dataField: "cf_fecha_solicitud",
    //   dataField: "cf_fecha_entrega",
    //   dataField: "cf_fecha_cancelacion",
    //   dataField: "warehouse_request_details",
    //   dataField: "warehouse_request_details",
    //   dataField: "warehouse_request_details",
    //   dataField: "cf_estatus",
    //   dataField: "SumTotal",

    //agrupar por cf_cve_area
    setDetailType(2);
    let articulosAgrupados = [];

    let codeArea = [
      ...new Set(
        Object.keys(infoArticulos).map((e) => {
          return infoArticulos[e].cf_cve_area;
        })
      ),
    ].filter(Boolean);

    for (const [i, area] of codeArea.entries()) {
      let depositoEntregado = 0;
      let articuloPorArea = infoArticulos.filter(
        (element) => element.cf_cve_area === area
      );

      for (const [j, articulo] of articuloPorArea.entries()) {
        if (j === 0) {
          articulosAgrupados.push({
            adscripcion: articulo.cf_cve_area,
            titular: articulo.area.cf_adscri7,
            area: articulo.area.cf_adscri6,
          });
        }

        if (!articulo.warehouse_request_details.length) {
          continue;
        }
        depositoEntregado +=
          articulo.cf_estatus === "T"
            ? articulo.warehouse_request_details[0].cf_cantidad_entregada
            : articulo.cf_estatus === "D" || articulo.cf_estatus === "H"
            ? articulo.warehouse_request_details[0].cf_cantidad_surtida
            : 0;
      }

      articulosAgrupados[i].detail1 = articuloPorArea;
      articulosAgrupados[i].SumTotal = depositoEntregado;
    }

    setDetailType(2);

    return articulosAgrupados;
  };

  const onClearFilter = () => {
    setFilter(defaultFilter(mode));
    setResults([]);
    setCount(0);
  };

  useSearchMode(
    mode === "admin"
      ? "/inventario-bienes-almacen-general/solicitudes/busqueda"
      : "/solicitudes/lista"
  );

  const sendMail = (id) => {
    dispatch(SEND_EMAIL(id))
      .then(unwrapResult)
      .then((res) => {
        dispatch(updateRequest({ id, cf_estatus: "E" }));
        notice(res?.message || "Se ha enviado el email", "success");
      })
      .catch((error) => {
        notice("No se pudo enviar el email, contacta al administrador");
      });
  };

  const onDetail = (id) => {};

  const onDraft = (id) => {
    history.push(`/solicitudes/resultados/${id}`);
  };

  const onCancel = (id) => {
    setCancelItem(id);
  };

  const onFile = (id) => {
    dispatch(GET_REQUEST_PDF(id))
      .then(unwrapResult)
      .then((result) => {
        if (result?.attachment_url) window.open(result.attachment_url);
      })
      .catch((res) => {
        notice("No se pudo cargar el PDF, contacta al administrador");
      });
    // history.push('/solicitudes/salidas');
  };

  const onTrashDraft = (id) => {
    setSelectedRequestId(id);
    setDeleteModal(true);
  };

  const handleCancelItem = () => {
    dispatch(CANCEL_REQUEST(cancelItem))
      .then((res) => {
        setCancelItem("");
        notice(res.payload.message, "success");
        dispatch(GET_REQUESTS({ page, per_page }));
      })
      .catch((err) => {
        console.log("err", err);
        notice("Ha ocurrido un error, favor de intentar mas tarde");
      });
  };

  const handleDeleteItem = () => {
    dispatch(DELETE_REQUEST(selectedRequestId))
      .then((res) => {
        if (res.payload === "") {
          setDeleteModal(false);
          notice("Borrador borrado exitosamente", "success");
          dispatch(GET_REQUESTS({ page, per_page }));
        } else {
          notice("Ha ocurrido un error borrando el borrador", "error");
        }
      })
      .catch((err) => console.log("err", err));
  };

  const onPrintBarcode = (id) => {
    const a = document.createElement("a");
    a.href = `nextwmsftp://?token=${authToken}?ids=${id}?type=request`;
    a.click();
  };

  useEffect(() => {
    let _validate_cve_articulo = getFilterParams().includes("cve_articulo");
    if (!_validate_cve_articulo) {
    } else {
      dispatch(setLoader(true));

      listConsumptionItem(page, per_page, getFilterParams())
        .then((res) => {
          if (res.status === 200) {
            setResults(onApplyFilters(res.data.results, getFilterParams()));
            setPage(page);
            setPer_page(res.data.per_page);
            setCount(res.data.count);
          }
        })

        .catch(console.log)
        .finally(() => dispatch(setLoader(false)));
    }
  }, [page, per_page]);
  return (
    <>
      <FullTable
        title="Consumo por artículo"
        has_button={false}
        // buttonTitle="Nueva consumo por artículo"
        // onClickButton={handleAdd}
        entities={results}
        columns={consumptionItems_columns(
          {
            sendMail,
            onDetail,
            onCancel,
            onFile,
            onTrashDraft,
            onDraft,
            onPrintBarcode,
          },
          mode,
          detailType
        )}
        page={page}
        setPage={(page) => page > 0 && setPage(page)}
        sizePerPage={per_page}
        setSizePerPage={(pageSize) => setPer_page(pageSize)}
        count={count}
        search_button={mode === "admin"}
        onClickSearch={() => {
          dispatch(resetFieldsFilter());
          history.push(
            mode === "admin"
              ? "/inventario-bienes-almacen-general/solicitudes/busqueda"
              : "/solicitudes/busqueda"
          );
        }}
        // SELECTED REQUESTS ACTIONS
        has_button2={mode === "admin"}
        buttonDisabled2={selectedRequests.length < 1}
        buttonTitle2={`Consultar Todos${
          selectedRequests.length > 1 ? `(${selectedRequests.length.toString()})` : ""
        }`}
        // onSelectRow={handleSelectRow}
        // onSelectAllRows={handleSelectAllRows}
        // onClickButton2={handleReqSelected}
        // nonSelectableRows={mode === "public" ? results.map(({ id }) => id) : []}
        hasReportIcons={mode === "admin"}
        // FILTERS
        //has_filter
        formFilters={filter}
        onFilterChange={handleFilterChange}
        filterInputs={consumptionFilters(mode)}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
        open_filter={true}
      />
      <BaseModal
        open={deleteModal}
        title="¿Borrar Borrador?"
        onClose={() => setDeleteModal(false)}
        onCancel={() => setDeleteModal(false)}
        onConfirm={handleDeleteItem}
        confirmLabel="Si, eliminar la solicitud"
        cancelLabel="No"
        maxWidth="sm"
        color="secondary"
      >
        <p>¿Estás seguro de que deseas borrar el Borrador</p>
      </BaseModal>
      <BaseModal
        open={Boolean(cancelItem)}
        title="¿Cancelar solicitud?"
        onClose={() => setCancelItem("")}
        onCancel={() => setCancelItem("")}
        onConfirm={handleCancelItem}
        confirmLabel="Si, cancelar la solicitud"
        cancelLabel="No"
        maxWidth="sm"
        color="secondary"
      >
        <p>¿Estás seguro de que deseas cancelar la solicitud</p>
      </BaseModal>
    </>
  );
};

export default ConsumptionItemPage;
