import React, { useCallback } from 'react';
import {useDropzone} from 'react-dropzone';

const DropzoneWrapper = ({ children, onFilesDrop=()=>{} }) => {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    onFilesDrop(acceptedFiles);
  }, [onFilesDrop])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop, noClick: true })

  return (
    <div className="h-100" {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="d-flex h-100">{children(isDragActive)}</div>
    </div>
  )
}

export default DropzoneWrapper;