import React, { memo, Suspense, lazy } from "react";
// import ItemEdit from "./ItemEdit";
import {
  makeStyles,
  Typography,
  Paper,
  TextField,
  InputAdornment,
  lighten,
  Checkbox,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import CloseIcon from "@material-ui/icons/Close";
import { PriceNumberFormat, NormalInput } from "./SimpleInput";
const ItemEdit = lazy(() => import("./ItemEdit"));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    whiteSpace: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    width: "100%",
  },
  snackbar: {
    "& .MuiSnackbarContent-root ": {
      backgroundColor: "#3699FF",
    },
  },
  mainCard: {
    display: "flex",
    flexDirection: "column",
  },
  card1: {
    minHeight: 35,
    padding: "5px 20px 5px 15px",
    boxShadow: "none",
    border: `solid 1px ${lighten(theme.palette?.gray?.main, 0.5)}`,
    borderRadius: 0,
    transition: "border 0.1s ease",
    "&>.MuiCardContent-root:last-child": {
      padding: 0,
    },
    "& input": {
      fontWeight: 500,
      color: "#000",
    },
    "&[class*='card1'] > p": {
      fontWeight: 500,
      color: "#000",
    },
    "&[class*='card2'] > input": {
      fontWeight: 500,
      color: "#000",
    },
  },
}));

const useItemFooterStyles = makeStyles((theme) => ({
  card2: {
    maxHeight: "37px",
    backgroundColor: ({ column }) => "#dedede",
    padding: ({ type }) => (type === "icon" ? 1 : "5px 20px"),
    textAlign: ({ type }) => type === "icon" && "center",
    boxShadow: "none",
    border: `solid 1px ${lighten(theme.palette?.gray?.main, 0.5)}`,
    borderRadius: 0,
    transition: "border 0.1s ease",
    "&>.MuiCardContent-root:last-child": {
      padding: 0,
    },
    "&:hover": {
      border: ({ type }) =>
        (type === "text" || type === "select") &&
        `solid 1px ${theme.palette?.secondary?.main}`,
    },
    maxWidth: ({ fitText }) => (fitText ? "100%" : 400),
    minWidth: ({ column }) => column === "id" && 100,
    userSelect: "none",
    "&>*": {
      userSelect: "none",
    },
  },
  adornment1: {
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0px 5px 4px 0px",
  },
  adornment2: {
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0px 0px 4px 3px",
  },
}));

const ItemListFooter = ({
  value,
  type,
  column,
  isPrice,
  isPercentage,
  uppercase,
  isEmpty = false,
  fitText = false,
}) => {
  const classes = useItemFooterStyles({ type, column, fitText });
  return !isEmpty ? (
    <Paper className={classes.card2} elevation={0}>
      {type === "text" && (
        <TextField
          size="small"
          InputProps={{
            disableUnderline: true,
            startAdornment:
              value && isPrice ? (
                <InputAdornment className={classes.adornment1}>$</InputAdornment>
              ) : (
                undefined
              ),
            endAdornment:
              value && isPercentage ? (
                <InputAdornment className={classes.adornment2}>%</InputAdornment>
              ) : (
                undefined
              ),
            inputComponent: isPrice ? PriceNumberFormat : NormalInput,
          }}
          inputProps={{
            style: {
              textAlign: column === "inventory_detail_cf_descuento" && "right",
              width:
                (column === "inventory_cf_descripcion" && 350) ||
                (column === "id" && 150),
              textTransform: uppercase && "uppercase",
              color: "rgba(0, 0, 0, 0.87)",
            },
          }}
          name={`footer-${column}-column`}
          value={value}
          disabled={true}
        />
      )}
    </Paper>
  ) : (
    ""
  );
};

const ItemsList = memo(
  ({
    allItems,
    columns = [],
    items,
    handleChange,
    handlePhoto,
    handleChangeQuantity,
    handleRemoveItem,
    handleSaveInventory,
    disabled,
    onSearchTextChange = () => {},
    footer,
    disableIndex,
    disableColumns,
    editOption = false,
    handleEditAction,
    itemsOptions,
    checkedEncontrado,
    setCheckedEncontrado,
    checkedLocalizado,
    setCheckedLocalizado,
    handleCheckEncontrado,
    handleCheckLocalizado,
    currentMode,
    ...props
  }) => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    let arrayIds = [];
    const handleChangeCheck = (event) => {
      if (event.target.checked === true && items.length > 0) {
        setOpen(true);
        allItems.map((item) => {
          if (item.item_id) {
            arrayIds.push(item.item_id);
          } else if (item.inventory) {
            arrayIds.push(item.inventory);
          }
        });
      }
      navigator.clipboard.writeText(arrayIds.join("|"));
      setChecked(event.target.checked);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const action = (
      <React.Fragment>
        <IconButton size="small" aria-label="close">
          <CloseIcon fontSize="small" onClick={handleClose} />
        </IconButton>
      </React.Fragment>
    );
    return (
      <div className={classes.root}>
        {columns.map(
          (column, i) =>
            column && (
              <div
                className={classes.mainCard}
                key={column.value + i}
                style={{
                  width: column?.width ?? "auto",
                }}
              >
                <Snackbar
                  className={classes.snackbar}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={open}
                  autoHideDuration={4000}
                  onClose={handleClose}
                  message="Copiado"
                  action={action}
                />
                <Paper
                  elevation={2}
                  className={classes.card1}
                  style={{
                    width: column?.width ?? "auto",
                  }}
                >
                  {column.text === "No. Inventario" ? (
                    <Typography>
                      {column.text}
                      <Checkbox
                        size="small"
                        checked={checked}
                        onChange={handleChangeCheck}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ padding: 0, paddingLeft: 5 }}
                      />
                    </Typography>
                  ) : column.text === "Bien encontrado" &&
                    (currentMode === "new" || currentMode === "edit") ? (
                    <Typography>
                      {column.text}
                      <Checkbox
                        size="small"
                        checked={checkedEncontrado}
                        onChange={handleCheckEncontrado}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ padding: 0, paddingLeft: 5 }}
                      />
                    </Typography>
                  ) : column.text === "Bien no localizado" &&
                    (currentMode === "new" || currentMode === "edit") ? (
                    <Typography>
                      {column.text}
                      <Checkbox
                        size="small"
                        checked={checkedLocalizado}
                        onChange={handleCheckLocalizado}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ padding: 0, paddingLeft: 5 }}
                      />
                    </Typography>
                  ) : (
                    <Typography>{column.text}</Typography>
                  )}
                </Paper>
                <Suspense
                  fallback={<Skeleton animation="wave" width={160} height={70} />}
                >
                  {items.map((item, index) => {
                    let disabledIndex =
                      disableIndex?.findIndex((it) => it === index.toString()) !== -1;
                    let disabledColumn =
                      disableColumns?.findIndex((col) => col === column.value) !== -1;
                    const disableCell = disabledIndex && disabledColumn;
                    const footerValue = footer?.find((f) => f.column === column.value);
                    return (
                      <div key={`item-edit-${index}`}>
                        <ItemEdit
                          key={`${item.uuid || item.id}_${index}`}
                          index={index}
                          editOption={editOption}
                          column={column.value}
                          maxLength={column.maxLength}
                          handlePhoto={handlePhoto}
                          value={item[column.value]}
                          handleChange={handleChange}
                          type={column.type}
                          hasLink={column.hasLink}
                          item={item}
                          options={(() => {
                            if (itemsOptions) {
                              return itemsOptions(item, column, index);
                            }
                            let def = [];
                            if (column?.options) {
                              def = column.options;
                            }

                            if (column.value === "presentacion") {
                              def =
                                item?.presentaciones?.map((pre) => ({
                                  name: pre.descripcion,
                                  id: pre.id,
                                })) ?? [];
                            }
                            return def;
                          })()}
                          handleChangeQuantity={handleChangeQuantity}
                          disabled={disabled || column.disabled || disableCell}
                          activeEditOption={disableCell}
                          handleRemoveItem={handleRemoveItem}
                          handleSaveInventory={handleSaveInventory}
                          searchFunction={column.searchFunction}
                          uppercase={column.uppercase}
                          onSearchTextChange={onSearchTextChange}
                          isPrice={column.isPrice}
                          isTextArea={column.isTextArea}
                          decimals={column.decimals}
                          requestFunction={column.requestFunction}
                          handleEditAction={handleEditAction}
                          label={column.label}
                          onClick={column.onClick}
                          color={column.color}
                          fitText={column.fitText}
                        />
                        {footerValue && index === items.length - 1 ? (
                          //@formatter:off
                          <ItemListFooter
                            isEmpty={footerValue?.column !== column.value}
                            key={`footer-${column.value}-column`}
                            value={footerValue?.value ?? ""}
                            type={footerValue?.type}
                            item={item}
                            uppercase={footerValue?.uppercase}
                            isPrice={footerValue?.isPrice}
                            isPercentage={footerValue?.isPercentage}
                            fitText={column.fitText}
                          />
                        ) : (
                          <ItemListFooter
                            isEmpty
                            key={`footer-${column.value}-column`}
                            value={footerValue?.value ?? ""}
                            type={footerValue?.type}
                            item={item}
                            uppercase={footerValue?.uppercase}
                            isPrice={footerValue?.isPrice}
                            isPercentage={footerValue?.isPercentage}
                          />
                        )
                        //@formatter:on
                        }
                      </div>
                    );
                  })}
                </Suspense>
              </div>
            )
        )}
      </div>
    );
  }
);

export default ItemsList;
