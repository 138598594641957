import { axios } from "../../redux/setupAxios";
import notice from "../components/Notice";
import {
  getInventoryFromRequestData,
  formatInventoryTipoEtiqueta,
  formatInventoryStatusEtiqueta,
  formatInventoryTipoBien,
} from "../utils/newEntryData";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../utils/objectsFunctions";
import {
  getMultipartParams,
  getParams,
  handleError,
  handleFormData,
  handleResponse,
} from "./helpers/validate";
import { INSTRUMENTAL_GOODS, UPLOAD_FILE_URL } from "./helpers/routes";
import { getFlattenObject } from "../utils/attachmentsUtils";

const URL = process.env.REACT_APP_API_URL;

const BASE_URL = URL + "/api/bienes-instrumentales";
const ASSET_ACQ_URL = BASE_URL + "/asset_acquisitions";
const CATALOG_CABMS_URL = BASE_URL + "/catalog-cabm";
const CATALOG_CABMS_CLAVE_URL = BASE_URL + "/catalog-cabm-clave";

export const defaultEntriesFilter = {
  cf_fecha_remision: "",
  cf_no_remision: "",
  cf_numero_factura: "",
  cf_fecha_factura: "",
  cf_no_contrato: "",
  cf_no_almacen: "",
  user__first_name: "",
  user__last_name: "",
};

export const getAssetAdq = (limit, page, filters = "") => {
  const filter_params = filterParams(clearEmptyStringsObject(cleanObject(filters)));
  return fetch(
    `${ASSET_ACQ_URL}?min&page=${page}&order=desc&limit=${limit}&${filter_params}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch((e) => {
      console.log(e);
      notice("Ocurrio un error, contacte al administrador");
      throw e;
    });
};

// export const createAssetAdq = body => {
//   return axios.post(ASSET_ACQ_URL, body);
// };

export const createAssetAdq = (body) => {
  const formData = new FormData();
  // Payload object is iterated also its inventory and its objects of each element to append each value to FormData. Inventory fields must be appended as 'inventory[n]<field>'
  for (let i = 0; i < Object.entries(body).length; i++) {
    if (Object.entries(body)[i][0] === "inventory") {
      for (let j = 0; j < Object.entries(body)[i][1].length; j++) {
        for (let k = 0; k < Object.entries(Object.entries(body)[i][1][j]).length; k++) {
          const inventoryField = Object.entries(Object.entries(body)[i][1][j])[k][0];
          const inventoryValue = Object.entries(Object.entries(body)[i][1][j])[k][1];
          if (
            inventoryField === "inventory_foto_data" &&
            inventoryValue?.toString() !== "[object File]"
          ) {
            formData.append(`inventory[${j}]${inventoryField}`, inventoryValue || "");
          } else {
            formData.append(`inventory[${j}]${inventoryField}`, inventoryValue);
          }
        }
      }
    } else {
      const field = Object.entries(body)[i][0];
      let value = Object.entries(body)[i][1];
      const isADateInput = field.includes("fecha") || field.includes("date");
      if (isADateInput && value) {
        const values = value.split("/");
        values[2] = values[2].length === 2 ? "20" + values[2] : values[2];
        value = values.join("-");
      }
      if (!isADateInput || value) {
        formData.append(field, value);
      }
    }
  }
  return fetch(ASSET_ACQ_URL, getMultipartParams("POST", formData))
    .then(handleResponse)
    .catch(handleError);
};

export const getCatalogCABMS = (query, type = null) => {
  const catalogUrl = type === "item" ? CATALOG_CABMS_CLAVE_URL : CATALOG_CABMS_URL;
  const url = query !== "" ? `${catalogUrl}/search/${query}` : catalogUrl;
  return axios.get(url);
};

export const getSingleAsset = (id) => {
  return axios
    .get(`${ASSET_ACQ_URL}/${id}`)
    .then((res) => {
      if (res && res.status && res.status === 200) {
        const { data } = res;
        const { asset_acquisition_details, ...rest } = data;
        const inventory = getInventoryFromRequestData(asset_acquisition_details);
        const payload = {
          ...rest,
          cf_medioadquisicion:
            asset_acquisition_details[0]?.inventory?.cf_medioadquisicion,
          proveedor: rest.supplier?.razon_social,
          inventory,
          cf_username: rest.user?.username,
          attachment_pdfs: getFlattenObject(rest.all_attachments),
        };
        return payload;
      } else return false;
    })
    .catch((e) => {
      console.log("e", e);
      return false;
    });
};

export const updateAsset = (body, id) => {
  const url = `${ASSET_ACQ_URL}/${id}/`;
  body = cleanObject(body);

  Object.entries(body).forEach(([field, value]) => {
    if (
      (field.includes("date") || field.includes("fecha")) &&
      value?.length === 8 &&
      value?.includes("/")
    ) {
      body[field] = value.slice(0, 6) + "20" + value.slice(6 + Math.abs(0));
    }
    if ((field.includes("date") || field.includes("fecha")) && value === "") {
      delete body[field];
    }
  });
  return axios.put(url, { ...body, id });
};

export const updateAssetAcquisition = async (id, body) => {
  let formatterBody = {};
  formatterBody = {
    // foto_data: await getBase64(body.foto_data),
    cf_clase: body.inventory_cf_clase,
    cf_descripcion: body.inventory_cf_descripcion,
    cf_grupo: body.inventory_cf_grupo,
    cf_inve_material_type: body.inventory_cf_inve_material_type,
    cf_estatus_etiqueta: body.inventory_cf_label_status,
    cf_label_type: body.inventory_cf_label_type,
    cf_marca: body.inventory_cf_marca,
    cf_modelo: body.inventory_cf_modelo,
    cf_serie: body.inventory_cf_serie,
    cf_subclase: body.inventory_cf_subclase,
    cf_subgrupo: body.inventory_cf_subgrupo,
    cf_tipo: body.inventory_cf_tipo,
    cf_tipobien: body.inventory_cf_tipobien,
    cf_cantidad_descuento: body.inventory_detail_cf_cantidad_descuento.replace(/,/g, ""),
    cf_descuento: body.inventory_detail_cf_descuento,
    cf_precio_unitario: body.inventory_detail_cf_precio_unitario.replace(/,/g, ""),
  };

  Object.keys(formatterBody).forEach((key) => {
    if (formatterBody[key] === null) {
      delete formatterBody[key];
    }
  });

  return await axios.patch(`${BASE_URL}/asset_acquisitions-detail/${id}/`, formatterBody);
};

export const saveInventoryAsset = async (id, body) => {
  const formatData = {
    cf_inve_material_type: body.inventory_cf_inve_material_type,
    cf_estatus_etiqueta: formatInventoryStatusEtiqueta(
      body.inventory_cf_label_status.toUpperCase()
    ),
    cf_label_type: formatInventoryTipoEtiqueta(
      body.inventory_cf_label_type.toUpperCase()
    ),
    cf_tipobien: formatInventoryTipoBien(body.inventory_cf_tipobien.toUpperCase()),
    cf_marca: body.inventory_cf_marca,
    cf_modelo: body.inventory_cf_modelo,
    cf_descripcion: body.inventory_cf_descripcion,
    cf_serie: body.inventory_cf_serie,
    num_folio: body.inventory_cf_num_inventario_anterior,
    cf_cve_partida: body.inventory_cf_cve_partida,
    cf_no_cuenta: body.inventory_cf_no_cuenta,
    cf_no_scuenta: body.inventory_cf_no_scuenta,
    foto_data: body.foto_data,

    // cabms_articulo: body.cabms_item,
    // cabms_clase: body.inventory_cabms_clase,
    // cabms_subclase: body.inventory_cabms_subclase,
    // cabms_grupo: body.inventory_cabms_grupo,
    // cabms_subgrupo: body.inventory_cabms_subgrupo,
    // cabms_tipo: body.inventory_cabms_tipo,
    cf_articulo: body.item,
    cf_clase: body.inventory_cf_clase,
    cf_grupo: body.inventory_cf_grupo,
    cf_subclase: body.inventory_cf_subclase,
    cf_subgrupo: body.inventory_cf_subgrupo,
    cf_tipo: body.inventory_cf_tipo,
    cf_label_status: body.inventory_cf_label_status,
    // FALTAN ESTOS CAMPOS
    cf_cantidad: body.inventory_detail_cf_cantidad,
    cf_cantidad_descuento: body.inventory_detail_cf_cantidad_descuento,
    cf_descuento: body.inventory_detail_cf_descuento,
    cf_precio_unitario: body.inventory_detail_cf_precio_unitario, // TAL VEZ ES cf_importe
  };

  Object.keys(formatData).forEach((key) => {
    if (formatData[key] === null) {
      delete formatData[key];
    }
  });

  return await axios.put(`${BASE_URL}/inventory/${id}/`, handleFormData(formatData));
};

export const closeAsset = (id) => {
  const url = `${ASSET_ACQ_URL}/close/${id}/`;
  return axios.put(url);
};

export const getFilteredAssets = (entries) => {
  const ids = entries.join("|");
  const url = `${ASSET_ACQ_URL}/get-edit?ids=${ids}`;
  return fetch(url, getMultipartParams("GET"))
    .then((res) => handleResponse(res))
    .then((json) =>
      json.map((rest) => ({
        ...rest,
        cf_fecha_factura: rest.cf_fecha_factura,
        cf_fecha_contrato: rest.cf_fecha_contrato,
        acquisition_date: rest.acquisition_date,
        cf_fecha_remision: rest.cf_fecha_remision,
        cf_fecha_recepcion_documento: rest.cf_fecha_recepcion_documento,
        cf_fecha_requisicion: rest.cf_fecha_requisicion,
        cf_fecha_recepcion_almacen: rest.cf_fecha_recepcion_almacen,
        proveedor: rest.supplier?.razon_social,
        inventory: getInventoryFromRequestData(rest.asset_acquisition_details),
      }))
    )
    .catch((e) => {
      console.log(e);
      notice("Ha ocurrido un error, contacta al administrador");
      throw e;
    });
};

export const addEntryFile = async (
  file = null,
  attach_id,
  title = "",
  attach_model = "asset_acquisition",
  field = "pdfs"
) => {
  const body = {
    attach_id,
    attach_model,
    image_data: file,
    field,
    title,
  };
  return axios.post(UPLOAD_FILE_URL, handleFormData(body));
};

// V2 Endpoint busquedas Smart filter
export const searchEntriesFilter = (params) => {
  const search_params = params;

  if (search_params.cf_no_proveedor !== "") {
    const cf_no_proveedor = search_params.cf_no_proveedor.text2
      ? search_params.cf_no_proveedor.text2.toString()
      : "";
    search_params.cf_no_proveedor = cf_no_proveedor;
  }

  if (search_params.cf_medioadquisicion) {
    search_params.asset_acquisition_details__inventory__cf_medioadquisicion =
      search_params.cf_medioadquisicion;
    delete search_params.cf_medioadquisicion;
  }
  Object.keys(search_params).forEach((key) => {
    search_params[key] === "" && delete search_params[key];
  });
  return axios.post(`${BASE_URL}/asset-acquisitions-search`, { search_params });
};

/*export const searchEntriesFilter = (params) => {
  console.log(params);
  const filter_params = Object.entries(params)
    .filter((x) => x[1])
    .map((x) => x.join("="))
    .join("&");

  return axios.get(`${ASSET_ACQ_URL}-filter?${filter_params}`);
};*/

export const getEntriesFilter = (page, limit, ids) => {
  const payload = {
    ids,
  };
  return axios
    .post(`${ASSET_ACQ_URL}-ids?page=${page}&limit=${limit}`, payload)
    .then((res) => ({
      ...res,
      data: {
        ...res.data,
        results: res.data.results.map((rest) => ({
          ...rest,
          cf_fecha_factura: rest.cf_fecha_factura,
          cf_fecha_contrato: rest.cf_fecha_contrato,
          acquisition_date: rest.acquisition_date,
          cf_fecha_remision: rest.cf_fecha_remision,
          cf_fecha_recepcion_documento: rest.cf_fecha_recepcion_documento,
          cf_fecha_requisicion: rest.cf_fecha_requisicion,
          cf_fecha_recepcion_almacen: rest.cf_fecha_recepcion_almacen,
          proveedor: rest.supplier?.razon_social,
          inventory: getInventoryFromRequestData(rest.asset_acquisition_details),
        })),
      },
    }))
    .catch((e) => {
      console.log(e);
      notice("Ha ocurrido un error, contacta al administrador");
      throw e;
    });
};

export const downloadReport = (ids) => {
  return fetch(
    `${INSTRUMENTAL_GOODS}/reports-assets-acquisitions/`,
    getParams("POST", { ids })
  )
    .then(handleResponse)
    .catch(handleError);
};
