export const routesPaths = {
  instrumentalGoods: "bienes-instrumentales",
  warehouse: "inventario-bienes-almacen-general",

  entryList: "bienes-instrumentales/entradas",
  entryDetails: [
    "bienes-instrumentales/entradas/nueva",
    "bienes-instrumentales/entradas/busqueda",
    "bienes-instrumentales/entradas/resultados",
  ],
  stockList: "bienes-instrumentales/inventario/ver",
  stockDetails: [
    "bienes-instrumentales/inventario-detalles/busqueda",
    "bienes-instrumentales/inventario-detalles/resultados",
  ],
  artWorkList: "bienes-instrumentales/obras-arte/ver",
  artWorkDetails: [
    "bienes-instrumentales/obras-arte/busqueda",
    "bienes-instrumentales/obras-arte/resultados",
    "bienes-instrumentales/obras-arte/nuevo",
    "bienes-instrumentales/obras-arte/resultados/bajas",
  ],
  vehicleList: "bienes-instrumentales/vehiculos/ver",
  vehicleDetails: [
    "bienes-instrumentales/vehiculos/busqueda",
    "bienes-instrumentales/vehiculos/resultados",
    "bienes-instrumentales/vehiculos/nuevo",
  ],
  stockSurveyList: "bienes-instrumentales/levantamiento-inventario",
  stockSurveyDetails: [
    "bienes-instrumentales/levantamiento-inventario/busqueda",
    "bienes-instrumentales/levantamiento-inventario/resultados",
    "bienes-instrumentales/levantamiento-inventario/nuevo",
  ],
  entranceList: "inventario-bienes-almacen-general/entradas/todas",
  entranceListStock: "inventario-bienes-almacen-general/entradas/stock",
  entranceListRecurrentes: "inventario-bienes-almacen-general/entradas/recurrentes",
  entranceListOrdinarias: "inventario-bienes-almacen-general/entradas/ordinarias",

  entranceListDevoluciones: "inventario-bienes-almacen-general/entradas/devoluciones",
  entranceListRevolvente: "inventario-bienes-almacen-general/entradas/fondo-revolvente",
  entranceListGastos: "inventario-bienes-almacen-general/entradas/gastos-a-comprobar",
  entranceDetails: [
    "inventario-bienes-almacen-general/entradas/busqueda",
    "inventario-bienes-almacen-general/entradas/resultados",
    "inventario-bienes-almacen-general/entradas/nuevo",
    "inventario-bienes-almacen-general/entradas/stock/nuevo",
    "inventario-bienes-almacen-general/entradas/recurrentes/nuevo",
    "inventario-bienes-almacen-general/entradas/ordinarias/nuevo",
    "inventario-bienes-almacen-general/entradas/devoluciones/nuevo",
    // "inventario-bienes-almacen-general/entradas/revolvente",
    // "inventario-bienes-almacen-general/entradas/gastos",
  ],
  entranceRefundDetails: [
    "inventario-bienes-almacen-general/entradas/devolucion/busqueda",
    "inventario-bienes-almacen-general/entradas/devolucion/resultados",
    "inventario-bienes-almacen-general/entradas/devolucion/nuevo",
  ],
  departureDetails: [
    "inventario-bienes-almacen-general/salidas/busqueda",
    "inventario-bienes-almacen-general/salidas/resultados",
    "inventario-bienes-almacen-general/salidas/recurrente/nuevo",
    "inventario-bienes-almacen-general/salidas/recurrente/resultados",
    "inventario-bienes-almacen-general/salidas/ordinaria/nuevo",
    "inventario-bienes-almacen-general/salidas/ordinaria/resultados",
  ],

  departureStockList: "inventario-bienes-almacen-general/salidas/stock",
  departureRecurrentList: "inventario-bienes-almacen-general/salidas/recurrentes",
  departureOrdinaryList: "inventario-bienes-almacen-general/salidas/ordinarias",

  departureRecurrentDetails: [
    "inventario-bienes-almacen-general/salidas/recurrente/nuevo",
    "inventario-bienes-almacen-general/salidas/recurrente/resultados",
  ],
  departureOrdinaryDetails: [
    "inventario-bienes-almacen-general/salidas/ordinaria/nuevo",
    "inventario-bienes-almacen-general/salidas/ordinaria/resultados",
  ],
  userList: "/usuarios",
  userDetails: ["usuarios/busqueda", "usuarios/resultados", "usuarios/nuevo"],
  config: "/configuracion",
  configWarehouseDetails: ["/configuracion/almacen/solicitudes"],
  ToDo: "/administracion-tareas",
  ToDoProyects: "/administracion-tareas/proyectos",
  ToDoFinished: "/administracion-tareas/terminadas",
  ToDoBoard: "/administracion-tareas/tableros",
  ToDoDetails: [
    "/administracion-tareas/proyectos",
    "/administracion-tareas/terminadas",
    "/administracion-tareas/tableros",
  ],
  details: ["/busqueda", "/resultados", "/nuev"],
  results: "/resultados",
  search: "/busqueda",
  new: "/nuev",
};
