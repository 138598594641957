import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

const BarcodeColumnIcon = ({ onClick, tooltip="Imprimir etiqueta", disabled, buttonStyle, color="info" }) => {
    return (
			<OverlayTrigger
				overlay={<Tooltip id='products-delete-tooltip'>{tooltip}</Tooltip>}
			>
				<button
					className={`btn btn-icon btn-${color} btn-sm btn-hover-${color}}`}
					style={{
						...buttonStyle,
						cursor: disabled ? 'not-allowed' : 'pointer'
					}}
					onClick={onClick}
					disabled={disabled}
				>
          <span className={`svg-icon svg-icon-md svg-icon-hover-${color}`}>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Barcode.svg')} />
          </span>
				</button>
			</OverlayTrigger>
		);
};

export default BarcodeColumnIcon;