import React from "react";
import FileColumnIcon from "./list-icons/FileColumnIcon";
import TrashColumnIcon from "./list-icons/TrashColumnIcon";

export const EntranceConcentrateColumnoFormatterNew = (
  cellContent,
  row,
  rowIndex,
  { remove }
) => (
  <div className={`d-flex justify-content-${row.isDraft ? "center" : "start"}`}>
    <span className="mr-3">
      <TrashColumnIcon onClick={() => remove(row)} />
    </span>
  </div>
);
