import {
  getBatches,
  getItemCABMSAndKey,
  getProviders,
  addEntryFilePDF,
  deleteEntryFilePDF,
} from "../crud/catalogsCrud";
import {
  labelTypeOptions,
  labelStatusOptions,
  stockMaterialOptions,
} from "./StockFormData";

export const searchEntryData = (providers) => [
  {
    label: "No. de entrada",
    placeholder: "No. de entrada",
    name: "id",
    type: "text",
    disabled: false,
    focus: true,
  },
  {
    label: "Fecha de captura",
    placeholder: "Fecha de captura",
    name: "cf_fecha_actualizacion",
    type: "text",
    disabled: false,
  },
  {
    label: "Tipo de documento",
    placeholder: "Tipo de documento",
    name: "cf_tipo_documento",
    type: "dropdown",
    required: true,
    options: [
      {
        text: "FACTURA",
        value: "1",
      },
      {
        text: "ENTREGA RECEPCIÓN TRANSFERENCIA",
        value: "2",
      },
      {
        text: "ENTREGA RECEPCIÓN DONACIÓN",
        value: "3",
      },
      {
        text: "ACUERDO",
        value: "4",
      },
      {
        text: "NO CUENTA CON DOCUMENTO",
        value: "5",
      },
      {
        text: "REMISIÓN",
        value: "6",
      },
      {
        text: "ACTA ADMINISTRATIVA",
        value: "7",
      },
    ],
  },
  {
    label: "Bienes recibidos en",
    placeholder: "Bienes recibidos en",
    name: "cf_no_almacen",
    type: "dropdown",
    required: true,
    options: [
      {
        text: "ÁREA",
        value: 1,
      },
      {
        text: "ALMACÉN",
        value: 2,
      },
    ],
  },
  {
    label: "Fecha de recepción Almacén",
    placeholder: "Dd/mm/aaaa",
    name: "cf_fecha_recepcion_almacen",
    type: "text",
    required: true,
  },
  {
    label: "Folio de entrada almacén",
    placeholder: "Folio de entrada almacén",
    name: "cf_folio_entrada_almacen",
    type: "text",
    required: true,
    uppercase: true,
    maxLength: 50,
    width: 210,
  },

  {
    name: "cf_no_proveedor",
    label: "Proveedores",
    type: "search",
    showAvatars: true,
    width: "48.3%",
    searchFunction: getProviders,
  },
  // {
  // 	label: 'Fecha de captura',
  // 	placeholder: 'Dd/mm/aaaa',
  // 	name: 'date_capture',
  // 	type: 'date',
  // 	required: true,
  // 	bgColor: '#bbdefb',
  // 	disabled: true
  // },
];

export const entryData = [
  {
    label: "Tipo de documento",
    placeholder: "Tipo de documento",
    name: "cf_tipo_documento",
    type: "dropdown",
    required: true,
    options: [
      {
        text: "FACTURA",
        value: "1",
      },
      {
        text: "ENTREGA RECEPCIÓN TRANSFERENCIA",
        value: "2",
      },
      {
        text: "ENTREGA RECEPCIÓN DONACIÓN",
        value: "3",
      },
      {
        text: "ACUERDO",
        value: "4",
      },
      {
        text: "NO CUENTA CON DOCUMENTO",
        value: "5",
      },
      {
        text: "REMISIÓN",
        value: "6",
      },
      {
        text: "ACTA ADMINISTRATIVA",
        value: "7",
      },
    ],
  },
  {
    label: "Bienes recibidos en",
    placeholder: "Bienes recibidos en",
    name: "cf_no_almacen",
    type: "dropdown",
    required: true,
    options: [
      {
        text: "ÁREA",
        value: 1,
      },
      {
        text: "ALMACÉN",
        value: 2,
      },
    ],
  },
  {
    label: "Fecha de recepción Almacén",
    placeholder: "Dd/mm/aaaa",
    name: "cf_fecha_recepcion_almacen",
    type: "date",
    required: true,
  },
  {
    label: "Folio de entrada almacén",
    placeholder: "Folio de entrada almacén",
    name: "cf_folio_entrada_almacen",
    type: "text",
    required: true,
    uppercase: true,
    maxLength: 50,
    width: 210,
  },
  {
    label: "PROVEEDOR",
    placeholder: "PROVEEDOR",
    name: "proveedor",
    type: "search",
    searchFunction: getProviders,
    required: true,
    uppercase: true,
    width: 550,
  },
];

export const entryData2 = [
  {
    label: "No. Factura",
    placeholder: "No. Factura",
    name: "cf_numero_factura",
    type: "text",
    required: true,
    uppercase: true,
    maxLength: 50,
  },
  {
    label: "Fecha de la factura",
    placeholder: "Dd/mm/aaaa",
    name: "cf_fecha_factura",
    type: "date",
    required: true,
  },
  {
    label: "Fecha recepción Factura DCI",
    placeholder: "Dd/mm/aaaa",
    name: "cf_fecha_recepcion_factura",
    type: "date",
    required: true,
  },
  {
    label: "No. Formato Almacén F",
    placeholder: "No. Formato Almacén F",
    name: "cf_no_formato_almacen",
    type: "text",
    required: true,
    uppercase: true,
    maxLength: 50,
  },
  {
    label: "Fecha recibido Formato Almacén F",
    placeholder: "Dd/mm/aaaa",
    name: "cf_fecha_recibido_formato_almacen",
    type: "date",
    required: true,
  },
  {
    label: "Formato Almacén F",
    name: "formatos_f",
    type: "file",
    required: false,
    uppercase: true,
    accept: "application/pdf",
    uploadFunction: addEntryFilePDF,
    deleteFunction: deleteEntryFilePDF,
  },
];

export const entryData3 = [
  {
    label: "No. De remisión",
    placeholder: "No. De remisión",
    name: "cf_no_remision",
    type: "text",
    required: true,
    uppercase: true,
    maxLength: 50,
  },
  {
    label: "Fecha de la remisión",
    placeholder: "Dd/mm/aaaa",
    name: "cf_fecha_remision",
    type: "date",
    required: true,
  },
  {
    label: "Fecha recepción Remisión DCI",
    placeholder: "Dd/mm/aaaa",
    name: "cf_fecha_recepcion_remision",
    type: "date",
    required: true,
  },
  {
    label: "No. Formato Almacén R",
    placeholder: "No. Formato Almacén R",
    name: "cf_no_formato_almacen_remision",
    type: "text",
    required: true,
    uppercase: true,
    maxLength: 50,
  },
  {
    label: "Fecha recibido Formato Almacén R",
    placeholder: "Dd/mm/aaaa",
    name: "cf_fecha_recibido_formato_almacen_remision",
    type: "date",
    required: true,
  },
  {
    label: "Formato Almacén R",
    name: "formatos_r",
    type: "file",
    displayName: false,
    required: false,
    uppercase: true,
    accept: "application/pdf",
    uploadFunction: addEntryFilePDF,
    deleteFunction: deleteEntryFilePDF,
  },
];

export const entryData5 = [
  {
    label: "Observaciones",
    placeholder: "Observaciones",
    name: "cf_observaciones",
    type: "text",
    required: true,
    uppercase: true,
    disabled: true,
    width: 980,
  },
];

export const entryData6 = [
  {
    label: "Subtotal",
    placeholder: "Subtotal",
    name: "cf_sub_total",
    type: "text",
    adornment: {
      value: "$",
    },
    isPrice: true,
    disabled: true,
  },
  {
    label: "IVA",
    placeholder: "IVA",
    name: "cf_iva_factura",
    type: "text",
    adornment: "price",
    disabled: true,
  },
  {
    label: "Importe neto",
    placeholder: "Importe neto",
    name: "cf_importe_neto",
    type: "text",
    adornment: {
      value: "$",
    },
    isPrice: true,
    disabled: true,
  },
];

export const inventoryFields = [
  {
    label: "Artículo CABMS",
    placeholder: "Artículo CABMS",
    name: "cabms_item",
    type: "search",
    hasRef: true,
    searchFunction: getItemCABMSAndKey,
    width: 460,
  },
  {
    label: "Clave CABMS",
    placeholder: " · · · · ",
    name: "cabms_key",
    type: "text",
    disabled: true,
    required: true,
    uppercase: true,
  },
  {
    label: "Artículo",
    placeholder: "Artículo",
    name: "cf_articulo",
    type: "text",
    width: 460,
  },

  {
    label: "Descripción",
    placeholder: "Descripción",
    name: "description",
    type: "text",
    required: true,
    uppercase: true,
    maxLength: 300,
    width: 460,
  },
  {
    label: "Marca",
    placeholder: "Marca",
    name: "marca",
    type: "text",
    required: true,
    uppercase: true,
    maxLength: 50,
    width: "auto",
  },
  {
    label: "Modelo",
    placeholder: "Modelo",
    name: "modelo",
    type: "text",
    required: true,
    uppercase: true,
    maxLength: 50,
    width: "auto",
  },
  {
    label: "Serie",
    placeholder: "Serie",
    name: "serie",
    type: "text",
    required: true,
    uppercase: true,
    maxLength: 35,
    width: "auto",
  },
  {
    label: "Partida presupuestal ",
    placeholder: "Partida presupuestal",
    name: "desc_partida",
    type: "search",
    required: true,
    uppercase: true,
    searchFunction: getBatches,
    hideText: true,
    width: 230,
  },
  {
    label: "Descripción partida presupuestal",
    placeholder: "Descripción partida presupuestal",
    name: "descripcion_partida",
    type: "text",
    required: true,
    uppercase: true,
    disabled: true,
  },
  {
    label: "No. De Cuenta",
    placeholder: "No. De Cuenta",
    name: "cf_no_cuenta",
    type: "text",
    required: true,
    uppercase: true,
    disabled: true,
  },
  {
    label: "No. De Subcuenta",
    placeholder: "No. De Subcuenta",
    name: "cf_no_scuenta",
    type: "text",
    required: true,
    uppercase: true,
    disabled: true,
  },
  {
    label: "Precio unitario",
    placeholder: "Precio unitario",
    name: "precio_unitario",
    type: "text",
    required: true,
    isPrice: true,
    helperText: "El valor debe ser mayor a 0",
  },
  {
    label: "Cantidad de descuento",
    placeholder: "Cantidad de descuento",
    name: "cantidad_descuento",
    type: "text",
    required: false,
    isPrice: true,
  },
  {
    label: "Descuento %",
    placeholder: "Descuento %",
    name: "descuento",
    type: "text",
    required: false,
    isPercentage: true,
  },
  {
    label: "Tipo de etiqueta",
    placeholder: "Seleccione tipo de etiqueta",
    name: "cf_label_type",
    type: "dropdown",
    options: labelTypeOptions,
    required: true,
    uppercase: true,
  },
  {
    label: "Estado de etiqueta",
    placeholder: "Seleccione estado de etiqueta",
    type: "dropdown",
    name: "cf_label_status",
    options: labelStatusOptions,
    required: true,
    uppercase: true,
  },
  {
    label: "Tipo de bien",
    placeholder: "Seleccione tipo de bien",
    name: "cf_tipobien",
    type: "dropdown",
    options: [
      { value: "E", text: "ELÉCTRICO/ELECTRÓNICO" },
      { value: "M", text: "MOBILIARIO" },
    ],
    required: true,
    uppercase: true,
    width: 230,
  },
  {
    label: "Tipo de material",
    placeholder: "Seleccione tipo de material",
    name: "inventory_cf_inve_material_type",
    type: "dropdown",
    options: stockMaterialOptions,
    required: true,
    uppercase: true,
  },
  {
    label: "Foto del bien",
    placeholder: "Foto del bien",
    name: "foto_bien",
    type: "photo",
    required: true,
    uppercase: true,
  },
];

export const inventoryColumns = (mode = "create", currentMode) => [
  {
    text: "Número de Inventario",
    value: "id",
    type: "text",
    disabled: true,
    fitText: true,
  },
  {
    text: "Tipo de material",
    value: "inventory_cf_inve_material_type",
    type: "dropdown",
    options: [
      { id: "METAL", name: "METAL" },
      { id: "MADERA", name: "MADERA" },
      { id: "PLÁSTICO", name: "PLÁSTICO" },
    ],
    uppercase: true,
    required: true,
    disabled: !currentMode,
  },
  {
    text: "Tipo de bien",
    value: "inventory_cf_tipobien",
    type: "dropdown",
    disabled: !currentMode,
    options: [
      { id: "E", name: "ELÉCTRICO/ELECTRÓNICO" },
      { id: "M", name: "MOBILIARIO" },
    ],
    uppercase: true,
  },
  {
    text: "Tipo de etiqueta",
    fitText: true,
    value: "inventory_cf_label_type",
    required: true,
    type: "dropdown",
    disabled: !currentMode,
    options: [
      { id: "NM-MD", name: "NO METÁLICA MEDIANA" },
      { id: "MT-MD", name: "METÁLICA MEDIANA" },
      { id: "NM-CH", name: "NO METÁLICA CHICA" },
      { id: "MT-CH", name: "METÁLICA CHICA" },
    ],
  },
  {
    text: "Estado de etiqueta",
    value: "inventory_cf_label_status",
    type: "dropdown",
    disabled: !currentMode,
    options: [
      { id: "PENDING", name: "PENDIENTE" },
      { id: "PRINTED", name: "ETIQUETA IMPRIMIDA" },
      { id: "PLACED", name: "ETIQUETA COLOCADA" },
    ],
  },
  {
    text: "Artículo CABMS",
    value: "cabmsItem",
    type: "select",
    uppercase: true,
    disabled: true,
  },
  {
    text: "Clave CABMS",
    value: "inventory_cabms_",
    type: "key",
    disabled: !currentMode,
  },
  {
    text: "No. de folio",
    placeholder: "Por definir",
    fitText: true,
    value: "inventory_cf_num_inventario_anterior",
    type: "text",
    uppercase: true,
    disabled: true,
  },
  {
    text: "Artículo",
    value: "inventory_cf_articulo",
    type: "text",
    width: 350,
    disabled: true,
  },
  // {
  //   text: "Clave",
  //   value: "inventory_cf_",
  //   type: "key",
  //   width: 150,
  //   disabled: !currentMode,
  // },
  {
    text: "Descripción",
    value: "inventory_cf_descripcion",
    type: "text",
    uppercase: true,
    fitText: true,
    disabled: !currentMode,
    width: 500,
  },
  {
    text: "Marca",
    value: "inventory_cf_marca",
    type: "text",
    uppercase: true,
    fitText: true,
    disabled: !currentMode,
    width: 500,
  },
  {
    text: "Modelo",
    value: "inventory_cf_modelo",
    type: "text",
    uppercase: true,
    fitText: true,
    disabled: !currentMode,
    width: 500,
  },
  {
    text: "Serie",
    value: "inventory_cf_serie",
    type: "text",
    uppercase: true,
    disabled: !currentMode,
    fitText: true,
    width: 500,
  },
  {
    text: "Partida presupuestal ",
    value: "inventory_cf_cve_partida",
    type: "search",
    searchFunction: getBatches,
    disabled: true,
  },
  {
    text: "Cuenta contable",
    value: "inventory_cf_no_cuenta",
    type: "text",
    uppercase: true,
    disabled: true,
    fitText: true,
  },
  {
    text: "Subcuenta contable",
    value: "inventory_cf_no_scuenta",
    type: "text",
    uppercase: true,
    disabled: true,
    fitText: true,
  },
  // {
  //   text: "Cantidad",
  //   value: "inventory_detail_cf_cantidad",
  //   type: "text",
  //   uppercase: true,
  //   disabled: !currentMode,
  // },
  {
    text: "Precio unitario",
    value: "inventory_detail_cf_precio_unitario",
    type: "text",
    isPrice: true,
    disabled: !currentMode,
    fitText: true,
  },
  {
    text: "Cantidad descuento",
    value: "inventory_detail_cf_cantidad_descuento",
    type: "text",
    isPrice: true,
    disabled: !currentMode,
    fitText: true,
  },
  {
    text: "Descuento",
    value: "inventory_detail_cf_descuento",
    type: "text",
    uppercase: true,
    disabled: !currentMode,
    fitText: true,
  },
  // {
  // 	text: 'Fecha ingreso',
  // 	value: 'inventory_cf_fecha_ingreso',
  // 	type: 'date'
  // },
  {
    text: "Foto del bien",
    value: "foto_data",
    type: "file",
    disabled: !currentMode,
  },
  mode === "create" && {
    text: "Acciones",
    //value: mode === 'create' ? 'delete_icon add_icon' : mode === 'edit' && 'delete_icon',
    value: "delete",
    type: "icon",
    disabled: !currentMode,
  },
];

export const getInventoryFromRequestData = (inventory) => {
  return inventory.map((item) => ({
    cabmsItem: item.inventory?.catalog_cabms?.cf_denominacion,
    inventory_cabms_grupo: item.inventory?.catalog_cabms?.cf_grupo,
    inventory_cabms_subgrupo: item.inventory?.catalog_cabms?.cf_subgrupo,
    inventory_cabms_clase: item.inventory?.catalog_cabms?.cf_clase,
    inventory_cabms_subclase: item.inventory?.catalog_cabms?.cf_subclase,
    inventory_cabms_tipo: item.inventory?.catalog_cabms?.cf_tipo,
    inventory_cf_articulo: item.inventory?.cf_articulo,
    item: item.inventory?.catalog_clave?.cf_denominacion,
    inventory_cf_grupo: item.inventory?.catalog_clave?.cf_grupo,
    inventory_cf_subgrupo: item.inventory?.catalog_clave?.cf_subgrupo,
    inventory_cf_clase: item.inventory?.catalog_clave?.cf_clase,
    inventory_cf_subclase: item.inventory?.catalog_clave?.cf_subclase,
    inventory_cf_tipo: item.inventory?.catalog_clave?.cf_tipo,
    inventory_cf_descripcion: item.inventory?.cf_descripcion,
    inventory_cf_modelo: item.inventory?.cf_modelo,
    inventory_cf_marca: item.inventory?.cf_marca,
    inventory_cf_serie: item.inventory?.cf_serie,
    inventory_cf_cve_partida: item.inventory?.cf_cve_partida,
    inventory_detail_cf_cantidad: item.cf_cantidad,
    inventory_detail_cf_precio_unitario: item.cf_precio_unitario,
    inventory_detail_cf_cantidad_descuento: item.cf_cantidad_descuento,
    inventory_detail_cf_descuento: item.cf_descuento,
    inventory_cf_fecha_ingreso: item.inventory?.cf_fecha_ingreso,
    inventory_accounting_account: item.inventory?.accounting_account,
    inventory_accounting_subaccount: item.inventory?.accounting_subaccount,
    inventory_cf_label_type: [
      { id: "NM-MD", name: "NO METÁLICA MEDIANA" },
      { id: "MT-MD", name: "METÁLICA MEDIANA" },
      { id: "NM-CH", name: "NO METÁLICA CHICA" },
      { id: "MT-CH", name: "METÁLICA CHICA" },
    ].find((option) => option.name === item.inventory?.cf_label_type?.toUpperCase())?.id,
    foto_data: item.inventory?.attachment?.url
      ? { name: item.inventory?.attachment?.url }
      : "",
    id: item.inventory?.id,
    asset_acq_detail_id: item.id,
    inventory_cf_tipobien: item.inventory?.cf_tipobien,
    inventory_cf_no_cuenta:
      item.inventory?.cf_no_cuenta == null
        ? item.inventory?.partida?.no_cuenta
        : item.inventory?.cf_no_cuenta,
    inventory_cf_no_scuenta:
      item.inventory?.cf_no_scuenta == null
        ? item.inventory?.partida?.no_scuenta
        : item.inventory?.cf_no_scuenta,
    inventory_cf_inve_material_type: item.inventory?.cf_inve_material_type,
    inventory_cf_label_status: [
      { id: "PENDING", name: "PENDIENTE" },
      { id: "PRINTED", name: "ETIQUETA IMPRIMIDA" },
      { id: "PLACED", name: "ETIQUETA COLOCADA" },
    ].find((option) => option.name === item.inventory?.cf_estatus_etiqueta?.toUpperCase())
      ?.id,
    attachment_pdfs: item.inventory?.attachment_pdfs,
    inventory_cf_num_inventario_anterior: item.inventory?.num_folio,
  }));
};

export const toggleEnableInputs = (inputs, enable = true, path = "") => {
  return enable
    ? inputs
    : inputs?.map((input) => {
        if (input.label === "Responsables" && path === "obras-arte") {
          return { ...input, disabled: true, type: "text" };
        }
        return { ...input, disabled: true };
      });
};

export const formatInventoryTipoBien = (value) => {
  switch (value) {
    case "MOBILIARIO": {
      return "M";
    }
    case "ELÉCTRICO/ELECTRÓNICO": {
      return "E";
    }
    default:
      return value;
  }
};

export const formatInventoryStatusEtiqueta = (value) => {
  switch (value) {
    case "PENDIENTE": {
      return "PENDING";
    }
    case "ETIQUETA IMPRIMIDA": {
      return "PRINTED";
    }
    case "ETIQUETA COLOCADA": {
      return "PLACED";
    }
    default:
      return value;
  }
};

export const formatInventoryTipoEtiqueta = (value) => {
  switch (value) {
    case "NO METÁLICA MEDIANA": {
      return "NM-MD";
    }
    case "METÁLICA MEDIANA": {
      return "MT-MD";
    }
    case "NO METÁLICA CHICA": {
      return "NM-CH";
    }
    case "METÁLICA CHICA": {
      return "MT-CH";
    }
    default:
      return value;
  }
};

export const initialDataAvailableForRequest = {
  cf_no_contrato: "",
  cf_fecha_contrato: "",
  cf_no_acta: "",
  cf_fecha_acta: "",
};

export const dataAvailableForRequest = [
  "cf_no_contrato",
  "cf_fecha_contrato",
  "cf_no_acta",
  "cf_fecha_acta",
];

export const initialDataAvailableForContract = {
  cf_no_pedido: "",
  cf_no_requisicion: "",
  cf_no_acta: "",
  cf_fecha_acta: "",
};

export const dataAvailableForContract = [
  "cf_no_pedido",
  "cf_no_requisicion",
  "cf_no_acta",
  "cf_fecha_acta",
];

export const initialDataAvailableForRevolvingFound = {
  cf_no_contrato: "",
  cf_fecha_contrato: "",
  cf_no_pedido: "",
  cf_no_requisicion: "",
  cf_no_acta: "",
  cf_fecha_acta: "",
};

export const dataAvailableForRevolvingFound = [
  "cf_no_contrato",
  "cf_fecha_contrato",
  "cf_no_pedido",
  "cf_no_requisicion",
  "cf_no_acta",
  "cf_fecha_acta",
];

export const initialDataAvailableForDonationAndActa = {
  cf_no_contrato: "",
  cf_fecha_contrato: "",
  cf_no_pedido: "",
  cf_no_requisicion: "",
};

export const dataAvailableForDonationAndActa = [
  "cf_no_contrato",
  "cf_fecha_contrato",
  "cf_no_pedido",
  "cf_no_requisicion",
];
