import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import CardColorContainer from "../../components/CardColorContainer";
import BatchTable from "../../components/BatchTable";
import UtilInputs from "../../components/UtilInputs";
import PageLayout from "../../components/PageLayout";
import Accordion from "../../components/Accordion";
import notice from "../../components/Notice";
import Chip from "../../components/Chip";
import { formInputs, material_columns } from "../../utils/stockSurveyData";
import { SetInventory } from "../../../redux/ducks/inventory.duck";
import {
  SurveyReassignment,
  SurveySurplus,
  SurveyScan,
  SurveyOut,
} from "./Tabs";
import DigitalizationListDocs from "../DigitalVault/components/common/DigitalizationListDocs";

const StockSurveyForm = () => {
  const [checkedEncontrado, setCheckedEncontrado] = React.useState(false);
  const [checkedLocalizado, setCheckedLocalizado] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedInventory } = useSelector((state) => state.inventory);
  const { currentMode } = useSelector((store) => store.subHeaderActions);
  useEffect(() => {
    if (currentMode === "new") {
      setCheckedEncontrado(false);
      setCheckedLocalizado(false);
    }
  }, [currentMode]);

  const index = selectedInventory.inventory_take_assets
    .map((item, i) => item.status2 && i.toString())
    .filter((item) => item);
  const INVENTORY_ITEMS = selectedInventory.inventory_take_assets.length;
  const FOUNTS_ITEMS =
    selectedInventory.inventory_take_assets.length > 0
      ? selectedInventory.inventory_take_assets
          .map((x) => x.status)
          .reduce((x, y) => x + y)
      : 0;
  const NO_FOUNTS_ITEMS =
    selectedInventory.inventory_take_assets.length > 0
      ? selectedInventory.inventory_take_assets
          .map((x) => x.status2)
          .reduce((x, y) => x + y)
      : 0;
  const OUT_ITEMS =
    selectedInventory.inventory_out.length +
    selectedInventory.artworks_out.length;
  const SURPLUS_ITEMS =
    selectedInventory.inventory_surplus.length +
    selectedInventory.artworks_surplus.length;
  const SCAN_ITEMS =
    selectedInventory.inventory_scan.length +
    selectedInventory.artworks_scan.length;
  const SCAN_USER = selectedInventory.usuario_valido;
  const URL_REDIRECT = selectedInventory.qr_resguardatario;


  const cardItems = [
    {
      title: "Bienes",
      value: INVENTORY_ITEMS,
      icon: "/media/svg/icons/Shopping/Cart1.svg",
      color: "success",
    },
    {
      title: "Bienes encontrados",
      value: FOUNTS_ITEMS,
      icon: "/media/svg/icons/Shopping/Box2.svg",
      color: "primary",
    },
    {
      title: "Bienes no localizados",
      value: NO_FOUNTS_ITEMS,
      icon: "/media/svg/icons/Code/Stop.svg",
      color: "danger",
    },
    {
      title: "Bienes fuera de lugar",
      value: OUT_ITEMS,
      icon: "/media/svg/icons/Design/Substract.svg",
      color: "warning",
    },
    {
      title: "Bienes sobrantes",
      value: SURPLUS_ITEMS,
      icon: "/media/svg/icons/Design/Mask.svg",
      color: "info",
    },
    {
      title: "Bienes escaneados",
      value: SCAN_ITEMS,
      icon: "/media/svg/icons/Shopping/Barcode-scan.svg",
      color: "dark",
    },
    {
      title: "Persona que validó",
      value: SCAN_USER,
      icon: "/media/svg/icons/General/User.svg",
      color: "success",
      name: SCAN_USER,
      onClick: () => {
        if (URL_REDIRECT) {
          window.open(URL_REDIRECT, "_blank");
        } else {
          notice("No se registro credencial en este inventario", "warning");
        }
      },
    },
  ];

  const handleForm = (e) => {
    const { name, value } = e.target;
    if (name === "area") {
      if (!selectedInventory.areas_cve.find((a) => a === value.num_area))
        dispatch(
          SetInventory({
            ...selectedInventory,
            areas_cve: [
              ...selectedInventory.areas_cve,
              value?.num_area?.trim(),
            ],
          })
        );
      else notice("Area ya seleccionada");
    } else if (name === "fecha_levantamiento") {
      history.push(
        `/bienes-instrumentales/levantamiento-inventario/resultados/${value}`
      );
      dispatch(SetInventory({ ...selectedInventory, [name]: value }));
    } else dispatch(SetInventory({ ...selectedInventory, [name]: value }));
  };

  const handleMaterialoForm = (i, field, value, aIndex = "") => {
    let updatedState = selectedInventory.inventory_take_assets;

    if (field === "inventory_area_destination") {
      updatedState[aIndex][field] = value?.text2;
      return dispatch(
        SetInventory({
          ...selectedInventory,
          inventory_take_assets: updatedState,
        })
      );
    } else if (field === "inventory_area_found_cve") {
      updatedState[aIndex][field] = value?.text2;
      return dispatch(
        SetInventory({
          ...selectedInventory,
          inventory_take_assets: updatedState,
        })
      );
    } else if (
      updatedState[aIndex]["status"] &&
      field === "status2" &&
      value === updatedState[aIndex]["status"]
    ) {
      setCheckedEncontrado(false);
      updatedState[aIndex][field] = value;
      updatedState[aIndex]["status"] = !value;
      return dispatch(
        SetInventory({
          ...selectedInventory,
          inventory_take_assets: updatedState,
        })
      );
    } else if (
      updatedState[aIndex]["status2"] &&
      field === "status" &&
      value === updatedState[aIndex]["status2"]
    ) {
      setCheckedLocalizado(false);
      updatedState[aIndex][field] = value;
      updatedState[aIndex]["status2"] = !value;
      return dispatch(
        SetInventory({
          ...selectedInventory,
          inventory_take_assets: updatedState,
        })
      );
    } else updatedState[aIndex][field] = value;

    return dispatch(
      SetInventory({
        ...selectedInventory,
        inventory_take_assets: updatedState,
      })
    );
  };

  const onSearchMaterial = (i, field, value, aIndex) => {
    let state = selectedInventory.inventory_take_assets;
    if (!value?.text) {
      if (field === "inventory_area_destination") {
        state[aIndex][field] = value;
        dispatch(
          SetInventory({ ...selectedInventory, inventory_take_assets: state })
        );
      } else if (field === "inventory_area_found_cve") {
        state[aIndex][field] = value;
        dispatch(
          SetInventory({ ...selectedInventory, inventory_take_assets: state })
        );
      } else return;
    } else return;
  };

  const removeArea = (area) => {
    let newAreas = selectedInventory.areas_cve.filter((a) => a !== area);

    dispatch(SetInventory({ ...selectedInventory, areas_cve: newAreas }));
  };

  const handleCheckEncontrado = (e) => {
    let updatedState = selectedInventory.inventory_take_assets;
    if (e.target.checked) {
      updatedState.map((item) => (item["status"] = e.target.checked));
      updatedState.map((item) => (item["status2"] = false));
      const arrayIds = updatedState.map((ids) => ids.id);
      navigator.clipboard.writeText(arrayIds.join("|"));
      setCheckedEncontrado(e.target.checked);
      setCheckedLocalizado(false);
    } else {
      updatedState.map((item) => (item["status"] = e.target.checked));

      setCheckedEncontrado(e.target.checked);
    }
  };
  const handleCheckLocalizado = (e) => {
    let updatedState = selectedInventory.inventory_take_assets;
    if (e.target.checked) {
      updatedState.map((item) => (item["status2"] = e.target.checked));
      updatedState.map((item) => (item["status"] = false));
      setCheckedLocalizado(e.target.checked);
      const arrayIds = updatedState.map((ids) => ids.id);
      navigator.clipboard.writeText(arrayIds.join("|"));
      setCheckedEncontrado(false);
    } else {
      updatedState.map((item) => (item["status2"] = e.target.checked));
      setCheckedLocalizado(e.target.checked);
    }
  };
  return (
    <PageLayout title={"Levantamiento de Inventario"}>
      <UtilInputs
        width={250}
        formValues={selectedInventory}
        onValuesChange={handleForm}
        inputValues={formInputs(
          Boolean(selectedInventory.id),
          selectedInventory.inventory_take_area_related,
          Boolean(currentMode)
        )}
      />
      <div className="mt-4">
        {selectedInventory.areas_cve.map((area, i) => (
          <Chip
            key={area}
            color="#3699FF"
            fontSize="1.2rem"
            label={area}
            style={{
              marginRight: 10,
              cursor: selectedInventory.id ? "auto" : "pointer",
            }}
            onClick={() => !selectedInventory.id && removeArea(area)}
          />
        ))}
      </div>
      <div className="my-5">
        <CardColorContainer cards={cardItems} />
      </div>
      <BatchTable
        i={0}
        items={selectedInventory.inventory_take_assets}
        columns={material_columns(
          Boolean(selectedInventory.id),
          Boolean(currentMode)
        )}
        handleChange={handleMaterialoForm}
        onSearchTextChange={onSearchMaterial}
        disableAddItem
        disableIndex={index}
        disableColumns={[
          "inventory_area_found_cve",
          "inventory_area_destination",
        ]}
        checkedEncontrado={checkedEncontrado}
        setCheckedEncontrado={setCheckedEncontrado}
        checkedLocalizado={checkedLocalizado}
        setCheckedLocalizado={setCheckedLocalizado}
        handleCheckEncontrado={handleCheckEncontrado}
        handleCheckLocalizado={handleCheckLocalizado}
        currentMode={currentMode}
      />
      {selectedInventory.id && (
        <>
          <Accordion header={<h6>Fuera de lugar</h6>}>
            <SurveyOut />
          </Accordion>
          <Accordion header={<h6>Sobrantes</h6>}>
            <SurveySurplus />
          </Accordion>
          <Accordion header={<h6>Escaneados</h6>}>
            <SurveyScan />
          </Accordion>
        </>
      )}
      <SurveyReassignment />
      {selectedInventory.all_attachments && (
        <DigitalizationListDocs
          data={selectedInventory.all_attachments}
          newDigitalization
          module={"inventory_take"}
          attachTo={selectedInventory.id}
        />
      )}
    </PageLayout>
  );
};

export default StockSurveyForm;
