import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const actionType = {
    SetPages: '[PATHNAME] SET_PAGEs',
};

// To see possible keys visit src/app/utils/routesPaths.js
const initialState = {};

export const reducer = persistReducer(
  { storage, key: 'pathname', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetPages:
        return { ...state, ...action.payload };
        
      default:
        return state
    }
  }
)

export const setPages = pages => ({ type: actionType.SetPages, payload: pages });