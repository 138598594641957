import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import notice from '../../../components/Notice'
import BaseModal from '../../../components/BaseModal'
import UtilInputs from '../../../components/UtilInputs'
import { defaultFinishedTask, finishedTaskForm } from '../utils'
import { finishTask, getTask } from '../../../crud/taskManagementCrud'
import { setLoader } from '../../../../redux/ducks/loader.duck'
import FileButton from '../../../components/FileButton'
import AttachmentsList from '../../../components/AttachmentsList'
import { updateTask } from '../../../crud/taskManagementCrud';

const ToDoFinishedTaskModal = ({ open, setOpen, detailId, detail, fetchTasksByBoard }) => {
  const dispatch = useDispatch()

  const { updateFromModal } = useSelector(store => store.taskManagement);

  const [task, setTask] = useState(defaultFinishedTask)

  const fetchTask = useCallback( async(id) => {
    if ( open && id !== 0 ) {
      dispatch(setLoader(true))
      getTask(id)
      .then( response => 
        setTask({
          ...task, 
          folio_name: response.folio?.name, 
          folio: response.folio?.id, 
          folio_number: response.folio_number, task: id})
      )
      .catch( e => notice("Error cargando la tarea") )
      .finally(() => dispatch(setLoader(false)))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect( () => {
    fetchTask(detailId)
  }, [detailId, fetchTask] )

  const handleChange = ({ target }) => setTask({...task, [target.name]: target.value})
  const handleAttachments = (files) => setTask({...task, 'attachments': files})

  const handleFinish = () => {
    dispatch(setLoader(true))
    finishTask(task)
    .then(res => {
      setOpen(false)
      setTask(defaultFinishedTask)
      
      if( Object.keys(updateFromModal).length > 0 ) {
      updateTask(updateFromModal.id, updateFromModal)
      .then(res => {
        notice(` ${res.name} actualizada con éxito `, "success")
      })
      .catch(e => { console.log(e); notice("Ha ocurrido un error al actualizar la tarea") })
      }

      fetchTasksByBoard()
    })
    .catch(e => {
      notice(Object.keys(e).map(eKey => `${eKey}: ${e[eKey]}`)?.join("\n") || "Ha ocurrido un error al actualizar la tarjeta")
    })
    .finally(() => dispatch(setLoader(false)))
  }

  const handleRemoveAttachment = (index) => {
    const updatedAtt = task.attachments
    updatedAtt.splice(index, 1)
    setTask({...task, 'attachments': [...updatedAtt]})
  }

  const handleCancel = async () => {
    dispatch(setLoader(true))
    await fetchTasksByBoard()
    setOpen(false);
    dispatch(setLoader(false))
    setTask(defaultFinishedTask)
    setTask({...task, 'attachments': []})
  }

  return (
    <BaseModal
      open={open}
      title={`Finalización ${detail?.title ?? ""}`}
      confirmLabel="Enviar"
      onClose={handleCancel}
      onConfirm={handleFinish}
      maxWidth="md"
    >
      <UtilInputs
        formValues={task}
        onValuesChange={handleChange}
        inputValues={finishedTaskForm}
      />
      <FileButton multiple accept='*' buttonTitle='Cargar archivos' handleChange={handleAttachments} className='my-2'/>
      <div className="d-flex mt-3">
        <AttachmentsList attachments={task.attachments} onClick={(e, i) => handleRemoveAttachment(i)}/>
      </div>
    </BaseModal>
  )
}

export default ToDoFinishedTaskModal
