export const defaultRequest = {
  sd_status: false,
  sd_fecha_fin: "",
  sd_fecha_inicio: "",
  sd_alerta_text: ""
}

export const requestForm = [
  {
    label: "Estado",
    name: "sd_status",
    type: "switch",
    color: "secondary"
  },
  {
    label: "Fecha de inicio",
    name: "sd_fecha_inicio",
    type: "date",
  },
  {
    label: "Fecha de fin",
    name: "sd_fecha_fin",
    type: "date",
  },
  {
    label: "Texto de alerta",
    name: "sd_alerta_text",
    type: "text",
    multiline: true,
    rows: 6,
    width: "100%"
  },
]