import React, { useEffect, useState } from "react";
import { Avatar, Grid } from "@material-ui/core";
import {
  Assignment,
  ExitToApp,
  AssignmentTurnedIn,
  Security,
  AvTimer,
  Loop,
} from "@material-ui/icons";
import notice from "../../components/Notice";
import SimpleCard from "../../components/SimpleCard";
import UtilInputs from "../../components/UtilInputs";
import { fetchDashboardDetails } from "../../crud/dashboardCrud";
import { defaultDetails, defaultFilter, filterForm } from "./utils";
import { downloadReport } from "../../crud/entryCrud";
import { setLoader } from "../../../redux/ducks/loader.duck";
import { useDispatch } from "react-redux";
import { stockNew, stockReports } from "../../crud/stockCrud";

const Counter = ({ limit }) => {
  let [count, setCount] = useState(0);

  let speed = limit > 10000 ? 0.0001 : limit > 1000 ? 1 : limit > 100 ? 5 : 50;

  useEffect(() => {
    if (limit === 0) setCount(0);
    if (count < limit)
      setTimeout(() => {
        setCount(count + 1);
      }, speed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, limit]);

  return count;
};

const Dashboard = () => {
  const dispatch = useDispatch();

  const [details, setDetails] = useState(defaultDetails);
  const [filter, setfilter] = useState(defaultFilter);
  const [ids, setIds] = useState();

  const fetchDetails = () => {
    setDetails(defaultDetails);
    fetchDashboardDetails(filter)
      .then((res) => {
        setIds(res);
        res["Bienes dados de alta"] = res?.bienes_dados_alta;
        res["Entradas"] = res?.entradas;
        res["Bienes cambiados de Área"] = res?.inventario_cambio_area;
        res["Bienes con inventario y resguardo"] =
          res?.inventario_con_levantamiento_resguardo;
        res["Total de bienes sin inventario"] = res?.inventario_pediente_levantamiento;
        res["Áreas con levantamiento de inventario"] = res?.levantamientos;
        res["Total bienes con inventario"] = res?.total_bienes_con_inventario;
        res["Total bienes Registrados"] = res?.total_bienes_registrados;
        delete res.bienes_dados_alta;
        delete res.entradas;
        delete res.inventario_cambio_area;
        delete res.inventario_con_levantamiento;
        delete res.inventario_con_levantamiento_resguardo;
        delete res.inventario_pediente_levantamiento;
        delete res.levantamientos;
        delete res.total_bienes_con_inventario;
        delete res.total_bienes_registrados;
        setDetails(res);
      })
      .catch((e) => notice(Array.isArray(e) ? e : "Ha ocurrido un error con el servidor"))
      .finally((e) => console.log(e));
  };

  useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = ({ target }) =>
    setfilter({ ...filter, [target.name]: target.value });

  const detailsIcons = (key) => {
    switch (key) {
      case "entradas":
        return <Assignment />;
      case "inventario_cambio_area":
        return <ExitToApp />;
      case "inventario_con_levantamiento":
        return <AssignmentTurnedIn />;
      case "inventario_con_levantamiento_resguardo":
        return <Security />;
      case "inventario_pediente_levantamiento":
        return <AvTimer />;
      case "levantamientos":
        return <Loop />;
      default:
        break;
    }
  };

  const handleDownloadEntradas = (name) => {
    if (
      name === "Total bienes Registrados" ||
      name === "Total de bienes sin inventario"
    ) {
      return;
    }

    if (name === "Entradas") {
      dispatch(setLoader(true));
      downloadReport(ids.entradas_ids)
        .then((res) => window.open(res.attachment_url, "_blank"))
        .finally(() => dispatch(setLoader(false)));
    }
  };
  const handleDownload = (name) => {
    if (
      name === "Total bienes Registrados" ||
      name === "Total de bienes sin inventario"
    ) {
      return;
    }

    let idList = [];
    const type = "area";
    let type2 = "";
    if (name === "Bienes dados de alta") {
      idList = ids.bienes_dados_alta_ids;
    } else if (name === "Bienes cambiados de Área") {
      idList = ids.inventario_cambio_area_ids;
      type2 = "dashboard";
    } else if (name === "Bienes con inventario y resguardo") {
      idList = ids.inventario_con_levantamiento_resguardo_ids;
    } else if (name === "Áreas con levantamiento de inventario") {
      idList = ids.levantamientos_ids;
    } else if (name === "Total bienes con inventario") {
      idList = ids.total_bienes_con_inventario_ids;
    }

    if (idList.length > 0) {
      dispatch(setLoader(true));
      stockNew(idList, type, type2)
        .then((res) => {
          if (res.attachment_url) window.open(res.attachment_url);
        })
        .catch((res) => {
          notice("No se pudo cargar el PDF, contacta al administrador");
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    } else {
      notice("No hay documentos para descargar", "warning");
    }
  };
  return (
    <>
      <UtilInputs
        className="mb-4 align-items-end"
        formValues={filter}
        onValuesChange={handleChange}
        inputValues={filterForm(fetchDetails)}
        width={120}
      />

      <Grid container spacing={2}>
        {Object.keys(details).map((d) => {
          return d.includes("ids") ? (
            <></>
          ) : (
            <Grid item xs={12} sm={6} md={4} key={d}>
              <SimpleCard
                className="h-100"
                contentClasses="h-100 d-flex align-items-center"
              >
                <>
                  <Avatar
                    style={
                      d === "Total bienes Registrados" ||
                      d === "Total de bienes sin inventario"
                        ? { background: "rgba(0, 0, 0, 0.54)" }
                        : { background: "rgb(5 131 255)", cursor: "pointer" }
                    }
                    onClick={
                      d === "Entradas"
                        ? () => handleDownloadEntradas(d)
                        : () => handleDownload(d)
                    }
                  >
                    {detailsIcons(d)}
                  </Avatar>

                  <div className="ml-4">
                    <h2>
                      <Counter limit={details[d]} />
                    </h2>
                    <span className="text-capitalize">
                      {d === "Bienes con inventario y resguardo"
                        ? "Bienes con inventario y resguardo del ejercicio"
                        : d === "Total de bienes sin inventario"
                        ? "Total de bienes sin inventario del ejercicio"
                        : d === "Total bienes con inventario"
                        ? "Total bienes con inventario del ejercicio"
                        : d}
                    </span>
                  </div>
                </>
              </SimpleCard>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Dashboard;
