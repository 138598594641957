export const defaultDoc = {
  register: "",
  module: "",
  number: "",
  select: "",
  folder: "",
  sub_folder: "",
  name: "",
  attachments: [],
};

export const docForm = (
  register,
  module_options = [],
  module_loader,
  number,
  selected,
  folderOption = [],
  subfolderOptions = []
) => [
  {
    name: "register",
    label: "Registro",
    type: "dropdown",
    options: [
      { value: "module", text: "A un módulo del sistema" },
      { value: "digital_vault", text: "Carpeta en bóveda digital" },
    ],
  },
  register === "module" && {
    name: "module",
    label: "Módulo",
    type: "dropdown",
    options: [
      { value: "asset_acquisition", text: "Entradas de Inventario" },
      { value: "inventory_take", text: "Levantamiento de Inventario" },
      { value: "inventory", text: "Inventario" },
      { value: "artwork", text: "Obras de arte" },
      { value: "vehicle", text: "Vehículos" },
      { value: "person", text: "Responsables" },
      { value: "area", text: "Áreas" },
    ],
  },
  register === "module" && {
    name: "number",
    label: "Número",
    type: "text",
    disabled: selected,
  },
  register === "module" && {
    name: "select",
    label: "Selección",
    type: "dropdown",
    disabled: module_loader || number,
    options: [{ value: "", text: "Seleccione una opción" }, ...module_options],
  },
  register === "digital_vault" && {
    name: "folder",
    label: "Carpeta",
    type: "dropdown",
    options: folderOption,
  },
  register === "digital_vault" && {
    name: "sub_folder",
    label: "Subcarpeta",
    type: "dropdown",
    options: subfolderOptions,
    disabled: subfolderOptions.length === 0,
  },
];
