import React from 'react'
import EyeColumnIcon from './list-icons/EyeColumnIcon'

export const ConfigActionColumn = (cellContent, row, rowIndex, { onEye }) => {
  return (
    <div className="d-flex" style={{ paddingRight: 10, justifyContent: 'center' }}>
		  <EyeColumnIcon onClick={() => onEye(row.id)}/>
	  </div>
  )
}
