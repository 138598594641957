import React from 'react'
import BaseModal from './BaseModal'
import DropInputsForm from './DropInputsForm'
import notice from './Notice'

const DropModal = ({ open, onConfirm, onCancel, title, formValues, onValuesChange }) => {

  const onConfirmDrop = () => {
    if (!formValues.motivo_baja) notice("Por favor anote el motivo")
    if (!formValues.tipo_baja) notice("Por favor seleccione el tipo de baja")
    else onConfirm()
  }

  return (
    <BaseModal
      open={open}
      title={title}
      onClose={onCancel}
      onCancel={onCancel}
      onConfirm={onConfirmDrop}
      confirmLabel='Si, dar de baja'
      maxWidth='sm'
    >
      <DropInputsForm formValues={formValues} onValuesChange={onValuesChange} width={260} />
    </BaseModal>
  )
}

export default DropModal
