import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Poppins"].join(","),
      h5: {
        fontWeight: 600,
      },
      subtitle1: {
        fontWeight: 500,
        fontSize: "1.25rem",
      },
      subtitle2: {
        fontWeight: 600,
        fontSize: "1.1rem",
      },
    },

    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#040323",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#0093FF",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      red: {
        main: "#DC0714",
      },
      gray: {
        main: "#9A9A9A",
      },
      text: {
        main: "#000000",
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#f018a6",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      warning: {
        // light: will be calculated from palette.primary.main,
        main: "#FFA800",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      green: {
        main: "#8CC71D",
      },
      pink: {
        main: "#C71DB3",
      },
      blue: {
        main: "#1DADC7",
      },
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: false, // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1,
      },
    },
    overrides: {
      MuiButton: {
        root: {
          textTransform: "capitalize",
        },
      },
      MuiInputBase: {
        root: {
          fontWeight: 600,
          "&.Mui-focused": {
            color: "#0093FF",
          },
          "&:hover": {
            color: "#0093FF",
          },
          "&.Mui-disabled": {
            color: "black",
          },
        },
      },
      MuiFormControl: {
        root: {
          "&>.MuiInputBase-root": {
            backgroundColor: "white",
            "&:disabled": {
              color: "black",
            },
            "&.Mui-disabled": {
              backgroundColor: "#dedede",
              color: "black",
            },
            "&:hover fieldset": {
              borderWidth: 2,
              borderColor: "#0093FF",
            },
            "&.Mui-disabled fieldset": {
              color: "black",
              borderWidth: 2,
              borderColor: "#cfd0cf",
            },
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          // for Chip Select
          "&:hover fieldset": {
            borderWidth: 2,
            borderColor: "#0093FF !important",
          },
          "&.Mui-disabled fieldset": {
            color: "black",
            borderWidth: 2,
            borderColor: "#cfd0cf !important",
          },
        },
      },
      MuiAutocomplete: {
        clearIndicator: {
          color: "#000",
        },
        input: {
          textTransform: "uppercase",
        },
      },
      MuiInputAdornment: {
        root: {
          "& > p": {
            fontWeight: 500,
            "&:hover": {
              color: "#0093FF",
            },
          },
        },
      },
    },
  }
);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
