import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setClearState, setSelectedVariants, setVariant, setVariants } from '../../../../redux/ducks/variants.duck'
import { getSingleVariant, getVariantsList } from '../../../crud/variantsCrud'
import { OperatingConditionTableColumns } from '../../../utils/configData'
import FullTable from '../../../components/FullTable'
import notice from '../../../components/Notice'

export const ConfigOperatingCondition = () => {
    const { count } = useSelector(state => state.variants.variants)
    const { permisos } = useSelector((store) => store.auth);

    const dispatch = useDispatch();
    const history = useHistory()
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [selectedIds, setSelectedIds] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true)
        getVariantsList(page, sizePerPage)
            .then(res => dispatch(setVariants(res)))
            .catch(e => notice("Error cargando la lista de condicion de operación"))
            .finally(() => setLoading(false))

    }, [dispatch, page, sizePerPage])

    const handleNew = () => {
        dispatch(setClearState())
        history.push('/bienes-instrumentales/configuracion/catalogos/condicion-de-operacion/nuevo')
    }

    const onEye = (id) => {
        dispatch(setClearState())
        getSingleVariant(id)
            .then(res => {
                dispatch(setVariant(res))
                dispatch(setSelectedVariants([res.id]))
                history.push(`/bienes-instrumentales/configuracion/catalogos/condicion-de-operacion/resultados/${id}`)
            }).catch(e => notice("Error al mostrar condición de operación"))
    }

    const handleSelectAllRows = (isSelect, rows) => isSelect ? setSelectedIds(rows.map(r => r.id)) : setSelectedIds([])

    const handleSelectRow = (row, isSelect) => {
        if (row && row.id) {
            if (!isSelect) {
                const newSelectedEntries = selectedIds.filter(id => id !== row.id);
                setSelectedIds(newSelectedEntries);
            } else {
                setSelectedIds([...selectedIds, row.id]);
            };
        };
    };

    const handleReqSelected = () => {
        dispatch(setClearState())
        dispatch(setSelectedVariants(selectedIds))
        getSingleVariant(selectedIds[0])
            .then(res => {
                dispatch(setVariant(res))
                history.push(`/bienes-instrumentales/configuracion/catalogos/condicion-de-operacion/resultados/${res.id}`)
            }).catch(e => notice("Error al mostrar condición de operación"))
    }

    

    return (
        <FullTable
            title='Condición de Operación'
            // NEW VEHICULE TRIGGER
            buttonTitle='Nueva Condición de Operación'
            onClickButton={handleNew}
            buttonDisabled={!permisos.permisos.includes("i-crear-condicion-operacion")}   
                     // TABLE ENTITIES
            // entities={results}
            columns={OperatingConditionTableColumns(onEye)}
            page={page}
            setPage={page => page > 0 && setPage(page)}
            sizePerPage={sizePerPage}
            setSizePerPage={setSizePerPage}
            count={count}
            loading={loading}
            // FILTERS ACTIONS
            search_button={false}
            // SELECTED ACTIONS
            has_button2
            buttonDisabled2={selectedIds.length < 1}
            buttonTitle2={`Consultar Todos${selectedIds.length > 1 ? `(${selectedIds.length.toString()})` : ''}`}
            onSelectRow={handleSelectRow}
            onSelectAllRows={handleSelectAllRows}
            onClickButton2={handleReqSelected}
        />
    )
}
