import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const actionType = {
  SetUser: '[USERS] SET_USER',
  SetUsers: '[USERS] SET_USERS',
  SetUsersIds: '[USERS] SET_USER_IDS',
  SetClearUser: '[USERS] SET_CLEAR_USER',
  SetSearchUser: '[USERS] SET_SEARCH_USER',
  SetOperativeUsers: '[USERS][OPERATIVE] SET_OPERATIVE_USERS'
};

export const initialSelectedUser = {
  username: "",
  first_name: "",
  last_name: "",
  // image: null,
  is_active: true,
  alm_area: null,
  titular: "",
  password: "",
  password_confirm: "",
  old_password:"",
  new_password2:"",
  new_password1:"",
  groups: [],
};

const initialState = {
  list: {
    results: [],
    count: 0,
  },
  searchedUser: initialSelectedUser,
  selectedUser: initialSelectedUser,
  selectedIds: [],
  operativeUsers: []
};

export const reducer = persistReducer(
  { storage, key: 'user', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetUsers:
        return { ...state, list: action.payload }
      
      case actionType.SetUser:
        return { ...state, selectedUser: action.payload };
      
      case actionType.SetUsersIds:
        return { ...state, selectedIds: action.payload };

      case actionType.SetSearchUser:
        return { ...state, searchedUser: action.payload };
      
      case actionType.SetClearUser:
        return initialState;

      case actionType.SetOperativeUsers:
        return { ...state, operativeUsers: action.payload }

      default:
        return state
    }
  }
)

export const setUsers = data => ({ type: actionType.SetUsers, payload: data })
export const setSelectedIds = data => ({ type: actionType.SetUsersIds, payload: data })
export const setUser = data => ({ type: actionType.SetUser, payload: data })
export const setSearchUser = data => ({ type: actionType.SetSearchUser , payload: data });
export const setClearUser = () => ({ type: actionType.SetClearUser })
export const setOperativeUser = ( data ) => ({ type: actionType.SetOperativeUsers, payload: data })
