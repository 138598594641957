import { FormControlLabel, Switch } from '@material-ui/core'
import React from 'react'

const SimpleSwitch = ({ label="", labelPos="top", name, value, onChange=()=>{}, disabled, color="primary", className="" }) => {
  return (
    <FormControlLabel
      className={className}
      disabled={disabled}
      label={label}
      labelPlacement={labelPos}
      control={
        <Switch
          defaultChecked={false}
          disabled={disabled}
          onChange={({ target }) => onChange({target: { name, value: target.checked }})}
          color={color}
          checked={value}
          name={name}
        />
      }
    />
  )
}

export default SimpleSwitch