import { getTokenByRefresh } from '../app/modules/Auth/_redux/authCrud';
import { actions } from '../app/modules/Auth/_redux/authRedux';
import axios from 'axios'
import { setReload } from './ducks/entry.duck';

export default function setupAxios(store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    }
  );
  axios.interceptors.response.use(
    response => {
      return response;
    },
    function(error) {
      const {
        auth: { refresh }
      } = store.getState();
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest._retry && refresh) {
        originalRequest._retry = true;
        getTokenByRefresh(refresh)
          .then(res => {
            if (res && res.status === 200) {
              // 1) put token to LocalStorage
  
              // 2) Change Authorization header
              axios.defaults.headers.common.Authorization = `Bearer ${res.data.access}`;
              store.dispatch(actions.login(res.data.access, res.data.refresh));
              // 3) return originalRequest object with Axios.
              //return axios(originalRequest);
              store.dispatch(setReload());
            }else {
              store.dispatch(actions.logout());
              return;
            }
          }).catch(error => {
            store.dispatch(actions.logout());
            return;
          });
      }else if(error.response?.status === 401 && !originalRequest._retry && !refresh){
        store.dispatch(actions.logout());
      }else if(error.response?.status === 400 && error.response?.status < 500){
        console.log(error);
        console.log(error.response);
        throw error
      };
      return error;
    }
  );
};

export { axios };