import React from 'react';
import {
    lighten,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import Icon from './Icon';
import { isAValidInteger } from '../utils/inputValidations';
import './styles/_materialItem.scss';
import SimpleCheckbox from './SimpleCheckbox';
import SimpleRadioButton from './SimpleRadioButton';


const useStyles = makeStyles(theme => ({
    card: {
        height: 45,
        maxHeight: 45,
        padding: ({ type }) => (type === 'icon' ? 1 : '5px 15px'),
        textAlign: ({ type }) => type === 'icon' && 'center',
        boxShadow: 'none',
        border: 'solid 1px transparent',
        borderTop: `solid 1px ${lighten(theme.palette?.gray?.main || '#fff', 0.8)}`,
        borderRight: `solid 1px ${lighten(theme.palette?.gray?.main || '#fff', 0.7)}`,
        borderRadius: 0,
        transition: 'border 0.1s ease',
        backgroundColor: ({ type }) =>
            type !== 'icon' && lighten(theme.palette?.gray?.main || '#fff', 0.9),
        '&>.MuiCardContent-root:last-child': {
            padding: 0,
        },
        '&:hover': {
            //border: ({ type }) => type !== 'icon' && `solid 2px ${theme.palette.gray.main}`
        },
        width: ({ width, column }) => column === 'description' ? 300 : width ? width : 100,
        minWidth: ({ width, column }) => column === 'description' ? 300 : width ? width : 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: ({ column }) => column === 'actions' && 'center'
    },
    cell: {
        padding: ({ type }) => (type === 'icon' ? 1 : '5px 15px'),
        borderRight: 'solid 1px #d9d9d9',
        borderBottom: 'solid 1px #d9d9d9',
        alignItems: 'center',
        justifyContent: ({ type }) => type === 'icon' && 'center',
        display: 'flex',
        backgroundColor: ({ type }) =>
            type !== 'icon' && lighten(theme.palette?.gray?.main || '#fff', 0.9),
        '&>.MuiCardContent-root:last-child': {
            padding: 0,
        },
        transition: 'border 0.1s ease',
    }
}));

const MaterialItem = ({
    value,
    type,
    item,
    onAddItem,
    onEditItem,
    onDeleteItem,
    onQuantityChange,
    onCheckItem,
    column,
    width,
    index,
    formValues,
    onValuesChange,
    disabled
}) => {
    const classes = useStyles({ type, column, width: width });
    const handleChange = e => {
        if (e && e.target) {
            const { value } = e.target;
            if (isAValidInteger(value))
                onQuantityChange(e, index)
        };
    };

    return (
        <div role="cell" className={classes.cell}>
            {
                type === 'text' ? (
                    <Typography>{value}</Typography>
                ) : type === 'icon' ? (
                    <>
                        {value.includes('add') && (
                            <Icon
                                src={toAbsoluteUrl('/media/svg/icons/Navigation/Plus.svg')}
                                color='primary'
                                label='Agregar'
                                onClick={() => onAddItem(item.id, item)}
                            />
                        )}
                        {value.includes('edit') && (
                            <Icon
                                src={toAbsoluteUrl('/media/svg/icons/Design/Edit.svg')}
                                color='success'
                                label='Editar'
                                onClick={() => onEditItem(item.id)}
                            />
                        )}
                        {value.includes('check') && (
                            <Icon
                                src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')}
                                color='primary'
                                label='Editar'
                                onClick={() => onCheckItem(item.id)}
                            />
                        )}
                        {value.includes('delete') && (
                            <Icon
                                src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')}
                                color='danger'
                                label='Eliminar'
                                onClick={() => onDeleteItem(item.id)}
                            />
                        )}
                    </>
                ) : type === 'input' ? (
                    <>
                        <input
                            style={{ height: 30, width: 130, fontSize: 11 }}
                            placeholder='Ingrese cantidad'
                            onChange={handleChange}
                            pattern="\d*"
                            value={value}
                            name={column}
                            disabled={item.disabled || disabled}
                            className={item.danger ? 'form-control m-auto input-danger' : 'form-control m-auto'}
                        />
                    </>
                ) : type === 'checkbox' ? (
                    <>
                        <div key={item.name} style={{ marginLeft: 25 }}>
                                <SimpleCheckbox
                                    name={column}
                                    disabled={disabled}
                                    value={value}
                                    onChange={e => onValuesChange(e, index)}
                                    checked={item.checked}
                                />
                        </div>
                    </>
                ) : type === 'radioButton' ? (
                    <>
                        <div key={item.name} style={{ marginLeft: 25 }}>
                                <SimpleRadioButton
                                    name={column}
                                    disabled={disabled}
                                    value={value}
                                    onChange={e => onValuesChange(e, index)}
                                    options={item.option}
                                />
                        </div>
                    </>
                ) : (
                    <></>
                )}
        </div>
    );
};

export default MaterialItem;
