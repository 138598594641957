import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { trello_data } from "../../app/pages/Todo/utils";

const actionType = {
  SetBoards: '[TASK_MANAGEMENT][BOARDS] SET_BOARDS',
  SetBoard: '[TASK_MANAGEMENT][BOARDS] SET_BOARD',
  SetTasksByBoard: '[TASK_MANAGEMENT][TASKS][BY_BOARD] SET_TASkS_BY_BOARD',
  SetTasksForTello: '[TASK_MANAGEMENT][TASKS][BY_BOARD] SET_TASkS_FOR_TRELLO',
  SetAllTasks: '[TASK_MANAGEMENT][TASKS][BY_BOARD] SET_All_TASKS',
  SetSearchTasks: '[TASK_MANAGEMENT][TASKS][BY_BOARD] SET_SEARCH_TASKS',
  UpdateFromModal: '[TASK_MANAGEMENT][TASK] UPDTATE_BY_MODAL'
};

export const initialState = {
  boards: {
    results: [],
    count: 0,
    page: 1,
    per_page: 50,
  },
  tasks: {
    resultsForTrello: trello_data([], ()=>{}),
    resultsByBoard: [],
    allResults: [],
    searchTasks: "",
  },
  updateFromModal: {},
  selectedBoard: null
};

export const reducer = persistReducer(
  { storage, key: 'task-management', whitelist: ['boards', 'selectedBoard'] },
  (state = initialState, action) => {
    switch (action.type) {

      case actionType.SetBoards:
        const { results, count, per_page } = action.payload;
        const leaders = results.map( result => { return { leader: `${result.users[0].username}` } } )
        const icono = { icon: "/media/svg/icons/Files/File.svg" };
        const color = { color: "primary"  };
        return { ...state, boards: { 
          results: results.map(( result, index ) => Object.assign({}, result, leaders[index], icono, color)), 
          count, 
          per_page 
        } };
      
      case actionType.SetBoard:
        return { ...state, selectedBoard: action.payload }

      case actionType.SetTasksByBoard:
        return { ...state, tasks: {...state.tasks, resultsByBoard: action.payload } }
      
      case actionType.SetTasksForTello:
        return { ...state, tasks: {...state.tasks, resultsForTrello: action.payload } }
      
      case actionType.SetAllTasks:
        return { ...state, tasks: {...state.tasks, allResults: action.payload } }
      
      case actionType.SetSearchTasks:
        return { ...state, tasks: {...state.tasks, searchTasks: action.payload } }
      
      case actionType.UpdateFromModal:
        return { ...state, updateFromModal: action.payload }

      default:
        return state;
    };
  }
);

export const setBoards = (boards) => ({ type: actionType.SetBoards, payload: boards });
export const setSelectedBoard = (data) => ({ type: actionType.SetBoard, payload: data });
export const setTasksByBoard = (tasks) => ({ type: actionType.SetTasksByBoard, payload: tasks });
export const setTasksForTrello = (tasks) => ({ type: actionType.SetTasksForTello, payload: tasks });
export const setAllTasks = (tasks) => ({ type: actionType.SetAllTasks, payload: tasks });
export const setSearchTasks = (tasks) => ({ type: actionType.SetSearchTasks, payload: tasks });
export const setUpdateFromModal = (task) => ({ type: actionType.UpdateFromModal, payload: task });
