import React from 'react'
import CloseColumnIcon from './list-icons/CloseColumnIcon';
import EyeColumnIcon from './list-icons/EyeColumnIcon';

export const VehiculesActionsColumnFormatter = (cellContent, row, rowIndex, { onEye, onDrop ,Permisos}) => {
  return (<>
	  {Permisos.includes("i-ver-vehiculo") && <EyeColumnIcon onClick={() => onEye(row.id)}/>}
      {Permisos.includes("i-crear-baja-vehiculo") && <CloseColumnIcon onClick={() => onDrop(row.id)} />}
    </>
  )
}
