import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultEntrance, defaultFilter, defaultRefund } from "../../app/utils/entranceData";

export const actionType = {
  SetEntrances: '[ENTRANCES] SET_ENTRANCES',
  SetEntrance: '[ENTRANCES] SET_ENTRANCE',
  SetSearchEntrance: '[ENTRANCES] SET_FILTER',
  SetRefund: '[ENTRANCES] SET_REFUND',
  SetSelectedIds: '[ENTRANCES] SET_SELECTED_ENTRANCES',
  ClearState: '[ENTRANCES] CLEAR_STATE',
};

export const initialState = {
    list: {
      results: [],
      count: 0
    },
    entrance: defaultEntrance,
    selectedIds: [],
    searchEnatrance: defaultFilter,
    refund: defaultRefund,
};

export const reducer = persistReducer(
  { storage, key: 'entrance', whitelist: ['entrance', 'selectedIds'] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetEntrances: 
        return { ...state, list: action.payload };
      
      case actionType.SetEntrance: 
        return { ...state, entrance: action.payload };

      case actionType.SetRefund: 
        return { ...state, refund: action.payload };

      case actionType.SetSearchEntrance: 
        return { ...state, searchEnatrance: action.payload };

      case actionType.SetSelectedIds: 
        return { ...state, selectedIds: action.payload };

      case actionType.ClearState:
        return state = initialState;

      default:
        return state
    };
  }
);

export const setEntrances = data => ({ type: actionType.SetEntrances, payload: data });

export const setEntrance = data => ({ type: actionType.SetEntrance, payload: data });

export const setSearchEntrance = data => ({ type: actionType.SetSearchEntrance, payload: data });

export const setRefund = data => ({ type: actionType.SetRefund, payload: data });

export const setSelectedIds = data => ({ type: actionType.SetSelectedIds, payload: data });

export const setClearState = data => ({ type: actionType.ClearState });