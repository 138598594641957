import { useSelector } from "react-redux";
import { searchRecursive } from "./recursiveSearch";

// TODO: rename to useRoutePermissions and move to src/app/hooks
export const useRoutesPermissions = () => {
    const { app_modules } = useSelector(store => store.auth.user);

    const allNames = searchRecursive(app_modules, 'name', ['submodules']);

    let names = {};

    allNames.forEach(name => {
        names[name] = true;
    });


    const entriesPermission = names['lista-de-entradas'],
          newEntryPermission = names['nueva-entrada'],
          stockPermission = names['ver_inventario'],
          stockDetailsPermission = names['ver_inventario'],
          requestPublicPermission = names['solicitudes-lista'],
          requestAdminPermission = names['lista-de-solicitudes'],
          departuresPermission = names['salidas'],
          newDeparturePermission = names['salidas'],
          entrancesPermission = names['entradas'],
          newEntrancePermission = names['entradas'],
          pledgedGoodsPermission = names['bienes-comprometidos'],
          contractsPermission = names['contratos'],
          detailContractsPermission = names['contratos'],
          detailsRequirementPermission = names['contratos'],
          requirementsPermission = names['requerimientos'],
          newRequestPermission = names['nueva-solicitud'];

    return {
        entriesPermission,
        newEntryPermission,
        stockPermission,
        stockDetailsPermission,
        requestPublicPermission,
        requestAdminPermission,
        departuresPermission,
        newDeparturePermission,
        entrancesPermission,
        newEntrancePermission,
        pledgedGoodsPermission,
        contractsPermission,
        detailContractsPermission,
        detailsRequirementPermission,
        requirementsPermission,
        newRequestPermission,
    };
};
