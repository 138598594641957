import React, { useRef, useEffect, useReducer, useCallback } from "react";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from "@material-ui/icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import uuid from "react-uuid";
import _ from "lodash";

const useClasses = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
  },
  slideContainer: {
    maxWidth: 640,
    margin: "0 15px 0 15px",
  },
});

const reducer = (state, action) => {
  let currentIndex = state.currentIndex;
  switch (action.type) {
    case "setItems": {
      return { ...state, items: action.payload, currentItem: action.payload[0] };
    }
    case "setCountItems": {
      return { ...state, countItems: action.payload };
    }
    case "next": {
      if (currentIndex + 1 < state.items.length) {
        currentIndex = currentIndex + 1;
      }
      return {
        ...state,
        currentIndex,
        currentItem: state.items[currentIndex],
        countItems: state.countItems + 1,
      };
    }
    case "prev": {
      if (state.currentIndex > 0) {
        currentIndex = currentIndex - 1;
      }
      return {
        ...state,
        currentIndex,
        currentItem: state.items[currentIndex],
        countItems: state.countItems - 1,
      };
    }
    case "clickItem": {
      return {
        ...state,
        currentIndex: action.payload,
        currentItem: state.items[action.payload],
      };
    }
    case "continue": {
      return { ...state, currentIndex: 0, currentItem: state.items[0] };
    }
    default:
      throw new Error();
  }
};

const initialState = {
  currentIndex: 0,
  items: [],
  currentItem: null,
  countItems: 1,
  totalItems: 10,
};

const Pagination = ({
  items = [],
  changeElements,
  totalItems = 1,
  currentPage = 1,
  totalPages = 1,
  totalItemsPerPage = 1,
  showPageInfo = true,
  swipeOnItems = false,
  onItemChange = () => {},
  renderElement = () => {},
  defaultIndex = 0,
  isInModal = false,
  showSlider = true,
}) => {
  const ref = useRef(null);
  const classes = useClasses();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentItem, currentIndex, countItems } = state;
  const prevCurrentIndex = useRef(null);
  const nextPage = 1;

  const handleKey = _.debounce((keyCode) => {
    // PREV PAGE
    if (keyCode === 37 && countItems > 1) {
      return currentPage > 1 && currentIndex === 0
        ? handleChangePage(currentPage, -1)
        : dispatch({ type: "prev" });
    }
    // NEXT PAGE
    if (keyCode === 39 && countItems !== totalItems) {
      return currentIndex + 1 === state.items.length
        ? handleChangePage(currentPage, nextPage)
        : dispatch({ type: "next" });
    }
  }, 100);

  const handleKeyDown = useCallback(
    (e) => {
      const { altKey, keyCode } = e;
      if (altKey && (keyCode === 39 || keyCode === 37)) {
        e.preventDefault();
        if (items.length > 1) {
          handleKey(keyCode);
        }
      }
    },
    [handleKey, items.length]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  const handleChangePage = (current, pageToGo) => {
    let changePageTo = current + pageToGo;
    changeElements(changePageTo);
    dispatch({ type: "continue" });
    dispatch({
      type: "setCountItems",
      payload: (changePageTo - 1) * totalItemsPerPage + 1,
    });
  };

  const onClickChangeItem = (index) => {
    dispatch({ type: "clickItem", payload: index });
    dispatch({ type: "setCountItems", payload: (currentPage - 1) * 10 + (index + 1) });
  };

  useEffect(() => {
    //center elements
    const elements = document.getElementsByClassName("slick-slide");
    if (elements) {
      Array.prototype.forEach.call(elements, (node) => {
        node.style.display = "flex";
        node.style.justifyContent = "center";
      });
      dispatch({ type: "setItems", payload: items });
    }
  }, [items]);

  useEffect(() => {
    const { currentIndex, currentItem } = state;
    if (ref?.current) {
      const { current } = ref;
      current.slickGoTo(state.currentIndex);
    }
    if (prevCurrentIndex?.current !== null) onItemChange({ currentIndex, currentItem });
    prevCurrentIndex.current = state.currentIndex;
  }, [state.currentIndex]); // eslint-disable-line

  useEffect(() => {
    if (defaultIndex !== 0) debounceDefaultItem();
  }, [ref?.current, defaultIndex]); // eslint-disable-line

  const debounceDefaultItem = _.debounce(() => {
    ref?.current?.slickGoTo(defaultIndex);
    dispatch({ type: "clickItem", payload: defaultIndex });
  }, 100);

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: items.length < 7 ? items.length : 7,
    slidesToScroll: 1,
    adaptiveHeight: true,
    draggable: swipeOnItems,
    speed: 50,
    arrows: false,
  };

  return (
    <div className={classes.container}>
      <Tooltip title={"Ir al principio"} arrow={true}>
        <span>
          <IconButton
            onClick={() => handleChangePage(0, nextPage)}
            disabled={currentPage === 1}
            size={"small"}
            disableRipple
            disableFocusRipple
            color="secondary"
            className={`border border-${currentIndex === 0 ? "secondary" : "primary"}`}
          >
            <FirstPageIcon fontSize={"large"} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={"Anterior"} arrow={true}>
        <span>
          <IconButton
            onClick={() =>
              currentPage > 1 && currentIndex === 0
                ? handleChangePage(currentPage, -1)
                : dispatch({ type: "prev" })
            }
            disabled={currentIndex === 0 && currentPage === 1}
            size={"small"}
            disableRipple
            disableFocusRipple
            color="secondary"
            className={`border border-${
              currentIndex === 0 ? "secondary" : "primary"
            } ml-3`}
          >
            <NavigateBeforeIcon fontSize={"large"} />
          </IconButton>
        </span>
      </Tooltip>
      {showSlider && (
        <div className={classes.slideContainer}>
          <Slider {...settings} ref={ref}>
            {items.map((item, index) => (
              <div key={uuid()}>
                {renderElement({
                  item,
                  currentItem,
                  onClick: () => {
                    onClickChangeItem(index);
                  },
                })}
              </div>
            ))}
          </Slider>
        </div>
      )}
      <Tooltip title={"Siguiente"} arrow={true}>
        <span>
          <IconButton
            onClick={() =>
              currentIndex + 1 === state.items.length
                ? handleChangePage(currentPage, nextPage)
                : dispatch({ type: "next" })
            }
            disabled={countItems === totalItems}
            size={"small"}
            disableRipple
            disableFocusRipple
            color="secondary"
            className={`border border-${
              countItems === totalItems ? "secondary" : "primary"
            }  mr-3`}
          >
            <NavigateNextIcon fontSize={"large"} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={"Ir al último"} arrow={true}>
        <span>
          <IconButton
            disabled={currentPage === totalPages}
            onClick={() => {
              handleChangePage(0, totalPages);
            }}
            size={"small"}
            disableRipple
            disableFocusRipple
            color="secondary"
            className={`border border-${
              countItems === totalItems ? "secondary" : "primary"
            }`}
          >
            <LastPageIcon fontSize={"large"} />
          </IconButton>
        </span>
      </Tooltip>
      {showPageInfo && (
        <span className={isInModal ? "ml-2" : "ml-2 font-size-h3"}>
          {currentIndex !== state.items.length ? countItems : countItems - 1} de{" "}
          {totalItems}
        </span>
      )}
    </div>
  );
};

export default Pagination;
