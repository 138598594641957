import React from "react";
import { columnStatusCssClasses } from "../../utils/columnFormattersHelpers";
import "../styles/_departureStatus.scss";

export const EntradaConcentradoColumnFormatter = (cellContent, row) => {
  return (
    <span className={`badge badge-pill badge-info`}>
      {row.concentrado_numero}
    </span>
  );
};
