import React, { useState, useCallback } from "react";
import { Apps, ViewList, Clear, Search, GetApp, DeleteOutline } from "@material-ui/icons";
import { Grid, IconButton, Divider, Box, Tooltip, Chip, Fade } from "@material-ui/core";
import SimpleInput from "../../../components/SimpleInput";
import { GRID_VIEW, LIST_VIEW } from "../../../utils/digitalVault";
import {
  setAllDigitalVaultFolders,
  setFoldersViewType,
  setSearchedeFiles,
  setSearchMode,
  clearSelectedFoldersIds,
  setDigitalVaultFolders,
} from "../../../../redux/ducks/digitalVault.duck";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import DigitalVaultNewFolderModal from "./DigitalVaultNewFolderModal";
import DigitalVaultDeleteFileModal from "./DigitalVaultDeleteFileModal";
// import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
// import Icon from '../../../components/Icon';
import {
  addDigitalVaultFiles,
  downloadFiles,
  fetchDigitalVaultFiles,
  fetchDigitalVaultFolders,
} from "../../../crud/digitalVaultCrud";
import notice from "../../../components/Notice";
import { setLoader } from "../../../../redux/ducks/loader.duck";
import { flattenTree } from "../../../utils/objectsFunctions";
import FileButton from "../../../components/FileButton";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import SplashLoader from "./common/SplashLoader";

const DigitalVaultSubHeader = () => {
  const dispatch = useDispatch();
  const [newFolderModalOpen, setNewFolderModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [searchIcon, setSearchIcon] = useState(false);
  const [isDeleteFile, setIsDeleteFile] = useState(false);
  const [attachmentSelected, setAttachmentSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    foldersViewType,
    selectedFoldersIds,
    allDigitalVaultFolders,
    currentDigitalVaultId,
    digitalVaultFolders,
  } = useSelector((state) => state.digitalVault);

  const fetchDigitalFolders = useCallback(async () => {
    dispatch(setLoader(true));
    return fetchDigitalVaultFolders()
      .then((res) => dispatch(setAllDigitalVaultFolders(res)))
      .catch((e) => notice("Ha ocurrido un error al obtener la carpeta"))
      .finally(() => dispatch(setLoader(false)));
  }, [dispatch]);

  const selectedFileInformation = () => {
    const flattenFolders = flattenTree(
      allDigitalVaultFolders,
      "subfolders"
    ).filter((folder) => Array.isArray(folder.files));
    const flattenFiles = flattenTree(flattenFolders, "files");
    return flattenFiles;
  };

  const handleNewFolder = () => {
    setNewFolderModalOpen(true);
  };

  // const formatFileId = (fileId) => {
  //   return `file_${fileId}`;
  // };

  const handleDownloadFiles = async () => {
    dispatch(setLoader(true));
    try {
      const ids = selectedFoldersIds.map(
        (id) => digitalVaultFolders.find((file) => file.id === id).attachment
      );
      const body = {
        ids: String(ids),
      };
      await downloadFiles(body);
      notice("Archivos descargados correctamente", "success");
    } catch (error) {
      console.log(error);
      notice("Hubo un error con la descarga de archivos");
    } finally {
      dispatch(setLoader(false));
    }
  };

  const [filesUploaded, setFilesUploaded] = useState(0);
  const [filesTotal, setFilesTotal] = useState(0);
  const heandleUploadFiles = async (acceptedFiles) => {
    setFilesTotal(acceptedFiles.length);
    if (!acceptedFiles) return notice("Ningún archivo encontrado", "info");
    const files = [];
    for (const file of acceptedFiles) {
      try {
        setLoading(true);
        setFilesUploaded((filesUploaded) => filesUploaded + 1);
        const res = await addDigitalVaultFiles(file, currentDigitalVaultId);
        files.push(res[0]);
      } catch {
        notice("Los archivos no se pudieron subir correctamente");
      }
    }
    const newFiles = files.map((file) => ({
      ...file,
      name: file.title,
      attachment: file.id,
    }));
    dispatch(setDigitalVaultFolders([...digitalVaultFolders, ...newFiles]));
    setFilesTotal(0);
    setFilesUploaded(0);
    setLoading(false);

    fetchDigitalFolders();
  };
  const deleteFileFromVault = () => {
    setAttachmentSelected(selectedFoldersIds[0]);
    setIsDeleteFile(true);
  };

  const handleSearch = () => {
    if (searchIcon) {
      dispatch(setLoader(true));
      fetchDigitalVaultFiles(search)
        .then(({ results }) => {
          dispatch(setSearchMode(true));
          if (!results.length) {
            notice("Sin resultados de búsqueda", "info");
            dispatch(setSearchedeFiles([]));
          } else {
            dispatch(setSearchedeFiles(results));
          }
          setSearchIcon(false);
        })
        .catch((e) => notice("Error buscando los archivos"))
        .finally(() => dispatch(setLoader(false)));
    } else {
      dispatch(setSearchedeFiles([]));
      dispatch(setSearchMode(false));
      setSearch("");
    }
  };

  return (
    <Grid container justifyContent="space-between">
      {loading && <SplashLoader total={filesTotal} subiendo={filesUploaded} />}
      <>
        <Grid item className="ml-auto mr-0 my-auto">
          <Box className="d-flex">
            <Grid item className="mb-auto mt-1 d-flex">
              <Tooltip title="Nueva carpeta">
                <IconButton onClick={handleNewFolder}>
                  <CreateNewFolderIcon color="primary" />
                </IconButton>
              </Tooltip>
              <FileButton
                isIconButton
                multiple
                accept="*"
                buttonTitle="Subir archivos"
                handleChange={heandleUploadFiles}
                iconButtonClassName=""
                iconProps={{ color: "primary" }}
              />
            </Grid>
            <Tooltip title="Descargar seleccionados">
              <IconButton
                disabled={selectedFoldersIds.length === 0}
                onClick={handleDownloadFiles}
              >
                <GetApp />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar seleccionados">
              <IconButton
                disabled={
                  selectedFoldersIds.length === 0 || selectedFoldersIds.length > 1
                }
                onClick={deleteFileFromVault}
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            <Tooltip title="Modo de visualización">
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={() =>
                  foldersViewType === "GRID VIEW"
                    ? dispatch(setFoldersViewType(LIST_VIEW))
                    : dispatch(setFoldersViewType(GRID_VIEW))
                }
              >
                <Grid className="m-0 p-0">
                  {foldersViewType === "GRID VIEW" ? <ViewList /> : <Apps />}
                </Grid>
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid
          item
          className="ml-0 mr-0 my-auto d-flex align-items-center"
          component="form"
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
          }}
        >
          <SimpleInput
            name="search"
            value={search}
            onChange={({ target }) => {
              setSearchIcon(true);
              setSearch(target.value);
            }}
            placeholder="Buscar en la bóveda"
            width={350}
          />
          <IconButton onClick={handleSearch}>
            {searchIcon ? <Search /> : <Clear />}
          </IconButton>
          {selectedFoldersIds.length > 0 && (
            <Fade in={selectedFoldersIds.length > 0} timeout={1500}>
              <Chip
                label={`${selectedFoldersIds.length} archivos seleccionados`}
                onDelete={() => {
                  dispatch(clearSelectedFoldersIds());
                }}
                color="secondary"
              />
            </Fade>
          )}
        </Grid>
      </>
      <DigitalVaultNewFolderModal
        open={newFolderModalOpen}
        onClose={() => setNewFolderModalOpen(false)}
      />
      <DigitalVaultDeleteFileModal
        file={attachmentSelected}
        open={isDeleteFile}
        onClose={() => setIsDeleteFile(false)}
      />
    </Grid>
  );
};

export default DigitalVaultSubHeader;
