import React, { Fragment, memo } from "react";

import {
  InputAdornment,
  makeStyles,
  Tooltip,
  colors,
  Typography,
  Divider,
} from "@material-ui/core";
import SimpleDate from "./SimpleDate";
import SimpleDropdown from "./SimpleDropdown";
import SimpleInput from "./SimpleInput";
import InputItemKey from "./InputItemKey";
import ProgressBarLabel from "./ProgressBarLabel";
import { getCABMSKey } from "../utils/cabmsAndKey";
import AutocompleteInput from "./AutocompleteInput";
import FileButton from "./FileButton";
import UploadFileButton from "./UploadFileButton";
import SimpleTextAutocomplete from "./SimpleTextAutocomplete";
import SimpleCheckbox from "./SimpleCheckbox";
import SimpleSwitch from "./SimpleSwitch";
import InputLabelStatus from "./InputLabelStatus";
import SimpleChipDropdown from "./SimpleChipDropdown";
import SimpleDropdownRequest from "./SimpleDropdownRequest";
import {
  ErrorOutline as ErrorOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Edit as EditIcon,
  Block as BlockIcon,
} from "@material-ui/icons";
import SimpleRadioButton from "./SimpleRadioButton";
import AutocompleteMultipleInput from "./AutocompleteMultipleInput";
import CustomButton from "./CustomButton";
import UploadFileButtonAuto from "./UploadFileButtonAuto";

const useStyles = makeStyles((theme) => ({
  root: {
    display: ({ withFlex }) => (withFlex ? "flex" : "contents"),
    flexDirection: ({ rootColumn }) => (rootColumn ? "column" : "row"),
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "&>*": {
      marginRight: 15,
    },
  },
  flexContainer: {
    display: "flex",
  },
  adornment: {
    color: theme.palette?.gray?.main,
    margin: "0px 5px 1px 0px",
  },
  adornmentConfirmPointer: {
    cursor: "pointer",
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

const UtilInputs = memo(
  ({
    view,
    formValues,
    onValuesChange,
    inputValues = [],
    width,
    onKeyDown,
    onBlur,
    chipProps = {},
    imagesPreview,
    setImagesPreview,
    onUploaderLoading,
    uploadLoading,
    rootStyle,
    rootColumn = false,
    withFlex = true,
    className = "",
  }) => {
    const classes = useStyles({ rootColumn, withFlex });
    const handleValuesChange = (e) => onValuesChange(e);
    return (
      <div className={`${classes.root} ${className}`} style={rootStyle}>
        {inputValues.map((filter, i) => {
          if (filter)
            switch (filter.type) {
              case "text":
                return (
                  <Fragment key={filter.name + i}>
                    <SimpleInput
                      helperText={filter.helperText}
                      label={filter.label}
                      required={filter.required}
                      focus={filter.focus}
                      autofocus={filter.autofocus === true ? true : false}
                      placeholder={filter.placeholder}
                      name={filter.name}
                      onChange={onValuesChange}
                      value={formValues[filter.name] || ""} // for clear filters on pledged goods
                      width={filter.width || (width && width)}
                      isPassword={filter.password}
                      onKeyDown={onKeyDown}
                      style={filter.style}
                      adornment={filter.adornment}
                      InputProps={{
                        endAdornment:
                          filter.password && formValues[filter.confirm]?.newValue ? (
                            <InputAdornment
                              classes={{
                                root: classes.adornmentConfirmPointer,
                              }}
                              position="end"
                              onClick={() => {
                                onValuesChange({
                                  target: {
                                    name: "password_confirm",
                                    value: {
                                      newValue: "",
                                      disabled: !filter.disabled,
                                    },
                                  },
                                });
                              }}
                            >
                              <Tooltip title={filter.disabled ? "Cambiar" : "Cancelar"}>
                                {filter.disabled ? <EditIcon /> : <BlockIcon />}
                              </Tooltip>
                            </InputAdornment>
                          ) : (
                            undefined
                          ),
                      }}
                      onBlur={onBlur}
                      disabled={
                        view === "SEARCH" &&
                        (filter.name === "area_nombre" || filter.name === "titular")
                          ? filter.onlySearch
                          : filter.disabled
                      }
                      multiline={filter.multiline}
                      inputProps={{
                        maxLength: filter.maxLength,
                        type: filter?.password ? "password" : "text",
                        autoComplete: "off",
                      }}
                      rows={filter.rows}
                      autoSelect={filter.autoSelect}
                      isPrice={filter.isPrice}
                      maxLength={filter.maxLength}
                      mask={filter.mask}
                      maskFormat={filter.maskFormat}
                      showLowerCase={filter.showLowerCase}
                    />
                    {filter?.confirm &&
                      (() => {
                        const confirmInput = inputValues.find(
                          (input) => input?.name === filter.confirm
                        );
                        const theValue =
                          formValues[filter.confirm]?.newValue ??
                          formValues[filter.confirm];
                        const disabled =
                          formValues[filter.confirm]?.disabled === undefined
                            ? false
                            : formValues[filter.confirm].disabled;
                        return (
                          disabled === false && (
                            <div
                              key={filter.confirm}
                              style={{
                                display: "flex",
                                position: "relative",
                              }}
                            >
                              <SimpleInput
                                inputProps={{
                                  type: "password",
                                }}
                                label={confirmInput?.label}
                                required={
                                  formValues[filter.name] !== theValue &&
                                  theValue?.length > 0
                                }
                                name={confirmInput?.name}
                                placeholder={confirmInput?.placeholder}
                                onChange={onValuesChange}
                                value={theValue}
                                width={confirmInput?.width || (width && width)}
                                isPassword={filter.confirm}
                                InputProps={{
                                  endAdornment:
                                    theValue?.length > 0 ? (
                                      <InputAdornment
                                        position="end"
                                        classes={{
                                          root:
                                            formValues[filter.name] !== theValue
                                              ? classes.adornmentConfirmPointer
                                              : undefined,
                                        }}
                                      >
                                        {formValues[filter.name] !== theValue ? (
                                          <Tooltip
                                            title={`Debe coincidir con el campo ${filter.placeholder}`}
                                          >
                                            <ErrorOutlineIcon color={"error"} />
                                          </Tooltip>
                                        ) : (
                                          <CheckCircleOutlineIcon
                                            style={{
                                              color: colors.green[500],
                                            }}
                                          />
                                        )}
                                      </InputAdornment>
                                    ) : (
                                      undefined
                                    ),
                                }}
                              />
                            </div>
                          )
                        );
                      })()}
                  </Fragment>
                );
              case "date":
                return (
                  <Fragment key={filter.name + i}>
                    <SimpleDate
                      label={filter.label}
                      required={filter.required}
                      placeholder={filter.placeholder}
                      name={filter.name}
                      onChange={handleValuesChange}
                      value={formValues[filter.name]}
                      width={filter.width || (width && width)}
                      onKeyDown={onKeyDown}
                      bgColor={filter.bgColor}
                      disabled={filter.disabled}
                      errorMessage={filter.errorMessage}
                      isFullYear={true}
                    />
                  </Fragment>
                );

              case "dropdown":
                return (
                  <Fragment key={filter.name + i}>
                    <SimpleDropdown
                      key={filter.name}
                      label={filter.label}
                      required={filter.required}
                      name={filter.name}
                      onChange={handleValuesChange}
                      value={formValues[filter.name]}
                      options={filter?.options}
                      width={filter.width || (width && width)}
                      disabled={filter.disabled}
                      toUpperCase={filter.toUpperCase}
                    />
                  </Fragment>
                );
              case "dropdown-chip":
                return (
                  <Fragment key={filter.name + i}>
                    <SimpleChipDropdown
                      key={filter.name}
                      label={filter.label}
                      required={filter.required}
                      placeholder={filter.placeholder}
                      name={filter.name}
                      onChange={handleValuesChange}
                      value={formValues[filter.name]}
                      options={filter.options}
                      width={filter.width || (width && width)}
                      disabled={filter.disabled}
                      message={filter.message}
                    />
                  </Fragment>
                );
              case "dropdown-request":
                return (
                  <Fragment key={filter.name + i}>
                    <SimpleDropdownRequest
                      key={filter.name}
                      label={filter.label}
                      required={filter.required}
                      name={filter.name}
                      onChange={handleValuesChange}
                      value={formValues[filter.name]}
                      requestFunction={filter.requestFunction}
                      width={filter.width || (width && width)}
                      disabled={filter.disabled}
                    />
                  </Fragment>
                );
              case "key":
                return ["cabms", "key", "cabms_item", "item"].includes(filter.name) ? (
                  <div key={filter.name + i}>
                    <InputItemKey
                      item={filter}
                      value={formValues[filter.name]}
                      handleValuesChange={handleValuesChange}
                      textMuted={(option) => getCABMSKey(option)}
                      optionValue={(option) => option.text}
                      optionText={(option) => option.text}
                      onClickOption={(option) =>
                        handleValuesChange({
                          target: {
                            name: filter.name,
                            value: option,
                          },
                        })
                      }
                      onKeyDown={onKeyDown}
                      width={filter.width || (width && width)}
                      disabled={filter.disabled}
                    />
                  </div>
                ) : (
                  <></>
                );
              case "progress":
                return (
                  <ProgressBarLabel
                    key={filter.name + i}
                    label={filter.label}
                    progress={formValues[filter.name]}
                    showProgress={filter.showProgress}
                  />
                );

              case "search":
                return (
                  <Fragment key={filter.name + i}>
                    <AutocompleteInput
                      name={filter.name}
                      label={filter.label}
                      required={filter.required}
                      value={formValues[filter.name]}
                      placeholder={filter.placeholder}
                      onChange={handleValuesChange}
                      searchFunction={filter.searchFunction}
                      onKeyDown={onKeyDown}
                      hideText={filter.hideText}
                      width={filter.width || (width && width)}
                      disabled={filter.disabled}
                      onlyResult={filter.onlyResult ? filter.onlyResult : false}
                    />
                  </Fragment>
                );
              case "search-multiple":
                return (
                  <Fragment key={filter.name + i}>
                    <AutocompleteMultipleInput
                      name={filter.name}
                      label={filter.label}
                      value={formValues[filter.name]}
                      placeholder={filter.placeholder}
                      options={filter.options}
                      onChange={(e) => handleValuesChange(e)}
                      searchFunction={filter.searchFunction}
                      requestFunction={filter.searchFunction}
                      width={filter.width || (width && width)}
                      disabled={filter.disabled}
                      showAvatars={filter.showAvatars}
                      limitTags={filter.limitTags}
                    />
                  </Fragment>
                );

              case "text-dropdown":
                return (
                  <Fragment key={filter.name + i}>
                    <SimpleTextAutocomplete
                      name={filter.name}
                      label={filter.label}
                      required={filter.required}
                      value={formValues[filter.name]}
                      placeholder={filter.placeholder}
                      onChange={(e) => handleValuesChange(e)}
                      options={filter.options}
                      onKeyDown={onKeyDown}
                      disabled={filter.disabled}
                      uppercase={filter.uppercase}
                    />
                  </Fragment>
                );
              case "photo":
                return (
                  <Fragment key={filter.name + i} style={{ marginLeft: 10 }}>
                    <FileButton
                      displayName={filter.displayName}
                      accept={filter.accept}
                      label={filter.label}
                      handleChange={(e) =>
                        handleValuesChange({
                          target: {
                            name: filter.name,
                            value: e,
                          },
                        })
                      }
                      value={formValues[filter.name]}
                      uploadedStyle={{
                        marginTop: 25,
                      }}
                      imagesPreview={imagesPreview}
                      setImagesPreview={setImagesPreview}
                      disabled={filter.disabled}
                    />
                  </Fragment>
                );

              case "file-auto":
                return (
                  <div key={filter.name + i}>
                    <UploadFileButtonAuto
                      displayName={filter.displayName}
                      accept={filter.accept}
                      label={filter.label}
                      disabled={filter.disabled}
                      handleChange={(e) =>
                        handleValuesChange({
                          target: {
                            name: filter.name,
                            value: e,
                          },
                        })
                      }
                      value={formValues[filter.name]}
                      uploadId={formValues?.id}
                      uploadedStyle={{
                        marginTop: 25,
                      }}
                      uploadFunction={filter.uploadFunction}
                      deleteFunction={filter.deleteFunction}
                      name={filter.name}
                      setLoading={onUploaderLoading}
                      loading={uploadLoading}
                      disableView={filter.disableView}
                    />
                  </div>
                );

              case "file":
                return (
                  <div key={filter.name + i}>
                    <UploadFileButton
                      displayName={filter.displayName}
                      accept={filter.accept}
                      label={filter.label}
                      disabled={filter.disabled}
                      handleChange={(e) =>
                        handleValuesChange({
                          target: {
                            name: filter.name,
                            value: e,
                          },
                        })
                      }
                      value={formValues[filter.name]}
                      uploadId={formValues?.id}
                      uploadedStyle={{
                        marginTop: 25,
                      }}
                      uploadFunction={filter.uploadFunction}
                      deleteFunction={filter.deleteFunction}
                      name={filter.name}
                      setLoading={onUploaderLoading}
                      loading={uploadLoading}
                      disableView={filter.disableView}
                    />
                  </div>
                );

              case "checkbox":
                return (
                  <Fragment key={filter.name + i} style={{ marginLeft: 10 }}>
                    <SimpleCheckbox
                      name={filter.name}
                      disabled={filter.disabled}
                      label={filter.label}
                      value={formValues[filter.name]}
                      onChange={(e) => handleValuesChange(e)}
                      labelPos={filter.labelPos}
                    />
                  </Fragment>
                );

              case "switch":
                return (
                  <div
                    key={filter.name + i}
                    style={{
                      width: filter.width || width,
                    }}
                  >
                    <SimpleSwitch
                      name={filter.name}
                      disabled={filter.disabled}
                      label={` ${filter.label} ${
                        formValues[filter.name] ? "Activo" : "Inactivo"
                      } `}
                      value={formValues[filter.name]}
                      onChange={(e) => handleValuesChange(e)}
                      labelPos={filter.labelPos}
                      color={filter.color}
                    />
                  </div>
                );

              case "radio":
                return (
                  <Fragment key={filter.name + i} style={{ marginLeft: 10 }}>
                    <SimpleRadioButton
                      name={filter.name}
                      disabled={filter.disabled}
                      label={filter.label}
                      value={formValues[filter.name]}
                      onChange={(e) => handleValuesChange(e)}
                      labelPos={filter.labelPos}
                      color={filter.color}
                      options={filter.options}
                      row={filter.row}
                    />
                  </Fragment>
                );

              case "status-label":
                return (
                  <Fragment key={filter.name + i}>
                    <InputLabelStatus
                      width={filter.width || (width && width)}
                      disabled={filter.disabled}
                      label={filter.label}
                      value={formValues[filter.name]}
                      name={filter.name}
                    />
                  </Fragment>
                );
              case "button":
                return (
                  <Fragment key={filter.label + i}>
                    <CustomButton
                      width={filter.width || (width && width)}
                      disabled={filter.disabled}
                      title={filter.label}
                      onClick={filter.onClick}
                      color={filter.color}
                      style={filter.style}
                      className={filter.className}
                      tooltip={filter.tooltip}
                      tooltipPos={filter.tooltipPos}
                    />
                  </Fragment>
                );

              case "line-break":
                return (
                  <div key={filter.type + i} style={{ flexBasis: "100%" }}>
                    {filter.title && (
                      <>
                        <Divider className={classes.divider} />
                        <Typography variant="h6">{filter.title}</Typography>
                      </>
                    )}
                    {filter.separator && <Divider className={classes.divider} />}
                  </div>
                );
              default:
                <></>;
            }
          return false;
        })}
      </div>
    );
  }
);

export default UtilInputs;
