import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createSlice } from "@reduxjs/toolkit";
import { defaultRequirementFilter, initialSelectedRequirement } from "../../app/utils/requirementsData";

export const initialState = {
  requirement: {
    results: [],
    count: 0,
    page: 1,
    per_page: 20
  },
  selectedRequirement: initialSelectedRequirement,
  searchRequirement: defaultRequirementFilter,
  selectedIds: [],
  currentRequestId: null,
  newRequirement: defaultRequirementFilter,
  isSearchTab: false,
  isResultsTab: false,
  isNewTab: false,
};

export const requirementSlice = createSlice({
  name: "[REQUIREMENTS]",
  initialState,
  reducers: {
    setPage: {
      reducer: (state, action) => {
        state.requirement.page = action.payload;
      },
      prepare: (current) => ({ payload: current })
    },
    setSizePerPage: {
      reducer: (state, action) => {
        state.requirement.per_page = action.payload;
      },
      prepare: (pageSize) => ({ payload: pageSize })
    },
    setFilterRequirement: {
      reducer: (state, action) => {
        state.searchRequirement = action.payload;
      }
    },
    setSelectedRequirement: {
      reducer: (state, action) => {
        state.selectedRequirement = action.payload;
      }
    },
    setSelectedRequirementIds: {
      reducer: (state, action) => {
        state.selectedIds = action.payload;
      }
    },
    clearRequirement: {
      reducer: (state) => {
        state.selectedRequirement = initialSelectedRequirement;
        state.searchRequirement = defaultRequirementFilter;
        state.selectedIds = [];
      }
    },
    setNewRequirement: {
      reducer: (state, action) => {
        state.newRequirement = action.payload;
      }
    },
    setCurrentRequirementTab: {
      reducer: (state, action) => {
        state.isSearchTab = action.payload.isSearchTab;
        state.isResultsTab = action.payload.isResultsTab;
        state.isNewTab = action.payload.isNewTab;
      },
    },
    setRequirements: {
      reducer: (state, action) => {
        state.requirement.results = action.payload.results;
        state.requirement.count = action.payload.count;
        state.requirement.per_page = action.payload.count;
        state.requirement.page = action.payload.page;
      },
    }
  },
});

export const {
  setPage,
  setSizePerPage,
  setSelectedRequirement,
  setFilterRequirement,
  setSelectedRequirementIds,
  clearRequirement,
  setNewRequirement,
  setCurrentRequirementTab,
  setRequirements,
} = requirementSlice.actions;

export const requirementSelector = (store) => store.requirement;

export default persistReducer({ storage, key: "requirement", whitelist: ["selectedIds", "selectedRequirement"] }, requirementSlice.reducer);
