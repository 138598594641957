import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  SetInventorysIds,
  SetInventorys,
  SetClearInventorys,
} from "../../../redux/ducks/inventory.duck";
import { getInventoryList, getReportInventory } from "../../crud/inventoryCrud";
import FullTable from "../../components/FullTable";
import { columnsSurvey, filterInputs } from "../../utils/stockSurveyData";
import { setCurrentMode } from "../../../redux/ducks/subHeaderActions.duck";

const StockSurveyPage = () => {
  const { list } = useSelector((state) => state.inventory);
  const { permisos } = useSelector((store) => store.auth);
  const { results, count } = list;
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filterForm, setFilterForm] = useState({ area: null });
  // useSearchMode("/inventario-bienes-almacen-general/articulos/busqueda")

  useEffect(() => {
    setLoading(true);
    getInventoryList({ page, limit: sizePerPage, cve_area: filterForm.area?.text2 })
      .then((res) => {
        if (res.status === 200 && res.data) dispatch(SetInventorys(res.data));
        // console.log(res.data)
      })
      .catch((err) => console.log("err", err))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, sizePerPage]);

  const handleNewInventory = () => {
    dispatch(SetClearInventorys());
    dispatch(setCurrentMode("new"));
    history.push("/bienes-instrumentales/levantamiento-inventario/nuevo");
  };

  const onEye = (id) => {
    history.push(`/bienes-instrumentales/levantamiento-inventario/resultados/${id}`);
  };

  const handleSelectAllRows = (isSelect, rows) =>
    isSelect ? setSelectedIds(rows.map((r) => r.id)) : setSelectedIds([]);

  const handleSelectRow = (row, isSelect) => {
    if (row && row.id) {
      if (!isSelect) {
        const newSelected = selectedIds.filter((id) => id !== row.id);
        setSelectedIds(newSelected);
      } else {
        setSelectedIds([...selectedIds, row.id]);
      }
    }
  };

  const handleReqSelected = () => {
    dispatch(SetInventorysIds(selectedIds));
    history.push(
      `/bienes-instrumentales/levantamiento-inventario/resultados/${selectedIds[0]}`
    );
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;

    setFilterForm({ ...filterForm, [name]: value });
  };

  const confirmFilters = () => {
    getInventoryList({ page, limit: sizePerPage, cve_area: filterForm.area?.text2 })
      .then((res) => {
        if (res.status === 200 && res.data) dispatch(SetInventorys(res.data));
        // console.log(res.data)
      })
      .catch((err) => console.log("err", err))
      .finally(() => setLoading(false));
  };
  const suma = sizePerPage * page;
  const handleReportInventory = () => {
    setLoading(true);

    getReportInventory()
      .then(({ status, data }) => {
        if (status === 200 && data.attachment_url) {
          window.open(data.attachment_url, "_blank");
        }
      })
      .catch((err) => console.log("err", err))
      .finally(() => setLoading(false));
  };
  return (
    <>
      <FullTable
        title="Levantamiento de Inventario"
        buttonTitle="Nuevo Levantamiento"
        buttonTitle3="Reporte"
        onClickButton={handleNewInventory}
        onClickButton3={handleReportInventory}
        count={count}
        entities={results}
        columns={columnsSurvey(onEye, page, suma, permisos.permisos)}
        setPage={setPage}
        page={page}
        sizePerPage={sizePerPage}
        setSizePerPage={setSizePerPage}
        loading={loading}
        enableRedBackground={true}
        search_button={false}
        has_button2={false}
        has_button3={true}
        hasReportIcons={false}
        hideCheckbox
        // has_button={!isDropPage}
        // onClickSearch={handleonClickSearch}
        // sizePerPage={isDropPage ? stockDrop.per_page : stock.per_page}
        // buttonDisabled={guardItems.length === 0}
        // badgeContent={guardItems}
        onSelectRow={handleSelectRow}
        buttonDisabled2={selectedIds.length < 1}
        buttonTitle2={`Consultar Todos${
          selectedIds.length > 1 ? `(${selectedIds.length.toString()})` : ""
        }`}
        onClickButton2={handleReqSelected}
        onSelectAllRows={handleSelectAllRows}
        // nonSelectableRows={nonSelectableRows}
        has_filter
        filterSelected={showFilter}
        onClickFilter={() => setShowFilter(!showFilter)}
        onFilterChange={handleFilter}
        formFilters={filterForm}
        filterInputs={filterInputs}
        onConfirmFilter={confirmFilters}
        onClearFilter={() => setFilterForm({ area: null })}
      />
    </>
  );
};

export default StockSurveyPage;
