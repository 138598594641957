import * as columnFormatters from "../components/column-formatters";

export const columns = ({ onEye, onLock }) => [
  {
    dataField: "articulo",
    text: "Artículo",
    sort: true,
    formatter: (cellContent) => cellContent?.cve_articulo,
  },
  {
    dataField: "articulo",
    text: "Descripción",
    sort: true,
    formatter: (cellContent) => cellContent?.descripcion,
  },
  {
    dataField: "articulo",
    text: "Unidad",
    sort: true,
    formatter: (cellContent) => cellContent?.unidad,
  },
  {
    dataField: "warehouse_request",
    text: "No. De Solicitud",
    sort: true,
  },
  {
    dataField: "cf_cantidad_solicitada",
    text: "Solicitadas",
    sort: true,
  },
  {
    dataField: "cf_cantidad_surtida",
    text: "Surtidas",
    sort: true,
  },
  {
    dataField: "stock",
    text: "Exitencia",
    sort: true,
    formatter: (cellContent) => cellContent?.existencia_total,
  },
  {
    dataField: "estatus",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.PledgegGoodsStatusColumnFormatter,
  },
];

export const filterInputs = [
  {
    label: "Cve. de Artículo",
    name: "cve_articulo",
    type: "text",
  },
];

export const defaultPledgegGoodsFilters = {
  cve_articulo: "",
};
