import React from 'react'
import EyeColumnIcon from './list-icons/EyeColumnIcon'

export const ArticlesActionsColumnFormatter = (cellContent, row, rowIndex, { onEye }) => {
  return (
    <>
      <EyeColumnIcon onClick={() => onEye(row.id)} />
    </>
  )
}
