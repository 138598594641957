import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  GET_REQUEST_PDF,
  setCurrentRequestTab,
  setFilterRequest,
  setGoodQuantities,
  setNewRequest,
  setSelectedRequest,
} from "../../../redux/ducks/request.duck";
import NewEntranceConcentrateForm from "./NewEntranceConcentrateForm";
import notice from "../../components/Notice";
import { defaultRequestFilter } from "../../utils/materialRequestData";
import {
  getEntryByID,
  getEntranceConcentratebyID,
} from "../../crud/entranceConcentrateCrud";
import {
  SetListEntriesForConcentrate,
  SetActionForm,
  SetConcentrateSelected,
} from "../../../redux/ducks/entranceConcentrate.duck";

const NewEntranceConcentratePage = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const moduleAdminRoute = "inventario-bienes-almacen-general";
  const { user } = useSelector((store) => store.auth);
  const inventoryModule = user.app_modules.find(
    (module) => module.name === moduleAdminRoute
  );
  const requestModule =
    inventoryModule && inventoryModule.submodules.length > 0
      ? inventoryModule.submodules.find(
          (module) => module.name === "solicitudes"
        )
      : undefined;
  const mode = !pathname.includes(moduleAdminRoute)
    ? "public"
    : requestModule
    ? "admin"
    : "public";
  const dispatch = useDispatch();
  const {
    selectedRequest,
    selectedItems,
    currentRequestInputsFields,
    isSearchTab,
    isResultsTab,
    isNewTab,
    filter,
    newRequest,
    newItems,
    selectedDraft,
    isDraftTab,
  } = useSelector((store) => store.request);
  const { actionForm, listEntriesForConcentrate } = useSelector(
    (store) => store.entranceConcentrate
  );

  const setRequestFormFunction = isSearchTab
    ? setFilterRequest
    : isResultsTab
    ? setSelectedRequest
    : isNewTab && setNewRequest;
  const requestForm = isDraftTab
    ? selectedDraft
    : isSearchTab
    ? filter
    : isResultsTab
    ? selectedRequest
    : isNewTab
    ? newRequest
    : defaultRequestFilter;

  const itemsVariable =
    isResultsTab || isDraftTab ? selectedItems : isNewTab ? newItems : [];

  const getInfoDetail = (id) => {
    getEntranceConcentratebyID(id)
      .then((res) => {
        if (res?.status && res.status === 200 && res.data) {
          dispatch(SetConcentrateSelected(res.data));
          getinfoTable(res.data.entries);
        } else {
          notice("Ocurrió un error al consultar la información");
        }
      })
      .catch((error) => {
        notice("Ocurrió un error al consultar la información");
      });
  };

  const getinfoTable = (data) => {
    dispatch(SetListEntriesForConcentrate([]));
    data.forEach((element) => {
      getEntryByID(element)
        .then((resp) => {
          let dataCustom = {
            id: element,
            cf_no_almacen: resp.data.cf_no_almacen,
            cf_no_proveedor: resp.data.cf_no_proveedor,
            cf_no_factura: resp.data.cf_no_factura,
            tipo_entrada: "", //No viene en el response
            cf_fecha_entrada: resp.data.cf_fecha_entrada,
            cf_fecha_factura: resp.data.cf_fecha_factura,
            cf_hora_ent: resp.data.cf_hora_ent,
            cf_no_pedido: resp.data.cf_no_pedido,
            cf_a_pedido: resp.data.cf_a_pedido,
            cf_ctaxpagar: resp.data.cf_ctaxpagar,
            cf_fecxpagar: resp.data.cf_fecxpagar,
            cf_no_requisicion: resp.data.cf_no_requisicion,
            cf_sub_total: resp.data.cf_sub_total,
            cf_iva_factura: resp.data.cf_iva_factura,
            cf_importe_neto: resp.data.cf_importe_neto,
            cf_observaciones: resp.data.cf_observaciones,
            cf_fol_bita: resp.data.cf_fol_bita,
            cf_cve_usuario: resp.data.cf_cve_usuario,
            cf_estatus: resp.data.cf_estatus,
            cf_tipo_documento: resp.data.cf_tipo_documento,
            cf_no_remision: resp.data.cf_no_remision,
            cf_fecha_remision: resp.data.cf_fecha_remision,
            cf_no_requerimiento: resp.data.cf_no_requerimiento,
            warehouse_request: resp.data.warehouse_request,
            entrada_cerrada: resp.data.entrada_cerrada,
            cf_ord_proveedor: resp.data.cf_ord_proveedor,
            cf_ord_fecha_registro: resp.data.cf_ord_fecha_registro,
            cf_ord_no_contrato: resp.data.cf_ord_no_contrato,
            cf_ord_area_solicitante: resp.data.cf_ord_area_solicitante,
            cf_recibido: resp.data.cf_recibido,
            ordinaria_descripcion: resp.data.ordinaria_descripcion,
          };
          let arrayTemporal = listEntriesForConcentrate;
          arrayTemporal.push(dataCustom);
          dispatch(SetListEntriesForConcentrate(arrayTemporal));
        })
        .catch((error) => {});
    });
  };

  useEffect(() => {
    if (actionForm === "") {
      dispatch(SetActionForm(id !== undefined ? "isEdit" : "isNew"));
    }

    if (actionForm === "isEdit" && id) {
      getInfoDetail(id);
    }
  }, [actionForm, id]); // eslint-disable-line

  const checkIfIsCompleted = (itemsVariable) =>
    itemsVariable.filter(
      (item) =>
        item.cf_cantidad_surtida.toString() !== "0" ||
        item.cf_cantidad_negada.toString() !== "0"
    ).length === itemsVariable.length;

  useEffect(() => {
    if (!isResultsTab) return;
    if (
      requestForm.cf_estatus === "G" &&
      itemsVariable.length > 0 &&
      !!itemsVariable?.find(
        (item) =>
          item.cf_cantidad_surtida?.toString() !== "0" ||
          item.cf_cantidad_negada?.toString() !== "0"
      ) &&
      currentRequestInputsFields?.find((field) => field.name === "label_status")
        .value.text !== "Surtiendo"
    ) {
      console.log(itemsVariable);
      console.log(currentRequestInputsFields);

      const isCompleted = checkIfIsCompleted(itemsVariable);
      const label_status = { color: "warning", text: "Surtiendo" };
      dispatch(
        setRequestFormFunction({
          ...requestForm,
          label_status,
          isCompleted,
          cf_estatus: mode === "public" ? requestForm.cf_estatus : "H",
        })
      );
      return;
    } else {
      const isCompleted = checkIfIsCompleted(itemsVariable);
      dispatch(setRequestFormFunction({ ...requestForm, isCompleted }));
    }
    const {
      cf_cantidad_solicitada: requestedGoodsQuantity,
      cf_cantidad_surtida: selectedGoodsQuantity,
      cf_cantidad_negada: deniedGoods,
    } = itemsVariable.reduce(
      (a, b) => ({
        cf_cantidad_solicitada:
          Number(a.cf_cantidad_solicitada) + Number(b.cf_cantidad_solicitada),
        cf_cantidad_surtida:
          Number(a.cf_cantidad_surtida) + Number(b.cf_cantidad_surtida),
        cf_cantidad_negada:
          Number(a.cf_cantidad_negada) + Number(b.cf_cantidad_negada),
      }),
      0
    );
    const newGoodQuantities = {
      requestedGoodsQuantity,
      selectedGoodsQuantity,
      deniedGoods,
    };
    dispatch(setGoodQuantities(newGoodQuantities));
  }, [itemsVariable, dispatch, isResultsTab]);

  const onFile = () => {
    dispatch(GET_REQUEST_PDF(selectedRequest.id))
      .then(({ payload }) => {
        if (payload?.attachment_url) window.open(payload.attachment_url);
      })
      .catch((res) => {
        notice("No se pudo cargar el PDF, contacta al administrador");
      });
  };

  useEffect(() => {
    const newTabs = pathname.includes("borrador")
      ? {
          isSearchTab: false,
          isResultsTab: false,
          isNewTab: false,
          isDraftTab: true,
        }
      : pathname.includes("busqueda")
      ? {
          isSearchTab: true,
          isResultsTab: false,
          isNewTab: false,
          isDraftTab: false,
        }
      : pathname.includes("nueva")
      ? {
          isSearchTab: false,
          isResultsTab: false,
          isNewTab: true,
          isDraftTab: false,
        }
      : {
          isSearchTab: false,
          isResultsTab: true,
          isNewTab: false,
          isDraftTab: false,
        };
    dispatch(setCurrentRequestTab(newTabs));
  }, [pathname, dispatch]);

  return (
    <>
      <NewEntranceConcentrateForm />
    </>
  );
};

export default NewEntranceConcentratePage;
