/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import EyeColumnIcon from "./list-icons/EyeColumnIcon";


export const StockSurveyActionsColumnFormatter = (cellContent,row,rowIndex,{ onEye,Permisos }) => (
	<div className="d-flex justify-content-center"> {/* IF ADD AND MORE ACTIONS, CHANGE JUSTIFY CENTER TO BETWEEN */}
		{Permisos.includes("i-ver-levantamiento") && <EyeColumnIcon onClick={() => onEye(row.last_inventory_take)} disabled={!row.inventory_take_total_count} />}
	</div>
);
