import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory  } from 'react-router-dom'
import { setClearState, setPermit } from '../../../../redux/ducks/permits.duck'
import notice from '../../../components/Notice'
import PageLayout from '../../../components/PageLayout'
import TabsButton from '../../../components/TabsButton'
import UtilInputs from '../../../components/UtilInputs'
import { getSinglePermission } from '../../../crud/permitsCrud'
import { permitForm } from '../../../utils/configData'

const ConfigPermitDetail = () => {
  const { permit, selectedPermits } = useSelector(state => state.permits)
  const dispatch = useDispatch()
  const history = useHistory()

  const onValuesChange = (e) => {
    if(e && e.target) {
        const { value, name } = e.target;
        
        dispatch(setPermit({...permit, [name]: value}))
    };
  };

  const handleClickTab = (id) => {
    getSinglePermission(id)
      .then(res => {
        if (res.status === 200) {
          dispatch(setPermit(res.data))
          history.push(`/inventario-bienes-almacen-general/configuracion/usuarios/permisos/resultados/${id}`)
        }
      }).catch(e => {
        dispatch(setClearState())
        notice("Error al mostrar el grupo")
        history.push(`/inventario-bienes-almacen-general/configuracion/usuarios/nuevo`)
      })
  }

  return (
    <PageLayout
      minHeight="100%"
      title={permit.id ? "Permiso" : "Nuevo Permiso"}
      header={
        permit.id && selectedPermits.length > 0 && <TabsButton
          list={selectedPermits} selected={permit.id} handleClickTab={handleClickTab}
        />
      }
    >
      <UtilInputs
        inputValues={permitForm}
        formValues={permit}
        onValuesChange={onValuesChange}
      />
    </PageLayout>
  )
}


export default ConfigPermitDetail
