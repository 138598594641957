/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import EyeColumnIcon from "./list-icons/EyeColumnIcon";
// import LockColumnIcon from "./list-icons/LockColumnIcon";

export const EntrancesActionsColumnFormatter = (cellContent, row, rowIndex, { onEye, onLock }) => (
	<div className="d-flex justify-content-center">
		<EyeColumnIcon onClick={() => onEye(row.id, row.devolucion)}/>
		{/* <LockColumnIcon onClick={() => onLock(row.id)}/> */}
	</div>
);
