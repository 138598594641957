import { DASHBOARD_IG_URL } from "./helpers/routes";
import { getParams, handleError, handleResponse } from "./helpers/validate";

export const fetchDashboardDetails = (body) => {
  let data = {
    fecha_inicio: body?.fecha_inicio.replaceAll("/", "-"),
    fecha_fin: body?.fecha_fin.replaceAll("/", "-"),
  };
  return fetch(DASHBOARD_IG_URL, getParams("POST", data))
    .then(handleResponse)
    .catch(handleError);
};
