import React from 'react'
import { makeStyles } from '@material-ui/core';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import CardColorItem from './CardColorItem';

const useStyles = makeStyles(theme => ({
	colorCards: {
		"&>*": {
			marginRight: 30,
			marginTop: 30
		}
	}
}));

const CardColorContainer = ({ cards = [] }) => {
  const classes = useStyles()
  return (
    <div className={`${classes.colorCards} d-flex flex-wrap`}>
			{cards.map(card => <CardColorItem
				key={card.title}
				title={card.title}
				color={card.color}
				quantity={card.value}
				src={toAbsoluteUrl(card.icon)}
				name={card.name}
				onClick={card.onClick}
			/>)}
		</div>
  )
}

export default CardColorContainer
