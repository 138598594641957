import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

const TrashColumnIcon = ({ onClick, disabled, tooltip="Borrar borrador", color="danger" }) => {
    return (
        <OverlayTrigger
            overlay={<Tooltip id='request-tooltip'>{tooltip}</Tooltip>}
        >
            <button
                className={`btn btn-icon btn-${color} btn-sm btn-hover-${color}`}
                onClick={onClick}
                disabled={disabled}
            >
                <span className={`svg-icon svg-icon-md svg-icon-hover-${color}`}>
                    <SVG
                        className='file-icon'
                        src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')}
                    />
                </span>
            </button>
        </OverlayTrigger>
    );
};

export default TrashColumnIcon;