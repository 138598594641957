import { ASSETS_CONFIGURATION_URL } from "./helpers/routes"
import { getParams, handleError, handleResponse } from "./helpers/validate"


export const getConfRequest = () => {
  return fetch(`${ASSETS_CONFIGURATION_URL}/1/`, getParams('GET'))
  .then(handleResponse)
  .catch(handleError)
}

export const updateConfRequest = (data) => {
  return fetch(`${ASSETS_CONFIGURATION_URL}/1/`, getParams('PUT', data))
  .then(handleResponse)
  .catch(handleError)
}