import { handleError } from "../crud/helpers/validate"
export const downLoaderWithURL = (url, name) => {
    const a = document.createElement("a");
    a.href = url;
    a.target = '_blank';
    //a.download = name;
    a.setAttribute("download", name);
    a.click();
};

export const base64ToFile = (url, filename, mimeType) => {
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename, {type: mimeType})})
        .then((file) => downloadFile(file, filename))
        .catch(handleError)
    );
}

export const downloadFile = (file, filename) => {
    var link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.target = '_blank';
    link.setAttribute("download", filename)
    link.click();
}