import { useCallback, useEffect } from "react";
import { useLocation } from "react-router";

export const useEnterSearch = (callback) => {
	const { pathname } = useLocation();

	const handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter'){
			e.preventDefault();
            callback && callback();
		}
	}, [callback]);
    
    useEffect(() => {
		pathname.includes('busqueda') && document.addEventListener('keydown', handleKeyDown);
        return () => pathname.includes('busqueda') && document.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown, pathname]);
};