import { getProvidersAlmacen } from "../../../crud/catalogsCrud";
import { addEntryFilePDFAlmacen } from "../../../crud/entrancesStockCrud";

export const FROrdGaInputs = (
  requirements,
  statusEntrada,
  entrada_precarga,
  entrada_borrador,
  entrada_cerrar,
  closed,
  isNewStockEntrance,
  typeEntry
) => {
  let inputs = [
    {
      label: "Tipo de entrada",
      name: "tipo_entrada",
      type: "dropdown-chip",
      disabled: !isNewStockEntrance,
      required: true,
      options: [
        { value: "", text: "Seleccione un tipo" },
        { value: "STOCK", text: "Stock", color: "primary" },
        { value: "ORDINARIA", text: "Ordinaria", color: "info" },
        { value: "RECURRENTE", text: "Recurrente", color: "danger" },
        {
          value: "LIMPIEZA",
          text: "Mantenimiento y Limpieza",
          color: "warning",
        },
        {
          value: "FONDOREVOLVENTE",
          text: "Fondo revolvente",
          color: "primary",
        },
        {
          value: "GASTOSCOMPROBAR",
          text: "Gastos a comprobar",
          color: "success",
        },
      ],
    },
    {
      label: "Proveedor",
      name: "cf_ord_proveedor",
      type: "text",
      required: true,
      disabled: closed,
    },
    {
      label: "Contrato",
      name: "cf_ord_no_contrato",
      placeholder:
        typeEntry === "GASTOSCOMPROBAR"
          ? "Gatos a comprobar"
          : typeEntry === "FONDOREVOLVENTE"
          ? "Fondo revolvente"
          : "Contrato",
      type: "text",
      required: true,
      disabled:
        typeEntry === "GASTOSCOMPROBAR" || typeEntry === "FONDOREVOLVENTE"
          ? true
          : closed,
    },
    {
      label: "No. De requerimiento",
      name: "requirement",
      type: "dropdown",
      options: requirements,
      required: true,
      disabled: Boolean(requirements.length === 0) || closed,
    },
    {
      label: "Área solicitante",
      // placeholder:
      placeholder: "Área solicitante",
      name: "cf_ord_area_solicitante",
      type: "text",
      width: 278,
      disabled: closed,
    },
    {
      label: "Recibido",
      placeholder: "Almacen",
      name: "cf_recibido",
      type: "text",
      disabled: closed,
    },
    {
      label: "Fecha sello almacén",
      name: "cf_fecha_entrada",
      type: "date",
      required: true,
      disabled: closed,
    },
    {
      label: "Fecha sello factura",
      name: "cf_fecha_factura",
      type: "date",
      required: true,
      disabled:
        (closed && entrada_precarga) || (closed && entrada_borrador) ? true : false,
      // disable field if entry is in detail page and if is not precharged
    },
    {
      label: "No. de factura",
      name: "cf_no_factura",
      type: "text",
      required: true,
      disabled:
        (closed && entrada_precarga) || (closed && entrada_borrador) ? true : false,
    },
    {
      label: "Importe factura",
      name: "cf_importe_remision_factura",
      type: "text",
      isPrice: true,
      disabled: closed,
      // disable field if entry is in detail page
    },
    {
      label: "Fecha sello remisión",
      name: "cf_fecha_remision",
      type: "date",
      required: true,
      disabled: closed,
    },
    {
      label: "No. de remisión",
      name: "cf_no_remision",
      type: "text",
      required: true,
      disabled: closed,
    },
    {
      label: "Importe remisión",
      name: "cf_importe_remision",
      type: "text",
      isPrice: true,
      disabled: closed,
    },
    {
      label: "Estado",
      name: "entrada_cerrada",
      type: "dropdown-chip",
      required: true,
      disabled: true, // disable field if entry is in detail page and for now, only E type permitted
      options: [
        statusEntrada === "Afectada/Cerrada"
          ? {
              value: true,
              text: "Afectada/Cerrada",
              color: "danger",
            }
          : statusEntrada === "Cerrada"
          ? {
              value: true,
              text: "Cerrada",
              color: "success",
            }
          : statusEntrada === "Afectada/En proceso"
          ? {
              value: false,
              text: "Afectada/En proceso",
              color: "warning",
            }
          : statusEntrada === "En proceso"
          ? {
              value: false,
              text: "En proceso",
              color: "info",
            }
          : {
              value: true,
              text: "Abierto",
              color: "primary",
            },
      ],
    },
    {
      label: "Factura",
      name: "factura",
      type: "file-auto",
      required: false,
      uppercase: true,
      accept: "application/pdf",
      uploadFunction: addEntryFilePDFAlmacen,
      disabled: isNewStockEntrance ? true : false,
    },
    {
      label: "Remisión",
      name: "remision",
      type: "file-auto",
      required: false,
      uppercase: true,
      accept: "application/pdf",
      uploadFunction: addEntryFilePDFAlmacen,
      disabled: isNewStockEntrance ? true : false,
    },
  ];
  if (isNewStockEntrance) {
    inputs = inputs.filter(
      (input) => input.name !== "factura" && input.name !== "remision"
    );
  }
  return inputs;
};
