import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { cleanSearch, defaultArticleForm } from "../../utils/articlesData";
import { useEnterSearch } from "../../hooks/useEnterSearch";
import EntryActionIcons from "../../components/EntryActionIcons";
import ModeTabs from "../../components/ModeTabs";
import notice from "../../components/Notice";
import Chip from "../../components/Chip";
import {
  setArticle,
  setArticlesIds,
  setSearchArticleForm,
} from "../../../redux/ducks/articles.duck";
import { createNewArticle, editArticle, getArticlesList } from "../../crud/articlesCrud";
import { setLoader } from "../../../redux/ducks/loader.duck";
import { clearEmptyStringsObject } from "../../utils/objectsFunctions";

const ArticlesActions = () => {
  const {
    selectedArticle,
    selectedArticleIds,
    newArticleForm,
    searchArticleForm,
  } = useSelector((state) => state.article);
  const { isSearchPage, isResultsPage, isNewPage } = useSelector(
    (store) => store.pathname
  );
  const { label } = useSelector((store) => store.subHeaderActions);
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionDisable, setActionDisable] = useState(false);

  const handleResults = () => {
    if (selectedArticle.id) {
      history.push(
        `/inventario-bienes-almacen-general/articulos/resultados/${selectedArticle?.id}`
      );
    } else if (selectedArticleIds[0]) {
      history.push(
        `/inventario-bienes-almacen-general/articulos/resultados/${selectedArticleIds[0]}`
      );
    } else {
      notice(
        "No se encontraron registros guardados, pruebe realizando una búsqueda",
        "info"
      );
      history.push("/inventario-bienes-almacen-general/articulos/busqueda");
      dispatch(setSearchArticleForm(defaultArticleForm));
    }
  };

  const onNewRegister = () => {
    history.push("/inventario-bienes-almacen-general/articulos/nuevo");
    if (selectedArticle.id) dispatch(setArticle(defaultArticleForm));
  };

  const cleanObject = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  };

  const errorMessage = (data) => {
    let error = Object.keys(data)[0];
    let errorCauseBy = error.split("_").join(" ");
    return `${errorCauseBy} - ${data[error][0]}`;
  };

  const handleSubmit = () => {
    const newArticle = cleanObject({
      partida_presu: newArticleForm.partida_presu.text2,
      cve_articulo: newArticleForm.cve_articulo,
      num_seccion:
        newArticleForm.tipo_articulo !== "STOCK" ? null : newArticleForm.num_seccion,
      //num_almacen: newArticleForm.num_almacen,
      num_almacen: 2,
      state: newArticleForm.state,
      label: newArticleForm.label,
      cf_label_type: newArticleForm.cf_label_type,
      desc_corta: newArticleForm.desc_corta,
      desc_amplia: newArticleForm.desc_amplia,
      uni_distribuye: newArticleForm.uni_distribuye,
      // precio_unitario: newArticleForm.precio_unitario,
      iva_unitario: newArticleForm.iva_unitario,
      // exi_minimat: selectedArticle.exi_minimat,
      // exi_maximat: selectedArticle.exi_maximat,
      exi_minimax: newArticleForm.exi_minimax,
      exi_maximax: newArticleForm.exi_maximax,
      tipo_articulo: newArticleForm.tipo_articulo,
      inventariable: newArticleForm.inventariable,
      marca_id: newArticleForm.marca_id,
      fecalta: newArticleForm.fecalta,
      presentaciones: newArticleForm.presentaciones.map(
        ({ descripcion, equivalencia, estatus }) => ({
          id: null,
          descripcion,
          equivalencia,
          estatus,
        })
      ),
    });

    console.log(
      " -------------------------- newArticle -------------------------- ",
      newArticle
    );

    if (!newArticle.num_almacen)
      return notice("Debes ingresar Clave del almacén", "warning");
    else if (newArticleForm.tipo_articulo === "STOCK" && !newArticle.num_seccion)
      return notice("Debes ingresar Clave de la sección", "warning");
    else if (!newArticle.partida_presu)
      return notice("Debes ingresar Partida presupuestal", "warning");
    // else if (!newArticle.precio_unitario)
    //   return notice("Debes ingresar el precio unitario", "warning");
    else if (!newArticle.iva_unitario?.toString())
      return notice("Debes ingresar el IVA", "warning");
    else dispatch(setLoader(true));
    createNewArticle(newArticle)
      .then((res) => {
        if (res.status === 201) {
          notice("Artículo creado correctamente", "success");
          history.push(
            `/inventario-bienes-almacen-general/articulos/resultados/${res.data.id}`
          );
        }
      })
      .catch((e) => {
        const { data } = e.response;
        notice(errorMessage(data));
      })
      .finally(() => dispatch(setLoader(false)));
  };

  const handleEdit = () => {
    const payload = cleanObject({
      partida_presu: selectedArticle.partida_presu.text2,
      cve_articulo: selectedArticle.cve_articulo,
      num_seccion:
        selectedArticle.tipo_articulo !== "STOCK" ? null : selectedArticle.num_seccion,
      num_almacen: 2,
      // num_almacen: selectedArticle.num_almacen,
      state: selectedArticle.state,
      label: selectedArticle.label,
      cf_label_type: selectedArticle.cf_label_type,
      desc_corta: selectedArticle.desc_corta,
      desc_amplia: selectedArticle.desc_amplia,
      uni_distribuye: selectedArticle.uni_distribuye,
      // precio_unitario: selectedArticle.precio_unitario,
      iva_unitario: selectedArticle.iva_unitario,
      // exi_minimat: selectedArticle.exi_minimat,
      // exi_maximat: selectedArticle.exi_maximat,
      exi_minimax: selectedArticle.exi_minimax,
      exi_maximax: selectedArticle.exi_maximax,
      tipo_articulo: selectedArticle.tipo_articulo,
      inventariable: selectedArticle.inventariable,
      marca_id: selectedArticle.marca_id,
      fecalta: selectedArticle.fecalta,
      presentaciones: selectedArticle.presentaciones.map(
        ({ id, descripcion, equivalencia, estatus }) => ({
          id,
          descripcion,
          equivalencia,
          estatus,
        })
      ),
    });
    if (!payload.num_almacen)
      return notice("Debes ingresar Clave del almacén", "warning");
    else if (selectedArticle.tipo_articulo === "STOCK" && !payload.num_seccion)
      //TODO .- Mover validación con el campo de Tipo de Bien
      return notice("Debes ingresar Clave de la sección", "warning");
    else if (!payload.partida_presu)
      return notice("Debes ingresar Partida presupuestal", "warning");
    // else if (!payload.precio_unitario)
    //   return notice("Debes ingresar el precio unitario", "warning");
    else if (!payload.iva_unitario?.toString())
      return notice("Debes ingresar el IVA", "warning");
    dispatch(setLoader(true));
    editArticle(payload, selectedArticle.id)
      .then((res) => {
        notice("Artículo actualizado correctamente", "success");
      })
      .catch((e) => {
        const { data } = e.response;
        if (data instanceof Array) {
          notice(data[0], "error");
        } else {
          const { data } = e.response;
          notice(errorMessage(data));
        }
      })
      .finally(() => dispatch(setLoader(false)));
  };

  const handleSearch = () => {
    setActionDisable(true);
    dispatch(setLoader(true));
    getArticlesList(clearEmptyStringsObject(cleanSearch(searchArticleForm)))
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(setArticlesIds(res.data.results.map((art) => art.id)));
          history.push(
            `/inventario-bienes-almacen-general/articulos/resultados/${res.data.results[0].id}`
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setActionDisable(false);
        dispatch(setLoader(false));
      });
  };

  useEnterSearch(handleSearch);

  return (
    <>
      {isResultsPage && (
        <Chip label={label} color="#E52441" style={{ marginRight: 10 }} />
      )}
      <ModeTabs
        onSearch={() => {
          dispatch(setSearchArticleForm(defaultArticleForm));
          history.push("/inventario-bienes-almacen-general/articulos/busqueda");
        }}
        onNew={onNewRegister}
        onResults={handleResults}
      />
      <EntryActionIcons
        showIcons={{
          save: !isSearchPage && isNewPage,
          edit: !isSearchPage && selectedArticle?.id && isResultsPage && !isNewPage,
          search: isSearchPage,
        }}
        handleSubmit={handleSubmit}
        handleEdit={handleEdit}
        handleSearch={handleSearch}
        labels={{
          save: "Nuevo artículo",
          edit: "Guardar cambios",
        }}
        disabledIcons={{
          save: actionDisable,
          edit: actionDisable,
          search: actionDisable,
        }}
      />
    </>
  );
};

export default ArticlesActions;
