import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultContractsFilter, defaultContractsForm } from "../../app/utils/contractsData";
import uuid from "react-uuid";

export const actionType = {
  SetContract: '[CONTRACT] SET_CONTRACT',
  SetFilteredContract: '[CONTRACT] SET_FILTERED_CONTRACT',
  SetSelectedIds: '[CONTRACT] SET_SELECTED_IDS',
  SetLoading: '[CONTRACT] SET_LOADING',
  ClearContract: '[CONTACT] CLEAR_CONTRACT',
  SetFilesToUpload: "[CONTRACT] SET_FILES_TO_UPLOAD"
};

export const initialState = {
  contract: defaultContractsForm,
  filteredContract: defaultContractsFilter,
  selectedContractIds: [],
  loading: false,
  filesToUpload: []
};

export const reducer = persistReducer(
  { storage, key: 'contract', whitelist: ["contract", "filteredContract", "selectedContractIds"] },
  (state = initialState, action) => {
    switch (action.type) {

      case actionType.SetContract:
        return { ...state, contract: action.payload };

      case actionType.SetFilteredContract:
        return { ...state, filteredContract: action.payload };

      case actionType.SetSelectedIds:
        return { ...state, selectedContractIds: action.payload };

      case actionType.SetLoading:
        return { ...state, loading: action.payload };

      case actionType.ClearContract:
        return {
          ...initialState,
          contract: {
            ...defaultContractsForm,
            contract_set: [
              {
                id: uuid(),
                budget_start: '',
                minimum_amount: 0,
                maximum_amount: 0,
                min_import: 0,
                max_import: 0,
                progress: 0,
                available_quantity: 0,
                set_item: [{
                  id: uuid(),
                  cons: 1,
                  cve_article: "",
                  article: "",
                  unit_measurement: "",
                  unit_price: "",
                  articulo: "",
                  presentaciones: [],
                  marca: null,
                  stock_available: null,
                }]
              }
            ]
          }
        };

      case actionType.SetFilesToUpload:
        return {...state, filesToUpload: action.payload}

      default:
        return state
    }
  }
)

export const setContract = data => ({ type: actionType.SetContract, payload: data });

export const setFilteredContract = data => ({ type: actionType.SetFilteredContract, payload: data });

export const setContractIds = data => ({ type: actionType.SetSelectedIds, payload: data });

export const setLoading = (isLoading) => ({ type: actionType.SetLoading, payload: isLoading });

export const clearContract = () => ({ type: actionType.ClearContract });

export const setFilesToUpload = (files) => ({type: actionType.SetFilesToUpload, payload: files});
