import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BaseModal from "../../components/BaseModal";
import EntryActionIcons from "../../components/EntryActionIcons";
import {
  createDepartureById,
  createOrdinaryDeparture,
  createRecurrentDeparture,
  listReqDepartures,
} from "../../crud/departuresCrud";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";
import {
  setDeparture,
  setOrdinary,
  setRecurrent,
  setSelectedDepartures,
} from "../../../redux/ducks/departure.duck";
import { setLoader } from "../../../redux/ducks/loader.duck";
import {
  defaultOdinaryDeparture,
  defaultRecurrentDeparture,
} from "../../utils/departureData";
import notice from "../../components/Notice";

const DepartureActions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    departure: { observaciones, id },
    searchDeparture,
    recurrent,
    ordinary,
  } = useSelector((state) => state.departure);
  const {
    isSearchPage,
    isNewPage,
    isDepartureRecurrentDetailsPage,
    isDepartureOrdinaryDetailsPage,
  } = useSelector((store) => store.pathname);
  const [modal, setModal] = useState(false);

  const createRecurrent = () => {
    dispatch(setLoader(true));
    let payload = {
      ...recurrent,
      outbound_recurrent_details: recurrent.outbound_recurrent_details.map(
        ({ articulo_recurrente_id, cantidad }) => ({
          articulo_recurrente_id,
          cantidad,
        })
      ),
    };
    if (payload.atiende_folio_dgrmsg && !payload.folio_dgrmsg) {
      notice("Es necesario agregar folio");
      dispatch(setLoader(false));
      return;
    }
    if (payload.outbound_recurrent_details.length === 0) {
      notice("Es necesario agregar al menos un artículo");
      dispatch(setLoader(false));
      return;
    }
    createRecurrentDeparture(payload)
      .then((res) => {
        if (res.pdf_url) window.open(res.pdf_url, "_blank");
        dispatch(setRecurrent(defaultRecurrentDeparture));
        history.push("/inventario-bienes-almacen-general/salidas/recurrentes");
      })
      .catch((error) => {
        notice(error.errors, "error");
        dispatch(setLoader(false));
      })
      .finally(() => dispatch(setLoader(false)));
  };

  const createOrdinary = () => {
    dispatch(setLoader(true));
    if (ordinary.atiende_folio_dgrmsg && !ordinary.folio_dgrmsg) {
      notice("Es necesario agregar folio");
      dispatch(setLoader(false));
      return;
    }
    createOrdinaryDeparture(ordinary)
      .then((res) => {
        if (res.pdf_url) window.open(res.pdf_url, "_blank");
        dispatch(setOrdinary(defaultOdinaryDeparture));
        history.push("/inventario-bienes-almacen-general/salidas/ordinarias");
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  };

  const handleLockDeparture = () => {
    createDepartureById(id, { observaciones }) // by the moment, only observations field will send
      .then((res) => {
        history.push("/inventario-bienes-almacen-general/salidas/stock");
      })
      .catch(console.log);
  };

  const handleSearch = () => {
    dispatch(setLoader(true));
    listReqDepartures(
      1,
      100,
      filterParams(clearEmptyStringsObject(cleanObject(searchDeparture)))
    )
      .then((res) => {
        dispatch(setSelectedDepartures(res.results.map(({ id }) => id)));
        dispatch(setDeparture(res.results[0]));
        history.push(
          `/inventario-bienes-almacen-general/salidas/resultados/${res.results[0].id}`
        );
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  };

  return (
    <>
      <div className="w-100 d-flex justify-content-end">
        <EntryActionIcons
          showIcons={{
            save:
              isNewPage &&
              (isDepartureRecurrentDetailsPage ||
                isDepartureOrdinaryDetailsPage),
            search: isSearchPage,
          }}
          handleSubmit={() =>
            isDepartureRecurrentDetailsPage
              ? createRecurrent()
              : isDepartureOrdinaryDetailsPage
              ? createOrdinary()
              : setModal(true)
          }
          handleSearch={handleSearch}
        />
      </div>
      <BaseModal
        open={modal}
        title="¿Estás seguro de marcar esta salida como procesada?"
        onClose={() => setModal(false)}
        onConfirm={handleLockDeparture}
        onCancel={() => setModal(false)}
        cancelLabel="No"
        maxWidth="sm"
        color="secondary"
        confirmLabel="Si, continuar"
      >
        Al marcar la salida como procesada, se disminuirá el stock de todos los
        artículos de la solicitud
      </BaseModal>
    </>
  );
};

export default DepartureActions;
