import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as requestService from "../../app/crud/requestCrud";
import { defaultRequestFilter, defaultRequestForm } from "../../app/utils/materialRequestData";

export const actionType = {
  SetRequests: "[REQUESTS] SET_REQUESTS",
  SetReload: "[REQUESTS] SET_RELOAD",
  SetSelectedRequest: "[REQUESTS] SET_SELECTED_REQUEST",
  ClearSelectedRequest: "[REQUESTS] CLEAR_SELECTED_REQUEST",
  SetSelectedItems: "[REQUESTS] SET_SELECTED_ITEMS",
  SetDrafts: "[REQUESTS] SET_DRAFTS",
  SetDraft: "[REQUESTS] SET_DRAFT",
  SetSelectedDraft: "[REQUESTS] SET_SELECTED_DRAFT",
};

export const initialState = {
    request: {
      results: [],
      count: 0,
      page: 1,
      per_page: 20
    },
    filter: defaultRequestFilter,
    selectedRequestIds: [],
    reload: false,
    selectedRequest: defaultRequestForm,
    currentRequestId: null,
    selectedItems: [],
    newItems: [],
    isSearchTab: false,
    isResultsTab: false,
    isNewTab: false,
    isDraftTab: false,
    newRequest: defaultRequestFilter,
    drafts: [],
    selectedDraft: null,
    selectedDrafts: [],
};

export const GET_REQUESTS = createAsyncThunk(
  "[REQUESTS]/GET_REQUESTS",
  async (args, { getState, rejectWithValue, dispatch }) => {
    const { request } = getState().request;
    let payload = null;

    try {

      const result = (await requestService.listRequests(
        args.page ? args.page : initialState.request.page,
        args.per_page ? args.per_page : initialState.request.per_page,
        args.mode === "admin" && "cf_estatus=D,G"
      ));

      if (result?.data) {
        payload = result.data;
        if (request.page !== args.page) dispatch(setPage(args.page));
        if (request.per_page !== args.per_page) dispatch(setSizePerPage(args.per_page));
        dispatch(setReload(false))
      } else payload = rejectWithValue({ error: "Error" });
    } catch (e) {
      payload = rejectWithValue({ error: "Error" });
      console.log(e)
    }
    return payload;

  }
);

export const GET_REQUEST_PDF = createAsyncThunk(
  "[REQUESTS]/GET_REQUEST_PDF",
  async (requestId, { rejectWithValue }) => {
    let payload = null;
    const result = (await requestService.requestGetPdf(requestId));
    if (result?.data) {
      payload = result.data;
    } else payload = rejectWithValue({ attachment_url: false });
    return payload;
  }
);


export const SEND_EMAIL = createAsyncThunk(
  "[REQUEST]/SEND_EMAIL",
  async (id, { rejectWithValue }) => {
    let payload = null;
    const result = (await requestService.requestSendEmail(id));
    if (result?.status === 200) {
      payload = result.data;
    } else payload = rejectWithValue({ error: "Error" });

    return payload;
  }
);

export const CANCEL_REQUEST = createAsyncThunk(
  "[REQUEST]/CANCEL_REQUEST",
  async(id, {rejectWithValue, dispatch}) => {
    let payload = null;
    try {
      dispatch(setLoading(true));
      const result = (await requestService.cancelRequest(id));
      if (result?.status === 200) {
        payload = result.data;
      } else payload = rejectWithValue({ error: "Error" });

      return payload;
    }catch(error) {
      rejectWithValue({ error: "Error"});
    }finally{
      dispatch(setLoading(false));
      return payload;
    };
  }
);

export const DELETE_REQUEST = createAsyncThunk(
  "[REQUEST]/DELETE_REQUEST",
  async(id, {rejectWithValue, dispatch}) => {
    let payload = null;
    try{
      dispatch(setLoading(true));
      const res = (await requestService.deleteRequest(id));
      const { status } = res;
      if([200, 201, 204].includes(status)){
        payload = res.data
      }else{
        rejectWithValue({ error: "Error"})
      };
    }catch(error) {
      rejectWithValue({ error: "Error"});
    }finally{
      dispatch(setLoading(false));
      return payload;
    };
  }
);


export const EDIT_REQUEST = createAsyncThunk(
  "[REQUEST]/DELETE_REQUEST",
  async(args, { rejectWithValue, dispatch }) => {
    let payload = null;
    try{
      dispatch(setLoading(true));
      const {id, body} = args;
      const res = (await requestService.editRequest(body, id));
      const { status } = res;
      if([200, 201, 204].includes(status)){
        payload = res.data
      }else{
        rejectWithValue({ error: "Error"});
      };
    }catch(err){
      rejectWithValue({ error: "Error"});
    }finally{
      dispatch(setLoading(false));
      return payload;
    }
  }
);

export const requestSlice = createSlice({
  name: "[REQUESTS]",
  initialState,
  reducers: {
    setRequests: {
      reducer: (state, action) => {
        state.request.results = action.payload;
      },
      prepare: (current) => ({ payload: current })
    },
    setPage: {
      reducer: (state, action) => {
        state.request.page = action.payload;
      },
      prepare: (current) => ({ payload: current })
    },
    setSizePerPage: {
      reducer: (state, action) => {
        state.request.per_page = action.payload;
      },
      prepare: (pageSize) => ({ payload: pageSize })

    },
    setSelectedItems: {
      reducer: (state, action) => {
        state.selectedItems = action.payload;
      }
    },
    setNewItems: {
      reducer: (state, action) => {
        state.newItems = action.payload;
      }
    },
    setSelectedRequest: {
      reducer: (state, action) => {
        state.selectedRequest = action.payload;
      }
    },
    setFilterRequest: {
      reducer: (state, action) => {
        state.filter = action.payload;
      }
    },
    setNewRequest: {
      reducer: (state, action) => {
        state.newRequest = action.payload;
      }
    },
    setClearSelectedRequest: {
      reducer: (state) => {
        state.selectedRequest = initialState.selectedRequest;
        state.selectedItems = initialState.selectedItems;
      }
    },
    clearState: {
      reducer: (state) => {
        state.request = initialState.request
        state.filter = initialState.filter
        state.selectedRequestIds = initialState.selectedRequestIds
        state.reload = initialState.reload
        state.selectedRequest = initialState.selectedRequest
        state.currentRequestId = initialState.currentRequestId
        state.selectedItems = initialState.selectedItems
        state.newItems = initialState.newItems
        state.currentRequestInputsFields = initialState.currentRequestInputsFields
        state.isSearchTab = initialState.isSearchTab
        state.isResultsTab = initialState.isResultsTab
        state.isNewTab = initialState.isNewTab
        state.newRequest = initialState.newRequest
        // NOT RESET DRAFTS STORE
      }
    },
    updateRequest: {
      reducer:(state, action) => {
        const {id, ...others} = action.payload;
        state.request.results = state.request.results.map((request) => {
          let nRequest = {...request};
          if (id === request.id) nRequest = {...nRequest, ...others};
          return nRequest;
        })
      }
    },
    setFieldFilter: {
      reducer: (state, action) => {
        const {fields} = state.filter;
        state.filter = {...fields, ...action.payload};
      }
    },
    resetFieldsFilter: {
      reducer: (state) => {
        state.filter = initialState.filter;
      }
    },
    setRequestsIds: {
      reducer: (state, action) => {
        state.selectedRequestIds = action.payload
      }
    },
    setLoading: {
      reducer: (state, action) => {
        state.loading = action.payload;
      }
    },
    setCurrentRequestTab: {
      reducer: (state, action) => {
        state.isSearchTab = action.payload.isSearchTab;
        state.isResultsTab = action.payload.isResultsTab;
        state.isNewTab = action.payload.isNewTab;
        state.isDraftTab = action.payload.isDraftTab;
      }
    },
    setReload: {
      reducer: (state, action) => {
        state.reload = action.payload;
      }
    },
    setGoodQuantities: {
      reducer: (state, action) => {
        state.goodsQuantities = action.payload;
      }
    },
    setDrafts: {
      reducer: (state, action) => {
        state.drafts = action.payload;
      }
    },
    setSelectedDraft: {
      reducer: (state, action) => {
        state.selectedDraft = action.payload;
      }
    },
    setSelectedDrafts: {
      reducer: (state, action) => {
        state.selectedDrafts = action.payload;
      }
    },
  },
  extraReducers: {
    [GET_REQUESTS.pending]: (state, action) => {
      const { requestId } = action.meta;
      state.reload = true;
      state.currentRequestId = requestId;
    },
    [GET_REQUESTS.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.currentRequestId === requestId) {
        const { results, count, per_page } = action.payload;
        state.request.results = results;
        state.request.count = count;
        state.request.per_page = per_page;
        state.reload = false;
        state.currentRequestId = null;
      }
    },
    [GET_REQUESTS.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.currentRequestId === requestId) {
        const { per_page } = initialState.request;
        state.request.per_page = per_page;
        state.reload = false;
        state.currentRequestId = null;
      }
    },
    [GET_REQUEST_PDF.pending]: (state, action) => {
      const { requestId } = action.meta;
      state.currentRequestId = requestId;
      state.reload = true;
    },
    [GET_REQUEST_PDF.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.currentRequestId === requestId) {
        state.reload = false;
        state.currentRequestId = null;
      }
    },
    [GET_REQUEST_PDF.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.currentRequestId === requestId) {
        state.reload = false;
        state.currentRequestId = null;
      }
    },
    [SEND_EMAIL.pending]: (state, action) => {
      const { requestId } = action.meta;
      state.reload = true;
      state.currentRequestId = requestId;
    },
    [SEND_EMAIL.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.currentRequestId === requestId) {
        state.reload = false;
        state.currentRequestId = null;
      }
    },
    [SEND_EMAIL.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.currentRequestId === requestId) {
        state.reload = false;
        state.currentRequestId = null;
      }
    },
     [CANCEL_REQUEST.pending]: (state, action) => {
       const { requestId } = action.meta;
       state.reload = true;
       state.currentRequestId = requestId;
     } ,
     [CANCEL_REQUEST.fulfilled]: (state, action) => {
       const { requestId } = action.meta;
       if (state.currentRequestId === requestId) {
         state.reload = false;
         state.currentRequestId = null;
       }
     } ,
     [CANCEL_REQUEST.rejected]: (state, action) => {
       const { requestId } = action.meta;
       if (state.currentRequestId === requestId) {
         state.reload = false;
         state.currentRequestId = null;
       }
     }
  }
});

export const {
  setPage,
  setSizePerPage,
  setSelectedItems,
  setSelectedRequest,
  setClearSelectedRequest,
  clearState,
  updateRequest,
  setFieldFilter,
  resetFieldsFilter,
  setRequestsIds,
  setLoading,
  setCurrentRequestTab,
  setReload,
  setNewRequest,
  setFilterRequest,
  setGoodQuantities,
  setNewItems,
  setDrafts,
  setSelectedDraft,
  setSelectedDrafts,
} = requestSlice.actions;

export const requestSelector = (store) => store.request;

export default persistReducer({ storage, key: "requests", whitelist: ["selectedRequestIds", "selectedRequest", "drafts", "selectedDraft", "selectedDrafts"] }, requestSlice.reducer);

export const setRequests = requests => ({ type: actionType.SetRequests, payload: requests });

export const clearSelectedRequest = () => ({ type: actionType.ClearSelectedRequest });
