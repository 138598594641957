import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { initialStockValues } from "../../app/utils/stockData";

export const actionType = {
    SetStock: '[Stock] SET_STOCK',
    SetStockDrops: '[Stock] SET_STOCK_DROPS',
    SetSelectedStocks: '[Stock] SET_SELECTED_STOCKS',
    SetIdsFilterResult: '[Stock] SET_IDS_FILTER_RESULT',
    SetSelectedStock: '[Stock] SET_SELECTED_STOCK',
    ClearSelectedStock: '[Stock] CLEAR_SELECTED_STOCK',
    SetSearchStockForm: '[Stock] SET_SEARCH_STOCK_FORM',
    SetStockFormDataAvailableToCopy: '[Stock] SET_STOCK_DATA_TO_COPY',
    ClearStockFormDataAvailableToCopy: '[Stock] CLEAR_STOCK_DATA_TO_COPY',
    SetInputsValuesCopiedToSelectedStocks: '[Stock] SET_INPUTS_COPIED_TO_STOCKS',
    SetToMultipleEdit: '[Stock] SET_TO_MULTIPLE_EDIT',
    SetStockFieldsToUpdate: '[Stock] SET_STOCK_FIELDS_TO_UPDATE',
    ClearFieldsToUpdate: '[Stock] CLEAR_FIELDS_TO_UPDATE',
};

export const initialState = {
  stock: {
    results: [],
    count: 0,
    page: 1,
    per_page: 50,
    ids: []
  },
  stockDrop: {
    results: [],
    count: 0,
    page: 1,
    per_page: 50
  },
  selectedStocks: [],
  setToMultipleEdit: [],
  idsFilterResult: [],
  selectedStockForm: initialStockValues,
  searchStockForm: initialStockValues,
  stockFormAvailableToCopy: {},
  stockFieldsToUpdate: {},
};

export const reducer = persistReducer(
  { storage, key: 'stock', whitelist: ['selectedStocks', 'selectedStock', 'paginateInfo'] },
  (state = initialState, action) => {
    switch (action.type) {

      case actionType.SetStock:
        return { ...state, stock: action.payload };

      case actionType.SetStockDrops:
        return { ...state, stockDrop: action.payload };

      case actionType.SetSelectedStocks:
        return { ...state, selectedStocks: action.payload };

      case actionType.SetToMultipleEdit:
        const { idToMultipleEdit, labelToMultipleEdit, valueToMultipleEdit } = action.payload;
        return { ...state, setToMultipleEdit: { 
          ...state.setToMultipleEdit, 
          idToMultipleEdit, 
          [labelToMultipleEdit]: valueToMultipleEdit  } };
      
      case actionType.SetIdsFilterResult:
        return { ...state, idsFilterResult: action.payload }
      
      case actionType.SetSelectedStock:
        
        return { ...state, selectedStockForm: action.payload };
      
      case actionType.SetSearchStockForm:
        return { ...state, searchStockForm: action.payload };
            
      case actionType.ClearSelectedStock:
        return { ...state, selectedStocks: initialState.selectedStocks, selectedStock: initialState.selectedStock, searchStockForm: initialState.searchStockForm};
      
      case actionType.SetStockFormDataAvailableToCopy:
        const { name, label, value } = action.payload;
        return { ...state, stockFormAvailableToCopy: { ...state.stockFormAvailableToCopy, [name]: {name, label, value} } }

      case actionType.SetInputsValuesCopiedToSelectedStocks:
        let newValues = [];
        const stocks = state.selectedStocks.filter(stock => stock.id !== state.selectedStockForm.id);

        action.payload.map(
          ( input ) => {
            return newValues = [ ...newValues, state.stockFormAvailableToCopy[input] ];
          }
        );

        var inputsValuesToObject = newValues.reduce(
          (obj, item) => Object.assign(obj, { [item.name]: item.value }), {});

        return { 
          ...state, 
          selectedStocks: [
            state.selectedStockForm,
            ...stocks.map( (stock) => ({ ...stock, ...inputsValuesToObject }) )
          ],
          stockFormAvailableToCopy: initialState.stockFormAvailableToCopy
        }
      
      case actionType.SetStockFieldsToUpdate:

        return { ...state, stockFieldsToUpdate: action.payload }

      case actionType.ClearStockFormDataAvailableToCopy:
        return { ...state, stockFormAvailableToCopy: initialState.stockFormAvailableToCopy }

      case actionType.ClearFieldsToUpdate:
        return { ...state, stockFieldsToUpdate: initialState.stockFieldsToUpdate }

      default:
        return state;
    };
  }
);

export const setStock = stock => ({ type: actionType.SetStock, payload: stock });

export const setStockDrops = stock => ({ type: actionType.SetStockDrops, payload: stock });

export const setSelectedStocks = selectedStocks => ({ type: actionType.SetSelectedStocks, payload: selectedStocks });

export const setIdsFilterResult = ids => ({ type: actionType.SetIdsFilterResult, payload: ids });

export const setSelectedStock = selectedStock => ({ type: actionType.SetSelectedStock, payload: selectedStock });

export const setSearchStockForm = searchForm => ({ type: actionType.SetSearchStockForm, payload: searchForm });

export const clearSelectedStocks = () => ({ type: actionType.ClearSelectedStock });

export const setStockDataAvailableToCopy = data => ({ type: actionType.SetStockFormDataAvailableToCopy, payload: data });

export const setCopyInputsValuesToSelectedStocks = inputs => ({
  type: actionType.SetInputsValuesCopiedToSelectedStocks,
  payload: inputs
});

export const clearStockFormDataAvailableToCopy = () => ({ type: actionType.ClearStockFormDataAvailableToCopy });

export const setToMultipleEdit = (stock) => ({ type: actionType.SetToMultipleEdit, payload: stock  });

export const setStockFieldsToUpdate = (data) => ({ type: actionType.SetStockFieldsToUpdate, payload: data  });
export const clearFieldsToUpdate = () => ({ type: actionType.ClearFieldsToUpdate  });