import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FullTable from "../../components/FullTable";
import {
  columns,
  defaultPledgegGoodsFilters,
  filterInputs,
} from "../../utils/pledgedGoodsData";
import {
  setPledgedGoods,
  setPage,
  setSizePerPage,
} from "../../../redux/ducks/pledgedGood.duck";
import {
  getArticlesList,
  getPledgedGoodArticles,
} from "../../crud/articlesCrud";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";
import notice from "../../components/Notice";
import ArticleCard from "./components/ArticleCard";

const PledgedGoodsPage = () => {
  const {
    pledgedGoods: { results, count, page, sizePerPage },
  } = useSelector((store) => store.pledgedGood);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [formFilters, setFormFilters] = useState(defaultPledgegGoodsFilters);
  const [connsultedArticle, setConsultedArticle] = useState(false);
  const [article, setArticle] = useState(null);

  const handleFilters = (e) => {
    const { name, value } = e.target;
    setFormFilters({ ...formFilters, [name]: value });
  };

  const getFilterParams = useCallback(
    () => filterParams(clearEmptyStringsObject(cleanObject(formFilters))),
    [formFilters]
  );

  const onConfirmFilter = async () => {
    try {
      setLoading(true);
      const { data } = await getArticlesList(formFilters);
      let art = data.results.find(
        (x) => x.cve_articulo === Number(formFilters.cve_articulo)
      );
      setConsultedArticle(true);
      if (data.results.length && art) {
        setArticle(art);
        getPledgedGoodArticles(page, sizePerPage, getFilterParams())
          .then((res) => {
            if (res.status === 200) {
              const { results, count } = res.data;
              dispatch(
                setPledgedGoods({
                  results,
                  count,
                })
              );
            }
          })
          .catch((err) => {
            console.log("err", err);
            notice("Ha ocurrido un error de conexión con el servidor", "error");
          })
          .finally(() => setLoading(false));
      } else {
        setArticle(undefined);
        const results = [];
        const count = 0;
        dispatch(
          setPledgedGoods({
            results,
            count,
          })
        );
      }
    } catch (error) {
      console.log(error);
      notice("Ha ocurrido un error de conexión con el servidor", "error");
    } finally {
      setLoading(false);
    }
  };

  const onClearFilter = async () => {
    setConsultedArticle(false);
    setFormFilters(defaultPledgegGoodsFilters);
    setLoading(true);
    const request = await getPledgedGoodArticles(page, sizePerPage);
    if (request?.status === 200 && request?.data) {
      const { results, count } = request.data;
      dispatch(
        setPledgedGoods({
          results,
          count,
        })
      );
    }
    setLoading(false);
  };

  const onEye = (id) => console.log("eye", id);
  const onLock = (id) => console.log("lock", id);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const request = await getPledgedGoodArticles(
        page,
        sizePerPage,
        getFilterParams()
      );
      if (request?.status === 200 && request?.data) {
        const { results, count } = request.data;
        dispatch(
          setPledgedGoods({
            results,
            count,
          })
        );
      }
      setLoading(false);
    })();
  }, [page, sizePerPage]); // eslint-disable-line

  return (
    <>
      {connsultedArticle && <ArticleCard article={article} />}
      <FullTable
        hideCheckbox
        search_button={false}
        has_filter
        title="Bienes comprometidos"
        has_button={false}
        filterInputs={filterInputs}
        onFilterChange={handleFilters}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
        formFilters={formFilters}
        columns={columns({ onLock, onEye })}
        entities={results}
        count={count}
        page={page}
        setPage={(page) => page > 0 && dispatch(setPage(page))}
        sizePerPage={sizePerPage}
        setSizePerPage={(sizePage) => dispatch(setSizePerPage(sizePage))}
        loading={loading}
      />
    </>
  );
};

export default PledgedGoodsPage;
