import React from 'react'
import EyeColumnIcon from './list-icons/EyeColumnIcon'
import LockColumnIcon from './list-icons/LockColumnIcon'
import FolderLockColumnIcon from './list-icons/FolderLockColumnIcon'

export const ToDoBoardActionsColumn = ( value, row, rowIndex, { onStore, onSeeTask, onSelectBoard, user, isVisitedStoredBoards } ) => {

  const userCanLockTask = ( user, row ) => {
    return user.id === row.user_in_charge || user.id === row.user_in_charge2 ? true : false
  }

  return (
    <>
      <EyeColumnIcon onClick={() => onSelectBoard(value)} tooltip='Ver tareas' />
      <FolderLockColumnIcon onClick={() => onSeeTask(value)} tooltip='Ver tareas archivadas' color='light-primary' />
      {(isVisitedStoredBoards || userCanLockTask(user, row)) && 
        <LockColumnIcon onClick={() => onStore(row)} tooltip='Archivar tablero' />
      }
    </>
  )
}
