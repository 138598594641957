import React from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const SimpleCheckbox = ({ label, labelPos="top", name, value, onChange, disabled=false }) => {
  return (
    <FormControlLabel
      disabled={disabled}
      label={<span className="font-weight-bold">{label}</span>}
      labelPlacement={labelPos}
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
      control={<Checkbox
        disabled={disabled}
        onChange={e => onChange({target: { name, value: e.target.checked }})}
        checked={value}
        name={name}
        color="secondary"
      />}
    />
  )
}

export default SimpleCheckbox
