import React, { useState, useEffect } from 'react'
import notice from '../../components/Notice'
import PageLayout from '../../components/PageLayout'
import UtilInputs from '../../components/UtilInputs'
import FileButton from '../../components/FileButton'
import CustomButton from '../../components/CustomButton'
import AttachmentsList from '../../components/AttachmentsList'
import { addDigitalVaultFiles, digitalizationWithModule, fetchDigitalVaultFolders } from '../../crud/digitalVaultCrud'
import { getInventoryList } from '../../crud/inventoryCrud'
import { fetchStockParams } from '../../crud/stockCrud'
import { getAssetAdq } from '../../crud/entryCrud'
import { defaultDoc, docForm } from './utils'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../../redux/ducks/loader.duck'

const DigitalizationDocs = () => {
  const dispatch = useDispatch()
  const [doc, setDoc] = useState(defaultDoc)
  const [options, setOptions] = useState([])
  const [optionsLoader, setOptionsLoader] = useState(true)
  const [folderOptions, setFolderOptions] = useState([])
  const [subfolderOptions, setSubfolderOptions] = useState([])

  useEffect(() => {
    fetchDigitalVaultFolders().then(res => setFolderOptions(res.map(folder => ({ ...folder, value: folder.id, text: folder.name }))))
  }, [])

  useEffect(() => {
    setOptionsLoader(true)
    switch (doc.module) {
      case "asset_acquisition":                     // ENTRADAS
        getAssetAdq(20, 1)
        .then(res => {
          if(res && res.results) {
            setOptions(res.results.map(entry => ({ text: `Entrada ${entry.id}`, value: entry.id })));
          };
        }).catch(console.log).finally(() => setOptionsLoader(false))
        break;
      case "inventory_take":                     // LEVANTAMIENTO DE INVENTARIO
        getInventoryList({ page: 1, limit: 20, order: "desc"})
        .then(res => {
          if (res.status === 200 && res.data)
            setOptions(res.data.results.map(survey => ({ text: `Levantamiento ${survey.last_inventory_take}`, value: survey.last_inventory_take })));
        }).catch(console.log).finally(() => setOptionsLoader(false))
        break;
      case "inventory":                     // INVENTARIO
        fetchStockParams(1, 20)
        .then(res => {
          if(res && res.results) {
            setOptions(res.results.map(stock => ({ text: `Inventario ${stock.id}`, value: stock.id })));
          };
        }).catch(console.log).finally(() => setOptionsLoader(false))
        break;
    
      default:
        break;
    }
  }, [doc.module])

  const handleChange = ({ target }) => {
    const { name, value } = target
    if (name === "folder") {
      let index = folderOptions.findIndex(f => f.value === value)
      setSubfolderOptions(folderOptions[index]?.subfolders?.map(sf => ({ ...sf, value: sf.id, text: sf.name })))
    }
    setDoc({...doc, [name]: value})
  }

  const handleAttachments = (files) => setDoc({...doc, attachments: files})

  const handleRemoveAttachment = (index) => {
    const updatedAtt = doc.attachments
    updatedAtt.splice(index, 1)
    setDoc({...doc, attachments: [...updatedAtt]})
  }

  const onSubmit = () => {
    let request
    switch (doc.register) {
      case "module":
        request = digitalizationWithModule(doc.module, doc.select || doc.number, doc.attachments)
        break;

      case "digital_vault":
        request = addDigitalVaultFiles(doc.attachments, doc.sub_folder || doc.folder)
        break;

      default:
        return notice("Seleccione un tipo de registro")
    }
    dispatch(setLoader(true))
    request
    .then(res => {
      setDoc(defaultDoc)
      notice("Se han cargado los archivos con éxito", "success")
    }).catch(e => {
      if( e.hasOwnProperty('url') ) {
        notice("Debe seleccionar al menos un archivo", 'info')
      } else {
        notice("Ha ocurrido un error al cargar sus archivos")
      }
    })
    .finally(() => dispatch(setLoader(false)))
  }

  return (
    <PageLayout title="Digitalización de documentos" className="bg-white" header={<div className='w-100 d-flex justify-content-end'>
      <CustomButton title='Digitalizar Documentos' color='success' onClick={onSubmit} />
    </div>}>
      <UtilInputs
        rootColumn
        width={250}
        inputValues={docForm(doc.register, options, optionsLoader, doc.number, doc.select, folderOptions, subfolderOptions)}
        formValues={doc}
        onValuesChange={handleChange}
      />
      <FileButton multiple accept='*' buttonTitle='Seleccionar archivos' handleChange={handleAttachments} className='mt-4' />
      <div className="d-flex mt-4">
        <AttachmentsList attachments={doc.attachments} onClick={(e, i) => handleRemoveAttachment(i)}/>
      </div>
    </PageLayout>
  )
}

export default DigitalizationDocs
