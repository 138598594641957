import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FullTable from "../../components/FullTable";
import notice from "../../components/Notice";

import {
  entrancesConcentrate_Columns,
  defaultFilter,
  filterInputs,
} from "../../utils/entranceConcentrateData";
import { listEntranceConcentrates, getPDF } from "../../crud/entranceConcentrateCrud";

import { useSearchMode } from "../../hooks/useSearchMode";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";
import {
  SetActionForm,
  ListConcentrates,
} from "../../../redux/ducks/entranceConcentrate.duck";

const EntranceConcentratePage = () => {
  const moduleAdminRoute = "inventario-bienes-almacen-general";
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { authToken, user } = useSelector((store) => store.auth);
  const inventoryModule = user.app_modules.find(
    (module) => module.name === moduleAdminRoute
  );
  const requestModule =
    inventoryModule && inventoryModule.submodules.length > 0
      ? inventoryModule.submodules.find((module) => module.name === "solicitudes")
      : undefined;
  const mode = !pathname.includes(moduleAdminRoute)
    ? "public"
    : requestModule
    ? "admin"
    : "public";
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(50);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState(defaultFilter);
  const [loading, setLoading] = useState(false);

  const getFilterParams = useCallback(() => {
    let params = {
      ...filter,
    };
    return filterParams(clearEmptyStringsObject(cleanObject(params)));
  }, [filter]);

  useEffect(() => {
    listEntranceConcentrates(page, per_page, getFilterParams())
      .then((res) => {
        if (res.status === 200) {
          setResults(res.data.results);
          setPage(page);
          setPer_page(res.data.per_page);
          setCount(res.data.count);
          dispatch(ListConcentrates(res.data.results));
        }
      })
      .catch(console.log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, per_page]);

  useSearchMode(
    mode === "admin"
      ? "/inventario-bienes-almacen-general/solicitudes/busqueda"
      : "/solicitudes/lista"
  );

  const handleAdd = () => {
    dispatch(SetActionForm("isNew"));

    history.push("/inventario-bienes-almacen-general/concentrado-entradas/nuevo");
  };

  const onDetail = (id) => {
    dispatch(SetActionForm("isEdit"));
    history.push(`/inventario-bienes-almacen-general/concentrado-entradas/editar/${id}`);
  };

  const onFile = (id) => {
    console.log("onFile => ", id);

    getPDF(id)
      .then((res) => {
        if (res?.status && res.status === 200 && res.data) {
          window.open(res.data.attachment_url, "_blank");
        } else {
          notice("Ocurrió un error al consultar la información");
        }
      })
      .catch((error) => {
        notice("Ocurrió un error al consultar la información");
      });
  };

  const onClearFilter = () => {
    setFilter(defaultFilter);
    setLoading(true);
    listEntranceConcentrates(page, per_page)
      .then((res) => {
        if (res.status === 200) {
          setResults(res.data.results);
          setPage(page);
          setPer_page(res.data.per_page);
          setCount(res.data.count);
          dispatch(ListConcentrates(res.data.results));
        }
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const handleFilterChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const onConfirmFilter = () => {
    setLoading(true);
    listEntranceConcentrates(page, per_page, getFilterParams())
      .then((res) => {
        if (res.status === 200) {
          setResults(res.data.results);
          setPage(page);
          setPer_page(res.data.per_page);
          setCount(res.data.count);
          dispatch(ListConcentrates(res.data.results));
        }
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };
  return (
    <>
      <FullTable
        title="Concentrado de entradas"
        has_button={true}
        buttonTitle="Nuevo Concentrado"
        onClickButton={handleAdd}
        entities={results}
        columns={entrancesConcentrate_Columns(
          {
            onDetail,
            onFile,
          },
          mode
        )}
        page={page}
        setPage={(pageNumber) => pageNumber > 0 && setPage(pageNumber)}
        sizePerPage={per_page}
        setSizePerPage={(pageSize) => setPer_page(pageSize)}
        count={count}
        search_button={false}
        loading={loading}
        // FILTERS
        has_filter={true}
        formFilters={filter}
        onFilterChange={handleFilterChange}
        filterInputs={filterInputs}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
      />
    </>
  );
};

export default EntranceConcentratePage;
