import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CONSULTA_QR, qrViewSelector } from '../../../redux/ducks/qrview.duck';
import QRView from '../../components/QRView';

const QRViewArtworkPage = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const {
      qrData
    } = useSelector(qrViewSelector);
    useEffect(() => {
      dispatch(CONSULTA_QR(id));
    }, [dispatch, id]);
    return (
        <QRView qr={qrData}/>
    );
};

export default QRViewArtworkPage;