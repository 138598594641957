import { formatDateDDMMYYYY } from "./dates";
import { medioAcquisitionOptions } from "./StockFormData";

export const entryData4 = [
  {
    label: "Medio de adquisición",
    placeholder: "Medio de adquisición",
    name: "cf_medioadquisicion",
    type: "dropdown",
    required: true,
    options: medioAcquisitionOptions,
  },
  {
    label: "No. De contrato",
    placeholder: "No. De contrato",
    name: "cf_no_contrato",
    type: "text",
    required: true,
    disabled: true,
    maxLength: 50,
    width: 350,
  },
  {
    label: "Fecha del contrato",
    placeholder: "Dd/mm/aaaa",
    name: "cf_fecha_contrato",
    type: "date",
    required: true,
    disabled: true,
  },
  {
    label: "No. De pedido",
    placeholder: "No. De pedido",
    name: "cf_no_pedido",
    type: "text",
    disabled: true,
    required: true,
    uppercase: true,
    maxLength: 50,
  },
  {
    label: "No. De requisición",
    placeholder: "No. De requisición",
    name: "cf_no_requisicion",
    type: "text",
    required: true,
    uppercase: true,
    disabled: true,
    maxLength: 20,
  },
  {
    label: "No. De Acta",
    placeholder: "No. De Acta",
    name: "cf_no_acta",
    type: "text",
    required: true,
    uppercase: true,
    disabled: true,
  },
  {
    label: "Fecha del acta",
    placeholder: "Fecha del acta",
    name: "cf_fecha_acta",
    type: "date",
    required: true,
    uppercase: true,
    disabled: true,
  },
];

export const initialInventoryForm = {
  cabms_item: "",
  cabms_key: "",
  item: "",
  key: "",
  description: "",
  marca: "",
  modelo: "",
  serie: "",
  partida: "",
  descripcion_partida: "",
  conciliado: "",
  cf_no_cuenta: "",
  cf_no_scuenta: "",
  precio_unitario: 0,
  cantidad_descuento: 0,
  descuento: 0,
  foto_bien: null,
  cantidad: 1,
  cf_label_type: "NM-MD",
  cf_label_status: "PENDING",
  cf_tipobien: "E",
  inventory_cf_inve_material_type: "METAL",
};

export const initialNewEntryForm = {
  cf_tipo_documento: "",
  cf_no_almacen: "",
  cf_fecha_recepcion_almacen: "",
  cf_folio_entrada_almacen: "",
  proveedor: "",
  cf_numero_factura: "",
  cf_fecha_factura: "",
  cf_fecha_recepcion_factura: "",
  cf_no_formato_almacen: "",
  cf_fecha_recibido_formato_almacen: "",
  formatos_f: {},
  //--segundos
  cf_no_remision: "",
  cf_fecha_remision: "",
  cf_fecha_recepcion_remision: "",
  cf_no_formato_almacen_remision: "",
  cf_fecha_recibido_formato_almacen_remision: "",
  formatos_r: {},
  //--tercero
  cf_medioadquisicion: "",
  cf_no_contrato: "",
  cf_fecha_contrato: "",
  cf_no_pedido: "",
  cf_no_requisicion: "",
  cf_no_acta: "",
  cf_fecha_acta: "",
  cf_observaciones: "",

  // cf_no_proveedor: [],
  // cf_fecha_recepcion_documento: "",
  cf_sub_total: 0,
  cf_iva_factura: "16%",
  cf_importe_neto: 0,
  // inventory: [],
  cf_folio_recepcion_almacen: "",
  // cf_fecha_actuali: formatDateDDMMYYYY(new Date()),
  // cf_username: "",
  // cf_fecha_requisicion: "",
  // acquisition_date: "",
};

export const InitialNewEntryImportForm = {
  cf_sub_total: 0,
  cf_iva_factura: "16%",
  cf_importe_neto: 0,
};
export const initialNewEntrySearchForm = {
  created_at: "",
  id: "",
  cf_tipo_documento: "",
  cf_no_almacen: "",
  cf_fecha_recepcion_almacen: "",
  cf_fecha_actualizacion: "",
  cf_folio_entrada_almacen: "",
  proveedor: "",
  cf_no_proveedor: [],
  cf_numero_factura: "",
  cf_fecha_factura: "",
  cf_fecha_recepcion_factura: "",
  cf_no_formato_almacen: "",
  cf_fecha_recibido_formato_almacen: "",
  cf_no_remision: "",
  cf_fecha_remision: "",
  cf_fecha_recepcion_remision: "",
  cf_no_formato_almacen_remision: "",
  cf_fecha_recibido_formato_almacen_remision: "",
  cf_medioadquisicion: "",
  cf_no_contrato: "",
  cf_fecha_contrato: "",
  cf_no_pedido: "",
  cf_no_requisicion: "",
  cf_no_acta: "",
  cf_fecha_acta: "",
  cf_observaciones: "",
};
export const initialNewEntrySearchForm2 = {
  id: "",
  cf_tipo_documento: "",
  cf_no_almacen: "",
  cf_fecha_recepcion_almacen: "",
  cf_fecha_actualizacion: "",
  cf_folio_entrada_almacen: "",
  proveedor: "",
  cf_no_proveedor: [],
  cf_numero_factura: "",
  cf_fecha_factura: "",
  cf_fecha_recepcion_factura: "",
  cf_no_formato_almacen: "",
  cf_fecha_recibido_formato_almacen: "",
  cf_no_remision: "",
  cf_fecha_remision: "",
  cf_fecha_recepcion_remision: "",
  cf_no_formato_almacen_remision: "",
  cf_fecha_recibido_formato_almacen_remision: "",
  cf_medioadquisicion: "",
  cf_no_contrato: "",
  cf_fecha_contrato: "",
  cf_no_pedido: "",
  cf_no_requisicion: "",
  cf_no_acta: "",
  cf_fecha_acta: "",
  cf_observaciones: "",
};
