import * as docx from 'docx';

export const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  // Creates docx file for with column/data table info

export const createTableDoc = (columns, data) => {
  // Deletes not wanted columns
  columns = columns.filter(column => column.csvExport !== false);
  const doc = new docx.Document({
		sections: [
			{
				properties: {},
				children: [
          new docx.Table({
            width: { size: 100, type: docx.WidthType.PERCENTAGE },
            rows: [
              new docx.TableRow({
                width: { size: 100, type: docx.WidthType.PERCENTAGE },
                children: columns.map(({text}, index) => new docx.TableCell({ 
                  children: [ new docx.Paragraph({ text }) ],
                  width: { size: 100 / columns.length, type: docx.WidthType.PERCENTAGE },
                  borders: {
                    // top: {style: docx.BorderStyle.NONE, size: 0, color: "FFFFFF"},
                    // bottom: {style: docx.BorderStyle.NONE, size: 0, color: "FFFFFF"},
                    left: {style: docx.BorderStyle.NONE, size: 0, color: "FFFFFF"},
                    right: {style: docx.BorderStyle.NONE, size: 0, color: "FFFFFF"},
                  },
                }))
              }),
              ...data.map(row => new docx.TableRow({
                width: { size: 100, type: docx.WidthType.PERCENTAGE },
                children: columns.map(({dataField}, index) => new docx.TableCell({ 
                  children: [ new docx.Paragraph({ text: row[dataField]?.toString(),  }) ],
                  width: { size: 100 / columns.length, type: docx.WidthType.PERCENTAGE },
                  borders: {
                    top: {style: docx.BorderStyle.NONE, size: 0, color: "FFFFFF"},
                    bottom: {style: docx.BorderStyle.NONE, size: 0, color: "FFFFFF"},
                    left: {style: docx.BorderStyle.NONE, size: 0, color: "FFFFFF"},
                    right: {style: docx.BorderStyle.NONE, size: 0, color: "FFFFFF"},
                  },
                  margins: {
                    left: 50,
                    right: 100,
                    top: 200,
                  }
                }))
              }))
            ]
          })
        ]
			},
		],
	});
  return doc;
};

export const exportToCsv = (filename, rows) => {
  var processRow = function (row) {
      var finalVal = '';
      for (var j = 0; j < row.length; j++) {
          var innerValue = row[j] === null ? '' : row[j].toString();
          if (row[j] instanceof Date) {
              innerValue = row[j].toLocaleString();
          };
          var result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0)
              result = '"' + result + '"';
          if (j > 0)
              finalVal += ',';
          finalVal += result;
      }
      return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
  } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
  }
}

export const getFileExtension = (fileName) => fileName.substring(fileName.lastIndexOf('.') + 1);