import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import notice from '../../components/Notice'
import EntranceForm from './EntranceForm'
import { setClearState, setEntrance, setSelectedIds } from '../../../redux/ducks/entrance.duck'
import { getEntranceDetail, getEntranceRequirementItems } from '../../crud/entrancesStockCrud'
import { setCurrentMode, setLabel } from '../../../redux/ducks/subHeaderActions.duck'
import { setLoader } from '../../../redux/ducks/loader.duck'
import { requirementData } from '../../utils/entranceData'

const EntranceDetail = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { entrance, selectedIds } = useSelector(state => state.entrance)

  useEffect(() => {
    if (id){
      dispatch(setLoader(true))
      getEntranceDetail(id)
      .then(res => {
        selectedIds.length === 0 && dispatch(setSelectedIds([id]))
        // let ordinary_item_details = res.warehouse_entry_details.map(it => it.ordinary_asset) || []
        let requisition_item_details = requirementData({...res, id}) || []
        if(
          res.entrada_pre_registro 
          && res.requirement 
        ){
          return getEntranceRequirementItems(res.requirement)
          .then(({ requisition_item_details }) => {
            const newData = requirementData({ ...entrance, id: null}, requisition_item_details)
            dispatch(setEntrance({...res, id, requisition_item_details: newData, tipo_entrada: res.limpieza ? "LIMPIEZA" : res.tipo_entrada }))
            dispatch(setLabel('Entrada ' + id))
          })
          .catch(e => {
            console.log(e);
            notice("Error cargando la lista de articulos")
          })
        }else {
          dispatch(setEntrance({...res, id, requisition_item_details, tipo_entrada: res.limpieza ? "LIMPIEZA" : res.tipo_entrada }))
          dispatch(setLabel('Entrada ' + id))
        }
      })
      .catch(e => {
        console.log(e);
        notice("Error cargando la entrada")
        dispatch(setClearState())
        dispatch(setCurrentMode("new"))
		  }).finally(() => dispatch(setLoader(false)))
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, selectedIds.length])

  useEffect(() => {
    if (entrance.requirement && !id)
    getEntranceRequirementItems(entrance.requirement)
    .then(({ requisition_item_details }) => {
      const newData = requirementData(entrance, requisition_item_details)
      dispatch(setEntrance({ ...entrance, requisition_item_details: newData }));
    })
    .catch(e => {
      console.log(e);
      notice("Error cargando la lista de articulos")
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, entrance.requirement])

  return (<>
    <EntranceForm />
  </>)
}

export default EntranceDetail
