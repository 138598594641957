import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import NewRequirementForm from "./NewRequirementForm";
import notice from "../../components/Notice";
import {
  setCurrentRequirementTab,
  setFilterRequirement,
  setNewRequirement,
  setSelectedRequirement,
} from "../../../redux/ducks/requirement.duck";
import { defaultRequirementFilter } from "../../utils/requirementsData";
import { getContractList } from "../../crud/contractsCrud";

const DetailsRequirementsPage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    selectedRequirement,
    searchRequirement,
    isSearchTab,
    isResultsTab,
    newRequirement,
  } = useSelector((store) => store.requirement);
  const [contracts, setContracts] = useState([]);
  const currentFormFunction =
    !isSearchTab && isResultsTab ? setSelectedRequirement : setNewRequirement;
  const currentForm = !isSearchTab && isResultsTab ? selectedRequirement : newRequirement;

  useEffect(() => {
    if (currentFormFunction && currentForm?.contract_set.length > 0) {
      let // contractHasItemWithIva = [],
        min_subtotal = 0,
        min_iva = 0,
        max_iva = 0,
        max_subtotal = 0,
        min_total = 0,
        max_total = 0;
      for (let i = 0; i < currentForm.contract_set.length; i++) {
        min_subtotal += Number(currentForm.contract_set[i].min_import);
        min_iva = ((min_subtotal * 1.16) / 100).toFixed(2);
        min_total = (min_subtotal * 1.16).toFixed(2);
        max_subtotal += Number(currentForm.contract_set[i].max_import);
        max_iva = ((max_subtotal * 1.16) / 100).toFixed(2);
        max_total = (max_subtotal * 1.16).toFixed(2);
      }

      dispatch(
        currentFormFunction({
          ...currentForm,
          min_subtotal,
          min_iva,
          max_iva,
          min_total,
          max_total,
          max_subtotal,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFormFunction, currentForm.contract_set, dispatch]);

  useEffect(() => {
    if (!isSearchTab && currentForm.supplier && currentForm.supplier.text2)
      getContractList(100, 1, `supplier_number=${currentForm?.supplier?.text2}`)
        .then((res) => {
          if (res.status === 200 && res.data?.results[0]?.id) {
            setContracts(
              res.data.results.map((item) => ({
                ...item,
                value: item.id,
                text: item.contract_number,
              }))
            );
          } else notice("El proveedor no cuenta con contratos");
        })
        .catch(console.log);
  }, [currentForm.supplier, isSearchTab]);

  const handleFormValues = (e) => {
    const { name, value } = e.target;
    if (name === "contrato") {
      dispatch(
        currentFormFunction({
          ...currentForm,
          // cf_fecha_requerimiento: date_start,
          // cf_fecha_maxima: date_end,
          [name]: value,
        })
      );
      let data = contracts.find((co) => co.id === value);
      if (data) {
        let { supplier_number, contract_set } = data;
        contract_set = contract_set
          ? contract_set.map((contract) => {
              let unitPricesSum = 0;
              for (let i = 0; i < contract.set_item.length; i++) {
                unitPricesSum += contract.set_item[i].unit_price;
              }

              //-----------------------------------------
              /* Se aplica el cambio de variable por available_quantity */
              const available_quantity = contract.available_amount;
              // const available_quantity =
              //   contract.maximum_amount - unitPricesSum;
              //-----------------------------------------

              return {
                ...contract,
                date_end: contract.delivery_date,
                min_import: contract.minimum_amount,
                max_import: contract.maximum_amount,
                progress: (contract.minimum_amount / contract.maximum_amount) * 100,
                available_quantity,
                set_item: contract.set_item.map((item) => ({
                  ...item,
                  cons: item.contract_consecutive,
                  cve_article: item.cve_articulo,
                  article: item.name_assets_category,
                  requested_amount: 0,
                  total_price: 0,
                  presentacion: item?.presentacion?.descripcion,
                  stock_available: item?.presentacion?.equivalencia,
                  marca: item?.marca,
                })),
              };
            })
          : [];
        // let unitPrices = 0;
        // for(let i=0;i<contract_set.length;i++){
        //     for(let j=0;j<contract_set[i].set_item.length;i++){
        //         unitPrices += contract_set[i].set_item[j].unit_price;
        //     };
        // };
        //const available_quantity = contract.maximum_amount - unitPrices;
        dispatch(
          currentFormFunction({
            ...currentForm,
            // cf_fecha_requerimiento: date_start,
            // cf_fecha_maxima: date_end,
            [name]: value,
            contract_set,
            supplier_number,
          })
        );
      }
      return;
    } else if (name === "supplier") {
      dispatch(
        currentFormFunction({
          ...currentForm,
          [name]: value,
        })
      );
    } else {
      dispatch(
        currentFormFunction({
          ...currentForm,
          [name]: value,
        })
      );
    }
  };

  const handleSearchForm = (e) => {
    if (!e.target) return;
    const { name, value } = e.target;
    dispatch(setFilterRequirement({ ...searchRequirement, [name]: value }));
  };

  useEffect(() => {
    const newTabs = pathname.includes("busqueda")
      ? { isSearchTab: true, isResultsTab: false, isNewTab: false }
      : pathname.includes("nuevo")
      ? { isSearchTab: false, isResultsTab: false, isNewTab: true }
      : { isSearchTab: false, isResultsTab: true, isNewTab: false };
    dispatch(setCurrentRequirementTab(newTabs));
  }, [pathname, dispatch]);

  useEffect(() => {
    return () => dispatch(setNewRequirement(defaultRequirementFilter));
  }, [dispatch]);

  return (
    <>
      <NewRequirementForm
        onChangeFormValues={isSearchTab ? handleSearchForm : handleFormValues}
        formValues={isSearchTab ? searchRequirement : currentForm}
        contracts={contracts}
      />
    </>
  );
};

export default DetailsRequirementsPage;
