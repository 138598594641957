import React from 'react'
import PropTypes from "prop-types"
import { CircularProgress, Card, CardActions, CardContent, CardHeader, makeStyles} from '@material-ui/core'

const useStyles = makeStyles( theme => ({
  header: {
    whiteSpace: 'break-spaces'
  }
}) )

const SimpleCard = ({ children, title, subtitle, footer, style={}, className="", headerClasses="", titleClasses, contentClasses="", footerClasses="", onClickCard=()=>{}, draggable=false, load=false }) => {
  const classes = useStyles();
  return (
    <Card style={style} elevation={0} onClick={onClickCard} className={className} draggable={draggable} onDrag={console.log}>
      {title && <CardHeader
        title={title}
        subheader={subtitle}
        className={`${headerClasses} ${classes.header}`}
        titleTypographyProps={{ className: titleClasses }}
      />}
      <CardContent className={contentClasses}>
        { load ? <CircularProgress /> : children}
      </CardContent>
      {footer && <CardActions className={footerClasses}>
        {footer}
      </CardActions>}
    </Card>
  )
}

SimpleCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  contentClasses: PropTypes.string,
  draggable: PropTypes.bool,
  footer: PropTypes.node,
  footerClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  onClickCard: PropTypes.func,
  style: PropTypes.object,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  titleClasses: PropTypes.string
}

export default SimpleCard
