import React from "react";
import { columnStatusCssClasses } from "../../utils/columnFormattersHelpers";
import "../styles/_departureStatus.scss";

export const EntrancesStatusColumnFormatter = (cellContent, row) => {
  const type =
    row.tipo_entrada === "STOCK" && row.limpieza
      ? "LIMPIEZA"
      : row.tipo_entrada === "STOCK" && row.devolucion
      ? "DEVOLUCION"
      : row.tipo_entrada;

  let statusEntrada;
  if (type === "LIMPIEZA" || type === "STOCK" || type === "RECURRENTE") {
    if (!row.entrada_cerrada) {
      statusEntrada = "En proceso";
    } else if (row.entrada_cerrada && row.registra_remision && !row.registra_factura) {
      statusEntrada = "Afectada/En proceso";
    } else if (row.entrada_cerrada && !row.registra_remision && !row.registra_factura) {
      statusEntrada = "Afectada";
    } else if (row.entrada_cerrada && row.registra_factura) {
      statusEntrada = "Afectada/Cerrada";
    } else {
      statusEntrada = "Abierto";
    }
  } else if (type === "ORDINARIA") {
    if (row.registra_remision && !row.registra_factura) {
      statusEntrada = "En proceso";
    } else if (row.registra_factura && !row.registra_remision) {
      statusEntrada = "Cerrada";
    } else {
      statusEntrada = "Abierto";
    }
  } else if (type === "FONDOREVOLVENTE" || type === "GASTOSCOMPROBAR") {
    statusEntrada = row.entrada_cerrada ? "Cerrada" : "Abierto";
  } else if (type === "DEVOLUCION") {
    statusEntrada = row.entrada_cerrada ? "Afectada/Cerrada" : "Abierto";
  }

  // if (row.entrada_cerrada && !row.registra_factura) {
  //   statusEntrada = "Afectada";
  // } else if (row.entrada_cerrada && row.registra_factura) {
  //   statusEntrada = "Cerrada";
  // } else if (!row.entrada_cerrada) {
  //   statusEntrada = "En proceso";
  // }
  return (
    <span
      className={`badge badge-pill badge-${
        columnStatusCssClasses[
          statusEntrada === "Afectada/Cerrada"
            ? 0
            : statusEntrada === "Cerrada"
            ? 1
            : statusEntrada === "Afectada/En proceso"
            ? 2
            : statusEntrada === "En proceso"
            ? 3
            : 4
        ]
      }`}
    >
      {statusEntrada}
    </span>
  );
};
