import React from 'react'
import EyeColumnIcon from './list-icons/EyeColumnIcon'
import TrashColumnIcon from './list-icons/TrashColumnIcon'

export const DraftsActionsColumnFormatter = (cellContent, row, rowIndex,{ onDetail, onTrashDraft }) => {
  return (
    <>
      <EyeColumnIcon
				onClick={() => onDetail(row.id)}
			/>
      <TrashColumnIcon
        onClick={() => onTrashDraft(row.id)}
      />
    </>
  )
}
