import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import FullTable from "../../components/FullTable";
import { setClearState, setDeparture, setDepartures, setRecurrent, setSelectedDepartures } from "../../../redux/ducks/departure.duck";
import { defaultFilter, defaultRecurrentDeparture, departureFilterInputs, recurrent_columns } from '../../utils/departureData';
import { cleanObject, clearEmptyStringsObject, filterParams } from "../../utils/objectsFunctions";
import { getOneOrdinary, getOrdinaryList, getOutboundPdf } from "../../crud/departuresCrud";
import { setLoader } from "../../../redux/ducks/loader.duck";

const DepartureOrdinaryPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { list } = useSelector(state => state.departure)
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [selectedIds, setSelectedIds] = useState([])
  const [filter, setFilter] = useState(defaultFilter)

  useEffect(() => {
    setLoading(true)
    getOrdinaryList(page, sizePerPage)
    .then(res => dispatch(setDepartures(res)))
    .catch(console.log)
    .finally(() => setLoading(false))
  }, [dispatch, page, sizePerPage])

  const handleFilterChange = ({ target }) => {
    const { name, value } = target
    setFilter({...filter, [name]: value})
  }

  const onConfirmFilter = () => {
    setLoading(true);
    getOrdinaryList(page, sizePerPage, filterParams(clearEmptyStringsObject(cleanObject(filter))))
    .then(res => dispatch(setDepartures(res)))
    .catch(console.log)
    .finally(() => setLoading(false))
  }

  const onClearFilter = () => {
    setFilter(defaultFilter)
    setLoading(true);
    getOrdinaryList(page, sizePerPage)
    .then(res => dispatch(setDepartures(res)))
    .catch(console.log)
    .finally(() => setLoading(false))
  }

  const handleAdd = () => {
    dispatch(setRecurrent(defaultRecurrentDeparture))
    history.push("/inventario-bienes-almacen-general/salidas/ordinaria/nuevo")
  }

  const onEye = (id, isSelectedIds) => {
    if (!isSelectedIds) dispatch(setClearState())
    getOneOrdinary(id)
    .then(res => {
      dispatch(setDeparture(res))
      history.push(`/inventario-bienes-almacen-general/salidas/ordinaria/resultados/${id}`)
    })
    .catch(console.log)
  }

  const handleSelectAllRows = (isSelect, rows) => isSelect ? setSelectedIds(rows.map(r => r.id)) : setSelectedIds([])

  const handleSelectRow = (row, isSelect) => {
		if(row && row.id){
			if(!isSelect){
				const newSelected = selectedIds.filter(id => id !== row.id);
				setSelectedIds(newSelected);
			}else{
				setSelectedIds([...selectedIds, row.id]);
			};
		};	
	};

  const handleReqSelected = () => {
    dispatch(setSelectedDepartures(selectedIds))
    onEye(selectedIds[0], true)
  }

  const handleSearch = () => {
    dispatch(setClearState())
    history.push("/inventario-bienes-almacen-general/salidas/busqueda")
  }

  const onFile = (id) => {
    dispatch(setLoader(true))
    getOutboundPdf(id)
    .then(res => res.pdf_url && window.open(res.pdf_url, "_blank"))
    .catch(console.log)
    .finally(() => dispatch(setLoader(false)))
  }

  return (
    <>
      <FullTable
        hideCheckbox
				title='Salidas ordinarias'
				buttonTitle='Nueva Salida Ordinaria'
				onClickButton={handleAdd}
        columns={recurrent_columns(onFile)}
				entities={list.results}
				count={list.count}
				page={page}
				setPage={page => page > 0 && setPage(page)}
				sizePerPage={sizePerPage}
				setSizePerPage={setSizePerPage}
				loading={loading}
        hasReportIcons={false}
        search_button={false}
        onClickSearch={handleSearch}
        // SELECTED Ids ACTIONS
        // has_button2
        buttonDisabled2={selectedIds.length < 1}
        buttonTitle2={`Consultar Todos${selectedIds.length > 0 ? `(${selectedIds.length.toString()})` : ''}`}
        onSelectRow={handleSelectRow}
			  onSelectAllRows={handleSelectAllRows}
			  onClickButton2={handleReqSelected}
        // FILTERS
        // has_filter
        formFilters={filter}
        onFilterChange={handleFilterChange}
        filterInputs={departureFilterInputs}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
			/>
    </>
  )
}

export default DepartureOrdinaryPage
