import React from 'react';
import EyeColumnIcon from './list-icons/EyeColumnIcon.js';
import LockColumnIcon from './list-icons/LockColumnIcon';

export const ActionsColumnFormatter = (
	cellContent,
	row,
	rowIndex,
	{ onEye, onLock,Permisos  }
) => {
	
	return (
		<div className='d-flex justify-content-between'>
			{Permisos.includes("i-ver-entrada") && <EyeColumnIcon
				onClick={() => onEye(row.id)}
			/>
			}
			{Permisos.includes("i-cerrar-entrada") && !row.is_closed && (
				<LockColumnIcon
					onClick={() => onLock(row.id)}
				/>
			)}
		</div>
	);
};
