import { makeStyles } from "@material-ui/core";
import React from "react";
import SVG from "react-inlinesvg";

const useStyles = makeStyles({
  container: {
    width: 180,
    minHeight: 75,
  },
  clickable: {
    cursor: "pointer",
  },
});

const CardColorItem = ({
  src,
  title,
  quantity,
  color = "primary",
  name = null,
  onClick = null,
}) => {
  const classes = useStyles();

  const containerClasses = `${
    classes.container
  } bg-${color} pt-3 pr-3 pl-3 pb-1 rounded text-light d-flex flex-column ${
    onClick ? classes.clickable : ""
  }`;

  const handleClick = onClick || (() => {});
  return (
    <div
      className={containerClasses}
      style={{ fontSize: 12 }}
      onClick={handleClick}
    >
      <p className="m-0 font-weight-bold">{title}</p>
      <div className="d-flex justify-content-between align-items-center mt-1">
        {name === null ? (
          <p
            className="mt-auto mb-auto mr-auto ml-0 h2"
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {quantity}
          </p>
        ) : (
          <span
            className="mt-auto mb-auto mr-auto ml-0 fw-bold "
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {name}
          </span>
        )}
        <div>
          <SVG
            src={src}
            color="white"
            width={40}
            height="auto"
            preProcessor={(code) =>
              code.replace(/fill="#000000*?"/g, 'fill="currentColor"')
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CardColorItem;
