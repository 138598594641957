import PropTypes from "prop-types"
import React, { useState }  from 'react'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { KeyboardArrowDown } from "@material-ui/icons";

const SimpleButtonGroup = ({ options=[], onSelect=()=>{}, className="", style={}, selected=0, variant="contained", color="primary", disabled=false }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = useState(selected); // for uncontrolled

  const handleSelect = (value, index) => {
    setSelectedIndex(index)
    setAnchorEl(null)
    onSelect(value)
  }
  
  return (
    <>
      <Button disabled={disabled} variant={variant} color={color} className={className} style={style} onClick={e => setAnchorEl(e.currentTarget)} endIcon={<KeyboardArrowDown />}>{options[selectedIndex]?.label}</Button>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {options.map((item, i) => <MenuItem key={item.value} disabled={item.disabled} onClick={() => handleSelect(item.value, i)}>{item.label}</MenuItem>)}
      </Menu>
    </>
  )
}

SimpleButtonGroup.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.number,
  style: PropTypes.object,
  variant: PropTypes.string
}

export default SimpleButtonGroup
