import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import FullTable from "../../components/FullTable";
import GuardModal from "../../components/GuardModal";
import BaseModal from "../../components/BaseModal";
import DropModal from "../../components/DropModal";
import notice from "../../components/Notice";
import { setCurrentMode, setFormats } from "../../../redux/ducks/subHeaderActions.duck";
import {
  setSelectedStocks,
  setIdsFilterResult,
  setStock,
  setStockDrops,
  clearStockFormDataAvailableToCopy,
  clearFieldsToUpdate,
} from "../../../redux/ducks/stock.duck";
import {
  columns,
  defaultFilter,
  filterStockInputs,
  mockAreaList,
} from "../../utils/stockData";
import {
  createDropStock,
  fetchStockParams,
  getDropStockList,
  guardStock,
} from "../../crud/stockCrud";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";
import { modeOptions } from "../../utils/subheaderIconsData";
import { defaultDrop } from "../../utils/catalogsData";
import { getKeyData } from "../../utils/cabmsAndKey";

const StockPage = () => {
  const { permisos } = useSelector((store) => store.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { stock, stockDrop } = useSelector((store) => store.stock);
  const [modalOpen, setModalOpen] = useState(false);
  const [fGuardModalOpen, setFGuardModalOpen] = useState(false);
  const [disabledGuardConfirm, setDisabledConfirm] = useState(false);
  const [guardItems, setGuardItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedStocksIds, setSelectedStocksIds] = useState([]);
  const [nonSelectableRows, setNonSelectableRows] = useState([]);
  const [dropStock, setDropStock] = useState(defaultDrop);
  const isDropPage = pathname.includes("bajas");
  const [filter, setFilter] = useState(defaultFilter);

  const getFilterParams = useCallback(() => {
    let key = filter.cabms_item?.text2 ? getKeyData(filter.cabms_item?.text2) : null;
    const filter_params = filterParams(
      clearEmptyStringsObject(cleanObject({ ...filter, ...key }))
    );
    return filter_params;
  }, [filter]);

  useEffect(() => {
    setSelectedStocksIds([]);
  }, [pathname]);

  useEffect(() => {
    dispatch(setIdsFilterResult([]));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (isDropPage)
      getDropStockList(stockDrop.page, stockDrop.per_page)
        .then((res) => {
          if (res && res.results) {
            dispatch(setStockDrops(res));
          }
        })
        .catch((err) => {
          console.log("err", err);
          notice(
            "No se han podido obtener los registros del inventario del servidor, contacta al adminstrador",
            "error"
          );
        })
        .finally(() => setLoading(false));
    else
      fetchStockParams(stock.page, stock.per_page, getFilterParams(), "desc", true)
        .then((res) => {
          if (res && res.results) {
            dispatch(setStock(res));
          }
        })
        .catch((err) => {
          console.log("err", err);
          notice(
            "No se han podido obtener los registros del inventario del servidor, contacta al adminstrador",
            "error"
          );
        })
        .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    isDropPage,
    stockDrop.page,
    stockDrop.per_page,
    stock.page,
    stock.per_page,
  ]);

  const handleFilterChange = ({ target }) => {
    const { name, value } = target;
    name === "cve_area"
      ? setFilter({ ...filter, [name]: value.num_area })
      : setFilter({ ...filter, [name]: value });
  };

  const onConfirmFilter = () => {
    setLoading(true);
    fetchStockParams(stock.page, stock.per_page, getFilterParams())
      .then((res) => {
        if (res && res.results) {
          dispatch(setStock(res));
        }
      })
      .catch((err) => {
        console.log("err", err);
        notice(
          "No se han podido obtener los registros del inventario del servidor, contacta al adminstrador",
          "error"
        );
      })
      .finally(() => setLoading(false));
  };

  const onClearFilter = () => {
    setFilter(defaultFilter);
    setLoading(true);
    fetchStockParams(stock.page, stock.per_page)
      .then((res) => {
        if (res && res.results) {
          dispatch(setStock(res));
        }
      })
      .catch((err) => {
        console.log("err", err);
        notice(
          "No se han podido obtener los registros del inventario del servidor, contacta al adminstrador",
          "error"
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    let nonSelectableIds = [];
    for (let i = 0; i < stock.results.length; i++) {
      if (!stock.results[i].cf_fecha_resguardo) {
        nonSelectableIds = [...nonSelectableIds, stock.results[i].id];
      }
    }
    setNonSelectableRows(nonSelectableIds);
  }, [stock]);

  const handleonClickSearch = () => {
    dispatch(setCurrentMode(modeOptions.search));
    if (pathname.includes("bajas")) {
      history.push("/bienes-instrumentales/inventario-detalles/bajas/busqueda");
    } else {
      history.push("/bienes-instrumentales/inventario-detalles/busqueda");
    }

    dispatch(setCurrentMode(modeOptions.search));
    //setFilterSelected(filterSelected => !filterSelected)
  };

  const handleModal = () => setModalOpen((modalOpen) => !modalOpen);

  const handleOnConfirmGuardModal = () => {
    setDisabledConfirm(true);
    const invalidItemIndex = guardItems.findIndex(
      (item) => item.cf_cve_area === undefined
    );
    if (invalidItemIndex !== -1) {
      notice(
        `Debes asignar el área a resguardar del item ${invalidItemIndex +
          1} de los bienes a resguardar`,
        "error"
      );
      setDisabledConfirm(false);
      return;
    }
    let promises = [];
    guardItems.forEach((item) =>
      promises.push(guardStock(item.id, item.cf_cve_area.text2))
    );
    Promise.all(promises)
      .then((values) => {
        fetchStockParams(stock.page, stock.per_pagem, getFilterParams())
          .then((res) => {
            if (res && res.results) {
              handleModal();
              setGuardItems([]);
              setDisabledConfirm(false);
              notice("Datos resguardados con exito", "success");
              dispatch(setStock(res));
            }
          })
          .catch((err) => err);
      })
      .catch((e) => {
        console.log(e);
        setDisabledConfirm(false);
        notice(
          "Ocurrio un problema mientras se resguardaban los datos, favor de intentar de nuevo"
        );
      });
  };

  const handleGuardItems = (index, event) => {
    if (event) {
      const { name, value } = event.target;
      let updatedItems = guardItems;
      updatedItems[index][name] = value;
      updatedItems[index].confirmationField = true;
      setGuardItems([...updatedItems]);
    }
  };

  const onEye = (id) => {
    const newSelectedStock = stock.results.find((stock) => stock.id === id);
    dispatch(setCurrentMode(null));

    if (newSelectedStock) {
      dispatch(setFormats({ A: true, B: true, C: true, D: true }));
      dispatch(clearFieldsToUpdate());
      dispatch(clearStockFormDataAvailableToCopy());
      dispatch(setIdsFilterResult([id]));
      history.push(`/bienes-instrumentales/inventario-detalles/resultados/${id}`);
    } else {
      dispatch(setIdsFilterResult([id]));
      history.push(`/bienes-instrumentales/inventario-detalles/bajas/resultados/${id}`);
    }
  };

  const onBox = (id) => {
    const index = guardItems.findIndex((x) => x.id === id);
    const newData = stock.results.find((y) => y.id === id);

    if (index === -1) {
      setGuardItems([...guardItems, { ...newData, cf_cve_area: newData.cf_cve_area }]);
    } else {
      let newItems = guardItems.filter((x) => x.id !== id);
      setGuardItems(newItems);
    }
  };

  const onDrop = (id) => {
    setDropStock({
      inventory: id,
      motivo_baja: "",
      tipo_baja: "",
    });
  };

  const onConfirmDrop = () => {
    setLoading(true);
    createDropStock(dropStock)
      .then((res) => {
        notice("Inventario fue dado de baja", "success");
        fetchStockParams(stock.page, stock.per_page, getFilterParams())
          .then((res) => {
            if (res && res.results) {
              dispatch(setStock(res));
            }
          })
          .catch((err) => {
            console.log("err", err);
            notice(
              "No se han podido obtener los registros del inventario del servidor, contacta al adminstrador",
              "error"
            );
          })
          .finally(() => setDropStock(defaultDrop));
      })
      .catch((e) => {})
      .finally(() => setLoading(false));
  };

  const onDropValuesChange = ({ target }) => {
    const { name, value } = target;
    setDropStock({ ...dropStock, [name]: value });
  };

  const handleSelectRow = (row, isSelect) => {
    if (row && row.id) {
      if (!isSelect) {
        const newSelectedEntries = selectedStocksIds.filter((id) => id !== row.id);
        setSelectedStocksIds(newSelectedEntries);
      } else {
        setSelectedStocksIds([...selectedStocksIds, row.id]);
      }
    }
  };

  const handleSelectAllRows = (isSelect, rows) => {
    if (!isSelect) {
      setSelectedStocksIds([]);
    } else {
      const selectedRowIds = rows.map((row) => row.id);
      setSelectedStocksIds(selectedRowIds);
    }
  };

  const onClickQuerySelected = () => {
    dispatch(setCurrentMode(modeOptions.search));

    let newSelectedStocks;
    if (isDropPage) {
      newSelectedStocks = stockDrop.results.filter((stock) =>
        selectedStocksIds.includes(stock.id)
      );
      dispatch(setSelectedStocks(newSelectedStocks));
      dispatch(setIdsFilterResult(newSelectedStocks.map((stock) => stock.id)));
      history.push(
        `/bienes-instrumentales/inventario-detalles/bajas/resultados/${newSelectedStocks[0].id}`
      );
    } else {
      newSelectedStocks = stock.results.filter((stock) =>
        selectedStocksIds.includes(stock.id)
      );
      dispatch(setSelectedStocks(newSelectedStocks));
      dispatch(setIdsFilterResult(newSelectedStocks.map((stock) => stock.id)));
      history.push(
        `/bienes-instrumentales/inventario-detalles/resultados/${newSelectedStocks[0].id}`
      );
    }

    dispatch(clearStockFormDataAvailableToCopy());
    dispatch(setCurrentMode(null));
  };

  const onClickChip = (item) => {
    const newGuardItems = guardItems.map((oldItem) => ({
      ...oldItem,
      cf_cve_area: item.cf_cve_area,
      confirmationField: false,
    }));
    setGuardItems(newGuardItems);
  };

  const onConfirmFilteredGuardItems = () => {
    const newGuardItems = stock.results
      .filter((y) => !y.cf_fecha_resguardo)
      .map((x) => ({ ...x, cf_cve_area: null }));
    setGuardItems(newGuardItems);
    setFGuardModalOpen(false);
    setTimeout(() => handleModal(), 500);
  };

  const setPage = (page) => {
    setLoading(true);
    return (
      page > 0 &&
      dispatch(
        isDropPage
          ? setStockDrops({ ...stockDrop, page: page })
          : setStock({ ...stock, page: page })
      )
    );
  };

  const setSizePage = (size) => {
    setLoading(true);
    dispatch(
      isDropPage
        ? setStockDrops({ ...stockDrop, per_page: size })
        : setStock({ ...stock, per_page: size })
    );
  };
  return (
    <>
      <FullTable
        title="Inventario"
        has_button={!isDropPage}
        buttonTitle="Resguardar"
        onClickSearch={handleonClickSearch}
        onClickButton={handleModal}
        entities={isDropPage ? stockDrop.results : stock.results}
        columns={columns(onEye, onBox, onDrop, guardItems, isDropPage, permisos.permisos)}
        page={isDropPage ? stockDrop.page : stock.page}
        setPage={setPage}
        sizePerPage={isDropPage ? stockDrop.per_page : stock.per_page}
        setSizePerPage={setSizePage}
        count={isDropPage ? stockDrop.count : stock.count}
        buttonDisabled={guardItems.length === 0}
        badgeContent={guardItems}
        loading={loading}
        enableRedBackground={isDropPage ? false : true}
        onSelectRow={handleSelectRow}
        has_button2={true}
        buttonDisabled2={selectedStocksIds.length < 1}
        buttonTitle2={`Consultar Todos${
          selectedStocksIds.length > 1 ? `(${selectedStocksIds.length.toString()})` : ""
        }`}
        onClickButton2={onClickQuerySelected}
        onSelectAllRows={handleSelectAllRows}
        nonSelectableRows={nonSelectableRows}
        // FILTERS
        has_filter={!isDropPage}
        formFilters={filter}
        onFilterChange={handleFilterChange}
        filterInputs={filterStockInputs}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
      />
      <GuardModal
        open={modalOpen}
        items={guardItems}
        areaList={mockAreaList}
        handleChange={handleGuardItems}
        onClose={() => {
          handleModal();
        }}
        onCancel={() => {
          handleModal();
        }}
        onConfirm={() => {
          handleOnConfirmGuardModal();
        }}
        disableOnConfirm={disabledGuardConfirm}
        onClickChip={onClickChip}
      />
      <BaseModal
        open={fGuardModalOpen}
        title="Se encontraron campos sin resguardar, desea realizar tal acción"
        onClose={() => setFGuardModalOpen(false)}
        onConfirm={() => onConfirmFilteredGuardItems()}
        confirmLabel="Aceptar"
        onCancel={() => setFGuardModalOpen(false)}
        cancelLabel="Cancelar"
        color="secondary"
      />
      <DropModal
        open={Boolean(dropStock.inventory)}
        onConfirm={onConfirmDrop}
        onCancel={() => setDropStock(defaultDrop)}
        title="Dar de baja el inventario"
        onValuesChange={onDropValuesChange}
        formValues={dropStock}
      />
    </>
  );
};

export default StockPage;
