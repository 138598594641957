import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

const SendColumnIcon = ({ onClick, disabled, tooltip="Enviar email", color="dark" }) => {
    return (
			<OverlayTrigger
				overlay={<Tooltip id='request-tooltip'>{tooltip}</Tooltip>}
			>
				<button
          className={`btn btn-icon btn-${color} btn-sm btn-hover-${color}`}
          onClick={onClick}
          disabled={disabled}
        >
          <span className={`svg-icon svg-icon-sm svg-icon-hover-${color}`}>
						<SVG
							src={toAbsoluteUrl('/media/svg/icons/Communication/Send.svg')}
						/>
					</span>
				</button>
			</OverlayTrigger>
		);
};

export default SendColumnIcon;