import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import uuid from "react-uuid";
import { getBase64 } from "../utils/files";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import notice from "./Notice";
import Icon from "./Icon";
import BaseModal from "./BaseModal";
import PDFViewer from "./PDFViewer";
import { useDispatch, useSelector } from "react-redux";
import { setEntrance } from "../../redux/ducks/entrance.duck";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  photoLabel: {
    color: theme.palette?.secondary?.main,
    overflow: "hidden",
    cursor: ({ disabled }) => disabled && "pointer",
    marginBottom: "0em",
    textOverflow: "ellipsis",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    gap: 10,
  },
  label: {
    marginLeft: 5,
    fontWeight: 500,
    fontSize: 14,
    margin: "0 0 0 5px",
    color: ({ disabled, touched, hover }) =>
      (touched || hover) && !disabled ? theme.palette.secondary.main : "black",
  },
  photoLink: {
    color: theme.palette?.secondary?.main,
    marginTop: 15,
    textDecoration: "underline",
  },
  namePhoto: {
    color: theme.palette?.secondary?.main,
    cursor: "pointer",
    margin: 0,
  },
  photoContainer: {
    marginTop: 12,
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
}));

const UploadFileButtonAuto = ({
  index,
  handleChange,
  value,
  disabled,
  label = "k",
  accept = "image/*",
  multiple = false,
  uploadFunction = null,
  uploadId,
  name,
  buttonTitle = "Elegir archivo",
  //   loading = false,
  deleteFunction = null,
  disableView = false,
}) => {
  const dispatch = useDispatch();

  const { selectedEntry, newEntryResultsForm } = useSelector(
    (store) => store.entry
  );
  const { entrance } = useSelector((store) => store.entrance); //ALMACEN ENTRADAS
  const [touched, setTouched] = useState(false);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles({ disabled, touched, hover });
  const handleCapture = ({ target }) =>
    !multiple
      ? handleChange(target.files[0], index)
      : handleChange(target.files);
  const inputId = uuid();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const [fileLoading, setFileLoading] = useState(false);
  const [isFetchFile, setIsFetchFile] = useState(false);
  const [fileData, setFileData] = useState({
    valueFile: "",
    idEntry: "",
    titleFile: "",
    attachModel: "warehouse_entry",
    field: "",
  });

  const [attachmentFactura, setAttachmentFactura] = useState(false);
  const [attachmentRemision, setAttachmentRemision] = useState(false);
  const [valueFactura, setValueFactura] = useState({});
  const [valueRemision, setValueRemision] = useState({});

  let { valueFile, titleFile, attachModel, field } = fileData;

  useEffect(() => {
    if (Object.keys(entrance.attachment_factura).length > 0) {
      setAttachmentFactura(true);
      setValueFactura(entrance.attachment_factura);
    }
    if (Object.keys(entrance.attachment_remision).length > 0) {
      setAttachmentRemision(true);
      setValueRemision(entrance.attachment_remision);
    }
  }, []);

  const handlUploadFile = (
    valueFile,
    idEntry,
    titleFile,
    attachModel,
    field
  ) => {
    uploadFunction(valueFile, idEntry, titleFile, attachModel, field)
      .then(({ data }) => {
        const newE = {
          target: {
            files: [{ url: data.url, name: data.title }],
          },
        };
        if (data.field === "factura") {
          setAttachmentFactura(true);
          setValueFactura(data);
        } else if (data.field === "remision") {
          setAttachmentRemision(true);
          setValueRemision(data);
        }
        dispatch(
          setEntrance({
            ...entrance,
          })
        );
        handleCapture(newE);
        notice(`¡Archivo ${label ?? ""} subido exitosamente!`, "success");
      })
      .catch((err) => {
        notice(
          `Ocurrio un error al intentar subir el archivo ${label ??
            ""}, intenta nuevamente.`
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // crear nueva entrada
  useEffect(() => {
    if (entrance.id && valueFile && titleFile && attachModel && field) {
      handlUploadFile(valueFile, entrance.id, titleFile, attachModel, field);
    }
  }, [entrance.id]);

  const handleInputChange = (e) => {
    e.persist();
    if (entrance.id) {
      setLoading(true);
      setIsFetchFile(false);
      handlUploadFile(
        e.target.files[0],
        uploadId,
        e.target.files[0]?.name,
        "warehouse_entry",
        name
      );
    } else {
      handleCapture(e);
      setFileData({
        ...fileData,
        valueFile: e.target.files[0],
        titleFile: e.target.files[0]?.name,
        attachModel: "warehouse_entry",
        field: name,
      });
    }
    const file = e?.target?.files[0];
    if (file && !multiple) {
      setPreviewPdf(file);
    }
  };

  // }, [handleInputChange]);

  // eslint-disable-next-line no-unused-vars
  //   const handleInputDelete = (id) => {
  //     deleteFunction(id).then(({ response }) => console.log(response));
  //   };

  const setPreviewPdf = (file) => {
    setFileLoading(true);
    getBase64(file)
      .then((data) => {
        setPdfFile(data);
        setPreviewOpen(true);
      })
      .catch((error) => console.log("err", error))
      .finally(() => setFileLoading(false));
  };

  const openModal = (value) => {
    if (value?.url) {
      window.open(value.url, "_blank");
    }
  };

  return (
    <div className={classes.container}>
      <>
        <input
          hidden
          type="file"
          accept={accept}
          id={inputId}
          onChange={handleInputChange}
          disabled={disabled}
          multiple={multiple}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label className={classes.photoLabel} htmlFor={inputId}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {label && <p className={classes.label}>{label}</p>}
              <span
                onFocus={() => setTouched(true)}
                onBlur={() => setTouched(false)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className={`btn btn-light-${
                  disabled ? "secondary" : "primary"
                } border border-${disabled ? "dark" : "primary"} h-100 ${
                  disabled ? "disabled text-dark" : ""
                }`}
                style={{
                  cursor: disabled && "not-allowed",
                }}
              >
                {name === "factura"
                  ? (accept === "application/pdf" && attachmentFactura) ||
                    attachmentFactura
                    ? "Cambiar archivo"
                    : "Elegir archivo"
                  : (accept === "application/pdf" && attachmentRemision) ||
                    attachmentRemision
                  ? "Cambiar archivo"
                  : "Elegir archivo"}

                {name === "factura"
                  ? !disableView &&
                    valueFactura &&
                    ((accept === "application/pdf" && attachmentFactura) ||
                      attachmentFactura) && (
                      <Icon
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Check.svg"
                        )}
                        color="success"
                        style={{
                          marginLeft: 5,
                          width: 20,
                          height: 20,
                        }}
                        label="Ver PDF"
                        onClick={() => openModal(valueFactura)}
                      />
                    )
                  : !disableView &&
                    valueRemision &&
                    ((accept === "application/pdf" && attachmentRemision) ||
                      attachmentRemision) && (
                      <Icon
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Check.svg"
                        )}
                        color="success"
                        style={{
                          marginLeft: 5,
                          width: 20,
                          height: 20,
                        }}
                        label="Ver PDF"
                        onClick={() => openModal(valueRemision)}
                      />
                    )}

                {!disableView &&
                  value &&
                  typeof value === "string" &&
                  value?.includes("https") && (
                    <Icon
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Check.svg"
                      )}
                      color="success"
                      style={{
                        marginLeft: 5,
                        width: 20,
                        height: 20,
                      }}
                      label="Ver PDF"
                      onClick={() => window.open(value.url, "blank")}
                    />
                  )}
              </span>
            </div>
          </label>
        </div>
      </>
      <BaseModal
        open={
          accept === "application/pdf" &&
          !loading &&
          previewOpen &&
          !fileLoading
          // &&
          // (value?.name || value?.url)
        }
        onClose={() => setPreviewOpen(false)}
        onCancel={() => setPreviewOpen(false)}
        maxWidth="md"
        title={
          <>
            <span className="mr-2">PDF cargado</span>
            <span className={`svg-icon svg-icon-md svg-icon-primary`}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
            </span>
          </>
        }
        cancelLabel="Aceptar"
      >
        {name === "factura"
          ? (valueFactura?.url || value?.name || value?.url) && (
              <div className="d-flex justify-content-center">
                {isFetchFile ? (
                  <iframe title={value?.name} src={pdfFile} />
                ) : (
                  <PDFViewer url={pdfFile} />
                )}
              </div>
            )
          : (valueRemision?.url || value?.name || value?.url) && (
              <div className="d-flex justify-content-center">
                {isFetchFile ? (
                  <iframe title={value?.name} src={pdfFile} />
                ) : (
                  <PDFViewer url={pdfFile} />
                )}
              </div>
            )}

        {/* {(value?.name || value?.url) && ( 
                <div className='d-flex justify-content-center'>
                    {isFetchFile ? (
                        <iframe title={value?.name} src={pdfFile} />
                    ) : (
                        <PDFViewer url={pdfFile} />
                    )}
                </div>
                )}  */}
      </BaseModal>
    </div>
  );
};

export default UploadFileButtonAuto;
