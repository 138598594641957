import PropTypes from "prop-types"
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const RichTextEditor = ({ value, name="", onChange=()=>{}, placeholder='Escribe aquí...' }) => {
  const [editorState, setEditorState] = useState(()=> EditorState.createEmpty())

  const handleChange = value => {
    setEditorState(value)
    let newValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    onChange({target: { 'name': name, value: newValue }})
  }
  return (
    <Editor
      placeholder={placeholder}
      editorClassName="border border-light"
      editorState={editorState}
      onEditorStateChange={handleChange}
      toolbar={{
        options: ['inline', 'blockType', 'fontSize', 'list', 'emoji', 'history'],
        inline: {
          options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
        },
        blockType: {
          inDropdown: true,
          options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
        },
      }}
      localization={{
        locale: 'es',
      }}
    />
  );
}

RichTextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.node.isRequired
}

export default RichTextEditor

