import {
  getParams,
  handleResponse,
  handleError,
  getMultipartParams,
} from "./helpers/validate";
import {
  TASK_MANAGEMENT,
  TASK_MANAGEMENT_BOARDS,
  TASK_MANAGEMENT_CLEAR_TASKS,
  TASK_MANAGEMENT_COMMENTS,
  TASK_MANAGEMENT_TASKS,
  TASK_MANAGEMENT_TASKS_BY_BOARD,
  UPLOAD_FILE_URL,
} from "./helpers/routes";

export const getBoards = (page = 1, limit = 20, parameter) => {
  if (parameter) {
    return fetch(
      `${TASK_MANAGEMENT_BOARDS}/?page=${page}&limit=${limit}${parameter}`,
      getParams("GET")
    )
      .then(handleResponse)
      .catch(handleError);
  } else {
    return fetch(
      `${TASK_MANAGEMENT_BOARDS}/?page=${page}&limit=${limit}`,
      getParams("GET")
    )
      .then(handleResponse)
      .catch(handleError);
  }
};

export const getBoard = (id) => {
  return fetch(`${TASK_MANAGEMENT_BOARDS}/${id}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const postBoard = (body) => {
  return fetch(`${TASK_MANAGEMENT_BOARDS}/`, getParams("POST", body))
    .then(handleResponse)
    .catch(handleError);
};

export const deleteBoard = (id) => {
  return fetch(`${TASK_MANAGEMENT_BOARDS}/${id}/`, getParams("DELETE"))
    .then(handleResponse)
    .catch(handleError);
};

export const getAllTasks = (name) => {
  if (name) {
    return fetch(`${TASK_MANAGEMENT_TASKS}/?limit=100&name=${name}`, getParams("GET"))
      .then(handleResponse)
      .catch(handleError);
  } else {
    return fetch(`${TASK_MANAGEMENT}/tasks-calendar/?limit=100`, getParams("GET"))
      .then(handleResponse)
      .catch(handleError);
  }
};

export const getAllPendingTasks = () => {
  const TOKEN = process.env.REACT_APP_MONITOR_USER_TOKEN;
  return fetch(`${TASK_MANAGEMENT_TASKS}?limit=100&column_state=1&shift=2`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
};

export const getTask = (id) => {
  return fetch(`${TASK_MANAGEMENT_TASKS}/${id}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const getTasksbyBoard = (boardId) => {
  return fetch(`${TASK_MANAGEMENT_TASKS_BY_BOARD}/${boardId}/`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const getTasksbyBoardColumn = (boardId, column = "") => {
  return fetch(
    `${TASK_MANAGEMENT_TASKS_BY_BOARD}/${boardId}/?column_state=${column}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleError);
};

export const createTask = (data) => {
  const formData = new FormData();

  formData.append("name", data["name"]);
  formData.append("folio", data["folio"]);
  formData.append("folio_number", data["folio_number"]);
  formData.append("reception_date", data["reception_date"]);
  formData.append("request_area", data["request_area"]);
  formData.append("in_charge_area", data["in_charge_area"]);
  formData.append("signed", data["signed"]);
  formData.append("shift", data["shift"]);
  formData.append("description", data["description"]);
  formData.append("priority", data["priority"]);
  formData.append("date_input", data["date_input"]);
  formData.append("blocked", data["blocked"]);
  formData.append("column_state", data["column_state"]);
  formData.append("board", data["board"]);
  data["users"].forEach((item) => formData.append("users[]", item));
  data["users_collaborators"].forEach((item) =>
    formData.append("users_collaborators[]", item)
  );
  data["attachments"].forEach((item) =>
    formData.append("attachments[]title", item["name"])
  );
  data["attachments"].forEach((item) => formData.append("attachments[]image_data", item));

  return fetch(TASK_MANAGEMENT_TASKS + "/", getMultipartParams("POST", formData))
    .then(handleResponse)
    .catch(handleError);
};

export const updateTask = (id, data) => {
  return fetch(`${TASK_MANAGEMENT_TASKS}/${id}/`, getParams("PUT", data))
    .then(handleResponse)
    .catch(handleError);
};

export const deleteTask = (id) => {
  return fetch(`${TASK_MANAGEMENT_TASKS}/${id}/`, getParams("DELETE"))
    .then(handleResponse)
    .catch(handleError);
};

export const updateTaskState = (id, data) => {
  return fetch(`${TASK_MANAGEMENT_TASKS}/${id}/`, getParams("PUT", data))
    .then(handleResponse)
    .catch(handleError);
};

export const deleteAttachmentFromTask = (id) => {
  return fetch(`${UPLOAD_FILE_URL}/${id}/`, getParams("DELETE"))
    .then(handleResponse)
    .catch(handleError);
};

export const createComment = (data) => {
  const formData = new FormData();

  formData.append("task", data["task"]);
  formData.append("comment", data["comment"]);
  data["attachments"].forEach((item) =>
    formData.append("attachments[]title", item["name"])
  );
  data["attachments"].forEach((item) => formData.append("attachments[]image_data", item));

  return fetch(`${TASK_MANAGEMENT_COMMENTS}/`, getMultipartParams("POST", formData))
    .then(handleResponse)
    .catch(handleError);
};

export const finishTask = (data) => {
  const formData = new FormData();
  formData.append("task", data["task"]);
  formData.append("folio", data["folio"]);
  formData.append("folio_number", data["folio_number"]);
  formData.append("other_document", data["other_document"]);
  formData.append("document_number", data["document_number"]);
  data["attachments"]?.forEach((item) =>
    formData.append("attachments[]title", item["name"])
  );
  data["attachments"]?.forEach((item) =>
    formData.append("attachments[]image_data", item)
  );

  return fetch(`${TASK_MANAGEMENT}/finish-tasks/`, getMultipartParams("POST", formData))
    .then(handleResponse)
    .catch(handleError);
};

export const clearFinishedTasks = (data) => {
  return fetch(`${TASK_MANAGEMENT_CLEAR_TASKS}/`, getParams("PUT", data))
    .then(handleResponse)
    .catch(handleError);
};

export const updateUserinBoard = (boardId, data) => {
  return fetch(`${TASK_MANAGEMENT}/boards/${boardId}/`, getParams("PUT", data))
    .then(handleResponse)
    .catch(handleError);
};

export const createTaskDocument = async (data) => {
  return fetch(`${TASK_MANAGEMENT}/other-documents/`, getParams("POST", data))
    .then(handleResponse)
    .catch(handleError);
};
export const deleteTaskDocument = async (id) => {
  return fetch(`${TASK_MANAGEMENT}/other-documents/${id}/`, getParams("DELETE"))
    .then(handleResponse)
    .catch(handleError);
};
