import { createStyles } from "@material-ui/core";

const styles = createStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    position: "relative",
    height: "100%",
    width: "100%",
    "&::before": {
      content: "''",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      opacity: 0.85
    }
  },
  subContainer: {
    position: "relative"
  },
  logo: {
    width: 200
  },
  headerFont: {
    color: "#000",
    textAlign:' center'
  },
  paperHeader: {
    margin: 'auto',
    padding: theme.spacing(3),
    maxWidth: 500,
    maxHeight: 175,
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
    position: "relative",
  },
  grid: {
    "&>*": {
      textAlign: 'center',
      marginBottom: 10
    }
  }
}));

export default styles;