import React from "react";
import { Archive, Block, Folder, Image, ListAlt, PictureAsPdf } from "@material-ui/icons";

export const DigitalVaultIconColumnFormatter = (cellContent, row, rowIndex) => {
  const blue = "#0093FF",
    red = "#DC0714",
    grey = "#9A9A9A",
    green = "#8CC71D";
  let Icon, color;

  if (!row.attachment) {
    Icon = Folder;
    color = blue;
  } else {
    if (row?.name.includes("pdf")) {
      Icon = PictureAsPdf;
      color = red;
    } else if (
      row?.name.includes("img") ||
      row?.name.includes("jpg") ||
      row?.name.includes("jpeg") ||
      row?.name.includes("png")
    ) {
      Icon = Image;
      color = green;
    } else if (
      row?.name.includes("xlsx") ||
      row?.name.includes("xls") ||
      row?.name.includes("csv")
    ) {
      Icon = ListAlt;
      color = green;
    } else if (row?.name.includes("zip")) {
      Icon = Archive;
      color = green;
    } else {
      Icon = Block;
      color = grey;
    }
  }

  return (
    <>
      <Icon className="opacity-50" style={{ color }} />
    </>
  );
};
