import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FullTable from '../../components/FullTable';
import notice from '../../components/Notice';
import { columns, defaultEntrance, defaultFilter, entranceFilterInputs } from '../../utils/entranceData';
import { getEntranceList } from '../../crud/entrancesStockCrud';
import { setClearState, setEntrance, setEntrances } from '../../../redux/ducks/entrance.duck';
import { cleanFilter, clearEmptyStringsObject, filterParams } from '../../utils/objectsFunctions';

const EntranceRecurrentPage = () => {
  const dispatch = useDispatch();
	const history = useHistory();
	const { list } = useSelector(store => store.entrance);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(20);
	const [filter, setFilter] = useState(defaultFilter);

	const getFilterParams = useCallback(() =>filterParams(clearEmptyStringsObject(cleanFilter({...filter, proveedor: filter.proveedor?.text2, tipo_entrada: "RECURRENTE", devolucion: 0}))), [filter]);

  useEffect(() => {
		setLoading(true)
		getEntranceList(page, sizePerPage, getFilterParams())
		.then(res => dispatch(setEntrances(res)))
		.catch(e => notice("Error cargando la lista de entradas"))
		.finally(() => setLoading(false))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, page, sizePerPage]);

  const handleFilterChange = ({ target }) => {
    const { name, value } = target
    setFilter({...filter, [name]: value})
  }

  const onConfirmFilter = () => {
    setLoading(true);
		getEntranceList(page, sizePerPage, getFilterParams())
		.then(res => dispatch(setEntrances(res)))
		.catch(e => notice("Error cargando la lista de entradas"))
		.finally(() => setLoading(false))
  }

  const onClearFilter = () => {
    setFilter(defaultFilter)
    setLoading(true);
    getEntranceList(page, sizePerPage, "tipo_entrada=RECURRENTE")
		.then(res => dispatch(setEntrances(res)))
		.catch(e => notice("Error cargando la lista de entradas"))
		.finally(() => setLoading(false))
  }

	const onEye = async (id) => {
		await dispatch(setClearState())
		history.push(`/inventario-bienes-almacen-general/entradas/resultados/${id}`)
	}

	const onLock = id => console.log('lock', id);
	
	const handleClickButton = async () => {
		await dispatch(setClearState())
		dispatch(setEntrance({...defaultEntrance, tipo_entrada: "RECURRENTE"}))
		history.push("/inventario-bienes-almacen-general/entradas/recurrentes/nuevo")
	}

	const handleSearch = () => {
		dispatch(setClearState())
		history.push("/inventario-bienes-almacen-general/entradas/busqueda")
	}

  return (
    <>
     <FullTable
				hasReportIcons={false}
				title='Entradas recurrentes'
				has_button={false}
				buttonTitle='Nueva entrada'
				onClickButton={handleClickButton}
				// FILTERS
        has_filter
        formFilters={filter}
        onFilterChange={handleFilterChange}
        filterInputs={entranceFilterInputs(false)}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
				onClickSearch={handleSearch}
				// TABLE LIST DATA
				columns={columns({ onLock, onEye })}
				entities={list.results}
				count={list.count}
				page={page}
				setPage={page => page > 0 && setPage(page)}
				sizePerPage={sizePerPage}
				setSizePerPage={setSizePerPage}
				loading={loading}
				hideCheckbox
			/> 
    </>
  )
}

export default EntranceRecurrentPage
