// This function transform object of attachment types into an array of all attachment files
// Used to display in PDFUploaderTab pdfs of all types in object (pdfs, facturas, remision, etc)

export const getFlattenObject = objectOfArrays => {
    let newArray = [];
    const filesKeysValues = Object.entries(objectOfArrays);
    for(let i=0;i<filesKeysValues.length;i++){
        const value = filesKeysValues[i][1];
        for(let j=0;j<value.length;j++){
            newArray = [...newArray, value[j]];
        };
    };
    return newArray;
};