import { makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { getCatalogCABMS } from '../crud/entryCrud';
import SimpleInput from './SimpleInput';
import uuid from 'react-uuid';

const useStyles = makeStyles(theme => ({
	options: {
		position: 'absolute',
		zIndex: 30,
		padding: 5,
		marginTop: 50,
		marginLeft: 100,
		maxHeight: 500,
		overflowY: 'auto'
	},
	option: {
			display: 'flex',
			padding: '3px 5px',
			cursor: 'pointer'
	},
	noOption: {
		display: 'flex',
		padding: '3px 5px',
		cursor: 'pointer',
		minWidth: 200,
		minHeight: 38,
		alignItems: 'center'
	},
	muted: {
		color: theme.palette?.gray?.main,
		marginLeft: 10
	},
	input: {
		minWidth: '100%',
		width: 150
	}
}));

const InputItemKey = ({
	item = { name: '', placeholder: '', label: '' },
	value,
	onClickOption,
	handleValuesChange,
	width,
	optionValue,
	textMuted,
	optionText,
	onKeyDown,
	inputRef,
	disabled=false
}) => {
	const ref = useRef();
	const classes = useStyles();

	const [shouldUpdateInput, setShouldUpdateInput] = useState(true);
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [touched, setTouched] = useState(false);

	useEffect(() => {
		if (!shouldUpdateInput) {
			setShouldUpdateInput(true);
			return;
		}
		const delayDebounceFn = setTimeout(() => {
			if(value?.length > 0){
			getCatalogCABMS(value ?? '', item.name)
				.then(res => {
					if (res && res.status === 200) {
						let data = res.data.results ? res.data.results : res.data;
						const options = data.map(cabms => ({
							text: cabms.cf_denominacion,
							cf_grupo: cabms.cf_grupo,
							cf_subgrupo: cabms.cf_subgrupo,
							cf_clase: cabms.cf_clase,
							cf_subclase: cabms.cf_subclase,
							cf_tipo: cabms.cf_tipo,
						}));
						setOptions(options);
						setOpen(true);
					}
				})
				.catch(err => {
					console.log('err', err);
				});
			}else{
				setOptions([]);
			};
		}, 400);

		return () => clearTimeout(delayDebounceFn);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	useEffect(() => {
		const handleClickOutside = event => {
			if (ref.current && !ref.current.contains(event.target)) setOpen(false);
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);

	const handleClickOption = option => {
		onClickOption(option);
		setOpen(false);
		setShouldUpdateInput(false);
	};

	const handleBlur = () => {
		setTimeout(() => {
			setOpen(false);
		}, 500)
	};
	return (
		<>
			<SimpleInput
				disabled={disabled}
				key={item.name}
				label={item.label}
				required={item.required}
				placeholder={item.placeholder}
				name={item.name}
				onChange={e => {
					if (!touched) setTouched(true);
					handleValuesChange(e);
				}}
				value={value}
				width={width && width}
				// onClick={() => setOpen(true)}
				autoComplete='off'
				onKeyDown={onKeyDown}
				onBlur={handleBlur}
				//inputRef={input => inputRef ? inputRef : input != null && shouldFocus && input.focus()}
				inputRef={inputRef}
			/>
			{open && touched && (
				<Paper ref={ref} className={classes.options}>
					{options.length > 0 ? (
						options.map(option => (
							<div
								className={classes.option}
								key={uuid()}
								onClick={() => handleClickOption(option)}
							>
								<Typography value={optionValue(option)}>
									{optionText(option)}
								</Typography>

								<Typography className={classes.muted}>
									{textMuted(option)}
								</Typography>
							</div>
						))
					) : (
						<div className={classes.noOption}>
							<Typography className={classes.muted}>Sin resultados</Typography>
						</div>
					)}
				</Paper>
			)}
		</>
	);
};

export default InputItemKey;
