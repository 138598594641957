import React, { useEffect } from "react";
import uuid from "react-uuid";
import { useDispatch, useSelector } from "react-redux";
import notice from "../../components/Notice";
import PageLayout from "../../components/PageLayout";
import UtilInputs from "../../components/UtilInputs";
import BatchTable from "../../components/BatchTable";
import { setRefund } from "../../../redux/ducks/entrance.duck";
import {
  defaultRefundArticles,
  refundFormInputs,
  refund_columns_articles,
} from "../../utils/entranceData";
import { isAValidInteger } from "../../utils/inputValidations";
import SimpleInput from "../../components/SimpleInput";
import { Grid } from "@material-ui/core";
import { getArticlesClean } from "../../crud/catalogsCrud";

const EntranceReturnForm = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [resultAutocomplete, setResultAutocomplete] = React.useState([]);
  const { refund } = useSelector((state) => state.entrance);

  const handleForm = (e) => {
    const { name, value } = e.target;
    if (name === "cf_fol_bita" && !isAValidInteger(value))
      notice("El campo Folio de bitacora debe ser un número");
    else dispatch(setRefund({ ...refund, [name]: value }));
  };

  const handleBatches = (i, field, value, aIndex = "") => {
    let updatedState = refund.warehouse_entry_details;
    if (field === "cf_cantidad" && isAValidInteger(value)) {
      updatedState[aIndex][field] = value;
      dispatch(setRefund({ ...refund, warehouse_entry_details: updatedState }));
      return;
    } else if (field === "cf_articulo" || field === "cf_cve_articulo") {
      if (
        refund.warehouse_entry_details.find(
          (it) => it.cf_cve_articulo?.toString() === value?.cve_articulo?.toString()
        )
      ) {
        notice("El articulo ya ha sido agregado");
        return;
      } else {
        updatedState[aIndex]["cf_articulo"] = value?.desc_corta?.trim();
        updatedState[aIndex]["cf_cve_articulo"] = value?.cve_articulo;
        dispatch(setRefund({ ...refund, warehouse_entry_details: updatedState }));
      }
    } else return;
  };

  const onSearchTextChange = (i, field, value, aIndex) => {
    let state = refund.warehouse_entry_details;
    if (!value?.text) {
      if (field === "cf_articulo") {
        state[aIndex]["cf_articulo"] = value;
        return dispatch(setRefund({ ...refund, warehouse_entry_details: state }));
      } else if (field === "cf_cve_articulo" && isAValidInteger(value)) {
        state[aIndex]["cf_cve_articulo"] = value;
        return dispatch(setRefund({ ...refund, warehouse_entry_details: state }));
      } else dispatch(setRefund({ ...refund, warehouse_entry_details: state }));
    }
  };

  const addArticle = (i) =>
    dispatch(
      setRefund({
        ...refund,
        warehouse_entry_details: [
          ...refund.warehouse_entry_details,
          { ...defaultRefundArticles, id: uuid() },
        ],
      })
    );

  const deleteArticle = (item, index) => {
    let updatedState = refund.warehouse_entry_details;
    updatedState.splice(index, 1);
    dispatch(setRefund({ ...refund, warehouse_entry_details: updatedState }));
  };

  const onGetResult = (result) => {
    setResultAutocomplete([]);
    setSearchTerm("");
    let updatedState = refund.warehouse_entry_details;
    const aIndex = refund.warehouse_entry_details.length;
    updatedState[aIndex] = {
      cf_cve_articulo: result.cve_articulo,
      cf_articulo: result.desc_amplia,
      cf_cantidad: 0,
    };
    dispatch(setRefund({ ...refund, warehouse_entry_details: updatedState }));
  };
  useEffect(() => {
    if (searchTerm.length > 0) {
      const getData = setTimeout(async () => {
        const result = await getArticlesClean(searchTerm, "return");
        setResultAutocomplete(result);
      }, 1500);
      return () => clearTimeout(getData);
    }
  }, [searchTerm]);
  return (
    <PageLayout title="Devolución">
      <UtilInputs
        formValues={refund}
        onValuesChange={handleForm}
        inputValues={refundFormInputs(refund.id)}
        width={200}
      />
      <SimpleInput
        name="cf_observaciones"
        label="Observaciones"
        width="100%"
        value={refund.cf_observaciones}
        onChange={handleForm}
        disabled={false}
      />
      <SimpleInput
        multiline
        rows={5}
        width="100%"
        label="Descripción"
        name="ordinaria_descripcion"
        onChange={handleForm}
        value={refund.ordinaria_descripcion}
        disabled={false}
      />
      <Grid item xs={5} mt={2} mb={2}>
        <SimpleInput
          label="Buscar por clave o nombre"
          width={"100%"}
          showAutocomplete
          onChange={({ target }) => setSearchTerm(target.value)}
          autocompleteResults={resultAutocomplete}
          onGetResult={onGetResult}
          value={searchTerm}
          disabled={refund.id}
        />
      </Grid>
      <div className="mt-4">
        <BatchTable
          i={0}
          items={refund.warehouse_entry_details}
          columns={refund_columns_articles(refund.id)}
          handleChange={handleBatches}
          addRow={addArticle}
          deleteRow={deleteArticle}
          onSearchTextChange={onSearchTextChange}
          disableAddItem
        />
      </div>
    </PageLayout>
  );
};

export default EntranceReturnForm;
