import { divide, get } from "lodash";
import React, { useEffect } from "react";
import { columnStatusCssClasses } from "../../utils/columnFormattersHelpers";
import { inputFormatDate } from "../../utils/dates";

const styleDiv = {
  borderBottom: "solid 1px #EBEDF3",
  fontSize: "14px",
};
const STATUSES = {
  // ERNESTO 13/JUL/21 INFO ON GROUP SLACK CHAT
  G: "Abierta",
  D: "Despachada",
  B: "Cancelada",
  H: "Surtiendo",
  X: "Borrador",
  // E: 'Correo enviado',
  // A: 'Abierta',
  // ELIAS INFO
  // G: 'Solicitada',
  // E: 'Correo envíado',
  // D: 'Despechada',
  // B: 'Cancelada',
  // H: 'Procesada (afectada)',
  // S: 'Suertiendo',
  // Ui/UX changes at 25/jun/21
  // A: 'Abierta',
  // C: 'Cancelada',
  // S: 'Suertiendo',
  // D: 'Despachada',
  // E: 'Entregada',
  // Before
  // X: 'Borrador',
  // G: 'Guardado',
  // E: 'Enviado',
  // B : 'Cancelado',
  T: "Entregado",
  default: "Sin/estatus",
};

export const ConsumptionItemColumnFormatter_Titular = (cellContent, row) => {
  const titularName = row.detail1.map((detail) => detail.area.cf_adscri7);
  const titular = titularName.reduce(
    (acc, e) => {
      if (acc.idx[e]) acc.arr.push(" ");
      else {
        acc.arr.push(e);
      }
      acc.idx[e] = true;
      return acc;
    },
    { idx: {}, arr: [] }
  ).arr;
  return (
    <>
      {titular.map((detail) => (
        <div
          style={{ width: "200px", borderBottom: "solid 1px #EBEDF3", minHeight: "22px" }}
        >
          {detail}
        </div>
      ))}
    </>
  );
};

export const ConsumptionItemColumnFormatter_FechaSolicitud = (cellContent, row) => {
  return (
    <>
      {row.detail1.map((item) => (
        <div style={styleDiv}>
          {item.cf_fecha_solicitud ? inputFormatDate(item.cf_fecha_solicitud) : ""}
        </div>
      ))}
    </>
  );
};
export const ConsumptionItemColumnFormatter_FechaEntrega = (cellContent, row) => {
  return (
    <>
      {row.detail1.map((item) => (
        <div style={styleDiv}>
          {item.cf_fecha_entrega ? inputFormatDate(item.cf_fecha_entrega) : ""}
        </div>
      ))}
    </>
  );
};
export const ConsumptionItemColumnFormatter_FechaCancelacion = (cellContent, row) => {
  return (
    <>
      {row.detail1.map((item) => (
        <div style={styleDiv}>
          {item.cf_fecha_cancelacion ? inputFormatDate(item.cf_fecha_cancelacion) : ""}
        </div>
      ))}
    </>
  );
};

export const ConsumptionItemColumnFormatter_Total = (cellContent, row) => {
  return (
    <>
      {row.detail1.map((item) => (
        <div style={styleDiv}>{item.SumTotal ? item.SumTotal : 0}</div>
      ))}
    </>
  );
};
export const ConsumptionItemColumnFormatter_CantidadSolicitada = (cellContent, row) => {
  return (
    <>
      {row.detail1.map((item) =>
        item.warehouse_request_details.map((element) => (
          <div style={styleDiv}>
            {element.cf_cantidad_solicitada ? element.cf_cantidad_solicitada : "0"}
          </div>
        ))
      )}
    </>
  );
};
export const ConsumptionItemColumnFormatter_CantidadEntregada = (cellContent, row) => {
  return (
    <>
      {row.detail1.map((item) =>
        item.warehouse_request_details.map((element) => (
          <div style={styleDiv}>
            {item.cf_estatus !== "T"
              ? 0
              : element.cf_cantidad_entregada
              ? element.cf_cantidad_entregada
              : "0"}
          </div>
        ))
      )}
    </>
  );
};
export const ConsumptionItemColumnFormatter_CantidadComprometido = (cellContent, row) => {
  return (
    <>
      {row.detail1.map((item) =>
        item.warehouse_request_details.map((element) => (
          <div style={styleDiv}>
            {item.cf_estatus === "D" || item.cf_estatus === "H"
              ? element.cf_cantidad_surtida
              : 0}
          </div>
        ))
      )}
    </>
  );
};

export const ConsumptionItemColumnFormatter_Estatus = (cellContent, row) => {
  return (
    <>
      {row.detail1.map((item) => (
        <div>
          <span
            className={`font-weight-bolder badge badge-pill badge-${
              columnStatusCssClasses[
                item.cf_estatus === "T"
                  ? 1
                  : item.cf_estatus === "H"
                  ? 2
                  : item.cf_estatus === "B"
                  ? 0
                  : item.cf_estatus === "D"
                  ? 1
                  : item.cf_estatus === "G"
                  ? 4
                  : "light"
              ]
            }`}
          >
            {STATUSES[item.cf_estatus ? item.cf_estatus : "default"]}
          </span>
        </div>
      ))}
    </>
  );
};
export const ConsumptionItemColumnFormatter_Id = (cellContent, row) => {
  return (
    <>
      {row.detail1.map((item) => (
        <div style={styleDiv}>{item.id}</div>
      ))}
    </>
  );
};
export const ConsumptionItemColumnFormatter = (cellContent, row) => {
  return (
    <span
      className={`font-weight-bolder badge badge-pill badge-${
        columnStatusCssClasses[
          cellContent === "T"
            ? 1
            : cellContent === "H"
            ? 2
            : cellContent === "B"
            ? 0
            : cellContent === "D"
            ? 1
            : cellContent === "G"
            ? 4
            : "light"
        ]
      }`}
    >
      {STATUSES[row.cf_estatus ?? "default"]}
    </span>
  );
};
