import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Avatar,
  Chip,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 150,
    borderRadius: 4,
    padding: "5px 0px",
  },
  label: {
    marginLeft: 5,
    fontWeight: 500,
    fontSize: 14,
    color: ({ disabled, touched, hover }) =>
      (touched || hover) && !disabled ? theme.palette.secondary.main : "black",
  },
}));

const AutocompleteMultipleInput = ({
  label,
  value = [],
  name,
  onChange,
  options = [],
  searchFunction,
  placeholder,
  width,
  disabled,
  showAvatars = false,
  limitTags = -1,
}) => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [touched, setTouched] = useState(false);
  const [hover, setHover] = useState(false);
  const classes = useStyles({ disabled, touched, hover });
  const [query, setQuery] = useState("");
  const [reqOptions, setReqOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (touched && searchFunction && query.length !== 0) {
      const delayDebounceFn = setTimeout(() => {
        setLoading(true);
        searchFunction(query)
          .then((res) => setReqOptions(res))
          .finally(console.log)
          .finally(() => setLoading(false));
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [query, searchFunction, touched]);

  const handleInputChange = (e, newInputValue = "") => {
    setQuery(
      newInputValue
        ?.toUpperCase()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
    );
  };
  if (
    pathname.includes("/inventario-detalles/busqueda") ||
    pathname.includes("/inventario-detalles/bajas/busqueda") ||
    pathname.includes("obras-arte") ||
    pathname.includes("vehiculos")
  ) {
    if (typeof value === "string") {
      value = [];
    }
  }

  return (
    <div
      style={{
        width: width ? width : 200,
        maxWidth: isMobile && 200,
      }}
    >
      <Typography className={classes.label}>{label}</Typography>
      <Autocomplete
        multiple
        limitTags={limitTags}
        disabled={disabled}
        loading={loading}
        options={reqOptions?.length > 0 ? reqOptions : options}
        loadingText={
          query && loading
            ? "Cargando"
            : `Ingresa búsqueda de ${label?.toLowerCase()}`
        }
        getOptionLabel={(option) => option?.text?.toString() || ""}
        value={value}
        inputValue={query}
        onChange={(e, newValue) => {
          onChange({ target: { name, value: newValue || "" } });
          setQuery("");
        }}
        onInputChange={handleInputChange}
        renderTags={(value, getTagProps) =>
          value?.map((option, index) => {
            return (
              <Chip
                key={option.value}
                avatar={
                  showAvatars && (
                    <Avatar className="bg-info text-white">
                      {option.text && option.text[0]}
                    </Avatar>
                  )
                }
                label={option.text}
                {...getTagProps({ index })}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            variant="outlined"
            className={classes.input}
            placeholder={value?.length === 0 && (placeholder || label)}
            onBlur={(e) => setTouched(false)}
            onFocus={(e) => setTouched(true)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            InputProps={{
              ...params.InputProps,
              // Comment this class Name to enable break lines on autocomplete multiple, uncomment to no wrap
              // className: `${classes.input} overflow-hidden`,
              // className: classes.input,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress color={"inherit"} size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default AutocompleteMultipleInput;
