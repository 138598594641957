// Keep object and keys with same value, please !!

export const modeOptions = {
  search: "search",
  new: "new",
  save: "save",
  edit: "edit",
  cancel: "cancel",
  baja: "baja",
  result: "result",
};
