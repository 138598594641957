import React from 'react'
import FullTable from '../../components/FullTable'
import { columns } from './utils/data'
import { Box } from '@material-ui/core'

const MonitoringRequest = ({ requests }) => {
  return (
    <Box sx={{ height: 'calc(100vh - 180px)' }}>
    <FullTable
      hideCheckbox
      title='Solicitudes recientes'
      columns={columns}
      entities={requests}
      count={requests.length}
      search_button={false}
      has_button={false}
      hide_pagination={true}
      isMonitoring
    />
    </Box>
  )
}

export default MonitoringRequest
