import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, CircularProgress } from '@material-ui/core';
import notice from './Notice';

import { zplRequest } from '../../app/utils/zplRequest';

const Label = ({ ids, item, printConfiguration, handlePrintConfiguration, fetchZpl }) => {

    const [ loadLabel, setLoadLabel ] = useState(false);
    const [ labelImgURL, setLabelImgURL ] = useState("");

    const {
		onLabelClick,
	} = useSelector(store => store.subHeaderActions);

    useEffect(() => {
		if(onLabelClick){
      setLoadLabel(true)
			fetchZpl(item.id)
			  .then(res => {
          if (res.data) {
            zplRequest(res.data, item.cf_label_type === "NM-CH" ? "2x4" : "6x10")
            .then(async res => {
            const blob = await res.blob()
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
              let base64data = reader.result;
              setLabelImgURL(base64data)
            }
            })
            .catch(console.log)
          }
			  })
			  .catch(err => {
          console.log('err', err.response.data.error);
          if( err.response.data.error === "Inventory item is missing required EPC"){
            notice('Este articulo no cuenta con EPC, debe asignar uno', 'error');
          }else{
          notice('No se ha podido obtener la Etiqueta desde el servidor', 'error');
          }
			  })
			  .finally(() => {
          setLabelImgURL(true)
          setLoadLabel(false)
			  });
		}
		// eslint-disable-next-line
	}, [onLabelClick]);

  return (
    <Box>
      <div className="text-center">
          {
              loadLabel ? 
              <CircularProgress color="secondary" />
              :
              <div style={{ height: 300, overflow: "hidden" }}>
              <img className="img-fluid" src={labelImgURL} alt="label" />
            </div>
          }
      </div>
      <FormControl component="fieldset">
        <FormLabel component="legend">{"Configuración"}</FormLabel>
        <RadioGroup
          aria-label="configuracion"
          name="config"
          value={printConfiguration}
          onChange={handlePrintConfiguration}
        >
          <FormControlLabel
            value="current"
            control={<Radio />}
            label="Etiqueta actual"
          />

          <FormControlLabel
            disabled={Object.keys(ids).length > 1 ? false : true}
            value="all"
            control={<Radio />}
            label="Todas las etiquetas filtradas"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default Label;
