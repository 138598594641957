import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  LinearProgress,
} from "@material-ui/core";

const StockGuardFormat = ({ value, handleChange, loader }) => {
  return (
    <>
      {loader ? (
        <LinearProgress color="secondary" />
      ) : (
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Elige el tipo de resguardo que desee consultar
          </FormLabel>
          <RadioGroup
            aria-label="guardFormat"
            name="guardFormat"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel value="vehicles" control={<Radio />} label="Mobiliario" />
            <FormControlLabel
              value="artworks"
              control={<Radio />}
              label="Obras de arte"
            />
          </RadioGroup>
        </FormControl>
      )}
    </>
  );
};

export default StockGuardFormat;
