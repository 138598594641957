import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import EntryActionIcons from "../../components/EntryActionIcons";
import ModeTabs from "../../components/ModeTabs";
import notice from "../../components/Notice";
import { clearRefundRequest, defaultRefund } from "../../utils/entranceData";
import { setRefund } from "../../../redux/ducks/entrance.duck";
import { setLoader } from "../../../redux/ducks/loader.duck";
import { createRefund } from "../../crud/refundCrud";
import { clearEmptyStringsObject } from "../../utils/objectsFunctions";
const EntranceRefundActions = () => {
  const { isEntranceRefundDetailsPage, isNewPage } = useSelector(
    (store) => store.pathname
  );
  const { refund, selectedIds } = useSelector((state) => state.entrance);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = () => {
    dispatch(setLoader(true));
    const payload = clearEmptyStringsObject(clearRefundRequest(refund));
    createRefund(payload)
      .then((res) => {
        notice("Solicitud de devolucción enviada correctamente", "success");
        handleLocation(
          "/inventario-bienes-almacen-general/entradas/devolucion/resultados/" +
            res.id
        );
      })
      .catch((e) => {
        notice("No se pudo realizar la operación");
      })
      .finally(() => dispatch(setLoader(false)));
  };

  const onNewRegister = () => {
    handleLocation(
      "/inventario-bienes-almacen-general/entradas/devolucion/nuevo"
    );
    if (refund.id) dispatch(setRefund(defaultRefund));
  };

  const handleResults = () => {
    if (refund.id) {
      handleLocation(
        `/inventario-bienes-almacen-general/entradas/devolucion/resultados/${refund.id}`
      );
    } else if (selectedIds[0]) {
      handleLocation(
        `/inventario-bienes-almacen-general/entradas/devolucion/resultados/${selectedIds[0]}`
      );
    } else {
      notice("No se encontraron registros guardados", "info");
      handleLocation(
        "/inventario-bienes-almacen-general/entradas/devolucion/nuevo"
      );
    }
  };

  const handleLocation = (location) => history.push(location);

  return (
    <>
      <ModeTabs
        onNew={onNewRegister}
        onResults={handleResults}
        showIcons={{ search: false, results: true, newIcon: true }}
      />
      <EntryActionIcons
        showIcons={{
          save: isEntranceRefundDetailsPage && isNewPage,
          edit: false,
        }}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default EntranceRefundActions;
