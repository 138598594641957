import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../../components/PageLayout";
import BaseModal from "../../components/BaseModal";
import AntTabs from "../../components/AntTabs";
import ArtWorkAssignmentDataTab from "../../components/ArtWorkAssignmentDataTab";
import ArtWorkDescriptionTab from "../../components/ArtWorkDescriptionTab";
import ArtWorkFinantialDataTab from "../../components/ArtWorkFinantialDataTab";
import {
  artWorksDetailsTabs,
  getArtWorkPayload,
  getFilteredPayload,
  initialSelectedArtWork,
  validateCreateArtWorkErrors,
  inputsLabelNameToCopy,
} from "../../utils/artWorksData";
import { getCABMSKey } from "../../utils/cabmsAndKey";
import {
  setCurrentArtworkTab,
  setFilteredArtWork,
  setSelectedArtWork,
  setEnableInputs,
  setResetArtWorkForm,
  setSelectedArtWorkIds,
  clearFilteredArtwork,
} from "../../../redux/ducks/artWorks.duck";
import FileButton from "../../components/FileButton";
import { downLoaderWithURL } from "../../utils/downloaderWithURL";
import { formatDateDDMMYYYY } from "../../utils/dates";
import { isConciliated } from "../../utils/batchesUtils";
import { getNames, getTipoBaja, updateFile } from "../../crud/stockCrud";
import {
  createArtWork,
  getArtWorksList,
  getSingleArtWork,
  getSingleArtWorkDrop,
  postZplArtwork,
  updateArtWork,
  fetchGuardFormat,
  artworkReports,
  getArtWorkListPost,
  unSuscribeId,
  updateMultipleArtWorkFields,
  getArtWorkDropedListPost,
  fetchGuardArtWorkList,
  unSuscribeReasonArtWork,
} from "../../crud/artWorksCrud";
import notice from "../../components/Notice";
import PDFUploaderTab from "../../components/PDFUploaderTab";
import SimpleInput from "../../components/SimpleInput";
import ReportContent from "../../components/Reports";
import Labels from "../../components/Labels";
import { useSearchMode } from "../../hooks/useSearchMode";
import useSubheaderAction from "../../hooks/useSubheaderAction";
import { useEnterSearch } from "../../hooks/useEnterSearch";
import { isAValidAmount, isAValidInteger } from "../../utils/inputValidations";
import {
  cleanObject,
  cleanObjectWithZero,
  clearEmptyStringsObject,
} from "../../utils/objectsFunctions";
import {
  setCurrentMode,
  setIsEditDisabled,
  setLabel,
  setOnReportClickFunction,
  setOnLabelClick,
  setOnFormatGuardClick,
} from "../../../redux/ducks/subHeaderActions.duck";
import { setLoader } from "../../../redux/ducks/loader.duck";
import SimplePagination from "../../components/SimplePagination";
import CheckboxList from "../../components/CheckboxList";
import CustomButton from "../../components/CustomButton";
import DropInputsForm from "../../components/DropInputsForm";
import UtilInputs from "../../components/UtilInputs";
import moment from "moment";
import UploadFileButton from "../../components/UploadFileButton";
import { Badge, Box, Typography, Button } from "@material-ui/core";
import { defaultDoc } from "../DigitalVault/utils";
import FileIconImg from "../../components/FileIconImg";
import { digitalizationWithModule } from "../../crud/digitalVaultCrud";
import DocumentsList from "../../components/DocumentsList";
import { modeOptions } from "../../utils/subheaderIconsData";

moment.locale("es");
const ArtWorksDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    onSearchClick2,
    onNewClick2,
    onCancelClick2,
    onEditClick2,
    onSaveClick2,
    onSearchClick3,
    onNewClick3,
    onCancelClick3,
    onEditClick3,
    onReportClick,
    unSuscribe,
    onFormatGuardClick,
  } = useSelector((store) => store.subHeaderActions);

  const {
    selectedArtWork,
    filteredArtWork,
    selectedArtWorksIds,
    enableInputs,
    resetArtWorkForm,
  } = useSelector((store) => store.artWork);
  const { isDetailsPage, isSearchPage, isResultsPage, isNewPage } = useSelector(
    (store) => store.pathname
  );
  const { idsFilterResult } = useSelector((store) => store.stock);
  const { user, authToken, permisos } = useSelector((store) => store.auth);
  const newFormData = isSearchPage ? filteredArtWork : selectedArtWork;
  const [listDocs, setListDocs] = useState({});
  const isDropPage = pathname.includes("bajas");
  const [selectedTab, setSelectedTab] = useState(1);
  const [doc, setDoc] = useState(defaultDoc);
  const [openBajaModal, setOpenBajaModal] = useState(false);
  const [disableBaja, setDisableBaja] = useState(true);
  const [tipoBajaList, setTipoBajaList] = useState([]);

  const { currentMode, onLabelClick } = useSelector((store) => store.subHeaderActions);
  // Configuration to print
  const [firstLetterPartida, setFirstLetterPartida] = useState("");

  const [printConfiguration, setPrintConfiguration] = useState("current");
  const [printLabel, setPrintLabel] = useState(false);
  const [reportsModal, setReportsModal] = useState(false);
  const [artWorkDataAvailableToCopy, setArtWorkDataAvailableToCopy] = useState({});

  useEffect(() => {
    if (newFormData) {
      setListDocs(newFormData?.all_attachments || {});
    }
  }, [newFormData]);
  const handlePrintConfiguration = (event) => {
    setPrintConfiguration(event.target.value);
  };

  useEffect(() => {
    if (onReportClick) {
      setReportsModal(true);
    }
  }, [onReportClick]);
  useEffect(() => {
    dispatch(setIsEditDisabled(!!selectedArtWork?.id));
  }, [selectedArtWork.id, dispatch]);
  const fetchParamsArtWork = useCallback(() => {
    dispatch(setLoader(true));
    if (isDropPage)
      getSingleArtWorkDrop(id)
        .then((res) => {
          dispatch(setSelectedArtWork(res));
        })
        .catch((error) => {
          console.log("error fetching artWorks", error);
          notice("Ha ocurrido un error al cargar la Obra de Arte" + id, "error");
        })
        .finally(() => dispatch(setLoader(false)));
    else {
      dispatch(setEnableInputs(false));
      getSingleArtWork(id)
        .then((res) => {
          const cveFirstLetter = res?.cf_cve_partida?.text2?.toString().charAt(0) || "";
          dispatch(
            setSelectedArtWork({
              ...res,
              user: res.changed_by?.first_name,
              cf_cve_prove: {
                id: res?.supplier?.id,
                text: res?.supplier?.razon_social?.trim(),
                text2: res?.supplier?.num_prove,
              },
              num_partida: res?.partida?.num_partida
                ? res?.partida?.num_partida.toString()
                : null,
              cf_no_cuenta: res?.partida?.no_cuenta ? res?.partida?.no_cuenta : null,
              cf_no_scuenta: res?.partida?.no_scuenta ? res?.partida?.no_scuenta : null,
              cf_conciliado: isConciliated(cveFirstLetter, res),
            })
          );
          dispatch(
            setResetArtWorkForm({
              ...res,
              user: res.changed_by?.first_name,
              cf_cve_prove: {
                id: res?.supplier?.id,
                text: res?.supplier?.razon_social?.trim(),
                text2: res?.supplier?.num_prove,
              },
              num_partida: res?.partida?.num_partida
                ? res?.partida?.num_partida.toString()
                : null,
              cf_no_cuenta: res?.partida?.no_cuenta ? res?.partida?.no_cuenta : null,
              cf_no_scuenta: res?.partida?.no_scuenta ? res?.partida?.no_scuenta : null,
              cf_conciliado: isConciliated(cveFirstLetter, res),
            })
          );
          dispatch(setIsEditDisabled(false));
        })
        .catch((error) => {
          console.log("error fetching artWorks", error);
          notice("Ha ocurrido un error al cargar la Obra de Arte" + id, "error");
        })
        .finally(() => dispatch(setLoader(false)));
    }
  }, [dispatch, id, isDropPage]);

  useEffect(() => {
    if (id) fetchParamsArtWork();
  }, [id, fetchParamsArtWork]);

  // useEffect(() => () => dispatch(clearSelectedArtWork()), [dispatch]);

  useEffect(() => {
    return () => dispatch(setEnableInputs(true));
  }, [dispatch]);

  const handleClickTab = (index) => {
    if (isDropPage) {
      history.push(
        `/bienes-instrumentales/obras-arte/resultados/bajas/${
          selectedArtWorksIds[index - 1]
        }`
      );
    } else {
      history.push(
        `/bienes-instrumentales/obras-arte/resultados/${selectedArtWorksIds[index - 1]}`
      );
    }
  };

  const onValuesChange = (e) => {
    let { name, value } = e.target;
    let labelName = inputsLabelNameToCopy.find(({ name }) => name === e.target.name);

    if (labelName && currentMode === "edit") {
      if (name === "cabms_item") {
        const obj = {
          cf_grupoc: value.cf_grupo,
          cf_subgrupoc: value.cf_subgrupo,
          cf_clasec: value.cf_clase,
          cf_subclasec: value.cf_subclase,
          cf_tipoc: value.cf_tipo,
        };
        setArtWorkDataAvailableToCopy({
          ...artWorkDataAvailableToCopy,
          cabms: {
            name: "cabms",
            label: labelName.label,
            value: obj,
          },
        });
      } else {
        setArtWorkDataAvailableToCopy({
          ...artWorkDataAvailableToCopy,
          [labelName.name]: {
            name: name,
            label: labelName.label,
            value,
            value,
          },
        });
      }
    }
    if (e && e.target) {
      const { value, name } = e.target;
      let newArtWork = {};
      if (name === "item" && value.text) {
        newArtWork = {
          ...newFormData,
          [name]: value.text,
          key: getCABMSKey(value),
        };
      } else if (name === "cabms_item" && value.text) {
        newArtWork = {
          ...newFormData,
          [name]: value.text,
          cabms_key: getCABMSKey(value),
        };
      } else if (name === "cf_num_area") {
        const {
          num_resguardo,
          area_attendant,
          num_ubica: cf_ubicacion,
          g,
          sg,
          ssg,
          ubicacion,
        } = value;
        const { edificio, piso, oficina, soficina } = ubicacion ?? {};
        const cf_responsables = getNames(area_attendant);
        const cf_fecha_actualizacion = formatDateDDMMYYYY();
        const ubicationParams =
          // before was g, sg and ssg, change condition to take one or put another codition (ex: if is a results page)
          true
            ? {
                edificio,
                piso,
                oficina,
                soficina,
              }
            : {
                piso: g,
                oficina: sg,
                suboficina: ssg,
              };
        newArtWork = {
          ...newFormData,
          [name]: value,
          responsable: cf_responsables,
          ubicacion: cf_ubicacion,
          cf_responsables,
          cf_ubicacion,
          g,
          sg,
          ssg,
          cf_fecha_actualizacion,
          num_resguardo,
          responsables: cf_responsables,
          ...ubicationParams,
        };
      } else if (name === "num_partida") {
        const cveFirstLetter = value.text2?.toString().charAt(0) || "";
        setFirstLetterPartida(cveFirstLetter);
        const cf_conciliado = isConciliated(cveFirstLetter, newFormData);
        const cf_no_cuenta = value.no_cuenta || "0";
        const cf_no_scuenta = value.no_scuenta || "0";
        const descripcion_partida = value.text;
        newArtWork = {
          ...newFormData,
          [name]: value,
          cf_no_cuenta,
          cf_no_scuenta,
          cf_conciliado,
          descripcion_partida,
        };
      } else if (
        name === "cf_no_poliza" ||
        name === "cf_fecha_poliza" ||
        name === "cf_tipo_poliza"
      ) {
        const cf_conciliado = isConciliated(firstLetterPartida, newFormData);
        newArtWork = { ...newFormData, [name]: value, cf_conciliado };
      } else if (
        name === "cf_no_inventa" &&
        (!isAValidInteger(value) || Number(value) > 2147483647)
      ) {
        return;
      } else if (
        ["cf_costo", "cf_importe", "cf_valor_avaluo"].includes(name) &&
        (!isAValidAmount(value) || Number(value) < 0 || Number(value) > 999999999.99)
      ) {
        return;
      } else if (
        ["cf_anio_resguardo", "cf_anio"].includes(name) &&
        (!isAValidInteger(value) || Number(value) >= 3000)
      ) {
        return;
      } else if (["cf_tipo", "cf_no_poliza"].includes(name) && !isAValidInteger(value)) {
        return;
      } else if (
        ["cf_no_poliza"].includes(name) &&
        (!isAValidInteger(value) || Number(value) >= 2147483647)
      ) {
        return;
      } else if (
        ["cf_id_tipo_obra"].includes(name) &&
        (!isAValidInteger(value) || Number(value) >= 32767)
      ) {
        return;
      } else {
        newArtWork = {
          ...newFormData,
          [name]: value,
          cf_conciliado: isConciliated(
            newFormData.cf_cve_partida?.text2?.toString()[0],
            newFormData
          ),
        };
        newArtWork = {
          ...newArtWork,
          cf_conciliado: isConciliated(
            newArtWork.cf_cve_partida?.text2?.toString()[0],
            newArtWork
          ),
        };
      }
      dispatch(setSelectedArtWork(newArtWork));
    }
  };

  const handleChangePhoto = (file) => {
    if (file) {
      dispatch(setLoader(true));
      updateFile(file, selectedArtWork.id, "artwork", file.name)
        .then((res) => {
          const image_artwork = res;
          const newArtWork = {
            ...selectedArtWork,
            image_artwork,
          };
          dispatch(setSelectedArtWork(newArtWork));
          notice("¡Imagen de obra de arte actualizada exitosamente!", "success");
        })
        .catch((error) => notice("No fué posible subir la imagen al servidor", "error"))
        .finally(() => dispatch(setLoader(false)));
    }
  };

  const onSuccessUploadPDFs = (responses) => {
    const newPDFAttachments = responses.map((res) => res.data);
    const newSelectedArtWork = {
      ...selectedArtWork,
      all_attachments: {
        ...selectedArtWork.all_attachments,
        pdfs: [...selectedArtWork.all_attachments.pdfs, ...newPDFAttachments],
      },
    };
    dispatch(setSelectedArtWork(newSelectedArtWork));
    dispatch(setResetArtWorkForm(newSelectedArtWork));
  };

  const onSucessRemovePDF = (deletedPDFId) => {
    const newPDFAttachments = selectedArtWork.all_attachments.pdfs.filter(
      (attachment) => attachment.id !== deletedPDFId
    );
    const newSelectedArtWork = {
      ...selectedArtWork,
      all_attachments: {
        ...selectedArtWork.all_attachments,
        pdfs: newPDFAttachments,
      },
    };
    dispatch(setSelectedArtWork(newSelectedArtWork));
    dispatch(setResetArtWorkForm(newSelectedArtWork));
  };

  useEffect(() => {
    const newTabs = pathname.includes("busqueda")
      ? {
          isSearchTab: true,
          isResultsTab: false,
          isNewTab: false,
        }
      : pathname.includes("nuevo")
      ? {
          isSearchTab: false,
          isResultsTab: false,
          isNewTab: true,
        }
      : {
          isSearchTab: false,
          isResultsTab: true,
          isNewTab: false,
        };
    dispatch(setCurrentArtworkTab(newTabs));
    getTipoBaja().then((res) => {
      const listTipoBaja = res.results.map((tipo) => ({
        value: tipo.id,
        text: tipo.nombre,
      }));
      setTipoBajaList(listTipoBaja);
    });
  }, [pathname, dispatch]);

  useEffect(() => {
    if (isResultsPage && selectedArtWork?.id)
      dispatch(setLabel(`No. Inventario ${selectedArtWork?.id}`));
  }, [dispatch, isResultsPage, selectedArtWork?.id]);

  useSearchMode("/bienes-instrumentales/obras-arte/busqueda");

  const handleSearchForm = (e) => {
    if (e?.target) {
      const { name, value } = e.target;
      let newData;
      if (name === "num_partida") {
        const cveFirstLetter = value.text2?.toString().charAt(0) || "";
        setFirstLetterPartida(cveFirstLetter);
        const cf_conciliado = isConciliated(cveFirstLetter, newFormData);
        const cf_no_cuenta = value.no_cuenta || "0";
        const cf_no_scuenta = value.no_scuenta || "0";
        const desc_partida = value.text;
        newData = {
          ...newFormData,
          [name]: value,
          cf_no_cuenta,
          cf_no_scuenta,
          cf_conciliado,
          desc_partida,
        };
      } else if (name === "cf_no_poliza") {
        const cf_conciliado = isConciliated(firstLetterPartida, newFormData);
        newData = { ...newFormData, [name]: value, cf_conciliado };
      } else if (name === "cf_fecha_poliza") {
        const cf_conciliado = isConciliated(firstLetterPartida, newFormData);
        newData = { ...newFormData, [name]: value, cf_conciliado };
      } else if (name === "cf_tipo_poliza") {
        const cf_conciliado = isConciliated(firstLetterPartida, newFormData);
        newData = { ...newFormData, [name]: value, cf_conciliado };
      } else {
        newData = { ...newFormData, [name]: value };
      }

      dispatch(setFilteredArtWork({ ...newData, [name]: value }));
    }
  };

  const onValuesChangeFunction = isSearchPage ? handleSearchForm : onValuesChange;
  const clearForms = () => {
    if (isSearchPage) {
      dispatch(clearFilteredArtwork());
    } else if (isNewPage) {
      dispatch(
        setSelectedArtWork({
          ...initialSelectedArtWork,
          user: user.first_name,
        })
      );
    } else if (isResultsPage) {
      dispatch(setSelectedArtWork(resetArtWorkForm));
    }
  };

  const searchDropedArtWork = async () => {
    try {
      dispatch(setLoader(true));
      let payload = getFilteredPayload(filteredArtWork);
      payload = cleanObject(payload);
      payload = clearEmptyStringsObject(payload);
      delete payload.user;
      delete payload.cabms;
      const filter_params = clearEmptyStringsObject(
        cleanObjectWithZero({ ...filteredArtWork })
      );

      if (filter_params?.motivo_baja) {
        filter_params["obra_arte__motivo_baja"] = filter_params["motivo_baja"];
        delete filter_params["motivo_baja"];
      }

      if (filter_params?.tipo_baja_descripcion) {
        filter_params["obra_arte__tipo_baja__descripcion"] =
          filter_params["tipo_baja_descripcion"];
        delete filter_params["tipo_baja_descripcion"];
      }
      if (filter_params?.fecha_baja) {
        filter_params["obra_arte__fecha_baja"] = filter_params["fecha_baja"];
        delete filter_params["fecha_baja"];
      }
      let filterParams = {
        search_params: filter_params,
        estatus: false,
      };
      let res = await getArtWorkDropedListPost(filterParams);
      const { data } = res;
      if (res && data.length > 0) {
        dispatch(setSelectedArtWorkIds(data.map((d) => d.id)));
        dispatch(setFilteredArtWork(initialSelectedArtWork));
        history.push(`/bienes-instrumentales/obras-arte/resultados/bajas/${data[0]?.id}`);

        dispatch(setIsEditDisabled(false));
        dispatch(setCurrentMode(null));
      } else {
        notice("No se encontraron Obras de arte que coincidan con su búsqueda", "info");
      }
    } catch (error) {
      notice(error, "error");
      clearForms();
    } finally {
      dispatch(setLoader(false));
      clearForms();
    }
  };
  const searchArtworks = async () => {
    try {
      dispatch(setLoader(true));
      let payload = getFilteredPayload(filteredArtWork);
      payload = cleanObject(payload);
      payload = clearEmptyStringsObject(payload);
      delete payload.user;
      delete payload.cabms;
      const filter_params = clearEmptyStringsObject(
        cleanObjectWithZero({ ...filteredArtWork })
      );
      if (filter_params.hasOwnProperty("responsables")) {
        filter_params.custom_responsables = filter_params.responsables.map(
          (responsable) => responsable.value
        );
        delete filter_params.responsables;
      }
      let filterParams = {
        search_params: filter_params,
      };

      let res = await getArtWorkListPost(filterParams);
      const { data } = res;
      if (res && data.length > 0) {
        dispatch(setSelectedArtWorkIds(data.map((d) => d.id)));
        dispatch(setFilteredArtWork(initialSelectedArtWork));
        history.push(`/bienes-instrumentales/obras-arte/resultados/${data[0]?.id}`);
        dispatch(setIsEditDisabled(false));
        dispatch(setCurrentMode(null));
      } else {
        notice("No se encontraron Obras de arte que coincidan con su búsqueda", "info");
        dispatch(setFilteredArtWork(initialSelectedArtWork));
      }
    } catch (error) {
      notice(error, "error");
      dispatch(setCurrentMode("search"));
    } finally {
      dispatch(setLoader(false));
    }
  };
  useEnterSearch(searchArtworks);

  const guardFormat = async () => {
    dispatch(setLoader(true));
    let idList = selectedArtWorksIds;
    try {
      const filename = `Resguardo-${moment().format("YYYY-MM-DD")}`;
      const res = await fetchGuardArtWorkList(idList);
      const formatAURL = res.data.attachment_url;
      downLoaderWithURL(formatAURL, filename + ".pdf");
    } catch (error) {
      console.log("error", error?.response);
      notice(
        `No se ha podido obtener el documento de resguardo desde el servidor`,
        "error"
      );
    } finally {
      dispatch(setOnFormatGuardClick(null));
      dispatch(setLoader(false));
    }
  };

  const printZpl = () => {
    let ids =
      printConfiguration === "current" ? selectedArtWork.id : selectedArtWorksIds.join();
    const a = document.createElement("a");
    a.href = `nextwmsftp://?token=${authToken}?ids=${ids}?type=artwork`;
    a.click();
    setTimeout(() => {
      setPrintLabel(false);
      setPrintConfiguration("current");
    }, 3000);
    dispatch(setOnLabelClick(""));
  };

  const handleCreateArtWork = () => {
    if (validateCreateArtWorkErrors(selectedArtWork)) {
      dispatch(setLoader(true));
      let payload = getArtWorkPayload(selectedArtWork);
      payload = clearEmptyStringsObject(payload);
      createArtWork(payload)
        .then((res) => {
          if (res && [200, 201].includes(res.status)) {
            dispatch(setLoader(false));
            dispatch(setCurrentMode(null));
            dispatch(setSelectedArtWork(initialSelectedArtWork));
            notice("¡Se creo el registro de Obra de Arte exitosamente!", "success");
          } else {
            dispatch(setLoader(false));
            dispatch(setCurrentMode("new"));
            notice("No se pudo crear el registro de Obra de Arte", "error");
          }
        })
        .catch((error) => {
          dispatch(setLoader(false));
          console.log("error", error);
          notice("No se pudo crear el registro de Obra de Arte", "error");
          dispatch(setCurrentMode("new"));
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };

  const handleEditArtWork = () => {
    dispatch(setLoader(true));
    const { id } = selectedArtWork;
    let payload = getArtWorkPayload(selectedArtWork);
    payload = clearEmptyStringsObject(payload);

    updateArtWork(id, payload)
      .then((res) => {
        if (res && [200, 201].includes(res.status)) {
          dispatch(setLoader(false));
          notice("¡Se actualizó la Obra de Arte exitosamente!", "success");
          dispatch(setCurrentMode(null));
        } else {
          dispatch(setCurrentMode("edit"));
          dispatch(setLoader(false));
          notice("No se pudo actualizar la Obra de Arte", "error");
        }
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(setLoader(false));
        dispatch(setCurrentMode("edit"));
        notice("No se pudo actualizar la Obra de Arte", "error");
      })
      .finally(() => dispatch(setLoader(false)));
  };
  //dar de baja
  const unSuscribeFunction = () => {
    setOpenBajaModal(true);
  };

  const functionBaja = () => {
    dispatch(setLoader(true));
    if (pathname.includes("bajas")) {
      unSuscribeReasonArtWork(id, bajaForm)
        .then((res) => {
          notice("Se edito la baja de la obra de Arte", "success");
          fetchParamsArtWork();
          setOpenBajaModal(false);
        })
        .catch((error) => {
          notice("Ha ocurrido un error al editar la Obra de Arte" + id, "error");
        })
        .finally(() => dispatch(setLoader(false)));
    } else {
      unSuscribeId(id, bajaForm)
        .then((res) => {
          notice("Se ha dado de baja la Obra de Arte", "success");
          setTimeout(() => {
            history.push(`/bienes-instrumentales/obras-arte/bajas`);
          }, 2000);
        })
        .catch((error) => {
          notice("Ha ocurrido un eliminar la Obra de Arte" + id, "error");
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };

  useSubheaderAction({
    action: onSearchClick2,
    isInPage: pathname.includes("/busqueda"),
    path: isDropPage
      ? "/bienes-instrumentales/obras-arte/busqueda/bajas"
      : "/bienes-instrumentales/obras-arte/busqueda",
  });

  useSubheaderAction({
    action: onSearchClick3,
    func: isDropPage ? searchDropedArtWork : searchArtworks,
    isInPage: pathname.includes("/busqueda"),
    path: isDropPage
      ? "/bienes-instrumentales/obras-arte/busqueda/bajas"
      : "/bienes-instrumentales/obras-arte/busqueda",
  });

  useSubheaderAction({
    action: onNewClick2,
    isInPage: pathname.includes("/nuevo"),
    path: "/bienes-instrumentales/obras-arte/nuevo",
  });
  useSubheaderAction({
    action: onNewClick3,
    isInPage: pathname.includes("/nuevo"),
    path: "/bienes-instrumentales/obras-arte/nuevo",
  });

  useSubheaderAction({
    action: onEditClick2,
    isInPage: pathname.includes("/resultados"),
    path: selectedArtWorksIds[0]
      ? `/bienes-instrumentales/obras-arte/resultados/${selectedArtWorksIds[0]}`
      : false,
  });

  useSubheaderAction({
    action: onEditClick3,
    //func: editEntry,
    isInPage: pathname.includes("/resultados"),
    path: selectedArtWorksIds[0]
      ? `/bienes-instrumentales/obras-arte/resultados/${selectedArtWorksIds[0]}`
      : false,
  });

  useSubheaderAction({
    action: onCancelClick2,
    func: clearForms,
    isInPage: isDetailsPage,
  });

  useSubheaderAction({
    action: onCancelClick3,
    func: clearForms,
    isInPage: isDetailsPage,
  });

  useSubheaderAction({
    action: onSaveClick2,
    func: isResultsPage ? handleEditArtWork : handleCreateArtWork,
    isInPage: true,
  });

  useSubheaderAction({
    action: onFormatGuardClick,
    func: guardFormat,
    isInPage: true,
  });

  useSubheaderAction({
    action: unSuscribe,
    func: unSuscribeFunction,
    isInPage: true,
  });
  useEffect(() => {
    if (onLabelClick) {
      setPrintLabel(true);
    }
  }, [onLabelClick]);

  const [openModalToCopy, setOpenModalToCopy] = useState(false);
  const [dataToCopy, setDataToCopy] = useState([]);

  const copyDataModal = () => {
    setOpenModalToCopy(true);
    setDataToCopy(Object.keys(artWorkDataAvailableToCopy));
  };
  const handleCopyToAllSelectedArtWork = () => {
    dispatch(setLoader(true));

    let values = dataToCopy
      .map((item) => artWorkDataAvailableToCopy[item])
      .reduce((obj, item) => ((obj[item.name] = item.value), obj), {});
    const requestedData = selectedArtWorksIds.map((id) => ({
      id,
      ...values,
    }));
    updateMultipleArtWorkFields(requestedData)
      .then((res) => {
        setOpenModalToCopy(false);
        notice("Se han editado las obras de arte exitosamente", "success");
      })
      .finally(() => {
        dispatch(setCurrentMode(null));

        setArtWorkDataAvailableToCopy({});
        setOpenModalToCopy(false);
        dispatch(setLoader(false));
      });
  };

  const onChangeDataToCopy = (data) => {
    setDataToCopy(data);
  };
  const [bajaForm, setBajaForm] = useState({});
  const closeFunction = () => setOpenBajaModal(false);
  useEffect(() => {
    if (bajaForm.motivo_baja && bajaForm.tipo_baja) {
      setDisableBaja(false);
    } else {
      setDisableBaja(true);
    }
  }, [bajaForm]);

  const inputsBaja = [
    {
      label: "Motivo de baja",
      name: "motivo_baja",
      type: "text",
    },
    {
      label: "Tipo de baja",
      name: "tipo_baja",
      type: "dropdown",
      options: tipoBajaList,
    },
    {
      label: "Fecha de baja",
      name: "fecha_baja",
      type: "date",
    },
  ];

  const handleChangeBaja = (e) => {
    setBajaForm({ ...bajaForm, [e.target.name]: e.target.value });
  };

  const updateListDocs = (newDocs) => {
    const newDocsObject =
      module === "artwork"
        ? { "Recientemente subido": newDocs }
        : { "Recientemente digitalizados": newDocs };
    setListDocs({ ...newDocsObject, ...listDocs });
  };
  const handleAttachments = (files) => {
    setDoc({ ...doc, attachments: files });
  };

  const onSubmit = (allAttach) => {
    if (allAttach) {
      const listIds = selectedArtWorksIds.toString().replaceAll(",", "|");
      const request = digitalizationWithModule("artwork", listIds, doc.attachments);
      dispatch(setLoader(true));
      request
        .then((res) => {
          updateListDocs(res);
          setDoc(defaultDoc);

          notice("Se han cargado los archivos con éxito", "success");
        })
        .catch((e) => {
          if (e.hasOwnProperty("url")) {
            notice("Debe seleccionar al menos un archivo", "info");
          } else {
            notice("Ha ocurrido un error al cargar sus archivos");
          }
        })
        .finally(() => dispatch(setLoader(false)));
    } else {
      const request = digitalizationWithModule("artwork", id, doc.attachments);
      dispatch(setLoader(true));
      request
        .then((res) => {
          updateListDocs(res);
          setDoc(defaultDoc);

          notice("Se han cargado los archivos con éxito", "success");
        })
        .catch((e) => {
          if (e.hasOwnProperty("url")) {
            notice("Debe seleccionar al menos un archivo", "info");
          } else {
            notice("Ha ocurrido un error al cargar sus archivos");
          }
        })
        .finally(() => {
          fetchParamsArtWork();
          dispatch(setLoader(false));
        });
    }
  };
  const handleRemoveAttachment = (index) => {
    const updatedAtt = doc.attachments;
    updatedAtt.splice(index, 1);
    setDoc({ ...doc, attachments: [...updatedAtt] });
  };

  return (
    <PageLayout
      // title=
      header={
        <Fragment>
          <div className="w-100">
            <div className="d-flex justify-content-between mb-3 w-100">
              <h6>
                {(isDropPage
                  ? "Bajas"
                  : pathname.includes("busqueda")
                  ? "Búsqueda"
                  : "Inventario") + " de Obras de Arte"}
              </h6>
              <div className="d-flex">
                {!isDropPage &&
                  !pathname.includes("busqueda") &&
                  selectedArtWork.id &&
                  permisos.permisos.includes("i-subir-archivo-obra-arte") && (
                    <FileButton
                      disabled={!currentMode}
                      handleChange={handleChangePhoto}
                      value={selectedArtWork.image_artwork}
                      buttonTitle="Subir foto"
                    />
                  )}
                {selectedArtWorksIds.length > 1 && !isDropPage && !isSearchPage && (
                  <CustomButton
                    outline
                    title={
                      <>
                        <i className="flaticon2-copy mr-2" />
                        {`Copiar (${Object.keys(artWorkDataAvailableToCopy).length})`}
                      </>
                    }
                    disabled={Object.keys(artWorkDataAvailableToCopy).length === 0}
                    color="info"
                    onClick={() => {
                      copyDataModal();
                    }}
                  />
                )}
              </div>
            </div>

            {pathname.includes("resultados") && selectedArtWorksIds.length > 1 && (
              <SimplePagination
                current={id}
                data={selectedArtWorksIds}
                handleClickItem={handleClickTab}
              />
            )}
          </div>

          <div className="w-40 d-flex justify-content-between">
            {!isNewPage && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div className="m-2">
                    <SimpleInput
                      focus={isSearchPage ? true : false || true}
                      width={200}
                      name="id"
                      label="No. inventario"
                      onChange={onValuesChangeFunction}
                      disabled={isResultsPage || !currentMode}
                      value={newFormData.id}
                    />
                  </div>
                  <div className="m-2">
                    <SimpleInput
                      width={200}
                      name="cf_no_inventa"
                      label="No. inventario Anterior"
                      onChange={onValuesChangeFunction}
                      disabled={isResultsPage || !currentMode}
                      value={newFormData.cf_no_inventa}
                    />
                  </div>
                </div>

                {isDropPage && (
                  <div className="my-3">
                    <DropInputsForm
                      formValues={newFormData}
                      onValuesChange={onValuesChangeFunction}
                    />
                  </div>
                )}
              </div>
            )}

            <BaseModal
              title={<span>Seleccione los campos</span>}
              open={openModalToCopy}
              onClose={() => setOpenModalToCopy(false)}
              onConfirm={handleCopyToAllSelectedArtWork}
              confirmLabel="Guardar y copiar"
              onCancel={() => setOpenModalToCopy(false)}
              cancelLabel="Cerrar"
              maxWidth="sm"
              color="secondary"
              disableOnConfirm={false}
            >
              <CheckboxList
                data={artWorkDataAvailableToCopy}
                onChangeData={onChangeDataToCopy}
              />
            </BaseModal>
          </div>
        </Fragment>
      }
    >
      <AntTabs
        selectedValue={selectedTab}
        onChangeTab={(e, newTab) => setSelectedTab(newTab)}
        tabs={
          !pathname.includes("busqueda") && selectedArtWork.id
            ? artWorksDetailsTabs
            : artWorksDetailsTabs.filter((tab) => tab.label !== "Documentos")
        }
      />

      {selectedTab === 0 ? (
        <Fragment>
          <ArtWorkAssignmentDataTab
            formValues={newFormData}
            onValuesChange={onValuesChangeFunction}
          />
        </Fragment>
      ) : selectedTab === 1 ? (
        <Fragment>
          <ArtWorkDescriptionTab
            formValues={newFormData}
            onValuesChange={onValuesChangeFunction}
          />
        </Fragment>
      ) : selectedTab === 2 ? (
        <Fragment>
          <ArtWorkFinantialDataTab
            formValues={newFormData}
            onValuesChange={onValuesChangeFunction}
          />
        </Fragment>
      ) : (
        <Fragment>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                color={"secondary"}
                variant="subtitle1"
                style={{ position: "static" }}
              >
                {Object.keys(listDocs).length ? " Resguardos" : " Sin resguardos"}
              </Typography>

              <FileButton
                isIconButton
                multiple
                accept="*"
                buttonTitle="Seleccionar nuevos archivos"
                handleChange={handleAttachments}
              />
            </div>
          </Box>
          <div
            className="d-flex mt-4"
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {doc.attachments?.map((att, i) => (
              <Badge
                badgeContent="X"
                color="error"
                key={att.name}
                style={{ cursor: "pointer" }}
                onClick={() => handleRemoveAttachment(i)}
              >
                <FileIconImg name={att.name} type={att.type} width={50} />
              </Badge>
            ))}
            {doc.attachments?.length > 0 && selectedArtWorksIds.length > 1 ? (
              <div style={{ marginTop: 10 }}>
                <Button variant="text" color="secondary" onClick={() => onSubmit(true)}>
                  {`Digitalizar todos [${selectedArtWorksIds.map((x) => x)}]`}`
                </Button>
                <Button variant="text" color="secondary" onClick={() => onSubmit(false)}>
                  {`Digitalizar ${id}`}`
                </Button>
              </div>
            ) : (
              doc.attachments?.length > 0 && (
                <div style={{ marginTop: 10 }}>
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => onSubmit(false)}
                  >
                    {`Digitalizar ${id}`}`
                  </Button>
                </div>
              )
            )}
          </div>
          {Object.keys(listDocs).length > 0 ? (
            <DocumentsList data={listDocs} setListDocs={setListDocs} module={module} />
          ) : (
            <p>No se encontraron archivos de ese año</p>
          )}
        </Fragment>
      )}

      <BaseModal
        title={
          <span>
            Etiqueta del bien <strong>{selectedArtWork.id}</strong>
          </span>
        }
        open={printLabel}
        onClose={() => {
          setPrintLabel(false);
          dispatch(setOnLabelClick(""));
        }}
        onConfirm={printZpl}
        confirmLabel="Imprimir"
        onCancel={() => {
          setPrintLabel(false);
          dispatch(setOnLabelClick(""));
        }}
        cancelLabel="Cerrar"
        maxWidth="sm"
        color="secondary"
        disableOnConfirm={false}
      >
        <Labels
          ids={selectedArtWorksIds}
          item={selectedArtWork}
          handlePrintConfiguration={handlePrintConfiguration}
          printConfiguration={printConfiguration}
          fetchZpl={postZplArtwork}
        />
      </BaseModal>
      <BaseModal
        open={reportsModal}
        onClose={() => {
          setReportsModal(false);
          dispatch(setOnReportClickFunction(""));
        }}
        disableOnConfirm={false}
        title="DESCARGAR REPORTE"
      >
        <ReportContent
          report={"reports-artworks"}
          data={
            selectedArtWorksIds.length === 0 ? selectedArtWork.id : selectedArtWorksIds
          }
          reportRequest={artworkReports}
        />
      </BaseModal>
      <BaseModal
        open={openBajaModal}
        onClose={closeFunction}
        disableOnConfirm={false}
        onConfirm={functionBaja}
        onCancel={closeFunction}
        cancelLabel="Cerrar"
        title="Baja"
      >
        <UtilInputs
          inputValues={inputsBaja}
          onValuesChange={handleChangeBaja}
          formValues={bajaForm}
          width={200}
        />
      </BaseModal>
    </PageLayout>
  );
};

export default ArtWorksDetailsPage;
