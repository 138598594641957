import React from "react";
import {
  Collapse,
  ListItemIcon,
  MenuItem,
  MenuList,
  Typography,
} from "@material-ui/core";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const MoveToFolderCollapse = ({
  in: open = false,
  folders = [],
  onClickFolder,
  onClickIcon,
  onClickBack,
  menuListProps = {},
  withArrow,
  isMainFolder,
}) => (
  <Collapse
    in={open}
    timeout="auto"
    unmountOnExit
    style={{ maxHeight: "60vh", minWidth: 300 }}
    classes={{
      wrapper: "overflow-auto mh-100",
    }}
  >
    <MenuList {...menuListProps}>
      <>
        {!isMainFolder && (
          <MenuItem onClick={onClickBack}>
            <Typography variant="inherit">Regresar</Typography>
            <ListItemIcon className="mt-auto mb-auto mr-auto ml-5">
              <ArrowBackIcon />
            </ListItemIcon>
          </MenuItem>
        )}
        {
          <MenuItem key="1" onClick={() => onClickFolder(null)}>
            <Typography variant="inherit">Raiz</Typography>
            <ListItemIcon
              style={{ padding: 8 }}
              className="ml-auto mt-auto mb-auto mr-0"
              onClick={(e) => {
                e.stopPropagation();
                onClickIcon(null);
              }}
            >
              <MenuOpenIcon />
            </ListItemIcon>
          </MenuItem>
        }
        {folders.length > 0 &&
          folders.map((folder) => (
            <MenuItem key={folder?.id} onClick={() => onClickFolder(folder)}>
              {withArrow ? (
                <>
                  <Typography variant="inherit">{folder?.name}</Typography>
                  <ListItemIcon
                    style={{ padding: 8 }}
                    className="ml-auto mt-auto mb-auto mr-0"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickIcon(folder?.subfolders);
                    }}
                  >
                    <MenuOpenIcon />
                  </ListItemIcon>
                </>
              ) : (
                folder?.name
              )}
            </MenuItem>
          ))}
      </>
    </MenuList>
  </Collapse>
);

export default MoveToFolderCollapse;
