import { makeStyles, Typography, lighten } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
    container: {
		width: ({ isMobile, width }) => width ? width : isMobile ? 200 : 130,
		maxWidth: ({ isMobile }) => isMobile && 200,
        borderColor: lighten(theme.palette.gray.main, 0.40),
        borderStyle: 'none',
        borderWidth: 1,
        "&:hover": {
            borderColor: ({ disabled }) => !disabled && theme.palette.secondary.main,
            opacity: 1,
        },
        paddingTop: 10.5,
        paddingBottom: 10.5,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        height: 36.433333,
        borderRadius: 4,
        padding: '0px 0px 0px 10px',
        display: 'flex',
        alignItems: 'center',
    },
	label: {
		marginLeft: 5,
        fontSize: 14,
        fontWeight: 500,
	},
}));

const InputLabelStatus = ({ width=200, label, value={ color: "success", text: "Abierta" }, disabled }) => {
    const classes = useStyles({ width, disabled });
    // Operator || will avoid crash if value is null
    const { color, text } = value || {};
    return (
			<div>
				<Typography className={classes.label}>{label}</Typography>
				<div className={classes.container}>
					<span className={`label label-lg label-light-${color} label-inline`}>
						{text}
					</span>
				</div>
			</div>
		);
};

export default InputLabelStatus;