import { makeStyles, IconButton, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import uuid from "react-uuid";
import { isAValidURL } from "../utils/inputValidations";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import Icon from "./Icon";
import { createURL } from "../utils/images";
import BaseModal from "./BaseModal";
import SVG from "react-inlinesvg";
import PostAddIcon from "@material-ui/icons/PostAdd";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  iconButton: {
    color: theme.palette.common.white,
    background: theme.palette.secondary.main,
    height: 30,
    width: 30,
    "&:hover": {
      background: theme.palette.secondary.dark,
    },
  },
  photoLabel: {
    color: theme.palette?.secondary?.main,
    overflow: "hidden",
    cursor: ({ disabled }) => disabled && "pointer",
    marginBottom: "0em",
    textOverflow: "ellipsis",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    gap: 10,
  },
  label: {
    marginLeft: 5,
    fontWeight: 500,
    fontSize: 14,
    margin: "0 0 0 5px",
    color: ({ disabled, touched, hover }) =>
      (touched || hover) && !disabled ? theme.palette.secondary.main : "black",
  },
  photoLink: {
    color: theme.palette?.secondary?.main,
    //marginTop: 15,
    textDecoration: "underline",
  },
  namePhoto: { color: "blue", cursor: "pointer", margin: 0 },
  photoContainer: {
    marginTop: 12,
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
}));

// accept === "image/*" is a condition used for image file uploader but pdf uploader
const FileButton = ({
  index,
  handleChange,
  value,
  disabled,
  label,
  accept = "image/*",
  buttonTitle = "Elegir archivo",
  multiple = false,
  imagesPreview = [],
  setImagesPreview = false,
  className = "",
  isIconButton = false,
  iconButtonClassName,
  iconProps = {},
}) => {
  const [touched, setTouched] = useState(false);
  const [hover, setHover] = useState(false);
  const classes = useStyles({ disabled, touched, hover });

  const handleCapture = ({ target }) => {
    !multiple ? handleChange(target.files[0], index) : handleChange([...target.files]);
  };

  const inputId = uuid();

  const [previewOpen, setPreviewOpen] = useState(false);

  const handlePreview = ({ target }) => {
    const { files } = target;
    let fileUrlsNames = [];
    for (let i = 0; i < files.length; i++) {
      const newUrl = createURL(files[i]);
      const newFileUrlName = {
        name: files[i].name,
        url: newUrl,
      };
      fileUrlsNames = [...fileUrlsNames, newFileUrlName];
    }
    setPreviewOpen(true);
    setImagesPreview && setImagesPreview(fileUrlsNames);
  };
  return (
    <div className={classes.container}>
      <>
        <input
          hidden
          type="file"
          accept={accept}
          id={inputId}
          onClick={(e) => (e.target.value = null)}
          onChange={(file) => {
            handleCapture(file);
            handlePreview(file);
          }}
          disabled={disabled}
          multiple={multiple}
        />
        <label className={classes.photoLabel} htmlFor={inputId}>
          {isIconButton ? (
            <Tooltip title={buttonTitle}>
              <IconButton
                className={
                  iconButtonClassName !== undefined
                    ? iconButtonClassName
                    : classes.iconButton
                }
                onFocus={() => setTouched(true)}
                onBlur={() => setTouched(false)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                component="span"
              >
                <PostAddIcon {...iconProps} />
              </IconButton>
            </Tooltip>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {label && <p className={classes.label}>{label}</p>}
              <span
                onFocus={() => setTouched(true)}
                onBlur={() => setTouched(false)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className={`btn btn-${disabled ? "secondary" : "primary"} border border-${
                  disabled ? "dark" : "primary"
                } h-100 ${disabled ? "disabled text-dark" : ""} ${className}`}
                style={{
                  cursor: disabled && "not-allowed",
                }}
              >
                {accept === "image/*"
                  ? imagesPreview.length === 0
                    ? buttonTitle
                    : "Cambiar archivo"
                  : buttonTitle}
                {(accept === "image/*" && imagesPreview.length > 0) || value?.name ? (
                  <Icon
                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                    color="success"
                    style={{
                      marginLeft: 5,
                      width: 20,
                      height: 20,
                    }}
                    label="Ver imagen"
                    onClick={() => setPreviewOpen(true)}
                  />
                ) : (
                  value?.url && (
                    <Icon
                      src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                      color="success"
                      style={{
                        marginLeft: 5,
                        width: 20,
                        height: 20,
                      }}
                      label="Ver imagen"
                      onClick={() => setPreviewOpen(true)}
                    />
                  )
                )}
              </span>
            </div>
          )}
        </label>
      </>

      <BaseModal
        open={Boolean(
          accept === "image/*" &&
            previewOpen &&
            (imagesPreview.length > 0 || value?.name || value?.url)
        )}
        onClose={() => setPreviewOpen(false)}
        onCancel={() => setPreviewOpen(false)}
        title={
          <>
            <span className="mr-2">
              {imagesPreview.length === 1 ? "Imagen cargada " : "Imágenes cargadas "}
            </span>
            <span className={`svg-icon svg-icon-md svg-icon-primary`}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Picture.svg")} />
            </span>
          </>
        }
        cancelLabel="Aceptar"
      >
        {(value?.name || value?.url) && imagesPreview.length === 0 ? (
          <img
            className="img-fluid p-3 overflow-auto d-block w-75 m-auto"
            src={
              isAValidURL(value?.name)
                ? value.name
                : isAValidURL(value?.url)
                ? value.url
                : createURL(value)
            }
            alt={value?.name}
          />
        ) : (
          setImagesPreview &&
          imagesPreview.map(({ name, url }) => (
            <React.Fragment key={name}>
              <p>{name}</p>
              <img
                className="img-fluid p-3 overflow-auto d-block w-75 m-auto"
                src={url}
                alt={name}
              />
            </React.Fragment>
          ))
        )}
      </BaseModal>
    </div>
  );
};

export default FileButton;
