import React, { useState } from 'react'
import { makeStyles, MenuItem, Typography, useMediaQuery, Select } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: ({ isMobile, width }) => width ? width : isMobile ? 200 : 130,
		"& > .MuiTypography-root": {
      marginLeft: 5,
      fontWeight: 500,
      fontSize: 14
		}
	},
	root: {
		whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
		padding: "5.5px 14px",
		backgroundColor: ({ disabled }) => disabled ? "#dedede" : "#d6eaff",
	},
	icon: {
		fill: "black",
		"&:hover": {
			fill: theme.palette.secondary.main,
		}
	},
	label: {
		marginLeft: 5,
		fontWeight: 500,
    fontSize: 14,
		color: ({ disabled, touched, hover }) => (touched || hover) && !disabled ? theme.palette.secondary.main : "black",
	},
}));

const SimpleChipDropdown = ({ label, name, onChange, value, options=[], width=false, labelStyle, disabled }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
	const [touched, setTouched] = useState(false)
	const [hover, setHover] = useState(false)
	const classes = useStyles({ isMobile, width, disabled, touched, hover });

  return (
    <div className={classes.container}>
			<Typography className={classes.label}>{label}</Typography>
			<Select
				fullWidth
				displayEmpty
				color="secondary"
				value={value}
				onChange={onChange}
				classes={classes}
				variant="outlined"
				size="small"
				name={name}
        disabled={disabled}
				inputProps={{
					classes: {
						icon: classes.icon,
					},
				}}
				onBlur={() => setTouched(false)}
				onFocus={e => setTouched(true)}
        onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
        renderValue={selected => (
          <span className={`label label-lg label-light-${options.find(op => op.value === selected)?.color} label-inline border border-${options.find(op => op.value === selected)?.color}`}>
            {options.find(op => op.value === selected)?.text}
          </span>
        )}
			>
				{options.map(option => option && (
					<MenuItem key={option.value.id ? option.value.id : option.value } value={option.value}>
            <span className={`label label-lg label-light-${option.color} label-inline`}>
						  {option.text}
            </span>
					</MenuItem>
				))}
			</Select>
		</div>
  )
}

export default SimpleChipDropdown
