import UtilInputs from "./UtilInputs";
import { useSelector } from "react-redux";

const DropInputsForm = ({ formValues, onValuesChange, width = 200 }) => {
  // const [options, setOptions] = useState([]);
  const { currentMode } = useSelector((store) => store.subHeaderActions);
  // useEffect(() => {
  //   getDropTypes()
  //     .then((res) => setOptions(res))
  //     .catch(console.log);
  // }, []);

  const dropInputs = [
    {
      label: "Motivo de baja",
      name: "motivo_baja",
      type: "text",
      required: true,
      multiline: true,
      rows: 5,
      width: "250px",
      disabled: !currentMode,
    },
    {
      label: "Tipo de baja",
      name: "tipo_baja_descripcion",
      type: "text",
      required: true,
      disabled: !currentMode,
    },
    {
      label: "Fecha de baja",
      name: "fecha_baja",
      type: "date",
      required: true,
      disabled: !currentMode,
    },
    {
      label: "Usuario registro baja",
      name: "usuario_registra_baja",
      type: "text",
      disabled: true,
    },
    {
      label: "Última impresión de resguardo",
      name: "fecha_ult_imp_resg",
      type: "date",
      disabled: true,
    },
  ];

  return (
    <UtilInputs
      formValues={formValues}
      onValuesChange={onValuesChange}
      inputValues={dropInputs}
      width={width}
    />
  );
};

export default DropInputsForm;
