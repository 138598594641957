import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { useRoutesPermissions } from "./utils/routesPermissions";

import ComponentsPage from "./pages/ComponentsPage";
import EntriesPage from "./pages/Entries/EntriesPage";
import NewEntryPage from "./pages/Entries/NewEntryPage";
import NewRequestPage from "./pages/Requests/NewRequestPage";
import StockPage from "./pages/Stock/StockPage";
import StockDetail from "./pages/Stock/StockDetail";
import RequestPage from "./pages/Requests/RequestsPage";
import ConsumptionItemPage from "./pages/ConsumptionItem/ConsumptionItemPage";
import DeparturesPage from "./pages/Departures/DeparturesPage";
import NewDeparturePage from "./pages/Departures/NewDeparturePage";
import DepartureRecurrentPage from "./pages/Departures/DepartureRecurrentPage";
import DepartureRecurrent from "./pages/Departures/DepartureRecurrent";
import DepartureOrdinaryPage from "./pages/Departures/DepartureOrdinaryPage";
import DepartureOrdinary from "./pages/Departures/DepartureOrdinary";
import EntrancesPage from "./pages/Entrances/EntrancesPage";
import PledgedGoodsPage from "./pages/PledgedGoods/PledgedGoodsPage";
import EntranceDetail from "./pages/Entrances/EntranceDetail";
import StockSurveyPage from "./pages/StockSurvey/StockSurveyPage";
import StockSurveyDetailPage from "./pages/StockSurvey/StockSurveyDetailPage";
import ContractsPage from "./pages/Contracts/ContractsPage";
import DetailContractsPage from "./pages/Contracts/DetailContractsPage";
import DetailsRequirementsPage from "./pages/Requirements/DetailsRequirementsPage";
import RequirementsPage from "./pages/Requirements/RequirementsPage";
import ArtWorksPage from "./pages/ArtWorks/ArtWorksPage";
import ArtWorksDetailsPage from "./pages/ArtWorks/ArtWorksDetailsPage";
import VehiculesDetailPage from "./pages/Vehicules/VehiculesDetailPage";
import VehiculesPage from "./pages/Vehicules/VehiculesPage";
import ArticlesPage from "./pages/Articles/ArticlesPage";
import ArticlesDetail from "./pages/Articles/ArticlesDetail";
import FilterRequestPage from "./pages/Requests/FilterRequestPage";
import ConfigurationPage from "./pages/Configuration/ConfigurationPage";
import { ConfigVariants } from "./pages/Configuration/Articles/ConfigVariants";
import { ConfigDocumentType } from "./pages/Configuration/Catalogos/ConfigDocumentType";
import ConfigDocumentTypeDetail from "./pages/Configuration/Catalogos/ConfigDocumentTypeDetail";
import { ConfigStock } from "./pages/Configuration/Catalogos/ConfigStock";
import ConfigStockDetail from "./pages/Configuration/Catalogos/ConfigStockDetail";
import { ConfigBudgetItem } from "./pages/Configuration/Catalogos/ConfigBudgetItem";
import ConfigBudgetItemDetail from "./pages/Configuration/Catalogos/ConfigBudgetItemDetail";
import { ConfigConditionOfUse } from "./pages/Configuration/Catalogos/ConfigConditionOfUse";
import ConfigConditionOfUseDetail from "./pages/Configuration/Catalogos/ConfigConditionOfUseDetail";
import { ConfigOperatingCondition } from "./pages/Configuration/Catalogos/ConfigOperatingCondition";
import ConfigOperatingConditionDetail from "./pages/Configuration/Catalogos/ConfigOperatingConditionDetail";
import { ConfigClassification } from "./pages/Configuration/Catalogos/ConfigClassification";
import ConfigClassificationDetail from "./pages/Configuration/Catalogos/ConfigClassificationDetail";
import { ConfigTypeOfProperty } from "./pages/Configuration/Catalogos/ConfigTypeOfProperty";
import ConfigTypeOfPropertyDetail from "./pages/Configuration/Catalogos/ConfigTypeOfPropertyDetail";
import { ConfigTypeOfWork } from "./pages/Configuration/Catalogos/ConfigTypeOfWork";
import ConfigTypeOfWorkDetail from "./pages/Configuration/Catalogos/ConfigTypeOfWorkDetail";
import ConfigGroups from "./pages/Configuration/Users/ConfigGroups";
import ConfigGroupDetail from "./pages/Configuration/Users/ConfigGroupDetail";
import ConfigPermits from "./pages/Configuration/Users/ConfigPermits";
import ConfigPermitDetail from "./pages/Configuration/Users/ConfigPermitDetail";
import ConfigVariantDetail from "./pages/Configuration/Articles/ConfigVariantDetail";
import ConfigRequestPage from "./pages/Configuration/Warehouse/ConfigRequestPage";
import UsersPage from "./pages/Users/UsersPage";
import NewUserPage from "./pages/Users/NewUserPage";
import ConfigPresentationTypes from "./pages/Configuration/Articles/ConfigPresentationTypes";
import ConfigPresentationTypeDetail from "./pages/Configuration/Articles/ConfigPresentationTypeDetail";
import RequestDraftPage from "./pages/Requests/RequestDraftPage";
import ReportsPage from "./pages/Reports/ReportsPage";
import EntranceReturnStock from "./pages/Entrances/EntranceReturnStock";
import EntranceOrdinaryPage from "./pages/Entrances/EntranceOrdinaryPage";
import EntranceRecurrentPage from "./pages/Entrances/EntranceRecurrentPage";
import EntranceStockPage from "./pages/Entrances/EntranceStockPage";
import EntranceDevolutionPage from "./pages/Entrances/EntranceDevolutionPage";
import DigitalVaultWrapper from "./pages/DigitalVault/DigitalVaultWrapper";
import DigitalizationDocs from "./pages/DigitalVault/DigitalizationDocs";
import ToDoWrapper from "./pages/Todo/ToDoWrapper";
import Dashboard from "./pages/Dashboard";
import EntranceRevolventePage from "./pages/Entrances/EntranceRevolventePage";
import EntranceGastosPage from "./pages/Entrances/EntranceGastosPage";
import EntranceConcentratePage from "./pages/EntranceConcentrate/EntranceConcentratePage";
import NewEntranceConcentratePage from "./pages/EntranceConcentrate/NewEntranceConcentratePage";

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const {
    entriesPermission,
    newEntryPermission,
    stockPermission,
    stockDetailsPermission,
    requestPublicPermission,
    departuresPermission,
    newDeparturePermission,
    entrancesPermission,
    newEntrancePermission,
    pledgedGoodsPermission,
    contractsPermission,
    detailContractsPermission,
    detailsRequirementPermission,
    requirementsPermission,
    newRequestPermission,
  } = useRoutesPermissions();
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/modulos" />

        {newEntryPermission && (
          <ContentRoute
            path="/bienes-instrumentales/entradas/nueva"
            component={NewEntryPage}
          />
        )}
        {newEntryPermission && (
          <ContentRoute
            path="/bienes-instrumentales/entradas/busqueda"
            component={NewEntryPage}
          />
        )}
        {newEntryPermission && (
          <ContentRoute
            path="/bienes-instrumentales/entradas/resultados/:id"
            component={NewEntryPage}
          />
        )}
        {entriesPermission && (
          <ContentRoute path="/bienes-instrumentales/entradas" component={EntriesPage} />
        )}
        {stockDetailsPermission && (
          <ContentRoute
            path="/bienes-instrumentales/inventario-detalles/resultados/:id"
            component={StockDetail}
          />
        )}
        {stockDetailsPermission && (
          <ContentRoute
            path="/bienes-instrumentales/inventario-detalles/bajas/resultados/:id"
            component={StockDetail}
          />
        )}
        {stockDetailsPermission && (
          <ContentRoute
            path="/bienes-instrumentales/inventario-detalles/bajas/busqueda"
            component={StockDetail}
          />
        )}
        {stockDetailsPermission && (
          <ContentRoute
            path="/bienes-instrumentales/inventario-detalles/busqueda"
            component={StockDetail}
          />
        )}
        {stockPermission && (
          <ContentRoute
            path="/bienes-instrumentales/inventario/ver"
            component={StockPage}
          />
        )}
        {stockPermission && (
          <ContentRoute
            path="/bienes-instrumentales/inventario/bajas"
            component={StockPage}
          />
        )}
        <ContentRoute
          path="/bienes-instrumentales/levantamiento-inventario/resultados/:id"
          component={StockSurveyDetailPage}
        />
        <ContentRoute
          path="/bienes-instrumentales/levantamiento-inventario/nuevo"
          component={StockSurveyDetailPage}
        />
        <ContentRoute
          path="/bienes-instrumentales/levantamiento-inventario"
          component={StockSurveyPage}
        />
        <ContentRoute
          path="/bienes-instrumentales/vehiculos/nuevo"
          component={VehiculesDetailPage}
        />
        <ContentRoute
          path="/bienes-instrumentales/vehiculos/busqueda"
          component={VehiculesDetailPage}
        />
        <ContentRoute
          path="/bienes-instrumentales/vehiculos/resultados/:id"
          component={VehiculesDetailPage}
        />
        <ContentRoute
          path="/bienes-instrumentales/vehiculos/bajas/resultados/:id"
          component={VehiculesDetailPage}
        />

        <ContentRoute
          path="/bienes-instrumentales/vehiculos/ver"
          component={VehiculesPage}
        />
        <ContentRoute
          path="/bienes-instrumentales/vehiculos/bajas/busqueda"
          component={VehiculesDetailPage}
        />
        <ContentRoute
          path="/bienes-instrumentales/vehiculos/bajas"
          component={VehiculesPage}
        />
        <ContentRoute
          path="/bienes-instrumentales/obras-arte/nuevo"
          component={ArtWorksDetailsPage}
        />
        <ContentRoute
          path="/bienes-instrumentales/obras-arte/busqueda"
          component={ArtWorksDetailsPage}
        />
        <ContentRoute
          exact
          path="/bienes-instrumentales/obras-arte/resultados/bajas/:id"
          component={ArtWorksDetailsPage}
        />
        <ContentRoute
          path="/bienes-instrumentales/obras-arte/resultados/:id"
          component={ArtWorksDetailsPage}
        />

        <ContentRoute
          path="/bienes-instrumentales/obras-arte/bajas"
          component={ArtWorksPage}
        />

        <ContentRoute
          path="/bienes-instrumentales/obras-arte/busqueda/bajas"
          component={ArtWorksDetailsPage}
        />
        <ContentRoute
          path="/bienes-instrumentales/obras-arte/ver"
          component={ArtWorksPage}
        />

        <ContentRoute
          path="/bienes-instrumentales/usuarios/nuevo"
          component={NewUserPage}
        />
        <ContentRoute
          path="/bienes-instrumentales/usuarios/busqueda"
          component={NewUserPage}
        />

        <ContentRoute
          path="/bienes-instrumentales/usuarios/resultados/:id"
          component={NewUserPage}
        />
        <ContentRoute path="/bienes-instrumentales/usuarios" component={UsersPage} />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/tipo-de-documento/nuevo"
          component={ConfigDocumentTypeDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/tipo-de-documento/busqueda"
          component={ConfigDocumentTypeDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/tipo-de-documento/resultados/:id"
          component={ConfigDocumentTypeDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/tipo-de-documento"
          component={ConfigDocumentType}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/almacen/nuevo"
          component={ConfigStockDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/almacen/resultados/:id"
          component={ConfigStockDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/almacen"
          component={ConfigStock}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/partida-presupuestal/nuevo"
          component={ConfigBudgetItemDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/partida-presupuestal/resultados/:id"
          component={ConfigBudgetItemDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/partida-presupuestal"
          component={ConfigBudgetItem}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/condicion-de-uso/nuevo"
          component={ConfigConditionOfUseDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/condicion-de-uso/resultados/:id"
          component={ConfigConditionOfUseDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/condicion-de-uso"
          component={ConfigConditionOfUse}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/condicion-de-operacion/nuevo"
          component={ConfigOperatingConditionDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/condicion-de-operacion/resultados/:id"
          component={ConfigOperatingConditionDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/condicion-de-operacion"
          component={ConfigOperatingCondition}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/clasificacion/nuevo"
          component={ConfigClassificationDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/clasificacion/resultados/:id"
          component={ConfigClassificationDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/clasificacion"
          component={ConfigClassification}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/tipo-de-bien/nuevo"
          component={ConfigTypeOfPropertyDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/tipo-de-bien/resultados/:id"
          component={ConfigTypeOfPropertyDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/tipo-de-bien"
          component={ConfigTypeOfProperty}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/tipo-de-obra/nuevo"
          component={ConfigTypeOfWorkDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/tipo-de-obra/resultados/:id"
          component={ConfigTypeOfWorkDetail}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos/tipo-de-obra"
          component={ConfigTypeOfWork}
        />
        <ContentRoute
          path="/bienes-instrumentales/configuracion/catalogos"
          component={ConfigurationPage}
        />

        <ContentRoute
          path="/bienes-instrumentales/boveda-digital"
          component={DigitalVaultWrapper}
        />
        <ContentRoute
          path="/bienes-instrumentales/digitalizacion-documentos"
          component={DigitalizationDocs}
        />

        <ContentRoute
          path="/bienes-instrumentales/administracion-tareas"
          component={ToDoWrapper}
        />

        <ContentRoute path="/solicitudes/borradores" component={RequestDraftPage} />
        {requestPublicPermission && (
          <ContentRoute path="/solicitudes/lista" component={RequestPage} />
        )}
        <ContentRoute path="/solicitudes/busqueda" component={FilterRequestPage} />
        {newRequestPermission && (
          <ContentRoute path="/solicitudes/nueva-solicitud" component={NewRequestPage} />
        )}
        {newRequestPermission && (
          <ContentRoute path="/solicitudes/resultados/:id" component={NewRequestPage} />
        )}

        <ContentRoute
          path="/inventario-bienes-almacen-general/solicitudes/busqueda"
          component={FilterRequestPage}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/solicitudes/resultados/:id"
          component={NewRequestPage}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/solicitudes"
          component={RequestPage}
        />

        <ContentRoute
          path="/inventario-bienes-almacen-general/consumo-articulos"
          component={ConsumptionItemPage}
        />

        {newDeparturePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/salidas/busqueda"
            component={NewDeparturePage}
          />
        )}
        {newDeparturePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/salidas/resultados/:id"
            component={NewDeparturePage}
          />
        )}
        {newDeparturePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/salidas/recurrente/nuevo"
            component={DepartureRecurrent}
          />
        )}
        {newDeparturePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/salidas/ordinaria/nuevo"
            component={DepartureOrdinary}
          />
        )}
        {departuresPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/salidas/stock"
            component={DeparturesPage}
          />
        )}
        {departuresPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/salidas/recurrentes"
            component={DepartureRecurrentPage}
          />
        )}
        {departuresPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/salidas/ordinarias"
            component={DepartureOrdinaryPage}
          />
        )}
        {newEntrancePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/nuevo"
            component={EntranceDetail}
          />
        )}
        {newEntrancePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/stock/nuevo"
            component={EntranceDetail}
          />
        )}
        {newEntrancePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/recurrentes/nuevo"
            component={EntranceDetail}
          />
        )}
        {newEntrancePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/ordinarias/nuevo"
            component={EntranceDetail}
          />
        )}
        {newEntrancePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/fondo-revolvente/nuevo"
            component={EntranceDetail}
          />
        )}
        {newEntrancePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/gastos-a-comprobar/nuevo"
            component={EntranceDetail}
          />
        )}
        {newEntrancePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/resultados/:id"
            component={EntranceDetail}
          />
        )}
        {newEntrancePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/busqueda"
            component={EntranceDetail}
          />
        )}
        {newEntrancePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/devolucion/nuevo"
            component={EntranceReturnStock}
          />
        )}
        {newEntrancePermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/devolucion/resultados/:id"
            component={EntranceReturnStock}
          />
        )}
        {entrancesPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/todas"
            component={EntrancesPage}
          />
        )}
        {entrancesPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/stock"
            component={EntranceStockPage}
          />
        )}
        {entrancesPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/recurrentes"
            component={EntranceRecurrentPage}
          />
        )}
        {entrancesPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/ordinarias"
            component={EntranceOrdinaryPage}
          />
        )}
        {entrancesPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/fondo-revolvente"
            component={EntranceRevolventePage} //cambiarlo despues
          />
        )}
        {entrancesPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/gastos-a-comprobar"
            component={EntranceGastosPage}
          />
        )}
        {entrancesPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/entradas/devoluciones"
            component={EntranceDevolutionPage}
          />
        )}
        {detailContractsPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/contratos/nuevo"
            component={DetailContractsPage}
          />
        )}
        <ContentRoute
          path="/inventario-bienes-almacen-general/contratos/busqueda"
          component={DetailContractsPage}
        />
        {detailContractsPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/contratos/:id"
            component={DetailContractsPage}
          />
        )}
        {contractsPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/contratos"
            component={ContractsPage}
          />
        )}
        {pledgedGoodsPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/bienes-comprometidos"
            component={PledgedGoodsPage}
          />
        )}
        <ContentRoute
          path="/inventario-bienes-almacen-general/requerimiento/busqueda"
          component={DetailsRequirementsPage}
        />
        {detailsRequirementPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/requerimiento/nuevo"
            component={DetailsRequirementsPage}
          />
        )}
        {detailsRequirementPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/requerimientos/resultados/:id"
            component={DetailsRequirementsPage}
          />
        )}
        {requirementsPermission && (
          <ContentRoute
            path="/inventario-bienes-almacen-general/requerimientos"
            component={RequirementsPage}
          />
        )}
        <ContentRoute
          path="/inventario-bienes-almacen-general/articulos/busqueda"
          component={ArticlesDetail}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/articulos/nuevo"
          component={ArticlesDetail}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/articulos/resultados/:id"
          component={ArticlesDetail}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/articulos"
          component={ArticlesPage}
        />
        <ContentRoute
          path={"/inventario-bienes-almacen-general/usuarios/nuevo"}
          component={NewUserPage}
        />
        <ContentRoute
          path={"/inventario-bienes-almacen-general/usuarios/busqueda"}
          component={NewUserPage}
        />
        <ContentRoute
          path={"/inventario-bienes-almacen-general/usuarios/resultados/:id"}
          component={NewUserPage}
        />
        <ContentRoute
          path={"/inventario-bienes-almacen-general/usuarios"}
          component={UsersPage}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/articulos/variantes/nuevo"
          component={ConfigVariantDetail}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/articulos/variantes/resultados/:id"
          component={ConfigVariantDetail}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/articulos/variantes"
          component={ConfigVariants}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/articulos/tipos-presentacion/nuevo"
          component={ConfigPresentationTypeDetail}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/articulos/tipos-presentacion/resultados/:id"
          component={ConfigPresentationTypeDetail}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/articulos/tipos-presentacion"
          component={ConfigPresentationTypes}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/articulos"
          component={ConfigurationPage}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/usuarios/grupos/nuevo"
          component={ConfigGroupDetail}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/usuarios/grupos/resultados/:id"
          component={ConfigGroupDetail}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/usuarios/grupos"
          component={ConfigGroups}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/usuarios/permisos/nuevo"
          component={ConfigPermitDetail}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/usuarios/permisos/resultados/:id"
          component={ConfigPermitDetail}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/usuarios/permisos"
          component={ConfigPermits}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/usuarios"
          component={ConfigurationPage}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/almacen/solicitudes"
          component={ConfigRequestPage}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/configuracion/almacen"
          component={ConfigurationPage}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/reportes"
          component={ReportsPage}
        />

        <ContentRoute
          path="/inventario-bienes-almacen-general/boveda-digital"
          component={DigitalVaultWrapper}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/digitalizacion-documentos"
          component={DigitalizationDocs}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/administracion-tareas"
          component={ToDoWrapper}
        />

        {/* EVERY MODULE HAS TO RENDER AN EMPTY PAGE AT SELECT ONE */}
        <ContentRoute path="/administrador-tareas" component={ToDoWrapper} />
        <ContentRoute path="/boveda-digital" component={DigitalVaultWrapper} />
        <ContentRoute path="/bienes-instrumentales" component={Dashboard} />
        <ContentRoute
          exact
          path="/inventario-bienes-almacen-general"
          component={ComponentsPage}
        />
        <ContentRoute path="/solicitudes" component={ComponentsPage} />

        {/* Nuevo */}
        <ContentRoute
          path="/inventario-bienes-almacen-general/concentrado-entradas/nuevo"
          component={NewEntranceConcentratePage}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/concentrado-entradas/editar/:id"
          component={NewEntranceConcentratePage}
        />
        <ContentRoute
          path="/inventario-bienes-almacen-general/concentrado-entradas"
          component={EntranceConcentratePage}
        />

        {/* <ContentRoute path="/components" component={ComponentsPage}/> */}
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
}
