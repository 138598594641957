import axios from "axios";
import notice from "../components/Notice";
import { isConciliated } from "../utils/batchesUtils";
import { getCABMSKey } from "../utils/cabmsAndKey";
import {
  CONSULTA_QR_ARTWORK,
  CONSULTA_QR_ARTWORK_POST,
  INSTRUMENTAL_GOODS,
} from "./helpers/routes";
import { getParams, handleResponse } from "./helpers/validate";

const fromatData = (data) => {
  const { datos_resguardos, cabms, artwork_type } = data;
  const {
    cf_grupoc: cf_grupo,
    cf_subgrupoc: cf_subgrupo,
    cf_clasec: cf_clase,
    cf_subclasec: cf_subclase,
    cf_tipoc: cf_tipo,
  } = cabms;
  const newCabmsKey = {
    cf_grupo,
    cf_subgrupo,
    cf_clase,
    cf_subclase,
    cf_tipo,
  };
  const cabms_key = data.cabms?.id ? getCABMSKey(newCabmsKey) : "";
  return {
    ...data,
    // Area fields
    ...datos_resguardos,
    desc_tipoobra: artwork_type?.id,
    key: data.cf_grupo ? getCABMSKey(data) : "",
    // If want to add "text" then remove hideText prop from array inputs from next 2 elements
    cf_cve_prove: { text2: data.cf_cve_prove },
    cf_cve_partida: { text2: data.cf_cve_partida },
    descripcion_partida: data.partida?.desc_partida,
    cf_conciliado: isConciliated(data.cf_conciliado?.toString().charAt(0) || "", data),
    cabms_key,
    item: data.cf_articulo,
    cabms_item: cabms?.cf_articulo,
  };
};
export const getArtWorkDropedListPost = (search_params) => {
  return axios.post(`${CONSULTA_QR_ARTWORK_POST}-search`, search_params);
};
export const getArtWorkListPost = (search_params) => {
  return axios.post(`${CONSULTA_QR_ARTWORK_POST}-search`, search_params);
};

export const getArtWorksList = (page, limit, filters = "", order = "desc") => {
  //como deberia mandarlo
  // axios.post(`${INVENTORY_API}-search`, { search_params });
  return axios
    .get(`${CONSULTA_QR_ARTWORK}?&page=${page}&limit=${limit}&order=${order}&${filters}`)
    .then((res) => {
      //console.log('res', res, res?.response);
      if (![200, 201].includes(res.status)) throw Error("Respuesta inválida");
      const data = res?.data || [];

      const getCABMSKeyFixed = (cabms) => {
        const {
          cf_grupoc: cf_grupo,
          cf_subgrupoc: cf_subgrupo,
          cf_clasec: cf_clase,
          cf_subclasec: cf_subclase,
          cf_tipoc: cf_tipo,
        } = cabms;
        const cabms_catalog = {
          cf_grupo,
          cf_subgrupo,
          cf_clase,
          cf_subclase,
          cf_tipo,
        };
        const cabmsKey = getCABMSKey(cabms_catalog);
        return cabmsKey;
      };
      const newArtWorks = {
        ...data,
        results: data.results.map(({ id, cf_no_inventa, ...rest }) => ({
          id,
          cf_no_inventa,
          cabms_key: rest.cabms.id ? getCABMSKeyFixed(rest.cabms) : "",
          cabms_item: rest.cabms?.cf_articulo,
          ...rest,
        })),
      };
      return { ...newArtWorks, page };
    })
    .catch((e) => e);
};

export const getSingleArtWork = (id) => {
  return axios
    .get(`${CONSULTA_QR_ARTWORK}/${id}`)
    .then((res) => {
      if ([200, 201].includes(res.status)) {
        const newData = fromatData(res.data);
        return newData;
      }
    })
    .catch((e) => e);
};

export const updateArtWork = (id, body) => {
  let formatBody = body;

  if (formatBody.cabms === undefined) {
    formatBody.cabms = {};
  }

  return axios.put(`${CONSULTA_QR_ARTWORK}/${id}/`, body);
};

export const createArtWork = (body) => {
  return axios.post(`${CONSULTA_QR_ARTWORK}/`, body);
};

export const downloadGuardPDF = (id, filename = "filename") => {
  return axios.get(`${CONSULTA_QR_ARTWORK}/${id}/pdf-resguardo?filename=${filename}`);
};

export const getArtWorkDropsList = (page, limit) => {
  return axios.get(`${CONSULTA_QR_ARTWORK}-baja?&page=${page}&limit=${limit}`);
};

export const getSingleArtWorkDrop = (id) => {
  return axios
    .get(`${CONSULTA_QR_ARTWORK}-baja/${id}`)
    .then((res) => {
      if ([200, 201].includes(res.status)) {
        const payload = { ...res.data, ...fromatData(res.data.artwork_data) };
        delete payload["artwork_data"];

        return payload;
      }
    })
    .catch((e) => e);
};

export const createArtworkDrop = (data) => {
  return axios.post(`${CONSULTA_QR_ARTWORK}-baja`, data);
};

export const postZplArtwork = (id) => {
  return axios.post(`${CONSULTA_QR_ARTWORK}/zpl_codes_post/`, { ids: [id] });
};

export const fetchGuardFormat = (id) => {
  const url = `${CONSULTA_QR_ARTWORK}/${id}/pdf-resguardo`;
  return axios.get(url);
};
export const fetchGuardArtWorkList = (id) => {
  return axios.post(`${CONSULTA_QR_ARTWORK}/pdf-resguardo`, { ids: id });
};
export const artworkReports = (report, ids, type, isDropPage) => {
  let body;
  if (isDropPage) {
    body = { ids, type, report_type: "baja" };
  } else {
    body = { ids, type };
  }

  return fetch(
    `${INSTRUMENTAL_GOODS}/reports_catalogs-artworks/`,
    getParams("POST", body)
  )
    .then(handleResponse)
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};

//dar de baja
export const unSuscribeId = async (id, data) => {
  const bajaData = {
    artwork: id,
    motivo_baja: data.motivo_baja,
    tipo_baja: data.tipo_baja,
  };
  return axios.post(`${CONSULTA_QR_ARTWORK}-baja`, bajaData);
};

export const unSuscribeReasonArtWork = async (id, data) => {
  try {
    const response = await axios.patch(`${CONSULTA_QR_ARTWORK}-baja/${id}/`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateMultipleArtWorkFields = async (data) => {
  return fetch(
    `${CONSULTA_QR_ARTWORK}-update-multiple`,
    getParams("PUT", { artworks: data })
  )
    .then(handleResponse)
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};
