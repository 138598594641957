import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import notice from '../../../components/Notice';
import BaseModal from '../../../components/BaseModal'
import UtilInputs from '../../../components/UtilInputs';
import { getOperativeUsers } from '../../../crud/usersCrud'
import { updateUserinBoard } from '../../../crud/taskManagementCrud'
import { setSelectedBoard } from '../../../../redux/ducks/taskManagement.duck';
import { setLoader } from '../../../../redux/ducks/loader.duck';

const inputsForm = (users) => [
  {
    name: "users",
    label: "Usuarios con acceso al tablero *",
    type: "search-multiple",
    options: users,
    showAvatars: true
  },
]

const ToDoUsersOnBoard = ({ open, setOpen }) => {
  const dispatch = useDispatch()
  const { selectedBoard } = useSelector(store => store.taskManagement);
  const [operativeUsers, setOperativeUser] = useState([])
  const [lastSavedBoard, setLastSavedBoard] = useState(selectedBoard);
  
  useEffect(() => {
    getOperativeUsers()
    .then((res) => setOperativeUser(res.results.map( user => ({ ...user, value: user.id, text: `${user.first_name} ${user.last_name}` }) )))
    .catch(console.log)
  }, [])

  const handleChange = ({ target }) => 
    dispatch(setSelectedBoard({...selectedBoard, users: operativeUsers?.filter(opU => target.value?.find(us => us.value === opU.value ))}))

  const handleUpdateUsers = () => {
    dispatch(setLoader(true))
    updateUserinBoard(selectedBoard?.id, {users: selectedBoard?.users?.map(us => us.id)})
    .then((res) => {
      // dispatch(setSelectedBoard({...selectedBoard, users: operativeUsers.filter(opU => form.users.find(us => us.value === opU.id ))}))
      setOpen(false)
      setLastSavedBoard(selectedBoard)
      notice("Usuarios actualizados", "success")
    }).catch(e => notice("Error con su solicitud"))
    .finally(() => dispatch(setLoader(false)))
  }

  const close = () => {
    dispatch(setSelectedBoard(lastSavedBoard));
    setOpen(false);
  }

  return (
    <BaseModal
      title="Usuarios con acceso al tablero"
      open={open}
      onClose={close}
      onCancel={close}
      onConfirm={handleUpdateUsers}
    >
      <UtilInputs
        formValues={{users: selectedBoard?.users?.map(user => ({ value: user.id, text: `${user.first_name} ${user.last_name}` })) || []}}
        onValuesChange={handleChange}
        inputValues={inputsForm(operativeUsers)}
        width="100%"
      />
    </BaseModal>
  )
}

export default ToDoUsersOnBoard
