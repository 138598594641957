import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  darken,
  Tooltip,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: ({ color, bgColor, isSelected }) =>
      isSelected ? bgColor : color,
    padding: "10px 15px",
    borderRadius: 1,
    border: ({ bgColor }) => `solid 1px ${bgColor}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: ({ width }) => width,
    height: ({ height }) => height,
    cursor: ({ cursor }) => cursor,
    userSelect: "none",
    margin: "0 10px",
    "&:hover": {
      boxShadow: ({ bgColor }) => `0 0 1px ${bgColor}`,
      webkitTransition: "all 0.5s ease-in-out",
      border: ({ bgColor }) => `solid 1px ${darken(bgColor, 0.1)}`,
      color: ({ color }) => color,
    },
  },
  label: {
    color: ({ color, bgColor, isSelected }) => (isSelected ? color : bgColor),
    fontWeight: (props) => (props.bold ? "bold" : "normal"),
    fontSize: (props) => props.fontSize,
    textAlign: "center",
    userSelect: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
});

const TabButton = ({
  bgColor = "#2a9cff",
  color = "#ffffff",
  isSelected = false,
  label = "",
  bold = false,
  fontSize = "1rem",
  onClick = () => {},
  width,
  height,
  cursor = "auto",
  tooltip,
}) => {
  const classes = useStyles({
    bgColor,
    color,
    isSelected,
    bold,
    fontSize,
    width,
    height,
    cursor,
  });
  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <Box
            className={classes.root}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={onClick}
            data-testid="chip-button"
          >
            <Typography className={classes.label}>{label}</Typography>
          </Box>
        </Tooltip>
      ) : (
        <Box
          className={classes.root}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={onClick}
          data-testid="chip-button"
        >
          <Typography className={classes.label}>{label}</Typography>
        </Box>
      )}
    </>
  );
};

export default TabButton;
