import React from 'react';
import axios from "axios"
import { ASSTETS_STOCK } from "./helpers/routes"
import notice from '../components/Notice';
import { getParams, handleResponse } from './helpers/validate';

export const createNewRequest = (data) => {
  return axios.post(ASSTETS_STOCK + "/request", data);
};

export const listRequests = async (page, sizePerPage, filters = "") => {
  return axios.get(`${ASSTETS_STOCK}/request-min?page=${page}&limit=${sizePerPage}&${filters}`)
};

export const listRequestsPublic = async (page, sizePerPage, filters = "") => {
  const TOKEN = process.env.REACT_APP_MONITOR_USER_TOKEN;
  return axios.get(`${ASSTETS_STOCK}/request?page=${page}&limit=${sizePerPage}&${filters}`,{
    headers: {
      Authorization: `Bearer ${TOKEN}`
    }
  })
};

export const singleRequest = (id) => {
  return fetch(`${ASSTETS_STOCK}/request/${id}`, getParams("GET"))
  .then(handleResponse)
  .then(res => ({
    ...res,
    area: res.area.cf_adscri6, // area
    responsible: res.area.cf_adscri7, // responsable
    items: res.warehouse_request_details.map(x => ({
      ...x.asset_category,
      cf_cantidad_solicitada: x.cf_cantidad_solicitada,
      cf_cantidad_surtida: x.cf_cantidad_surtida,
      cf_cantidad_negada: x.cf_cantidad_negada,
      cf_estatus: x.cf_estatus,
      actions: ['edit', 'delete'],
      warehouse_request_id: x.warehouse_request_id,
      request_detail_id: x.id,
      //desc_corta: x.variant_text ? `${x.asset_category.desc_corta} ${x.variant_text}` : x.asset_category.desc_corta,
      // Displays variant in bold font
      desc_corta: x.variant_text ? <>{x.asset_category.desc_corta} <strong>{x.variant_text}</strong></> : x.asset_category.desc_corta,
      id: x.id,
      variant_asset_category_1: x.variant_asset_category_1,
      variant_asset_category_2: x.variant_asset_category_2,
    }))
  }))
  .catch(e => {
    notice("Ha ocurrido un error, contacte al administrador")
    throw e
  })
};

export const requestGetPdf = async(requestId) => {
  return axios.get(`${ASSTETS_STOCK}/request/${requestId}/get-pdf`);
};

export const editRequest = (body, id) => {
  return axios.put(`${ASSTETS_STOCK}/request-update/${id}`, body);
};

export const updateMaterialRequest = (body, id) => {
  return axios.patch(`${ASSTETS_STOCK}/request-detail-surtir/${id}/`, body);
};

export const dispatchRequest = (body, id) => {
  return axios.patch(`${ASSTETS_STOCK}/request/${id}/dispatch`, body);
};

export const requestSendEmail = async (id) => axios.put(`${ASSTETS_STOCK}/request/${id}/send-mail`);

export const cancelRequest = async(id) => axios.put(`${ASSTETS_STOCK}/request/${id}/cancel-request`);

export const deleteRequest = async(id) => axios.delete(`${ASSTETS_STOCK}/request/${id}`);

//export const editRequest = async(id, payload) => axios.put(`${ASSTETS_STOCK}/request/${id}`, payload);

export const getRequestZpl = (id) => {
    return axios.get(`${ASSTETS_STOCK}/request/${id}/zpl_code`);
};

export const postRequestZpl = (id) => {
  return axios.post(`${ASSTETS_STOCK}/request/zpl_codes_post/`, {ids: [id]});
};
