import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import CustomButton from "../../components/CustomButton";
import PageLayout from "../../components/PageLayout";
import UtilInputs from "../../components/UtilInputs";
import { SetListEntriesForConcentrate } from "../../../redux/ducks/entranceConcentrate.duck";
import { getEntries } from "../../crud/entranceConcentrateCrud";
import EntranceConcentrateTable from "./EntranceConcentrateTable";

const NewEntranceConcentrateForm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.auth);
  const [valueSearch, setValueSearch] = useState({ entry: "" });
  const [dataTableInfo, setDataTableInfo] = useState([]);
  const { actionForm, listEntriesForConcentrate } = useSelector(
    (state) => state.entranceConcentrate
  );

  const handleClearTable = () => {
    handleClearFilter();
    setDataTableInfo([]);
  };

  const handleClearFilter = () => {
    setValueSearch({
      ...valueSearch,
      entry: "",
    });
  };

  const hasValue = (dataTableInfo) => {
    let validate = false;
    for (var index = 0; index < dataTableInfo.length; ++index) {
      var actual = dataTableInfo[index];
      if (actual.id !== valueSearch.entry.data.id) {
        validate = true;
      } else {
        validate = false;
        break;
      }
    }
    return validate;
  };
  const handleAddTableInfo = async () => {
    let inf = dataTableInfo;
    if (valueSearch.entry !== "") {
      if (dataTableInfo.length > 0) {
        let validate = await hasValue(dataTableInfo);
        if (validate) {
          inf.push(valueSearch.entry.data);
          setDataTableInfo(inf);
          dispatch(SetListEntriesForConcentrate(inf));
          handleClearFilter();
        }
      } else {
        inf.push(valueSearch.entry.data);
        setDataTableInfo(inf);
        dispatch(SetListEntriesForConcentrate(inf));
        handleClearFilter();
      }
    }
  };
  const handleFilterChange = ({ target }) => {
    const { name, value } = target;
    setValueSearch({ ...valueSearch, [name]: value });
  };

  useEffect(() => {
    dispatch(SetListEntriesForConcentrate([]));
  }, []);

  useEffect(() => {
    handleAddTableInfo();
  }, [valueSearch]);

  return (
    <PageLayout
      title={
        <div className="d-flex justify-content-between align-items-center">
          {actionForm === "isNew" && (
            <h6 className="text-nowrap">Nuevo Concentrado de Entradas</h6>
          )}
          {actionForm === "isEdit" && (
            <h6 className="text-nowrap">Concentrado de Entradas</h6>
          )}
        </div>
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {actionForm === "isNew" && (
            <UtilInputs
              width={150}
              formValues={valueSearch}
              onValuesChange={handleFilterChange}
              inputValues={[
                {
                  label: "No Registro de entrada",
                  placeholder: "Buscar entrada",
                  name: "entry",
                  type: "search",
                  searchFunction: getEntries,
                  width: 450,
                  required: true,
                },
              ]}
            />
          )}
        </div>

        <div style={{ minHeight: 30 }}></div>
        <div>
          <EntranceConcentrateTable
            data={listEntriesForConcentrate}
            actionForm={actionForm}
            handleAddTableInfo={handleAddTableInfo}
          ></EntranceConcentrateTable>
        </div>
      </div>
    </PageLayout>
  );
};

export default NewEntranceConcentrateForm;
