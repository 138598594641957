import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  SetModule: "[Set Module] Action",
  ClearModule: "[Clear Module] Action",
  Loading: "[Loading] Action",
  Permisos:"[Set User] Permisos"
};

export const initialAuthState = {
  user: undefined,
  authToken: undefined,
  currentModule: undefined,
  refresh: undefined,
  loading: false,
  permisos:[]
};

export const reducer = persistReducer(
  { storage, key: "v713-demo1-auth", whitelist: ["user", "authToken", "refresh", "currentModule","permisos"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, refresh } = action.payload;
        return { ...state, authToken, refresh, loading: true };
      }
      case actionTypes.Permisos:{
        return {...state,permisos:action.payload}
      }
      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user, loading: false };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetModule: {
        return { ...state, currentModule: action.payload.moduleName }
      }

      case actionTypes.ClearModule: {
        return { ...state, currentModule: undefined };
      }

      case actionTypes.Loading: 
        return { ...state, loading: action.payload };
      
      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, refresh) => ({ type: actionTypes.Login, payload: { authToken, refresh } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  setPermisos:(permisos)=> ({type:actionTypes.Permisos,payload:{permisos}}),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  setModule: (moduleName) => ({ type: actionTypes.SetModule, payload: { moduleName } }),
  clearModule: () => ({ type: actionTypes.ClearModule }),
  setLoading: loading => ({ type: actionTypes.Loading, payload: loading }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    try{
      const { data: user } = yield getUserByToken();
      yield put(actions.fulfillUser(user));
    }catch(error) {
      console.log('yield', error);
    }finally{
      yield put(actions.setLoading(false));
    }
  });
}
