import React, { useState, useEffect } from "react";
import UtilInputs from "../../../components/UtilInputs";
import {
  stockGuardTabInputs,
  stockGuardTabInputs2,
  stockGuardTabInputs2Search,
  stockGuardTabInputs3,
  stockGuardTabInputs3Search,
  stockGuardSearchTabInputs,
} from "../../../utils/stockData";
import { fieldsToTypeText } from "../../../utils/objectsFunctions";
import {
  getAllResponsables,
  getEdificiosMultiple,
} from "../../../crud/catalogsCrud";

const GuardTab = ({ selectedStock, onChange, currentMode }) => {
  const [providers, setProviders] = useState([]);
  const [edificios, setEdificios] = useState([]);

  useEffect(() => {
    getAllResponsables()
      .then((res) => {
        res.length > 0 && setProviders(res);
      })
      .catch(() => setProviders([]));
    getEdificiosMultiple()
      .then((res) => res.length > 0 && setEdificios(res))
      .catch(() => setEdificios([]));
  }, []);
  console.log(selectedStock);
  let formatStockGuardTabInput =
    currentMode === "search"
      ? stockGuardSearchTabInputs(providers)
      : stockGuardTabInputs;
  // let inputValues = currentMode === 'search' ? fieldsToTypeText(finantialInputs) : finantialInputs;
  let formatStockGuardTabInputs2 =
    currentMode === "search"
      ? fieldsToTypeText(stockGuardTabInputs2Search)
      : currentMode === "edit"
      ? stockGuardTabInputs2(false)
      : stockGuardTabInputs2(true);
  let formatStockGuardTabInputs3 =
    currentMode === "search"
      ? stockGuardTabInputs3Search(edificios)
      : stockGuardTabInputs3;

  return (
    <div className="grid-2-columns">
      <UtilInputs
        width={200}
        inputValues={formatStockGuardTabInput}
        onValuesChange={onChange}
        formValues={selectedStock}
      />
      <UtilInputs
        width={200}
        inputValues={formatStockGuardTabInputs2}
        onValuesChange={onChange}
        formValues={selectedStock}
      />
      <UtilInputs
        width={200}
        inputValues={formatStockGuardTabInputs3}
        onValuesChange={onChange}
        formValues={selectedStock}
      />
    </div>
  );
};

export default GuardTab;
