import axios from "axios";
import { PERMISSIONS_URL } from "./helpers/routes";

export const getPermissions = (page=1, limit=100000) => {
  return axios.get(`${PERMISSIONS_URL}?page=${page}&limit=${limit}`);
};

export const getSinglePermission = (id) => {
  return axios.get(`${PERMISSIONS_URL}/${id}`);
};

export const createtPermission = (data) => {
  return axios.post(`${PERMISSIONS_URL}`, data);
};

export const updatePermission = (data) => {
  return axios.put(`${PERMISSIONS_URL}/${data.id}`, data);
};

export const deletePermission = (id) => {
  return axios.delete(`${PERMISSIONS_URL}/${id}`);
};