import notice from "../components/Notice";
import { formatDateDDMMYYYY } from "../utils/dates";
import { downLoaderWithURL } from "../utils/downloaderWithURL";
import {
  BASE_URL,
  CATALOGS_URL,
  INSTRUMENTAL_GOODS,
  VEHICULES_URL,
} from "./helpers/routes";
import { getMultipartParams, getParams, handleResponse } from "./helpers/validate";
import axios from "axios";
import { PAGINATION_LIMIT } from "../utils/configData";

const CATALOG_VEHICULE_URL = CATALOGS_URL + "/autos";

const paginateBody = (response, page) => {
  return {
    count: response.data.count,
    links: response.data.links,
    page: page,
    per_page: response.data.per_page,
    total_pages: response.data.total_page,
  };
};

const formatData = (data) => {
  return {
    ...data,
    cabms_key: data.cabms?.cf_grupoc
      ? `${data.cabms.cf_grupoc}·${data.cabms.cf_subgrupoc}·${data.cabms.cf_clasec}·${data.cabms?.cf_subclasec}·${data.cabms?.cf_tipoc}`
      : "",
    cabms_item: data.cabms?.cf_articulo,
    key: `${data.clave?.cf_grupo}·${data.clave?.cf_subgrupo}·${data.clave?.cf_clase}·${data.clave?.cf_subclase}·${data.clave?.cf_tipo}`,
    item: data.clave?.cf_denominacion,
    cf_tarj_circ: data.cf_tarj_circ,
    cf_poliza_seg: data.cf_poliza_seg,
    cf_factura: data.cf_factura && data.cf_factura === "1" ? true : false,
    cf_manual: data.cf_manual,
    cf_placas_y_calcs: data.cf_placas_y_calcs,
    cf_marca: data.marca_nombre
      ? {
          id: data.marca_nombre?.id,
          text: data.marca_nombre?.desc_marca.trim(),
        }
      : null,
    cf_smarca: data.smarca_nombre?.id,
    cf_typev: data.type_nombre?.id,
    cf_area: data.cf_cve_area
      ? { id: data.cf_cve_area.trim(), text2: data.cf_cve_area.trim() }
      : null,
    cf_piso: data.datos_resguardos?.piso,
    cf_oficina: data.datos_resguardos?.oficina,
    cf_suboficina: data.datos_resguardos?.soficina,
    cf_cve_partida: data.cf_cve_partida
      ? { id: data.cf_cve_partida, text2: data.cf_cve_partida }
      : { id: "", text2: "" },
    desc_partida: data.partida?.desc_partida,
    cf_no_resguardo: data.datos_resguardos?.num_resguardo,
    cf_centros_costos: data.datos_resguardos?.centro_costos,
    cf_responsables: data.datos_resguardos?.responsables,
    cf_ubicacion: data.datos_resguardos?.edificio_nombre?.trim(),
    // cf_proveedor: data.cf_cve_prove
    //   ? `${data.supplier.razon_social} | ${data.supplier.num_prove}`
    //   : "",
    //     ? { id: data.cf_cve_prove, text2: data.cf_cve_prove }
    //     : { id: "", text2: "" },
    cf_proveedor: data.cf_cve_prove
      ? {
          id: data?.supplier ? data?.supplier?.num_prove : data.cf_cve_prove,
          text2: data?.supplier ? data.supplier.razon_social : data.cf_cve_prove,
        }
      : { id: "", text2: "" },
  };
};

export const listVehicules = (page, limit, params = "", order = "desc") => {
  return fetch(
    `${VEHICULES_URL}?page=${page}&limit=${limit}&order=${order}&${params}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};

export const updateMultipleVehiclesFields = async (data) => {
  return fetch(
    `${INSTRUMENTAL_GOODS}/vehicle-update-multiple`,
    getParams("PUT", { vehicles: data })
  )
    .then(handleResponse)
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};
export const searchVehicle = async (page, limit, params = "", order = "desc") => {
  const search_params = params;
  return axios.post(`${BASE_URL}/api/bienes-instrumentales/vehicle-search`, {
    search_params,
  });
};
export const searchDropVehicle = async (page, limit, params = "", order = "desc") => {
  const search_params = params;
  return axios.post(`${BASE_URL}/api/bienes-instrumentales/vehicle-search`, {
    search_params,
    estatus: false,
  });
};
export const singleVehicule = (id) => {
  return fetch(`${VEHICULES_URL}/${id}`, getParams("GET"))
    .then(handleResponse)
    .then((res) => {
      const payload = formatData(res);
      return payload;
    })
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};

export const unSuscribeId = async (id, data) => {
  const bajaData = {
    vehicle: id,
    motivo_baja: data.motivo_baja,
    tipo_baja: data.tipo_baja,
  };
  return axios.post(`${VEHICULES_URL}-baja`, bajaData);
};

export const unSuscribeReason = async (id, data) => {
  const bajaData = {
    motivo_baja: data.motivo_baja,
    tipo_baja: data.tipo_baja,
  };
  return axios.patch(`${VEHICULES_URL}-baja/${id}/`, bajaData);
};

export const createVehicule = (data) => {
  return fetch(VEHICULES_URL, getParams("POST", data))
    .then(handleResponse)
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};

export const updateVehicule = (data) => {
  return fetch(`${VEHICULES_URL}/${data.id}/`, getParams("PUT", data))
    .then(handleResponse)
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};

export const deleteVehicule = (id) => {
  return fetch(`${VEHICULES_URL}/${id}`, getParams("DELETE"))
    .then(handleResponse)
    .then((res) => {
      notice("Vehículo borrado", "success");
    })
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};

export const getVehiculesBrands = (query = "") => {
  return fetch(`${CATALOG_VEHICULE_URL}/marcas?desc_marca=*${query}*`, getParams("GET"))
    .then(handleResponse)
    .then((res) =>
      res.results.map((option) => ({
        id: option.id,
        text: option.desc_marca.trim(),
      }))
    )
    .catch((e) => {
      console.log(e);
      notice("Ha ocurrido un error, favor de contactar al administrador");
      throw e;
    });
};

export const getVehiculesSubBrands = (id = "") => {
  return fetch(
    `${CATALOG_VEHICULE_URL}/submarcas?id_marca=${id}&limit=999`,
    getParams("GET")
  )
    .then(handleResponse)
    .then((res) =>
      res.results.map((option) => ({
        id: option.id,
        value: option.id,
        text: option.desc_smarca.trim(),
      }))
    )
    .catch((e) => {
      notice("Ingrese un numero de submarca");
      throw e;
    });
};

export const getVehiculesTypes = (query = "") => {
  return fetch(`${CATALOG_VEHICULE_URL}/tipos?desc_tipov=${query}`, getParams("GET"))
    .then(handleResponse)
    .then((res) =>
      res.results.map((option) => ({
        value: option.id,
        text: option.desc_tipov.trim(),
      }))
    )
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar al administrador");
      throw e;
    });
};

export const getGuardFileVehicule = (id) => {
  const filename = `VH-Resguardos-${id}-${formatDateDDMMYYYY().replaceAll("/", "")}`;
  return fetch(
    `${VEHICULES_URL}/${id}/pdf-resguardo?filename=${filename}`,
    getMultipartParams("GET")
  )
    .then(handleResponse)
    .then((res) => downLoaderWithURL(res?.attachment_url, filename))
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar al administrador");
      throw e;
    });
};

export const getvehicleFilter = (params = "", page = 1, limit = PAGINATION_LIMIT) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.get(
      `${CATALOG_VEHICULE_URL}/marcas?${params}&page=${page}&limit=${limit}`
    );
    if (res?.status && res.status === 200) {
      resolve([
        res.data.results.map((option) => ({
          ...option,
          id: option.id,
          text: option.desc_marca.trim(),
        })),
        paginateBody(res, page),
      ]);
    } else reject({ error: "error" });
  });
};

export const getvehicleSubBrandFilter = (
  params = "",
  page = 1,
  limit = PAGINATION_LIMIT
) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.get(
      `${CATALOG_VEHICULE_URL}/submarcas?${params}&page=${page}&limit=${limit}`
    );
    if (res?.status && res.status === 200) {
      resolve([
        res.data.results.map((option) => ({
          id_marca: option?.marca?.id,
          desc_smarca: option?.desc_smarca,
          desc_marca: option?.marca?.desc_marca,
          id: option.id,
          text: option.desc_marca,
        })),
        paginateBody(res, page),
      ]);
    } else reject({ error: "error" });
  });
};

export const listVehicleDrops = (page, limit) => {
  return fetch(`${VEHICULES_URL}-baja?page=${page}&limit=${limit}`, getParams("GET"))
    .then(handleResponse)
    .then((res) => {
      const { count, per_page, results } = res;
      const newData = results.map((vh) => ({
        ...vh?.vehicle_data,
        tipo_baja: vh?.tipo_baja,
        motivo_baja: vh?.motivo_baja,
      }));
      return { results: newData, count, per_page, page };
    })
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};

export const singleVehiculeDrop = (id) => {
  return fetch(`${VEHICULES_URL}-baja/${id}`, getParams("GET"))
    .then(handleResponse)
    .then((res) => {
      const payload = { ...res, ...formatData(res.vehicle_data) };
      delete payload["vehicle_data"];

      return payload;
    })
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};

export const createVehiculeDrop = (data) => {
  return fetch(`${VEHICULES_URL}-baja`, getParams("POST", data))
    .then(handleResponse)
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};

export const postZplVehicle = (id) => {
  return axios.post(`${INSTRUMENTAL_GOODS}/vehicle/zpl_codes_post/`, {
    ids: [id],
  });
};

export const fetchGuardFormat = (id) => {
  const url = `${VEHICULES_URL}/${id}/pdf-resguardo`;
  return axios.get(url);
};
export const fetchGuardVehicleList = (id) => {
  return axios.post(`${VEHICULES_URL}/pdf-resguardo`, { ids: id });
};

export const vehiclesReports = (report, ids, type, isDropPage) => {
  let body;
  if (isDropPage) {
    body = { ids, type, report_type: "baja" };
  } else {
    body = { ids, type };
  }
  return fetch(
    `${INSTRUMENTAL_GOODS}/reports_catalogs-vehicles/`,
    getParams("POST", body)
  )
    .then(handleResponse)
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};
