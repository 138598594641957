import React, {useMemo, useState} from "react";
import {Link} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../_helpers";
import { useSelector } from "react-redux";

export function Brand() {
  const uiService = useHtmlClassService();
  const { currentModule } = useSelector(store => store.auth);
  const initLogo = document.getElementById('kt_body').classList.contains("aside-minimize");
  const [brandLogo, setBrandLogo] = useState(!initLogo);

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
          uiService.config,
          "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);
  
  const handleToggle = () => {
    setBrandLogo(brandLogo => !brandLogo);
  };

  return (
    <>
      {/* begin::Brand */}
      <div
          className={`brand flex-column-auto ${layoutProps.brandClasses}`}
          id="kt_brand"
      >
        { brandLogo &&
          <Link to="" className="brand-logo">
            {/* <img alt="logo" src={layoutProps.headerLogo}/> */}
            <p className="text-uppercase text-light" style={{ fontSize: 15, marginBottom: 0 }}>{currentModule?.display_text}</p>
          </Link>
        }

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle" onClick={handleToggle}>
              <span className="svg-icon svg-icon-xl">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
              </span>
            </button>
            {/* end::Toolbar */}
            </>
        )}
      </div>
      {/* end::Brand */}
      </>
  );
}
