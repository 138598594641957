import React from "react";
import FullTable from "../../../components/FullTable";
import {
  last_seen_columns,
  last_scanned_columns,
} from "../../../utils/stockData";

const LastSeenTab = ({ selectedStock }) => {
  const onEye = (id) =>
    window.open(
      `/bienes-instrumentales/levantamiento-inventario/resultados/${id}`,
      "_blank"
    );

  return (
    <>
      <FullTable
        hideCheckbox
        subtitle="Última vez visto"
        hide_pagination
        has_button={false}
        search_button={false}
        entities={
          Array?.isArray(selectedStock.last_seen_data)
            ? selectedStock.last_seen_data
            : []
        }
        columns={last_seen_columns(onEye)}
      />
      <FullTable
        style={{ marginTop: "24px" }}
        subtitle="Última vez escaneados"
        hideCheckbox
        hide_pagination
        has_button={false}
        search_button={false}
        entities={
          Array?.isArray(selectedStock.last_scanned_data)
            ? selectedStock.last_scanned_data
            : []
        }
        columns={last_scanned_columns(onEye)}
      />
    </>
  );
};

export default LastSeenTab;
