import React, { useState } from "react";
import { makeStyles, TextField, Typography, useMediaQuery } from "@material-ui/core";
import InputMask from "react-input-mask";
import { inputFormatDate } from "../utils/dates";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: ({ width }) => (width ? width : 200),
  },
  label: {
    color: ({ disabled, touched, hover }) =>
      (touched || hover) && !disabled ? theme.palette.secondary.main : "black",
    marginLeft: 5,
    fontWeight: 500,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  input: {
    marginTop: 0,
    height: 36.4333333,
  },
}));

const InputMaskCustom = (props) => {
  const { inputRef, value, onChange, name, isFullYear } = props;
  const [dateFormat, setDateFormat] = useState("d9/m9/2099");

  const formatDate = () => {
    value.length === 10 ? setDateFormat("d9/m9/9999") : setDateFormat("d9/m9/2099");
    if (!value) return;

    let output =
      value.length === 10 ? value : [value.slice(0, 6), "20", value.slice(6)].join("");

    return onChange({
      target: {
        value: output.length > 10 ? output.slice(0, 10) : output,
        name,
      },
    });
  };

  return (
    <InputMask
      {...props}
      formatChars={{
        "9": "[0-9]",
        d: /[0-3]/,
        m: /[0-1]/,
      }}
      inputRef={inputRef}
      mask={dateFormat}
      name={name}
      id={name}
      value={value !== null ? value : ""}
      onChange={onChange}
      onFocus={(e) => {
        let output;

        if (isFullYear) {
          output = [value?.slice(0, 6), value?.slice(6, 10)].join("");
          setDateFormat("d9/m9/9999");
        } else {
          output = [value?.slice(0, 6), value?.slice(8, 10)].join("");
          setDateFormat("d9/m9/99");
        }
        onChange({ target: { value: output, name } });
      }}
      onKeyDown={(e) => e.key === "Enter" && value?.length !== 10 && formatDate()}
      onBlur={() => {
        value[0] && value[0] !== "_"
          ? formatDate()
          : onChange({ target: { value: "", name } });
      }}
    />
  );
};

const SimpleDate = ({
  label,
  name,
  onChange,
  value,
  width = false,
  labelStyle,
  onKeyDown = () => {},
  disabled,
  required,
  bgColor,
  errorMessage,
  isFullYear = false,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [touched, setTouched] = useState(false);
  const [hover, setHover] = useState(false);
  const classes = useStyles({
    value,
    isMobile,
    width,
    disabled,
    bgColor,
    touched,
    hover,
  });
  return (
    <div className={classes.container}>
      <Typography className={classes.label} style={labelStyle}>
        {label}
      </Typography>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        color="secondary"
        placeholder={isFullYear ? "dd/mm/yyyy" : "dd/mm/yy"}
        className={classes.input}
        required={required}
        disabled={disabled}
        name={name}
        value={
          typeof value === "string" && value?.includes("-")
            ? inputFormatDate(value)
            : value
        }
        onChange={(e) => onChange(e)}
        onKeyDown={(e) => e.key === "Enter" && onKeyDown()}
        onFocusCapture={() => setTouched(true)}
        onBlurCapture={() => setTouched(false)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        error={(touched && required && !value) || Boolean(errorMessage)}
        helperText={errorMessage}
        InputProps={{
          inputComponent: InputMaskCustom,
          inputProps: {
            isFullYear: isFullYear,
          },
        }}
      />
    </div>
  );
};

export default SimpleDate;
