import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatNotificationData } from "../utils/NotificationUtils";
import { setNotification } from "../../redux/ducks/notification.duck";

const SOCKET = process.env.REACT_APP_SOCKET_URL;

const MainSocket = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      const socket = new WebSocket(SOCKET);
      socket.onopen = () => console.log("Web Socket Iniciado...");

      socket.onmessage = (e) => {
        const data = JSON.parse(e.data);

        const { users } = data.message;

        let uniqueUsers = [...new Set(users)];

        if (Boolean(uniqueUsers.find((u) => u === user.id))) {
          dispatch(setNotification(formatNotificationData(data.message)));
        }
      };

      socket.onerror = (error) => console.log(error);

      return () => {
        socket.onclose = () => console.log("Web Socket Cerrado...");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default MainSocket;
