import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { MenuOption } from "./MenuOption";
import SVG from "react-inlinesvg";

export function AsideMenuList({ layoutProps }) {
  const { currentModule } = useSelector((store) => store.auth);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {currentModule.display_text === "BIENES INSTRUMENTALES" && (
          <li className="menu-item">
            <NavLink
              className={`${"menu-link menu-toggle"}`}
              to={"/bienes-instrumentales/"}
            >
              <span className="svg-icon menu-icon">
                <SVG src="/media/svg/icons/Home/Home.svg" />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
        )}

        {currentModule.submodules
          .sort((a, b) => a.seq < b.seq && -1)
          .map((module) => (
            <MenuOption key={module.id} item={module} />
          ))}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
