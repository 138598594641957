import React from "react";
import { columnStatusCssClasses, columnStatusTitles } from "../../utils/columnFormattersHelpers";

export const StatusColumnFormatter = (cellContent, row) => {
	return (
		<span
			className={`badge badge-pill badge-${
				columnStatusCssClasses[row.is_closed ? 1 : 2]
			} label-inline`}
		>
			{columnStatusTitles[row.is_closed ? 1 : 0]}
		</span>
	);
};
