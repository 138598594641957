import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import FullTable from '../../components/FullTable'
import { getTasksbyBoardColumn } from '../../crud/taskManagementCrud'
import ToDoDetailTaskModal from './components/ToDoDetailTaskModal'
import { finished_task_columns } from './utils'

const ToDoFinished = () => {
  const { id } = useParams()
  const history = useHistory()
  const { isWarehousePage, isInstrumentalGoodsPage } = useSelector(store => store.pathname);
  const [detailModal, setDetailModal] = useState(false)
  const [taskDetailId, setTaskDetailId] = useState(0);
  const [tasks, setTasks] = useState([])
  const [isDeletedATask, setIsDeletedATask] = useState(false);

  const fetchTasksByBoard = useCallback(async() => {
    getTasksbyBoardColumn(id, 5)
    .then(res => setTasks(res))
    .catch(console.log)  
  },[id],)

  useEffect(() => {
    if (id)
    fetchTasksByBoard()
  }, [fetchTasksByBoard, id])

  const onEye = (id) => {
    setTaskDetailId(id)
    setDetailModal(true)
  }

  useEffect(()=>{
    if( isDeletedATask ){
      fetchTasksByBoard()
    }
    setIsDeletedATask(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isDeletedATask, setIsDeletedATask ])

  const onReturnToBoard = () => {
    let route
    if (isWarehousePage) route = "/inventario-bienes-almacen-general/administracion-tareas"
    else if (isInstrumentalGoodsPage) route = "/bienes-instrumentales/administracion-tareas"
    else route = "/administrador-tareas"
    history.push(route)
  }

  return (
    <>
      <FullTable
        title='Tareas archivadas > Entregas de fin de año'
        count={tasks.length}
        entities={tasks}
        columns={finished_task_columns(onEye)}
        has_button
        buttonTitle='Regresar a los tableros'
        onClickButton={onReturnToBoard}
        search_button={false}
        hideCheckbox
        hide_pagination
      />
      <ToDoDetailTaskModal open={detailModal} setOpen={setDetailModal} detailId={taskDetailId} fetchTasksByBoard={fetchTasksByBoard} setIsDeletedATask={setIsDeletedATask} />
    </>
  )
}

export default ToDoFinished
