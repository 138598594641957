import React from 'react';
import BaseModal from './BaseModal';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import AutocompleteInput from './AutocompleteInput';
import { getAreas } from '../crud/catalogsCrud';
import Chip from './Chip';

const useStyles = makeStyles({
	inlineInputs: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingBottom: 10,
		paddingTop: 5,
		'&>*': {
			margin: 'auto',
			display: 'flex',
			alignItems: 'flex-start',
			flexDirection: 'column',
		},
		width: '100%',
	},
	chipDiv: { marginTop: 17, height: 35 },
});

/**
 * Modal that shows items need to be guarded.
 * @param {[{}]} areaList  -  Array of objects for the guard area field.
 * @param {*} handleChange -  Function that is executed when a change is made to the items.
 * @param {[{}]} items     -  Array of objects with the data that will be shown on the modal's table.
 * @param {Boolean} open   -  Boolean that indicates if the modal must be visible.
 * @param {*} onClose      -  Function that will be executed when an action that closes the modal is made.
 * @param {*} onConfirm     -  Function that will be executed with the confirm button.
 * @param {*} onCancel     -  Function that will be executed with the cancel button.
 */

//width  < 1008
const GuardModal = ({ handleChange, items=[], open, onClose, onConfirm, onCancel, disableOnConfirm, onClickChip }) => {
	const classes = useStyles();
    return (
			<BaseModal
				open={open}
				maxWidth='md'
				onClose={onClose}
				title='Resguardar'
				onConfirm={onConfirm}
				onCancel={onCancel}
				cancelLabel='Cancelar'
				confirmLabel='Resguardar'
				disableOnConfirm={disableOnConfirm}
				color='secondary'
			>
				<Table>
					<TableHead>
						<TableRow>
							<CustomTableCell isHead>No. Entrada</CustomTableCell>
							<CustomTableCell isHead>No. Inventario</CustomTableCell>
							<CustomTableCell isHead>CABMS</CustomTableCell>
							<CustomTableCell isHead>Artículo CABMS</CustomTableCell>
							<CustomTableCell isHead>Descripción</CustomTableCell>
							<CustomTableCell isArea isHead>
								Área resguardante
							</CustomTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{items.map((item, index) => (
							<TableRow key={item.id}>
								<CustomTableCell isFirst>
									{item.asset_acquisition_id}
								</CustomTableCell>
								<CustomTableCell>{item.id}</CustomTableCell>
								<CustomTableCell>{item.cabms_key}</CustomTableCell>
								<CustomTableCell>{item.cabms_cf_denominacion}</CustomTableCell>
								<CustomTableCell>{item.cf_descripcion}</CustomTableCell>
								<CustomTableCell isArea>
									<div className={classes.inlineInputs}>
									<AutocompleteInput
										onChange={({ target }) =>
											handleChange(index, {
												target: { name: 'cf_cve_area', value: target.value },
											})
										}
										name='cf_cve_area'
										value={item.cf_cve_area}
										label='Área resguardante'
										placeholder='Ingrese el área deseada'
										searchFunction={getAreas}
										required
									/>
									{item.confirmationField && items.length > 1 && (
										<span className={classes.chipDiv}>
											<Chip
												color='#0093FF'
												label='Asignar misma área'
												lighten={0.8}
												fontSize='1rem'
												onClick={() => onClickChip(item)}
												cursor='pointer'
											/>
										</span>
									)}
									</div>
								</CustomTableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</BaseModal>
		);
}

const CustomTableCell = ({ isArea = false, isHead = false, isFirst = false, children }) => {

    const style = {
        normal: {
            backgroundColor: isArea ?  'white' : '#F9F9F9',
            padding: '0.25rem 1rem 0.25rem 1rem',
            borderRight: 'solid #d9d7d7 1px',
            borderTop: 'solid #d9d7d7 1px',
        },
        head: {
            padding: '0.25rem 1rem 0.25rem 1rem',
            maxWidth: isArea ? '100%' : '5rem',
        }
    }

    if (isHead) {
        return (
            <TableCell style={ style.head }>
                { children }
            </TableCell>
        )
    } else {
        return (
            <TableCell
                style={ style.normal }
                scope={ isFirst ? 'row' : '' }
                component={ isFirst ? 'th' : '' }  
            >
                { children }
            </TableCell>
        )
    }
}

export default GuardModal;