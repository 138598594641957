import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { 
  LIST_VIEW 
} from '../../app/utils/digitalVault';

const actionType = {
  SetFoldersViewType: '[DIGITAL VAULT] SET_FOLDERS_VIEW_TYPE',
  SetDigitalVaultFolders: '[DIGITAL VAULT] SET_DIGITAL_VAULT_FOLDERS',
  SetAllDigitalVaultFolders: '[DIGITAL VAULT] SET_ALL_DIGITAL_VAULT_FOLDERS',
  SetSelectedFoldersIds: '[DIGITAL VAULT] SET_SELECTED_FOLDERS_IDS',
  SetCurrentDigitalVaultId: '[DIGITAL VAULT] SET_CURRENT_DIGITAL_VAULT_ID',
  SetSearchedFiles: '[DIGITAL VAULT] SET_SEARCHED_FILES',
  SetVisitFolders: '[DIGITAL_VAULT] SET_VISIT_FOLDERS',
  ClearVisitedFolders: '[DIGITAL_VAULT] CLEAR_VISITED_FOLDERS',
  ClearSelectedFoldersIds: '[DIGITAL_VAULT] CLEAR_SELECTED_FOLDERS',
  SetSearchMode: '[DIGITAL_VAULT] SET_SEARCH_MODE',
  DeleteFileFromList: '[DIGITAL_VAULT] DELETE_FILE'
};

export const initialState = {
  foldersViewType: LIST_VIEW,
  digitalVaultFolders: [],
  allDigitalVaultFolders: [],
  selectedFoldersIds: [],
  currentDigitalVaultId: null,
  searchedFiles: [],
  visitFolders: [],
  searchMode: false
};

export const reducer = persistReducer(
  // Must save foldersViewType
  { storage, key: 'digitalVault', whitelist: ["foldersViewType"] },
  (state = initialState, action) => {
    switch (action.type) {
			case actionType.SetFoldersViewType:
				return { ...state, foldersViewType: action.payload };

      case actionType.SetDigitalVaultFolders:
        return { ...state, digitalVaultFolders: action.payload };

      case actionType.SetAllDigitalVaultFolders:
          return { ...state, allDigitalVaultFolders: action.payload };

      case actionType.SetSelectedFoldersIds:
        return { ...state, selectedFoldersIds: action.payload };

      case actionType.SetCurrentDigitalVaultId:
        return { ...state, currentDigitalVaultId: action.payload };
      
      case actionType.SetSearchedFiles:
        return { ...state, searchedFiles: action.payload };
      
      case actionType.SetVisitFolders:
        const isFolderVisited = state.visitFolders.find( folder => folder.id === action.payload[0].id )
        if ( isFolderVisited === undefined ) {
          return { ...state, visitFolders: [ ...state.visitFolders, ...action.payload ] }
        } else {
          const visitFolderIndex = state.visitFolders.findIndex( folder => folder.id === action.payload[0].id );
          return { ...state, visitFolders: state.visitFolders.slice(0, visitFolderIndex+1) }
        }
      
      case actionType.ClearVisitedFolders:
        return { ...state, visitFolders: initialState.visitFolders }
      
      case actionType.ClearSelectedFoldersIds:
        return { ...state, selectedFoldersIds: initialState.selectedFoldersIds }
      
      case actionType.SetSearchMode:
        return { ...state, searchMode: action.payload }

      case actionType.DeleteFileFromList:
        return { 
          ...state, 
          digitalVaultFolders: state.digitalVaultFolders.filter( folder => folder.id !== action.payload ),
          selectedFoldersIds: state.selectedFoldersIds.filter( folder => folder !== action.payload )
        } 
        
      default:
				return state;
		}
  }
)

export const setFoldersViewType = foldersViewType => ({ type: actionType.SetFoldersViewType, payload: foldersViewType });

export const setDigitalVaultFolders = folders => ({ type: actionType.SetDigitalVaultFolders, payload: folders?.map( folder => ( folder.hasOwnProperty('attachment') ? { ...folder, id_resource: folder.id, id: `file_${folder.id}` } : { ...folder, id_resource: folder.id, id: `folder_${folder.id}` }) ) });

export const setVisitFolders = folder => ({ type: actionType.SetVisitFolders, payload: folder })

export const setAllDigitalVaultFolders = folders => ({ type: actionType.SetAllDigitalVaultFolders, payload: folders });

export const setSelectedFoldersIds = ids => ({ type: actionType.SetSelectedFoldersIds, payload: ids });

export const clearSelectedFoldersIds = () => ({ type: actionType.ClearSelectedFoldersIds })

export const deleteFileFromList = (fileId) => ({ type: actionType.DeleteFileFromList, payload: fileId })
 
export const setCurrentDigitalVaultId = id => ({ type: actionType.SetCurrentDigitalVaultId, payload: id });

export const setSearchedeFiles = data => ({ type: actionType.SetSearchedFiles, payload: data });

export const clearVisitedFolders = () => ({ type: actionType.ClearVisitedFolders })

export const setSearchMode = (mode) => ({ type: actionType.SetSearchMode, payload: mode })