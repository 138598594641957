import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionType = {
  SetRequests: "[DASHBOARD_REQUESTS] SET_REQUESTS",
  SetNewRequest: "[DASHBOARD_REQUESTS] SET_NEW_REQUEST",
  SetAssets: "[DASHBOARD_ASSETS] SET_ASSETS",
  SetNewAsset: "[DASHBOARD_ASSET] SET_NEW_ASSET",
  SetTasks: "[DASHBOARD_TASKS] SET_TASKS"
};

export const initialState = {
    requests: {
      results: []
    },
    assets: [],
    tasks: {
      results: []
    }
};

export const reducer = persistReducer(
  { storage, key: 'dashboard', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {

      case actionType.SetRequests:
        const { results } = action.payload;
        return { ...state, requests: { results } };
      
      case actionType.SetNewRequest:
        return { ...state, requests: { results: [ action.payload, ...state.requests.results ] } }
    
      case actionType.SetTasks:
        return { ...state, tasks: {...state.tasks, results: action.payload } }

      case actionType.SetAssets:
        return { ...state, assets: [ ...state.assets, action.payload ] };

      case actionType.SetNewAsset:
        return { ...state, assets: [ action.payload, ...state.assets ] }

      default:
        return state;
    };
  }
);

export const setAssets = assets => ({ type: actionType.SetAssets, payload: assets });
export const setNewAsset = asset => ({ type: actionType.SetNewAsset, payload: asset });
export const setTasks = (tasks) => ({ type: actionType.SetTasks, payload: tasks });
export const setRequests = requests => ({ type: actionType.SetRequests, payload: requests });
export const setNewRequest = request => ({ type: actionType.SetNewRequest, payload: request });