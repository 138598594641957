import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import { Box, Grid } from "@material-ui/core";
import PageLayout from "../../components/PageLayout";
import UtilInputs from "../../components/UtilInputs";
import notice from "../../components/Notice";
import {
  defaultBatchRecurrent,
  recurrentDepartureColumns,
  recurrentForm,
} from "../../utils/departureData";
import { setRecurrent } from "../../../redux/ducks/departure.duck";
import { isAValidInteger } from "../../utils/inputValidations";
import BatchTable from "../../components/BatchTable";
import SimpleInput from "../../components/SimpleInput";
import { getArticles } from "../../crud/catalogsCrud";

const DepartureRecurrent = () => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [resultAutocomplete, setResultAutocomplete] = React.useState([]);
  const dispatch = useDispatch();
  const { recurrent } = useSelector((state) => state.departure);
  const [recurrentsList, setRecurrentsList] = useState([]);

  const handleForm = (e) => {
    const { name, value } = e.target;
    let namePerson;

    if (value.includes("Lic")) {
      namePerson = value.replace("Lic. ", "");
    } else {
      namePerson = value;
    }
    const updateField = (fieldName) => {
      dispatch(
        setRecurrent({
          ...recurrent,
          [fieldName]: namePerson,
          [`puesto_${fieldName}`]: "Lic",
          [`${fieldName}_drop`]: value,
        })
      );
    };

    if (name === "responsable_entrega_drop") {
      updateField("responsable_entrega");
    } else if (name === "responsable_recibe_drop") {
      updateField("responsable_recibe");
    } else {
      dispatch(setRecurrent({ ...recurrent, [name]: value }));
    }
  };

  const formatItemSelected = (aIndex, value, updatedState, stock = "") => {
    updatedState[aIndex]["articulo_recurrente_id"] = value;
    updatedState[aIndex]["stock"] = stock;
    updatedState[aIndex]["marca"] = recurrentsList[aIndex]?.find(
      (it) => it.id === value
    )?.marca;
    updatedState[aIndex]["modelo"] = recurrentsList[aIndex]?.find(
      (it) => it.id === value
    )?.modelo;
    updatedState[aIndex]["serie"] = recurrentsList[aIndex]?.find(
      (it) => it.id === value
    )?.serie;
    updatedState[aIndex]["nro_inventario"] = recurrentsList[aIndex]?.find(
      (it) => it.id === value
    )?.nro_inventario;
    updatedState[aIndex]["inventariable"] = recurrentsList[aIndex]?.find(
      (it) => it.id === value
    )?.inventariable
      ? { color: "success", text: "Inventariable" }
      : { color: "dark", text: "No Inventariable" };
    updatedState[aIndex]["cantidad"] = recurrentsList[aIndex]?.find(
      (it) => it.id === value
    )?.stock_actual;

    return updatedState;
  };

  const handleBatch = (i = "", { target }, aIndex) => {
    const { name, value } = target;
    if (name === "articulo" && typeof value === "object") {
      if (value.cve_articulo < 9000) {
        notice("El articulo no pertence a este grupo");
        return;
      } else {
        let newRec = recurrentsList;

        const newRecurrentOptions = value?.recurrent_assets?.map((it) => ({
          ...it,
          value: it.id,
          text: it.descripcion,
          text2: `stock: ${it.stock_actual}`,
          name: it.descripcion,
          textMuted: `stock: ${it.stock_actual}`,
          disabled: recurrent.outbound_recurrent_details.find(
            ({ articulo_recurrente_id }) => articulo_recurrente_id === it.id
          )
            ? true
            : false, // disable selected options
        }));

        newRec[aIndex] = newRecurrentOptions;
        setRecurrentsList(newRec);

        let updatedState = recurrent.outbound_recurrent_details;
        updatedState[aIndex][name] = value;
        updatedState[aIndex]["cf_cve_articulo"] = value?.cve_articulo;
        // Fill by default the next item into input articulo_recurrente_id
        const recurrentDetail = recurrent.outbound_recurrent_details.filter(
          (detail) => detail.cf_cve_articulo === value.cve_articulo
        ).length;
        const defaultSelectedItem = recurrentsList[aIndex][recurrentDetail - 1];
        if (defaultSelectedItem) {
          const defaultSelectedItemId = defaultSelectedItem["id"];
          updatedState = formatItemSelected(
            aIndex,
            defaultSelectedItemId,
            updatedState
          );
        }
        // If user is changing article
        updatedState[aIndex]["articulo"] = value?.text;
        handleForm(
          {
            target: { name: "outbound_recurrent_details", value: updatedState },
          },
          i
        );
        return;
      }
    } else if (name === "articulo_recurrente_id") {
      const stock = recurrentsList[aIndex].find((item) => item.id === value)
        .stock_actual;
      let updatedState = formatItemSelected(
        aIndex,
        value,
        recurrent.outbound_recurrent_details,
        stock
      );

      let newRec = recurrentsList.map((rec) =>
        rec.map((item) => ({
          ...item,
          disabled: recurrent.outbound_recurrent_details.find(
            ({ articulo_recurrente_id }) => articulo_recurrente_id === item.id
          )
            ? true
            : false,
        }))
      );

      setRecurrentsList(newRec);
      handleForm(
        { target: { name: "outbound_recurrent_details", value: updatedState } },
        i
      );
      return;
    } else if (name === "cantidad") {
      if (isAValidInteger(value) && value > 0) {
        if (
          !recurrent.outbound_recurrent_details[aIndex]["inventariable"] ||
          recurrent.outbound_recurrent_details[aIndex]["inventariable"]
            ?.text === "No Inventariable"
        ) {
          let updatedState = recurrent.outbound_recurrent_details;
          if (
            updatedState[aIndex]["inventariable"].text === "No Inventariable" &&
            value > updatedState[aIndex]["stock"]
          ) {
            notice(
              "No puedes agregar cantidad mayor al stock disponible",
              "error"
            );
            return;
          }
          handleForm(
            {
              target: {
                name: "outbound_recurrent_details",
                value: updatedState,
              },
            },
            i
          );
          return;
        }
      }
    } else {
      let updatedState = recurrent.outbound_recurrent_details;
      updatedState[aIndex][name] = value;
      handleForm(
        { target: { name: "outbound_recurrent_details", value: updatedState } },
        i
      );
      return;
    }
  };

  const handleAddItem = () => {
    setRecurrentsList([...recurrentsList, []]);
    dispatch(
      setRecurrent({
        ...recurrent,
        outbound_recurrent_details: [
          ...recurrent.outbound_recurrent_details,
          { ...defaultBatchRecurrent, id: uuid() },
        ],
      })
    );
  };

  const onRemoveItem = (recItem, index) => {
    dispatch(
      setRecurrent({
        ...recurrent,
        outbound_recurrent_details: [
          ...recurrent.outbound_recurrent_details.filter(
            (item) => item.cf_cve_articulo !== recItem?.cf_cve_articulo
          ),
        ],
      })
    );
    const newRecurrentsList = recurrentsList.filter((item, i) => i !== index);
    setRecurrentsList(newRecurrentsList);
  };

  const onHandlechecks = (e) => {
    const { name, checked } = e.target;
    dispatch(
      setRecurrent({
        ...recurrent,
        [name]: checked,
      })
    );
  };

  useEffect(() => {
    dispatch(
      setRecurrent({
        ...recurrent,
        outbound_recurrent_details: [],
      })
    );
  }, []);
  useEffect(() => {
    if (recurrent.atiende_folio_dgrmsg === false) {
      dispatch(
        setRecurrent({
          ...recurrent,
          folio_dgrmsg: "",
        })
      );
    }
  }, [recurrent.atiende_folio_dgrmsg]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const getData = setTimeout(async () => {
        const result = await getArticles(searchTerm);
        setResultAutocomplete(result);
      }, 2000);

      return () => clearTimeout(getData);
    }
  }, [searchTerm]);
  function contarElementosConCveArticulo(array, cf_cve_articulo) {
    let contador = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i].cf_cve_articulo === cf_cve_articulo) {
        contador++;
      }
    }
    return contador;
  }
  const onGetResult = (result) => {
    if (result.recurrent_assets.length === 0) {
      notice("No hay stock del artículo seleccionado", "error");
      setResultAutocomplete([]);
      setSearchTerm("");
      return;
    }
    const aIndex = recurrent.outbound_recurrent_details.length;
    setResultAutocomplete([]);
    setSearchTerm("");

    if (result.cve_articulo < 9000) {
      notice("El articulo no pertence a este grupo");
      return;
    }

    const itemExists = recurrent.outbound_recurrent_details.some(
      (it) => it.cf_cve_articulo?.toString() === result.cve_articulo?.toString()
    );

    const count = contarElementosConCveArticulo(
      recurrent.outbound_recurrent_details,
      result.cve_articulo
    );

    if (count + 1 > result?.recurrent_assets.length) {
      notice("No puedes agregar el elemento nuevamente", "error");
      return;
    }

    let newRec = recurrentsList;
    const newRecurrentOptions = result?.recurrent_assets?.map((it) => ({
      ...it,
      value: it.id,
      text: it.descripcion,
      text2: `stock: ${it.stock_actual}`,
      name: it.descripcion,
      textMuted: `stock: ${it.stock_actual}`,
      disabled: recurrent.outbound_recurrent_details.find(
        ({ articulo_recurrente_id }) => articulo_recurrente_id === it.id
      )
        ? true
        : false, // disable selected options
    }));

    newRec[aIndex] = newRecurrentOptions;

    setRecurrentsList(newRec);
    let updatedItems = [...recurrent.outbound_recurrent_details];
    updatedItems[aIndex] = {
      cf_cve_articulo: result.cve_articulo,
      articulo: result.desc_corta,
      articulo_recurrente_id: "",
      cantidad: "",
      descripcion: "",
      marca: "",
      modelo: "",
      serie: "",
      stock: 0,
      nro_inventario: "",
      inventariable: {
        color: "",
        text: "",
      },
      id: "",
    };
    if (result?.recurrent_assets.length !== 0) {
      const value = result?.recurrent_assets[0].id;
      updatedItems[aIndex]["articulo_recurrente_id"] = "";
      updatedItems[aIndex]["marca"] = recurrentsList[aIndex]?.find(
        (it) => it.id === value
      )?.marca;
      updatedItems[aIndex]["modelo"] = recurrentsList[aIndex]?.find(
        (it) => it.id === value
      )?.modelo;
      updatedItems[aIndex]["serie"] = recurrentsList[aIndex]?.find(
        (it) => it.id === value
      )?.serie;
      updatedItems[aIndex]["nro_inventario"] = recurrentsList[aIndex]?.find(
        (it) => it.id === value
      )?.nro_inventario;
      updatedItems[aIndex]["inventariable"] = recurrentsList[aIndex]?.find(
        (it) => it.id === value
      )?.inventariable
        ? { color: "success", text: "Inventariable" }
        : { color: "dark", text: "No Inventariable" };
      updatedItems[aIndex]["cantidad"] = recurrentsList[aIndex]?.find(
        (it) => it.id === value
      )?.stock_actual;
    }

    handleForm(
      {
        target: { name: "outbound_recurrent_details", value: updatedItems },
      },
      aIndex
    );
  };

  return (
    <PageLayout title="Salida recurrente">
      <UtilInputs
        width={300}
        formValues={recurrent}
        onValuesChange={handleForm}
        inputValues={recurrentForm(
          recurrent.responsable_entrega,
          recurrent.responsable_recibe,
          recurrent.atiende_folio_dgrmsg
        )}
      />
      <section
        style={{
          display: "flex",
          fontWeight: "bold",
          gap: "10px",
          margin: "10px 0px",
        }}
      >
        <div>
          <label style={{ marginRight: "10px" }}>
            <input
              type="checkbox"
              name="atiende_folio_dgrmsg"
              style={{ marginRight: "10px" }}
              onChange={(e) => onHandlechecks(e)}
              value={recurrent.atiende_folio_dgrmsg}
            />
            ATIENDE A FOLIO DE LA DGRMSG.
          </label>
        </div>

        <div>
          <label style={{ marginRight: "10px" }}>
            <input
              type="checkbox"
              name="ccp_instrumentales"
              style={{ marginRight: "10px" }}
              onChange={(e) => onHandlechecks(e)}
              value={recurrent.ccp_instrumentales}
            />
            C.C.P. Jefe de Departamento de Control de Inventarios
          </label>
        </div>
      </section>
      <Grid item xs={5} mt={2} mb={2}>
        <SimpleInput
          label="Buscar por clave o nombre"
          width={"100%"}
          showAutocomplete
          onChange={({ target }) => setSearchTerm(target.value)}
          autocompleteResults={resultAutocomplete}
          onGetResult={onGetResult}
          value={searchTerm}
        />
      </Grid>
      <Box className="mt-5">
        <BatchTable
          i={0}
          disableAddItem
          items={recurrent?.outbound_recurrent_details ?? []}
          columns={recurrentDepartureColumns}
          handleChange={(i, column, value, index) =>
            handleBatch(i, { target: { value, name: column } }, index)
          }
          addRow={handleAddItem}
          deleteRow={onRemoveItem}
          onSearchTextChange={(i, column, value, index) =>
            handleBatch(i, { target: { value, name: column } }, index)
          }
          itemsOptions={(item, column, index) => {
            if (column?.value === "articulo_recurrente_id") {
              return recurrentsList[index];
            }
          }}
        />
      </Box>
    </PageLayout>
  );
};

export default DepartureRecurrent;
