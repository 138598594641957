import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setVariant } from '../../redux/ducks/variants.duck'
import { createVariant, getSingleVariant, updateVariant } from '../crud/variantsCrud'
import { defaultVariant } from '../utils/configData'
import EntryActionIcons from './EntryActionIcons'
import ModeTabs from './ModeTabs'
import notice from './Notice'

const ConfigVariantActions = () => {
  const { variant, selectedVariants } = useSelector(state => state.variants)
  const history = useHistory()
  const dispatch = useDispatch()
  const [actionDisable, setActionDisable] = useState(false)

  const validations = () => {
    if (!variant.nombre) notice("Es necesario ingresar un nombre a la variante")
    else if (!variant.descripcion) notice("Es necesario ingresar una descripción a la variante")
    else return true
  }

  const handleSubmit = (requestFunciton) => {
    if (validations()) {
      setActionDisable(true)
      requestFunciton(variant)
      .then(res => {
        console.log(res)
        history.push("/inventario-bienes-almacen-general/configuracion/articulos/variantes")
      })
      .catch(e => notice("Error al guardar la variante"))
      .finally(() => setActionDisable(false))
    }
  }

  const handleResults = () => {
    if (variant.id) {
      history.push(`/inventario-bienes-almacen-general/configuracion/articulos/variantes/resultados/${variant.id}`)
    } else if (selectedVariants[0]) {
      getSingleVariant(selectedVariants[0])
      .then(res => {
        dispatch(setVariant((res)))
        history.push(`/inventario-bienes-almacen-general/configuracion/articulos/variantes/resultados/${res.id}`)
      })
      .catch(console.log)
    } else {
      notice("No se encontraron registros guardados", "info")
      onNewRegister()
    }
  }

  const onNewRegister = () => {
    history.push("/inventario-bienes-almacen-general/configuracion/articulos/variantes/nuevo")
    if (variant.id) dispatch(setVariant((defaultVariant)));
  };

  return (
    <>
     <ModeTabs onNew={onNewRegister} onResults={handleResults} showIcons={{search: false, results: true, newIcon: true}} />
      <EntryActionIcons
        showIcons={{
          save: !variant.id,
          edit: variant.id,
        }}
        handleSubmit={() => handleSubmit(createVariant)}
        handleEdit={() => handleSubmit(updateVariant)}
        disabledIcons={{
          save: actionDisable,
          edit: actionDisable,
        }}
      /> 
    </>
  )
}

export default ConfigVariantActions
