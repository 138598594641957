import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import {
  Link,
  Typography,
  Breadcrumbs,
  Paper,
  Grid,
  makeStyles,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import FileFolderElement from "../../components/FileFolderElement";
import BaseModal from "../../components/BaseModal";
import SimpleInput from "../../components/SimpleInput";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { downLoaderWithURL } from "../../utils/downloaderWithURL";
import { useHistory, useLocation } from "react-router-dom";
import { flattenTree } from "../../utils/objectsFunctions";
import { foldersListColumns, GRID_VIEW, ONDRAG_BG_COLOR } from "../../utils/digitalVault";
import { useDispatch, useSelector } from "react-redux";
import FullTable from "../../components/FullTable";
import {
  addDigitalVaultFiles,
  fetchDigitalVaultFolders,
  fetchDigitalVaultFolder,
  moveToFolder,
  getDigitalVaultFiles,
  renameFoldersFile,
} from "../../crud/digitalVaultCrud";
import notice from "../../components/Notice";
import { setLoader } from "../../../redux/ducks/loader.duck";
import {
  setAllDigitalVaultFolders,
  clearVisitedFolders,
  setDigitalVaultFolders,
  setVisitFolders,
  setSelectedFoldersIds,
  setCurrentDigitalVaultId,
  setSearchMode,
} from "../../../redux/ducks/digitalVault.duck";
import { setOperativeUser } from "../../../redux/ducks/users.duck";
import { getOperativeUsersCatalogs } from "../../crud/catalogsCrud";
import DropzoneWrapper from "../../components/DropzoneWrapper";
import DigitalVaultMenuContext from "./components/DigitalVaultMenuContext";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import MoveToFolderCollapse from "./components/MoveToFolderCollapse";
import DigitalVaultDeleteFileModal from "./components/DigitalVaultDeleteFileModal";
import SplashLoader from "./components/common/SplashLoader";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    padding: `${theme.spacing(3)}px 2px`,
  },
}));

const DigitalVaultPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const {
    foldersViewType,
    digitalVaultFolders,
    allDigitalVaultFolders,
    selectedFoldersIds,
    currentDigitalVaultId,
    searchedFiles,
    visitFolders,
    searchMode,
  } = useSelector((store) => store.digitalVault);
  const classes = useStyles();
  const filesToShow = searchMode ? searchedFiles : digitalVaultFolders;
  const [isSubfolder, setIsSubfolder] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [openModelRename, setopenModelRename] = useState(false);
  const [renameText, setRenameText] = useState("");
  const cacheFileFolder = useRef({});
  const pathIds = useMemo(
    () =>
      pathname
        .replace(/.+?(?=carpetas)/, "")
        .replace("carpetas/", "")
        .replace(/^(\/)|(\/)$/, "")
        .split("/"),
    [pathname]
  );
  // Calculates breadcrumbs by folders pathname, if is not correct, redirects to root digital vault route
  const breadcrumbs = useMemo(() => {
    const memoBreadcrumbs = visitFolders.map((folder) => {
      return { id: folder.id, name: folder.name };
    });
    return memoBreadcrumbs;
  }, [visitFolders]);

  const fixId = (cbId) =>
    typeof cbId === "string" && cbId?.includes("_")
      ? Number(cbId.split("_")[cbId.split("_")?.length - 1])
      : Number(cbId);

  // Fetchs op users necessaries for new folder modal
  const fetchOperativeUsers = useCallback(async () => {
    getOperativeUsersCatalogs()
      .then((res) => dispatch(setOperativeUser(res)))
      .catch();
  }, [dispatch]);

  // Fetch a specific folder
  const fetchSubFolder = (folder) => {
    dispatch(setLoader(true));
    return fetchDigitalVaultFolder(folder)
      .then((res) => {
        dispatch(
          setVisitFolders([{ ...res, id_resource: res.id, id: `folder_${res.id}` }])
        );
        dispatch(setDigitalVaultFolders([...res.subfolders, ...res.files]));
        dispatch(setCurrentDigitalVaultId(res.id));
      })
      .catch((e) => {
        e.hasOwnProperty("detail")
          ? notice("Carpeta no encontrada", "info")
          : notice("Ha ocurrido un error al obtener la carpeta");
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  // Fetchs op users necessaries for new folder modal
  const fetchDigitalFolders = useCallback(async () => {
    dispatch(setLoader(true));
    return fetchDigitalVaultFolders()
      .then((res) => dispatch(setAllDigitalVaultFolders(res)))
      .catch((e) => notice("Ha ocurrido un error al obtener la carpeta"))
      .finally(() => dispatch(setLoader(false)));
  }, [dispatch]);

  useEffect(() => {
    fetchOperativeUsers();
  }, [fetchOperativeUsers]);

  useEffect(() => {
    dispatch(clearVisitedFolders());
    fetchDigitalFolders();
  }, [dispatch, fetchDigitalFolders]);

  // Open last pathname folder
  useEffect(() => {
    if (!isSubfolder) {
      const flattenFolders = flattenTree(allDigitalVaultFolders, "subfolders");
      const lastFolder = flattenFolders.find(
        ({ id }) => id.toString() === pathIds[pathIds.length - 1]
      );
      if (lastFolder) {
        dispatch(setDigitalVaultFolders([...lastFolder.subfolders, ...lastFolder.files]));
        dispatch(setCurrentDigitalVaultId(lastFolder.id));
      } else {
        dispatch(setDigitalVaultFolders(allDigitalVaultFolders));
        // TODO: Change root folder for setting crumbreads
        history.push("/boveda-digital/carpetas");
      }
    }
  }, [allDigitalVaultFolders, pathIds, dispatch, setIsSubfolder, isSubfolder]);

  const handleFolder = (folder) => {
    const targetFolderId = fixId(folder?.id);
    if (isDragging) return;
    if (folder.attachment) {
      getDigitalVaultFiles(folder.id_resource).then((res) => {
        downLoaderWithURL(res.attachment.url);
      });
    } // cargadas por default
    else if (folder?.subfolders && folder?.files) {
      dispatch(setVisitFolders([folder]));
      dispatch(setDigitalVaultFolders([...folder.subfolders, ...folder.files]));
      dispatch(setCurrentDigitalVaultId(targetFolderId));
      history.push(`${pathname}/${targetFolderId}`);
    } // De estos modos validamos si es sub carpeta
    else if (!folder.hasOwnProperty("attachment")) {
      setIsSubfolder(true);
      fetchSubFolder(targetFolderId);
      history.push(`${pathname}/${targetFolderId}`);
    }
  };

  const handleBreadcrumb = (breadcrumbId, parentId) => {
    const redirectToBreadcrumb = () => {
      const id = breadcrumbId.replace("folder_", "");
      const pathnameSplit = pathname.split(`/${id}/`);
      history.push(`${pathnameSplit[0]}/${id}`);
    };
    if (!breadcrumbId) {
      dispatch(setSearchMode(false));
      dispatch(clearVisitedFolders());
      dispatch(setDigitalVaultFolders(allDigitalVaultFolders));
      dispatch(setCurrentDigitalVaultId(null));
      dispatch(setSelectedFoldersIds([]));
      history.push("/boveda-digital/carpetas");
      return;
    } else if (!parentId) {
      const id = breadcrumbId.replace("folder_", "");
      redirectToBreadcrumb();
      fetchSubFolder(id);
      return;
    }
    const flattenFolders = flattenTree(allDigitalVaultFolders, "subfolders");
    const newSelectedFolder = flattenFolders.find(({ id }) => id === breadcrumbId);
    if (!!newSelectedFolder) {
      redirectToBreadcrumb();
      dispatch(setDigitalVaultFolders(newSelectedFolder.subfolders));
      dispatch(setCurrentDigitalVaultId(newSelectedFolder.id));
    }
  };

  const handleSelectRow = (folder, selected) =>
    dispatch(
      setSelectedFoldersIds(
        selected
          ? [...selectedFoldersIds, folder?.id]
          : selectedFoldersIds.filter((id) => id !== folder?.id)
      )
    );

  const handleSelectAllRows = (selected, folders) =>
    dispatch(setSelectedFoldersIds(selected ? folders.map(({ id }) => id) : []));
  const [loading, setLoading] = useState(false);

  const [filesUploaded, setFilesUploaded] = useState(0);
  const [filesTotal, setFilesTotal] = useState(0);

  const heandleFilesDrop = async (acceptedFiles) => {
    if (!acceptedFiles.length) return notice("Ningún archivo encontrado", "info");
    setFilesTotal(acceptedFiles.length);
    const files = [];
    for (const file of acceptedFiles) {
      try {
        setLoading(true);
        setFilesUploaded((filesUploaded) => filesUploaded + 1);
        const res = await addDigitalVaultFiles(file, currentDigitalVaultId);
        files.push(res[0]);
      } catch {
        notice("Los archivos no se pudieron subir correctamente");
      }
    }
    const newFiles = files.map((file) => ({
      ...file,
      name: file.title,
      attachment: file.id,
    }));
    dispatch(setDigitalVaultFolders([...digitalVaultFolders, ...newFiles]));
    setFilesTotal(0);
    setFilesUploaded(0);
    setLoading(false);

    fetchDigitalFolders();
  };

  const handleCacheFolder = ({ id, attachment }) => {
    cacheFileFolder.current = {
      id,
      type: !attachment?.id ? "folders" : "file",
    };
  };

  const nonSelectableRows = useMemo(() => {
    let values = [];
    if (digitalVaultFolders) {
      for (let i = 0; i < digitalVaultFolders.length; i++) {
        if (
          (digitalVaultFolders[i]?.subfolders && digitalVaultFolders[i]?.files) ||
          !digitalVaultFolders[i].hasOwnProperty("attachment")
        ) {
          values = [...values, digitalVaultFolders[i]?.id];
        }
      }
      return values;
    }
  }, [digitalVaultFolders]);

  const handleMoveToFolder = async (sourceFolder, targetFolder, cbEnd = () => {}) => {
    const targetFolderName =
      filesToShow.filter((x) => x.id === sourceFolder.id).length === 0
        ? ""
        : filesToShow.filter((x) => x.id === sourceFolder.id)[0].name;
    const targetFolderId = fixId(targetFolder?.id);
    const sourceFolderId = fixId(sourceFolder?.id);
    // If there is the same folder, then stop moving action
    if (targetFolderId === sourceFolderId) {
      return;
    }

    //validate files and folders by name
    if (targetFolderName !== "") {
      const isFileEqualsName = targetFolder?.files?.some(
        (file) => file.name === targetFolderName
      );

      const isFolderEqualsName = targetFolder?.subfolders?.some(
        (file) => file.name === targetFolderName
      );

      if (isFileEqualsName || isFolderEqualsName) {
        notice("Existe un archivo con el mismo nombre.");
        return;
      }
    }

    const isFileInsideSameFolder = targetFolder?.files?.some(
      (file) => fixId(file.id) === sourceFolderId
    );
    const isFolderInsideSameFolder = targetFolder?.subfolders?.some(
      (file) => fixId(file.id) === sourceFolderId
    );
    if (isFileInsideSameFolder || isFolderInsideSameFolder) {
      return;
    }
    try {
      dispatch(setLoader(true));
      const res = await moveToFolder(sourceFolderId, targetFolderId, sourceFolder?.id);
      if (res.status === 200) {
        const movedFile = await res.json();
        const newAllDigitalVaultFolders = allDigitalVaultFolders.map(
          (digitalVaultFolder) => {
            const digitalVaultFolderId = fixId(digitalVaultFolder?.id);

            const type = sourceFolder?.type === "file" ? "files" : "subfolders";
            // If is the folder to move then moves folder there
            if (fixId(digitalVaultFolderId) === targetFolderId) {
              return {
                ...digitalVaultFolder,
                [type]: [...digitalVaultFolder[type], movedFile],
              };
              // If is the current folder then removes folder in current one
            } else if (digitalVaultFolderId === fixId(currentDigitalVaultId)) {
              return {
                ...digitalVaultFolder,
                [type]: digitalVaultFolder[type].filter((file) => {
                  return fixId(file?.id) !== fixId(movedFile?.id);
                }),
              };
            } else {
              return digitalVaultFolder;
            }
          }
        );
        const newDigitalVaultFolders = digitalVaultFolders.filter(
          (file) => fixId(file?.id) !== sourceFolderId
        );
        setTimeout(() => {
          dispatch(setDigitalVaultFolders(newDigitalVaultFolders));
        }, 1);
        dispatch(setAllDigitalVaultFolders(newAllDigitalVaultFolders));
      }
      cbEnd();
    } catch (e) {
      console.log("e", e);
      notice("Ocurrió un error, intente más tarde.");
    } finally {
      dispatch(setLoader(false));
      fetchDigitalFolders();
    }
  };

  const onDragStart = () => {
    setIsDragging(true);
  };
  const onDragStop = (e, folder) => {
    setIsDragging(false);
    handleMoveToFolder(
      {
        id: folder?.id,
        type: !folder?.attachment?.id ? "folders" : "file",
      },
      cacheFileFolder?.current
    );
  };

  const initialState = {
    mouseX: null,
    mouseY: null,
  };

  const [state, setState] = useState(initialState);
  const [currentMoveOptionFolders, setCurrentMoveOptionFolders] = useState([
    allDigitalVaultFolders,
  ]);
  const [collapse1Open, setCollapse1Open] = useState(false);
  const handleClick = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX + 20,
      mouseY: event.clientY - 4,
    });
    setCollapse1Open(false);
  };

  const handleClose = () => {
    setState(initialState);
  };

  const handleOpenMoveMenu = () => {
    setCollapse1Open(!collapse1Open);
    setState({ ...state, mouseY: 250 });
  };

  const handleOpenFolder = (subfolders) => {
    // Add subfolders to the array of folder's history
    setCurrentMoveOptionFolders((prevFolders) => [...prevFolders, subfolders]);
  };

  const handleBackFolder = () => {
    // Removes last element from folder's history
    setCurrentMoveOptionFolders((prevFolders) =>
      prevFolders.slice(0, prevFolders.length - 1)
    );
  };

  const isMainFolder = useMemo(() => currentMoveOptionFolders.length === 1, [
    currentMoveOptionFolders,
  ]);
  const collapseFolders = useMemo(() => {
    if (!currentMoveOptionFolders.slice(-1)[0]?.length) {
      return allDigitalVaultFolders;
    }
    return currentMoveOptionFolders.slice(-1)[0];
  }, [currentMoveOptionFolders, allDigitalVaultFolders]);
  const contextMenuOpen = state.mouseY !== null;
  const onClickFolderMenuContext = (folder, handleClose) =>
    handleMoveToFolder(cacheFileFolder?.current, folder, handleClose);

  const [attachmentSelectedMenu, setAttachmentSelectedMenu] = useState({});
  const [isDeleteFileMenu, setIsDeleteFileMenu] = useState(false);

  const deleteFileFromVault = () => {
    setAttachmentSelectedMenu(cacheFileFolder.current.id);
    setIsDeleteFileMenu(true);
  };

  return (
    <>
      {loading && <SplashLoader total={filesTotal} subiendo={filesUploaded} />}

      <DropzoneWrapper onFilesDrop={heandleFilesDrop}>
        {(isDragActive) => (
          <Paper
            className={`h-100 p-5 overflow-auto w-100 ${
              isDragActive && foldersViewType === GRID_VIEW ? ONDRAG_BG_COLOR : ""
            }`}
          >
            <Breadcrumbs
              className={classes.breadcrumbs}
              maxItems={6}
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="inherit" onClick={() => handleBreadcrumb()}>
                Bóveda Digital
              </Link>
              {breadcrumbs?.length &&
                breadcrumbs.slice(0, breadcrumbs.length - 1).map((breadcrumb) => (
                  <Link
                    key={breadcrumb.id}
                    color="inherit"
                    onClick={() => handleBreadcrumb(breadcrumb.id, breadcrumb.parent)}
                  >
                    {breadcrumb.name}
                  </Link>
                ))}
              {breadcrumbs?.length && (
                <Typography color="textPrimary">
                  {breadcrumbs[breadcrumbs.length - 1].name}
                </Typography>
              )}
            </Breadcrumbs>
            <Grid container>
              {foldersViewType === GRID_VIEW ? (
                filesToShow.map((folder, index) => (
                  <DigitalVaultMenuContext
                    key={folder?.id}
                    fileFolderId={cacheFileFolder?.current?.id}
                    fileFolderType={cacheFileFolder?.current?.type}
                    folders={allDigitalVaultFolders}
                    onClickFolderMenuContext={(folder, handleClose) =>
                      handleMoveToFolder(cacheFileFolder?.current, folder, handleClose)
                    }
                  >
                    <FileFolderElement
                      cacheId={cacheFileFolder?.current?.id}
                      isDragging={isDragging}
                      key={index}
                      folder={folder}
                      onClick={handleFolder}
                      onMouseEnter={(e) => {
                        handleCacheFolder(folder);
                      }}
                      onDragStart={onDragStart}
                      onDragStop={onDragStop}
                    />
                  </DigitalVaultMenuContext>
                ))
              ) : (
                <Grid item className="w-100">
                  <FullTable
                    has_button={false}
                    entities={filesToShow}
                    columns={foldersListColumns}
                    page={1}
                    search_button={false}
                    onSelectRow={handleSelectRow}
                    onSelectAllRows={handleSelectAllRows}
                    hide_pagination={true}
                    rowEvents={{
                      onClick: (e, row) => handleFolder(row),
                      onContextMenu: (e, row) => handleClick(e, row),

                      onMouseEnter: (e, row) => {
                        handleCacheFolder(row);
                      },
                    }}
                    nonSelectableRows={nonSelectableRows}
                    selectedRows={selectedFoldersIds}
                    containerClassName={isDragActive ? ONDRAG_BG_COLOR : ""}
                  />
                  <Menu
                    keepMounted
                    open={contextMenuOpen}
                    onClose={handleClose}
                    anchorReference={
                      state.mouseY !== null && state.mouseX !== null
                        ? "anchorPosition"
                        : "none"
                    }
                    anchorPosition={
                      state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : { top: null, left: null }
                    }
                    className="d-flex flex-row"
                  >
                    <span className="d-flex">
                      <MenuList>
                        <ListItem button onClick={() => setopenModelRename(true)}>
                          <MenuItem
                            className="d-flex justify-content-between"
                            onClick={() => setopenModelRename(true)}
                          >
                            <Typography variant="inherit">Renombrar</Typography>
                          </MenuItem>
                        </ListItem>
                        <ListItem
                          button
                          onClick={(e) => {
                            deleteFileFromVault();
                            handleClose();
                          }}
                        >
                          <MenuItem className="d-flex justify-content-between">
                            <Typography variant="inherit">Eliminar</Typography>
                          </MenuItem>
                        </ListItem>
                        <ListItem button onClick={() => handleOpenMoveMenu()}>
                          <MenuItem className="d-flex justify-content-between">
                            <Typography variant="inherit">Mover a</Typography>
                            <ListItemIcon>
                              <ArrowRightIcon
                                fontSize="small"
                                className="ml-auto mt-auto mb-auto mr-0"
                              />
                            </ListItemIcon>
                          </MenuItem>
                        </ListItem>
                      </MenuList>
                      <MoveToFolderCollapse
                        in={collapse1Open}
                        folders={collapseFolders}
                        onClickFolder={(folder) =>
                          onClickFolderMenuContext(folder, handleClose)
                        }
                        onClickIcon={handleOpenFolder}
                        withArrow
                        isMainFolder={isMainFolder}
                        onClickBack={handleBackFolder}
                      />
                    </span>
                  </Menu>
                </Grid>
              )}
            </Grid>
          </Paper>
        )}
      </DropzoneWrapper>
      <DigitalVaultDeleteFileModal
        file={attachmentSelectedMenu}
        open={isDeleteFileMenu}
        onClose={() => setIsDeleteFileMenu(false)}
        // fetchDigitalFolders={fetchDigitalFolders}
      />
      <BaseModal
        title={<span>Capture el nombre</span>}
        open={openModelRename}
        onClose={() => {
          setopenModelRename(false);
          setRenameText("");
          handleClose();
        }}
        onConfirm={async () => {
          const _id = fixId(cacheFileFolder.current.id);
          const filesToShow_filter = filesToShow.filter(
            (item) => item.id === cacheFileFolder.current.id
          );

          if (filesToShow_filter === null) {
            return;
          }

          if (filesToShow_filter.length === 0) {
            return;
          }

          //Validate Unic name
          const filesToShow_for_Name = filesToShow.filter(
            (item) => item.name.toUpperCase() === renameText.toUpperCase()
          );

          if (filesToShow_for_Name.length > 0) {
            notice("Existe un archivo con el mismo nombre.");
            return;
          }

          const isFile = filesToShow_filter[0].hasOwnProperty("attachment");

          try {
            dispatch(setLoader(true));
            const res = await renameFoldersFile(_id, isFile, renameText);
            if (res.status === 200) {
              fetchDigitalFolders();
              setopenModelRename(false);
              setRenameText("");
              handleClose();
            }
          } catch (e) {
            console.log("e", e);
            notice("Ocurrió un error, intente más tarde.");
          } finally {
            dispatch(setLoader(false));
          }
        }}
        confirmLabel="Guardar"
        onCancel={() => {
          setopenModelRename(false);
          setRenameText("");
          handleClose();
        }}
        cancelLabel="Cerrar"
        maxWidth="sm"
        color="secondary"
        disableOnConfirm={renameText.replace(" ", "").length === 0 ? true : false}
      >
        <div style={{ width: "100%" }}>
          <SimpleInput
            value={renameText}
            onChange={(e) => {
              setRenameText(e.target.value);
            }}
            width="100%"
            showLowerCase={true}
          />
        </div>
      </BaseModal>
    </>
  );
};

export default DigitalVaultPage;
