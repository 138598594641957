import React from "react";
import * as columnFormatters from "../components/column-formatters";
import { getAreas, getArtworksByInv } from "../crud/catalogsCrud";
import { getInventory } from "../crud/inventoryCrud";
import { getFormat } from "../crud/inventoryCrud";
import { setFormatDate } from "./dates";

export const filterInputs = [
  {
    label: "Área",
    name: "area",
    type: "search",
    placeholder: "Busqueda por clave de area",
    searchFunction: getAreas,
    width: 600,
  },
];

export const columnsSurvey = (onEye, page, suma, Permisos) => [
  {
    dataField: "id",
    sort: true,
    text: "consecutivo",
    formatter: (col, row, index) => {
      const numero = index + 1;
      const indexFinal = numero + suma;
      if (page === 1) {
        return numero;
      } else if (page === 2) {
        return numero + 20;
      } else {
        return indexFinal - 20;
      }
    },
  },
  {
    dataField: "num_area",
    text: "Clave de área",
    sort: true,
  },
  {
    dataField: "nom_area",
    text: "Área",
    sort: true,
  },
  {
    dataField: "nom_sarea",
    text: "Subarea",
    sort: true,
  },
  {
    dataField: "ubication",
    text: "Edificio",
    sort: true,
    formatter: (col) => col?.edificio,
  },
  {
    dataField: "ubication",
    text: "Piso",
    sort: true,
    formatter: (col) => col?.piso,
  },
  {
    dataField: "inventory_count",
    text: "Número de bienes",
    sort: true,
  },
  {
    dataField: "last_inventory_take_date",
    text: "Fecha de último inventario",
    sort: true,
    formatter: (col) =>
      col ? (
        setFormatDate(col, "PPP")
      ) : (
        <span style={{ color: "red" }}>Sin Registro</span>
      ),
  },
  {
    dataField: "inventory_take_total_count",
    text: "Levantamiento de inventario",
    sort: true,
  },
  {
    dataField: "last_inventory_take",
    text: "Acciones",
    formatter: columnFormatters.StockSurveyActionsColumnFormatter,
    formatExtraData: {
      onEye,
      Permisos,
    },
  },
];

export const initialInventoryValues = {
  last_inventory_take_date: "",
  inventory_take_total_count: "",
  inventory_take_assets: [],
  areas_cve: [],
  inventory_out: [],
  artworks_out: [],
  inventory_surplus: [],
  artworks_surplus: [],
  inventory_scan: [],
  artworks_scan: [],
  inventory_reas: [],
  artworks_reas: [],
  fecha_levantamiento: "",
};

export const formInputs = (id, options, mode) => [
  !id
    ? {
        label: "Área",
        name: "area",
        type: "search",
        searchFunction: getAreas,
        width: 1025,
        disabled: !mode,
      }
    : {
        label: "Ultimo Inventario",
        name: "fecha_levantamiento",
        type: "dropdown",
        disabled: !id,
        options,
      },
  id && {
    label: "Usuario que registro",
    name: "usuario",
    type: "text",
    disabled: true,
  },
];

export const material_columns = (disabled, mode) => [
  {
    text: "No. Inventario",
    type: "text",
    value: "item_id",
    disabled: true,
    width: 150,
    hasLink: true,
  },
  {
    text: "Inventario",
    value: "inventory_name",
    type: "text",
    width: 300,
    disabled: true,
  },
  {
    text: "Tipo de inventario",
    value: "survey_type",
    type: "text",
    width: 150,
    disabled: true,
  },
  {
    text: "Área del bien",
    value: "inventory_area_cve",
    type: "text",
    width: 150,
    disabled: true,
  },
  disabled && {
    text: "Área a donde se cambia",
    value: "inventory_area_destination",
    type: "search",
    searchFunction: getAreas,
    width: 350,
    disabled: !mode,
  },
  {
    text: "Área donde se encontró",
    value: "inventory_area_found_cve",
    type: "search",
    searchFunction: getAreas,
    width: 350,
    disabled: !mode,
  },
  {
    text: "Bien encontrado",
    value: "status",
    type: "checkbox",
    width: 200,
    disabled: !mode,
  },
  {
    text: "Bien no localizado",
    value: "status2",
    type: "checkbox",
    width: 200,
    disabled: !mode,
  },
];

export const defaultMaterial = {
  inventory: "",
  inventory_name: "",
  inventory_area_cve: "",
  inventory_area_destination: "",
  inventory_area_found_cve: "",
  status: 3,
  type: "Escaneado",
};

export const defaultArtwork = {
  artwork: "",
  inventory_name: "",
  inventory_area_cve: "",
  inventory_area_destination: "",
  inventory_area_found_cve: "",
  status: 3,
  type: "Escaneado",
};

export const material_columns2 = (
  disabled,
  mode,
  isType,
  isSurveyPlus = false
) => [
  {
    text: "No. Inventario",
    value: "inventory",
    width: 150,
    type: "search",
    searchFunction: getInventory,
    disabled: !mode,
    // hasLink: !mode,
  },
  {
    text: "Nombre del artículo",
    value: "inventory_name",
    width: 300,
    type: "text",
    disabled: true,
  },
  {
    text: isSurveyPlus ? "Área anterior" : "Área del bien",
    value: "inventory_area_cve",
    type: "text",
    width: 150,
    disabled: true,
  },
  {
    text: "Área a donde se cambia",
    value: "inventory_area_destination",
    type: "search",
    searchFunction: getAreas,
    width: 350,
    disabled: !mode,
  },
  {
    text: "Área donde se encontró",
    value: "inventory_area_found_cve",
    type: "search",
    searchFunction: getAreas,
    width: 200,
    disabled: !mode,
  },
  {
    text: "Acciones",
    type: "icon",
    value: "delete",
    disabled: !mode,
  },
  isType && {
    text: "Tipo",
    type: "text",
    value: "type",
    width: 130,
    disabled: true,
  },
];

export const material_columns3 = (disabled, mode, isType = false) => [
  {
    text: "No. Inventario",
    value: "artwork",
    width: 150,
    type: "search",
    searchFunction: getArtworksByInv,
    // hasLink: !mode,
    disabled: !mode,
  },
  {
    text: "Titulo",
    value: "inventory_name",
    width: 300,
    type: "text",
    disabled: true,
  },
  {
    text: "Área del bien",
    value: "inventory_area_cve",
    type: "text",
    width: 150,
    disabled: true,
  },
  {
    text: "Área a donde se cambia",
    value: "inventory_area_destination",
    type: "search",
    searchFunction: getAreas,
    width: 350,
    disabled: !mode,
  },
  {
    text: "Área donde se encontró",
    value: "inventory_area_found_cve",
    type: "search",
    searchFunction: getAreas,
    width: 200,
    disabled: !mode,
  },
  {
    text: "Acciones",
    type: "icon",
    value: "delete",
    disabled: !mode,
  },
  isType && {
    text: "Tipo",
    type: "text",
    value: "type",
    width: 130,
    disabled: true,
  },
];

export const payloadRequest = (form) => ({
  areas: form.areas_cve,
  inventory_take_assets: form.inventory_take_assets
    .filter(({ status, status2 }) => status || status2)
    .map(
      ({
        item_id,
        status,
        status2,
        survey_type,
        inventory_area_destination,
        inventory_area_found_cve,
      }) =>
        survey_type.includes("Articulo")
          ? {
              inventory: item_id,
              asset_type: "inventory",
              inventory_area_destination,
              area_asset_found: status ? inventory_area_found_cve : "",
              status: status ? 1 : status2 ? 2 : "",
            }
          : {
              artwork: item_id,
              asset_type: "artwork",
              inventory_area_destination,
              area_asset_found: status ? inventory_area_found_cve : "",
              status: status ? 1 : status2 ? 2 : "",
            }
    )
    .concat(
      form.inventory_reas.map(
        ({
          inventory,
          inventory_area_destination,
          status,
          inventory_area_found_cve,
        }) => ({
          inventory,
          inventory_area_destination,
          status,
          asset_type: "inventory",
          area_asset_found: inventory_area_found_cve,
        })
      )
    )
    .concat(
      form.artworks_reas.map(
        ({
          artwork,
          inventory_area_destination,
          status,
          inventory_area_found_cve,
        }) => ({
          artwork,
          inventory_area_destination,
          status,
          asset_type: "artwork",
          area_asset_found: inventory_area_found_cve,
        })
      )
    ),
});

export const editRequest = (form) => ({
  inventory_take_assets: form.inventory_take_assets
    .filter(({ status, status2 }) => status || status2)
    .map(
      ({
        id,
        item_id,
        status,
        status2,
        survey_type,
        inventory_area_found_cve,
        inventory_area_destination,
      }) =>
        survey_type.includes("Articulo")
          ? {
              id,
              inventory: item_id,
              asset_type: "inventory",
              inventory_area_destination: status2
                ? ""
                : inventory_area_destination,
              area_asset_found: status2 ? "" : inventory_area_found_cve,
              status: status ? 1 : status2 ? 2 : "",
            }
          : {
              artwork: item_id,
              asset_type: "artwork",
              inventory_area_destination: status2
                ? ""
                : inventory_area_destination,
              area_asset_found: status2 ? "" : inventory_area_found_cve,
              status: status ? 1 : status2 ? 2 : "",
            }
    )
    .concat(
      form.inventory_out.map(
        ({
          id,
          inventory,
          inventory_area_destination,
          status,
          inventory_area_found_cve,
        }) => ({
          id,
          inventory,
          inventory_area_destination,
          status,
          asset_type: "inventory",
          area_asset_found: inventory_area_found_cve,
        })
      )
    )
    .concat(
      form.artworks_out.map(
        ({
          id,
          artwork,
          inventory_area_destination,
          status,
          inventory_area_found_cve,
        }) => ({
          id,
          artwork,
          inventory_area_destination,
          status,
          asset_type: "artwork",
          area_asset_found: inventory_area_found_cve,
        })
      )
    )
    .concat(
      form.inventory_surplus.map(
        ({
          id,
          inventory,
          inventory_area_destination,
          status,
          inventory_area_found_cve,
        }) => ({
          id,
          inventory,
          inventory_area_destination,
          status,
          asset_type: "inventory",
          area_asset_found: inventory_area_found_cve,
        })
      )
    )
    .concat(
      form.artworks_surplus.map(
        ({
          id,
          artwork,
          inventory_area_destination,
          status,
          inventory_area_found_cve,
        }) => ({
          id,
          artwork,
          inventory_area_destination,
          status,
          asset_type: "artwork",
          area_asset_found: inventory_area_found_cve,
        })
      )
    )
    .concat(
      form.inventory_scan.map(
        ({
          id,
          inventory,
          inventory_area_destination,
          status,
          inventory_area_found_cve,
        }) => ({
          id,
          inventory,
          inventory_area_destination,
          status,
          asset_type: "inventory",
          area_asset_found: inventory_area_found_cve,
        })
      )
    )
    .concat(
      form.artworks_scan.map(
        ({
          id,
          artwork,
          inventory_area_destination,
          status,
          inventory_area_found_cve,
        }) => ({
          id,
          artwork,
          inventory_area_destination,
          status,
          asset_type: "artwork",
          area_asset_found: inventory_area_found_cve,
        })
      )
    )
    .concat(
      form.inventory_reas.map(
        ({
          inventory,
          inventory_area_destination,
          status,
          inventory_area_found_cve,
        }) => ({
          inventory,
          inventory_area_destination,
          status,
          asset_type: "inventory",
          area_asset_found: inventory_area_found_cve,
        })
      )
    )
    .concat(
      form.artworks_reas.map(
        ({
          artwork,
          inventory_area_destination,
          status,
          inventory_area_found_cve,
        }) => ({
          artwork,
          inventory_area_destination,
          status,
          asset_type: "artwork",
          area_asset_found: inventory_area_found_cve,
        })
      )
    ),
});

export const clear_inventory_take_assets = (data) => {
  const { inventories, artworks } = data;
  const stockSurvey = inventories.map(({ id, cf_articulo, cf_cve_area }) => ({
    id,
    item_id: id,
    inventory_name: cf_articulo?.trim(),
    inventory_area_cve: cf_cve_area?.trim(),
    inventory_area_destination: "",
    inventory_area_found_cve: "",
    survey_type: "Articulo",
    status: false,
    status2: false,
  }));

  const artSurvey = artworks.map(({ id, cf_titulo, cf_num_area }) => ({
    id,
    item_id: id,
    inventory_name: cf_titulo?.trim(),
    inventory_area_cve: cf_num_area?.trim(),
    inventory_area_destination: "",
    inventory_area_found_cve: "",
    survey_type: "Obra de arte",
    status: false,
    status2: false,
  }));
  return stockSurvey.concat(artSurvey);
};

export const payloadResponse = (form) => ({
  ...form,
  inventory_take_area_related: form.inventory_take_area_related.map(
    ({ id, fecha_hora_levantamiento }) => ({
      id,
      value: id,
      text: fecha_hora_levantamiento,
    })
  ),
  fecha_levantamiento: form.id,
  inventory_take_assets: form.inventory_take_assets // articulos con status 1
    .filter((item) => item.status !== 3)
    .map(
      ({
        id,
        inventory,
        artwork,
        inventory_area_cve,
        inventory_area_destination_cve,
        status,
        asset_type,
        inventory_name,
        inventory_area_found_cve,
      }) => ({
        id,
        item_id: inventory || artwork,
        inventory_name,
        inventory_area_cve,
        inventory_area_destination: inventory_area_destination_cve,
        inventory_area_found_cve,
        survey_type: asset_type === "inventory" ? "Articulo" : "Obra de arte",
        status: Boolean(status === 1),
        status2: Boolean(status === 2),
      })
    ),
  inventory_reas: [],
  artworks_reas: [],
  // ARTICULOS FUERA DE LUGAR  => area_found : not null, area_destination:  null
  inventory_out: form.inventory_take_assets
    .filter(
      (item) =>
        item.status === 3 &&
        item.asset_type === "inventory" &&
        item.inventory_area_found_cve &&
        !item.inventory_area_destination_cve
    )
    .map((item) => ({
      ...item,
      inventory_area_destination: item.inventory_area_destination_cve,
    })),
  artworks_out: form.inventory_take_assets
    .filter(
      (item) =>
        item.status === 3 &&
        item.asset_type !== "inventory" &&
        item.inventory_area_found_cve &&
        !item.inventory_area_destination_cve
    )
    .map((item) => ({
      ...item,
      inventory_area_destination: item.inventory_area_destination_cve,
    })),

  // ARTICLOS SOBRANTES  => inventory_area_found_cve : null, inventory_area_destination_cve:  not null || inventory_area_found_cve : not null, inventory_area_destination_cve: not null
  inventory_surplus: form.inventory_take_assets
    .filter(
      (item) =>
        item.status === 3 &&
        item.asset_type === "inventory" &&
        item.inventory_area_destination_cve
    )
    .map((item) => ({
      ...item,
      inventory_area_destination: item.inventory_area_destination_cve,
    })),
  artworks_surplus: form.inventory_take_assets
    .filter(
      (item) =>
        item.status === 3 &&
        item.asset_type !== "inventory" &&
        item.inventory_area_destination_cve
    )
    .map((item) => ({
      ...item,
      inventory_area_destination: item.inventory_area_destination_cve,
    })),

  // ARTICLOS EXCANEADOS  => area_found : null, area_destination:  null
  inventory_scan: form.inventory_take_assets
    .filter(
      (item) =>
        item.status === 3 &&
        item.asset_type === "inventory" &&
        !item.inventory_area_found_cve &&
        !item.inventory_area_destination_cve
    )
    .map((item) => ({
      ...item,
      inventory_area_destination: item.inventory_area_destination_cve,
    })),
  artworks_scan: form.inventory_take_assets
    .filter(
      (item) =>
        item.status === 3 &&
        item.asset_type !== "inventory" &&
        !item.inventory_area_found_cve &&
        !item.inventory_area_destination_cve
    )
    .map((item) => ({
      ...item,
      inventory_area_destination: item.inventory_area_destination_cve,
    })),
});

export const format_buttons = (id, formats) => [
  {
    label: "Formato A",
    onClick: () => getFormat(id, "A"),
    image: "/media/svg/icons/Files/Selected-file.svg",
    disabled: !formats.A,
  },
  {
    label: "Formato B",
    onClick: () => getFormat(id, "B"),
    image: "/media/svg/icons/Files/Selected-file.svg",
    disabled: !formats.B,
  },
  {
    label: "Formato C",
    onClick: () => getFormat(id, "C"),
    image: "/media/svg/icons/Files/Selected-file.svg",
    disabled: !formats.C,
  },
  {
    label: "Formato D",
    onClick: () => getFormat(id, "D"),
    image: "/media/svg/icons/Files/Selected-file.svg",
    disabled: !formats.D,
  },
];
