import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import CardAction from "../../components/CardAction";
import BaseModal from "../../components/BaseModal";
import UtilInputs from "../../components/UtilInputs";
import notice from "../../components/Notice";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentReport,
  setModal,
  resetCurrentReport,
  handleFormChange,
  setLoading,
} from "../../../redux/ducks/reports.duck";
import { getInventoryCatalog } from "../../crud/reportsCrud";
import _ from "lodash";
import { isAValidAmount } from "../../utils/inputValidations";
import { downLoaderWithURL } from "../../utils/downloaderWithURL";
import moment from "moment";
moment.locale("es");
const ReportsPage = () => {
  const dispatch = useDispatch();

  const [dropdownOptions, setDropdownOptions] = useState([{ value: "", text: "" }]);

  const {
    availableReports,
    modalOpen,
    loading,
    formValues,
    formInputs,
    currentReport,
  } = useSelector((store) => store.reports);

  React.useEffect(() => {
    getInventoryCatalog()
      .then((res) => {
        setDropdownOptions(res);
      })
      .catch();
  }, []);

  const handleReport = (id) => {
    const reports = availableReports(dropdownOptions);
    const current = _(reports).find((report) => report.id === id);
    if (current) {
      const { formValues, formInputs, ...rest } = current;
      dispatch(setCurrentReport(formValues, formInputs, rest));
      dispatch(setModal(true));
    }
  };

  const handleCloseModal = () => {
    dispatch(resetCurrentReport());
    dispatch(setModal(false));
  };
  const [isDisable, setIsDisable] = useState(true);

  const handleChange = (e) => {
    const { target } = e;

    if (target) {
      let { name, value } = target;
      if (name === "anio")
        if (
          isAValidAmount(value) &&
          !value.includes(".") &&
          !value.includes("-") &&
          value.length < 5
        )
          dispatch(handleFormChange(name, value));
      if (name === "fecha_fin" && currentReport?.name === "Reporte de existencias") {
        const today = moment().format("YYYY/MM/DD");
        const selectedDate = moment(value, "DD/MM/YYYY").format("YYYY/MM/DD");
        setIsDisable(moment(selectedDate).isAfter(today));
      }
      dispatch(handleFormChange(name, value));
    }
  };

  const handleConfirm = async () => {
    setIsDisable(true);
    try {
      let formattedFormValues = {};
      //Change format of inputs type date, to format YYYY-MM-DD
      for (let [key, value] of Object.entries(formValues)) {
        if (value) {
          switch (key) {
            case "fecha_inicio":
            case "fecha_fin": {
              formattedFormValues = {
                ...formattedFormValues,
                [key]: value
                  .split("/")
                  .reverse()
                  .join("-"),
              };
              break;
            }
            default: {
              formattedFormValues = {
                ...formattedFormValues,
                [key]: value,
              };
            }
          }
        }
      }
      dispatch(setLoading(true));
      const results = await currentReport?.requestFunction(formattedFormValues);
      if (results?.attachment_url) downLoaderWithURL(results?.attachment_url);
    } catch (e) {
      notice(`Ocurrió un problema durante la operación${e ? `: ${e}` : ""}`);
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <Grid container spacing={3}>
      {availableReports().map((card) => (
        <Grid item key={card.id} xs={12} md={3}>
          <CardAction
            isCenterTitle={true}
            roundIcon={false}
            title={card.name}
            actionButtonTitle={"Descargar"}
            IconComponent={
              <path d="M4.85714286,1 L11.7364114,1 C12.0910962,1 12.4343066,1.12568431 12.7051108,1.35473959 L17.4686994,5.3839416 C17.8056532,5.66894833 18,6.08787823 18,6.52920201 L18,19.0833333 C18,20.8738751 17.9795521,21 16.1428571,21 L4.85714286,21 C3.02044787,21 3,20.8738751 3,19.0833333 L3,2.91666667 C3,1.12612489 3.02044787,1 4.85714286,1 Z M8,12 C7.44771525,12 7,12.4477153 7,13 C7,13.5522847 7.44771525,14 8,14 L15,14 C15.5522847,14 16,13.5522847 16,13 C16,12.4477153 15.5522847,12 15,12 L8,12 Z M8,16 C7.44771525,16 7,16.4477153 7,17 C7,17.5522847 7.44771525,18 8,18 L11,18 C11.5522847,18 12,17.5522847 12,17 C12,16.4477153 11.5522847,16 11,16 L8,16 Z"></path>
            }
            colorIcon={card.color}
            onClickActionButton={() => handleReport(card.id)}
          />
        </Grid>
      ))}

      <BaseModal
        title={currentReport?.name}
        open={modalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        confirmLabel={"Descargar"}
        onCancel={handleCloseModal}
        cancelLabel={"Cancelar"}
        color={"secondary"}
        disableOnConfirm={
          currentReport?.name === "Reporte de existencias" ? isDisable : loading
        }
      >
        <UtilInputs
          width={261}
          formValues={formValues}
          inputValues={formInputs}
          onValuesChange={handleChange}
        />
      </BaseModal>
    </Grid>
  );
};

export default ReportsPage;
