export const isAValidInteger = (value) =>
  !isNaN(Number(value)) && !value.toString().includes(".");

export const isAValidAmount = (value) => {
  let isValid = true;
  if (isNaN(Number(value))) isValid = false;
  if (value.toString().includes(".")) {
    const pastDot = value.toString().split(".")[1];
    if (pastDot.length > 2) isValid = false;
  }
  return isValid;
};

export const isAValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const isAValidURL = (string) => {
  try {
    const url = new URL(string);
    return ["http:", "https:", "ftp:"].includes(url.protocol);
  } catch {
    return false;
  }
};

export const startsWithZero = (value) => {
  if (!value && value !== 0) return false;
  if (value.toString().startsWith("0")) return true;
  else return false;
};

export const priceFormat = (value) =>
  Number(value)
    .toFixed(2)
    .toLocaleString();

export const priceToDouble = (price) => Number(price.replace(/[^0-9.]+/g, ""));
