import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultVariant } from "../../app/utils/configData"

const actionType = {
  SetVariants: '[CONFIG] SET_VARIANTS',
  SetVariant: '[CONFIG] SET_VARIANT',
  SetSelectedVariants: '[CONFIG] SET_SELECTED_VARIANT',
  ClearState: '[CONFIG] CLEAR_STATE',
};

const initialState = {
  variants: {
    results: [],
    count: 0
  },
  variant: defaultVariant,
  selectedVariants: [],
};

export const reducer = persistReducer(
  { storage, key: 'config', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetVariants:
        return { ...state, variants: action.payload }
      
      case actionType.SetVariant:
        return { ...state, variant: action.payload }
      
      case actionType.SetSelectedVariants:
        return { ...state, selectedVariants: action.payload }
      
      case actionType.ClearState:
        return state = initialState
      
      default:
        return state
    }
  }
)

export const setVariants = data => ({ type: actionType.SetVariants, payload: data })

export const setVariant = data => ({ type: actionType.SetVariant, payload: data })

export const setSelectedVariants = data => ({ type: actionType.SetSelectedVariants, payload: data })

export const setClearState = data => ({ type: actionType.ClearState, payload: data })