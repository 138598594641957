export const BASE_URL = process.env.REACT_APP_API_URL;

export const ASSTETS_STOCK = `${BASE_URL}/api/inventario-bienes-almacen-general`;
export const INSTRUMENTAL_GOODS = `${BASE_URL}/api/bienes-instrumentales`;
export const CATALOGS_URL = `${BASE_URL}/api/catalogos`;

export const DASHBOARD_IG_URL = `${INSTRUMENTAL_GOODS}/dashboard-detail`;

export const MULTIPLE_UPLOAD_FILE_URL = `${BASE_URL}/api/base/upload-multiple-file`;

export const UPLOAD_FILE_URL = `${BASE_URL}/api/base/upload-file`;
export const DOWNLOAD_FILES_URL = `${BASE_URL}/api/base/download-files`;
export const CONSULTA_QR = `${BASE_URL}/consulta-qr/inventario`;
export const CONSULTA_QR_ARTWORK = `${BASE_URL}/api/bienes-instrumentales/artwork`;
export const CONSULTA_QR_ARTWORK_POST = `${BASE_URL}/api/bienes-instrumentales/artwork`;
export const VEHICULES_URL = `${BASE_URL}/api/bienes-instrumentales/vehiculos`;
export const ARTICLES = `${BASE_URL}/api/inventario-bienes-almacen-general/articulos`;
export const PLEDGED_ARTICLES = `${BASE_URL}/api/inventario-bienes-almacen-general/articulos-comprometidos`;
export const USERS_URL = `${BASE_URL}/api/users`;
export const OPERATIVE_USERS_URL = `${BASE_URL}/api/opusers`;
export const INVENTORY_API = BASE_URL + "/api/bienes-instrumentales/inventory";
export const AQUISITIONS_API = BASE_URL + "/api/bienes-instrumentales/asset_acquisitions";
export const INVENTORYS_SURVEY_API =
  BASE_URL + "/api/bienes-instrumentales/inventory-take-areas";
export const INVENTORY_SURVEY_API =
  BASE_URL + "/api/bienes-instrumentales/inventory-take";

//TODO: This can possibly be repeated, with 'Configuration' module. This would need to be changed later, with the person responsible for the task. Actually i'll be temporary until defines then. <--
export const GROUPS_URL = `${BASE_URL}/api/groups`;
export const PERMISSIONS_URL = `${BASE_URL}/api/permissions`;
export const ENTRANCE_URL = `${ASSTETS_STOCK}/entry-stock`;
export const RECURRENT_URL = `${ASSTETS_STOCK}/entry-recurrent`;
export const ORDINARY_URL = `${ASSTETS_STOCK}/entry-ordinary`;
export const REVOLVENTE_URL = `${ASSTETS_STOCK}/entry-fondo-revolvente`;
export const GASTOS_URL = `${ASSTETS_STOCK}/entry-gastos-a-comprobar`;

export const CLEAN_URL = `${ASSTETS_STOCK}/entry-cleanning`;
export const REFUND_URL = `${ASSTETS_STOCK}/return-stock`;
export const REQUIREMENT_URL = `${ASSTETS_STOCK}/requisition`;
export const ASSETS_CONFIGURATION_URL = `${ASSTETS_STOCK}/configuration`;
export const DIGITAL_VAULT_URL = `${BASE_URL}/api/digital-vault`;
export const DELETE_DOCUMENT = `${BASE_URL}/api/base/upload-file`;
export const SIGNATURE_URL = `${ASSTETS_STOCK}/signatures`;
export const PARTIDA_URL = `${BASE_URL}/api/catalogos/partida`;

//TODO: -->

/**
 * Task Management
 */
export const TASK_MANAGEMENT = `${BASE_URL}/api/task-management`;
export const TASK_MANAGEMENT_BOARDS = `${TASK_MANAGEMENT}/boards`;
export const TASK_MANAGEMENT_TASKS = `${TASK_MANAGEMENT}/tasks`;
export const TASK_MANAGEMENT_TASKS_BY_BOARD = `${TASK_MANAGEMENT_TASKS}/board`;
export const TASK_MANAGEMENT_FOLIOS = `${TASK_MANAGEMENT}/folios`;
export const TASK_MANAGEMENT_SHIFTS = `${TASK_MANAGEMENT}/shifts`;
export const TASK_MANAGEMENT_PRIORITIES = `${TASK_MANAGEMENT}/priorities`;
export const TASK_MANAGEMENT_COMMENTS = `${TASK_MANAGEMENT}/comments`;
export const TASK_MANAGEMENT_CLEAR_TASKS = `${TASK_MANAGEMENT}/clear/tasks`;
