import axios from "axios";
import { trimObjValues } from "../utils/objectsFunctions";
import { CATALOGS_URL } from "./helpers/routes";
import uuid from "react-uuid";
import { PAGINATION_LIMIT } from "../utils/configData";

const paginateBody = (response, page) => {
  return {
    count: response.data.count,
    links: response.data.links,
    page: page,
    per_page: 1,
    total_pages: response.data.total_pages,
  };
};

export const getFilterAreasDataById = async (ids, page) => {
  return new Promise(async (resolve, reject) => {
    if (ids.length > 0) {
      const idData = await axios.get(`${CATALOGS_URL}/crud-areas/${ids[page - 1]}`);
      let res = {
        data: {
          count: ids.length,
          ids: ids,
          per_page: 1,
          results: [idData.data],
          total_pages: ids.length,
        },
        status: 200,
      };
      if (res?.status && res.status === 200) {
        const resultsData = [
          res.data.results
            .map((x) => trimObjValues(x))
            .map((option) => ({
              ...option,
              id: option?.id ?? uuid(),
              text: option.nom_area,
              responsables:
                option?.responsables &&
                option?.responsables[0]?.person &&
                option?.responsables[0]?.person?.id
                  ? option?.responsables?.map((area_) => ({
                      id: area_.person?.idpersona,
                      value: area_.person?.idpersona,
                      text: area_.person?.nombre?.trim(),
                    }))
                  : [],
            })),
          paginateBody(res, page),
          res.data.ids,
        ];
        resolve(resultsData);
      } else reject({ error: "error" });
    } else {
      reject({ error: "Error" });
    }
  });
};

//? attachements en areas
export const getAttachmentAreas = async (id) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.get(`${CATALOGS_URL}/area-attachments/${id}`);
    if (res?.status === 200) {
      resolve(res?.data?.all_attachments);
    } else {
      reject({ error: "Error" });
    }
  });
};
//?buscar por año en areas
export const searchModuleAreaYear = async (id, year) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.get(`${CATALOGS_URL}/area-attachments/${id}/?year=${year}`);
    if (res?.status === 200) {
      if (res?.data?.all_attachments) {
        resolve(res?.data?.all_attachments);
      } else {
        reject({ error: "Error" });
      }
    } else {
      reject({ error: "Error" });
    }
  });
};
//? ORIGINAL
export const getFilterAreasId = async (params, page = 1, limit = PAGINATION_LIMIT) => {
  let searchParams = {};
  params.split("&").map((areaParam) => {
    let [keyParam, valueParam] = areaParam.split("=");
    searchParams[keyParam] =
      keyParam == "custom_responsables"
        ? valueParam.split(",").map((elem) => parseInt(elem))
        : valueParam;
  });
  let filterParams = {
    search_params: searchParams,
  };
  try {
    const ids = await axios.post(`${CATALOGS_URL}/area-search`, filterParams);
    if (ids.data.length > 0) {
      const idData = await axios.get(
        `${CATALOGS_URL}/crud-areas/${ids.data[page - 1].id}`
      );
      let res = {
        data: {
          count: ids.data.length,
          ids: ids.data.map((idElment) => idElment.id),
          per_page: 1,
          results: [idData.data],
          total_pages: ids.data.length,
        },
        status: 200,
      };
      if (res?.status && res.status === 200) {
        const resultsData = [
          res.data.results
            .map((x) => trimObjValues(x))
            .map((option) => ({
              ...option,
              id: option?.id ?? uuid(),
              text: option.nom_area,
              responsables:
                option?.responsables &&
                option?.responsables[0]?.person &&
                option?.responsables[0]?.person?.id
                  ? option?.responsables?.map((area_) => ({
                      id: area_.person?.idpersona,
                      value: area_.person?.idpersona,
                      text: area_.person?.nombre?.trim(),
                    }))
                  : [],
            })),
          paginateBody(res, page),
          res.data.ids,
        ];
        return resultsData;
      } else {
        return { error: "warning" };
      }
    } else if (ids.data.length === 0 && ids.status === 200) {
      return { error: "warning" };
    } else {
      return { error: "error" };
    }
  } catch (err) {
    return { error: "error" };
  }
};

// export const getFilterAreas = (params = "", page = 1, limit = PAGINATION_LIMIT) => {

//   return new Promise(async (resolve, reject) => {
//     // console.log(params);
//     const res = await axios.get(
//       `${CATALOGS_URL}/areas-filter?${params}&page=${page}&limit=${limit}`
//     );
//     if (res?.status && res.status === 200) {
//       // resultsData.lenght = 3
//       const resultsData = [
//         res.data.results
//           .map((x) => trimObjValues(x))
//           .map((option) => ({
//             ...option,
//             //@formatter:off
//             id: option?.id ?? uuid(),
//             //@formatter:on
//             text: option.nom_area,
//             responsables:
//               option?.area_attendant &&
//               option?.area_attendant[0]?.person &&
//               option?.area_attendant[0]?.person?.id
//                 ? option?.area_attendant?.map((area_) => ({
//                     id: area_.person?.idpersona,
//                     value: area_.person?.idpersona,
//                     text: area_.person?.nombre?.trim(),
//                   }))
//                 : [],
//           })),
//         paginateBody(res, page),
//         res.data.ids,
//       ];

//       resolve(resultsData);
//     } else reject({ error: "error" });
//   });
// };

export const updateMultipleAreasFields = (body) => {
  return axios.put(`${CATALOGS_URL}/areas-multiple-edit/`, body);
};
