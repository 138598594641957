export const requestInputsFields = [
	{
		label: 'Número de solicitud',
		placeholder: 'Número de solicitud',
		name: 'id',
		type: 'text',
		disabled: true,
	},
	{
		label: 'Fecha de solicitud',
		placeholder: 'Dd/mm/aaaa',
		name: 'cf_fecha_solicitud',
		type: 'date',
	},
	{
		label: 'Área',
		placeholder: 'Área',
		name: 'area',
		type: 'text',
		disabled: true,
	},
	{
		label: 'Responsable',
		placeholder: 'Responsable',
		name: 'responsible',
		type: 'text',
		disabled: true,
		multiline: true,
		width: 300,
	},
	{
		label: 'Correo electrónico',
		placeholder: 'Correo electrónico',
		name: 'correo_electronico',
		type: 'text',
		disabled: true,
	},
	{
		label: 'Estado',
		name: 'cf_estatus',
		type: 'dropdown-chip',
		disabled: true,
		options: [
			{value: "G", text: "Abierta", color: "primary"},
			{value: "H", text: "Surtiendo", color: "info"},
			{value: "D", text: "Despachada", color: "warning"},
			{value: "T", text: "Entregada", color: "success"},
		]
	},
];

export const iconsActions = (onFile, onEmail, onCancel, onLabel, disabled) => onLabel ? 
	[{
    image: "/media/svg/icons/Files/File.svg",
    tooltip: "Ver PDF",
    onClick: onFile,
		style: { marginLeft: 20 },
		color: "success"
  },
  {
    image: "/media/svg/icons/Communication/Send.svg",
    tooltip: "Enviar mail",
    onClick: onEmail,
		style: { margin: "0 20px" },
		color: "dark",
		disabled
  },
  {
    image: "/media/svg/icons/Code/Error-circle.svg",
    tooltip: "Cancelar",
    onClick: () => onCancel(true),
		color: "danger",
		disabled
  },
  {
    image: "/media/svg/icons/Devices/CPU2.svg",
    tooltip: "Imprimir etiqueta",
    onClick: onLabel,
		color: "primary"
  }] : [{
    image: "/media/svg/icons/Files/File.svg",
    tooltip: "Ver PDF",
    onClick: onFile,
		style: { marginLeft: 20 },
		color: "success"
  },
  {
    image: "/media/svg/icons/Communication/Send.svg",
    tooltip: "Enviar mail",
    onClick: onEmail,
		style: { margin: "0 20px" },
		color: "dark",
		disabled
  },
  {
    image: "/media/svg/icons/Code/Error-circle.svg",
    tooltip: "Cancelar",
    onClick: () => onCancel(true),
		color: "danger",
		disabled
  }]