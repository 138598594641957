export const searchRecursive = (obj, key, keys=[]) => {
   let results = [];
   if(typeof obj === 'object' && obj.length === undefined){
       const item = obj[key];
       if(item){
           results.push(item);
       };
       keys.forEach(aditionalKey => {
           const aditionals = searchRecursive(obj[aditionalKey], key, keys);
           aditionals.forEach(aditional => {
               results.push(aditional);
           });
       });
   }else if(typeof obj === 'object' && obj.length !== undefined){
       obj.forEach(subObj => {
           const subObjNames = searchRecursive(subObj, key, keys);
           subObjNames.forEach(name => {
               results.push(name);
           });
       });
   }else {
       console.log('3');
   };
   return results;
};