import React from 'react'

export const ToDoBoardLeaderColumn = ( value ) => {
  return (
    <>
      <span className="label label-info label-xl mr-3 font-weight-bold">
      {value.split(" ").map(name => name[0]).join("")}
      </span>
      {value}
    </>
  )
}
