import React from 'react';
import { Tab, Tabs, darken } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const AntTabs9 = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
        marginBottom: "1rem",
    },
    flexContainer: {
        overflow: 'auto',
        "&::-webkit-scrollbar": {
            display: 'none',
        }
    },
})(Tabs);

const AntTab9 = withStyles(theme => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        background: "#e4e6ef",
        fontSize: 16,
        borderTop: '1px solid #d3d6e8',
        borderLeft: '1px solid #d3d6e8',
        borderRight: '1px solid #d3d6e8',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        fontWeight: theme?.typography?.fontWeightRegular,
        color: darken(theme?.palette?.gray?.main || '#808080', 0.4),
        opacity: 0.85,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&$selected': {
            fontWeight: 600,
            opacity: 1,
        },
        '&:hover': {
            fontWeight: 600,
            opacity: 1,
        },
    },
    selected: {},
}))(props => <Tab disableRipple {...props} />);

const AntTabs = ({ selectedValue, onChangeTab, tabs = [], color = "secondary" }) => {
    return (
        <AntTabs9 value={selectedValue} onChange={onChangeTab} indicatorColor={color} textColor="primary" TabIndicatorProps={{style: { background: tabs[0]?.color ? "white" : null, height: tabs[0]?.color ? 6 : null }}}>
            {tabs.map(tab => <AntTab9 key={tab.value} value={tab.value} label={tab.label} style={{ background: tab.color, color: tab.color && "white" }} />)}
        </AntTabs9>
    );
};

export default AntTabs;
