import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setEntrance,
  setSearchEntrance,
  setSelectedIds,
} from "../../../redux/ducks/entrance.duck";
import { setLoader } from "../../../redux/ducks/loader.duck";
import BaseModal from "../../components/BaseModal";
import EntryActionIcons from "../../components/EntryActionIcons";
import ModeTabs from "../../components/ModeTabs";
import notice from "../../components/Notice";
import {
  createEntrance,
  closeEntrance,
  getEntranceList,
} from "../../crud/entrancesStockCrud";
import {
  clearRequest,
  defaultEntrance,
  defaultFilter,
  ordinaryRequest,
} from "../../utils/entranceData";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";
import { addRequirementFile } from "../../crud/requirementCrud";
import { setFiles } from "../../../redux/ducks/files.duck";
import { requestFormatDateToMMDDYYYY } from "../../utils/dates";
import moment from "moment";
moment.locale("es");

const EntranceActions = () => {
  const { files } = useSelector((store) => store.files);
  const { isNewPage, isSearchPage } = useSelector((store) => store.pathname);
  const { entrance, selectedIds, searchEnatrance } = useSelector(
    (state) => state.entrance
  );
  const { permisos } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState(false);

  const compareEntranceDate = (creationDate) => {
    const currentDate = new Date();

    if (
      creationDate.getFullYear() === currentDate.getFullYear() &&
      creationDate.getMonth() + 1 === currentDate.getMonth() + 1
    )
      return true;
    else return false;
  };

  // warehouse_entry_details
  const handleSubmit = ({ closed = false, precharge = false }) => {
    dispatch(setLoader(true));
    const payload = clearRequest({
      ...entrance,
      cf_importe_neto: entrance.cf_importe_neto
        ? Number(entrance?.cf_importe_neto?.replaceAll(",", ""))
        : null,
      cf_importe_remision: entrance.cf_importe_remision
        ? Number(entrance?.cf_importe_remision?.replaceAll(",", ""))
        : null,
      entrada_cerrada: closed,
      cf_ord_no_contrato:
        entrance.tipo_entrada === "FONDOREVOLVENTE"
          ? "Fondo revolvente"
          : entrance.tipo_entrada === "GASTOSCOMPROBAR"
          ? "Gastos a comprobar"
          : entrance.cf_ord_no_contrato,
    });
    if (payload.cf_importe_neto === null) {
      delete payload.cf_importe_neto;
    }
    if (precharge && entrance.tipo_entrada === "STOCK") {
      payload.warehouse_entry_details = [];
    }
    if (!payload.cf_fecha_entrada) {
      notice("Debes agregar al menos la fecha sello almacén");
      dispatch(setLoader(false));
      return;
    }
    if (
      !compareEntranceDate(
        new Date(requestFormatDateToMMDDYYYY(payload.cf_fecha_entrada))
      ) &&
      entrance.tipo_entrada !== "ORDINARIA" &&
      entrance.tipo_entrada !== "FONDOREVOLVENTE" &&
      entrance.tipo_entrada !== "GASTOSCOMPROBAR"
    ) {
      dispatch(setLoader(false));
      setModal(false);
      return notice(
        "Mes y año de la entrada no puede ser distinta del mes y año actual",
        "warning"
      );
    }
    createEntrance(payload)
      .then(async (res) => {
        if (res.id) {
          if (files.length > 0) {
            const uploadFile = await Promise.all(
              files.map((file) =>
                addRequirementFile(file, res.id, file.name, "warehouse_entry")
              )
            );
            if (
              uploadFile.some((response) =>
                [500, 422].includes(response?.response?.status)
              )
            )
              notice("Alguno de los los archivos no se pudo subir");
            else {
              notice("Entrada creada correctamente", "success");
              handleLocation(
                `/inventario-bienes-almacen-general/entradas/resultados/${res.id}`
              );
              dispatch(setFiles([]));
            }
          } else {
            setModal(false);
            notice("Entrada creada correctamente", "success");
            handleLocation(
              `/inventario-bienes-almacen-general/entradas/resultados/${res.id}`
            );
            // dispatch(setCurrentMode())
          }
        }
      })
      .catch((e) => {
        if (e.hasOwnProperty("error")) {
          notice(e.error, "warning");
        } else {
          notice("Error al crear, revise todos los datos de la nueva entrada");
        }
        setModal(false);
      })
      .finally(() => dispatch(setLoader(false)));
  };

  const handleSubmitStockPrecharge = ({ closed = false, precharge = false }) => {
    dispatch(setLoader(true));
    if (
      precharge &&
      entrance.tipo_entrada === "LIMPIEZA" &&
      entrance.cf_fecha_entrada.length === 0
    ) {
      delete entrance.cf_fecha_entrada;
      delete entrance.cf_importe_neto;
    }

    let payload = clearRequest({
      ...entrance,
      entrada_cerrada: closed,
    });
    if (
      precharge &&
      (entrance.tipo_entrada === "STOCK" || entrance.tipo_entrada === "RECURRENTE")
    ) {
      delete payload.cf_fecha_entrada;
      payload.warehouse_entry_details = [];
    }
    payload = {
      ...payload,
      cf_importe_remision: payload.cf_importe_remision
        ? Number(payload?.cf_importe_remision?.replaceAll(",", ""))
        : null,
    };
    console.log(payload);
    createEntrance(payload)
      .then(async (res) => {
        if (res.id) {
          if (files.length > 0) {
            const uploadFile = await Promise.all(
              files.map((file) =>
                addRequirementFile(file, res.id, file.name, "warehouse_entry")
              )
            );
            if (
              uploadFile.some((response) =>
                [500, 422].includes(response?.response?.status)
              )
            )
              notice("Alguno de los los archivos no se pudo subir");
            else {
              notice("Entrada creada correctamente", "success");
              handleLocation(
                `/inventario-bienes-almacen-general/entradas/resultados/${res.id}`
              );
              dispatch(setFiles([]));
            }
          } else {
            setModal(false);
            notice("Entrada creada correctamente", "success");
            handleLocation(
              `/inventario-bienes-almacen-general/entradas/resultados/${res.id}`
            );
            // dispatch(setCurrentMode())
          }
        }
      })
      .catch((e) => {
        if (e.hasOwnProperty("error")) {
          notice(e.error, "warning");
        } else {
          notice("Error al crear, revise todos los datos de la nueva entrada");
        }
        setModal(false);
      })
      .finally(() => dispatch(setLoader(false)));
  };

  const handleUpdate = ({ closed = false, precharge = false, patch = false }) => {
    dispatch(setLoader(true));
    let payload;
    if (entrance.tipo_entrada === "ORDINARIA" && closed) {
      payload = {
        id: entrance.id,
        cf_no_factura: entrance.cf_no_factura,
        entrada_cerrada: true,
        cf_fecha_factura: entrance.cf_fecha_factura ? entrance.cf_fecha_factura : null,
      };
    } else if (entrance.tipo_entrada === "ORDINARIA") {
      payload = ordinaryRequest({
        ...entrance,
        entrada_cerrada: closed,
      });
    } else {
      payload = clearRequest({
        ...entrance,
        entrada_cerrada: closed,
      });
    }
    if (precharge && entrance.tipo_entrada === "STOCK") {
      payload.warehouse_entry_details = [];
    }
    if (
      entrance.tipo_entrada === "FONDOREVOLVENTE" ||
      entrance.tipo_entrada === "GASTOSCOMPROBAR"
    ) {
      payload = {
        ...entrance,
        cf_ord_no_contrato:
          entrance.tipo_entrada === "FONDOREVOLVENTE"
            ? "Fondo revolvente"
            : "Gastos a comprobar",
        cf_importe_neto: entrance.cf_importe_neto
          ? Number(entrance?.cf_importe_neto?.replaceAll(",", ""))
          : null,
        cf_importe_remision: entrance.cf_importe_remision
          ? Number(entrance?.cf_importe_remision?.replaceAll(",", ""))
          : null,
      };
      delete payload.warehouse_entry_details;
    }

    payload = {
      ...entrance,
      cf_importe_neto: entrance.cf_importe_neto
        ? Number(entrance?.cf_importe_neto?.replaceAll(",", ""))
        : null,
      cf_importe_remision: entrance.cf_importe_remision
        ? Number(entrance?.cf_importe_remision?.replaceAll(",", ""))
        : null,
      cf_importe_remision_factura: entrance.cf_importe_remision_factura
        ? Number(entrance?.cf_importe_remision_factura?.replaceAll(",", ""))
        : null,
    };
    if (entrance.tipo_entrada === "LIMPIEZA") {
      payload = {
        ...payload,
        warehouse_entry_details: entrance.requisition_item_details.map((item) => ({
          cf_cve_articulo: item.cf_cve_articulo,
          cf_cantidad: item.cf_cantidad,
          cf_precio_unit: Number(
            item.cf_precio_unit.length > 8
              ? item.cf_precio_unit?.replaceAll(",", "")
              : item.cf_precio_unit
          ),
        })),
      };
      delete payload.cf_importe_neto;

      delete payload.requisition_item_details;
    }
    if (
      entrance.tipo_entrada === "RECURRENTE" &&
      !permisos.permisos.includes("a-entrada-precarga")
    ) {
      if (entrance.requisition_item_details.some((item) => !item.cf_cantidad)) {
        notice("Se requiere una cantidad mayor a 1 en los artículos");
        dispatch(setLoader(false));
        return;
      }
      payload = {
        ...payload,
        warehouse_entry_details: entrance.requisition_item_details.map((item) => ({
          id: item.requisition_item_detail,
          requisition_item_detail: item.requisition_item_detail,
          cf_observaciones: "",
          cf_cve_articulo: item.cf_cve_articulo,
          cf_cantidad: item.cf_cantidad,
          recurrent_assets: item.recurrent_assets,
        })),
      };
    }
    if (
      entrance.tipo_entrada === "STOCK" &&
      !permisos.permisos.includes("a-entrada-precarga")
    ) {
      if (entrance.requisition_item_details.some((item) => !item.cf_cantidad)) {
        notice("Se requiere una cantidad mayor a 1 en los artículos");
        dispatch(setLoader(false));
        return;
      }
      payload = {
        ...payload,
        warehouse_entry_details: entrance.requisition_item_details.map((item) => ({
          id: item.id,
          requisition_item_detail: item.requisition_item_detail,
          cf_cantidad: item.cf_cantidad,
          recurrent_assets: [],
        })),
      };
      delete payload.requisition_item_details;
    }

    payload = {
      ...payload,
      entrada_cerrada: closed,
      cf_fecha_remision:
        payload.cf_fecha_remision === "" ? null : payload.cf_fecha_remision,
    };
    let patchObj = {};
    if (patch) {
      patchObj = {
        cf_no_factura: payload.cf_no_factura,
        cf_observaciones: payload.cf_observaciones,
        cf_fecha_factura: payload.cf_fecha_factura,
        id: payload.id,
      };
    }
    closeEntrance(patch ? patchObj : payload)
      .then((res) => {
        setModal(false);
        notice("La entrada ha sido cerrada correctamente", "success");
        dispatch(
          setEntrance({
            ...entrance,
            entrada_cerrada: closed,
          })
        );
        // dispatch(setCurrentMode())
      })
      .catch(() => notice("Error al actualizar la entrada"))
      .finally(() => dispatch(setLoader(false)));
  };

  const createOrdinary = () => {
    dispatch(setLoader(true));
    const payload = ordinaryRequest(entrance);
    createEntrance(payload)
      .then(async (res) => {
        if (res.id) {
          if (files.length > 0) {
            const uploadFile = await Promise.all(
              files.map((file) =>
                addRequirementFile(file, res.id, file.name, "warehouse_entry")
              )
            );
            if (
              uploadFile.some((response) =>
                [500, 422].includes(response?.response?.status)
              )
            )
              notice("Alguno de los los archivos no se pudo subir");
            else {
              notice("Entrada creada correctamente", "success");
              handleLocation(
                `/inventario-bienes-almacen-general/entradas/resultados/${res.id}`
              );
              dispatch(setFiles([]));
            }
          } else {
            notice("Entrada creada correctamente", "success");
            handleLocation(
              `/inventario-bienes-almacen-general/entradas/resultados/${res.id}`
            );
            // dispatch(setCurrentMode())
          }
        }
      })
      .catch((e) => notice(e?.error ? e?.error : "Error al crear la entrada"))
      .finally(() => dispatch(setLoader(false)));
  };

  //FONDOREVOLVENTE
  const createRevolvente = () => {
    dispatch(setLoader(true));
    const payload = ordinaryRequest(entrance); //puede ser utiliaza para revolvente y gastos
    createEntrance(payload)
      .then(async (res) => {
        if (res.id) {
          if (files.length > 0) {
            const uploadFile = await Promise.all(
              files.map((file) =>
                addRequirementFile(file, res.id, file.name, "warehouse_entry")
              )
            );
            if (
              uploadFile.some((response) =>
                [500, 422].includes(response?.response?.status)
              )
            )
              notice("Alguno de los los archivos no se pudo subir");
            else {
              notice("Entrada creada correctamente", "success");
              handleLocation(
                `/inventario-bienes-almacen-general/entradas/resultados/${res.id}`
              );
              dispatch(setFiles([]));
            }
          } else {
            notice("Entrada creada correctamente", "success");
            handleLocation(
              `/inventario-bienes-almacen-general/entradas/resultados/${res.id}`
            );
            // dispatch(setCurrentMode())
          }
        }
      })
      .catch((e) => notice(e?.error ? e?.error : "Error al crear la entrada"))
      .finally(() => dispatch(setLoader(false)));
  };
  //GASTOSCOMPROBAR
  const createGastos = () => {
    dispatch(setLoader(true));
    console.log(entrance);
    const payload = ordinaryRequest(entrance);
    createEntrance(payload)
      .then(async (res) => {
        if (res.id) {
          if (files.length > 0) {
            const uploadFile = await Promise.all(
              files.map((file) =>
                addRequirementFile(file, res.id, file.name, "warehouse_entry")
              )
            );
            if (
              uploadFile.some((response) =>
                [500, 422].includes(response?.response?.status)
              )
            )
              notice("Alguno de los los archivos no se pudo subir");
            else {
              notice("Entrada creada correctamente", "success");
              handleLocation(
                `/inventario-bienes-almacen-general/entradas/resultados/${res.id}`
              );
              dispatch(setFiles([]));
            }
          } else {
            notice("Entrada creada correctamente", "success");
            handleLocation(
              `/inventario-bienes-almacen-general/entradas/resultados/${res.id}`
            );
            // dispatch(setCurrentMode())
          }
        }
      })
      .catch((e) => {
        notice(e?.error ? e?.error : "Error al crear la entrada");
      })
      .finally(() => dispatch(setLoader(false)));
  };
  const onNewRegister = () => {
    handleLocation("/inventario-bienes-almacen-general/entradas/nuevo");
    if (entrance.id) dispatch(setEntrance(defaultEntrance));
  };

  const handleResults = () => {
    if (entrance.id) {
      handleLocation(
        `/inventario-bienes-almacen-general/entradas/resultados/${entrance.id}`
      );
    } else if (selectedIds[0]) {
      handleLocation(
        `/inventario-bienes-almacen-general/entradas/resultados/${selectedIds[0]}`
      );
    } else {
      notice("No se encontraron registros guardados", "info");
      handleLocation("/inventario-bienes-almacen-general/entradas/nuevo");
    }
  };

  const handleSearch = () => {
    dispatch(setLoader(true));
    getEntranceList(
      1,
      100,
      filterParams(
        clearEmptyStringsObject(
          cleanObject({
            ...searchEnatrance,
            proveedor: searchEnatrance.proveedor?.text2,
          })
        )
      )
    )
      .then((res) => {
        dispatch(setSearchEntrance(defaultFilter));
        dispatch(setSelectedIds(res.results.map(({ id }) => id)));
        handleLocation(
          `/inventario-bienes-almacen-general/entradas/resultados/${res.results[0].id}`
        );
      })
      .catch((e) => notice("Error cargando la lista de entradas"))
      .finally(() => dispatch(setLoader(false)));
  };

  const handleLocation = (location) => history.push(location);
  const showAditionalButton =
    (entrance.tipo_entrada === "STOCK" || entrance.tipo_entrada === "RECURRENTE") &&
    (entrance.entrada_pre_registro || isNewPage);

  //?FECHA Y NUMERO REQUERIDOS
  let fechaAndNoFactura = Boolean(entrance.cf_fecha_factura && entrance.cf_no_factura);
  let fechaAndNoRemision = Boolean(entrance.cf_fecha_remision && entrance.cf_no_remision);
  //?STOCK
  let isStockEntranceAndUserPrecarga =
    entrance.tipo_entrada === "STOCK" && permisos.permisos.includes("a-entrada-precarga");
  let isStockEntranceAndUserBorrador =
    entrance.tipo_entrada === "STOCK" && permisos.permisos.includes("a-entrada-borrador");
  let isStockEntranceAndUserCerrarAndOpen =
    entrance.tipo_entrada === "STOCK" &&
    permisos.permisos.includes("a-entrada-cerrar") &&
    !entrance.entrada_cerrada;

  //? LIMPIEZA
  let isLimpiezaEntranceAndUserPrecarga =
    entrance.tipo_entrada === "LIMPIEZA" &&
    permisos.permisos.includes("a-entrada-precarga");
  let isLimpiezaPrecargaProveedor =
    permisos.permisos.includes("a-entrada-precarga") &&
    entrance.tipo_entrada === "LIMPIEZA" &&
    entrance.supplier &&
    (fechaAndNoFactura || fechaAndNoRemision);
  let isLimpiezaEntranceAndUserBorrar =
    entrance.tipo_entrada === "LIMPIEZA" &&
    permisos.permisos.includes("a-entrada-borrador") &&
    entrance.supplier &&
    (fechaAndNoFactura || fechaAndNoRemision);
  let isLimpiezaEntranceAndUserCerrar =
    entrance.tipo_entrada === "LIMPIEZA" &&
    permisos.permisos.includes("a-entrada-cerrar") &&
    entrance.supplier &&
    (fechaAndNoFactura || fechaAndNoRemision);
  //? RECURRENTE
  let isRecurrenteEntranceAndUserPrecarga =
    entrance.tipo_entrada === "RECURRENTE" &&
    permisos.permisos.includes("a-entrada-precarga");
  let isRecurrenteEntranceAndUserBorrar =
    entrance.tipo_entrada === "RECURRENTE" &&
    permisos.permisos.includes("a-entrada-borrador");
  let isRecurrenteEntranceAndUserCerrar =
    entrance.tipo_entrada === "RECURRENTE" &&
    permisos.permisos.includes("a-entrada-cerrar");
  let isRecurrentEntranceAndUserCerrarAndOpen =
    entrance.tipo_entrada === "RECURRENTE" &&
    permisos.permisos.includes("a-entrada-cerrar") &&
    !entrance.entrada_cerrada;

  //? ORDINARIA
  let isOrdinariaEntranceAndUserPrecarga =
    entrance.tipo_entrada === "ORDINARIA" &&
    permisos.permisos.includes("a-entrada-precarga");
  let isOrdinariaEntranceAndUserBorrar =
    entrance.tipo_entrada === "ORDINARIA" &&
    permisos.permisos.includes("a-entrada-borrador");
  let isOrdinariaEntranceAndUserCerrar =
    entrance.tipo_entrada === "ORDINARIA" &&
    permisos.permisos.includes("a-entrada-cerrar");
  //bloquear si ya esta creada

  //? FONDOREVOLVENTE
  let isRevolventeEntranceAndUserPrecarga =
    entrance.tipo_entrada === "FONDOREVOLVENTE" &&
    permisos.permisos.includes("a-entrada-precarga");
  let isRevolventeEntranceAndUserBorrar =
    entrance.tipo_entrada === "FONDOREVOLVENTE" &&
    permisos.permisos.includes("a-entrada-borrador");
  let isRevolventeEntranceAndUserCerrar =
    entrance.tipo_entrada === "FONDOREVOLVENTE" &&
    permisos.permisos.includes("a-entrada-cerrar");

  //? GASTOSCOMPROBAR
  let isGastosEntranceAndUserPrecarga =
    entrance.tipo_entrada === "GASTOSCOMPROBAR" &&
    permisos.permisos.includes("a-entrada-precarga");
  let isGastosEntranceAndUserBorrar =
    entrance.tipo_entrada === "GASTOSCOMPROBAR" &&
    permisos.permisos.includes("a-entrada-borrador");
  let isGastosEntranceAndUserCerrar =
    entrance.tipo_entrada === "GASTOSCOMPROBAR" &&
    permisos.permisos.includes("a-entrada-cerrar");
  //?REQUISITION ITEM DETAILS
  let RequisitionItemDetails = entrance.requisition_item_details;
  let RequisitionItemDetailsStatus = RequisitionItemDetails.filter((item) => item.status); //status true

  let RequisitionQuantity =
    RequisitionItemDetails.filter((item) => item.cf_cantidad > 0).length > 0; //o que la cantidad sea mayor a 0

  //*Borrador-limpieza
  let RequisitionQuantityArt = RequisitionItemDetails.map((item) =>
    Boolean(
      item.cf_cve_articulo >= 7000 &&
        item.cf_cve_articulo <= 7999 &&
        item.cf_cantidad > 0 &&
        item.cf_precio_unit > 0
    )
  );
  //tabla de articulos en limpieza
  let requisitionArtTable = RequisitionQuantityArt.includes(false); //da como resultado false si NO CONTIENE

  //PROVEEDOR, CONTRATO, NO.REQUERIMIENTO REQUERIDOS
  const requiredFieldsToSave =
    entrance.supplier && entrance.contract_number && entrance.requirement;
  //fechas del día de hoy o mayor
  const dateNow = moment().format("YYYY-MM-DD");
  // const date2 = moment(entrance.cf_fecha_entrada, "DD-MM-YYYY").format("YYYY-MM-DD");
  // let fechas = moment(date2).isSameOrAfter(dateNow);
  //para recurrentes cando la tabla no llegue debe habilitarse con solo poner la fecha adecuada, BORRADOR-RECURRENTES

  const date2 = moment(entrance.cf_fecha_entrada, "DD/MM/YYYY").format("YYYY-MM-DD");
  let fechas =
    (moment(entrance.cf_fecha_entrada).isSame(dateNow, "year") &&
      moment(entrance.cf_fecha_entrada).isSame(dateNow, "month")) ||
    (moment(date2).isSame(dateNow, "year") && moment(date2).isSame(dateNow, "month"));
  let requesitionTableWhitoutArticle = RequisitionItemDetails.length === 0 && fechas;
  //? ENTRADAS CERRADAS
  const permisoCerrada = permisos.permisos.includes("a-entrada-cerrar");
  return (
    <>
      <ModeTabs
        onNew={onNewRegister}
        onResults={handleResults}
        onSearch={() =>
          handleLocation("/inventario-bienes-almacen-general/entradas/busqueda")
        }
      />
      <EntryActionIcons
        showIcons={{
          save: isNewPage || entrance.entrada_cerrada || !entrance.entrada_cerrada,
          lock:
            entrance.id &&
            // !entrance.entrada_cerrada &&
            entrance.tipo_entrada !== "ORDINARIA" &&
            !isStockEntranceAndUserPrecarga &&
            !isStockEntranceAndUserBorrador &&
            !isLimpiezaEntranceAndUserBorrar &&
            !isRecurrenteEntranceAndUserPrecarga &&
            !isRecurrenteEntranceAndUserBorrar &&
            !isOrdinariaEntranceAndUserPrecarga &&
            !isRevolventeEntranceAndUserPrecarga &&
            !isRevolventeEntranceAndUserBorrar &&
            !isRevolventeEntranceAndUserCerrar &&
            !isGastosEntranceAndUserPrecarga &&
            !isGastosEntranceAndUserBorrar &&
            !isGastosEntranceAndUserCerrar,
          edit: false,
          search: isSearchPage,
        }}
        disabledIcons={
          //stock
          ((fechaAndNoFactura || fechaAndNoRemision) &&
            (isStockEntranceAndUserPrecarga || isRecurrenteEntranceAndUserPrecarga) &&
            requiredFieldsToSave) ||
          (RequisitionQuantity &&
            permisos.permisos.includes("a-entrada-borrador") &&
            fechas) ||
          (RequisitionQuantity &&
            (isStockEntranceAndUserCerrarAndOpen ||
              isRecurrentEntranceAndUserCerrarAndOpen) &&
            (fechaAndNoFactura || fechaAndNoRemision) &&
            fechas) ||
          //limpieza
          isLimpiezaPrecargaProveedor ||
          ((isLimpiezaEntranceAndUserBorrar || isLimpiezaEntranceAndUserCerrar) &&
            RequisitionItemDetails.length > 0) ||
          isOrdinariaEntranceAndUserPrecarga ||
          isOrdinariaEntranceAndUserBorrar ||
          isRevolventeEntranceAndUserPrecarga ||
          isRevolventeEntranceAndUserBorrar ||
          isRevolventeEntranceAndUserCerrar ||
          isGastosEntranceAndUserPrecarga ||
          isGastosEntranceAndUserBorrar ||
          isGastosEntranceAndUserCerrar
            ? { save: false, lock: false }
            : entrance.tipo_entrada === "ORDINARIA" || entrance.entrada_cerrada
            ? { save: false, lock: false }
            : { save: false, lock: true }
        }
        handleSubmit={() =>
          entrance.id && entrance.entrada_cerrada
            ? handleUpdate({
                closed: true,
                precharge: false,
              })
            : entrance.tipo_entrada === "ORDINARIA" && !entrance.id
            ? setModal(true)
            : entrance.tipo_entrada === "FONDOREVOLVENTE" && !entrance.id
            ? setModal(true)
            : entrance.tipo_entrada === "GASTOSCOMPROBAR" && !entrance.id
            ? setModal(true)
            : entrance.tipo_entrada === "FONDOREVOLVENTE" && entrance.id
            ? setModal(true)
            : entrance.tipo_entrada === "GASTOSCOMPROBAR" && entrance.id
            ? setModal(true)
            : setModal(true)
        }
        handleLock={() => setModal(true)}
        handleSearch={handleSearch}
      />
      {entrance.entrada_cerrada ? (
        <BaseModal
          open={modal}
          title="¿Deseas guardar la entrada?"
          onClose={() => setModal(false)}
          confirmLabel="Guardar"
          onConfirm={() =>
            entrance.id
              ? handleUpdate({
                  closed: true,
                  precharge: false,
                  patch: true,
                })
              : handleSubmit({
                  closed: false,
                  precharge: false,
                })
          }
          maxWidth="sm"
          confirmVariant="outlined"
          color="secondary"
        />
      ) : isStockEntranceAndUserPrecarga ||
        isLimpiezaEntranceAndUserPrecarga ||
        isRecurrenteEntranceAndUserPrecarga ||
        isOrdinariaEntranceAndUserPrecarga ||
        isRevolventeEntranceAndUserPrecarga ||
        isGastosEntranceAndUserPrecarga ? (
        <BaseModal
          open={modal}
          title="¿Deseas precargar la entrada?"
          onClose={() => setModal(false)}
          onConfirm={() =>
            entrance.id
              ? handleUpdate({
                  closed: false,
                  precharge: true,
                })
              : handleSubmitStockPrecharge({
                  closed: false,
                  precharge: true,
                })
          }
          maxWidth="sm"
          confirmVariant="outlined"
          confirmLabel="Precargar"
          color="secondary"
        >
          Tenga en cuenta que al cerrarse la entrada, no podra ser editada.
        </BaseModal>
      ) : isStockEntranceAndUserBorrador ||
        isLimpiezaEntranceAndUserBorrar ||
        isOrdinariaEntranceAndUserBorrar ||
        isRecurrenteEntranceAndUserBorrar ||
        isRevolventeEntranceAndUserBorrar ||
        isGastosEntranceAndUserBorrar ? (
        <BaseModal
          open={modal}
          title="¿Deseas guardar como borrador la entrada?"
          onClose={() => setModal(false)}
          confirmLabel="Guardar como borrador"
          onConfirm={() =>
            entrance.id
              ? handleUpdate({
                  closed: false,
                  precharge: false,
                })
              : handleSubmit({
                  closed: false,
                  precharge: false,
                })
          }
          maxWidth="sm"
          confirmVariant="outlined"
          color="secondary"
        >
          Tenga en cuenta que al cerrarse la entrada, no podra ser editada.
        </BaseModal>
      ) : permisoCerrada ? (
        <BaseModal
          open={modal}
          title="¿Deseas cerrar la entrada?"
          onClose={() => setModal(false)}
          onConfirm={() =>
            entrance.id
              ? handleUpdate({
                  closed: true,
                  precharge: false,
                })
              : handleSubmit({
                  closed: true,
                  precharge: false,
                })
          }
          onCancel={() =>
            entrance.id
              ? handleUpdate({
                  closed: false,
                  precharge: false,
                })
              : handleSubmit({
                  closed: false,
                  precharge: false,
                })
          }
          cancelLabel="No, guardar como borrador"
          maxWidth="sm"
          cancelColor="secondary"
          confirmLabel="Si, continuar y afectar"
          cancelVariant="contained"
          aditionalButtonLabel="Precargar"
          onClickAditionalButton={
            showAditionalButton
              ? () =>
                  entrance.id
                    ? handleUpdate({
                        closed: false,
                        precharge:
                          isStockEntranceAndUserCerrarAndOpen ||
                          isRecurrentEntranceAndUserCerrarAndOpen
                            ? false
                            : true,
                      })
                    : handleSubmit({
                        closed: false,
                        precharge:
                          isStockEntranceAndUserCerrarAndOpen ||
                          isRecurrentEntranceAndUserCerrarAndOpen
                            ? false
                            : true,
                      })
              : false
          }
        >
          Tenga en cuenta que al cerrarse la entrada, no podra ser editada.
        </BaseModal>
      ) : (
        <BaseModal
          open={modal}
          title="¿Deseas cerrar la entrada?"
          onClose={() => setModal(false)}
          onConfirm={() =>
            entrance.id
              ? handleUpdate({
                  closed: true,
                  precharge: false,
                })
              : handleSubmit({
                  closed: true,
                  precharge: false,
                })
          }
          onCancel={() =>
            entrance.id
              ? handleUpdate({
                  closed: false,
                  precharge: false,
                })
              : handleSubmit({
                  closed: false,
                  precharge: false,
                })
          }
          cancelLabel="No, guardar como borrador"
          maxWidth="sm"
          cancelColor="secondary"
          confirmLabel="Si, continuar y afectar"
          cancelVariant="contained"
          aditionalButtonLabel="Precargar"
          onClickAditionalButton={
            showAditionalButton
              ? () =>
                  entrance.id
                    ? handleUpdate({
                        closed: false,
                        precharge:
                          isStockEntranceAndUserCerrarAndOpen ||
                          isRecurrentEntranceAndUserCerrarAndOpen
                            ? false
                            : true,
                      })
                    : handleSubmit({
                        closed: false,
                        precharge:
                          isStockEntranceAndUserCerrarAndOpen ||
                          isRecurrentEntranceAndUserCerrarAndOpen
                            ? false
                            : true,
                      })
              : false
          }
        >
          Tenga en cuenta que al cerrarse la entrada, no podra ser editada.
        </BaseModal>
      )}
    </>
  );
};

export default EntranceActions;
