import { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router";

export const useSearchMode = (path) => {
  const history = useHistory();
	const { id } = useParams();

	const handleKeyDown = useCallback((e) => {
		const {ctrlKey, keyCode} = e;
		if (ctrlKey && keyCode === 70){
			e.preventDefault();
            history.push(path);
		}
	}, [history, path]);
    
    useEffect(() => {
		id !== 'busqueda' && document.addEventListener('keydown', handleKeyDown);
        return () => id !== 'busqueda' && document.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown, id]);
};