import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  setClearState,
  setDeparture,
  setDepartures,
  setSelectedDepartures,
} from "../../../redux/ducks/departure.duck";
import { setLoader } from "../../../redux/ducks/loader.duck";
import FullTable from "../../components/FullTable";
import notice from "../../components/Notice";
import {
  listReqDepartures,
  singleReqDeparture,
} from "../../crud/departuresCrud";
import { requestGetPdf } from "../../crud/requestCrud";
import {
  columns,
  defaultFilter,
  departureFilterInputs,
} from "../../utils/departureData";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";

const DeparturesPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.departure);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [selectedIds, setSelectedIds] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);

  const getFilterParams = useCallback(() => {
    let params = {
      ...filter,
      cf_fecha_entrega_cancelado:
        filter.cf_fecha_entrega_cancelado_inicio &&
        filter.cf_fecha_entrega_cancelado_fin
          ? `${filter.cf_fecha_entrega_cancelado_inicio}:${filter.cf_fecha_entrega_cancelado_fin}`
          : "",
    };
    delete params.cf_fecha_entrega_cancelado_fin;
    delete params.cf_fecha_entrega_cancelado_inicio;
    console.log(params);
    return filterParams(clearEmptyStringsObject(cleanObject(params)));
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    listReqDepartures(page, sizePerPage, getFilterParams())
      .then((res) => dispatch(setDepartures(res)))
      .catch(console.log)
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, sizePerPage]);

  const handleFilterChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const onConfirmFilter = () => {
    setLoading(true);
    listReqDepartures(page, sizePerPage, getFilterParams())
      .then((res) => dispatch(setDepartures(res)))
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const onClearFilter = () => {
    setFilter(defaultFilter);
    setLoading(true);
    listReqDepartures(page, sizePerPage)
      .then((res) => dispatch(setDepartures(res)))
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const handleAdd = () =>
    history.push("/inventario-bienes-almacen-general/salidas/nueva");

  const onFile = (id) => {
    requestGetPdf(id)
      .then((result) => {
        if (result.status === 200 && result.data?.attachment_url)
          window.open(result.data.attachment_url);
        else notice("No se pudo cargar el PDF, contacta al administrador");
      })
      .catch((e) =>
        notice("No se pudo cargar el PDF, contacta al administrador")
      );
    // history.push('/solicitudes/salidas');
  };

  const onEye = (id, isSelectedIds) => {
    if (!isSelectedIds) dispatch(setClearState());
    dispatch(setLoader(true));
    singleReqDeparture(id)
      .then((res) => {
        dispatch(setDeparture(res));
        history.push(
          `/inventario-bienes-almacen-general/salidas/resultados/${id}`
        );
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  };

  const onCross = (id) => console.log("cross");

  const handleSelectAllRows = (isSelect, rows) =>
    isSelect ? setSelectedIds(rows.map((r) => r.id)) : setSelectedIds([]);

  const handleSelectRow = (row, isSelect) => {
    if (row && row.id) {
      if (!isSelect) {
        const newSelected = selectedIds.filter((id) => id !== row.id);
        setSelectedIds(newSelected);
      } else {
        setSelectedIds([...selectedIds, row.id]);
      }
    }
  };

  const handleReqSelected = () => {
    dispatch(setSelectedDepartures(selectedIds));
    onEye(selectedIds[0], true);
  };

  const handleSearch = () => {
    dispatch(setClearState());
    history.push("/inventario-bienes-almacen-general/salidas/busqueda");
  };

  return (
    <>
      <FullTable
        title="Salidas"
        buttonTitle="Nueva Salida"
        onClickButton={handleAdd}
        columns={columns(onFile, onEye, onCross)}
        entities={list.results}
        count={list.count}
        page={page}
        setPage={(page) => page > 0 && setPage(page)}
        sizePerPage={sizePerPage}
        setSizePerPage={setSizePerPage}
        loading={loading}
        hasReportIcons={false}
        has_button={false}
        onClickSearch={handleSearch}
        // SELECTED Ids ACTIONS
        has_button2
        buttonDisabled2={selectedIds.length < 1}
        buttonTitle2={`Consultar Todos${
          selectedIds.length > 0 ? `(${selectedIds.length.toString()})` : ""
        }`}
        onSelectRow={handleSelectRow}
        onSelectAllRows={handleSelectAllRows}
        onClickButton2={handleReqSelected}
        // FILTERS
        has_filter
        formFilters={filter}
        onFilterChange={handleFilterChange}
        filterInputs={departureFilterInputs}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
      />
    </>
  );
};

export default DeparturesPage;
