import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardActions, CardContent, CardHeader, makeStyles, Typography, SvgIcon } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	root: {
		height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
	},
  icon: {
    fontSize: '3rem',
    color: props => props.colorIcon ? theme.palette[props.colorIcon]['main'] : '#C7861D'
  },
  roundIcon: {
    fontSize: '4rem',
    color: theme.palette.common.white,
    backgroundColor: props => props.colorIcon ? theme.palette[props.colorIcon]['main'] : theme.palette.primary.main,
    borderRadius: '50%',
    padding: theme.spacing(1.5)
  },
  header: {
    height: '100%',
    alignItems: 'start'
  },
  content: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1, 2, 2, 1)
  }
}));

export const CardAction = ({disabled=false,title, actionButtonTitle, onClickActionButton=()=>{}, IconComponent = null, ...props}) => {
  const classes = useStyles(props);

  return (
    <Card className={classes.root}>
      <CardHeader className={classes.header} title={<Typography align={props.isCenterTitle ? 'center' : 'left'} variant="subtitle1">{title}</Typography>} />
      <CardContent className={classes.content}>
        <SvgIcon className={props.roundIcon ? classes.roundIcon : classes.icon}>{IconComponent}</SvgIcon>
      </CardContent>
      <CardActions className={classes.content}>
        <Button disableElevation variant="contained" color="secondary" onClick={onClickActionButton} disabled={disabled}>{actionButtonTitle}</Button>
      </CardActions>
    </Card>
  )
};

CardAction.propTypes = {
  roundIcon: PropTypes.bool,
};

CardAction.defaultProps = {
  roundIcon: true,
};

export default CardAction;
