import React from "react";
import axios from "axios";
import { ASSTETS_STOCK } from "./helpers/routes";
import notice from "../components/Notice";
import { getParams, handleResponse } from "./helpers/validate";

export const createNewEntranceConcentrate = (data) => {
  return axios.post(ASSTETS_STOCK + "/entry-concentrated/", data);
};

export const listEntranceConcentrates = async (
  page,
  sizePerPage,
  filters = "",
  order = "desc"
) => {
  return axios.get(
    `${ASSTETS_STOCK}/entry-concentrated-list/?page=${page}&limit=${sizePerPage}&order=${order}&${filters}`
  );
};

export const getEntranceConcentratebyID = (ID) => {
  try {
    const params = new URLSearchParams();
    params.append("id", `${ID}`);

    return axios.get(
      `${ASSTETS_STOCK}/entry-concentrated/?${params.toString()}`
    );
  } catch {}
};

export const getEntryByID = (ID) => {
  try {
    return axios.get(`${ASSTETS_STOCK}/entry-stock/${ID}/`);
  } catch {}
};

export const getPDF = (ID) => {
  try {
    const body = {
      concentrated_id: ID,
    };

    return axios.post(`${ASSTETS_STOCK}/entry-concentrated-report/`, body);
  } catch {}
};

export const getEntries = (query) => {
  try {
    const params = new URLSearchParams();
    params.append("no_entrada", `${query}*`);

    return axios
      .get(`${ASSTETS_STOCK}/entry-concentrated-pending/?${params.toString()}`)
      .then((res) => {
        let result = [];
        if (res?.status && res.status === 200 && res.data.results) {
          res.data.results.forEach((option) => {
            result.push({
              id: option.id,
              text: option.id.toString().trim(),
              text2: option.ordinaria_descripcion?.trim(),
              data: option,
            });
          });
        }
        return result;
      })
      .catch((err) => console.log("error" + err));
  } catch {}
};
