import * as columnFormatters from "../components/column-formatters";
import { IdsFormatter } from "../components/column-formatters/IdsFormatter";
import {
  getAllResponsables,
  getAreas,
  getBatches,
  getItemCABMSAndKey,
  getProviders,
  getResponsibles,
  getResposableByName,
} from "../crud/catalogsCrud";
import { isConciliated } from "./batchesUtils";
import { getNames, getKey } from "../crud/stockCrud";
import notice from "../components/Notice";
import { getKeyData } from "./cabmsAndKey";
import { labelStatusOptions, labelTypeOptions } from "./StockFormData";
import { setFormatDate } from "./dates";
import { CollectionsBookmarkOutlined } from "@material-ui/icons";

export const columns = (
  onEye,
  onBox,
  onDrop,
  guardItems,
  isDropPage,
  Permisos
) => [
  {
    dataField: "id",
    text: "No. inventario",
    sort: true,
    formatter: (cell, row) => IdsFormatter(cell, row, "I -"),
  },
  isDropPage
    ? {
        dataField: "motivo_baja",
        text: "Motivo",
        sort: true,
      }
    : {
        dataField: "cabms_key",
        text: "CABMS",
        sort: true,
      },
  isDropPage
    ? {
        dataField: "tipo_baja",
        text: "Tipo de baja",
        sort: true,
        formatter: (cell) =>
          [
            { value: 1, text: "Perdida" },
            { value: 2, text: "Defecto" },
            { value: 3, text: "Obsolencia" },
          ].find((x) => x.value === cell)?.text,
      }
    : {
        dataField: "cabms_item",
        text: "Artículo CABMS",
        sort: true,
      },
  {
    dataField: "cf_marca",
    text: "Marca",
    sort: true,
  },
  {
    dataField: "cf_modelo",
    text: "Modelo",
    sort: true,
  },
  {
    dataField: "cf_serie",
    text: "Serie",
    sort: true,
  },
  {
    dataField: "cf_cve_area",
    text: "Área",
    sort: true,
  },
  {
    dataField: "cf_responsables",
    text: "Responsable",
    sort: true,
    // formatter: (cell, row ,rowIndex) => {
    // 	const attendants = row.area_information?.area_attendant || [];
    // 	return attendants.map(({num_empleado}, index, array) => `${num_empleado}${array[(index+1)] ? ', ' : '.'}`)
    // }
  },
  {
    dataField: "id",
    text: "Acciones",
    formatter: isDropPage
      ? columnFormatters.DropActionColumn
      : columnFormatters.StockActionsColumnFormatter,
    formatExtraData: {
      onEye,
      onBox,
      onDrop,
      guardItems,
      Permisos,
    },
    classes: isDropPage ? "text-center" : "text-right pr-0",
    headerClasses: isDropPage ? "text-center" : "text-right pr-3",
    style: {
      minWidth: "100px",
    },
    csvExport: false,
  },
];

export const defaultFilter = {
  no_inventa: "",
  marca: "",
  serie: "",
  cve_area: "",
  cabms_item: null,
};

export const filterStockInputs = [
  {
    label: "No. de inventario",
    name: "no_inventa",
    type: "text",
  },
  // {
  //   label: "Fecha de depreciación",
  //   name: "fecha_depreciacion",
  //   type: "text",
  // },
  {
    label: "Artículo CABMS",
    placeholder: "Artículo CABMS",
    name: "cabms_item",
    type: "search",
    required: true,
    hasRef: true,
    searchFunction: getItemCABMSAndKey,
    width: 200,
  },
  {
    label: "Area",
    name: "cve_area",
    type: "search",
    searchFunction: getAreas,
  },

  // {
  //   label: 'Marca',
  //   name: 'marca',
  //   type: 'text',
  // },
  {
    label: "Serie",
    name: "serie",
    type: "text",
  },
];

export const mockAreaList = [
  { name: "P-S-01-SITE", desc: "Dirección General de Informática" },
  { name: "P-S-02-SITE", desc: "Dirección General de Informática" },
  { name: "P-S-03-SITE", desc: "Dirección General de Informática" },
  { name: "P-S-04-SITE", desc: "Dirección General de Informática" },
  { name: "P-S-05-SITE", desc: "Dirección General de Informática" },
];

export const initialStockValues = {
  id: "",
  no_inventa: "",
  fecha_depreciacion: "",
  num_folio: "",
  cabms_key: "",
  cabms_item: null,
  key: "",
  cf_ingreso: "",
  cf_articulo: "",
  cf_num_inventario_anterior: "",
  item: "",
  cf_descripcion: "",
  cf_marca: "",
  cf_modelo: "",
  cf_serie: "",
  cf_condicion_uso: "",
  cf_descripcion_uso: "",
  cf_clasificacion: "",
  cf_aniolocalizacion: "",
  cf_observaciones: "",
  cf_ns_recepcion: "",
  contenidos: "",
  mantenimiento: "",
  cf_bptn: "",
  cf_clavens: "",
  cf_seguros: "",
  cf_bseguros: "",
  cf_mised: "",
  cf_clasificacion_sicaf: "",
  cf_acontabilidad: "",
  cf_libroinv: "",
  cf_tipo_registro: "",
  cf_bcontabilidad: "",
  cf_infreportada: "",
  cf_rpnt: "",
  cf_eninventario: "",
  cf_fecha_resguardo: "",
  cf_cve_area: "",
  cf_fecinv: "",
  cf_tipobien: "",
  cve_partida: [],
  cf_conciliado: "",
  cf_no_cuenta: "",
  cf_no_scuenta: "",
  cf_anio_resguardo: "",
  cf_cve_prove: [],
  cf_fecha_ingreso: "",
  cf_importe: "",
  cf_no_poliza: "",
  cf_fecha_poliza: "",
  cf_tipo_poliza: "",
  cf_num_factura: "",
  cf_fecha_factura: "",
  reference_number: "",
  reference_date: "",
  cf_mudanza: "",
  cf_fecha_actuali: "",
  cf_medioadquisicion: "",
  cf_label_type: "",
  cf_estatus_etiqueta: "",
  desc_partida: "",
  relation_area_num_resguardo: "",
  num_resguardo: "",
  cf_fecha_impr_resguardo: "",
  cf_responsables: [],
  cf_ubicacion: "",
  relation_area_area_grupo: "",
  relation_area_area_subgrupo: "",
  relation_area_area_subsubgrupo: "",
  num_area: "",
  relation_area_nom_area: "",
  relation_nom_sarea: "",
  relation_nom_ssarea: "",
  nom_area: "",
  nom_sarea: "",
  nom_ssarea: "",
  cf_inve_material_type: "",
  cf_fecha_recepcion_factura: "",
  cf_fecha_recepcion_remision: "",
  username: "",
  relation_area_piso: "",
  relation_area_edificio__nom_edificio: [],
  relation_area_edificio__cve_edificio: [],
  relation_area_oficina: "",
  cf_usuario: "",
  cf_no_inventa: "",
  cabms_grupo: "",
  cabms_subgrupo: "",
  cabms_clase: "",
  cabms_subclase: "",
  cabms_tipo: "",
  last_shetler_print: "",
  motivo_baja: "",
  fecha_baja: "",
  tipo_baja_descripcion: "",
};

export const getFixedSingleDropInventoryFromRequest = (dropInventory) => {
  return {
    ...getFixedSingleInventoryFromRequest(dropInventory.inventory_data),
    motivo_baja: dropInventory.motivo_baja,
    tipo_baja_descripcion: dropInventory.tipo_baja_descripcion,
    usuario_registra_baja: dropInventory.usuario_registra_baja,
    fecha_ult_imp_resg: dropInventory.fecha_ult_imp_resg,
    fecha_baja: dropInventory.fecha_baja,
  };
};

export const getFixedSingleInventoryFromRequest = (inventory) => ({
  ...inventory,
  ...inventory.area_information,
  key: getKey(inventory),
  cabms_key: getKey(inventory.catalog_cabms),
  cf_estatus_etiqueta: labelStatusOptions.find(
    (type) =>
      type.text.toUpperCase() === inventory?.cf_estatus_etiqueta?.toUpperCase()
  )?.value,
  cf_label_type: labelTypeOptions.find(
    (type) =>
      type.text.toUpperCase() === inventory?.cf_label_type?.toUpperCase()
  )?.value,
  cf_articulo:
    inventory.cf_articulo != null
      ? inventory.cf_articulo
      : inventory.catalog_clave?.cf_denominacion.trim(),
  foto_data: inventory.attachment,
  cf_cve_prove: {
    id: inventory.id,
    text: inventory.supplier?.razon_social,
    text2: inventory.supplier?.num_prove,
  },
  cve_partida: {
    id: inventory.partida?.id,
    text: inventory.partida?.num_partida,
  },
  desc_partida: inventory.partida?.desc_partida?.toUpperCase(),
  relation_area_edificio__nom_edificio: inventory.area_information?.ubicacion?.edificio?.trim(),
  relation_area_oficina: inventory.area_information?.ubicacion?.oficina,
  relation_area_soficina: inventory.area_information?.ubicacion?.soficina,
  relation_area_piso: inventory.area_information?.ubicacion?.piso,
  relation_area_area_grupo:
    inventory.area_information?.grupos_information?.area_grupo,
  relation_area_area_subgrupo:
    inventory.area_information?.grupos_information?.area_subgrupo,
  relation_area_area_subsubgrupo:
    inventory.area_information?.grupos_information?.area_subsubgrupo,
  cabms_item: {
    ...inventory.catalog_cabms,
    text: inventory.catalog_cabms?.cf_denominacion?.trim(),
    text2: inventory.cabms_key,
  },
  cabms_grupo: inventory.catalog_cabms?.cf_grupo,
  cabms_subgrupo: inventory.catalog_cabms?.cf_subgrupo,
  cabms_clase: inventory.catalog_cabms?.cf_clase,
  cabms_subclase: inventory.catalog_cabms?.cf_subclase,
  cabms_tipo: inventory.catalog_cabms?.cf_tipo,
  cf_responsables: getNames(inventory?.area_information?.area_attendant),
});

export const getFixedInventoryFromRequest = (inventory) => {
  return inventory.map((stockSelect) => ({
    ...getFixedSingleInventoryFromRequest(stockSelect),
  }));
};

export const getPayloadRequest = (inventories) =>
  inventories.map((stock) => {
    const {
      cf_grupo,
      cf_subgrupo,
      cf_clase,
      cf_subclase,
      cf_tipo,
    } = getKeyData(stock.key);
    return {
      id: stock.id,
      asset_acquisition_details: stock.asset_acquisition_details,
      cf_num_inventario_anterior: stock.cf_num_inventario_anterior,
      cf_num_inventario: stock.cf_num_inventario_anterior,
      cf_grupo: stock.cf_grupo,
      cf_subgrupo: stock.cf_subgrupo,
      cf_clase: stock.cf_clase,
      cf_subclase: stock.cf_subclase,
      cf_tipo: stock.cf_tipo,
      cf_ingreso: stock.cf_ingreso,
      cf_num_foto: stock.cf_num_foto,
      cf_anio_resguardo: stock.cf_anio_resguardo,
      cf_cve_area: stock.cf_cve_area,
      cf_articulo: stock.cf_articulo,
      cf_descripcion: stock.cf_descripcion,
      cf_marca: stock.cf_marca,
      cf_modelo: stock.cf_modelo,
      cf_serie: stock.cf_serie,
      cf_condicion_uso: stock.cf_condicion_uso,
      cf_descripcion_uso: stock.cf_descripcion_uso,
      cf_cve_partida: stock.cf_cve_partida,
      cf_conciliado: stock.cf_conciliado,
      cf_cve_prove: stock.cf_cve_prove?.text2,
      cf_importe: stock.cf_importe,
      cf_num_factura: stock.cf_num_factura,
      cf_no_poliza: stock.cf_no_poliza,
      cf_tipo_poliza: stock.cf_tipo_poliza,
      cf_observaciones: stock.cf_observaciones,
      cf_usuario: stock.cf_usuario,
      cf_no_inventa: stock.cf_no_inventa,
      cf_inve_material_type: stock.cf_inve_material_type,
      cf_tipobien: stock.cf_tipobien,
      cf_clasificacion: stock.cf_clasificacion,
      cf_aniolocalizacion: stock.cf_aniolocalizacion,
      cf_ns_recepcion: stock.cf_ns_recepcion,
      cf_seguros: stock.cf_seguros,
      cf_clasificacion_sicaf: stock.cf_clasificacion_sicaf,
      cf_tipo_registro: stock.cf_tipo_registro,
      cf_rpnt: stock.cf_rpnt,
      cf_bpnt: stock.cf_bpnt,
      cf_bseguros: stock.cf_bseguros,
      cf_acontabilidad: stock.cf_acontabilidad,
      cf_bcontabilidad: stock.cf_bcontabilidad,
      cf_eninventario: stock.cf_eninventario,
      cf_medioadquisicion: stock.cf_medioadquisicion,
      cf_clavens: stock.cf_clavens,
      cf_mised: stock.cf_mised,
      cf_libroinv: stock.cf_libroinv,
      cf_infreportada: stock.cf_infreportada,
      catalog_clave: {
        cf_grupo,
        cf_subgrupo,
        cf_clase,
        cf_subclase,
        cf_tipo,
        cf_denominacion: stock.item,
      },
      catalog_cabms: {
        cf_grupo: stock?.cabms_key
          ? getKeyData(stock?.cabms_key)?.cf_grupo
          : "",
        cf_subgrupo: stock?.cabms_key
          ? getKeyData(stock?.cabms_key)?.cf_subgrupo
          : "",
        cf_clase: stock?.cabms_key
          ? getKeyData(stock?.cabms_key)?.cf_clase
          : "",
        cf_subclase: stock?.cabms_key
          ? getKeyData(stock?.cabms_key)?.cf_subclase
          : "",
        cf_tipo: stock?.cabms_key ? getKeyData(stock?.cabms_key)?.cf_tipo : "",
        cf_denominacion: stock.cabms_item,
      },
      movements: stock.movements,
      foto: stock.foto,
      asset_acquisition_id: stock.asset_acquisition_id,
      area_information: stock.area_information,
      reference_number: stock.reference_number,
      cf_mudanza: stock.cf_mudanza,
      cabms: stock.cabms_key,
      key: stock.key,
      cabms_cf_denominacion: stock.cabms_item,
      item_cf_denominacion: stock.item,
      cabms_key: stock.cabms_key,
      cabms_item: stock.cabms_item,
      item: stock.item,
      cf_label_type: stock.cf_label_type,
      cf_estatus_etiqueta: stock.cf_estatus_etiqueta,
      cf_fecha_resguardo: stock.cf_fecha_resguardo,
      cf_fecinv: stock.cf_fecinv,
      cf_fecha_ingreso: stock.cf_fecha_ingreso,
      cf_fecha_poliza: stock.cf_fecha_poliza,
      cf_fecha_factura: stock.cf_fecha_factura,
      reference_date: stock.reference_date,
      cf_fecha_actuali: stock.cf_fecha_actuali,
      inventory_cabms: {
        inventory_cabms_grupo: stock?.cabms_key
          ? getKeyData(stock?.cabms_key)?.cf_grupo
          : "",
        inventory_cabms_subgrupo: stock?.cabms_key
          ? getKeyData(stock?.cabms_key)?.cf_subgrupo
          : "",
        inventory_cabms_clase: stock?.cabms_key
          ? getKeyData(stock?.cabms_key)?.cf_clase
          : "",
        inventory_cabms_subclase: stock?.cabms_key
          ? getKeyData(stock?.cabms_key)?.cf_subclase
          : "",
        inventory_cabms_tipo: stock?.cabms_key
          ? getKeyData(stock?.cabms_key)?.cf_tipo
          : "",
      },
      cf_fecha_recepcion_factura: stock.cf_fecha_recepcion_factura,
      cf_fecha_recepcion_remision: stock.cf_fecha_recepcion_remision,
      cf_no_contrato: stock.cf_no_contrato,
      cf_fecha_contrato: stock.cf_fecha_contrato,
      cf_no_pedido: stock.cf_no_pedido,
      cf_no_requisicion: stock.cf_no_requisicion,
      cf_no_acta: stock.cf_no_acta,
      cf_fecha_acta: stock.cf_fecha_acta,
      cf_no_cuenta: stock.cf_no_cuenta,
      cf_no_scuenta: stock.cf_no_scuenta,
    };
  });

export const historialTableColumns = [
  { name: "No. de Resguardo", field: "num_resguardo" },
  //{ name: 'Fecha de resguardo', field: 'cf_fecha_resguardo' },
  //{ name: 'Fecha Últ. Imp. de Resg.', field: 'cf_fecha_impr_resguardo' },
  { name: "Responsables", field: "cf_responsables" },
  //{ name: 'Edificio', field: 'cf_ubicacion' },
  { name: "Edificio", field: "num_ubica" },
  { name: "Piso", field: "g" },
  { name: "Oficina", field: "sg" },
  { name: "Suboficina", field: "ssg" },
  { name: "Clave área", field: "num_area" },
  { name: "Área resguardante", field: "nom_area" },
  { name: "Subárea", field: "nom_sarea" },
  { name: "Subsubárea", field: "nom_ssarea" },
  { name: "Fecha de resguardo", field: "resguardado_el" },
];

export const icons = (
  onReportsClick,
  onFormatGuardClick,
  onLabelClick,
  getFormat,
  isSelectedStock
) => [
  {
    tooltip: "Reportes",
    label: "Reportes",
    onClick: onReportsClick,
    image: "/media/svg/icons/Files/DownloadedFile.svg",
    disabled: !isSelectedStock,
  },
  {
    tooltip: "Resguardo",
    label: "Resguardo",
    onClick: onFormatGuardClick,
    image: "/media/svg/icons/Files/File.svg",
  },
  {
    tooltip: "Etiqueta",
    label: "Etiqueta",
    onClick: onLabelClick,
    style: { margin: "0 30px" },
    image: "/media/svg/icons/Shopping/Barcode.svg",
  },
  {
    tooltip: "Formato A",
    label: "Formato A",
    onClick: () => getFormat("a"),
    image: "/media/svg/icons/Files/Selected-file.svg",
  },
  {
    tooltip: "Fomrato B",
    label: "Formato B",
    onClick: () => getFormat("b"),
    style: { margin: "0 30px" },
    image: "/media/svg/icons/Files/Selected-file.svg",
  },
  {
    tooltip: "Fomrat C",
    label: "Formato C",
    onClick: () => getFormat("c"),
    image: "/media/svg/icons/Files/Selected-file.svg",
  },
  {
    tooltip: "Formato D",
    label: "Formato D",
    onClick: () => getFormat("d"),
    style: { margin: "0 30px" },
    image: "/media/svg/icons/Files/Selected-file.svg",
  },
];

export const generateDataToMultipleEdit = (stockId, { name, value }, mode) => {
  if (mode !== "search")
    return {
      id: stockId,
      [name]: value,
    };
};

export const handleChangeValidations = (newData, { name, value }, mode) => {
  if (name === "cabms_item") {
    newData = {
      ...newData,
      [name]: value,
      cabms_key: value?.text2,
    };
  } else if (name === "cve_partida" && value && mode !== "search") {
    const cveFirstLetter = value.text2?.toString().charAt(0) || "";
    const cf_conciliado = isConciliated(cveFirstLetter, newData);
    const cf_no_cuenta = value.no_cuenta || "0";
    const cf_no_scuenta = value.no_scuenta || "0";
    const desc_partida = value.text;

    newData = {
      ...newData,
      [name]: value,
      cf_cve_partida: value.text2?.toString(),
      cf_conciliado,
      cf_no_scuenta,
      cf_no_cuenta,
      desc_partida,
    };
  } else if (name === "num_area" && value) {
    const {
      area_attendant,
      num_resguardo,
      num_ubica: cf_ubicacion,
      num_area,
      nom_area,
      nom_sarea,
      nom_ssarea,
      ubicacion,
      grupos_information,
    } = value;
    newData =
      mode !== "search"
        ? {
            ...newData,
            [name]: value,
            cf_responsables: getNames(area_attendant),
            num_resguardo,
            cf_ubicacion,
            cf_cve_area: num_area,
            num_area,
            nom_area: nom_area,
            nom_sarea: nom_sarea,
            nom_ssarea: nom_ssarea,
            relation_area_edificio__nom_edificio: ubicacion?.edificio,
            relation_area_piso: ubicacion?.piso,
            relation_area_oficina: ubicacion?.oficina,
            relation_area_soficina: ubicacion?.soficina,
            relation_area_area_grupo: grupos_information?.area_grupo,
            relation_area_area_subgrupo: grupos_information?.area_subgrupo,
            relation_area_area_subsubgrupo:
              grupos_information?.area_subsubgrupo,
          }
        : { ...newData, [name]: value, cve_area: num_area };
  } else if (
    ["cf_no_poliza", "cf_fecha_poliza", "cf_tipo_poliza"].includes(name)
  ) {
    newData = {
      ...newData,
      [name]: value,
      cf_conciliado: isConciliated(newData.cf_cve_partida, newData),
    };
  } else if (name === "cf_ingreso" && Number(value) > 32767) {
    notice("El ingreso no debe ser mayor a 32767", "warning");
    return;
  } else if (name === "cf_aniolocalizacion") {
    if (Number(value) > 3000) {
      notice("Año de localización debe ser menor 3000", "warning");
      return;
    }
    if (value.length >= 4 && Number(value) < 1900) {
      notice("Año de localización debe ser mayor 1900", "warning");
      return;
    }
  } /*else if (
    ["cf_ingreso", "cf_aniolocalizacion"].includes(name) && 
    !isAValidInteger(value)
  )
    return;*/
  //   else {
  //   newData = { ...newData, [name]: value };
  // }
  newData = { ...newData, [name]: value };
  return newData;
};

// GENERAL FORM

export const stockInputs = (disabled, isSearchPage) => [
  {
    label: "No. de inventario",
    name: "no_inventa",
    type: "text",
    disabled: !isSearchPage || disabled,
    focus: isSearchPage ? true : false || true,
  },
  {
    label: "Fecha de depreciación",
    name: "fecha_depreciacion",
    type: isSearchPage ? "text" : "date",
    disabled,
  },
  {
    label: "Folio",
    placeholder: "No. de Folio",
    name: "num_folio",
    type: "text",
    disabled,
  },
  {
    label: "No. Inventario Informático",
    name: "cf_no_inventa",
    type: "text",
    disabled,
  },
  {
    label: "No. Inv. Anterior",
    name: "cf_num_inventario_anterior",
    type: "text",
    disabled,
  },
  {
    label: "Tipo de etiqueta",
    name: "cf_label_type",
    type: "dropdown",
    disabled,
    options: [
      { value: "", text: "Seleccione un tipo de etiqueta" },
      { value: "NM-MD", text: "NO METÁLICA MEDIANA" },
      { value: "MT-MD", text: "METÁLICA MEDIANA" },
      { value: "NM-CH", text: "NO METÁLICA CHICA" },
      { value: "MT-CH", text: "METÁLICA CHICA" },
    ],
  },
  {
    label: "Estado de etiqueta",
    type: "dropdown",
    name: "cf_estatus_etiqueta",
    disabled,
    options: [
      { value: "", text: "Seleccione un estado de etiqueta" },
      { value: "PENDING", text: "Pendiente" },
      { value: "PRINTED", text: "Etiqueta impresa" },
      { value: "PLACED", text: "Etiqueta colocada" },
    ],
  },
  {
    label: "Ingreso",
    name: "cf_ingreso",
    type: "text",
    disabled,
  },
  {
    type: "line-break",
  },
  {
    label: "CABMS Grupo",
    name: "cabms_grupo",
    disabled,
    type: "text",
    mask: "*",
    width: 120,
  },
  {
    label: "CABMS Subgrupo",
    name: "cabms_subgrupo",
    disabled,
    type: "text",
    mask: "***",
    width: 120,
  },
  {
    label: "CABMS Clase",
    name: "cabms_clase",
    disabled,
    type: "text",
    mask: "***",
    width: 120,
  },
  {
    label: "CABMS Subclase",
    name: "cabms_subclase",
    disabled,
    type: "text",
    mask: "***",
    width: 120,
  },
  {
    label: "CABMS Tipo",
    name: "cabms_tipo",
    disabled,
    type: "text",
    mask: "***",
    width: 120,
  },
  {
    type: "line-break",
  },
  {
    label: "Artículo CABMS",
    name: "cabms_item",
    type: "search",
    width: "50%",
    disabled: disabled || !isSearchPage,
    searchFunction: getItemCABMSAndKey,
  },
  {
    type: "line-break",
  },
  {
    label: "Clave de Artículo",
    name: "key",
    type: "text",
    disabled: true,
  },
  {
    type: "line-break",
  },
  {
    label: "Artículo",
    name: "cf_articulo",
    type: "text",
    width: "50%",
    // searchFunction: getItemAndKey,
    disabled,
  },
  {
    type: "line-break",
  },
  {
    label: "Descripción",
    name: "cf_descripcion",
    type: "text",
    multiline: true,
    width: "100%",
    rows: 4,
    disabled,
  },
  {
    type: "line-break",
  },
  {
    label: "Marca",
    name: "cf_marca",
    type: "text",
    disabled,
  },
  {
    label: "Modelo",
    name: "cf_modelo",
    type: "text",
    disabled,
  },
  {
    label: "Serie",
    name: "cf_serie",
    type: "text",
    disabled,
  },
  {
    label: "Condición de uso",
    name: "cf_condicion_uso",
    type: "dropdown",
    disabled,
    options: [
      { value: "", text: "Seleccione una condición" },
      { text: "No Definido", value: isSearchPage ? "0" : 0 },
      { text: "Buen estado", value: isSearchPage ? "1" : 1 },
      { text: "Maltratado", value: isSearchPage ? "2" : 2 },
      { text: "Incompleto", value: isSearchPage ? "3" : 3 },
      { text: "Descompuesto", value: isSearchPage ? "4" : 4 },
      { text: "Mal Estado", value: isSearchPage ? "5" : 5 },
    ],
  },
  {
    label: "Condición de operación",
    name: "cf_descripcion_uso",
    type: "dropdown",
    disabled,
    options: [
      { value: "", text: "Seleccione una condición" },
      { text: "No Definido", value: isSearchPage ? "0" : 0 },
      { text: "En operación", value: isSearchPage ? "1" : 1 },
      { text: "En Mantenimiento", value: isSearchPage ? "2" : 2 },
      { text: "Sin uso temporal", value: isSearchPage ? "3" : 3 },
      { text: "Para Baja", value: isSearchPage ? "4" : 4 },
    ],
  },
  {
    label: "Clasificación",
    placeholder: "Clasificación",
    name: "cf_clasificacion",
    type: "dropdown",
    disabled,
    options: [
      { value: "", text: "Seleccione una clasificación" },
      { text: "Por Definir", value: "0" },
      { text: "Senado", value: "1" },
      { text: "Nueva Sede", value: "2" },
      { text: "Controlable", value: "3" },
    ],
  },
  {
    label: "Año de localización",
    name: "cf_aniolocalizacion",
    type: "text",
    disabled,
  },
  {
    label: "Fecha de actualización",
    name: "cf_fecha_actuali",
    type: isSearchPage ? "text" : "date",
    disabled: disabled,
  },
  {
    label: "Clave de usuario ant",
    name: "cf_usuario",
    type: "text",
    disabled: !isSearchPage || disabled,
  },
  !isSearchPage && {
    label: "Usuario",
    name: "username",
    type: "text",
    disabled: true,
  },
  {
    type: "line-break",
  },
  {
    label: "Observaciones",
    placeholder: "Observaciones",
    name: "cf_observaciones",
    type: "text",
    disabled,
    width: "100%",
    multiline: true,
    rows: 4,
  },
];

// GUARD TAB FORM

export const stockGuardTabInputs = [
  {
    label: "No. de Resguardo",
    name: "num_resguardo",
    type: "text",
    disabled: true,
  },
  {
    label: "Fecha de resguardo",
    name: "cf_fecha_resguardo",
    type: "date",
    disabled: true,
  },
  {
    type: "line-break",
  },
  {
    label: "Fecha Últ. Imp. de Resg.",
    name: "last_shetler_print",
    type: "text",
    disabled: true,
  },
  {
    type: "line-break",
  },
  {
    label: "Responsables",
    placeholder: "Responsables",
    name: "cf_responsables",
    type: "text",
    width: 415,
    multiline: true,
    disabled: true,
  },
];

export const stockGuardSearchTabInputs = (providers) => [
  {
    label: "No. de Resguardo",
    name: "relation_area_num_resguardo",
    type: "text",
    disabled: false,
  },
  {
    label: "Fecha de resguardo",
    name: "cf_fecha_resguardo",
    type: "text",
    disabled: false,
  },
  {
    type: "line-break",
  },
  {
    label: "Fecha Últ. Imp. de Resg.",
    name: "last_shetler_print",
    type: "text",
    disabled: false,
  },
  {
    type: "line-break",
  },
  {
    label: "Responsables",
    name: "cf_responsables",
    type: "search-multiple",
    width: 415,
    disabled: false,
    searchFunction: getResposableByName,
  },
];

export const stockGuardTabInputs2Search = [
  {
    label: "Clave área",
    name: "cf_cve_area",
    type: "text",
    disabled: true,
    multiline: false,
    width: 300,
  },
  {
    type: "line-break",
  },
  {
    label: "Área resguardante",
    name: "relation_area_nom_area",
    type: "text",
    disabled: true,
    multiline: false,
    width: 300,
  },
  {
    type: "line-break",
  },
  {
    label: "Subárea",
    name: "relation_area_nom_sarea",
    type: "text",
    disabled: true,
    multiline: true,
    width: 300,
  },
  {
    type: "line-break",
  },
  {
    label: "Subsubárea",
    name: "relation_area_nom_ssarea",
    type: "text",
    disabled: true,
    multiline: true,
    width: 300,
  },
];

export const stockGuardTabInputs2 = (edit) => [
  {
    label: "Clave área",
    name: "cf_cve_area",
    type: "text",
    disabled: edit,
    multiline: false,
    width: 300,
  },
  {
    type: "line-break",
  },
  {
    label: "Área resguardante",
    name: "nom_area",
    type: "text",
    disabled: true,
    multiline: false,
    width: 300,
  },
  {
    type: "line-break",
  },
  {
    label: "Subárea",
    name: "nom_sarea",
    type: "text",
    disabled: true,
    multiline: true,
    width: 300,
  },
  {
    type: "line-break",
  },
  {
    label: "Subsubárea",
    name: "nom_ssarea",
    type: "text",
    disabled: true,
    multiline: true,
    width: 300,
  },
];

export const stockGuardTabInputs3 = [
  {
    label: "Edificio",
    name: "relation_area_edificio__nom_edificio",
    type: "text",
    disabled: true,
  },
  {
    label: "Piso",
    name: "relation_area_piso",
    type: "text",
    disabled: true,
  },
  {
    label: "Oficina",
    name: "relation_area_oficina",
    type: "text",
    disabled: true,
  },
  {
    label: "Suboficina",
    name: "relation_area_soficina",
    type: "text",
    disabled: true,
  },
  {
    label: "Grupo",
    name: "relation_area_area_grupo",
    type: "text",
    disabled: true,
    multiline: true,
  },
  {
    label: "Subgrupo",
    name: "relation_area_area_subgrupo",
    type: "text",
    disabled: true,
    multiline: true,
  },
  {
    label: "Subsubgrupo",
    name: "relation_area_area_subsubgrupo",
    type: "text",
    disabled: true,
    multiline: true,
  },
];

export const stockGuardTabInputs3Search = (edificios) => [
  {
    label: "Edificio",
    name: "relation_area_edificio__cve_edificio",
    type: "search-multiple",
    width: 415,
    disabled: false,
    options: edificios,
  },
  {
    label: "Piso",
    name: "relation_area_piso",
    type: "text",
  },
  {
    label: "Oficina",
    name: "relation_area_oficina",
    type: "text",
  },
  {
    label: "Suboficina",
    name: "relation_area_soficina",
    type: "text",
  },
  {
    label: "Grupo",
    name: "relation_area_area_grupo",
    type: "text",
    multiline: true,
  },
  {
    label: "Subgrupo",
    name: "relation_area_area_subgrupo",
    type: "text",
    multiline: true,
  },
  {
    label: "Subsubgrupo",
    name: "relation_area_area_subsubgrupo",
    type: "text",
    multiline: true,
  },
];

// FINANTIAL TAB FORM

export const finantialInputs = (disabled) => [
  {
    label: "Fecha de inventario",
    name: "cf_fecinv",
    type: "date",
    disabled,
  },
  {
    label: "Tipo de material",
    name: "cf_inve_material_type",
    type: "dropdown",
    disabled,
    options: [
      { value: "METAL", text: "METAL" },
      { value: "MADERA", text: "MADERA" },
      { value: "PLÁSTICO", text: "PLÁSTICO" },
      { value: "MIXTO", text: "MIXTO" },
    ],
  },
  {
    label: "Tipo de bien",
    placeholder: "Tipo de bien",
    name: "cf_tipobien",
    type: "dropdown",
    disabled,
    options: [
      { text: "Seleccionar tipo de bien", value: "" },
      { text: "Mueble", value: "M" },
      { text: "Equipo Eléctrico/Electrónico", value: "E" },
    ],
  },
  {
    label: "Año resguardo",
    name: "cf_anio_resguardo",
    type: "text",
    disabled,
  },
  {
    label: "Partida presupuestal ",
    name: "cve_partida",
    type: "search",
    searchFunction: getBatches,
    disabled,
    width: 415,
  },

  {
    label: "Descripción partida presupuestal",
    name: "desc_partida",
    type: "text",
    disabled: true,
    width: 415,
  },
  {
    label: "No de cuenta",
    name: "cf_no_cuenta",
    type: "text",
    disabled: true,
  },
  {
    label: "No de subcuenta",
    name: "cf_no_scuenta",
    type: "text",
    disabled: true,
  },
  {
    label: "Proveedor",
    name: "cf_cve_prove",
    type: "search",
    searchFunction: getProviders,
    width: 415,
    disabled,
  },
  {
    label: "No. Factura",
    name: "cf_num_factura",
    type: "text",
    disabled,
  },
  {
    label: "Fecha de la factura",
    name: "cf_fecha_factura",
    type: "date",
    disabled,
  },
  {
    label: "Fecha recepción Factura DCI",
    name: "cf_fecha_recepcion_factura",
    type: "date",
    disabled,
  },
  {
    label: "No. De remisión",
    name: "cf_no_remision",
    type: "text",
    disabled,
  },
  {
    label: "Fecha de la remisión",
    name: "cf_fecha_remision",
    type: "date",
    disabled,
  },
  {
    label: "Fecha Recep. Remisión DCI",
    name: "cf_fecha_recepcion_remision",
    type: "date",
    disabled,
  },
  {
    label: "Importe",
    name: "cf_importe",
    type: "text",
    isPrice: true,
    disabled,
  },
  {
    label: "No. De póliza",
    name: "cf_no_poliza",
    type: "text",
    disabled,
  },
  {
    label: "Fecha de póliza",
    name: "cf_fecha_poliza",
    type: "date",
    disabled,
  },
  {
    label: "Tipo de póliza",
    placeholder: "Tipo de póliza",
    name: "cf_tipo_poliza",
    type: "text",
    disabled,
  },
  {
    label: "Conciliado",
    name: "cf_conciliado",
    type: "dropdown",
    disabled: true,
    options: [
      { text: "Seleccione un tipo", value: "" },
      { text: "Si", value: "S" },
      { text: "No", value: "N" },
      { text: "No requiere", value: "R" },
    ],
  },
  {
    label: "Fecha de captura",
    name: "asset_acquisition_fecha_actualizacion",
    type: "date",
    disabled: true,
  },
  {
    label: "Medio de adquisición",
    name: "cf_medioadquisicion",
    type: "dropdown",
    disabled,
    options: [
      { text: "PEDIDO", value: "PEDIDO" },
      { text: "CONTRATO", value: "CONTRATO" },
      { text: "FONDO REVOLVENTE", value: "FONDO REVOLVENTE" },
      { text: "DONACIÓN", value: "DONACIÓN" },
      { text: "ACTA", value: "ACTA" },
    ],
  },
  {
    label: "Fecha de depreciación",
    name: "fecha_depreciacion",
    type: "date",
    disabled: true,
    disabled,
  },
];

// Inputs datos financieros Search
export const finantialInputsSearch = (disabled, providers, partidas) => [
  {
    label: "Fecha de inventario",
    name: "cf_fecinv",
    type: "text",
  },
  {
    label: "Tipo de material",
    name: "cf_inve_material_type",
    type: "dropdown",
    disabled,
    options: [
      { value: "METAL", text: "METAL" },
      { value: "MADERA", text: "MADERA" },
      { value: "PLÁSTICO", text: "PLÁSTICO" },
      { value: "MIXTO", text: "MIXTO" },
    ],
  },
  {
    label: "Tipo de bien",
    placeholder: "Tipo de bien",
    name: "cf_tipobien",
    type: "dropdown",
    disabled,
    options: [
      { text: "Seleccionar tipo de bien", value: "" },
      { text: "Mueble", value: "M" },
      { text: "Equipo Eléctrico/Electrónico", value: "E" },
    ],
  },
  {
    label: "Año resguardo",
    name: "cf_anio_resguardo",
    type: "text",
  },
  {
    label: "Partida presupuestal",
    name: "cve_partida",
    type: "search",
    width: 415,
    disabled: false,
    options: partidas,
    searchFunction: getBatches,
  },
  {
    label: "Descripción partida presupuestal",
    name: "desc_partida",
    type: "text",
  },
  {
    label: "No de cuenta",
    name: "cf_no_cuenta",
    type: "text",
  },
  {
    label: "No de subcuenta",
    name: "cf_no_scuenta",
    type: "text",
  },
  {
    label: "Proveedor",
    name: "cf_cve_prove",
    type: "search",
    width: 415,
    disabled: false,
    // options: providers,
    searchFunction: getProviders,
  },
  {
    label: "No. Factura",
    name: "cf_num_factura",
    type: "text",
  },
  {
    label: "Fecha de la factura",
    name: "cf_fecha_factura",
    type: "text",
  },
  {
    label: "Fecha recepción Factura DCI",
    name: "cf_fecha_recepcion_factura",
    type: "text",
  },
  {
    label: "No. De remisión",
    name: "cf_no_remision",
    type: "text",
  },
  {
    label: "Fecha de la remisión",
    name: "cf_fecha_remision",
    type: "text",
  },
  {
    label: "Fecha Recep. Remisión DCI",
    name: "cf_fecha_recepcion_remision",
    type: "text",
  },
  {
    label: "Importe",
    name: "cf_importe",
    type: "text",
  },
  {
    label: "No. De póliza",
    name: "cf_no_poliza",
    type: "text",
  },
  {
    label: "Fecha de póliza",
    name: "cf_fecha_poliza",
    type: "text",
  },
  {
    label: "Tipo de póliza",
    placeholder: "Tipo de póliza",
    name: "cf_tipo_poliza",
    type: "text",
  },
  {
    label: "Conciliado",
    name: "cf_conciliado",
    type: "dropdown",
    disabled: true,
    options: [
      { text: "Seleccione un tipo", value: "" },
      { text: "Si", value: "S" },
      { text: "No", value: "N" },
      { text: "No requiere", value: "R" },
    ],
  },
  {
    label: "Fecha de captura",
    name: "asset_acquisition_fecha_actualizacion",
    type: "text",
  },
  {
    label: "Medio de adquisición",
    name: "cf_medioadquisicion",
    type: "dropdown",
    disabled,
    options: [
      { text: "PEDIDO", value: "PEDIDO" },
      { text: "CONTRATO", value: "CONTRATO" },
      { text: "FONDO REVOLVENTE", value: "FONDO REVOLVENTE" },
      { text: "DONACIÓN", value: "DONACIÓN" },
      { text: "ACTA", value: "ACTA" },
    ],
  },
];

// REPORT TAB FORM

export const reportStockInputs = [
  {
    label: "NS Recepción",
    name: "cf_ns_recepcion",
  },
  {
    label: "Clave NS",
    name: "cf_clavens",
  },
  {
    label: "Clasificación SICAF",
    name: "cf_clasificacion_sicaf",
  },
  {
    label: "Tipo de registro",
    name: "cf_tipo_registro",
  },
  {
    label: "En inventario",
    name: "cf_eninventario",
  },
  {
    label: "Seguros",
    name: "cf_seguros",
  },
  {
    label: "B-Seguros",
    name: "cf_bseguros",
  },
  {
    label: "A-Contabilidad",
    name: "cf_acontabilidad",
  },
  {
    label: "B-Contabilidad",
    name: "cf_bcontabilidad",
  },
  {
    label: "R-PNT",
    name: "cf_rpnt",
  },
  {
    label: "B-PNT",
    name: "cf_bpnt",
  },
  {
    label: "Libro inv.",
    name: "cf_libroinv",
  },
  {
    label: "Inf. Reportada",
    name: "cf_infreportada",
  },
  {
    label: "MISED",
    name: "cf_mised",
  },

  {
    label: "CONTENIDOS",
    name: "contenidos",
  },
  {
    label: "MANTENIMIENTO",
    name: "mantenimiento",
  },
];

export const last_seen_columns = (onEye) => [
  {
    dataField: "created_at",
    text: "Fecha de creación",
    sort: false,
    formatter: (cell, row, rowIndex) => setFormatDate(cell, "PPP"),
  },
  {
    dataField: "changed_by",
    text: "Usuario",
    sort: false,
    formatter: (cell, row, rowIndex) => cell?.username,
  },
  {
    dataField: "in_area",
    text: "Área dónde se encontro",
    sort: false,
  },
  {
    dataField: "inventory_take",
    text: "Acciones",
    classes: "text-center",
    headerClasses: "text-center",
    formatter: columnFormatters.StockLastSeenActionsColumnFormatter,
    formatExtraData: { onEye },
  },
];

export const last_scanned_columns = (onEye) => [
  {
    dataField: "fecha_levantamiento",
    text: "Fecha de levantamiento",
    sort: false,
    formatter: (cell, row, rowIndex) => setFormatDate(cell, "PPP"),
  },
  {
    dataField: "usuario",
    text: "Usuario",
    sort: false,
    formatter: (cell, row, rowIndex) => cell,
  },
  {
    dataField: "areas_cve",
    text: "Área dónde se escaneo",
    sort: false,
    formatter: columnFormatters.StockAreasCveColumnFormatter,
  },
  {
    dataField: "id",
    text: "Acciones",
    classes: "text-center",
    headerClasses: "text-center",
    formatter: columnFormatters.StockLastSeenActionsColumnFormatter,
    formatExtraData: { onEye },
  },
];

export const inputsLabelNameToCopy = [
  { label: "Motivo de baja", name: "motivo_baja" },
  { label: "Tipo de baja", name: "tipo_baja_descripcion" },
  { label: "Fecha de baja", name: "fecha_baja" },
  {
    label: "Fecha de inventario",
    name: "cf_fecinv",
  },
  {
    label: "No. de Folio",
    name: "num_folio",
  },
  {
    label: "Tipo de material",
    name: "cf_inve_material_type",
  },
  {
    label: "Tipo de bien",
    name: "cf_tipobien",
  },
  {
    label: "Año resguardo",
    name: "cf_anio_resguardo",
  },
  {
    label: "Partida presupuestal",
    name: "cve_partida",
  },
  {
    label: "Descripción partida presupuestal",
    name: "desc_partida",
  },
  {
    label: "No de cuenta",
    name: "cf_no_cuenta",
  },
  {
    label: "No de subcuenta",
    name: "cf_no_scuenta",
  },
  {
    label: "Proveedor",
    name: "cf_cve_prove",
  },
  {
    label: "No. Factura",
    name: "cf_num_factura",
  },
  {
    label: "Fecha de la factura",
    name: "cf_fecha_factura",
  },
  {
    label: "Fecha recepción Factura DCI",
    name: "cf_fecha_recepcion_factura",
  },
  {
    label: "No. De remisión",
    name: "reference_number",
  },
  {
    label: "Fecha de la remisión",
    name: "reference_date",
  },
  {
    label: "Fecha Recep. Remisión DCI",
    name: "cf_fecha_recepcion_remision",
  },
  {
    label: "Importe",
    name: "cf_importe",
  },
  {
    label: "No. De póliza",
    name: "cf_no_poliza",
  },
  {
    label: "Fecha de póliza",
    name: "cf_fecha_poliza",
  },
  {
    label: "Tipo de póliza",
    name: "cf_tipo_poliza",
  },
  {
    label: "Conciliado",
    name: "cf_conciliado",
  },
  {
    label: "Fecha de captura",
    name: "asset_acquisition_fecha_actualizacion",
  },
  {
    label: "Medio de adquisición",
    name: "cf_medioadquisicion",
  },
  {
    label: "No. de inventario",
    name: "no_inventa",
  },
  {
    label: "Fecha de depreciación",
    name: "fecha_depreciacion",
  },
  {
    label: "Folio",
    name: "No. de Folio",
  },
  {
    label: "No. Inventario Informático",
    name: "cf_no_inventa",
  },
  {
    label: "No. Inv. Anterior",
    name: "cf_num_inventario_anterior",
  },
  {
    label: "Tipo de etiqueta",
    name: "cf_label_type",
  },
  {
    label: "Estado de etiqueta",
    name: "cf_estatus_etiqueta",
  },
  {
    label: "Ingreso",
    name: "cf_ingreso",
  },
  {
    label: "CABMS Grupo",
    name: "cabms_grupo",
  },
  {
    label: "CABMS Subgrupo",
    name: "cabms_subgrupo",
  },
  {
    label: "CABMS Clase",
    name: "cabms_clase",
  },
  {
    label: "CABMS Subclase",
    name: "cabms_subclase",
  },
  {
    label: "CABMS Tipo",
    name: "cabms_tipo",
  },
  {
    label: "Clave CABMS",
    name: "cabms_key",
  },
  {
    label: "Artículo CABMS",
    name: "cabms_item",
  },
  {
    label: "Clave de Artículo",
    name: "key",
  },
  {
    label: "Artículo",
    name: "cf_articulo",
  },
  {
    label: "Descripción",
    name: "cf_descripcion",
  },
  {
    label: "Marca",
    name: "cf_marca",
  },
  {
    label: "Modelo",
    name: "cf_modelo",
  },
  {
    label: "Serie",
    name: "cf_serie",
  },
  {
    label: "Condición de uso",
    name: "cf_condicion_uso",
  },
  {
    label: "Condición de operación",
    name: "cf_descripcion_uso",
  },
  {
    label: "Clasificación",
    name: "cf_clasificacion",
  },
  {
    label: "Año de localización",
    name: "cf_aniolocalizacion",
  },
  {
    label: "Fecha de actualización",
    name: "cf_fecha_actuali",
  },
  {
    label: "Clave de usuario ant",
    name: "cf_usuario",
  },
  {
    label: "NS Recepción",
    name: "cf_ns_recepcion",
  },
  {
    label: "Clave NS",
    name: "cf_clavens",
  },
  {
    label: "Clasificación SICAF",
    name: "cf_clasificacion_sicaf",
  },
  {
    label: "Tipo de registro",
    name: "cf_tipo_registro",
  },
  {
    label: "En inventario",
    name: "cf_eninventario",
  },
  {
    label: "Seguros",
    name: "cf_seguros",
  },
  {
    label: "B-Seguros",
    name: "cf_bseguros",
  },
  {
    label: "A-Contabilidad",
    name: "cf_acontabilidad",
  },
  {
    label: "B-Contabilidad",
    name: "cf_bcontabilidad",
  },
  {
    label: "R-PNT",
    name: "cf_rpnt",
  },
  {
    label: "B-PNT",
    name: "cf_bpnt",
  },
  {
    label: "Libro inv.",
    name: "cf_libroinv",
  },
  {
    label: "Inf. Reportada",
    name: "cf_infreportada",
  },
  {
    label: "MISED",
    name: "cf_mised",
  },
  {
    label: "CONTENIDOS",
    name: "contenidos",
  },
  {
    label: "MANTENIMIENTO",
    name: "mantenimiento",
  },
  {
    label: "Edificio",
    name: "relation_area_edificio__nom_edificio",
  },
  {
    label: "Edificio",
    name: "relation_area_edificio__cve_edificio",
  },
  {
    label: "Piso",
    name: "relation_area_piso",
  },
  {
    label: "Oficina",
    name: "relation_area_oficina",
  },
  {
    label: "Suboficina",
    name: "relation_area_soficina",
  },
  {
    label: "Grupo",
    name: "relation_area_area_grupo",
  },
  {
    label: "Subgrupo",
    name: "relation_area_area_subgrupo",
  },
  {
    label: "Subsubgrupo",
    name: "relation_area_area_subsubgrupo",
  },
  {
    label: "Clave área",
    name: "cf_cve_area",
  },
  {
    label: "Área resguardante",
    name: "relation_area_nom_area",
  },
  {
    label: "Subárea",
    name: "relation_area_nom_sarea",
  },
  {
    label: "Subsubárea",
    name: "relation_area_nom_ssarea",
  },
  {
    label: "Área resguardante",
    name: "nom_area",
  },
  {
    label: "Subárea",
    name: "nom_sarea",
  },
  {
    label: "Subsubárea",
    name: "nom_ssarea",
  },
  {
    label: "No. de Resguardo",
    name: "relation_area_num_resguardo",
  },
  {
    label: "No. de Resguardo",
    name: "num_resguardo",
  },
  {
    label: "Fecha de resguardo",
    name: "cf_fecha_resguardo",
  },
  {
    label: "Fecha Últ. Imp. de Resg.",
    name: "cf_fecha_impr_resguardo",
  },
  {
    label: "Responsables",
    name: "cf_responsables",
  },
  {
    label: "Observaciones",
    name: "cf_observaciones",
  },
  {
    label: "Fecha Últ. Imp. de Resg.",
    name: "last_shetler_print",
  },
  {
    label: "Número de remisión",
    name: "cf_no_remision",
  },
  {
    label: "Fecha de la remisión",
    name: "cf_fecha_remision",
  },
  {
    label: "Importe",
    name: "cf_importe",
  },
];
