import React from "react";
import Chip from "../../components/Chip";
import { useSelector } from "react-redux";

const UserInfoChip = () => {
  const { user } = useSelector(store => store.auth);
  return (
      <Chip style={{ width:'100%', marginRight: '1rem', textTransform: 'uppercase' }} color="#3699FF" label={ `${user?.alm_area?.area} - ${user?.alm_area?.titular}` } />
    );
};

export default UserInfoChip;
