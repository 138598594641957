import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import uuid from "react-uuid";
import {
  setArticle,
  setArticlesIds,
  setNewArticleForm,
  setSearchArticleForm,
} from "../../../redux/ducks/articles.duck";
import ArticlesForm from "./ArticlesForm";
import { useSearchMode } from "../../hooks/useSearchMode";
import { validateIntValules } from "../../utils/articlesData";
import { isAValidAmount, isAValidInteger } from "../../utils/inputValidations";
import { getOneArticle, postArticleZpl } from "../../crud/articlesCrud";
import notice from "../../components/Notice";
import BaseModal from "../../components/BaseModal";
import CustomButton from "../../components/CustomButton";
import { zplRequest } from "../../utils/zplRequest";
import { setLoader } from "../../../redux/ducks/loader.duck";

const ArticlesDetail = () => {
  const {
    selectedArticle,
    searchArticleForm,
    newArticleForm,
    selectedArticleIds,
  } = useSelector((state) => state.article);
  const { isSearchPage, isResultsPage } = useSelector((store) => store.pathname);
  const { authToken } = useSelector((store) => store.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [labelModal, setLabelModal] = useState("");

  useSearchMode("/inventario-bienes-almacen-general/articulos/busqueda");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentSetArticleFormFunction = isSearchPage
    ? setSearchArticleForm
    : isResultsPage
    ? setArticle
    : setNewArticleForm;
  const currentArticleForm = isSearchPage
    ? searchArticleForm
    : isResultsPage
    ? selectedArticle
    : newArticleForm;

  useEffect(() => {
    if (id && isResultsPage) {
      dispatch(setLoader(true));
      getOneArticle(id)
        .then((res) => {
          if (res.status === 200 && res.data?.id) {
            dispatch(
              currentSetArticleFormFunction({
                ...res.data,
                cf_variants1: [],
                cf_variants2: [],
                partida_presu: {
                  id: res.data.detalle_partida?.id,
                  text: res.data.detalle_partida?.desc_partida,
                  text2: res.data.detalle_partida?.num_partida,
                },
                iva_unitario: Number(res.data.iva_unitario) > 0 ? 1 : 0,
              })
            );

            selectedArticleIds.length === 0 && dispatch(setArticlesIds([res.data.id]));
          }
        })
        .catch((error) => {
          history.push("/inventario-bienes-almacen-general/articulos/busqueda");
          console.log("err", error);
          notice(
            "No se ha podido obtener el artículo, intenta realizando otra búsqueda",
            "error"
          );
        })
        .finally(() => dispatch(setLoader(false)));
    }
  }, [
    currentSetArticleFormFunction,
    dispatch,
    history,
    id,
    isResultsPage,
    selectedArticleIds.length,
  ]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    let newData;
    if (name === "cf_cve_partida") {
      newData = { ...currentArticleForm, [name]: value.text2 };
    } else if (validateIntValules.includes(name)) {
      if (isAValidAmount(value)) newData = { ...currentArticleForm, [name]: value };
    } else if (name === "cve_articulo") {
      if (isAValidInteger(value)) newData = { ...currentArticleForm, [name]: value };
    } else {
      newData = { ...currentArticleForm, [name]: value };
    }
    newData && dispatch(currentSetArticleFormFunction(newData));
  };

  const handleAddRow = (name) => {
    dispatch(
      currentSetArticleFormFunction({
        ...currentArticleForm,
        [name]: [
          ...currentArticleForm[name],
          { id: uuid(), cf_variant_type: "", delete: true },
        ],
      })
    );
  };

  const handleRemoveRow = (item, name) => {
    const updated = currentArticleForm[name].filter((x) => x.id !== item.id);
    dispatch(currentSetArticleFormFunction({ ...currentArticleForm, [name]: updated }));
  };

  const handleChangeRow = (target, property, index) => {
    const { value, name } = target;
    let newData = currentArticleForm[property];
    if (property === "presentaciones") {
      newData[index][name] = value;
    } else newData[index][name] = value;

    const updated = { ...currentArticleForm, [property]: newData };

    dispatch(currentSetArticleFormFunction(updated));
  };

  const handleAddPresentation = () => {
    const presentaciones = [
      ...currentArticleForm.presentaciones,
      {
        id: uuid(),
        descripcion: "",
        equivalencia: "",
        estatus: false,
      },
    ];
    dispatch(currentSetArticleFormFunction({ ...currentArticleForm, presentaciones }));
  };

  const onLabelClick = () => {
    return postArticleZpl(currentArticleForm.id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data)
            return zplRequest(res.data, "2x4")
              .then(async (res) => {
                const blob = await res.blob();
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function() {
                  let base64data = reader.result;
                  base64data && setLabelModal(base64data);
                };
              })
              .catch(console.log);
          else notice("No hay etiquetas pendientes", "info");
        }
      })
      .catch((err) => {
        console.log("err", err);
        notice("No se ha podido obtener la Etiqueta desde el servidor");
      });
  };

  const printZpl = () => {
    const a = document.createElement("a");
    a.href = `nextwmsftp://?token=${authToken}?ids=${id}?type=article`;
    a.click();
    setLabelModal("");
  };

  return (
    <>
      <ArticlesForm
        formValues={currentArticleForm}
        handleChange={handleChange}
        isSearchPage={isSearchPage}
        handleChangeRow={handleChangeRow}
        addRow={handleAddRow}
        handleRemoveItem={handleRemoveRow}
        addPresentation={handleAddPresentation}
        onLabelClick={onLabelClick}
      />
      <BaseModal
        title={
          <span>
            Etiqueta del artículo <strong>{currentArticleForm?.cve_articulo}</strong>
          </span>
        }
        open={Boolean(labelModal)}
        onClose={() => setLabelModal("")}
        onConfirm={printZpl}
        confirmLabel="Imprimir"
        onCancel={() => setLabelModal("")}
        cancelLabel="Cerrar"
        maxWidth="sm"
        color="secondary"
        disableOnConfirm={false}
      >
        <div className="text-center">
          <div style={{ height: 300, overflow: "hidden" }}>
            <img className="img-fluid" src={labelModal} alt="label" />
          </div>
          <div className="d-flex justify-content-center m-5">
            <CustomButton
              onClick={printZpl}
              title="Imprimir etiquetas"
              color="secondary"
            />
          </div>
        </div>
      </BaseModal>
    </>
  );
};

export default ArticlesDetail;
