import React, { useEffect } from "react";
import UtilInputs from "./UtilInputs";
import { artWorkDescriptionTabInputs } from "../utils/artWorksData";
import { toggleEnableInputs } from "../utils/newEntryData";
import { useSelector } from "react-redux";
import { getArtworkTypes } from "../crud/catalogsCrud";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const ArtWorkDescriptionTab = ({ formValues, onValuesChange }) => {
  const classes = useStyles();
  const [artworkTypesOptions, setArtworkTypesOptions] = React.useState([]);

  useEffect(() => {
    getArtworkTypes()
      .then((response) => setArtworkTypesOptions(response))
      .catch((e) => console.log(e));
  }, []);
  const { currentMode } = useSelector((store) => store.subHeaderActions);
  const { isResultsPage, isNewPage } = useSelector((store) => store.pathname);

  const URL = () => {
    if (isNewPage) {
      return false;
    } else if (isResultsPage) {
      return formValues?.image_artwork
        ? "undefined" === typeof formValues?.image_artwork?.url
          ? formValues?.image_artwork
          : formValues?.image_artwork?.url
        : null;
    } else {
      return false;
    }
  };

  if (true) {
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} style={{ minWidth: 700 }}>
            <UtilInputs
              formValues={formValues}
              inputValues={
                !currentMode
                  ? toggleEnableInputs(
                      artWorkDescriptionTabInputs(artworkTypesOptions),
                      false
                    )
                  : artWorkDescriptionTabInputs(artworkTypesOptions)
              }
              onValuesChange={onValuesChange}
            />
          </Grid>
          {URL() === false ? null : (
            <Grid item md={12} lg={6}>
              <img
                // className="img-fluid"
                src={URL()}
                style={{
                  marginTop: 70,
                  height: "auto",
                  //minHeight: 200,
                  minWidth: 300,
                  maxWidth: 700,
                  alignSelf: "center",
                  margin: "auto",
                }}
                alt={formValues?.image_artwork?.title}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }

  return (
    <div className="d-flex flex-wrap">
      <div style={{ maxWidth: 700 }}>
        <UtilInputs
          formValues={formValues}
          inputValues={
            !currentMode
              ? toggleEnableInputs(
                  artWorkDescriptionTabInputs(artworkTypesOptions),
                  false
                )
              : artWorkDescriptionTabInputs(artworkTypesOptions)
          }
          onValuesChange={onValuesChange}
        />
      </div>
      {URL() === false ? null : (
        <img
          className="img-fluid"
          src={URL()}
          style={{
            marginTop: 70,
            height: 350,
            width: 450,
            alignSelf: "center",
            margin: "auto",
          }}
          alt={formValues?.image_artwork?.title}
        />
      )}
    </div>
  );
};

export default ArtWorkDescriptionTab;
