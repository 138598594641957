import React from 'react'
import "../styles/_departureStatus.scss"
import { columnStatusCssClasses } from "../../utils/columnFormattersHelpers";

export const PledgegGoodsStatusColumnFormatter = (cellContent, row) => {

  return (
    <span
      className={`badge badge-pill badge-${
        columnStatusCssClasses[cellContent === "B" ? 0 : 1]
      }`}
		>
			{cellContent === "B" ? "Baja" : "Activo"}
		</span>
  )
}
