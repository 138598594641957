import React from 'react'
// import BoardColumnIcon from './list-icons/BoardColumnIcon'
import EyeColumnIcon from './list-icons/EyeColumnIcon'

export const ToDoFinishActionsColumn = (value, row, rowIndex, { onEye, onReturnToBoard }) => {
  return (
    <>
      <EyeColumnIcon onClick={() => onEye(value)} tooltip='Ver Detalles' />
      {/* <BoardColumnIcon onClick={() => onReturnToBoard(value)} tooltip='Regresar al tablero' /> */}
    </>
  )
}

