import {
  ASSTETS_STOCK,
  CLEAN_URL,
  ENTRANCE_URL,
  GASTOS_URL,
  ORDINARY_URL,
  RECURRENT_URL,
  REVOLVENTE_URL,
  UPLOAD_FILE_URL,
} from "./helpers/routes";
import { getParams, handleResponse, handleError } from "./helpers/validate";
import axios from "axios";
export const getEntranceList = (page = 1, limit = 20, params = "") => {
  return fetch(`${ENTRANCE_URL}?page=${page}&limit=${limit}&${params}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const getEntranceDetail = (id) => {
  return fetch(`${ENTRANCE_URL}/${id}/`, getParams("GET"))
    .then(handleResponse)
    .then((res) => ({
      ...res,
      contract_number: res.contract_id,
      supplier: {
        id: res.cf_no_proveedor,
        text: res.proveedor_razon_social,
        text2: res.cf_no_proveedor,
      },
      requisition_item_details: [],
    }))
    .catch(handleError);
};

export const createEntrance = (data) => {
  return fetch(
    `${
      data.tipo_entrada === "STOCK"
        ? ENTRANCE_URL
        : data.tipo_entrada === "RECURRENTE"
        ? RECURRENT_URL
        : data.tipo_entrada === "LIMPIEZA"
        ? CLEAN_URL
        : data.tipo_entrada === "FONDOREVOLVENTE"
        ? REVOLVENTE_URL
        : data.tipo_entrada === "GASTOSCOMPROBAR"
        ? GASTOS_URL
        : ORDINARY_URL
    }/`,
    getParams("POST", data)
  )
    .then(handleResponse)
    .catch(handleError);
};

export const closeEntrance = (data) => {
  return fetch(
    `${
      data.tipo_entrada === "LIMPIEZA"
        ? CLEAN_URL
        : data.tipo_entrada === "RECURRENTE"
        ? RECURRENT_URL
        : data.tipo_entrada === "ORDINARIA" ||
          data.tipo_entrada === "FONDOREVOLVENTE" ||
          data.tipo_entrada === "GASTOSCOMPROBAR"
        ? ORDINARY_URL
        : ENTRANCE_URL
    }/${data.id}/`,
    getParams("PATCH", data)
  )
    .then(handleResponse)
    .catch(handleError);
};

export const updateQuantityStock = (id, data) => {
  return fetch(
    `${ASSTETS_STOCK}/entry-detail-update-stock/${id}`,
    getParams("PATCH", data)
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getEntranceRequirementItems = (id) => {
  return fetch(`${ASSTETS_STOCK}/entry-requirement-items/${id}`, getParams("GET"))
    .then(handleResponse)
    .then(
      ({
        requisition_item_details,
        total_entry_delivered_items_amount,
        total_requirement_items_amount,
      }) => ({
        requisition_item_details,
        total_entry_delivered_items_amount,
        total_requirement_items_amount,
      })
    )
    .catch(handleError);
};

export const addEntryFilePDFAlmacen = (
  file = null,
  attach_id,
  title = "",
  attach_model,
  field
) => {
  const body = {
    attach_id,
    attach_model: "warehouse_entry",
    image_data: file,
    field,
    title,
  };

  const formData = new FormData();
  for (const name in body) {
    formData.append(name, body[name]);
  }
  return axios.post(UPLOAD_FILE_URL, formData);
};
