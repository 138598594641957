import * as columnFormatters from "../components/column-formatters/UsersActionsColumnFormatter";
import * as statusColumnFormatters from "../components/column-formatters/UserStatusColumnFormatter";
import { getActiveAdscriptions } from "../crud/usersCrud";

/**
 * It returns an array of objects that will be used to create the table columns
 * @param onEye - function to be called when the eye icon is clicked
 * @param mode - string
 */
export const columns = (onEye, mode) => mode === 'bienes-instrumentales' 
  ? [
  {
    dataField: "alm_area",
    text: "Area",
    sort: true,
  },
  {
    dataField: "username",
    text: "Usuario",
    sort: true,
  },
  {
    dataField: "first_name",
    text: "Nombre",
    sort: true,
  },
  {
    dataField: "last_name",
    text: "Apellido",
    sort: true,
  },
  {
    dataField: "email",
    text: "Correo",
    sort: true,
  },
  {
    dataField: "groups",
    text: "Grupo",
    sort: true,
    formatter: (groups) => groups[0],
  },
  {
    dataField: "is_active",
    text: "Estatus",
    sort: true,
    formatter: statusColumnFormatters.UserStatusColumnFormatter,
  },
  {
    dataField: "id",
    text: "Acciones",
    formatter: columnFormatters.UsersActionsColumnFormatter,
    formatExtraData: {
      onEye,
    },
  },
  ] : [
  {
    dataField: "username",
    text: "Usuario",
    sort: false,
  },
  {
    dataField: "adscripcion",
    text: "Adscripción",
    sort: false,
  },
  {
    dataField: "fullname",
    text: "Titular",
    sort: false,
  },
  {
    dataField: "area",
    text: "Area",
    sort: false,
  },
  {
    dataField: "is_active",
    text: "Estatus",
    sort: false,
    formatter: statusColumnFormatters.UserStatusColumnFormatter,
  },
  {
    dataField: "groups",
    text: "Grupo",
    sort: false,
    formatter: (groups) => groups[0],
  },
  {
    dataField: "id",
    text: "Acciones",
    formatter: columnFormatters.UsersActionsColumnFormatter,
    formatExtraData: {
      onEye,
    },
  },
];

export const defaultFilter = {
  alm_area: "",
  adscripcion: '',
  username: '',
  titular: '',
};

export const filterInputs = (mode) => [
  {
    label: mode === 'bienes-instrumentales' ? "Área de almacen" : 'Adscripción',
    name: mode === 'bienes-instrumentales' ? "alm_area" : 'adscripcion',
    type: "text",
  },
  {
    label: "Usuario",
    name: "username",
    type: "text",
  },
  mode !== 'bienes-instrumentales' && {
    label: "Titular",
    name: "titular",
    type: "text",
  }
];
/**
 * It returns an array of objects that are used to create the form inputs
 * @param isInstrumentalGoodsPage - boolean
 * @param [disabled=false] - boolean
 */
export const formInputs = (isInstrumentalGoodsPage, disabled = false) => [
  {
    label: "Usuario",
    placeholder: "Usuario",
    name: "username",
    type: "text",
  },
  isInstrumentalGoodsPage && {
    label: "Nombre",
    placeholder: "Nombre",
    name: "first_name",
    type: "text",
  },
  isInstrumentalGoodsPage && {
    label: "Apellido",
    placeholder: "Apellido",
    name: "last_name",
    type: "text",
  },
  !isInstrumentalGoodsPage && {
    label: "Adscripción",
    name: "alm_area",
    type: "search",
    searchFunction: getActiveAdscriptions,
    disabled: disabled,
  },
  !isInstrumentalGoodsPage && {
    label: "Titular",
    name: "titular",
    type: "text",
    disabled: true,
  },
  {
    label: "Contraseña",
    placeholder: "Contraseña",
    name: "password",
    password: true,
    confirm: "password_confirm",
    type: "text",
  },
  {
    label: "Confirmar contraseña",
    placeholder: "Confirmar contraseña",
    name: "password_confirm",
  },
  {
    label: "Estado",
    name: "is_active",
    type: "dropdown",
    options: [
      { value: true, text: "Activo" },
      { value: false, text: "Baja" },
    ],
  },
];

/**
 * It returns an array of objects, each object representing a form input
 * @param isInstrumentalGoodsPage - boolean
 */
export const formInputsMe = (isInstrumentalGoodsPage) => [
  {
    label: "Usuario",
    placeholder: "Usuario",
    name: "username",
    type: "text",
    disabled: true,
  },
  isInstrumentalGoodsPage && {
    label: "Nombre",
    placeholder: "Nombre",
    name: "first_name",
    type: "text",
    disabled: true,
  },
  isInstrumentalGoodsPage && {
    label: "Apellido",
    placeholder: "Apellido",
    name: "last_name",
    type: "text",
    disabled: true,
  },
  !isInstrumentalGoodsPage && {
    label: "Titular",
    name: "titular",
    type: "text",
    disabled: true,
  },
  !isInstrumentalGoodsPage && {
    label: "Área de almacén",
    name: "alm_area",
    type: "text",
    disabled: true,
  },
  {
    label: "Contraseña Anterior",
    placeholder: "Contraseña Anterior",
    name: "old_password",
    password: true,
    type: "text",
    showLowerCase: true,
  },
  {
    label: "Contraseña",
    placeholder: "Contraseña",
    name: "new_password1",
    password: true,
    type: "text",
    showLowerCase: true,
  },
  {
    label: "Confirmar Contraseña",
    placeholder: "Confirmar Contraseña",
    name: "new_password2",
    password: true,
    type: "text",
    showLowerCase: true,
  },
  {
    label: "Estado",
    name: "is_active",
    disabled: true,
    type: "dropdown",
    display: false,
    options: [
      { value: true, text: "Activo" },
      { value: false, text: "Baja" },
    ],
  },
];
