import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const SplashLoader = ({ title="Cargando..."}) => {
	const { loader } = useSelector(state => state.loader)
  return (
		loader && <div style={{
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: 0,
			zIndex: 99999,
			backgroundColor: 'rgba(255,255,255,0.5)',
		}}>
			<div
        className="d-flex flex-column justify-content-center align-items-center"
				style={{
					position: 'fixed',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					backgroundColor: 'white',
					padding: '20px 40px',
					borderRadius: 15,
					color: '#9A9A9A'
				}}
			>
				<p>{title}</p>
				<Spinner animation='border' role='status'>
					<span className='sr-only'>Loading...</span>
				</Spinner>
			</div>
		</div>
	);
};

export default SplashLoader;