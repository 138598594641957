import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Typography } from "@material-ui/core";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import Pagination from "./Pagination";
import UtilInputs from "./UtilInputs";
import notice from "./Notice";
import Icon from "./Icon";
import BaseModal from "./BaseModal";
import {
  windowActionTypes,
  setFormValues,
  // setPaginateInfo,
  setResults,
  setGroupValues,
  windowSelector,
  setViewType,
  setQueryToSearch,
  setWindowFormAvailableToCopy,
  setPureResults,
  setPureInputValues,
  setPureWindowFormAvailableToCopy,
} from "../../redux/ducks/window.duck";
import {
  cleanObject,
  cleanObjectWithZero,
  clearEmptyStringsObject,
  filterParams,
} from "../utils/objectsFunctions";
import {
  copyFeatureValidCatalogs,
  icons,
  iconSignature,
  inputsCatalogAreasLabelNameToCopy,
} from "../utils/catalogsData";
import TabButton from "./TabButton";
import { isAValidInteger } from "../utils/inputValidations";
import {
  crearCatalogoCabms,
  editCatalogoCabms,
  eliminarCatalogoCabms,
  crearCatalogoArea,
  editCatalogoArea,
  eliminarCatalogoArea,
  crearEdificio,
  editarEdificio,
  eliminarEdificio,
  crearResponsable,
  editarResponsable,
  eliminarResponsable,
  crearMarca,
  editarMarca,
  eliminarMarca,
  crearSubmarca,
  editarSubmarca,
  eliminarSubmarca,
  getGroups,
  getSubGroups,
  getSubSubGroups,
  getSupplierPDF,
  getSupplierExcel,
  getCostCenterReport,
  downloadCatalogReport,
  getSecondmentReport,
  downloadCatalogPdfReport,
  supplierCRUD,
  signatureCRUD,
  formatResponsableObject,
  partidaCrud,
  getMultiplePDF,
} from "../crud/catalogsCrud";
import { setLoader } from "../../redux/ducks/loader.duck";
import { getBase64 } from "../utils/files";
import CustomButton from "./CustomButton";
import CheckboxList from "./CheckboxList";
import {
  updateMultipleAreasFields,
  getFilterAreasDataById,
  getAttachmentAreas,
} from "../crud/areasFilterCrud";
import DigitalizationListDocs from "../pages/DigitalVault/components/common/DigitalizationListDocs";
import "./styles/_signature.scss";
import { CATALOGS_URL, INSTRUMENTAL_GOODS } from "../crud/helpers/routes";
import { getParams, handleResponse } from "../crud/helpers/validate";
import SplashLoader from "./LoaderFilterForm";

const FilterForm = ({ NameCatalogo = null }) => {
  const { permisos, authToken } = useSelector((store) => store.auth);
  const [allAttachment, setAllAtchment] = useState();

  const [isModalToSaveArea, setIsModalToSaveArea] = useState(false);
  const {
    configuration: {
      tabs,
      activeTab: {
        id,
        hasReport,
        defaultValues,
        inputValues,
        inputs,
        results,
        ids,
        queryToSearch,
        requestList,
        viewType,
        paginationIndex,
        paginationCount,
        paginationPerPage,
        paginationPage,
        paginationTotalPage,
      },
      windowFormAvailableToCopy,
    },
  } = useSelector(windowSelector);
  const dispatch = useDispatch();
  const [openModalToCopy, setOpenModalToCopy] = useState(false);
  const [dataToCopy, setDataToCopy] = useState([]);
  const [idsResultArea, setIdsResultArea] = useState([]);
  const [loading, setLoading] = useState(false);

  let isArea =
    id === "area" && viewType === "RESULTS" && windowActionTypes.init === "INIT";

  //handlesearch
  const handleSearch = async (page = 1) => {
    dispatch(setLoader(true));
    if (viewType === windowActionTypes.init) {
      setViewType(windowActionTypes.search);
    } else {
      const values = { ...inputValues };
      if (values?.text) delete values.text;
      if (values?.id) delete values.id;
      if (id === "signature") {
        delete values.prefijo;
        delete values.estatus;
        delete values.state;
        delete values.update_at;
        delete values.created_at;
        delete values.adscripcion;
      }
      if (id === "secondments") {
        delete values.cf_cargo;
      }
      if (id === "area") {
        delete values.edificio;
      }
      let custom_responsables;
      let edificio;
      if (id === "area") {
        custom_responsables = values?.responsables?.map((res) => res?.id);
        edificio = values?.edificio_nombre.id;
      }
      let payload =
        viewType === "SEARCH"
          ? filterParams(
              cleanObjectWithZero(
                clearEmptyStringsObject(JSON.parse(JSON.stringify(values)))
              )
            )
          : "";
      if (viewType === "SEARCH" && queryToSearch === "") {
        dispatch(setQueryToSearch(payload));
      }
      payload = queryToSearch !== "" ? queryToSearch : payload;
      if (id === "area") {
        if (custom_responsables.length > 0) {
          payload = payload.concat(`&custom_responsables=${custom_responsables.join()}`);
        }
        if (edificio) {
          payload = payload.concat(`&edificio=${edificio}`);
        }
      }
      return requestList(payload, page, 1)
        .then((res) => {
          setIdsResultArea(res[2]);
          if (res[0]?.length > 0) {
            dispatch(setViewType(windowActionTypes.results));
            dispatch(setResults(res));
            dispatch(setFormValues({ ...res[0][0] }));
          } else {
            notice("No se encontraron coincidencias", "warning");
            dispatch(setQueryToSearch(""));
            dispatch(setFormValues(defaultValues));
            return;
          }
        })
        .catch((err) => {
          if (err.error === "warning") {
            notice("No se encontraron coincidencias", "warning");
          } else {
            notice(
              "Ha ocurrido un error, contacta al administrador ó intenta nuevamente"
            );
          }
          dispatch(setQueryToSearch(""));
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };
  const createResponsible = async () => {
    let formatedBody = { ...inputValues };
    formatedBody = cleanObject(formatedBody);
    formatedBody = clearEmptyStringsObject(formatedBody);

    const res = await fetch(
      `${CATALOGS_URL}/responsables-crud/`,
      getParams("POST", formatedBody)
    ).then(handleResponse);

    return res;
  };
  //? -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  const handleSearchById = (page) => {
    dispatch(setLoader(true));

    return getFilterAreasDataById(ids, page)
      .then((res) => {
        if (res[0].length > 0) {
          dispatch(setViewType(windowActionTypes.results));
          dispatch(setResults(res));
          dispatch(setFormValues({ ...res[0][0] }));
        } else {
          notice("No se encontraron coincidencias", "warning");
          dispatch(setQueryToSearch(""));
          dispatch(setFormValues(defaultValues));
        }
      })
      .catch((err) => {
        notice("Ha ocurrido un error, contacta al administrador ó intenta nuevamente");
        dispatch(setQueryToSearch(""));
      })
      .finally(() => dispatch(setLoader(false)));
  };
  //?Attchament
  useEffect(() => {
    if (results && id === "area") {
      let id = results[0]?.id;
      getAttachmentAreas(id).then((res) => {
        setAllAtchment(res);
      });
    } else if (id === "responsible" && results.length > 0) {
      if (Object.keys(results[0].all_attachments).length > 0) {
        setAllAtchment(results[0].all_attachments);
      } else {
        setAllAtchment({});
      }
    } else {
      setAllAtchment([]);
    }
  }, [results]);

  //? -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

  const clearForm = (view) => {
    dispatch(setViewType(view));
    dispatch(setFormValues(defaultValues));
  };

  const handleEdit = () => {
    dispatch(setViewType(windowActionTypes.edit));
  };

  const copyDataModal = () => {
    setOpenModalToCopy(true);
    setDataToCopy(Object.keys(windowFormAvailableToCopy));
  };

  const onChangeDataToCopy = (data) => {
    setDataToCopy(data);
  };
  const handleSave = async () => {
    let dataResponse;

    switch (id) {
      case "area":
        dataResponse =
          viewType === windowActionTypes.new
            ? setIsModalToSaveArea(true)
            : editArea(inputValues.id, inputValues);
        break;
      case "article":
      case "cabms":
        dataResponse =
          viewType === windowActionTypes.new
            ? saveCabms(inputValues)
            : editCabms(inputValues.id, inputValues);
        break;
      case "edifice":
        dataResponse =
          viewType === windowActionTypes.new
            ? saveEdifice(inputValues)
            : editEdifice(inputValues.id, inputValues);
        break;
      case "vehicleBrand":
        dataResponse =
          viewType === windowActionTypes.new
            ? saveVehicleBrand(inputValues)
            : editVehicleBrand(inputValues.id, inputValues);
        break;
      case "vehicleSubBrand":
        dataResponse =
          viewType === windowActionTypes.new
            ? saveVehicleSubBrand(inputValues)
            : editVehicleSubBrand(inputValues.id, inputValues);
        break;
      case "responsible":
        if (!inputValues.nombre || !inputValues.tiponomina) {
          return notice("Nombre y Tipo de Nómina son requeridos", "info");
        } else {
          if (viewType === windowActionTypes.new) {
            dataResponse = createResponsible();
          } else {
            dataResponse = editarResponsable(inputValues.id, inputValues);
          }
        }
        break;
      case "supplier":
        if (!inputValues.razon_social || !inputValues.num_prove) {
          return notice("Razón social y Número de proveedor son requeridos", "info");
        } else {
          dataResponse =
            viewType === windowActionTypes.new
              ? supplierCRUD.createSupplier(inputValues)
              : supplierCRUD.updateSupplier(inputValues.id, inputValues);
        }
        break;
      case "signature":
        dataResponse =
          viewType === windowActionTypes.new
            ? signatureCRUD.createSignature(inputValues)
            : signatureCRUD.updateSignature(inputValues.id, inputValues);
        break;

      case "partida":
        dataResponse =
          viewType === windowActionTypes.new
            ? partidaCrud.createPartida(inputValues)
            : partidaCrud.updatePartida(inputValues.num_partida, inputValues);
        break;
      default:
        return notice("Sin funcionamiento.", "warning");
    }
    if (dataResponse) {
      dispatch(setLoader(true));
      dataResponse
        .then((response) => {
          if (viewType === windowActionTypes.new && response.id && id === "responsible") {
            notice("Se ha creado un nuevo responsable", "success");
          } else if (response.data) {
            viewType === windowActionTypes.new
              ? notice(
                  `Se ha agregado un nuevo registro con ID: ${
                    id === "partida" ? response.data.num_partida : response?.data?.id
                  }`,
                  "success"
                )
              : notice(
                  `Se ha editado un registro con ID: ${
                    id === "partida" ? inputValues.num_partida : inputValues.id
                  }`,
                  "success"
                );
            if (id === "area") {
              handleSearchById(paginationPage);
            } else {
              handleSearch(paginationPage);
            }
          } else {
            notice("Por favor, complete todos los campos", "warning");
          }
        })
        .catch((e) => {
          if (e?.num_empleado) {
            notice(e.num_empleado);
          } else if (e.error) {
            notice(e.error);
          } else {
            notice("No se ha podido guardar el registro");
          }
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };

  const handleDelete = () => {
    let dataResponse;
    switch (id) {
      case "area":
        dataResponse = deleteArea(inputValues.num_area);
        break;
      case "article":
      case "cabms":
        dataResponse = deleteCabms(inputValues.id);
        break;
      case "edifice":
        dataResponse = deleteEdifice(inputValues.id);
        break;
      case "vehicleBrand":
        dataResponse = deleteVehicleBrand(inputValues.id);
        break;
      case "vehicleSubBrand":
        dataResponse = deleteVehicleSubBrand(inputValues.id);
        break;
      case "responsible":
        dataResponse = eliminarResponsable(inputValues.id);
        break;
      case "supplier":
        dataResponse = supplierCRUD.deleteSupplier(inputValues.id);
        break;
      case "partida":
        dataResponse = partidaCrud.deletePartida(inputValues.num_partida);
        break;
      default:
        return notice("Sin funcionamiento.", "warning");
    }
    if (dataResponse) {
      dispatch(setLoader(true));

      dataResponse
        .then((response) => {
          if (response.status === 204) {
            notice(
              `Se ha eliminado un registro con ID: ${
                id === "partida" ? inputValues.num_partida : inputValues.id
              }`,
              "success"
            );
            dispatch(setViewType(windowActionTypes.new));
            dispatch(setFormValues(defaultValues));
          } else {
            notice("No se ha podido eliminar el registro, intente otra vez.", "warning");
          }
        })
        .catch(() => {
          notice("Ha ocurrido un error al eliminar el registro", "error");
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };

  const saveArea = async (body) => {
    setIsModalToSaveArea(false);
    await crearCatalogoArea(body)
      .then((response) => {
        if (response.data) {
          notice(
            `Se ha agregado un nuevo registro con ID: ${response?.data?.id}`,
            "success"
          );
          handleSearch(paginationPage);
        } else {
          notice("Por favor, complete todos los campos", "warning");
        }
      })
      .catch(() => {
        notice("No se ha podido guardar el registro", "error");
      });
  };

  const editArea = async (id, body) => {
    return await editCatalogoArea(id, body);
  };

  const deleteArea = async (id) => {
    return await eliminarCatalogoArea(id);
  };

  const saveCabms = async (body) => {
    return await crearCatalogoCabms(body);
  };

  const editCabms = async (id, body) => {
    return await editCatalogoCabms(id, body);
  };

  const deleteCabms = async (id) => {
    return await eliminarCatalogoCabms(id);
  };

  const editEdifice = async (id, body) => {
    return await editarEdificio(id, body);
  };

  const saveEdifice = async (body) => {
    return await crearEdificio(body);
  };

  const deleteEdifice = async (id) => {
    return await eliminarEdificio(id);
  };

  const editVehicleBrand = async (id, body) => {
    return await editarMarca(id, body);
  };

  const saveVehicleBrand = async (body) => {
    return await crearMarca(body);
  };

  const deleteVehicleBrand = async (id) => {
    return await eliminarMarca(id);
  };

  const editVehicleSubBrand = async (id, body) => {
    return await editarSubmarca(id, body);
  };

  const saveVehicleSubBrand = async (body) => {
    return await crearSubmarca(body);
  };

  const deleteVehicleSubBrand = async (id) => {
    return await eliminarSubmarca(id);
  };

  const handleCancel = () => {
    setAllAtchment({});
    if (id === "signature") {
      dispatch(
        setPureInputValues({
          clave_firma: "",
          adscripcion: "",
          area_nombre: "",
          area: "",
          titular: "",
          prefijo: "",
          estatus: true,
          nombre_firma_1: "",
          fecha_actualizacion_firma: "",
          firma_pdf: null,
        })
      );
    }
    dispatch(setViewType(windowActionTypes.init));
    dispatch(setQueryToSearch(""));
    // Clears copy elements
    if (windowActionTypes.edit) {
      dispatch(setPureWindowFormAvailableToCopy({}));
    }
  };

  const handleReportPdf = () => {
    let dataResponse;
    switch (id) {
      case "supplier":
        dataResponse = getSupplierPDF("PDF");
        break;
      case "ccostos":
        dataResponse = getCostCenterReport("PDF");
        break;
      case "secondments":
        dataResponse = getSecondmentReport("PDF");
        break;
      case "cabms":
        dataResponse = downloadCatalogPdfReport("cabms", ids);
        break;
      case "article":
        dataResponse = downloadCatalogPdfReport("articulos", ids);
        break;
      case "area":
        dataResponse = downloadCatalogPdfReport("areas", ids);
        break;
      case "edifice":
        dataResponse = downloadCatalogPdfReport("edificios", ids);
        break;
      case "vehicleBrand":
        dataResponse = downloadCatalogPdfReport("marcas", ids);
        break;
      case "vehicleSubBrand":
        dataResponse = downloadCatalogPdfReport("submarcas", ids);
        break;
      case "responsible":
        dataResponse = downloadCatalogPdfReport("responsables", ids);
        break;
      case "signature":
        if (viewType === windowActionTypes.new) {
          return getBase64(inputValues.firma_pdf)
            .then((res) => {
              let pdfWindow = window.open("");
              pdfWindow.document.write(
                `<iframe width='100%' height='100%' src="${res}"></iframe>`
              );
            })
            .catch(console.log);
        } else if (viewType === windowActionTypes.results)
          return window.open(inputValues.firma_pdf, "_blank");
        else return;
      default:
        notice("Sin funcionamiento.", "warning");
    }
    if (dataResponse) {
      dispatch(setLoader(true));
      dataResponse
        .then(({ status, data }) => {
          if (status === 200 && data.attachment_url)
            window.open(data.attachment_url, "_blank");
          else notice("Ha ocurrido un problema con su reporte");
        })
        .catch((e) => {
          notice("No se ha podido guardar el registro", "error");
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };

  const handleReportExcel = () => {
    let dataResponse;
    switch (id) {
      case "supplier":
        dataResponse = getSupplierExcel("EXCEL");
        break;
      case "ccostos":
        dataResponse = getCostCenterReport("EXCEL");
        break;
      case "secondments":
        dataResponse = getSecondmentReport("EXCEL");
        break;
      case "area":
        dataResponse = downloadCatalogReport("areas", ids);
        break;
      case "article":
        downloadCatalogReport("articulos", ids);
        return;
      case "cabms":
        dataResponse = downloadCatalogReport("cabms", ids);
        break;
      case "edifice":
        dataResponse = downloadCatalogReport("edificios", ids);
        break;
      case "vehicleBrand":
        dataResponse = downloadCatalogReport("marcas", ids);
        break;
      case "vehicleSubBrand":
        dataResponse = downloadCatalogReport("submarcas", ids);
        break;
      case "responsible":
        dataResponse = downloadCatalogReport("responsables", ids);
        break;
      case "partida":
        dataResponse = downloadCatalogReport("partida", ids);
        break;
      default:
        notice("Sin funcionamiento.", "warning");
    }

    if (dataResponse) {
      dispatch(setLoader(true));
      dataResponse
        .then(({ status, data }) => {
          if (status === 200 && data.attachment_url)
            window.open(data.attachment_url, "_blank");
          else notice("Ha ocurrido un problema con su reporte");
        })
        .catch((e) => {
          notice("No se ha podido guardar el registro", "error");
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };

  const handleMultiplePDF = () => {
    if (inputValues.custom_anio_pdf.length === 4) {
      const dataResponse = getMultiplePDF(ids, inputValues.custom_anio_pdf);
      if (dataResponse) {
        dispatch(setLoader(true));
        dataResponse
          .then(({ status, data }) => {
            if (status === 200 && data.attachment_url)
              window.open(data.attachment_url, "_blank");
            else notice("Ha ocurrido un problema con su reporte");
          })
          .catch((e) => {
            notice("No se ha podido guardar el registro", "error");
          })
          .finally(() => dispatch(setLoader(false)));
      }
    } else {
      notice("Debe seleccionar un año de resguardo válido", "warning");
      return;
    }
  };

  const [groups, setGroups] = useState([]);
  const [subgroups, setSubGroups] = useState([]);
  const [subSubgroups, setSubSubGroups] = useState([]);

  // get groups
  useEffect(() => {
    if (tabs.find((tab) => tab?.id === "area"))
      getGroups()
        .then((response) => setGroups(response))
        .catch((e) => console.log(e));
  }, [tabs]);

  // get subgroups
  useEffect(() => {
    if (inputValues.g) {
      getSubGroups(inputValues.g)
        .then((response) => setSubGroups(response))
        .catch((e) => console.log(e));
    }
  }, [inputValues.g]);

  // get subsubgroups
  useEffect(() => {
    if (inputValues.sg) {
      getSubSubGroups(inputValues.g, inputValues.sg)
        .then((response) => setSubSubGroups(response))
        .catch((e) => console.log(e));
    }
  }, [inputValues.g, inputValues.sg]);

  const validateInput = (name, value) => {
    if (name === "custom_anio_pdf") {
      if (isNaN(Number(value)) || value.length > 4) {
        return;
      } else {
        dispatch(setFormValues({ ...inputValues, [name]: value }));
      }
    }

    if (id === "signature") {
      if (name === "adscripcion") {
        dispatch(
          setFormValues({
            ...inputValues,
            [name]: value,
            titular: value?.cf_idpersona?.cf_nombre_com,
            area: value?.id,
            area_nombre: value?.cf_idarea?.cf_adscri7,
            prefijo: value?.cf_prefijo,
          })
        );
      } else dispatch(setFormValues({ ...inputValues, [name]: value }));
    } else if (id === "partida") {
      if (
        name === "num_partida" ||
        name === "ccapitulo" ||
        name === "cpartida" ||
        name === "cspartida" ||
        name === "no_cuenta" ||
        name === "no_scuenta"
      ) {
        if (!value.match(/^[0-9]*$/)) {
          return;
        } else {
          dispatch(setFormValues({ ...inputValues, [name]: value }));
        }
      } else dispatch(setFormValues({ ...inputValues, [name]: value }));
    } else if (id === "supplier") {
      if (name === "num_prove") {
        if (!value.match(/^[0-9]*$/g)) {
          return;
        } else {
          dispatch(setFormValues({ [name]: value }));
        }
      } else {
        dispatch(setFormValues({ [name]: value }));
      }
    } else {
      if (name === "cve_edificio") {
        if (isAValidInteger(value)) {
          dispatch(setFormValues({ [name]: value }));
        }
      } else if (name === "num_empleado") {
        if (isAValidInteger(value)) {
          dispatch(setFormValues({ [name]: value }));
        }
      } else {
        dispatch(setFormValues({ [name]: value }));
      }
    }

    if (viewType === windowActionTypes.edit && copyFeatureValidCatalogs.includes(id)) {
      const { label } = inputsCatalogAreasLabelNameToCopy?.find(
        (field) => field?.name === name
      );
      const newCopyField = {
        name,
        label,
        value,
      };
      dispatch(setWindowFormAvailableToCopy(newCopyField));
    }

    clearGroupValues(name);
  };

  const clearGroupValues = (group) => {
    if (windowActionTypes.edit && group === "g") {
      dispatch(setGroupValues());
    }
  };

  const DisablesPermisos = (action, Catalogo, Prevaction) => {
    switch (Catalogo) {
      case "cabms":
        return !permisos.permisos.includes(`i-${action}-cabms`);

      case "vehicleBrand":
        return !permisos.permisos.includes(`i-${action}-vehiculo`);

      case "vehicleSubBrand":
        return !permisos.permisos.includes(`i-${action}-submarca-vehiculo`);

      case "responsible":
        return !permisos.permisos.includes(`i-${action}-responsables`);
      case "area":
        return !permisos.permisos.includes(`i-${action}-areas`);

      default:
        return;
    }
  };

  const handleCopyToAllSelectedAreas = () => {
    setLoading(true);
    dispatch(setLoader(true));

    // eslint-disable-next-line no-sequences
    // raw arg is to get the newValues needed for inputs
    const getNewValues = (raw = false) => {
      const newValues = {};
      for (const key in windowFormAvailableToCopy) {
        if (dataToCopy.includes(key)) {
          if (key === "responsables" && !raw) {
            newValues[key] = formatResponsableObject(
              windowFormAvailableToCopy[key].value
            );
          } else if (key === "edificio" && !raw) {
            newValues[key] = windowFormAvailableToCopy[key].value?.id;
          } else {
            newValues[key] = windowFormAvailableToCopy[key].value;
          }
        }
      }
      return newValues;
    };
    const areas = ids.map((id) => {
      const area = {
        id,
        ...getNewValues(),
      };
      return area;
    });

    const body = { areas };
    updateMultipleAreasFields(body)
      .then((res) => {
        notice(
          `${
            ids.length > 1
              ? "Se han editado las áreas exitosamente"
              : "Se ha editado el área exitosamente"
          }`,
          "success"
        );
        // dispatch(setCurrentMode(null));
        setOpenModalToCopy(false);
        // dispatch(clearStockFormDataAvailableToCopy());
        // setOriginalStock(selectedStockForm)
        const newResults = results.map((result) => ({
          ...result,
          ...getNewValues(true),
        }));
        dispatch(setPureResults(newResults));
        const currentInputValues = results?.find(
          (result) => result.id === inputValues.id
        );
        if (currentInputValues?.id) {
          const newInputValues = {
            ...currentInputValues,
            ...getNewValues(true),
          };
          dispatch(setPureInputValues(newInputValues));
        }
        dispatch(setViewType("RESULTS"));
      })
      .catch((e) => {
        if (e.error) notice(e.error);
        else notice("Ha ocurrido un error con la solicitud", "error");

        throw e;
      })
      .finally(() => {
        setLoading(false);
        dispatch(setLoader(false));
      });
  };

  const [openM, setOpenM] = useState(false);

  const openModal = () => {
    setOpenM(true);
  };
  const onBaja = () => {
    dispatch(setLoader(true));

    if (inputValues.estatus) {
      const idBaja = inputValues.id;

      signatureCRUD
        .bajaFunction(idBaja)
        .then((res) => {
          notice("Se dio de baja exitosamente", "success");
          dispatch(setFormValues(res));
        })
        .finally(() => {
          setOpenM(false);

          dispatch(setLoader(false));
        });
    }
  };
  const statusFirma = inputValues.estatus;
  return (
    <div className="w-100">
      {loading && <SplashLoader />}
      <div className="d-flex justify-content-between row">
        <div className="d-flex col-md-12 mb-2">
          <div className={`${id === "signature" ? "signature" : "otro"}`}>
            <div className={`${id === "signature" ? "signatureIcons" : "otro"}`}>
              {icons(
                permisos,
                id,
                // id === "signature" ? true : false,
                // //ocultar edit
                // id === "secondments" ? true : false,
                viewType === windowActionTypes.search ||
                  viewType === windowActionTypes.new
                  ? handleSearch
                  : () => clearForm(windowActionTypes.search),
                handleEdit,
                () => clearForm(windowActionTypes.new),
                () => handleSave(),
                handleDelete,
                handleCancel,
                handleReportPdf,
                handleReportExcel,
                handleMultiplePDF,
                loading ||
                  (viewType !== windowActionTypes.search &&
                    viewType !== windowActionTypes.init),
                loading ||
                  (DisablesPermisos("editar", NameCatalogo) &&
                    viewType !== windowActionTypes.results),
                loading ||
                  DisablesPermisos(
                    "crear",
                    NameCatalogo,
                    viewType === windowActionTypes.new ||
                      viewType === windowActionTypes.results ||
                      viewType === windowActionTypes.edit ||
                      viewType === windowActionTypes.search
                  ),
                loading ||
                  (viewType !== windowActionTypes.edit &&
                    viewType !== windowActionTypes.new),
                loading ||
                  DisablesPermisos(
                    "eliminar",
                    NameCatalogo,
                    viewType === windowActionTypes.new ||
                      viewType === windowActionTypes.results ||
                      viewType === windowActionTypes.edit ||
                      viewType === windowActionTypes.search
                  ),
                loading ||
                  (viewType !== windowActionTypes.results &&
                    viewType !== windowActionTypes.new &&
                    viewType !== windowActionTypes.edit &&
                    viewType !== windowActionTypes.search),
                id,
                hasReport,
                id === "signature" &&
                  (viewType === windowActionTypes.new ||
                    viewType === windowActionTypes.results ||
                    viewType === windowActionTypes.edit) &&
                  !inputValues.firma_pdf
              ).map(
                (icon) =>
                  icon && (
                    <div key={icon.label}>
                      <Icon
                        src={toAbsoluteUrl(icon.image)}
                        color={icon.color || "secondary"}
                        label={icon.tooltip}
                        onClick={icon.onClick}
                        disabled={icon.disabled}
                        style={{
                          width: 55,
                          height: 50,
                          margin: "0px 2px",
                          fontSize: 10,
                        }}
                        subLabel={icon.label}
                        disableTooltip={true}
                        hide={icon.hide}
                      />
                      {false && icon.label && (
                        <Typography variant="h6">{icon.label}</Typography>
                      )}
                    </div>
                  )
              )}
            </div>
            {id === "signature" && (
              <div>
                {iconSignature(openModal, statusFirma, viewType).map(
                  (icon) =>
                    icon && (
                      <div key={icon.label}>
                        <Icon
                          src={toAbsoluteUrl(icon.image)}
                          color={icon.color || "secondary"}
                          label={icon.tooltip}
                          onClick={icon.onClick}
                          disabled={icon.disabled}
                          style={{
                            width: 55,
                            height: 50,
                            margin: "0px 2px",
                            fontSize: 10,
                          }}
                          subLabel={icon.label}
                          disableTooltip={true}
                          hide={icon.hide}
                        />
                        {false && icon.label && (
                          <Typography variant="h6">{icon.label}</Typography>
                        )}
                      </div>
                    )
                )}
              </div>
            )}
          </div>
          {copyFeatureValidCatalogs.includes(id) && (
            <CustomButton
              outline
              title={
                <>
                  <i className="flaticon2-copy mr-2" />
                  {`Copiar (${Object.keys(windowFormAvailableToCopy).length})`}
                </>
              }
              disabled={
                viewType !== windowActionTypes.edit ||
                Object.keys(windowFormAvailableToCopy).length === 0
              }
              color="info"
              onClick={copyDataModal}
            />
          )}
        </div>
        <div style={{ width: 330, padding: "20px 20px" }} className={"d-flex col-md-12"}>
          {(viewType === windowActionTypes.results ||
            viewType === windowActionTypes.edit) && (
            <Pagination
              key={id}
              isInModal
              items={results}
              showSlider={false}
              totalItems={paginationCount}
              currentPage={paginationPage}
              totalPages={paginationTotalPage}
              totalItemsPerPage={paginationPerPage}
              changeElements={isArea ? handleSearchById : handleSearch}
              renderElement={({ item, currentItem, ...props }) => {
                return (
                  <TabButton
                    {...props}
                    tooltip={item.text}
                    label={item.id.toString()}
                    width={60}
                    height={30}
                    fontSize={"0.8rem"}
                    isSelected={item.id === inputValues?.id}
                    cursor="pointer"
                    className="mr-1"
                  />
                );
              }}
              defaultIndex={paginationIndex}
              onItemChange={({ currentIndex, currentItem }) => {
                // setOriginalForm(currentItem)
                dispatch(setFormValues({ ...currentItem }));
              }}
            />
          )}
        </div>
      </div>

      <Divider className="my-4" />
      <div style={{ maxHeight: 500, overflowY: "auto" }}>
        {inputs && (
          <UtilInputs
            view={viewType}
            clearInput={windowActionTypes.init ? true : false}
            inputValues={(() => {
              let returnedInputs =
                id === "area"
                  ? inputs(groups, subgroups, subSubgroups, viewType)
                  : inputs;
              if (
                viewType === windowActionTypes.init ||
                viewType === windowActionTypes.results
              ) {
                returnedInputs = returnedInputs.map((input) => {
                  let temp = Object.assign({}, input);
                  temp.disabled = true;
                  return temp;
                });
              } else if (viewType === windowActionTypes.edit) {
                returnedInputs = returnedInputs.map((input) => {
                  let temp = Object.assign({}, input);
                  if (temp.name === "num_area") temp.disabled = true;
                  return temp;
                });
              }
              return returnedInputs;
            })()}
            onValuesChange={({ target: { name, value } }) => {
              validateInput(name, value);
            }}
            formValues={inputValues}
            width={"calc(50% - 15px)"}
          />
        )}
        {results[0]?.id && id === "area" && (
          <DigitalizationListDocs
            data={allAttachment}
            newDigitalization
            module={"area"}
            attachTo={results[0]?.id}
            onlyPdf
          />
        )}
        {results[0]?.id && id === "responsible" && (
          <DigitalizationListDocs
            data={allAttachment}
            newDigitalization
            module={"person"}
            attachTo={results[0]?.id}
          />
        )}
      </div>
      <BaseModal
        title={"Registrar un nuevo número de área"}
        open={isModalToSaveArea}
        onClose={() => {
          setIsModalToSaveArea(false);
          handleCancel();
        }}
        onConfirm={() => {
          saveArea(inputValues);
        }}
        confirmLabel="Si, guardar"
        onCancel={() => {
          setIsModalToSaveArea(false);
          handleCancel();
        }}
        cancelLabel="Cerrar"
        maxWidth="sm"
        color="secondary"
        disableOnConfirm={false}
      >
        <Typography variant="body1">
          {" Para esta operación, se generará un nuevo numero de resguardo "}
        </Typography>
        <Typography variant="body1">{" ¿Estás de acuerdo? "}</Typography>
      </BaseModal>
      <BaseModal
        title={<span>Seleccione los campos</span>}
        open={openModalToCopy}
        onClose={() => setOpenModalToCopy(false)}
        onConfirm={handleCopyToAllSelectedAreas}
        confirmLabel="Guardar y copiar"
        onCancel={() => setOpenModalToCopy(false)}
        cancelLabel="Cerrar"
        maxWidth="sm"
        color="secondary"
        disableOnConfirm={false}
      >
        <CheckboxList
          data={windowFormAvailableToCopy}
          onChangeData={onChangeDataToCopy}
        />
      </BaseModal>
      <BaseModal
        title="¿Deseas dar de baja?"
        open={openM}
        onClose={() => setOpenM(false)}
        onConfirm={() => onBaja()}
        onCancel={() => setOpenM(false)}
        cancelLabel="Cancelar"
        cancelColor="secondary"
        // confirmLabel="Aceptar"
        cancelVariant="contained"
      />
    </div>
  );
};

export default FilterForm;
