import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import update from "immutability-helper";
import _ from "lodash";
import RequestMaterialList from "../../components/RequestMaterialList";
import CardColorContainer from "../../components/CardColorContainer";
import AutocompleteInput from "../../components/AutocompleteInput";
import PDFUploaderTab from "../../components/PDFUploaderTab";
import CustomButton from "../../components/CustomButton";
import SimpleInput from "../../components/SimpleInput";
import PageLayout from "../../components/PageLayout";
import TabsButton from "../../components/TabsButton";
import UtilInputs from "../../components/UtilInputs";
import Subtitle from "../../components/Subtitle";
import notice from "../../components/Notice";
import {
  requestSelector,
  setReload,
  setSelectedItems,
  setSelectedRequest,
} from "../../../redux/ducks/request.duck";
import { dispatchRequest, singleRequest } from "../../crud/requestCrud";
import { getArticlesWithVariants } from "../../crud/catalogsCrud";
import { createDepartureById } from "../../crud/departuresCrud";
import { materialColumns as bagColumns } from "../../utils/materialRequestData";
import { getColourizedLabelStatus } from "../../utils/requestUtils";
import { requestInputsFields } from "../../utils/requestData";
import { isAValidInteger } from "../../utils/inputValidations";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette?.gray?.main,
    marginBottom: 20,
  },
  pickupInputs: {
    display: "flex",
    "&>*": {
      marginRight: 30,
    },
    flexWrap: "wrap",
  },
  colorCards: {
    "&>*": {
      marginRight: 30,
      marginTop: 30,
    },
  },
}));

const NewRequestForm = ({
  materialColumns,
  selectedMaterialData,
  onEditItem,
  onCheckItem,
  onDeleteSelectedItem,
  onSelectedQuantitiesChange,
  form,
  handleForm,
  handleOption,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { selectedRequestIds, selectedRequest, selectedItems, isNewTab } = useSelector(
    requestSelector
  );
  const moduleAdminRoute = "inventario-bienes-almacen-general";
  const { user } = useSelector((store) => store.auth);
  const inventoryModule = user.app_modules.find(
    (module) => module.name === moduleAdminRoute
  );
  const { permisos } = useSelector((store) => store.auth);

  const isInventory = pathname.includes("inventario-bienes-almacen-general");
  const requestModule =
    inventoryModule && inventoryModule.submodules.length > 0
      ? inventoryModule.submodules.find((module) => module.name === "solicitudes")
      : undefined;
  const mode = !pathname.includes(moduleAdminRoute)
    ? "public"
    : requestModule
    ? "admin"
    : "public";
  const [articleBag, setArticleBag] = useState([]);

  const REQUESTED =
    selectedItems.length > 0
      ? selectedItems.map((x) => Number(x.cf_cantidad_solicitada)).reduce((a, b) => a + b)
      : 0;

  const ASSORTMENT =
    selectedItems.length > 0
      ? selectedItems.map((x) => Number(x.cf_cantidad_surtida)).reduce((a, b) => a + b)
      : 0;

  const DENIED =
    selectedItems.length > 0
      ? selectedItems.map((x) => Number(x.cf_cantidad_negada)).reduce((a, b) => a + b)
      : 0;

  const cardItems = [
    {
      title: "Bienes solicitados",
      value: REQUESTED,
      icon: "/media/svg/icons/Shopping/Box2.svg",
      color: "primary",
    },
    {
      title: "Bienes surtidos",
      value: ASSORTMENT,
      icon: "/media/svg/icons/Shopping/Cart1.svg",
      color: "success",
    },
    {
      title: "Bienes negados",
      value: DENIED,
      icon: "/media/svg/icons/Code/Stop.svg",
      color: "danger",
    },
  ];

  const handleClickTab = (id) => {
    singleRequest(id)
      .then((res) => {
        const newSelectedRequest = {
          ...res,
          label_status: getColourizedLabelStatus(res.cf_estatus),
        };
        dispatch(setSelectedRequest(newSelectedRequest));
        dispatch(
          setSelectedItems(
            res.items.map((item) => ({
              ...item,
              disabled: true,
            }))
          )
        );
        history.push(
          isInventory
            ? `/inventario-bienes-almacen-general/solicitudes/resultados/${res.id}`
            : `/solicitudes/resultados/${res.id}`
        );
      })
      .catch(console.log);
  };

  const handleDispatchRequest = (e) => {
    e.preventDefault();
    dispatch(setReload(true));
    const payload = {
      warehouse_request_details: selectedMaterialData.map((item) => ({
        cf_cve_articulo: item.cve_articulo,
        cf_cantidad_surtida: item.cf_cantidad_surtida,
        cf_cantidad_negada: item.cf_cantidad_negada,
        //cf_cantidad_solicitada: item.cf_cantidad_solicitada,
        id: item.request_detail_id,
        warehouse_request_id: item.warehouse_request_id,
      })),
    };
    dispatchRequest(payload, form.id)
      .then((res) => {
        const status = res?.response?.status || res.status || 400;
        if ([200, 201].includes(status)) {
          const label_status = { color: "primary", text: "Despachada" };
          dispatch(
            setSelectedRequest({
              ...selectedRequest,
              label_status,
              cf_estatus: "D",
            })
          );
          notice("La solicitud fué despachada", "info");
        } else {
          notice(
            (res?.response?.data?.error || "No se ha podido despachar la solicitud.") +
              " Contacta al administrador",
            "error"
          );
        }
      })
      .catch((error) => {
        notice("No se ha podido despachar la solicitud", "error");
      })
      .finally(() => dispatch(setReload(false)));
  };

  const onSuccessUploadPDFs = (responses) => {
    const newAttachments = responses.map((res) => res.data);
    const updatedForm = {
      ...form,
      attachment_pdfs: [...form.attachment_pdfs, ...newAttachments],
    };
    dispatch(setSelectedRequest(updatedForm));
  };

  const onSucessRemovePDF = (deletedPDFId) => {
    const newAttachments = form.attachment_pdfs.filter(
      (attachment) => attachment.id !== deletedPDFId
    );
    const updatedForm = { ...form, attachment_pdfs: newAttachments };
    dispatch(setSelectedRequest(updatedForm));
  };

  const onDeliverRequest = (id) => {
    createDepartureById(id, {})
      .then((response) => {
        if (response) {
          notice(`${response.outbound_related_object} cerrada`, "success");
        }
      })
      .catch((e) => {
        notice(`Intente de nuevo`, "info");
      })
      .finally(() => {
        handleClickTab(id);
      });
  };
  console.log(form);
  return (
    <PageLayout
      title={
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="text-nowrap">
            {form.cf_estatus === "X"
              ? "Borrador"
              : form?.id
              ? "Información de solicitud"
              : "Nueva solicitud"}
          </h6>
          {mode === "admin" && form.cf_estatus !== "X" && (
            <div className="d-flex justify-content-between">
              <CustomButton
                color="primary"
                title="Entregar"
                disabled={form.cf_estatus !== "D"}
                onClick={() => {
                  onDeliverRequest(form.id);
                }}
              />
              <CustomButton
                color="primary"
                title="Despachar"
                disabled={
                  !(
                    form.cf_estatus === "H" &&
                    permisos.permisos.includes(`a-despachar-solicitud`)
                  )
                }
                onClick={handleDispatchRequest}
              />
            </div>
          )}
        </div>
      }
      header={
        form.id &&
        mode === "admin" &&
        selectedRequestIds.length > 1 && (
          <TabsButton
            list={selectedRequestIds}
            selected={form.id}
            handleClickTab={handleClickTab}
          />
        )
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection:
            mode === "admin" && form.cf_estatus !== "X" ? "column" : "column-reverse",
        }}
      >
        {mode === "admin" && form.cf_estatus !== "X" && (
          <UtilInputs
            formValues={form}
            onValuesChange={handleForm}
            inputValues={requestInputsFields}
            width="200px"
          />
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Subtitle title="Información de recolección" padding="10px 0px 20px 0px" />
          <form className={classes.pickupInputs} autoComplete="off">
            <SimpleInput
              width={300}
              label="Nombre de quién recoge"
              placeholder="Nombre de quién recoge"
              labelStyle={{ marginBottom: 10 }}
              value={form?.collect_name}
              name="collect_name"
              onChange={handleForm}
              //disabled={disabled}
              // Marcelo asked to hide
              disabled={mode === "admin" && true}
              required
            />
            <SimpleInput
              width={300}
              label="Extensión de quién recoge"
              placeholder="Extensión de quién recoge"
              labelStyle={{ marginBottom: 10 }}
              value={form?.collect_extension}
              name="collect_extension"
              onChange={(e) =>
                isAValidInteger(e.target?.value) &&
                Number(e.target.value) <= 32767 &&
                handleForm(e)
              }
              //disabled={disabled}
              // Marcelo asked to hide
              disabled={mode === "admin" && true}
              required
            />
            <SimpleInput
              width={300}
              label="Correo electrónico de quién recoge"
              labelStyle={{ marginBottom: 10 }}
              value={form?.collect_email}
              placeholder="correo@senado.gob.mx"
              name="collect_email"
              onChange={handleForm}
              isPassword // for disable uppercase
              errorMessage={
                form?.collect_email &&
                !/^[a-zA-Z0-9!#$%&'*+-/.=?^_`{|}~-]+@senado\.gob.mx$/.test(
                  form?.collect_email.toLowerCase()
                ) &&
                "Formato incorrecto, el dominio debe ser @senado.gob.mx"
              }
              // Marcelo asked to hide
              disabled={mode === "admin" && true}
              required
            />
          </form>
        </div>
        {mode === "admin" && form.cf_estatus !== "X" && (
          <React.Fragment>
            <Subtitle
              title="Información de bienes"
              padding="15px 0px 0px 0px"
              margin="0px 0px -10px 0px"
            />
            <CardColorContainer cards={cardItems} />
          </React.Fragment>
        )}
        <div style={{ minHeight: 30 }}></div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {(form.cf_estatus === "X" || isNewTab) && (
            <AutocompleteInput
              uppercase={true}
              name="article"
              label="Artículo"
              placeholder="Buscar artículo"
              onChange={({ target }) => {
                const inBag = articleBag.some((bag) =>
                  typeof target?.value?.variant_asset_id_1 === "number"
                    ? bag.desc_corta ===
                      `${target?.value?.desc_corta} ${target?.value?.variant_text}`
                    : bag.cve_articulo === target?.value?.cve_articulo
                );
                if (!inBag)
                  setArticleBag(
                    articleBag.concat({
                      ...target.value,
                      key: target.value.id,
                      cve_articulo: target.value.cve_articulo,
                      desc_corta: target.value.text,
                      uni_distribuye: target.value.unit,
                      cf_cantidad_solicitada: "",
                      cf_cantidad_surtida: 0,
                      cf_cantidad_negada: 0,
                      actions: ["add", "delete"],
                      //id: uuid(),
                      disabled: false,
                      danger: false,
                    })
                  );
              }}
              searchFunction={getArticlesWithVariants}
            />
          )}
          {articleBag.length > 0 && (
            <RequestMaterialList
              columns={bagColumns("public")}
              items={articleBag}
              onDeleteItem={(id) => {
                const index = _(articleBag).findIndex((bag) => bag.id === id);
                if (index !== -1) {
                  setArticleBag(
                    update(articleBag, {
                      $splice: [[index, 1]],
                    })
                  );
                }
              }}
              onAddItem={(id, item) => {
                const index = _(articleBag).findIndex(
                  (bag) => bag.cve_articulo === item.cve_articulo
                );
                if (index !== -1) {
                  if (
                    Boolean(articleBag[index].cf_cantidad_solicitada) &&
                    articleBag[index].cf_cantidad_solicitada > 0
                  ) {
                    handleOption({
                      target: {
                        name: "article",
                        value: { ...articleBag[index] },
                      },
                    });
                    setArticleBag(
                      update(articleBag, {
                        $splice: [[index, 1]],
                      })
                    );
                  } else {
                    setArticleBag(
                      update(articleBag, {
                        [index]: { danger: { $set: true } },
                      })
                    );
                  }
                }
                //	handleOption()
              }}
              onQuantityChange={(e, index) => {
                const { value } = e.target;
                setArticleBag(
                  update(articleBag, {
                    [index]: {
                      cf_cantidad_solicitada: { $set: value },
                      danger: { $set: false },
                    },
                  })
                );
              }}
            />
          )}
          {selectedMaterialData && selectedMaterialData.length > 0 ? (
            <React.Fragment>
              <Subtitle title="Materiales seleccionados" padding="20px 0px 20px 0px" />
              <RequestMaterialList
                columns={materialColumns}
                items={selectedMaterialData}
                onEditItem={onEditItem}
                onCheckItem={onCheckItem}
                onDeleteItem={onDeleteSelectedItem}
                onQuantityChange={onSelectedQuantitiesChange}
              />
            </React.Fragment>
          ) : (
            <Typography>Aún no hay materiales seleccionados</Typography>
          )}
        </div>
        <div className="mt-5">
          {form.id && mode === "admin" && form.cf_estatus !== "X" && (
            <PDFUploaderTab
              field="pdfs"
              id={form.id}
              pdfTitleName="la solicitud"
              attach_model="warehouse_request"
              attachment_pdfs={form.attachment_pdfs}
              onSuccessUpload={onSuccessUploadPDFs}
              onSucessRemove={onSucessRemovePDF}
            />
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default NewRequestForm;
