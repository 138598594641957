import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import SVG from "react-inlinesvg";
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    filter: {
        display: 'flex',
        cursor: ({ disabled }) => disabled ? 'not-allowed ' : 'pointer'
    },
    svg: {
        "& path": {
            fill: ({ isSelected, color }) => color ? color : isSelected ? theme.palette.secondary.main : theme.palette.gray.main
        },
        margin: 'auto'
    },
    text: {
        margin: 'auto 5px',
        color: ({ isSelected }) => isSelected ? theme.palette.secondary.main : theme.palette.gray.main
    }
}));

const IconButton = ({ src='', label='', isSelected=false, onClick=()=>{}, disabled=false, color }) => {
    const classes = useStyles({ isSelected, disabled, color });

    const handleClick = () => !disabled && onClick();

    return (
        <div className={classes.filter} onClick={handleClick}>
            <SVG src={src}  fill="white" className={classes.svg}/>
            <Typography className={classes.text}>{label}</Typography>
        </div>
    );
};

IconButton.propTypes = {
    isSelected: PropTypes.bool,
    onClick: PropTypes.func
};

export default IconButton;