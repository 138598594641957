import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FullTable from "../../components/FullTable";
import notice from "../../components/Notice";
import ToDoBoardModal from "./components/ToDoBoardModal";
import { all_task_columns, columns } from "./utils";
import {
  getBoards,
  postBoard,
  deleteBoard,
  getAllTasks,
} from "../../crud/taskManagementCrud";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllTasks,
  setBoards,
  setSearchTasks,
  setSelectedBoard,
} from "../../../redux/ducks/taskManagement.duck";
import ToDoDetailTaskModal from "./components/ToDoDetailTaskModal";
import { setLoader } from "../../../redux/ducks/loader.duck";
import BaseModal from "../../components/BaseModal";

const ToDoBoard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    boards,
    tasks: { allResults, searchTasks },
  } = useSelector((store) => store.taskManagement);
  const { isWarehousePage, isInstrumentalGoodsPage } = useSelector(
    (store) => store.pathname
  );
  const { user } = useSelector((store) => store.auth);
  const [boardModal, setBoardModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [sendingForm, setSendingForm] = useState(false);
  const [successForm, setSuccessForm] = useState(true);
  const [detailId, setDetailId] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [isVisitedStoredBoards, setIsVisitedStoredBoards] = useState(false);
  const [isStoreBoard, setIsStoreBoard] = useState(false);
  const [selectedToStore, setSelectedToStore] = useState({});
  const [isDeletedATask, setIsDeletedATask] = useState(false);

  const onNewBoard = () => {
    setSuccessForm(true);
    setBoardModal(true);
  };

  const onSelectBoard = (id) => {
    dispatch(setSelectedBoard(boards.results.find((board) => board.id === id)));
    let route;
    if (isWarehousePage)
      route =
        "/inventario-bienes-almacen-general/administracion-tareas/tableros/columnas/";
    else if (isInstrumentalGoodsPage)
      route = "/bienes-instrumentales/administracion-tareas/tableros/columnas/";
    else route = "/administrador-tareas/tableros/columnas/";
    history.push(route + id);
  };

  const fetchBoards = useCallback(
    async (parameter = "") => {
      dispatch(setLoader(true));
      getBoards(page, limit, parameter)
        .then((response) => {
          dispatch(setBoards(response));
        })
        .catch((e) => console.log(e))
        .finally(dispatch(setLoader(false)));
    },
    [dispatch, limit, page]
  );

  const viewBoardsByMode = () => {
    if (!isVisitedStoredBoards) {
      setIsVisitedStoredBoards(true);
      fetchBoards("&archivado=true");
    } else {
      setPage(1);
      setIsVisitedStoredBoards(false);
      fetchBoards();
    }
  };

  useEffect(() => {
    if (isVisitedStoredBoards) {
      fetchBoards("&archivado=true");
    } else {
      fetchBoards();
    }
  }, [fetchBoards, isVisitedStoredBoards]);

  const createdBoard = (body) => {
    const formatBody = {
      ...body,
      users: body.users.map((user) => user.value),
      user_in_charge: user.id,
    };

    if (!formatBody.name || !formatBody.users.length) {
      setSuccessForm(false);
    } else {
      setSuccessForm(true);
      setSendingForm(true);

      postBoard(formatBody)
        .then((res) => notice("Guardado correctamente", "success"))
        .catch((e) => {
          notice("Algo salió mal, intente de nuevo...", "info");
          console.log(e);
        })
        .finally(() => {
          fetchBoards();
          setBoardModal(false);
          setSendingForm(false);
        });
    }
  };

  const onStore = (row) => {
    setIsStoreBoard(true);
    setSelectedToStore(row);
  };

  const handleStoreBoard = (id) => {
    setSendingForm(true);
    deleteBoard(id)
      .then((res) => {
        notice("Tablero archivado", "info");
      })
      .catch((e) => {
        notice("Algo salió mal, intente de nuevo...", "warning");
        console.log(e);
      })
      .finally(() => {
        setSendingForm(false);
        fetchBoards();
        setIsStoreBoard(false);
      });
  };

  const onSeeFinishedTask = (id) => {
    let route;
    if (isWarehousePage)
      route =
        "/inventario-bienes-almacen-general/administracion-tareas/terminadas/";
    else if (isInstrumentalGoodsPage)
      route = "/bienes-instrumentales/administracion-tareas/terminadas/";
    else route = "/administrador-tareas/terminadas/";
    history.push(route + id);
  };

  const onSeeTask = (id) => {
    setDetailId(id);
    setDetailModal(true);
  };

  const handleSearch = () => {
    if (searchTasks) {
      dispatch(setLoader(true));
      getAllTasks(searchTasks?.toUpperCase())
        .then(({ results }) => dispatch(setAllTasks(results)))
        .catch((e) => notice("Error buscando los archivos"))
        .finally(() => dispatch(setLoader(false)));
    }
  };

  const handleTasks = () => {
    dispatch(setLoader(true));
    getAllTasks(searchTasks?.toUpperCase())
      .then(({ results }) => dispatch(setAllTasks(results)))
      .catch((e) => notice("Error buscando los archivos"))
      .finally(() => dispatch(setLoader(false)));
  }

  useEffect(()=>{
    if( isDeletedATask ){
      handleTasks()
    }
    setIsDeletedATask(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeletedATask, setIsDeletedATask])

  const goToCalendarPage = () => {
    let route;
    if (isWarehousePage)
      route =
        "/inventario-bienes-almacen-general/administracion-tareas/calendario";
    else if (isInstrumentalGoodsPage)
      route = "/bienes-instrumentales/administracion-tareas/calendario";
    else route = "/administrador-tareas/calendario";
    history.push(route);
    return;
  };

  return (
    <>
      {allResults.length > 0 ? (
        <FullTable
          title="Tareas"
          count={allResults.length}
          entities={allResults}
          columns={all_task_columns(onSeeTask)}
          search_button={false}
          hideCheckbox
          hide_pagination
          buttonTitle="Mostrar tableros"
          onClickButton={() => dispatch(setSearchTasks(""))}
          has_filter={false}
        />
      ) : (
        <FullTable
          title={
            isVisitedStoredBoards ? `Tableros archivados` : `Tableros activos`
          }
          count={boards.count}
          loading={sendingForm}
          entities={boards.results}
          columns={columns(
            onSelectBoard,
            onStore,
            onSeeFinishedTask,
            user,
            isVisitedStoredBoards
          )}
          onClickButton={onNewBoard}
          buttonTitle={"Nuevo tablero"}
          has_button2={true}
          onClickButton2={viewBoardsByMode}
          buttonTitle2={
            !isVisitedStoredBoards
              ? `Ver tableros archivados`
              : `Ver tableros activos`
          }
          buttonDisabled2={false}
          has_button3={true}
          onClickButton3={goToCalendarPage}
          buttonTitle3={`Calendario de tareas`}
          buttonDisabled3={false}
          page={page}
          sizePerPage={limit}
          setPage={(page) => page > 0 && setPage(page)}
          setSizePerPage={setLimit}
          search_button={false}
          hideCheckbox
        />
      )}
      <ToDoBoardModal
        open={boardModal}
        setOpen={setBoardModal}
        onConfirmAction={createdBoard}
        disabledConfirmAction={sendingForm}
        successForm={successForm}
      />
      <ToDoDetailTaskModal
        detailId={detailId}
        open={detailModal}
        setOpen={setDetailModal}
        setIsDeletedATask={setIsDeletedATask}
        fetchTasksByBoard={handleSearch}
      />
      <BaseModal
        title={`Archivar tablero`}
        children={`¿Está seguro que desea archivar el tablero ${selectedToStore.name} ?`}
        open={isStoreBoard}
        onCancel={() => setIsStoreBoard(false)}
        onClose={() => setIsStoreBoard(false)}
        onConfirm={() => {
          handleStoreBoard(selectedToStore.id);
        }}
        disableOnConfirm={sendingForm}
      />
    </>
  );
};

export default ToDoBoard;
