export const materialColumns = (mode = 'admin') => mode === 'admin' ? [
  {
    text: 'Art',
    value: 'cve_articulo',
    type: 'text'
  },
  {
    text: 'Nombre del artículo',
    value: 'desc_corta',
    type: 'text',
    width: 300
  },
  {
    text: 'Unidad',
    value: 'uni_distribuye',
    type: 'text',
    width: 180
  },
  {
    text: 'Solicitud',
    value: 'cf_cantidad_solicitada',
    type: 'text',
    width: 180,
  },
  {
    text: 'Surtido',
    value: 'cf_cantidad_surtida',
    type: 'input',
    width: 180
  },
  {
    text: 'Negada',
    value: 'cf_cantidad_negada',
    type: 'input',
    width: 180
  },
  {
    text: 'Acciones',
    value: 'actions',
    type: 'icon',
    icon: 120
  },
] : [
  {
    text: 'Art',
    value: 'cve_articulo',
    type: 'text'
  },
  {
    text: 'Nombre del artículo',
    value: 'desc_corta',
    type: 'text',
    width: 300
  },
  {
    text: 'Unidad',
    value: 'uni_distribuye',
    type: 'text',
    width: 180
  },
  {
    text: 'Solicitud',
    value: 'cf_cantidad_solicitada',
    type: 'input',
    width: 180,
  },
  {
    text: 'Acciones',
    value: 'actions',
    type: 'icon',
    icon: 120
  },
];


export const materialData = [
	{
		key: 100,
		description: 'SWITCH1',
		unit: 'Pieza1',
		quantity: '',
		actions: [
			'add',
			'delete',
		],
		id: 1,
		disabled: false
	},
	{
		key: 345,
		description: 'SWITCH2',
		unit: 'Pieza2',
		quantity: '',
		actions: [
			'add',
			'delete',
		],
		id: 2,
		disabled: false
	},
	{
		key: 78,
		description: 'SWITCH3',
		unit: 'Pieza3',
		quantity: '',
		actions: [
			'add',
			'delete',
		],
		id: 3,
		disabled: false
	},
	{
		key: 22,
		description: 'SWITCH4',
		unit: 'Pieza4',
		quantity: '',
		actions: [
			'add',
			'delete',
		],
		id: 4,
		disabled: false
	}
];

export const defaultRequestForm = {
	collect_name: "",
  collect_extension: '',
  collect_email: ''
}

export const defaultRequestFilter = {
	id: "", // no. solicitud
	articulos: null,
	cf_fecha_solicitud: '',
	collect_name: '',
	collect_extension: '',
	collect_email: '',
	cf_estatus: '',
  area: '',
  nom_sarea: '',
}

export const requestFilters = [
  {
		label: 'Artículos',
		placeholder: 'Artículos',
		name: 'articulos',
		type: 'text',
		width: 615,
  },
  {
		label: 'No. de solicitud',
		placeholder: 'No. de solicitud',
		name: 'id',
		type: 'text'
  },
  {
		label: 'Fecha de solicitud',
		placeholder: 'Dd/mm/aaaa',
		name: 'cf_fecha_solicitud',
		type: 'text'
  },
  {
    label: 'Nombre',
    placeholder: 'Nombre de quien recoge',
    name: 'collect_name',
    type: 'text',
  },
  {
    label: 'Extensión',
    placeholder: 'Extensión de quien recoge',
    name: 'collect_extension',
    type: 'text',
  },
  {
    label: 'Correo electrónico',
    placeholder: 'Correo electrónico de quien recoge',
    name: 'collect_email',
    type: 'text',
  },
  {
    label: 'Estado',
    placeholder: 'Estado',
    name: 'cf_estatus',
    type: 'dropdown',
    options: [
      {
        text: "Entregado",
        value: "T"
      },
      {
        text: 'Abierta',
        value: 'G'
      },
      {
        text: 'Correo enviado',
        value: 'E'
      },
      {
        text: 'Despachada',
        value: 'D'
      },
      {
        text: 'Cancelada',
        value: 'B'
      },
      {
        text: 'Surtiendo',
        value: 'H'
      },
      {
        text: 'Borrador',
        value: 'X'
      }
    ],
  }
];
