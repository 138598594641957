import React, { useState, useEffect } from 'react'
import BaseModal from '../../../components/BaseModal'
import UtilInputs from '../../../components/UtilInputs'
import { boardForm, defaultBoard } from '../utils'
import { Box } from '@material-ui/core'
import { getOperativeUsers } from '../../../crud/usersCrud'

const ToDoBoardModal = ({ open, setOpen, onConfirmAction, disabledConfirmAction, successForm }) => {
  const [board, setBoard] = useState(defaultBoard)
  const [operativeUsers, setOperativeUser] = useState([])

  useEffect(() => {
    getOperativeUsers()
      .then((res) => setOperativeUser(res.results.map( user => ({ value: user.id, text: `${user.first_name} ${user.last_name}` }) )))
      .catch(console.log)
  }, [])

  const handleChange = ({ target }) => {
    setBoard({...board, [target.name]: target.value}); 
  };

  const sendPost = () => {
    onConfirmAction(board); 
    if( !successForm ) setBoard(defaultBoard)
  }

  const handleCancel = () => {
    setOpen(false)
    setBoard(defaultBoard)
  }

  return (
    <BaseModal
      title="Nuevo tablero"
      open={open}
      onClose={handleCancel}
      onCancel={handleCancel}
      onConfirm={sendPost}
      disableOnConfirm={disabledConfirmAction}
    >
      <UtilInputs
        formValues={board}
        onValuesChange={handleChange}
        inputValues={boardForm(operativeUsers)}
        width="100%"
      />
      {!successForm && <Box className="mt-4 alert alert-secondary" role="alert">
        Por favor, llenar los campos requeridos...
      </Box>}
    </BaseModal>
  )
}

export default ToDoBoardModal
