import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import uuid from "react-uuid";
import { getBase64 } from "../utils/files";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import notice from "./Notice";
import Icon from "./Icon";
import BaseModal from "./BaseModal";
import PDFViewer from "./PDFViewer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  photoLabel: {
    color: theme.palette?.secondary?.main,
    overflow: "hidden",
    cursor: ({ disabled }) => disabled && "pointer",
    marginBottom: "0em",
    textOverflow: "ellipsis",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    gap: 10,
  },
  label: {
    marginLeft: 5,
    fontWeight: 500,
    fontSize: 14,
    margin: "0 0 0 5px",
    color: ({ disabled, touched, hover }) =>
      (touched || hover) && !disabled ? theme.palette.secondary.main : "black",
  },
  photoLink: {
    color: theme.palette?.secondary?.main,
    marginTop: 15,
    textDecoration: "underline",
  },
  namePhoto: {
    color: theme.palette?.secondary?.main,
    cursor: "pointer",
    margin: 0,
  },
  photoContainer: {
    marginTop: 12,
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
}));

const UploadFileButton = ({
  index,
  handleChange,
  value,
  disabled,
  label,
  accept = "image/*",
  multiple = false,
  uploadFunction = null,
  uploadId,
  name,
  buttonTitle = "Elegir archivo",
  //   loading = false,
  deleteFunction = null,
  disableView = false,
}) => {
  const { pathname } = useLocation();

  const { selectedEntry, newEntryResultsForm } = useSelector((store) => store.entry);
  const [touched, setTouched] = useState(false);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles({ disabled, touched, hover });
  const handleCapture = ({ target }) =>
    !multiple ? handleChange(target.files[0], index) : handleChange(target.files);

  const inputId = uuid();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const [fileLoading, setFileLoading] = useState(false);
  const [isFetchFile, setIsFetchFile] = useState(false);
  const parseName = {
    formatos_r: "Formato Almacen R.pdf",
    formatos_f: "Formato Almacen F.pdf",
  };

  const handleInputChange = (e) => {
    e.persist();
    if (uploadId && uploadFunction !== null) {
      setLoading(true);
      setIsFetchFile(false);
      //@formatter:off
      uploadFunction(
        e.target.files[0],
        uploadId,
        parseName[name] ?? e.target.files[0]?.name,
        "asset_acquisition",
        name
      )
        .then(({ data }) => {
          const newE = {
            target: { files: [{ url: data.url, name: data.title }] },
          };
          handleCapture(newE);
          notice(`¡Archivo ${label ?? ""} subido exitosamente!`, "success");
        })
        .catch((err) => {
          notice(
            `Ocurrio un error al intentar subir el archivo ${label ??
              ""}, intenta nuevamente.`
          );
          //@formatter:on
        })
        .finally(() => {
          setLoading(false);
        });
    } else handleCapture(e);
    const file = e?.target?.files[0];
    if (file && !multiple) {
      setPreviewPdf(file);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleInputDelete = (id) => {
    deleteFunction(id).then(({ response }) => console.log(response));
  };

  const setPreviewPdf = (file) => {
    setFileLoading(true);
    getBase64(file)
      .then((data) => {
        setPdfFile(data);
        setPreviewOpen(true);
      })
      .catch((error) => console.log("err", error))
      .finally(() => setFileLoading(false));
  };

  const openModal = (value) => {
    setPreviewOpen(true);
    setIsFetchFile(true);
    if (value?.url) {
      setPdfFile(value.url);
    }
  };
  return (
    <div className={classes.container}>
      <>
        <input
          hidden
          type="file"
          accept={accept}
          id={inputId}
          onChange={handleInputChange}
          disabled={disabled}
          multiple={multiple}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label className={classes.photoLabel} htmlFor={inputId}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {label && <p className={classes.label}>{label}</p>}
              <span
                onFocus={() => setTouched(true)}
                onBlur={() => setTouched(false)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className={`btn btn-light-${
                  disabled ? "secondary" : "primary"
                } border border-${disabled ? "dark" : "primary"} h-100 ${
                  disabled ? "disabled text-dark" : ""
                }`}
                style={{ cursor: disabled && "not-allowed" }}
              >
                {pathname.includes("/bienes-instrumentales/entradas") &&
                newEntryResultsForm[name] !== null
                  ? accept === "application/pdf" &&
                    Object.keys(newEntryResultsForm[name]).length > 0
                    ? "Cambiar archivo"
                    : buttonTitle
                  : accept === "application/pdf" && newEntryResultsForm[name]
                  ? "Cambiar archivo"
                  : buttonTitle}

                {pathname.includes("/bienes-instrumentales/entradas") &&
                newEntryResultsForm[name] !== null
                  ? !disableView &&
                    value &&
                    ((accept === "application/pdf" &&
                      Object.keys(newEntryResultsForm[name]).length > 0) ||
                      (Object.keys(newEntryResultsForm[name]).length > 0 &&
                        newEntryResultsForm[name])) && (
                      <Icon
                        src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                        color="success"
                        style={{ marginLeft: 5, width: 20, height: 20 }}
                        label="Ver PDF"
                        onClick={() => openModal(value)}
                      />
                    )
                  : !disableView &&
                    value &&
                    ((accept === "application/pdf" && newEntryResultsForm[name]) ||
                      newEntryResultsForm[name]) && (
                      <Icon
                        src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                        color="success"
                        style={{ marginLeft: 5, width: 20, height: 20 }}
                        label="Ver PDF"
                        onClick={() => openModal(value)}
                      />
                    )}

                {!disableView &&
                  value &&
                  typeof value === "string" &&
                  value?.includes("https") && (
                    <Icon
                      src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                      color="success"
                      style={{ marginLeft: 5, width: 20, height: 20 }}
                      label="Ver PDF"
                      onClick={() => window.open(value, "blank")}
                    />
                  )}
              </span>
            </div>
          </label>
        </div>
      </>
      <BaseModal
        open={
          accept === "application/pdf" &&
          !loading &&
          previewOpen &&
          !fileLoading &&
          (value?.name || value?.url)
        }
        onClose={() => setPreviewOpen(false)}
        onCancel={() => setPreviewOpen(false)}
        maxWidth="md"
        title={
          <>
            <span className="mr-2">PDF cargado</span>
            <span className={`svg-icon svg-icon-md svg-icon-primary`}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
            </span>
          </>
        }
        cancelLabel="Aceptar"
      >
        {(value?.name || value?.url) && (
          <div className="d-flex justify-content-center">
            {isFetchFile ? (
              <iframe title={value?.name} src={pdfFile} />
            ) : (
              <PDFViewer url={pdfFile} />
            )}
          </div>
        )}
      </BaseModal>
    </div>
  );
};

export default UploadFileButton;
