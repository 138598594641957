import React from 'react'

export const ArticlesGoodTypeColumnFormatter = (cellContent) => {
  return (
    <span className={`label label-lg label-light-${
      cellContent?.toLowerCase() === "stock" ? "primary"
      : cellContent?.toLowerCase() === "recurrente" ? "info" : "danger"} label-inline`}>
      {cellContent}
    </span>
  )
}
