import React from "react";
import { columnStatusCssClasses } from "../../utils/columnFormattersHelpers";

export const RequestStatusColumnFormatter = (cellContent, row) => {

	const STATUSES = {
		// ERNESTO 13/JUL/21 INFO ON GROUP SLACK CHAT
		G: 'Abierta',
		D: 'Despachada',
		B: 'Cancelada',
		H: 'Surtiendo',
		X: 'Borrador',
		// E: 'Correo enviado',
		// A: 'Abierta',
		// ELIAS INFO
		// G: 'Solicitada',
		// E: 'Correo envíado',
		// D: 'Despechada',
		// B: 'Cancelada',
		// H: 'Procesada (afectada)',
		// S: 'Suertiendo',
		// Ui/UX changes at 25/jun/21
		// A: 'Abierta',
		// C: 'Cancelada',
		// S: 'Suertiendo',
		// D: 'Despachada',
		// E: 'Entregada',
		// Before
		// X: 'Borrador',
		// G: 'Guardado',
		// E: 'Enviado',
		// B : 'Cancelado',
		T: 'Entregado',
		'default': 'Sin/estatus'
	};

	return (
		<span
			className={`font-weight-bolder badge badge-pill badge-${
				columnStatusCssClasses[
					cellContent === "T" ? 1
					: cellContent === "H" ? 2
					: cellContent === "B" ? 0
					: cellContent === "D" ? 1
					: cellContent === "G" ? 4
					: "light"
				]
			}`}
		>
			{STATUSES[row.cf_estatus ?? 'default']}
		</span>
	);
};
