import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { initialSelectedArtWork } from '../../app/utils/artWorksData';

export const actionType = {
    SetArtWorks: '[ARTWORK] SET_ARTWORKS',
    SetArtWorkDrops: '[ARTWORK] SET_ARTWORK_DROPS',
    SetSelectedArtWork: '[ARTWORK] SET_SELECTED_ARTWORK',
    SetSelectedArtWorksIds: '[ARTWORK] SET_SELECTED_ARTWORK_IDS',
    SetFilteredArtWork: '[ARTWORK] SET_FILTERED_ARTWORK',
    SetLoading: '[ARTWORK] SET_LOADING',
    ClearSelectedArtWork: '[ARTWORK] CLEAR_SELECTED_ARTWORK',
    SetCurrentArtworkTab: '[ARTWORK] SET_CURRENT_ARTWORK_TAB',
    SetEnableInputs: "[ARTWORK] SET_ENABLE_INPUTS",
    SetResetArtWorkForm: "[ARTWORK] SET_RESET_ARTWORK_FORM",
    ClearFilteredArtwork: "[ARTWORK] CLEAR_FILTERED_ARTWORK"
};
//console.log({user: store.getState().user.name})
export const initialState = {
    artWorks: {
      count: 0,
      results: [],
      per_page: 50,
      page: 1,
    },
    artWorkDrops: {
      count: 0,
      results: [],
      per_page: 50,
      page: 1,
    },
    selectedArtWork: initialSelectedArtWork,
    filteredArtWork: initialSelectedArtWork,
    selectedArtWorksIds: [],
    loading: false,
    isSearchTab: false,
    isResultsTab: false,
    isNewTab: false,
    enableInputs: true,
    resetArtWorkForm: {}
};

export const reducer = persistReducer(
  { storage, key: 'artWork', whitelist: ["selectedArtWork", "filteredArtWork", "selectedArtWorksIds", "tabs"] },
  (state = initialState, action) => {
    switch (action.type) {
			case actionType.SetArtWorks:
				return { ...state, artWorks: action.payload };

      case actionType.SetArtWorkDrops:
				return { ...state, artWorkDrops: action.payload };

			case actionType.SetSelectedArtWork:
				return { ...state, selectedArtWork: action.payload };

      case actionType.SetFilteredArtWork:
				return { ...state, filteredArtWork: action.payload };

      case actionType.SetSelectedArtWorksIds:
				return { ...state, selectedArtWorksIds: action.payload };

			case actionType.SetLoading:
				return { ...state, loading: action.payload };

			case actionType.ClearSelectedArtWork:
				return { ...initialState };

      case actionType.SetCurrentArtworkTab:
        return { ...state, ...action.payload };

      case actionType.SetEnableInputs:
        return {
          ...state,
          enableInputs: action.payload
        };

      case actionType.SetResetArtWorkForm:
        return {
          ...state,
          resetArtWorkForm: action.payload
        };

      case actionType.ClearFilteredArtwork:
        return {
          ...state,
          filteredArtWork: initialSelectedArtWork
      };

      default:
				return state;
		}
  }
)

export const setArtWorks = data => ({ type: actionType.SetArtWorks, payload: data });

export const setArtWorkDrops = data => ({ type: actionType.SetArtWorkDrops, payload: data });

export const setSelectedArtWork = artWork => ({ type: actionType.SetSelectedArtWork, payload: artWork });

export const setFilteredArtWork = artWork => ({ type: actionType.SetFilteredArtWork, payload: artWork });

export const setSelectedArtWorkIds = data => ({ type: actionType.SetSelectedArtWorksIds, payload: data });

export const setLoading = (isLoading) => ({ type: actionType.SetLoading, payload: isLoading });

export const clearSelectedArtWork = () => ({ type: actionType.ClearSelectedArtWork });

export const setCurrentArtworkTab = newTab => ({ type: actionType.SetCurrentArtworkTab, payload: newTab });

export const setEnableInputs = enable => ({type: actionType.SetEnableInputs, payload: enable});

export const setResetArtWorkForm = formData => ({type: actionType.SetResetArtWorkForm, payload: formData});

export const clearFilteredArtwork = () => ({ type: actionType.ClearFilteredArtwork });