import React from "react";
import {
  ListAltTwoTone as CatalogIcon,
  // ClassTwoTone as ArticleIcon,
  WorkTwoTone as WorkIcon,
  DriveEtaTwoTone as VehicleIcon,
  DriveEtaSharp as SubVehicleIcon,
  ApartmentTwoTone as EdificeIcon,
  Person as PersonIcon,
  LocalShipping,
  AssignmentTurnedIn,
} from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {
  getPersons,
  getEdificios,
  getMarcaByName,
  getCABMSFilter,
  getEdificiesFilter,
  getVehicleBrandFilter,
  getVehicleSubBrandFilter,
  getCostCenterList,
  getSecondmentsList,
  supplierCRUD,
  signatureCRUD,
  getResponsableFilterID,
  partidaCrud,
} from "../crud/catalogsCrud";
import { getFilterAreasId } from "../../app/crud/areasFilterCrud";
import { getActiveAdscriptions } from "../crud/usersCrud";

export const icons = (
  permisos,
  id,
  onSearch,
  onEdit,
  onNew,
  onSave,
  onDelete,
  onCancel,
  onReportPdf,
  onReportExcel,
  getMultiplePDF,
  disableSearch,
  disableEdit,
  disableNew,
  disableSave,
  disableDelete,
  disableCancel,
  viewMode,
  hasReport,
  disablePDFReport = false,
  disableExcelReport = false
) => {
  //CABMS PERMISOS
  const cabmsCrear = permisos.permisos.includes("i-crear-cabms");
  const cabmsEditar = permisos.permisos.includes("i-editar-cabms");
  const cabmsEliminar = permisos.permisos.includes("i-eliminar-cabms");
  //EDIFICIOS PERMISOS
  const edificioCrear = permisos.permisos.includes("i-crear-edificio");
  const edificioEditar = permisos.permisos.includes("i-editar-edificio");
  const edificioEliminar = permisos.permisos.includes("i-eliminar-edificio");
  //VEHICULOS PERMISOS
  const brandCrear = permisos.permisos.includes("i-crear-marca-vehiculo");
  const brandEditar = permisos.permisos.includes("i-editar-marca-vehiculo");
  const brandEliminar = permisos.permisos.includes("i-eliminar-marca-vehiculo");
  //SUB VEHICULOS PERMISOS
  const subBrandCrear = permisos.permisos.includes("i-crear-submarca-vehiculo");
  const subBrandEditar = permisos.permisos.includes("i-editar-submarca-vehiculo");
  const subBrandEliminar = permisos.permisos.includes("i-eliminar-submarca-vehiculo");
  //RESPONSABLE PERMISOS
  const responsableCrear = permisos.permisos.includes("i-crear-responsables");
  const responsableEditar = permisos.permisos.includes("i-editar-responsables");
  const responsableEliminar = permisos.permisos.includes("i-eliminar-responsables");
  //PARTIDAS PERMISOS
  const partidasEditar = permisos.permisos.includes("i-editar-partida-presupuestal");
  const partidasEliminar = permisos.permisos.includes("i-eliminar-partida-presupuestal");
  const partidasCrear = permisos.permisos.includes("i-crear-partida-presupuestal");
  //AREAS PERMISOS
  const areasCrear = permisos.permisos.includes("i-crear-areas");
  const areasEditar = permisos.permisos.includes("i-editar-areas");
  const areasEliminar = permisos.permisos.includes("i-eliminar-areas");
  //PROVEEDOR PERMISOS
  const proveedor = permisos.permisos.includes("i-catalogo-proveedores");
  return [
    {
      label: "Buscar",
      onClick: () => onSearch(),
      style: { margin: "0 30px" },
      image: "/media/svg/icons/General/Search.svg",
      disabled: disableSearch,
      color: "info",
      // hide: ,
    },

    (id === "area" && areasCrear) ||
    (id === "cabms" && cabmsCrear) ||
    (id === "edifice" && edificioCrear) ||
    (id === "vehicleBrand" && brandCrear) ||
    (id === "vehicleSubBrand" && subBrandCrear) ||
    (id === "responsible" && responsableCrear) ||
    (id === "supplier" && proveedor) ||
    (id === "partida" && partidasCrear) ||
    id === "signature" ||
    id === "ccostos"
      ? {
          label: "Nuevo",
          onClick: onNew,
          style: { margin: "0 30px" },
          image: "/media/svg/icons/Files/File.svg",
          color: "info",
        }
      : {
          label: "Nuevo",
          onClick: onNew,
          style: { margin: "0 30px" },
          image: "/media/svg/icons/Files/File.svg",
          disabled: disableNew,
          color: "info",
          hide: "none",
        },

    (id === "area" && areasEditar) ||
    (id === "cabms" && cabmsEditar) ||
    (id === "edifice" && edificioEditar) ||
    (id === "vehicleBrand" && brandEditar) ||
    (id === "vehicleSubBrand" && subBrandEditar) ||
    (id === "responsible" && responsableEditar) ||
    (id === "supplier" && proveedor) ||
    (id === "partida" && partidasEditar) ||
    id === "signature" ||
    id === "ccostos"
      ? {
          label: "Editar",
          onClick: onEdit,
          style: { margin: "0 30px" },
          image: "/media/svg/icons/General/Edit.svg",
          color: "warning",
        }
      : {
          label: "Editar",
          onClick: onEdit,
          style: { margin: "0 30px" },
          image: "/media/svg/icons/General/Edit.svg",
          disabled: disableEdit,
          color: "warning",
          hide: "none",
        },
    id !== "secondments" && {
      label: "Guardar",
      onClick: onSave,
      style: { margin: "0 30px" },
      image: "/media/svg/icons/General/Save.svg",
      disabled: disableSave,
      color: "primary",
    },
    (id === "area" && areasEliminar) ||
    (id === "cabms" && cabmsEliminar) ||
    (id === "edifice" && edificioEliminar) ||
    (id === "vehicleBrand" && brandEliminar) ||
    (id === "vehicleSubBrand" && subBrandEliminar) ||
    (id === "responsible" && responsableEliminar) ||
    (id === "supplier" && proveedor) ||
    (id === "partida" && partidasEliminar) ||
    id === "ccostos"
      ? {
          label: "Eliminar",
          onClick: onDelete,
          style: { margin: "0 30px" },
          image: "/media/svg/icons/General/Trash.svg",
          color: "danger",
        }
      : {
          label: "Eliminar",
          onClick: onDelete,
          style: { margin: "0 30px" },
          image: "/media/svg/icons/General/Trash.svg",
          disabled: disableDelete,
          color: "danger",
          hide: "none",
        },

    {
      label: "Cancelar",
      onClick: onCancel,
      style: { margin: "0 30px" },
      image: "/media/svg/icons/Navigation/Close.svg",
      disabled: disableCancel,
      color: "danger",
    },
    (hasReport.pdf || viewMode === "supplier") && {
      label: viewMode === "signature" ? "Firma PDF" : "Reporte PDF",
      onClick: onReportPdf,
      style: { margin: "0 30px" },
      image: "/media/svg/icons/Files/DownloadedFile.svg",
      color: "success",
      disabled: disablePDFReport,
    },
    (hasReport.excel || viewMode === "supplier" || viewMode === "partida") && {
      label: "Reporte Excel",
      onClick: onReportExcel,
      style: { margin: "0 30px" },
      image: "/media/svg/icons/Files/DownloadedFile.svg",
      color: "success",
      disabled: disableExcelReport,
    },
    id === "area" &&
      (hasReport.pdf || viewMode === "supplier") && {
        label: "Resguardos PDF",
        onClick: getMultiplePDF,
        style: { margin: "0 30px" },
        image: "/media/svg/icons/Files/DownloadedFile.svg",
        color: "success",
        disabled: disablePDFReport,
      },
  ];
};

export const iconSignature = (openModal, statusFirma, viewType) => {
  const disableSwitch =
    statusFirma === false ||
    viewType === "INIT" ||
    viewType === "SEARCH" ||
    viewType === "NEW" ||
    viewType === "EDIT"
      ? true
      : false;
  return [
    {
      label: "Baja",
      onClick: openModal,
      style: { margin: "0 30px" },
      image: "/media/svg/icons/General/Trash.svg",
      disabled: disableSwitch,
      color: "danger",
    },
  ];
};
export const defaultDrop = { motivo_baja: "", tipo_baja: "" };

export const defaultVehiculesBrand = {
  id: "",
  desc_marca: "",
};

//PLEASE DON'T REMOVE DISABLED PROPERTY ON ANY ITEM, BECAUSE USES WHEN USER IS ON RESULTS VIEW
export const vehiculesBrandForm = [
  {
    label: "Nombre",
    placeholder: "Nombre",
    name: "desc_marca",
    type: "text",
    uppercase: true,
    disabled: false,
  },
];

export const defaultArea = {
  num_area: "",
  g: "",
  sg: "",
  ssg: "",
  nom_area: "",
  nom_sarea: "",
  nom_ssarea: "",
  cen_costos: "",
  num_ubica: "",
  num_empleado: "",
  custom_anio_pdf: "",
  num_resguardo: "",
  telefono_a: "",
  celular_a: "",
  mail_a: "",
  edificio_nombre: "",
  piso: "",
  oficina: "",
  suboficina: "",
  responsables: [],
};

//PLEASE DON'T REMOVE DISABLED PROPERTY ON ANY ITEM, BECAUSE USES WHEN USER IS ON RESULTS VIEW
export const areaForm = (groups, subgroups, subsubgroups, viewType) => {
  return [
    {
      label: "Clave de Área",
      name: "num_area",
      type: "text",
      required: true,
      width: 270,
      disabled: false,
    },
    {
      label: "Año resguardo PDF",
      name: "custom_anio_pdf",
      type: "text",
      required: true,
      width: 270,
      disabled: false,
    },
    {
      label: "Grupo",
      name: "g",
      type: "dropdown",
      options: groups,
      disabled: false,
      width: 290,
    },
    {
      label: "Subgrupo",
      name: "sg",
      type: "dropdown",
      options: subgroups,
      disabled: false,
      width: 290,
    },
    {
      label: "Subsubgrupo",
      name: "ssg",
      type: "dropdown",
      options: subsubgroups,
      disabled: false,
      width: 390,
    },
    {
      type: "line-break",
    },
    {
      label: "Área",
      name: "nom_area",
      type: "text",
      disabled: false,
      width: 552,
    },
    {
      type: "line-break",
    },
    {
      label: "Subárea",
      name: "nom_sarea",
      type: "text",
      disabled: false,
      width: 552,
    },
    {
      type: "line-break",
    },
    {
      label: "Subsubárea",
      name: "nom_ssarea",
      type: "text",
      disabled: false,
      width: 552,
    },
    {
      title: "Ubicaciones",
      type: "line-break",
    },
    {
      label: "Edificio",
      name: "edificio_nombre",
      type: "search",
      searchFunction: getEdificios,
      disabled: false,
    },
    {
      label: "Piso",
      name: "piso",
      type: "text",
      disabled: false,
    },
    {
      label: "Oficina",
      name: "oficina",
      type: "text",
      disabled: false,
    },
    // {
    //   label: "Suboficina",
    //   name: "soficina",
    //   type: "text",
    //   disabled: false,
    // },
    {
      label: "Suboficina",
      name: "suboficina",
      type: "text",
      disabled: false,
    },
    {
      separator: true,
      type: "line-break",
    },
    {
      label: "Responsables",
      name: "responsables",
      type: "search-multiple",
      searchFunction: getPersons,
      width: 595,
      disabled: false,
    },
    {
      separator: true,
      type: "line-break",
    },
    {
      label: "No. de resguardo",
      name: "num_resguardo",
      type: "text",
      disabled: viewType === "SEARCH" ? false : true,
      width: "100%",
      helperText: "El número de resguardo se generará automáticamente",
    },
    {
      label: "Teléfono",
      name: "telefono_a",
      type: "text",
      disabled: false,
      width: "100%",
    },
    {
      label: "Celular",
      name: "celular_a",
      type: "text",
      disabled: false,
      width: "100%",
    },
    {
      label: "Correo electrónico",
      name: "mail_a",
      type: "text",
      disabled: false,
      width: "100%",
    },
    {
      type: "line-break",
    },
  ];
};

export const defaultCABMS = {
  cf_grupo: "",
  cf_subgrupo: "",
  cf_clase: "",
  cf_subclase: "",
  cf_tipo: "",
  cf_denominacion: "",
};

//PLEASE DON'T REMOVE DISABLED PROPERTY ON ANY ITEM, BECAUSE USES WHEN USER IS ON RESULTS VIEW
export const cabmsForm = [
  {
    label: "Grupo",
    name: "cf_grupo",
    type: "text",
    uppercase: true,
    disabled: false,
  },
  {
    label: "Subgrupo",
    name: "cf_subgrupo",
    type: "text",
    uppercase: true,
    disabled: false,
  },
  {
    label: "Clase",
    name: "cf_clase",
    type: "text",
    uppercase: true,
    disabled: false,
  },
  {
    label: "Subclase",
    name: "cf_subclase",
    type: "text",
    uppercase: true,
    disabled: false,
  },
  {
    label: "Consecutivo",
    name: "cf_tipo",
    type: "text",
    uppercase: true,
    disabled: false,
  },
  {
    label: "Concepto",
    name: "cf_denominacion",
    type: "text",
    multiline: true,
    rows: 4,
    width: 552,
    disabled: false,
  },
];

export const defaultVehiculesSubBrand = {
  id: "",
  desc_smarca: "",
  id_marca: "",
};

//PLEASE DON'T REMOVE DISABLED PROPERTY ON ANY ITEM, BECAUSE USES WHEN USER IS ON RESULTS VIEW
export const vehiculesSubBrandForm = [
  {
    label: "Submarca",
    placeholder: "Submarca",
    name: "desc_smarca",
    type: "text",
    disabled: false,
  },
  {
    label: "Marca",
    placeholder: "Marca",
    name: "id_marca",
    type: "search",
    searchFunction: getMarcaByName,
    disabled: false,
  },
];

export const defaultArticles = {
  cf_grupo: "",
  cf_subgrupo: "",
  cf_clase: "",
  cf_subclase: "",
  cf_tipo: "",
  cf_denominacion: "",
};

//PLEASE DON'T REMOVE DISABLED PROPERTY ON ANY ITEM, BECAUSE USES WHEN USER IS ON RESULTS VIEW
export const articlesForm = [
  {
    label: "Grupo",
    name: "cf_grupo",
    type: "text",
    disabled: false,
  },
  {
    label: "Subgrupo",
    name: "cf_subgrupo",
    type: "text",
    disabled: false,
  },
  {
    label: "Clase",
    name: "cf_clase",
    type: "text",
    disabled: false,
  },
  {
    label: "Subclase",
    name: "cf_subclase",
    type: "text",
    disabled: false,
  },
  {
    label: "Tipo",
    name: "cf_tipo",
    type: "text",
    disabled: false,
  },
  {
    label: "Denominación",
    name: "cf_denominacion",
    type: "text",
    multiline: true,
    rows: 3,
    width: "30vw",
    disabled: false,
  },
];

export const defaultEdifices = {
  cve_edificio: "",
  nom_edificio: "",
  direccion: "",
  colonia: "",
  ciudad: "",
  estado: "",
  delegacion: "",
  cp: "",
  observ: "",
};

export const edificiesForm = [
  {
    label: "Clave Edificio",
    placeholder: "Clave Edificio",
    name: "cve_edificio",
    type: "text",
    uppercase: true,
    disabled: false,
  },
  {
    label: "Nombre Edificio",
    placeholder: "Nombre Edificio",
    name: "nom_edificio",
    type: "text",
    disabled: false,
  },
  {
    label: "Dirección",
    placeholder: "Dirección",
    name: "direccion",
    type: "text",
    disabled: false,
  },
  {
    label: "Alcaldía/Municipio",
    placeholder: "Alcaldía/Municipio",
    name: "delegacion",
    type: "text",
    disabled: false,
  },
  {
    label: "Colonia",
    placeholder: "Colonia",
    name: "colonia",
    type: "text",
    disabled: false,
  },
  {
    label: "Ciudad",
    placeholder: "Ciudad",
    name: "ciudad",
    type: "text",
    disabled: false,
  },
  {
    label: "Estado",
    placeholder: "Estado",
    name: "estado",
    type: "text",
    disabled: false,
  },
  {
    label: "Código Postal",
    placeholder: "Código Postal",
    name: "cp",
    type: "text",
    uppercase: true,
    disabled: false,
  },
  {
    label: "Observaciones",
    placeholder: "Observaciones",
    name: "observ",
    type: "text",
    uppercase: true,
    multiline: true,
    rows: 4,
    width: "30vw",
    disabled: false,
  },
];

export const defaultCenterCosts = {
  cos_id: "",
  cos_clave: "",
  cos_nombre: "",
  cos_responsable: "",
  cos_telefono: "",
  cos_nivel: "",
  estatus: "",
};

//PLEASE DON'T REMOVE DISABLED PROPERTY ON ANY ITEM, BECAUSE USES WHEN USER IS ON RESULTS VIEW
export const centerCostsForm = [
  {
    label: "ID",
    placeholder: "ID",
    name: "cos_id",
    type: "text",
    disabled: false,
  },
  {
    label: "Clave",
    placeholder: "Clave",
    name: "cos_clave",
    type: "text",
    disabled: false,
  },
  {
    label: "Nombre",
    placeholder: "Nombre",
    name: "cos_nombre",
    type: "text",
    uppercase: true,
    multiline: true,
    rows: 3,
    disabled: false,
  },
  {
    label: "Responsable",
    placeholder: "Responsable",
    name: "cos_responsable",
    type: "text",
    disabled: false,
  },
  {
    label: "Telefono",
    placeholder: "Telefono",
    name: "cos_telefono",
    type: "text",
    disabled: false,
  },
  {
    label: "Nivel",
    placeholder: "Nivel",
    name: "cos_nivel",
    type: "text",
    disabled: false,
  },
  {
    label: "Estado",
    placeholder: "Estado",
    name: "estatus",
    type: "text",
    disabled: false,
  },
];

//PLEASE DON'T REMOVE DISABLED PROPERTY ON ANY ITEM, BECAUSE USES WHEN USER IS ON RESULTS VIEW
export const responsibleForm = [
  {
    label: "Nombre",
    placeholder: "Nombre",
    name: "nombre",
    type: "text",
    disabled: false,
  },
  {
    label: "Número de Empleado",
    placeholder: "Número de Empleado",
    name: "num_empleado",
    type: "text",
    disabled: false,
  },
  {
    label: "RFC",
    placeholder: "RFC",
    name: "rfc",
    type: "text",
    disabled: false,
  },
  {
    label: "CURP",
    placeholder: "CURP",
    name: "curp",
    type: "text",
    disabled: false,
  },
  {
    label: "Fecha de Nacimiento",
    name: "fenacim",
    type: "date",
    isFullYear: true,
    disabled: false,
  },
  {
    label: "Genero",
    placeholder: "Genero",
    name: "genero",
    type: "dropdown",
    options: [
      { value: "M", text: "MASCULINO" },
      { value: "F", text: "FEMENINO" },
    ],
    disabled: false,
  },
  {
    label: "Tipo de Nómina",
    placeholder: "Tipo de Nómina",
    name: "tiponomina",
    type: "dropdown",
    options: [
      { value: "B", text: "BASE" },
      { value: "C", text: "CONFIANZA" },
      { value: "H", text: "HONORARIOS" },
      { value: "S", text: "JUBILADOS" },
      { value: "M", text: "MANDOS MEDIOS" },
      { value: "R", text: "SENADOR" },
      { value: "A", text: "SENADORA" },
      { value: "T", text: "SERVICIO TÉCNICO" },
    ],
    disabled: false,
  },
  {
    type: "line-break",
  },
  {
    label: "Adscripción",
    placeholder: "Adscripción",
    name: "adscripcion",
    type: "text",
    uppercase: true,
    multiline: true,
    rows: 4,
    disabled: false,
    width: 550,
  },
];

export const defaultResponsible = {
  num_empleado: "",
  no_empleado: "",
  nombre: "",
  rfc: "",
  curp: "",
  fenacim: "",
  genero: "",
  adscripcion: "",
  tiponomina: "",
};

// SUPPLIER
export const supplierForm = [
  {
    label: "Numero de Proveedor",
    name: "num_prove",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Número de refrendo",
    name: "numrefrendo",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Razon social",
    name: "razon_social",
    type: "text",
    disabled: false,
    width: "100%",
  },
  {
    label: "Representante",
    name: "representante",
    type: "text",
    disabled: false,
    width: "100%",
  },
  {
    label: "Domicilo completo",
    name: "domicilio",
    type: "text",
    disabled: false,
    width: "100%",
  },
  {
    label: "Calle",
    name: "dom_prov",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Colonia",
    name: "colonia_prov",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Código postal",
    name: "cp_prov",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Correo electrónico",
    name: "mailprov",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Celúlar",
    name: "celular",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    type: "line-break",
  },
  {
    label: "Teléfonos",
    name: "telefonos",
    type: "text",
    multiline: true,
    rows: 5,
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Notas",
    name: "nota",
    type: "text",
    width: "47.6%",
    disabled: false,
    multiline: true,
    rows: 5,
  },
  {
    label: "Fecha de actualización",
    name: "fecact",
    type: "date",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Fecha de alta",
    name: "fecalta",
    type: "date",
    disabled: false,
    width: "47.6%",
  },
];

export const defaultSupplier = {
  num_prove: "",
  numrefrendo: "",
  razon_social: "",
  representante: "",
  domicilio: "", // direccion completa
  dom_prov: "", // CALLE
  colonia_prov: "", // COLONIA
  cp_prov: "", // CP
  telefonos: "",
  celular: "",
  mailprov: "",
  nota: "",
  fecact: "",
  fecalta: "",
};
const partida_presupuestales = [
  {
    label: "No. de partida",
    name: "num_partida",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Descripción partida",
    name: "desc_partida",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "No. de capítulo",
    name: "ccapitulo",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Descripción capítulo",
    name: "ccapitulo_desc",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "No. genérico",
    name: "cpartida",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Descripción genérico",
    name: "cpartida_desc",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "No. específico",
    name: "cspartida",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Descripción específico",
    name: "cspartida_desc",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Tipo clasificador",
    name: "tipo_clasificador",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "Fecha alta",
    name: "fecha_alta",
    type: "date",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "No. de cuenta",
    name: "no_cuenta",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
  {
    label: "No. Subcuenta",
    name: "no_scuenta",
    type: "text",
    disabled: false,
    width: "47.6%",
  },
];

export const defaultPartida = {
  num_partida: "",
  desc_partida: "",
  ccapitulo: "",
  ccapitulo_desc: "",
  cpartida: "",
  cpartida_desc: "",
  cspartida: "",
  cspartida_desc: "",
  tipo_clasificador: "",
  fecha_alta: "",
  no_cuenta: "",
  no_scuenta: "",
};
export const instrumentalGoodsItems = (Permisos) => [
  {
    hasReport: { pdf: true, excel: true },
    name: "CABMS",
    id: "cabms",
    icon: <CatalogIcon />,
    viewType: "INIT",
    loading: false,
    defaultValues: defaultCABMS,
    inputValues: defaultCABMS,
    inputs: cabmsForm,
    results: [],
    requestList: getCABMSFilter,
    queryToSearch: "",
    ids: [],
    disabled: !Permisos.includes("i-ver-cabms"),
  },
  // { hasReport: { pdf:true, excel:true }, name: "Artículos", id: "article", icon: <ArticleIcon/>, viewType: 'INIT', loading: false, defaultValues: defaultArticles, inputValues: defaultArticles, inputs: articlesForm, results: [], requestList: getArticleFilter, queryToSearch:"", ids: [], disabled: !Permisos.includes("i-ver-articulo") },
  {
    hasReport: { pdf: true, excel: true },
    name: "Edificios",
    id: "edifice",
    icon: <EdificeIcon />,
    viewType: "INIT",
    loading: false,
    defaultValues: defaultEdifices,
    inputValues: defaultEdifices,
    inputs: edificiesForm,
    results: [],
    requestList: getEdificiesFilter,
    queryToSearch: "",
    ids: [],
    disabled: !Permisos.includes("i-ver-edificio"),
  },
  {
    hasReport: { pdf: true, excel: true },
    name: "Marcas de vehículo",
    id: "vehicleBrand",
    icon: <VehicleIcon />,
    viewType: "INIT",
    loading: false,
    defaultValues: defaultVehiculesBrand,
    inputValues: defaultVehiculesBrand,
    inputs: vehiculesBrandForm,
    results: [],
    requestList: getVehicleBrandFilter,
    queryToSearch: "",
    ids: [],
    disabled: !Permisos.includes("i-ver-marca-vehiculo"),
  },
  {
    hasReport: { pdf: true, excel: true },
    name: "Submarcas de vehículo",
    id: "vehicleSubBrand",
    icon: <SubVehicleIcon />,
    viewType: "INIT",
    loading: false,
    defaultValues: defaultVehiculesSubBrand,
    inputValues: defaultVehiculesSubBrand,
    inputs: vehiculesSubBrandForm,
    results: [],
    requestList: getVehicleSubBrandFilter,
    queryToSearch: "",
    ids: [],
    disabled: !Permisos.includes("i-ver-submarca-vehiculo"),
  },
  {
    hasReport: { pdf: true, excel: true },
    name: "Áreas",
    id: "area",
    icon: <WorkIcon />,
    viewType: "INIT",
    loading: false,
    defaultValues: defaultArea,
    inputValues: defaultArea,
    inputs: areaForm,
    results: [],
    requestList: getFilterAreasId,
    queryToSearch: "",
    ids: [],
    disabled: !Permisos.includes("i-ver-areas"),
  },
  {
    hasReport: { pdf: true, excel: true },
    name: "Responsables",
    id: "responsible",
    icon: <PersonIcon />,
    viewType: "INIT",
    loading: false,
    defaultValues: defaultResponsible,
    inputValues: defaultResponsible,
    inputs: responsibleForm,
    results: [],
    requestList: getResponsableFilterID,
    queryToSearch: "",
    ids: [],
    disabled: !Permisos.includes("i-ver-responsables"),
  },
  {
    hasReport: { pdf: false, excel: false },
    name: "Proveedores",
    id: "supplier",
    icon: <LocalShipping />,
    viewType: "INIT",
    loading: false,
    defaultValues: defaultSupplier,
    inputValues: defaultSupplier,
    inputs: supplierForm,
    results: [],
    requestList: supplierCRUD.getFilterProveedoresId,
    queryToSearch: "",
    ids: [],
    disabled: !Permisos.includes("i-catalogo-proveedores"),
  },
  {
    hasReport: { pdf: false, excel: false },
    name: "Partidas presupuestales",
    id: "partida",
    icon: <AssignmentIcon />,
    viewType: "INIT",
    loading: false,
    defaultValues: defaultPartida,
    inputValues: defaultPartida,
    inputs: partida_presupuestales,
    results: [],
    requestList: partidaCrud.getFilterPartida,
    queryToSearch: "",
    ids: [],
    disabled: !Permisos.includes("i-ver-partida-presupuestal"),
  },
];

// WAREHOUSE CATALOGS

const defaultCostCenter = {
  cos_id: "",
  cos_clave: "",
  cos_nombre: "",
  cos_responsable: "",
  cos_telefono: "",
  cos_nivel: "",
  estatus: "",
};

const costCenterForm = [
  {
    label: "ID",
    name: "cos_id",
    type: "text",
  },
  {
    label: "Clave",
    name: "cos_clave",
    type: "text",
  },
  {
    label: "Nombre",
    name: "cos_nombre",
    type: "text",
  },
  {
    label: "Responsable",
    name: "cos_responsable",
    type: "text",
  },
  {
    label: "Teléfono",
    name: "cos_telefono",
    type: "text",
  },
  {
    label: "Nivel",
    name: "cos_nivel",
    type: "text",
  },
  {
    label: "Estatus",
    name: "estatus",
    type: "text",
  },
];

const defaultSecondments = {
  cf_cvearea: "",
  cf_prefijo: "",
  persona: "",
  cf_estatus: "",
};

const secondmentForm = [
  {
    label: "Clave de área",
    name: "cf_cvearea",
    type: "text",
  },
  {
    label: "Prefijo",
    name: "cf_prefijo",
    type: "text",
  },
  {
    label: "Persona",
    name: "persona",
    type: "text",
  },
  {
    label: "Estatus",
    name: "cf_estatus",
    type: "text",
  },
];

const defaultSignature = {
  clave_firma: "",
  adscripcion: "",
  area_nombre: "",
  area: "",
  titular: "",
  prefijo: "",
  estatus: true,
  nombre_firma_1: "",
  fecha_actualizacion_firma: "",
  firma_pdf: null,
};

const signatureForm = [
  {
    label: "Clave de firma",
    name: "clave_firma",
    type: "text",
    disabled: true,
  },
  {
    label: "Adscripción ",
    name: "adscripcion",
    type: "search",
    searchFunction: getActiveAdscriptions,
    onlyResult: true,
  },
  {
    label: "Área",
    name: "area_nombre",
    type: "text",
    onlySearch: false,
    disabled: true,
  },
  {
    label: "Titular",
    name: "titular",
    type: "text",
    onlySearch: false,
    disabled: true,
  },
  {
    label: "Prefijo",
    name: "prefijo",
    type: "text",
    disabled: true,
  },
  {
    label: "Estatus",
    name: "estatus",
    type: "switch",
    width: 100,
    disabled: true,
  },
  {
    label: "Cargar PDF",
    name: "firma_pdf",
    type: "file",
    accept: "application/pdf",
    disableView: true,
  },
  {
    label: "Nombre firma",
    name: "nombre_firma_1",
    type: "text",
  },
  {
    label: "Fecha de actualización",
    name: "fecha_actualizacion_firma",
    type: "date",
    disabled: true,
  },
];

export const warehpuseItems = [
  {
    hasReport: { pdf: true, excel: true },
    name: "Centros de costos",
    id: "ccostos",
    icon: <EdificeIcon />,
    viewType: "INIT",
    loading: false,
    defaultValues: defaultCostCenter,
    inputValues: defaultCostCenter,
    inputs: costCenterForm,
    results: [],
    requestList: getCostCenterList,
    queryToSearch: "",
  },
  {
    hasReport: { pdf: true, excel: true },
    name: "Adscripciones",
    id: "secondments",
    icon: <CatalogIcon />,
    viewType: "INIT",
    loading: false,
    defaultValues: defaultSecondments,
    inputValues: defaultSecondments,
    inputs: secondmentForm,
    results: [],
    requestList: getSecondmentsList,
    queryToSearch: "",
  },
  {
    hasReport: { pdf: true, excel: false },
    name: "Firmas",
    id: "signature",
    icon: <AssignmentTurnedIn />,
    viewType: "INIT",
    loading: false,
    defaultValues: defaultSignature,
    inputValues: defaultSignature,
    inputs: signatureForm,
    results: [],
    requestList: signatureCRUD.fetchSigntures,
    queryToSearch: "",
  },
];

// Only in areas at current moment
export const copyFeatureValidCatalogs = ["area"];

export const inputsCatalogAreasLabelNameToCopy = [
  {
    label: "Clave de Área",
    name: "num_area",
  },
  {
    label: "Grupo",
    name: "g",
  },
  {
    label: "Subgrupo",
    name: "sg",
  },
  {
    label: "Subsubgrupo",
    name: "ssg",
  },
  {
    label: "Área",
    name: "nom_area",
  },
  {
    label: "Subárea",
    name: "nom_sarea",
  },
  {
    label: "Subsubárea",
    name: "nom_ssarea",
  },
  {
    label: "Edificio",
    name: "edificio_nombre",
  },
  {
    label: "Piso",
    name: "piso",
  },
  {
    label: "Oficina",
    name: "oficina",
  },
  {
    label: "Suboficina",
    // name: "soficina",
    name: "suboficina",
  },
  {
    label: "Responsables",
    name: "responsables",
  },
  {
    label: "No. de resguardo",
    name: "num_resguardo",
  },
  {
    label: "Teléfono",
    name: "telefono_a",
  },
  {
    label: "Celular",
    name: "celular_a",
  },
  {
    label: "Correo electrónico",
    name: "mail_a",
  },
];
