import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllDigitalVaultFolders,
  setDigitalVaultFolders,
} from "../../../../redux/ducks/digitalVault.duck";
import BaseModal from "../../../components/BaseModal";
import notice from "../../../components/Notice";
import UtilInputs from "../../../components/UtilInputs";
import {
  createNewFolder,
  fetchDigitalVaultFolders,
} from "../../../crud/digitalVaultCrud";
import { newFolderInputs } from "../../../utils/digitalVault";
const initialFormValues = {
  name: "",
  users: [],
};

const DigitalVaultNewFolderModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { currentDigitalVaultId, digitalVaultFolders } = useSelector(
    (store) => store.digitalVault
  );
  const { operativeUsers } = useSelector((store) => store.users);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);

  const handleOnConfirm = async () => {
    const { name, users, folder_type, date_start, date_end } = formValues;
    if (name === "") return notice("Debes agregar un nombre válido");
    // else if (!users.length) return notice("Debes agregar usuario");

    setLoading(true);
    let body = {
      name,
      folder_type,
      parent: currentDigitalVaultId,
      users: users.map(({ id }) => id),
    };
    if (date_start) body = { ...body, date_start };
    if (date_end) body = { ...body, date_end };
    try {
      const newFolder = await createNewFolder(body);
      onClose();
      setFormValues(initialFormValues);
      await dispatch(
        setDigitalVaultFolders([
          { ...newFolder, subfolders: [], files: [] },
          ...digitalVaultFolders,
        ])
      );
      notice("Carpeta creada exitosamente", "success");
      try {
        const newDigitalVaultFolders = await fetchDigitalVaultFolders();
        if (Array.isArray(newDigitalVaultFolders))
          dispatch(setAllDigitalVaultFolders(newDigitalVaultFolders));
      } catch (e) {}
    } catch (error) {
      notice("Error al crear carpeta");
    } finally {
      setLoading(false);
      setFormValues(initialFormValues);
    }
  };

  const handleValuesChange = (e) => {
    if (!e?.target) return;
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <BaseModal
      title="Nueva carpeta"
      open={open}
      onClose={onClose}
      onConfirm={handleOnConfirm}
      confirmLabel="Crear carpeta"
      maxWidth="md"
      color="secondary"
      loading={loading}
    >
      <UtilInputs
        formValues={formValues}
        onValuesChange={handleValuesChange}
        inputValues={newFolderInputs(operativeUsers)}
      />
    </BaseModal>
  );
};

export default DigitalVaultNewFolderModal;
