import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CircularProgress, Grid } from "@material-ui/core";
import PageLayout from "../../components/PageLayout";
import UtilInputs from "../../components/UtilInputs";
import SimpleCard from "../../components/SimpleCard";
import SimpleSwitch from "../../components/SimpleSwitch";
import { formInputs } from "../../utils/usersData";
import { setSelectedIds, setUser } from "../../../redux/ducks/users.duck";
import { getPermissionsSchema, getUserById } from "../../crud/usersCrud";
import { setLoader } from "../../../redux/ducks/loader.duck";

const NewUserPage = () => {
  const { isInstrumentalGoodsPage } = useSelector((store) => store.pathname);
  const { selectedUser } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loaderSchema, setLoaderSchema] = useState(false);
  const [schema, setSchema] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(setLoader(true));
      getUserById(id)
        .then((res) => {
          dispatch(setUser(res));
          dispatch(setSelectedIds([id]));
        })
        .catch(console.log)
        .finally(() => dispatch(setLoader(false)));
    }
  }, [dispatch, id]);

  useEffect(() => {
    setLoaderSchema(true);
    getPermissionsSchema()
      .then((res) => setSchema(res))
      .catch(console.log)
      .finally(() => setLoaderSchema(false));
  }, []);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    if (name === 'alm_area') {
      dispatch(setUser({ ...selectedUser, [name]: value, titular: value.cf_idpersona?.cf_nombre_com }));
    } else if (name === "groups") {
      let index = selectedUser.groups.findIndex((it) => it === value);
      if (index !== -1) {
        let newData = selectedUser.groups;
        newData.splice(index, 1);
        dispatch(setUser({ ...selectedUser, [name]: newData }));
      } else
        dispatch(
          setUser({ ...selectedUser, [name]: [...selectedUser.groups, value] })
        );
    } else dispatch(setUser({ ...selectedUser, [name]: value }));
  };

  return (
    <PageLayout title={id ? "Usuario" : "Nuevo Usuario"}>
      <UtilInputs
        width={200}
        inputValues={formInputs(isInstrumentalGoodsPage, Boolean(id))}
        formValues={selectedUser}
        onValuesChange={handleChange}
      />
      <div className="mt-3 p-3 text-center">
        {loaderSchema ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={2}>
            {schema.map((item, i) => (
              <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                <SimpleCard title={item.description}>
                  {item.groups.map((group) => (
                    <SimpleSwitch
                      key={group.id}
                      name="groups"
                      labelPos="end"
                      color="secondary"
                      label={group.description}
                      onChange={(e) =>
                        handleChange({
                          target: { name: "groups", value: group.id },
                        })
                      }
                      value={
                        selectedUser.groups.find((it) => it === group.id)
                          ? true
                          : false
                      }
                    />
                  ))}
                </SimpleCard>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </PageLayout>
  );
};

export default NewUserPage;
