import { StatusColumnFormatter } from "./columns/StatusColumnFormatter";
import { randomColor } from "../../../utils/colors";

export const columns = [
  {
    dataField: "id",
    text: "No de solicitud",
    sort: true,
  },
  {
    dataField: "area.cf_adscri6",
    text: "Area",
    sort: true,
  },
  {
    dataField: "area.cf_adscri7",
    text: "Responsable",
    sort: true,
  },
  {
    dataField: "cf_estatus",
    text: "Estatus",
    sort: true,
    formatter: StatusColumnFormatter,
  },
];

export const columns_task = [
  {
    dataField: "id",
    text: "No de solicitud",
    sort: true,
  },
  {
    dataField: "area.cf_adscri6",
    text: "Area",
    sort: true,
  },
  {
    dataField: "cf_estatus",
    text: "Estatus",
    sort: true,
    formatter: StatusColumnFormatter,
  },
];

export const mock_data = [
  {
    id: "101951",
    area: "Dirección Gereal de asuntos juridicos",
    responsable: "Fernando Gomez Lopez",
    state: true,
  },
  {
    id: "101952",
    area: "Dirección Gereal de asuntos juridicos",
    responsable: "Fernando Gomez Lopez",
    state: true,
  },
  {
    id: "101953",
    area: "Dirección Gereal de asuntos juridicos",
    responsable: "Fernando Gomez Lopez",
    state: true,
  },
  {
    id: "101954",
    area: "Dirección Gereal de asuntos juridicos",
    responsable: "Fernando Gomez Lopez",
    state: true,
  },
  {
    id: "101955",
    area: "Dirección Gereal de asuntos juridicos",
    responsable: "Fernando Gomez Lopez",
    state: true,
  },
  {
    id: "101956",
    area: "Dirección Gereal de asuntos juridicos",
    responsable: "Fernando Gomez Lopez",
    state: true,
  },
  {
    id: "101957",
    area: "Dirección Gereal de asuntos juridicos",
    responsable: "Fernando Gomez Lopez",
    state: true,
  },
  {
    id: "101958",
    area: "Dirección Gereal de asuntos juridicos",
    responsable: "Fernando Gomez Lopez",
    state: true,
  },
  {
    id: "101959",
    area: "Dirección Gereal de asuntos juridicos",
    responsable: "Fernando Gomez Lopez",
    state: true,
  },
  {
    id: "101960",
    area: "Dirección Gereal de asuntos juridicos",
    responsable: "Fernando Gomez Lopez",
    state: true,
  },
];

export const calebdar_mock = [
  {
    id: 1,
    title: "Generación de remision",
    start: "2022-01-12 09:12",
    end: "2022-01-12 09:12",
    backgroundColor: "#42ab12",
    status: "Por hacer",
    department: "Adquisiciones",
    folio: "DGRMSG-1",
  },
  {
    id: 2,
    title: "Generación de remision",
    start: "2022-01-15 06:12",
    end: "2022-01-15 09:12",
    backgroundColor: "#6f42c1",
    status: "Por hacer",
    department: "Adquisiciones",
    folio: "DGRMSG-1",
  },
  {
    id: 3,
    title: "Generación de remision",
    start: "2022-01-15 06:12",
    end: "2022-01-15 08:12",
    backgroundColor: "#625",
    status: "Por hacer",
    department: "Adquisiciones",
    folio: "DGRMSG-1",
  },
  {
    id: 4,
    title: "Generación de remision",
    start: "2022-01-17 06:12",
    end: "2022-01-17 07:12",
    backgroundColor: "#854",
    status: "Por hacer",
    department: "Adquisiciones",
    folio: "DGRMSG-1",
  },
  {
    id: 5,
    title: "Generación de remision",
    start: "2022-01-21 06:12",
    end: "2022-01-17 07:12",
    backgroundColor: "#dee954",
    status: "Por hacer",
    department: "Adquisiciones",
    folio: "DGRMSG-1",
  },
];

export const format_trello_data = (data, user_in_charge) => {
  const x = data.map((obj) => {
    let reformatObject = {};
    reformatObject["id"] = obj.id;
    reformatObject["title"] = obj.name;
    reformatObject["date"] = obj.date_input;
    reformatObject["status"] = obj.priority?.name;
    reformatObject["label"] = obj.folio?.name;
    reformatObject["badges"] = obj.users.map(
      (user) => `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`
    );
    reformatObject["draggable"] =
      user_in_charge === obj.board?.user_in_charge ? true : !obj.blocked;
    reformatObject["column_state"] = obj.column_state;
    reformatObject["area"] = obj.request_area;
    reformatObject["user"] = obj.signed;
    reformatObject["folio"] = obj.folio?.name;
    reformatObject["department"] = obj.shift?.name;

    return reformatObject;
  });

  return x;
};

export const format_calendar = (data = []) =>
  data.map((item) => ({
    ...item,
    start: item.date,
    end: item.date,
    backgroundColor: randomColor(),
    status:
      item.column_state === 1
        ? "Por hacer"
        : item.column_state === 2
        ? "En progreso"
        : item.column_state === 3
        ? "Aseguramiento de calidad"
        : item.column_state === 4
        ? "Terminada"
        : "Archivada",
  }));
