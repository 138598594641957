import React, { useState } from "react";
import {
  makeStyles,
  MenuItem,
  Typography,
  useMediaQuery,
  Select,
  ListItemText,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: ({ isMobile, width }) => (width ? width : isMobile ? 200 : 130),
    "& > .MuiTypography-root": {
      marginLeft: 5,
      fontWeight: 500,
      fontSize: 14,
    },
  },
  root: {
    minHeight: 25,
    padding: "5.5px 14px",
    backgroundColor: ({ disabled }) => (disabled ? "#dedede" : "#d6eaff"),
    display: "flex",
    alignItems: "center",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },
  label: {
    marginLeft: 5,
    fontWeight: 500,
    fontSize: 14,
    color: ({ disabled, touched, hover }) =>
      (touched || hover) && !disabled ? theme.palette.secondary.main : "black",
  },
}));

const SimpleDropdown = ({
  label,
  name,
  onChange,
  value,
  options = [],
  width = false,
  disabled,
  toUpperCase = true,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [touched, setTouched] = useState(false);
  const [hover, setHover] = useState(false);

  const classes = useStyles({ isMobile, width, disabled, touched, hover });

  const renderedValue = (selected) =>
    options
      .find((op) => op.value === selected)
      ?.text?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") ?? "";

  const listItemValue = (option) =>
    option.text?.normalize("NFD").replace(/[\u0300-\u036f]/g, "") ?? "";
  return (
    <div className={classes.container}>
      <Typography className={classes.label}>{label}</Typography>
      <Select
        fullWidth
        displayEmpty
        value={value}
        onChange={onChange}
        classes={classes}
        color="secondary"
        variant="outlined"
        size="small"
        name={name}
        disabled={disabled}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onBlur={() => setTouched(false)}
        onFocus={(e) => setTouched(true)}
        renderValue={(selected) => (
          <span className={classes.root}>
            {toUpperCase
              ? renderedValue(selected).toUpperCase()
              : renderedValue(selected)}
          </span>
        )}
      >
        {options.map(
          (option) =>
            option && (
              <MenuItem
                key={option.value?.id ? option.value?.id : option.value}
                value={option.value}
                disabled={option.disabled}
              >
                <ListItemText
                  className="d-flex align-items-end"
                  primary={
                    toUpperCase
                      ? listItemValue(option).toUpperCase()
                      : listItemValue(option)
                  }
                  secondary={option.text2}
                  secondaryTypographyProps={{ className: "ml-3" }}
                />
              </MenuItem>
            )
        )}
      </Select>
    </div>
  );
};

export default SimpleDropdown;
