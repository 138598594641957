import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setClearState,
  setSelectedVariants,
  setVariant,
  setVariants,
} from "../../../../redux/ducks/variants.duck";
import { getSingleVariant, getVariantsList, getList } from "../../../crud/variantsCrud";
import { DocumentTypeTableColumns } from "../../../utils/configData";
import FullTable from "../../../components/FullTable";
import notice from "../../../components/Notice";

export const ConfigDocumentType = () => {
  const { count } = useSelector((state) => state.variants.variants);
  const dispatch = useDispatch();
  const history = useHistory();
  const { permisos } = useSelector((store) => store.auth);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);

  useEffect(() => {
    setLoading(true);
    getList(page, sizePerPage)
      .then((res) => {
        setResults(res.results);
      })
      .catch((e) => notice("Error cargando la lista de tipo de documento"))
      .finally(() => setLoading(false));
  }, [dispatch, page, sizePerPage]);

  const handleNew = () => {
    dispatch(setClearState());
    history.push(
      "/bienes-instrumentales/configuracion/catalogos/tipo-de-documento/nuevo"
    );
  };

  const onEye = (id) => {
    dispatch(setClearState());
    getSingleVariant(id)
      .then((res) => {
        dispatch(setVariant(res));
        dispatch(setSelectedVariants([res.id]));
        history.push(
          `/bienes-instrumentales/configuracion/catalogos/tipo-de-documento/resultados/${id}`
        );
      })
      .catch((e) => notice("Error al mostrar tipo de documento"));
  };

  const handleSelectAllRows = (isSelect, rows) =>
    isSelect ? setSelectedIds(rows.map((r) => r.id)) : setSelectedIds([]);

  const handleSelectRow = (row, isSelect) => {
    if (row && row.id) {
      if (!isSelect) {
        const newSelectedEntries = selectedIds.filter((id) => id !== row.id);
        setSelectedIds(newSelectedEntries);
      } else {
        setSelectedIds([...selectedIds, row.id]);
      }
    }
  };

  const handleReqSelected = () => {
    dispatch(setClearState());
    dispatch(setSelectedVariants(selectedIds));
    getSingleVariant(selectedIds[0])
      .then((res) => {
        dispatch(setVariant(res));
        history.push(
          `/bienes-instrumentales/configuracion/catalogos/tipo-de-documento/resultados/${res.id}`
        );
      })
      .catch((e) => notice("Error al mostrar tipo de documento"));
  };

  return (
    <FullTable
      title="Tipo de Documento"
      // NEW VEHICULE TRIGGER
      buttonTitle="Nuevo Tipo de Documento"
      onClickButton={handleNew}
      buttonDisabled={!permisos.permisos.includes("i-crear-tipo-documento")}
      // TABLE ENTITIES
      entities={results}
      columns={DocumentTypeTableColumns(onEye)}
      page={page}
      setPage={(page) => page > 0 && setPage(page)}
      sizePerPage={sizePerPage}
      setSizePerPage={setSizePerPage}
      count={count}
      loading={loading}
      // FILTERS ACTIONS
      search_button={false}
      // SELECTED ACTIONS
      has_button2
      buttonDisabled2={selectedIds.length < 1}
      buttonTitle2={`Consultar Todos${
        selectedIds.length > 1 ? `(${selectedIds.length.toString()})` : ""
      }`}
      onSelectRow={handleSelectRow}
      onSelectAllRows={handleSelectAllRows}
      onClickButton2={handleReqSelected}
    />
  );
};
