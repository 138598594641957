import React, { useEffect, useState } from "react";
import { generalForm, vehiclesDetailsTabs } from "../utils/vehiculesData";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AntTabs from "./AntTabs";
import PageLayout from "./PageLayout";
import SimpleInput from "./SimpleInput";
import UtilInputs from "./UtilInputs";
import VehiculesDataTab from "./VehiculesDataTab";
import VehiculesGuardTab from "./VehiculesGuardTab";
import VehiculesFinantialTab from "./VehiculesFinantialTab";
import PDFUploaderTab from "./PDFUploaderTab";
import SimplePagination from "./SimplePagination";
import DropInputsForm from "./DropInputsForm";
import { getVehiculesSubBrands } from "../crud/vehiculesCrud";
import {
  setSelectedVehicule,
  setResetVehiclesForm,
  setEnableInputs,
} from "../../redux/ducks/vehicules.duck";
import SimpleDate from "./SimpleDate";
import { inputFormatDate } from "../utils/dates";
import { toggleEnableInputs } from "../utils/newEntryData";
import BaseModal from "./BaseModal";
import CheckboxList from "./CheckboxList";
import CustomButton from "./CustomButton";
import { defaultDoc } from "../pages/DigitalVault/utils";
import { Badge, Box, Typography, Button } from "@material-ui/core";
import FileButton from "./FileButton";
import { setLoader } from "../../redux/ducks/loader.duck";
import notice from "./Notice";
import DocumentsList from "./DocumentsList";
import FileIconImg from "./FileIconImg";
import { digitalizationWithModule } from "../crud/digitalVaultCrud";
import { modeOptions } from "../utils/subheaderIconsData";
import { setCurrentMode } from "../../redux/ducks/subHeaderActions.duck";

const VehiculesForm = ({
  formValues,
  handleChange,
  handleCopyToAllSelectedVehicules,
  setVehiculesDataAvailableToCopy,
  vehiculesDataAvailableToCopy,
  openModalToCopy,
  setOpenModalToCopy,
  dataToCopy,
  setDataToCopy,
  onChangeDataToCopy,
  copyDataModal,
}) => {
  const { id } = useParams();
  const { isSearchPage, isResultsPage } = useSelector((store) => store.pathname);
  const { selectedVehiculeIds, enableInputs } = useSelector((state) => state.vehicules);
  const { currentMode } = useSelector((store) => store.subHeaderActions);
  const [listDocs, setListDocs] = useState({});
  const { permisos } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isDropPage = pathname.includes("bajas");
  const [selectedTab, setSelectedTab] = useState(3);
  const [subbrands, setSubbrands] = useState([]);
  const [doc, setDoc] = useState(defaultDoc);

  useEffect(() => {
    dispatch(setEnableInputs(Boolean(currentMode)));
  }, [currentMode, dispatch]);
  useEffect(() => {
    if (formValues) {
      setListDocs(formValues.all_attachments || {});
    }
  }, [formValues.all_attachments]);
  useEffect(() => {
    getVehiculesSubBrands(formValues.cf_marca?.id)
      .then(setSubbrands)
      .catch(console.log);
  }, [formValues.cf_marca?.id]);

  const handleChangeTab = (e, newTab) => setSelectedTab(newTab);

  const handleClickTab = (index) => {
    dispatch(setCurrentMode(modeOptions.result));

    if (isDropPage) {
      history.push(
        `/bienes-instrumentales/vehiculos/bajas/resultados/${
          selectedVehiculeIds[index - 1]
        }`
      );
    } else {
      history.push(
        `/bienes-instrumentales/vehiculos/resultados/${selectedVehiculeIds[index - 1]}`
      );
    }
  };

  const onSuccessUpload = (res) => {
    if (formValues?.all_attachments === undefined) return;
    const newFiles = res.map((x) => x.data);
    const newVehicule = {
      ...formValues,
      all_attachments: {
        ...formValues.all_attachments,
        pdfs: [
          ...(formValues.all_attachments?.pdfs ? formValues.all_attachments.pdfs : []),
          ...newFiles,
        ],
      },
    };
    dispatch(setSelectedVehicule(newVehicule));
    dispatch(setResetVehiclesForm(newVehicule));
  };

  const onSucessRemove = (removedId) => {
    const newFiles = formValues.all_attachments.pdfs.filter((x) => x.id !== removedId);
    const newVehicule = {
      ...formValues,
      all_attachments: {
        ...formValues.all_attachments,
        pdfs: newFiles,
      },
    };
    dispatch(setSelectedVehicule(newVehicule));
    dispatch(setResetVehiclesForm(newVehicule));
  };
  const formValues2 = () => {
    if (isResultsPage) {
      return {
        ...formValues,
        cf_typev: `${formValues["cf_typev"]}`,
      };
    } else {
      return formValues;
    }
  };
  const updateListDocs = (newDocs) => {
    const newDocsObject =
      module === "vehicle"
        ? { "Recientemente subido": newDocs }
        : { "Recientemente digitalizados": newDocs };
    setListDocs({ ...newDocsObject, ...listDocs });
  };
  const handleAttachments = (files) => {
    setDoc({ ...doc, attachments: files });
  };

  const onSubmit = (allAttach) => {
    if (allAttach) {
      const listIds = selectedVehiculeIds.toString().replaceAll(",", "|");
      const request = digitalizationWithModule("vehicle", listIds, doc.attachments);
      dispatch(setLoader(true));
      request
        .then((res) => {
          updateListDocs(res);
          setDoc(defaultDoc);

          notice("Se han cargado los archivos con éxito", "success");
        })
        .catch((e) => {
          if (e.hasOwnProperty("url")) {
            notice("Debe seleccionar al menos un archivo", "info");
          } else {
            notice("Ha ocurrido un error al cargar sus archivos");
          }
        })
        .finally(() => dispatch(setLoader(false)));
    } else {
      const request = digitalizationWithModule("vehicle", id, doc.attachments);
      dispatch(setLoader(true));
      request
        .then((res) => {
          updateListDocs(res);
          setDoc(defaultDoc);

          notice("Se han cargado los archivos con éxito", "success");
        })
        .catch((e) => {
          if (e.hasOwnProperty("url")) {
            notice("Debe seleccionar al menos un archivo", "info");
          } else {
            notice("Ha ocurrido un error al cargar sus archivos");
          }
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    }
  };
  const handleRemoveAttachment = (index) => {
    const updatedAtt = doc.attachments;
    updatedAtt.splice(index, 1);
    setDoc({ ...doc, attachments: [...updatedAtt] });
  };
  return (
    <PageLayout
      header={
        <>
          <div className="w-100">
            <div className="d-flex justify-content-between mb-3 w-100">
              <h6>
                {isSearchPage
                  ? "Buscar Vehículo"
                  : isDropPage
                  ? "Datos de Vehìculo"
                  : formValues.id
                  ? "Editar Vehículo"
                  : "Nuevo Vehículo"}
              </h6>
              <div className="d-flex">
                {selectedVehiculeIds.length > 1 && !isDropPage && !isSearchPage && (
                  <CustomButton
                    outline
                    title={
                      <>
                        <i className="flaticon2-copy mr-2" />
                        {`Copiar (${Object.keys(vehiculesDataAvailableToCopy).length})`}
                      </>
                    }
                    disabled={Object.keys(vehiculesDataAvailableToCopy).length === 0}
                    color="info"
                    onClick={() => {
                      copyDataModal();
                    }}
                  />
                )}
              </div>
            </div>

            {formValues.id && selectedVehiculeIds.length > 1 && (
              <SimplePagination
                current={formValues.id}
                data={selectedVehiculeIds}
                handleClickItem={handleClickTab}
              />
            )}
          </div>
        </>
      }
    >
      <div className="my-3">
        <h6 className="text-muted">Datos generales</h6>
        {isDropPage && (
          <DropInputsForm formValues={formValues} onValuesChange={handleChange} />
        )}

        <BaseModal
          title={<span>Seleccione los campos</span>}
          open={openModalToCopy}
          onClose={() => setOpenModalToCopy(false)}
          onConfirm={handleCopyToAllSelectedVehicules}
          confirmLabel="Guardar y copiar"
          onCancel={() => setOpenModalToCopy(false)}
          cancelLabel="Cerrar"
          maxWidth="sm"
          color="secondary"
          disableOnConfirm={false}
        >
          <CheckboxList
            data={vehiculesDataAvailableToCopy}
            onChangeData={onChangeDataToCopy}
          />
        </BaseModal>
        <SimpleInput
          disabled={!enableInputs || currentMode === "edit" || currentMode === "new"}
          focus={isSearchPage ? true : false || true}
          label="No de inventario"
          placeholder="No de inventario"
          name="id"
          width="50%"
          value={formValues.id}
          onChange={handleChange}
        />

        <UtilInputs
          formValues={formValues2()}
          inputValues={
            !enableInputs
              ? toggleEnableInputs(generalForm(subbrands), false)
              : generalForm(subbrands, formValues.cf_marca?.id ? false : true)
          }
          onValuesChange={handleChange}
        />
        <SimpleInput
          disabled={!enableInputs}
          label="Observaciones"
          placeholder="Observaciones"
          name="cf_observaciones"
          width="100%"
          value={formValues.cf_observaciones}
          onChange={handleChange}
          inputProps={{ maxLength: 500 }}
        />
        <SimpleDate
          label="Fecha de actualización"
          placeholder="dd/mm/yyyy"
          name="updated_at"
          width={170}
          value={formValues.updated_at && inputFormatDate(formValues.updated_at)}
          disabled
        />
      </div>
      <AntTabs
        tabs={
          formValues.id
            ? vehiclesDetailsTabs
            : vehiclesDetailsTabs.filter((tab) => tab.label !== "Documentos")
        }
        selectedValue={selectedTab}
        onChangeTab={handleChangeTab}
      />
      {selectedTab === 0 && (
        <VehiculesDataTab formValues={formValues} handleChange={handleChange} />
      )}
      {selectedTab === 1 && (
        <VehiculesGuardTab formValues={formValues} handleChange={handleChange} />
      )}
      {selectedTab === 2 && (
        <VehiculesFinantialTab formValues={formValues} handleChange={handleChange} />
      )}
      {selectedTab === 3 && (
        <>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                color={"primary"}
                variant="subtitle1"
                style={{ position: "static" }}
              >
                {Object.keys(listDocs).length ? " Resguardos" : " Sin resguardos"}
              </Typography>

              <FileButton
                isIconButton
                multiple
                accept="*"
                buttonTitle="Seleccionar nuevos archivos"
                handleChange={handleAttachments}
              />
            </div>
          </Box>
          <div
            className="d-flex mt-4"
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {doc.attachments?.map((att, i) => (
              <Badge
                badgeContent="X"
                color="error"
                key={att.name}
                style={{ cursor: "pointer" }}
                onClick={() => handleRemoveAttachment(i)}
              >
                <FileIconImg name={att.name} type={att.type} width={50} />
              </Badge>
            ))}
            {doc.attachments?.length > 0 && selectedVehiculeIds.length > 1 ? (
              <div style={{ marginTop: 10 }}>
                <Button variant="text" color="secondary" onClick={() => onSubmit(true)}>
                  {`Digitalizar todos [${selectedVehiculeIds.map((x) => x)}]`}`
                </Button>
                <Button variant="text" color="secondary" onClick={() => onSubmit(false)}>
                  {`Digitalizar ${id}`}`
                </Button>
              </div>
            ) : (
              doc.attachments?.length > 0 && (
                <div style={{ marginTop: 10 }}>
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => onSubmit(false)}
                  >
                    {`Digitalizar ${id}`}`
                  </Button>
                </div>
              )
            )}
          </div>
          {Object.keys(listDocs).length > 0 ? (
            <DocumentsList data={listDocs} setListDocs={setListDocs} module={module} />
          ) : (
            <p>No se encontraron archivos de ese año</p>
          )}
        </>
      )}
    </PageLayout>
  );
};

export default VehiculesForm;
