import * as columnFormatters from "../components/column-formatters";
import notice from "../components/Notice";
import {
  getAreas,
  getBatches,
  getItemCABMSAndKey,
  getProviders,
  getResposableByName,
} from "../crud/catalogsCrud";
import { getKeyData } from "./cabmsAndKey";
import {
  conciliedTypes,
  goodTypes,
  labelTypeOptions,
  medioAcquisitionOptions,
  stockMaterialOptions,
} from "./StockFormData";
import { IdsFormatter } from "../components/column-formatters/IdsFormatter";

export const artWorksColumns = (onEye, onDrop, Permisos) => [
  {
    dataField: "id",
    text: "No. inventario",
    sort: true,
    formatter: (cell, row) => IdsFormatter(cell, row, ""),
  },
  {
    dataField: "cabms_key",
    text: "CABMS",
    sort: true,
  },
  {
    dataField: "cabms_item",
    text: "Artículo CABMS",
    sort: true,
  },
  {
    dataField: "cf_articulo",
    text: "Artículo",
    sort: true,
  },
  {
    dataField: "cf_autor",
    text: "Autor",
    sort: true,
  },
  {
    dataField: "cf_titulo",
    text: "Título",
    sort: true,
  },
  {
    dataField: "cf_num_area",
    text: "Área",
    sort: true,
  },
  {
    dataField: "datos_resguardos",
    text: "Responsable",
    sort: true,
    formatter: (cell) => cell?.responsable?.trim(),
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.ArtWorksColumnFormatter,
    formatExtraData: {
      onEye,
      onDrop,
      Permisos,
    },
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "100px",
    },
    csvExport: false,
  },
];

export const artWorkDropsColumns = (onEye, onDrop, Permisos) => [
  {
    dataField: "artwork",
    text: "No. inventario",
    sort: true,
  },
  {
    dataField: "motivo_baja",
    text: "Motivo",
    sort: true,
  },
  {
    dataField: "tipo_baja_descripcion",
    text: "Tipo de baja",
    sort: true,
  },
  {
    dataField: "articulo",
    text: "Artículo",
    sort: true,
  },
  {
    dataField: "artwork_data.cf_autor",
    text: "Autor",
    sort: true,
    formatter: (cell) => cell?.trim(),
  },
  {
    dataField: "artwork_data.cf_titulo",
    text: "Título",
    sort: true,
  },
  {
    dataField: "cve_area",
    text: "Área",
    sort: true,
  },
  {
    dataField: "responsable",
    text: "Responsable",
    sort: true,
    formatter: (cell) => cell?.trim(),
  },
  // {
  //   dataField: "action",
  //   text: "Acciones",
  //   formatter: columnFormatters.ArtWorksColumnFormatter,
  //   formatExtraData: {
  //     onEye,
  //     onDrop,
  //     Permisos,
  //   },
  //   classes: "text-right pr-0",
  //   headerClasses: "text-right pr-3",
  //   style: {
  //     minWidth: "100px",
  //   },
  //   csvExport: false,
  // },
  {
    dataField: "artwork_data.id",
    text: "Acciones",
    formatter: columnFormatters.DropActionColumn,
    formatExtraData: { onEye, onDrop, Permisos },
    classes: "text-center",
    headerClasses: "text-center",
    style: {
      minWidth: "100px",
    },
    csvExport: false,
  },
];

export const defaultFilter = {
  id: "",
  cf_autor: "",
  cf_titulo: "",
  area: null,
};

export const filterInputs = [
  {
    label: "Articulo",
    name: "id",
    type: "text",
  },
  {
    label: "Autor",
    name: "cf_autor",
    type: "text",
  },
  {
    label: "Título",
    name: "cf_titulo",
    type: "text",
  },
  {
    label: "Área",
    name: "area",
    type: "search",
    searchFunction: getAreas,
  },
];

export const iconsActions = (handleDownloadGuardPDF) => [
  {
    label: "Etiqueta",
    image: "/media/svg/icons/Shopping/Barcode.svg",
    tooltip: "Etiqueta",
    onClick: () => {},
  },
  {
    label: "Resguardo",
    image: "/media/svg/icons/Files/File.svg",
    tooltip: "Resguardo",
    style: { margin: "0 30px" },
    onClick: () => handleDownloadGuardPDF(),
  },
];

export const artWorksDetailsTabs = [
  { value: 0, label: "Datos de Asignación", color: "#3699FF" },
  { value: 1, label: "Descripción de la Obra", color: "#1BC5BD" },
  { value: 2, label: "Datos Financieros", color: "#7337EE" },
  { value: 3, label: "Documentos", color: "#F64E60" },
];

export const artWorkAssignmentDataTabInputs = (currentMode) => [
  {
    label: "Artículo",
    placeholder: "Artículo",
    name: "item",
    type: "key",
    hasChip: true,
    width: 293,
  },
  {
    label: "Clave de Art.",
    placeholder: "Clave",
    name: "key",
    type: "text",
    disabled: true,
    width: 293,
  },
  {
    type: "line-break",
  },
  {
    label: "Artículo CABMS",
    placeholder: "Artículo",
    name: "cabms_item",
    type: "search",
    searchFunction: getItemCABMSAndKey,
    hasChip: true,
    width: 293,
  },
  {
    label: "Clave de Art. CABMS",
    placeholder: "Clave CABMS",
    name: "cabms_key",
    type: "text",
    disabled: true,
    width: 293,
  },
  {
    type: "line-break",
  },
  {
    label: "No. de Resguardo",
    placeholder: "No. de Resguardo",
    name: "num_resguardo",
    type: "text",
    width: 293,
    maxLength: 10,
    disabled: true,
  },
  {
    label: "Fecha de resguardo",
    name: "cf_fecha_resguardo",
    type: "date",
    width: 293,
    //errorMessage: !isAfterDate() && "La fecha de resguardo debe ser mayor a la fecha de creaciòn"
  },
  {
    type: "line-break",
  },
  {
    label: "Área resguardante",
    placeholder: "Área resguardante",
    name: "cf_num_area",
    type: "search",
    searchFunction: getAreas,
    width: 600,
  },
  {
    type: "line-break",
  },
  {
    label: "Responsables",
    placeholder: "Responsables",
    name: "responsables",
    type: currentMode === "search" ? "search-multiple" : "text",
    width: 600,
    disabled: currentMode === "search" ? false : true,
    searchFunction: getResposableByName,
  },
  {
    type: "line-break",
  },
  {
    label: "Edificio",
    placeholder: "Edificio",
    name: "edificio",
    type: "text",
    width: 600,
    disabled: true,
  },
  {
    type: "line-break",
  },
  {
    label: "Piso",
    placeholder: "Piso",
    name: "piso",
    type: "text",
    width: 190,
    disabled: true,
  },
  {
    label: "Oficina",
    placeholder: "Oficina",
    name: "oficina",
    type: "text",
    width: 190,
    disabled: true,
  },
  {
    label: "Suboficina",
    placeholder: "Suboficina",
    name: "soficina",
    type: "text",
    width: 190,
    disabled: true,
  },
  {
    type: "line-break",
  },
  {
    label: "Fecha de actualización",
    placeholder: "Fecha de actualización",
    name: "updated_at",
    type: "date",
    width: 190,
    disabled: true,
  },
  {
    label: "Usuario",
    placeholder: "Usuario",
    name: "user",
    type: "text",
    disabled: true,
    width: 190,
    autoComplete: false,
  },
  // {
  // 	label: 'Estatus',
  // 	placeholder: 'Estatus',
  // 	name: 'cf_estatus',
  // 	type: 'dropdown',
  // 	options: [
  // 		{ value: 'A', text: 'Alta' },
  // 		{ value: 'B', text: 'Baja' },
  // 	],
  // 	width: 190,
  // },
];

export const initialSelectedArtWork = {
  cf_no_inventa: "",
  id: "",
  item: "",
  key: "",
  cf_resguardo: "",
  cf_fecha_resguardo: "",
  cf_num_area: "",
  cf_responsables: [],
  cf_ubicacion: "",
  g: "",
  sg: "",
  ssg: "",
  cf_fecha_actualizacion: "",
  user: "",
  cf_estatus: "",
  cf_titulo: "",
  cf_tipo: "",
  cf_tecnica: "",
  cf_autor: "",
  cf_medida: "",
  cf_anio: "",
  cf_moneda: "",
  cf_costo: "",
  cf_valor_avaluo: "",
  cf_observa: "",
  cf_fecinv: "",
  cf_inve_material_type: "",
  cf_tipobien: "",
  cf_anio_resguardo: "",
  cf_cve_partida: null,
  cf_no_cuenta: "",
  cf_no_scuenta: "",
  cf_cve_prove: "",
  cf_num_factura: "",
  invoice_date: "",
  cf_fecha_recepcion_factura: "",
  reference_number: "",
  reference_date: "",
  cf_fecha_recepcion_remision: "",
  cf_importe: "",
  cf_no_poliza: "",
  cf_fecha_poliza: "",
  cf_tipo_poliza: "",
  cf_conciliado: "",
  cf_fecha_actuali: "",
  cf_medioadquisicion: "",
  asset_acquisition_id: "",
  cabms_item: "",
  cabms_key: "",
  num_resguardo: "",
  responsable: "",
  edificio: "",
  cabms: "",
  cf_label_type: "",
  motivo_baja: "",
  fecha_baja: "",
  tipo_baja_descripcion: "",
  responsables: [],
};

export const reportInputs = [
  {
    label: "NS Recepción",
    name: "cf_ns_recepcion",
  },
  {
    label: "Clave NS",
    name: "cf_clavens",
  },
  {
    label: "Clasificación SICAF",
    name: "cf_clasificacion_sicaf",
  },
  {
    label: "Tipo de registro",
    name: "cf_tipo_registro",
  },
  {
    label: "En inventario",
    name: "cf_eninventario",
  },
  {
    label: "Seguros",
    name: "cf_seguros",
  },
  {
    label: "B-Seguros",
    name: "cf_bseguros",
  },
  {
    label: "A-Contabilidad",
    name: "cf_acontabilidad",
  },
  {
    label: "B-Contabilidad",
    name: "cf_bcontabilidad",
  },
  {
    label: "R-PNT",
    name: "cf_rpnt",
  },
  {
    label: "B-PNT",
    name: "cf_bpnt",
  },
  {
    label: "Libro inv.",
    name: "cf_libroinv",
  },
  {
    label: "Inf. Reportada",
    name: "cf_infreportada",
  },
  {
    label: "MISED",
    name: "cf_mised",
  },
];

export const artWorkDescriptionTabInputs = (artworkTypes = []) => [
  {
    label: "Título",
    placeholder: "Título",
    name: "cf_titulo",
    type: "text",
    multiline: true,
    width: 600,
    rows: 2,
    maxLength: 250,
  },
  {
    type: "line-break",
  },
  {
    label: "Tipo de Obra",
    placeholder: "Tipo de Obra",
    name: "cf_id_tipo_obra",
    type: "dropdown",
    options: artworkTypes,
    width: 600,
  },
  {
    type: "line-break",
  },
  {
    label: "Técnica",
    placeholder: "Técnica",
    name: "cf_tecnica",
    type: "text",
    width: 600,
    maxLength: 50,
  },
  {
    type: "line-break",
  },
  {
    label: "Autor",
    placeholder: "Autor",
    name: "cf_autor",
    type: "text",
    width: 293,
    maxLength: 80,
  },
  {
    label: "Medida",
    placeholder: "Medida",
    name: "cf_medida",
    type: "text",
    width: 293,
    maxLength: 80,
  },
  {
    type: "line-break",
  },
  {
    label: "Año",
    placeholder: "Año",
    name: "cf_anio",
    type: "text",
    width: 293,
  },
  {
    label: "Tipo de moneda",
    placeholder: "Tipo de moneda",
    name: "cf_moneda",
    type: "dropdown",
    options: [
      { value: "N", text: "Nacional" },
      { value: "E", text: "Extranjera" },
    ],
    width: 293,
  },
  {
    type: "line-break",
  },
  {
    label: "Valor Histórico",
    placeholder: "Valor Histórico",
    name: "cf_costo",
    type: "text",
    isPrice: true,
    width: 293,
  },
  {
    label: "Valor de Avalúo",
    placeholder: "Valor de Avalúo",
    name: "cf_valor_avaluo",
    type: "text",
    isPrice: true,
    width: 293,
  },
  {
    type: "line-break",
  },
  {
    label: "Observaciones",
    placeholder: "Observaciones",
    name: "cf_observa",
    type: "text",
    width: 600,
    multiline: true,
    maxLength: 200,
  },
];

export const artWorkFinantialTabInputs = [
  {
    label: "Fecha de inventario",
    placeholder: "Fecha de inventario",
    name: "cf_fecinv",
    type: "date",
    width: 150,
  },
  {
    label: "Tipo de material",
    placeholder: "Tipo de material",
    name: "cf_inve_material_type",
    type: "dropdown",
    options: stockMaterialOptions,
    width: 200,
  },
  {
    label: "Tipo de bien",
    placeholder: "Tipo de bien",
    name: "cf_tipobien",
    type: "dropdown",
    options: goodTypes,
    width: 200,
  },
  {
    label: "Tipo de etiqueta",
    name: "cf_label_type",
    type: "dropdown",
    options: labelTypeOptions,
    required: true,
    uppercase: true,
    width: 200,
    partida: false,
  },
  {
    label: "Año resguardo",
    placeholder: "Año resguardo",
    name: "cf_anio_resguardo",
    type: "text",
    partida: false,
  },
  {
    label: "Partida presupuestal",
    placeholder: "Partida presupuestal",
    name: "num_partida",
    type: "search",
    width: 200,
    searchFunction: getBatches,
    hideText: true,
    partida: false,
  },
  {
    label: "Descripción partida presupuestal",
    placeholder: "Descripción partida presupuestal",
    name: "descripcion_partida",
    type: "text",
    width: 300,
  },
  {
    label: "No. De cuenta",
    placeholder: "No. De cuenta",
    name: "cf_no_cuenta",
    type: "text",
    partida: false,
    disabled: true,
  },
  {
    label: "No. De subcuenta",
    placeholder: "No. De subcuenta",
    name: "cf_no_scuenta",
    type: "text",
    width: 200,
    disabled: true,
  },
  {
    label: "Proveedor",
    placeholder: "Proveedor",
    name: "cf_cve_prove",
    type: "search",
    searchFunction: getProviders,
    width: 460,
  },
  {
    label: "No. Factura",
    placeholder: "No. Factura",
    name: "cf_num_factura",
    type: "text",
    width: 250,
    maxLength: 10,
  },
  {
    label: "Fecha de la factura",
    placeholder: "Fecha de la factura",
    name: "invoice_date",
    type: "date",
    width: 200,
  },
  {
    label: "Fecha recepción Factura DCI",
    placeholder: "Fecha recepción Factura DCI",
    name: "cf_fecha_recepcion_factura",
    type: "date",
    width: 200,
  },
  {
    label: "No. De remisión",
    placeholder: "No. De remisión",
    name: "reference_number",
    type: "text",
    width: 250,
    maxLength: 255,
    partida: false,
  },
  {
    label: "Fecha de la remisión",
    placeholder: "Fecha de la remisión",
    name: "reference_date",
    type: "date",
    width: 200,
    partida: false,
  },
  {
    label: "Fecha Recep. Remisión DCI",
    placeholder: "Fecha Recep. Remisión DCI",
    name: "cf_fecha_recepcion_remision",
    type: "date",
    width: 200,
    partida: false,
  },
  {
    label: "Importe",
    placeholder: "Importe",
    name: "cf_importe",
    type: "text",
    isPrice: true,
    width: 180,
    partida: false,
  },
  {
    label: "No. De póliza",
    placeholder: "No. De póliza",
    name: "cf_no_poliza",
    type: "text",
    width: 180,
    maxLength: 1024,
  },
  {
    label: "Fecha de póliza",
    placeholder: "Fecha de póliza",
    name: "cf_fecha_poliza",
    type: "date",
    width: 200,
  },
  {
    label: "Tipo de póliza",
    placeholder: "Tipo de póliza",
    name: "cf_tipo_poliza",
    type: "text",
    width: 180,
    maxLength: 8,
  },
  {
    label: "Conciliado",
    placeholder: "Conciliado",
    name: "cf_conciliado",
    type: "dropdown",
    options: conciliedTypes,
    disabled: true,
  },
  {
    label: "Fecha de captura",
    placeholder: "Fecha de captura",
    name: "cf_fecha_actuali",
    type: "text",
    width: 200,
  },
  {
    label: "Medio de adquisición",
    placeholder: "Medio de adquisición",
    name: "cf_medioadquisicion",
    type: "dropdown",
    options: medioAcquisitionOptions,
    width: 200,
  },
];

export const inputsLabelNameToCopy = [
  {
    name: "image_artwork",
    label: "Subir foto",
  },
  {
    name: "id",
    label: "No. inventario",
  },
  {
    name: "cf_no_inventa",
    label: "No. inventario Anterior",
  },
  {
    name: "cabms_item",
    label: "Artìculo CABMS",
  },
  {
    label: "Artículo",
    name: "item",
  },
  {
    label: "Clave de Art. CABMS",
    name: "cabms_key",
  },
  {
    label: "No. de Resguardo",
    name: "num_resguardo",
  },
  { label: "Fecha de resguardo", name: "cf_fecha_resguardo" },
  {
    label: "Área resguardante",
    name: "cf_num_area",
  },
  {
    label: "Responsables",
    name: "responsables",
  },
  {
    label: "Edificio",
    name: "edificio",
  },
  {
    label: "Piso",
    name: "piso",
  },
  {
    label: "Oficina",
    name: "oficina",
  },
  {
    label: "Suboficina",
    name: "soficina",
  },
  {
    label: "Fecha de actualización",
    name: "updated_at",
  },
  {
    label: "Usuario",
    name: "user",
  },
  {
    label: "Título",
    name: "cf_titulo",
  },
  {
    label: "Tipo de Obra",
    name: "cf_id_tipo_obra",
  },
  {
    label: "Técnica",
    name: "cf_tecnica",
  },
  {
    label: "Autor",
    name: "cf_autor",
  },
  {
    label: "Medida",
    name: "cf_medida",
  },
  {
    label: "Año",
    name: "cf_anio",
  },
  {
    label: "Tipo de moneda",
    name: "cf_moneda",
  },
  {
    label: "Valor Histórico",
    name: "cf_costo",
  },
  {
    label: "Valor de Avalúo",
    name: "cf_valor_avaluo",
  },
  {
    label: "Observaciones",
    name: "cf_observa",
  },
  {
    label: "NS Recepción",
    name: "cf_ns_recepcion",
  },
  {
    label: "Clave NS",
    name: "cf_clavens",
  },
  {
    label: "Clasificación SICAF",
    name: "cf_clasificacion_sicaf",
  },
  {
    label: "Tipo de registro",
    name: "cf_tipo_registro",
  },
  {
    label: "En inventario",
    name: "cf_eninventario",
  },
  {
    label: "Seguros",
    name: "cf_seguros",
  },
  {
    label: "B-Seguros",
    name: "cf_bseguros",
  },
  {
    label: "A-Contabilidad",
    name: "cf_acontabilidad",
  },
  {
    label: "B-Contabilidad",
    name: "cf_bcontabilidad",
  },
  {
    label: "R-PNT",
    name: "cf_rpnt",
  },
  {
    label: "B-PNT",
    name: "cf_bpnt",
  },
  {
    label: "Libro inv.",
    name: "cf_libroinv",
  },
  {
    label: "Inf. Reportada",
    name: "cf_infreportada",
  },
  {
    label: "MISED",
    name: "cf_mised",
  },
  {
    label: "Fecha de inventario",
    name: "cf_fecinv",
  },
  {
    label: "Tipo de material",
    name: "cf_inve_material_type",
  },
  {
    label: "Tipo de bien",
    name: "cf_tipobien",
  },
  {
    label: "Tipo de etiqueta",
    name: "cf_label_type",
  },
  {
    label: "Año resguardo",
    name: "cf_anio_resguardo",
  },
  {
    label: "Partida presupuestal",
    name: "num_partida",
  },
  {
    label: "Descripción partida presupuestal",
    name: "descripcion_partida",
  },
  {
    label: "No. De cuenta",
    name: "cf_no_cuenta",
  },
  {
    label: "No. De subcuenta",
    name: "cf_no_scuenta",
  },
  {
    label: "Proveedor",
    name: "cf_cve_prove",
  },
  {
    label: "No. Factura",
    name: "cf_num_factura",
  },
  {
    label: "Fecha de la factura",
    name: "invoice_date",
  },
  {
    label: "Fecha recepción Factura DCI",
    name: "cf_fecha_recepcion_factura",
  },
  {
    label: "No. De remisión",
    name: "reference_number",
  },
  {
    label: "Fecha de la remisión",
    name: "reference_date",
  },
  {
    label: "Fecha Recep. Remisión DCI",
    name: "cf_fecha_recepcion_remision",
  },
  {
    label: "Importe",
    name: "cf_importe",
  },
  {
    label: "No. De póliza",
    name: "cf_no_poliza",
  },
  {
    label: "Fecha de póliza",
    name: "cf_fecha_poliza",
  },
  {
    label: "Tipo de póliza",
    name: "cf_tipo_poliza",
  },
  {
    label: "Conciliado",
    name: "cf_conciliado",
  },
  {
    label: "Fecha de captura",
    name: "cf_fecha_actuali",
  },
  {
    label: "Medio de adquisición",
    name: "cf_medioadquisicion",
  },
];
export const validateCreateArtWorkErrors = (form) => {
  if (!form.cabms_key || form.cabms_key === "")
    notice("Debes agregar Artículo CABMS para crear Obra de Arte");
  else if (!form.cf_label_type) notice("Debes seleccionar un tipo de etiqueta");
  else return true;
};

export const getArtWorkPayload = (form) => {
  const { cf_cve_prove: old_cf_cve_prove, cf_num_area, item, key } = form;
  const cf_cve_prove = ["string", "number"].includes(typeof old_cf_cve_prove)
    ? old_cf_cve_prove
    : old_cf_cve_prove.text2;
  const item_key = form.key ? getKeyData(form.key) : {};
  const {
    cf_grupo: cf_grupoc,
    cf_subgrupo: cf_subgrupoc,
    cf_clase: cf_clasec,
    cf_subclase: cf_subclasec,
    cf_tipo: cf_tipoc,
  } = form.cabms_key ? getKeyData(form.cabms_key) : {};
  const cf_cve_partida = form.cf_cve_partida?.text2
    ? form.cf_cve_partida?.text2
    : undefined;
  let payload = {
    ...form,
    ...item_key,
    cf_cve_prove,
    cf_cve_partida: form.cf_cve_partida?.text2 ? form.cf_cve_partida?.text2 : undefined,
    cf_num_area: cf_num_area?.num_area,
    cf_articulo: item,
    cabms: cf_grupoc && {
      cf_grupoc,
      cf_subgrupoc,
      cf_clasec,
      cf_subclasec,
      cf_tipoc,
    },
  };
  if (key === "" || !key) delete payload.cf_articulo;
  delete payload.item;
  delete payload.key;
  return payload;
};

export const getFilteredPayload = (form) => {
  const { cf_cve_prove: old_cf_cve_prove, cf_num_area, item, key, cabms_key } = form;
  const cf_cve_prove = ["string", "number"].includes(typeof old_cf_cve_prove)
    ? old_cf_cve_prove
    : old_cf_cve_prove.text2;
  const item_key = {
    cf_grupo: key[0],
    cf_subgrupo: key[1],
    cf_clase: key[2],
    cf_subclase: key[3],
    cf_tipo: key[4],
  };

  const cf_cve_partida = form.cf_cve_partida?.text2
    ? form.cf_cve_partida?.text2
    : undefined;
  let payload = {
    ...form,
    ...item_key,
    cf_cve_prove,
    cf_cve_partida,
    cf_num_area: cf_num_area?.num_area,
    cf_articulo: item,
    cabms: cabms_key,
  };
  if (key === "" || !key) delete payload.cf_articulo;
  delete payload.item;
  delete payload.cabms_item;
  delete payload.key;
  delete payload.cabms_key;
  return payload;
};
