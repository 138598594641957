import axios from 'axios';
import { ASSTETS_STOCK, UPLOAD_FILE_URL } from "./helpers/routes";

export const getContractList = (limit, page, filters = '') => {
	return axios.get(`${ASSTETS_STOCK}/contratos?&page=${page}&limit=${limit}&${filters}`)
};

export const getSingleContract = (id) => {
	return axios.get(`${ASSTETS_STOCK}/contratos/${id}`);
};

export const editContract = (id, payload) => {
	return axios.put(`${ASSTETS_STOCK}/contratos/${id}`, payload);
};

export const createContract = (payload) => {
	return axios.post(`${ASSTETS_STOCK}/contratos`, payload);
}

export const addContractFile = (file=null, attach_id, title="", attach_model="contract", field="pdfs") => {
  const body = {
    attach_id,
    attach_model,
    image_data: file,
    field,
    title,
  };
  const formData = new FormData();

  for(const name in body) {
    formData.append(name, body[name]);
  }
	return axios.post(UPLOAD_FILE_URL, formData);
};
