import React from 'react';
import { Typography } from '@material-ui/core';
import Icon from './Icon';
import { toAbsoluteUrl } from '../../_metronic/_helpers';


const IconActions = ({icons = []}) => {
  return (
    <div className="d-flex flex-wrap">
      {icons.map(icon => (
        <div key={icon.label || icon.tooltip} className="d-flex flex-column align-items-center mx-2">
          <Icon
            src={toAbsoluteUrl(icon.image)}
            color={icon.color || "secondary"}
            label={icon.tooltip}
            onClick={icon.onClick}
            disabled={icon.disabled}
            style={icon.style}
            disableTooltip={!icon.tooltip}
          />
          {icon.label&&<Typography>{icon.label}</Typography>}
        </div>
      ))}
    </div>
  )
}

export default IconActions
