import React, { useState } from "react";
import {
  ClickAwayListener,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    width: ({ isMobile, width }) => (width ? width : 200),
  },
  options: {
    position: "absolute",
    zIndex: 1,
  },
}));

const SimpleTextAutocomplete = ({
  name,
  value,
  onChange,
  options = [],
  label,
  placeholder,
  required,
  disabled,
  uppercase = false,
}) => {
  const [open, setOpen] = useState(false);
  const [touched, setTouched] = useState(false);
  const classes = useStyles();

  const handleBlur = (e) => {
    !value ? setTouched(true) : setTouched(false);
  };

  const handleSelect = (option) => {
    onChange({ target: { name, value: !uppercase ? option : option.toUpperCase() } });
    setOpen(false);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    if (uppercase) e.target.value = e.target.value.toUpperCase();
    onChange(e);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Typography className="ml-2">{label}</Typography>
        <TextField
          size="small"
          variant="outlined"
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
          onClick={() => setOpen(true)}
          required={required}
          error={touched && required}
          className={classes.input}
          onBlur={handleBlur}
          onKeyDown={(e) => e.key === "Tab" && setOpen(false)}
        />
        {open && (
          <Paper className={classes.options}>
            {options.map((option) => (
              <MenuItem key={option} value={option} onClick={(e) => handleSelect(option)}>
                {option}
              </MenuItem>
            ))}
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default SimpleTextAutocomplete;
