import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import EntranceReturnForm from './EntranceReturnForm'
import { useParams } from 'react-router'
import { getEntranceDetail } from '../../crud/entrancesStockCrud'
import { setClearState, setRefund, setSelectedIds } from '../../../redux/ducks/entrance.duck'
import notice from '../../components/Notice'
import { setCurrentMode } from '../../../redux/ducks/subHeaderActions.duck'
import { setLoader } from '../../../redux/ducks/loader.duck'

const EntranceReturnStock = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    if (id){
      dispatch(setLoader(true))
      getEntranceDetail(id)
      .then(res => {
        dispatch(setSelectedIds([id]))
		  	dispatch(setRefund({...res, id}))
		  })
      .catch(() => {
		  	notice("Error cargando la entrada")
		  	dispatch(setClearState())
		  	dispatch(setCurrentMode("new"))
		  }).finally(() => dispatch(setLoader(false)))
    }
  }, [dispatch, id])

  return (
    <>
      <EntranceReturnForm />
    </>
  )
}

export default EntranceReturnStock
