export const cleanObject = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === 0 ||
      obj[propName] === "0"
    ) {
      delete obj[propName];
    } else if (typeof obj[propName] === "object") {
      if (obj[propName].length === 0 || Object.keys(obj[propName]).length === 0)
        delete obj[propName];
    }
  }
  return obj;
};

export const cleanObjectWithZero = (obj) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    } else if (typeof obj[propName] === "object") {
      if (obj[propName].length === 0 || Object.keys(obj[propName]).length === 0)
        delete obj[propName];
    }
  }
  return obj;
};

export const cleanFilter = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      typeof obj[propName] === "object"
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const clearEmptyStringsObject = (obj) => {
  for (var propName in obj) {
    if (obj[propName] === "") {
      delete obj[propName];
    }
  }
  return obj;
};

export const filterParams = (obj) =>
  Object.entries(obj)
    .map((x) => x.join("="))
    .join("&");

// Converts array of inputs to type text and enables all, also removes photo and file inputs
export const fieldsToTypeText = (inputs, pathname, currentMode) => {
  return inputs
    .filter(
      (field) =>
        field.type !== "photo" && field.type !== "file" && field.type !== "line-break"
    )
    .map((input) =>
      ["dropdown", "text"].includes(input.type)
        ? { ...input, disabled: false }
        : {
            ...input,
            type: `${
              input.label === "Proveedor" && pathname.includes("obras-arte/busqueda")
                ? "search"
                : input.label === "Responsables" &&
                  pathname.includes("obras-arte/resultados")
                ? "text"
                : input.label === "Responsables" &&
                  pathname.includes("obras-arte/busqueda")
                ? "search-multiple"
                : "text"
            }`,
            disabled: false,
          }
    );
};
export const trimObjValues = (obj) => {
  Object.keys(obj).map((k) =>
    typeof obj[k] === "string" ? (obj[k] = obj[k].trim()) : obj[k]
  );
  return obj;
};

// Returns a flatten object by searching for property recursively

export const flattenTree = (array, key) =>
  array.flatMap((item) => (item[key] ? [item, ...flattenTree(item[key], key)] : item));
