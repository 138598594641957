/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
// import CloseColumnIcon from "./list-icons/CloseColumnIcon";
import EyeColumnIcon from "./list-icons/EyeColumnIcon";
import FileColumnIcon from "./list-icons/FileColumnIcon";
// import FileColumnIcon from "./list-icons/FileColumnIcon";

export const DeparturesActionsColumnFormatter = (cellContent, row, rowIndex, { onFile, onEye, onCross }) => (
	<>
		<EyeColumnIcon onClick={() => onEye(row.id)}/>
		<FileColumnIcon onClick={() => onFile(row.id)}/>
		{/* <CloseColumnIcon tooltip="Borrar" onClick={() => onCross(row.id)} /> */}
	</>
);
