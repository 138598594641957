/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { ContentRoute, Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import QRViewPage from "./modules/QRView/QRViewPage";
import QRViewArtworkPage from "./modules/QRViewArtwork/QRViewArtworkPage";
import ModulesPage from "./pages/Modules/ModulesPage";
import MonitoringWrapper from "./pages/Monitoring/MonitoringWrapper"
import MeUser from "./pages/Users/MeUser";
import { setupAxios } from "../redux";
import store from "../redux/store";

export function Routes() {
  const { authToken } = useSelector(store => store.auth);

  useEffect(() => {
    setupAxios(store);
  }, [authToken]);

  const { isAuthorized, currentModule } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      currentModule: auth.currentModule
    }),
    shallowEqual
  );

  return (
    <Switch>
      <Route exact path={'/consulta-qr/inventario/:id'} component={QRViewPage} /> 
      <Route path={'/consulta-qr/inventario/oa/:id'} component={QRViewArtworkPage} />  
      <ContentRoute path="/monitoreo" component={MonitoringWrapper} />
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      { isAuthorized && <Route exact path="/modulos"><Layout><ModulesPage/></Layout></Route> }
      { isAuthorized && <Route path="/usuarios/EditarPerfil/:id"><Layout><MeUser/></Layout></Route> }

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : !currentModule ? <>
      <Redirect to="/modulos"/> 
      </>
      : (
        <>
        <Layout>
          <BasePage />
        </Layout>
        </>
      )}
    </Switch>
  );
}
