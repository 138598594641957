import React from "react";
import {
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

/**
 *
 * Type Objet (nested object)
 * data:
 * {
 *  cve: { label },
 *  ...
 * }
 *
 */

const CheckboxList = ({ data, onChangeData }) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(Object.keys(data));

  if (Object.keys(data).includes("cf_importe")) {
    delete data["cf_importe"];
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    onChangeData(newChecked);
    setChecked(newChecked);
  };

  return (
    <List className={classes.root}>
      {Object.keys(data).map((value) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
          <ListItem
            key={value}
            role={undefined}
            dense
            button
            onClick={handleToggle(value)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={`${data[value].label}`} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default CheckboxList;
