import React from 'react'
import { makeStyles } from '@material-ui/core';
import SimpleInput from '../../../components/SimpleInput';
import { reportStockInputs } from '../../../utils/stockData';

const useStyles = makeStyles(() => {
	return {
		largeInputs: {
			display: 'flex',
			flexWrap: 'wrap',
			'&>*': {
				margin: '15px 15px 0px 0px',
			},
		},
}});

const ReportsTab = ({ onChange, selectedStock, currentMode }) => {
  const classes = useStyles()

  return (
    <div className={classes.largeInputs}>
				{reportStockInputs.map(input => (
					<div style={{ alignSelf: 'flex-end' }} key={input.name}>
						<SimpleInput
							disabled={!currentMode}
							label={input.label}
							name={input.name}
							multiline={true}
							rows={3}
							width={250}
							value={selectedStock[input.name]}
							onChange={onChange}
						/>
					</div>
				))}
			</div>
  )
}

export default ReportsTab