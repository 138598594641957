import { axios } from "../../redux/setupAxios";
import { getTodayDate } from "../utils/dates";
import { downLoaderWithURL } from "../utils/downloaderWithURL";
// import { formatDate } from "../utils/dates";
import { filterParams } from "../utils/objectsFunctions";

import {
  INVENTORY_SURVEY_API,
  INVENTORYS_SURVEY_API,
  INVENTORY_API,
} from "./helpers/routes";
import { getParams, handleError, handleResponse } from "./helpers/validate";

export const getInventoryList = (params) => {
  const filteredParams = filterParams(params);
  const url = `${INVENTORYS_SURVEY_API}?${filteredParams}`;
  return axios.get(url);
};
export const getReportInventory = () => {
  const url = `${INVENTORY_SURVEY_API}/report`;
  return axios.post(url);
};
export const getOneInventory = (id) => {
  return axios.get(`${INVENTORY_SURVEY_API}/${id}`);
};

export const createNewInventory = (body) => {
  return axios.post(INVENTORY_SURVEY_API, body);
};

export const editInventory = async (id, body) => {
  return fetch(`${INVENTORY_SURVEY_API}/${id}/`, getParams("PATCH", body))
    .then(handleResponse)
    .catch(handleError);
};

export const inventoryArea = (data) => {
  return axios.post(`${INVENTORY_API}-artwork-by-multiple-areas`, data);
};

export const getInventory = (no_inventa) => {
  let query = "";
  no_inventa === "" ? (query = 0) : (query = no_inventa);
  const url = `${INVENTORY_API}?no_inventa=${query}`;
  return axios
    .get(url)
    .then(
      (res) =>
        res?.status &&
        res.status === 200 &&
        res.data.results.map((option) => ({
          ...option,
          id: option?.id,
          text: option?.id?.toString(),
          text2: option?.cf_articulo?.trim(),
        }))
    )
    .catch((err) => console.log("error" + err));
};

export const deleteInventory = (id) => {
  return axios.delete(`${INVENTORY_SURVEY_API}/${id}`);
};

export const getFormat = async (id, type, variant) => {
  try {
    const res = await axios.get(
      `${INVENTORY_API}/format-levantamiento/${id}?format_type=${type}&format_class=${variant}`
    );
    console.log(res);
    const formatAURL = res.data.attachment_url;
    const name = `Formato${type}-${id}-${getTodayDate()}`;
    downLoaderWithURL(formatAURL, name + ".pdf");
  } catch (error) {
    console.log(error);
  }
};
