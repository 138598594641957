import { Paper } from "@material-ui/core";
import React from "react";

const ArticleCard = ({ article }) => {
  if (!article) return <h3 className="text-danger">Este articulo no existe</h3>;

  return (
    <Paper
      className="p-4 grid-3-columns"
      style={{ gridAutoRows: "minmax(50px, auto)" }}
    >
      <div>
        <b>Articulo</b>
        <br />
        <label>{article?.desc_corta}</label>
      </div>
      <div>
        <b>Descripción</b>
        <br />
        <label>{article?.desc_amplia}</label>
      </div>
      <div>
        <b>Unidad</b>
        <br />
        <label>{article?.uni_distribuye}</label>
      </div>
      <div>
        <b>Existencia</b>
        <br />
        <label>{article?.stock?.existencia_total}</label>
      </div>
      <div>
        <b>Estatus</b>
        <br />
        {
          <label
            className={`badge badge-pill badge-${
              article?.state ? "success" : "danger"
            }`}
          >
            {article?.state ? "Activo" : "Inactivo"}
          </label>
        }
      </div>
    </Paper>
  );
};

export default ArticleCard;
