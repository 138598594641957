import React, { useRef } from 'react'
import { Folder as FolderIcon } from '@material-ui/icons';
import { Typography, Grid, useTheme, makeStyles } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import ArchiveIcon from '@material-ui/icons/Archive';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles({
  container: {
    backgroundColor: 'transparent',
    opacity: 0.86,
    "&:hover": {
      opacity: 1
    }
  }
});

const FONT_SIZE = 150;

const FileFolderElement = ({ 
  folder={}, 
  onClick=()=>{}, 
  onMouseEnter=()=>{},
  onDragStart=()=>{},
  onDragStop=()=>{},
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    secondary,
    red,
    green,
    grey
  } = theme?.palette;
  const {
    name
  } = folder;
  let Icon, color;
  if( ( folder?.subfolders && folder?.files ) || !folder.hasOwnProperty('attachment') ){
    Icon = FolderIcon;
    color = secondary?.main;
  } else {
    switch(folder?.attachment?.field){
      // Pdfs
      case "pdf":
        Icon = PictureAsPdfIcon;
        color = red?.main;
        break;
      // Images
      case "img":
        Icon = ImageIcon;
        color = green?.main;      
        break;
      case "jpg":
        Icon = ImageIcon;
        color = green?.main;      
        break;
      case "jpeg":
        Icon = ImageIcon;
        color = green?.main;      
        break;
      case "png":
        Icon = ImageIcon;
        color = green?.main;      
        break;
      // Excell
      case "xlsx":
        Icon = ListAltIcon;
        color = green?.main;      
        break;  
      case "xls":
        Icon = ListAltIcon;
        color = green?.main;      
        break;  
      case "csv":
        Icon = ListAltIcon;
        color = green?.main;      
        break;  
      // Rest
      case "zip":
        Icon = ArchiveIcon;
        color = green?.main;      
        break;
  
      default: 
        Icon = DescriptionIcon;
        color = grey?.main;
    };
  }

  const stopFolder = useRef(null);
  // 100ms prevents click after dragevent
  const onStop = (e, data) => {
    // preventDefault stops Grid onClick event
    stopFolder.current =  folder;
    e?.preventDefault();
    setTimeout(() => {
      onDragStop(e, stopFolder?.current);
    }, 400);
  };

  const onStart = (e) => {
    setTimeout(() => {
      onDragStart();
    }, 400);
  }
  
  return (
    <div
      draggable
      onDragEnd={onStop}
      onDragStart={onStart}
      id={folder?.id}
    >
      <Grid 
        className={`${classes.container} p-5 cursor-pointer`} 
        title={name} 
        item 
        onClick={(e) => {
          onClick(folder);
        }}
        onMouseEnter={onMouseEnter}
      >
        <Icon className="opacity-50" style={{ fontSize: FONT_SIZE, color }}/>
        <Typography align="center" style={{ maxWidth: 150 }} className='text-break'>{name}</Typography>
      </Grid>
    </div>
  );
};

export default FileFolderElement;