import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultFilters, defaultVehicule } from "../../app/utils/vehiculesData";

const actionType = {
  SetSelectedVehicule: "[VEHICULES] SET_VEHICULE",
  SetFilteredVehicule: "[VEHICULES] SET_FILTERED_VEHICULE",
  ClearFilteredVehicule: "[VEHICULES] CLEAR_FILTERED_VEHICULE",
  SetVehicules: "[VEHICULES] SET_VEHICULES",
  SetVehiculeDrops: "[VEHICULES] SET_VEHICULE_DROPS",
  SetVehiculesIds: "[VEHICULES] SET_VEHICULES_IDS",
  SetCurrentVehiculeTab: "[VEHICULES] SET_CURRENT_VEHICULE_TAB",
  SetNewVehicule: "[VEHICULES] SET_NEW_VEHICULE",
  ClearNewVehicule: "[VEHICULES] CLEAR_NEW_VEHICULE",
  SetLoading: "[VEHICULES] SET_LOADING",
  SetEnableInputs: "[VEHICULES] SET_ENABLE_INPUTS",
  SetResetVehiclesForm: "[VEHICULES] SET_RESET_VEHICLES_FORM",
  SetClearVehicles: "[VEHICULES] SET_CLEAR_VEHICLES",
};

const initialState = {
  list: {
    results: [],
    count: 0,
    page: 1,
    per_page: 50,
  },
  dropList: {
    results: [],
    count: 0,
    page: 1,
    per_page: 50,
  },
  selectedVehicule: defaultVehicule,
  selectedVehiculeIds: [],
  filteredVehicule: defaultFilters,
  newVehicule: defaultVehicule,
  isSearchTab: false,
  isResultsTab: false,
  isNewTab: false,
  loading: false,
  enableInputs: true,
  resetVehiclesForm: {},
};

export const reducer = persistReducer(
  { storage, key: "vehicules", whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetVehicules:
        return { ...state, list: action.payload };

      case actionType.SetVehiculeDrops:
        return { ...state, dropList: action.payload };

      case actionType.SetSelectedVehicule:
        return { ...state, selectedVehicule: action.payload };

      case actionType.SetFilteredVehicule:
        return { ...state, filteredVehicule: action.payload };

      case actionType.CleatFilteredVehicule:
        return { ...state, filteredVehicule: defaultFilters };

      case actionType.SetVehiculesIds:
        return { ...state, selectedVehiculeIds: action.payload };

      case actionType.SetCurrentVehiculeTab:
        return { ...state, ...action.payload };

      case actionType.SetNewVehicule:
        return { ...state, newVehicule: action.payload };

      case actionType.ClearNewVehicule:
        return { ...state, newVehicule: defaultFilters };

      case actionType.SetLoading:
        return { ...state, loading: action.payload };

      case actionType.SetEnableInputs:
        return {
          ...state,
          enableInputs: action.payload,
        };

      case actionType.SetResetVehiclesForm:
        return {
          ...state,
          resetVehiclesForm: action.payload,
        };

      case actionType.SetClearVehicles:
        return {
          ...initialState,
        };

      default:
        return state;
    }
  }
);

export const setVehicules = (data) => ({ type: actionType.SetVehicules, payload: data });

export const setVehiculeDrops = (data) => ({
  type: actionType.SetVehiculeDrops,
  payload: data,
});

export const setVehiculesIds = (data) => ({
  type: actionType.SetVehiculesIds,
  payload: data,
});

export const setSelectedVehicule = (data) => ({
  type: actionType.SetSelectedVehicule,
  payload: data,
});

export const setFilteredVehicule = (data) => ({
  type: actionType.SetFilteredVehicule,
  payload: data,
});

export const clearFilteredVehicule = (data) => ({
  type: actionType.ClearFilteredVehicule,
  payload: data,
});

export const setCurrentVehiculeTab = (newTab) => ({
  type: actionType.SetCurrentVehiculeTab,
  payload: newTab,
});

export const setNewVehicule = (data) => ({
  type: actionType.SetNewVehicule,
  payload: data,
});

export const clearNewVehicule = (data) => ({ type: actionType.ClearNewVehicule });

export const setLoading = (isLoading) => ({
  type: actionType.SetLoading,
  payload: isLoading,
});

export const setEnableInputs = (enable) => ({
  type: actionType.SetEnableInputs,
  payload: enable,
});

export const setResetVehiclesForm = (formData) => ({
  type: actionType.SetResetVehiclesForm,
  payload: formData,
});

export const setClearVehicles = () => ({ type: actionType.SetClearVehicles });
