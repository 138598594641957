import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultRequest } from "../../app/pages/Configuration/Warehouse/utils";

const actionType = {
  SetModule: '[CONFIG] SET_MODULES',
  SetRequest: '[CONFIG] SET_REQUEST',
  ClearState: '[CONFIG] CLEAR_STATE',
};

const initialState = {
  module: null,
  request: defaultRequest
};

export const reducer = persistReducer(
  { storage, key: 'config', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetModule:
        return { ...state, module: action.payload }
      
      case actionType.SetRequest:
        return { ...state, request: action.payload }
      
      case actionType.ClearState:
        return state = initialState
      
      default:
        return state
    }
  }
)

export const setModule = data => ({ type: actionType.SetModule, payload: data })

export const setRequest = data => ({ type: actionType.SetRequest, payload: data })

export const setClearState = data => ({ type: actionType.ClearState, payload: data })
