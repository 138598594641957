import React from "react";
import {
  Image,
  Archive,
  ListAlt,
  PictureAsPdf,
  InsertDriveFile,
} from "@material-ui/icons";
import { Tooltip, useTheme } from "@material-ui/core";

const FileIconImg = ({
  name = "",
  type = "",
  width = 40,
  hideTitle = false,
  tooltip = false,
  tooltipPos = "top",
}) => {
  const theme = useTheme();
  const { secondary, red, green, grey } = theme?.palette;
  let Icon, color;
  switch (type) {
    case "image/png":
      Icon = Image;
      color = green?.main;
      break;
    case "image/jpeg":
      Icon = Image;
      color = green?.main;
      break;
    case "image/jpg":
      Icon = Image;
      color = green?.main;
      break;
    case "image/gif":
      Icon = Image;
      color = green?.main;
      break;

    case "application/zip":
      Icon = Archive;
      color = grey?.main;
      break;
    case "application/pdf":
      Icon = PictureAsPdf;
      color = red?.main;
      break;

    case "text/csv":
      Icon = ListAlt;
      color = secondary?.main;
      break;

    case "application/vnd.ms-excel":
      Icon = ListAlt;
      color = secondary?.main;
      break;

    default:
      Icon = InsertDriveFile;
      color = secondary?.main;
  }
  if (tooltip) {
    return (
      <Tooltip title={name} placement={tooltipPos}>
        <div style={{ width }} className="mx-2">
          <Icon
            style={{
              color,
              fontSize: width,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "clip",
            }}
          />
        </div>
      </Tooltip>
    );
  } else {
    return (
      <div style={{ width }} className="mx-2">
        <Icon
          style={{
            color,
            fontSize: width,
          }}
        />
        {!hideTitle && (
          <p
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              fontSize: 8,
              textAlign: "center",
            }}
          >
            {name}
          </p>
        )}
      </div>
    );
  }
};

export default FileIconImg;
