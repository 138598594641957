import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPages } from '../../redux/ducks/pathname.duck';
import { useCheckRoutes } from '../hooks/useCheckRoutes';
import { routesPaths } from '../utils/routesPaths';

// routes in utils/routesPaths. then will be stored with its name transformed, in example: from 'details' to 'isDetailsPage'
const routes = Object.keys(routesPaths).map(key => key);

const PathnameWrapper = ({ children }) => {
    const dispatch = useDispatch();

	const isInRouteArray = useCheckRoutes(routes);

    useEffect(() => {
        let newPages = {};
        for(let i=0;i<routes.length;i++){
            const route = routes[i];
            const capitalizedNameRoute = route.charAt(0).toUpperCase() + route.slice(1);
            const nameRoute = `is${capitalizedNameRoute}Page`;
            newPages[nameRoute] = isInRouteArray[i];
        };
        dispatch(setPages(newPages));
    }, [isInRouteArray, dispatch]);


    return (
        <>
            {children}
        </>
    );
};

export default PathnameWrapper;