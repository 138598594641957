import React from 'react';
import { Badge } from '@material-ui/core'
import FileIconImg from './FileIconImg'

const AttachmenstList = ({ attachments=[], onClick=()=>{} }) => {

  return (
    <>
      {attachments?.map((att, i) => (
        <Badge badgeContent="X" color="error" key={att.name} style={{ cursor: "pointer" }} onClick={(e) => onClick(e, i)}>
          <FileIconImg name={att.name} type={att.type} width={60} />
        </Badge>
      ))}
    </>
  );
};

export default AttachmenstList;