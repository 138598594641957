import React, { useEffect, useState } from 'react'
import { getAllPendingTasks } from '../../crud/taskManagementCrud';
import { Redirect, Switch } from 'react-router-dom'
import { ContentRoute } from '../../../_metronic/layout'
import MonitoringRequest from './MonitoringRequest'
import MonitoringRFID from './MonitoringRFID'
import MonitoringTask from './MonitoringTask'
import MonitoringAppBar from './components/MonitoringAppBar'
import { listRequestsPublic } from '../../crud/requestCrud';
import { useDispatch, useSelector } from 'react-redux';
import { format_calendar, format_trello_data } from './utils/data';
import { setTasks, setRequests } from '../../../redux/ducks/dashboard.duck';
import SocketContainer from './Socket';

const MonitoringWrapper = () => {

  const dispatch = useDispatch();
  const [ loadRequests, setLoadRequests ] = useState(false);
  const { requests, tasks } = useSelector(store => store.dashboard);
  
  // GET REQUESTS
  useEffect(() => {
    setLoadRequests(true);
    listRequestsPublic(1, 14, 'cf_estatus=T')
    .then(res => {
      if (res.status === 200) {
        dispatch(setRequests(res.data))
      }
    })
    .catch(console.log)
    .finally(() => setLoadRequests(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch]);

  useEffect( () => {
    getAllPendingTasks()
    .then(response => {
      if ( response.results.length ) {
        let formatResponse = format_trello_data(response.results)
        dispatch(setTasks(formatResponse))
      }        
    })
    .catch( e => console.log(e) )
  }, [dispatch])

  return (
    <>
      <MonitoringAppBar />
      <SocketContainer />
      <div className="p-8">
        <Switch>
          <Redirect exact from="/monitoreo" to="/monitoreo/RFID" />
          <ContentRoute
            path="/monitoreo/RFID"
            render={() => (
              <MonitoringRFID
                requests={requests.results}
                loadRequests={loadRequests}
              />
            )}
          />
          <ContentRoute
            path="/monitoreo/solicitudes"
            render={() => <MonitoringRequest requests={requests.results} />}
          />
          <ContentRoute
            path="/monitoreo/pendientes"
            render={() => (
              <MonitoringTask
                tasks={format_calendar(tasks.results)}
                requests={requests.results}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
}

export default MonitoringWrapper
