import PropTypes from "prop-types"
import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es-us'
import PageLayout from './PageLayout'
import { invertColor } from "../utils/colors";

const customView = ({ view, event, backgroundColor }) => {
  if (view.type === "listMonth")
  return(
    <div
      className="w-100 px-2 py-1"
    >
      <span className="font-size-sm text-wrap">
        {event.title}
      </span>
      <span className="font-weight-bolder text-wrap mx-4">
        {event.extendedProps.status}
      </span>
      <span className="mx-4">{event.extendedProps.folio}</span>
      <span className="mx-4">{event.extendedProps.department}</span>
      <span className="mx-4">{event.extendedProps.user}</span>
      <span className="mx-4">{event.extendedProps.area}</span>
      {event.extendedProps.user && event.extendedProps.user[0] && <span style={{ background: backgroundColor, float: "right", color: invertColor(backgroundColor, true) }} className="badge badge-pill rounded-circle">{event.extendedProps.user[0]}</span>}
    </div>
  )

  return (
    <div
      className="w-100 px-2 py-1"
      style={{ background: backgroundColor, color: invertColor(backgroundColor, true) }}
    >
      <p className="font-size-sm text-wrap">
        {event.title}
      </p>
      <p className="font-weight-bolder text-wrap">
        {event.extendedProps.status}
      </p>
    </div>
  )
}

const Calendar = ({ data=[], eventClick=()=>{}, calendarPlugins=[dayGridPlugin, listPlugin], initialView="dayGridMonth", calendarViews="dayGridMonth,listMonth", title="" }) => {
  return (
    <PageLayout className="bg-white" title={title}>
      <FullCalendar
        events={data}
        eventClick={eventClick}
        locale={esLocale}
        plugins={calendarPlugins}
        initialView={initialView}
        headerToolbar={{
          left: "today prev,next", 
          center: "title",
          right: calendarViews
        }}
        eventContent={customView}
      />
    </PageLayout>
  )
}

Calendar.propTypes = {
  calendarPlugins: PropTypes.array,
  calendarViews: PropTypes.string,
  data: PropTypes.array.isRequired,
  eventClick: PropTypes.func.isRequired,
  initialView: PropTypes.string,
  title: PropTypes.string
}

export default Calendar
