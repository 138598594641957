import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createSlice } from "@reduxjs/toolkit";
import update from "immutability-helper/index";
import _ from "lodash";

export const windowActionTypes = {
  init: "INIT",
  search: "SEARCH",
  new: "NEW",
  edit: "EDIT",
  results: "RESULTS",
};

const initialState = {
  configuration: {
    displayBar: false,
    menuItems: [],
    tabs: [],
    activeTab: null,
    paginateInfo: {},
    windowFormAvailableToCopy: {},
  },
};

export const windowSlice = createSlice({
  name: "[WINDOW]",
  initialState,
  reducers: {
    setDisplayBar: {
      reducer: (state, action) => {
        state.configuration.displayBar = action.payload;
      },
    },
    addNewTab: {
      reducer: (state, action) => {
        const {
          configuration: { tabs },
        } = state;
        const offTargets = tabs.map((tab) => ({ ...tab, target: false }));
        const initialTabState = {
          ...action.payload,
          x: 0,
          y: 0,
          target: true,
          paginationIndex: 0,
        };
        state.configuration.tabs = offTargets.concat(initialTabState);
        state.configuration.activeTab = initialTabState;
      },
    },
    removeTab: {
      reducer: (state, action) => {
        const {
          configuration: { tabs, activeTab },
        } = state;
        const getIndexTarget = _(tabs).findIndex((tab) => tab.id === action.payload);
        state.configuration.tabs = update(tabs, { $splice: [[getIndexTarget, 1]] });
        if (action.payload === activeTab?.id) state.configuration.activeTab = null;

        //reset results from menuItem
        // const index = state.configuration.menuItems.findIndex(x => x.id === action.payload);
        // state.configuration.menuItems[index] = initialState.configuration.menuItems[index];
      },
    },
    setTabTarget: {
      reducer: (state, action) => {
        const {
          configuration: { tabs },
        } = state;
        const getIndexTarget = _(tabs).findIndex((tab) => tab.id === action.payload);
        const offTargets = tabs.map((w) => ({ ...w, target: false }));
        state.configuration.tabs = update(offTargets, {
          [getIndexTarget]: {
            target: { $set: true },
          },
        });
        state.configuration.activeTab = tabs[getIndexTarget];
      },
    },
    setTabMinimize: {
      reducer: (state, action) => {
        const {
          configuration: { tabs },
        } = state;
        const getIndexTarget = _(tabs).findIndex((tab) => tab.id === action.payload);
        state.configuration.tabs = update(tabs, {
          [getIndexTarget]: {
            target: { $set: false },
          },
        });
        state.configuration.activeTab = null;
      },
    },
    setTabPosition: {
      reducer: (state, action) => {
        const {
          configuration: { tabs, activeTab },
        } = state;
        const {
          id,
          position: { x, y },
        } = action.payload;
        const getIndexTarget = _(tabs).findIndex((tab) => tab.id === id);
        state.configuration.tabs = update(tabs, {
          [getIndexTarget]: {
            x: { $set: x },
            y: { $set: y },
          },
        });
        state.configuration.activeTab = update(activeTab, {
          x: { $set: x },
          y: { $set: y },
        });
      },
      prepare: (id, position) => ({ payload: { id, position } }),
    },
    setFormValues: {
      reducer: (state, action) => {
        const { values } = action.payload;
        const { activeTab, tabs } = state.configuration;
        if (activeTab) {
          const { id } = activeTab;
          const index = state.configuration.tabs.findIndex((x) => x.id === id);
          state.configuration.activeTab.inputValues = {
            ...state.configuration.activeTab.inputValues,
            ...values,
          };
          state.configuration.tabs[index].inputValues = {
            ...state.configuration.tabs[index].inputValues,
            ...state.configuration.activeTab.results.paginationPage,
            ...values,
          };

          //update position pagination
          const { results, inputValues } = activeTab;
          const idxPagination = _(results).findIndex((res) => res.id === inputValues.id);

          if (idxPagination !== -1) {
            state.configuration.tabs = update(tabs, {
              [index]: { paginationIndex: { $set: idxPagination } },
            });
          }
        }
      },
      prepare: (values) => ({ payload: { values } }),
    },
    setGroupValues: {
      reducer: (state) => {
        const { activeTab } = state.configuration;
        if (activeTab) {
          const { id } = activeTab;
          const index = state.configuration.tabs.findIndex((x) => x.id === id);

          state.configuration.activeTab.inputValues = {
            ...state.configuration.activeTab.inputValues,
            sg: "",
            ssg: "",
          };

          state.configuration.tabs[index].inputValues = {
            ...state.configuration.tabs[index].inputValues,
            ...state.configuration.activeTab.results.paginationPage,
            sg: "",
            ssg: "",
          };
        }
      },
    },
    setQueryToSearch: {
      reducer: (state, action) => {
        const { queryToSearch } = action.payload;
        const { activeTab, tabs } = state.configuration;

        if (activeTab) {
          const { id } = activeTab;

          const index = state.configuration.tabs.findIndex((x) => x.id === id);

          state.configuration.tabs = update(tabs, {
            [index]: { queryToSearch: { $set: queryToSearch } },
          });

          state.configuration.activeTab = update(activeTab, {
            queryToSearch: { $set: queryToSearch },
          });
        }
      },
      prepare: (queryToSearch) => ({ payload: { queryToSearch } }),
    },

    setResults: {
      reducer: (state, action) => {
        const { results } = action.payload;
        const { activeTab, tabs } = state.configuration;
        if (activeTab) {
          const { id } = activeTab;
          const index = state.configuration.tabs.findIndex((x) => x.id === id);
          state.configuration.activeTab = update(activeTab, {
            results: { $set: results[0] },
            ids: { $set: results[2] },
            paginationIndex: { $set: 0 },
            paginationCount: { $set: results[1].count }, //count: 774
            paginationPerPage: { $set: results[1].per_page },
            paginationPage: { $set: results[1].page },
            paginationTotalPage: { $set: results[1].total_pages },
            paginationLinks: { $set: results[1].links },
          });

          state.configuration.tabs = update(tabs, {
            [index]: {
              results: { $set: results[0] },
              ids: { $set: results[2] },
              paginationIndex: { $set: 0 },
            },
          });
        }
      },

      prepare: (results) => ({ payload: { results } }),
    },
    setViewType: {
      reducer: (state, action) => {
        const { activeTab, tabs } = state.configuration;
        const { view } = action.payload;
        if (activeTab) {
          const { id } = activeTab;
          const index = state.configuration.tabs.findIndex((x) => x.id === id);
          state.configuration.tabs = update(tabs, {
            [index]: { viewType: { $set: view } },
          });
          state.configuration.activeTab = update(activeTab, {
            viewType: { $set: view },
          });
        }
      },
      prepare: (view) => ({ payload: { view } }),
    },
    resetForm: {
      reducer: (state) => {
        const { activeTab } = state.configuration;
        if (activeTab) {
          const { id } = activeTab;
          state[id] = initialState[id];
        }
      },
    },
    resetFormValues: {
      reducer: (state) => {
        const { activeTab } = state.configuration;
        if (activeTab) {
          const { id } = activeTab;
          state[id].formValues = initialState[id].formValues;
        }
      },
    },
    setMenuItems: {
      reducer: (state, action) => {
        state.configuration.menuItems = action.payload;
      },
    },
    setLoading: {
      reducer: (state, action) => {
        const { activeTab } = state.configuration;
        if (activeTab) {
          state.configuration.activeTab.loading = action.payload;
        }
      },
    },
    setWindowFormAvailableToCopy: {
      reducer: (state, action) => {
        const { name, label, value } = action.payload;
        state.configuration.windowFormAvailableToCopy[name] = {
          name,
          label,
          value,
        };
      },
    },
    setPureResults: {
      reducer: (state, action) => {
        state.configuration.activeTab.results = action.payload;
      },
    },
    setPureInputValues: {
      reducer: (state, action) => {
        state.configuration.activeTab.inputValues = action.payload;
      },
    },
    setPureWindowFormAvailableToCopy: {
      reducer: (state, action) => {
        state.configuration.windowFormAvailableToCopy = action.payload;
      },
    },
  },
});

export const {
  setDisplayBar,
  addNewTab,
  removeTab,
  setTabTarget,
  setTabMinimize,
  setTabPosition,
  setFormValues,
  setQueryToSearch,
  // setPaginateInfo,
  setResults,
  setViewType,
  resetForm,
  resetFormValues,
  setGroupValues,
  setMenuItems,
  setLoading,
  setWindowFormAvailableToCopy,
  setPureResults,
  setPureInputValues,
  setPureWindowFormAvailableToCopy,
} = windowSlice.actions;

export const windowSelector = (store) => store.window;

export default persistReducer(
  { storage, key: "window", whitelist: [] },
  windowSlice.reducer
);
