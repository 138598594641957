import React from 'react'
import { columnStatusCssClasses } from '../../utils/columnFormattersHelpers'

export const ArticlesStatusColumnFormatter = (cellContent) => {
  return (
    <span
    className={`badge badge-pill badge-${
    columnStatusCssClasses[cellContent ? 1 : 0]
      }`}
		>
			{cellContent ? "Activo" : "Inactivo"}
		</span>
  )
}
