import React from 'react'

export const StatusColumnFormatter = (cellContent) => {
  return (
		<span
			className={`badge badge-pill badge-${cellContent ? "primary" : "danger"} label-inline`}
		>
			{cellContent ? "Abierto" : "Cerrado"}
		</span>
  )
}
