import React from "react";
import { columnStatusCssClasses } from "../../utils/columnFormattersHelpers";

export const RequirementStatusColumnFormatter = (cellContent, row) => {

	return (
		<span
			className={`label label-lg label-light-${
				columnStatusCssClasses[cellContent ? 0 : 1]
			} label-inline`}
		>
			{cellContent? 'Cerrado' : 'Abierto'}
		</span>
	);
};