import React, { useState } from "react";
import { Paper, Typography, makeStyles, CircularProgress } from "@material-ui/core";
import { useLocation } from "react-router-dom";

import notice from "./Notice";

const items = [
  {
    name: "Reporte por área",
    type: "area",
    color: "secondary",
  },
  {
    name: "Reporte por clave CABM",
    type: "cabms",
    color: "primary",
  },
  {
    name: "Reporte por tipo de bien",
    type: "tipo_bien",
    color: "warning",
  },
  {
    name: "Reporte resumen conciliación",
    type: "partida",
    color: "success",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(4),
      width: theme.spacing(24),
      height: theme.spacing(24),
    },
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
  },
}));

const ReportContent = ({ data, report, reportRequest = () => {} }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const isDropPage = pathname.includes("bajas");

  const [downloadReport, setDownloadReport] = useState(false);

  const action = (report, data, type) => {
    let ids = data;
    setDownloadReport(true);
    reportRequest(report, ids, type, isDropPage)
      .then((res) => {
        if (res.attachment_url) window.open(res.attachment_url);
      })
      .catch((res) => {
        notice("No se pudo cargar el PDF, contacta al administrador");
      })
      .finally(() => {
        setDownloadReport(false);
      });
  };

  return (
    <div className={classes.root}>
      {downloadReport ? (
        <CircularProgress color="secondary" />
      ) : (
        items.map((item, index) => (
          <Paper
            onClick={() => {
              action(report, data, item.type);
            }}
            className={`${classes.paper} bg-${item.color} `}
            key={index}
          >
            <Typography color="textPrimary" variant="h6">
              {" "}
              {item.name}{" "}
            </Typography>
          </Paper>
        ))
      )}
    </div>
  );
};

export default ReportContent;
