import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionType = {
  setDisabledSaveFalse: "[SUBHEADER ACTIONS] SET_DISABLED_SAVE_FALSE",
  SetOnReportClickFunction: "[SUBHEADER ACTIONS] SET_ON_REPORT_CLICK_FUNCTION",
  SetOnFormatGuardClick: "[SUBHEADER ACTIONS] SET_ON_FORMAT_GUARD_CLICK",
  SetOnLabelClick: "[SUBHEADER ACTIONS] SET_ON_LABEL_CLICK",
  SetOnLockClick: "[SUBHEADER ACTIONS] SET_ON_LOCK_CLICK",
  SetOnGetFormatA: "[SUBHEADER ACTIONS] SET_ON_GET_FORMATA",
  SetOnGetFormatB: "[SUBHEADER ACTIONS] SET_ON_GET_FORMATB",
  SetOnGetFormatC: "[SUBHEADER ACTIONS] SET_ON_GET_FORMATC",
  SetOnGetFormatD: "[SUBHEADER ACTIONS] SET_ON_GET_FORMATD",
  SetOnSearch: "[SUBHEADER ACTIONS] SET_ON_SEARCH",
  SetOnNew: "[SUBHEADER ACTIONS] SET_ON_NEW",
  SetOnSave: "[SUBHEADER ACTIONS] SET_ON_SAVE",
  SetOnEdit: "[SUBHEADER ACTIONS] SET_ON_EDIT",
  SetOnCancel: "[SUBHEADER ACTIONS] SET_ON_CANCEL",
  ClearActions: "[SUBHEADER ACTIONS] CLEAR_ACTIONS",
  ClearEditingForms: "[SUBHEADER ACTIONS] CLEAR_EDITING_FORMS",
  SetEditingForm: "[SUBHEADER ACTIONS] SET_EDITING_FORM",
  SetIsEditDisabled: "[SUBHEADER ACTIONS] SET_IS_EDIT_DISABLED",
  SetIsFinalizeDisabled: "[SUBHEADER ACTIONS] SET_ON_FINALIZE_CLICK",

  SetCurrentMode: "[SUBHEADER ACTIONS] SET_CURRENT_MODE",
  SetOnSearch2: "[SUBHEADER ACTIONS] SET_ON_SEARCH2",
  SetOnNew2: "[SUBHEADER ACTIONS] SET_ON_NEW2",
  SetOnSave2: "[SUBHEADER ACTIONS] SET_ON_SAVE2",
  SetOnEdit2: "[SUBHEADER ACTIONS] SET_ON_EDIT2",
  SetOnCancel2: "[SUBHEADER ACTIONS] SET_ON_CANCEL2",
  SetOnSearch3: "[SUBHEADER ACTIONS] SET_ON_SEARCH3",
  SetOnNew3: "[SUBHEADER ACTIONS] SET_ON_NEW3",
  SetOnSave3: "[SUBHEADER ACTIONS] SET_ON_SAVE3",
  SetOnEdit3: "[SUBHEADER ACTIONS] SET_ON_EDIT3",
  SetOnCancel3: "[SUBHEADER ACTIONS] SET_ON_CANCEL3",
  SetOnFinalize: "[SUBHEADER ACTIONS] SET_ON_FINALIZE",
  SetUnSuscribe: "[SUBHEADER ACTIONS] SET_UN_SUSCRIBE",

  SetLabel: "[SUBHEADER ACTIONS] SET_LABEL",
  SetFormats: "[SUBHEADER ACTIONS] SET_FORMATS",
};

const notEditingForms = {
  isEditingSearchForm: false,
  isEditingEditForm: false,
  isEditingNewForm: false,
};

export const initialState = {
  disabledSave: false,
  onReportClick: null,
  onFormatGuardClick: null,
  onLabelClick: null,
  onLockClick: null,
  unSuscribe: null,

  onGetFormatAClick: null,
  onGetFormatBClick: null,
  onGetFormatCClick: null,
  onGetFormatDClick: null,
  onSearchClick: null,
  onNewClick: null,
  onSaveClick: null,
  onEditClick: null,
  onCancelClick: null,
  isEditDisabled: true,
  isFinalizeDisabled: false,
  ...notEditingForms,

  currentMode: null,

  onSearchClick2: null,
  onNewClick2: null,
  onSaveClick2: null,
  onEditClick2: null,
  onCancelClick2: null,
  onSearchClick3: null,
  onNewClick3: null,
  onSaveClick3: null,
  onEditClick3: null,
  onFinalizeClick: null,
  onCancelClick3: null,

  label: "",
  enableFormats: {
    A: true,
    B: true,
    C: true,
    D: true,
  },
};

export const reducer = persistReducer(
  { storage, key: "subheader", whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetOnReportClickFunction:
        return { ...state, onReportClick: action.payload };

      case actionType.SetOnFormatGuardClick:
        return { ...state, onFormatGuardClick: action.payload };

      case actionType.SetOnLabelClick:
        return { ...state, onLabelClick: action.payload };

      case actionType.SetOnLockClick:
        return { ...state, onLockClick: action.payload };

      case actionType.SetOnGetFormatA:
        return { ...state, onGetFormatAClick: action.payload };

      case actionType.SetOnGetFormatB:
        return { ...state, onGetFormatBClick: action.payload };

      case actionType.SetOnGetFormatC:
        return { ...state, onGetFormatCClick: action.payload };

      case actionType.SetOnGetFormatD:
        return { ...state, onGetFormatDClick: action.payload };

      case actionType.SetOnSearch:
        return { ...state, onSearchClick: action.payload };

      case actionType.setDisabledSaveTrue:
        return { ...state, disabledSave: true };

      case actionType.setDisabledSaveFalse:
        return { ...state, disabledSave: false };

      case actionType.SetOnNew:
        return { ...state, onNewClick: action.payload };

      case actionType.SetOnSave:
        return { ...state, onSaveClick: action.payload };

      case actionType.SetOnEdit:
        return { ...state, onEditClick: action.payload };

      case actionType.SetOnCancel:
        return { ...state, onCancelClick: action.payload };

      case actionType.ClearActions:
        return initialState;

      case actionType.ClearEditingForms:
        return { ...state, ...notEditingForms };

      case actionType.SetEditingForm:
        return {
          ...state,
          ...notEditingForms,
          // If wants none true then send 'none'
          isEditingSearchForm: action.payload === "search" ? true : false,
          isEditingEditForm: action.payload === "edit" ? true : false,
          isEditingNewForm: action.payload === "new" ? true : false,
        };

      case actionType.SetIsEditDisabled:
        return { ...state, isEditDisabled: action.payload };

      case actionType.SetIsFinalizeDisabled:
        return { ...state, isFinalizeDisabled: action.payload };

      case actionType.SetCurrentMode:
        return { ...state, currentMode: action.payload };

      case actionType.SetOnSearch2:
        return { ...state, onSearchClick2: action.payload };

      case actionType.SetOnNew2:
        return { ...state, onNewClick2: action.payload };

      case actionType.SetOnSave2:
        return { ...state, onSaveClick2: action.payload };

      case actionType.SetOnEdit2:
        return { ...state, onEditClick2: action.payload };

      case actionType.SetOnCancel2:
        return { ...state, onCancelClick2: action.payload, currentMode: null };

      case actionType.SetOnSearch3:
        return { ...state, onSearchClick3: action.payload };

      case actionType.SetOnNew3:
        return { ...state, onNewClick3: action.payload };

      case actionType.SetOnSave3:
        return { ...state, onSaveClick3: action.payload };

      case actionType.SetOnEdit3:
        return { ...state, onEditClick3: action.payload };

      case actionType.SetOnCancel3:
        return { ...state, onCancelClick3: action.payload, currentMode: null };

      case actionType.SetOnFinalize:
        return { ...state, onFinalizeClick: action.payload, currentMode: null };
      case actionType.SetUnSuscribe:
        return { ...state, unSuscribe: action.payload, currentMode: null };

      case actionType.SetLabel:
        return { ...state, label: action.payload };

      case actionType.SetFormats:
        return { ...state, enableFormats: action.payload };

      default:
        return state;
    }
  }
);
export const setDisabledSaveFalse = () => ({
  type: actionType.setDisabledSaveFalse,
});
export const setDisabledSaveTrue = () => ({
  type: actionType.setDisabledSaveTrue,
});
export const setOnReportClickFunction = (id) => ({
  type: actionType.SetOnReportClickFunction,
  payload: id,
});
export const setOnFormatGuardClick = (id) => ({
  type: actionType.SetOnFormatGuardClick,
  payload: id,
});
export const setOnLabelClick = (id) => ({
  type: actionType.SetOnLabelClick,
  payload: id,
});
export const setOnLockClick = (id) => ({
  type: actionType.SetOnLockClick,
  payload: id,
});

export const setOnGetFormatA = (format) => ({
  type: actionType.SetOnGetFormatA,
  payload: format,
});
export const setOnGetFormatB = (format) => ({
  type: actionType.SetOnGetFormatB,
  payload: format,
});
export const setOnGetFormatC = (format) => ({
  type: actionType.SetOnGetFormatC,
  payload: format,
});
export const setOnGetFormatD = (format) => ({
  type: actionType.SetOnGetFormatD,
  payload: format,
});

export const clearActions = () => ({ type: actionType.ClearActions });

export const clearEditingForms = () => ({ type: actionType.ClearEditingForms });
export const setEditingForm = (form) => ({
  type: actionType.SetEditingForm,
  payload: form,
});

export const setIsEditDisabled = (isEditDisabled = false) => ({
  type: actionType.SetIsEditDisabled,
  payload: isEditDisabled,
});

export const setIsFinalizeDisabled = (isFinalizeDisabled = false) => ({
  type: actionType.SetIsFinalizeDisabled,
  payload: isFinalizeDisabled,
});

export const setCurrentMode = (mode) => ({
  type: actionType.SetCurrentMode,
  payload: mode,
});
export const setOnSearch2 = (id) => ({
  type: actionType.SetOnSearch2,
  payload: id,
});
export const setOnNew2 = (id) => ({ type: actionType.SetOnNew2, payload: id });
export const setOnSave2 = (id) => ({
  type: actionType.SetOnSave2,
  payload: id,
});
export const setOnEdit2 = (id) => ({
  type: actionType.SetOnEdit2,
  payload: id,
});
export const setOnCancel2 = (id) => ({
  type: actionType.SetOnCancel2,
  payload: id,
});
export const setOnSearch3 = (id) => ({
  type: actionType.SetOnSearch3,
  payload: id,
});
export const setOnNew3 = (id) => ({ type: actionType.SetOnNew3, payload: id });
export const setOnSave3 = (id) => ({
  type: actionType.SetOnSave3,
  payload: id,
});
export const setOnEdit3 = (id) => ({
  type: actionType.SetOnEdit3,
  payload: id,
});
export const setOnCancel3 = (id) => ({
  type: actionType.SetOnCancel3,
  payload: id,
});

export const setOnFinalize = (id) => ({
  type: actionType.SetOnFinalize,
  payload: id,
});
export const setUnSuscribe = (id) => ({
  type: actionType.SetUnSuscribe,
  payload: id,
});

export const setLabel = (label) => ({
  type: actionType.SetLabel,
  payload: label,
});
export const setFormats = (label) => ({
  type: actionType.SetFormats,
  payload: label,
});
