import React, { useState, useEffect } from 'react';
import { AppBar, makeStyles, Toolbar, Typography } from '@material-ui/core';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import { formatDate } from '../../../utils/dates'

const useStyles = makeStyles((theme) => ({
  logo: {
    marginRight: theme.spacing(2),
  },
}));

export default function MonitoringAppBar() {
  const classes = useStyles();

  const [time, setTime] = useState(new Date());

  function refreshTimer() {
    setTime(new Date());
  }
  useEffect(() => {
    const timer = setInterval(refreshTimer, 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <AppBar color="transparent" position="static">
        <Toolbar variant="dense">
          <img className={classes.logo} src={toAbsoluteUrl("/media/logos/logo-senado.png")} alt="Senado logo" width={50} />
          <Typography variant="h6" color="inherit">
              {formatDate("PPP, p a", time)}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}