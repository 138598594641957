import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { setClearState, setVariant } from "../../../../redux/ducks/variants.duck";
import { getSingleVariant } from "../../../crud/variantsCrud";
import notice from "../../../components/Notice";
import PageLayout from "../../../components/PageLayout";
import TabsButton from "../../../components/TabsButton";
import UtilInputs from "../../../components/UtilInputs";
import { DocumentTypeForm } from "../../../utils/configData";
import useSubheaderAction from "../../../hooks/useSubheaderAction";
import { setCurrentMode } from "../../../../redux/ducks/subHeaderActions.duck";
import { modeOptions } from "../../../utils/subheaderIconsData";
import { createTaskDocument, deleteTaskDocument } from "../../../crud/taskManagementCrud";
import { setLoader } from "../../../../redux/ducks/loader.duck";

const ConfigVariantDetail = () => {
  const { variant, selectedVariants } = useSelector((state) => state.variants);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { pathname } = useLocation();
  const { onSaveClick2, unSuscribe, currentMode } = useSelector(
    (store) => store.subHeaderActions
  );
  useEffect(() => {
    if (pathname.includes("nuevo")) {
      dispatch(setCurrentMode(modeOptions.new));
    } else if (pathname.includes("resultados")) {
      dispatch(setCurrentMode(modeOptions.result));
    }
  }, [pathname]);

  const createDocument = () => {
    dispatch(setLoader(true));
    const data = {
      name: variant.name,
      state: variant.state,
    };
    createTaskDocument(data)
      .then((res) => {
        notice("Documento creado correctamente.", "success");
        history.push("/bienes-instrumentales/configuracion/catalogos/tipo-de-documento");
      })
      .catch((error) => {
        notice("No se pudo crear", "error");
      })
      .finally(() => dispatch(setLoader(false)));
  };

  const onDelete = () => {
    deleteTaskDocument(params.id)
      .then((res) => {
        notice("Documento eliminado correctamente.", "success");
        history.push("/bienes-instrumentales/configuracion/catalogos/tipo-de-documento");
      })
      .catch((error) => {
        notice("No se pudo crear", "error");
      })
      .finally(() => dispatch(setLoader(false)));
  };

  //subheader actions
  useSubheaderAction({
    action: onSaveClick2,
    func: createDocument,
    isInPage: true,
  });
  useSubheaderAction({
    action: unSuscribe,
    func: onDelete,
    isInPage: true,
  });

  const onValuesChange = (e) => {
    if (e && e.target) {
      const { value, name } = e.target;
      dispatch(setVariant({ ...variant, [name]: value }));
    }
  };

  const handleClickTab = (id) => {
    getSingleVariant(id)
      .then((res) => {
        dispatch(setVariant(res));
        history.push(
          `/bienes-instrumentales/configuracion/catalogos/tipo-de-documento/resultados/${id}`
        );
      })
      .catch((e) => {
        dispatch(setClearState());
        notice("Error al mostrar tipo de Documento");
        history.push(
          "/bienes-instrumentales/configuracion/catalogos/tipo-de-documento/nuevo"
        );
      });
  };

  return (
    <PageLayout
      minHeight="100%"
      title={variant.id ? "Tipo de Documento" : "Nuevo Tipo de Documento"}
      header={
        selectedVariants.length > 1 && (
          <TabsButton
            list={selectedVariants}
            selected={variant.id}
            handleClickTab={handleClickTab}
          />
        )
      }
    >
      <UtilInputs
        inputValues={DocumentTypeForm(currentMode)}
        formValues={variant}
        onValuesChange={onValuesChange}
      />
    </PageLayout>
  );
};

export default ConfigVariantDetail;
