import {
  PERMISSIONS_URL,
  USERS_URL,
  BASE_URL,
  OPERATIVE_USERS_URL,
  CATALOGS_URL,
} from "./helpers/routes";
import {
  getMultipartParams,
  getParams,
  handleError,
  handleResponse,
} from "./helpers/validate";
import { filterParams } from "../utils/objectsFunctions";

export const getUsers = (page, sizePerPage, params = "") => {
  return fetch(
    `${USERS_URL}?page=${page}&limit=${sizePerPage}&${params}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getUserById = (id) => {
  return fetch(`${USERS_URL}/${id}`, getParams("GET"))
    .then(handleResponse)
    .then((res) => ({
      ...res,
      password: "",
      password_confirm: "",
      groups: res.groups.map(({ id }) => id),
      titular: res.alm_area?.titular,
      alm_area: {
        ...res.alm_area,
        text: res.alm_area?.alm_area,
      },
    }))
    .catch(handleError);
};

export const createUser = (userData) => {
  const formData = new FormData();
  formData.append("username", userData["username"]);
  formData.append("first_name", userData["first_name"]);
  formData.append("last_name", userData["last_name"]);
  formData.append("titular", userData["titular"]);
  formData.append("alm_area", userData["alm_area"].text);
  formData.append("password", userData["password"]);
  formData.append("is_active", userData["is_active"]);
  userData["groups"].forEach((item) => formData.append("groups", item));
  // formData.append('image', userData['image']);
  // userData['user_permissions'].forEach((item) => formData.append("user_permissions", item));
  return fetch(USERS_URL + "/", getMultipartParams("POST", formData))
    .then(handleResponse)
    .catch(handleError);
};

export const NewPassword = (userData) => {
  const formData = new FormData();
  formData.append("old_password", userData["old_password"]);
  formData.append("new_password2", userData["new_password2"]);
  formData.append("new_password1", userData["new_password1"]);
  // formData.append('image', userData['image']);

  return fetch(
    `${BASE_URL}/api/users/me/${userData.id}/`,
    getMultipartParams("PATCH", formData)
  )
    .then(handleResponse)
    .catch(handleError);
};
export const editUser = (userData) => {
  const formData = new FormData();
  formData.append("username", userData["username"]);
  formData.append("first_name", userData["first_name"]);
  formData.append("last_name", userData["last_name"]);
  formData.append("titular", userData["titular"]);
  formData.append("alm_area", userData["alm_area"]);
  userData["password"] && formData.append("password", userData["password"]);
  formData.append("is_active", userData["is_active"]);
  userData["groups"].forEach((item) => formData.append("groups", item));
  // formData.append('image', userData['image']);
  return fetch(`${USERS_URL}/${userData.id}/`, getMultipartParams("PATCH", formData))
    .then(handleResponse)
    .catch(handleError);
};

export const deleteUser = (id) => {
  return fetch(`${USERS_URL}/${id}`, getParams("DELETE"))
    .then(handleResponse)
    .catch(handleError);
};

export const getPermissionsSchema = () => {
  return fetch(`${PERMISSIONS_URL}-schema`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const getOperativeUsers = (inChargeArea = "") => {
  const params =
    !inChargeArea || inChargeArea === "Sin definir"
      ? {
          limit: 500,
        }
      : { limit: 500, mainModule: inChargeArea };

  return fetch(`${OPERATIVE_USERS_URL}?${filterParams(params)}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const getAdscriptions = (query) => {
  return fetch(
    `${CATALOGS_URL}/almacen-adscripciones?cf_cvearea=${query?.trim()}&page=1&limit=15`,
    getParams("GET")
  )
    .then(handleResponse)
    .then((res) =>
      res.results.map((item) => ({
        ...item,
        text: item?.cf_cvearea,
      }))
    )
    .catch(handleError);
};

export const getActiveAdscriptions = (query) => {
  return fetch(
    `${CATALOGS_URL}/almacen-adscripcion-activa?cf_cvearea=${query?.trim()}&page=1&limit=15`,
    getParams("GET")
  )
    .then(handleResponse)
    .then((res) =>
      res.results.map((item) => ({
        ...item,
        text: item?.cf_cvearea,
      }))
    )
    .catch(handleError);
};
