import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  setCurrentRequestTab,
  requestSelector,
  setDrafts,
  setSelectedDraft,
  setSelectedDrafts,
  setSelectedItems
} from "../../../redux/ducks/request.duck";
import BaseModal from '../../components/BaseModal';
import FullTable from '../../components/FullTable';
import { request_drafts_columns } from '../../utils/tableData';

const RequestDraftPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { drafts } = useSelector(requestSelector);
  const [selected, setSelected] = useState([])
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteDraft, setDeleteDraft] = useState(null);

  useEffect(() => {
    dispatch(setCurrentRequestTab({ isSearchTab: false, isResultsTab: false, isNewTab: false, isDraftTab: false }));
  }, [dispatch]);

  const handleAdd = () => history.push("/solicitudes/nueva-solicitud");

  const handleSelectAllRows = (isSelect, rows) => isSelect ? setSelected(rows) : setSelected([])

  const handleSelectRow = (row, isSelect) => {
		if(row && row.id){
			if(!isSelect){
				const newSelectedEntries = selected.filter(sel => sel.id !== row.id);
				setSelected(newSelectedEntries);
			}else{
				setSelected([...selected, row]);
			};
		};
	};

  const handleReqSelected = () => {
    dispatch(setSelectedDrafts(selected))
    history.push(`/solicitudes/resultados/borrador-${selected[0].id}`)
  }

  const onDetail = (id) => {
    const draft = drafts.find(x => x.id === id)
    dispatch(setSelectedDraft(draft))
    dispatch(setSelectedDrafts([draft]))
    dispatch(setSelectedItems(draft?.warehouse_request_details.map(x => ({...x, cve_articulo: x.cf_cve_articulo, actions: ['edit', 'delete']}))))
    history.push(`/solicitudes/resultados/borrador-${draft.id}`)
  }

  const onTrashDraft = (id) => {
    setDeleteDraft(id)
    setDeleteModal(true)
  }

  const handleDeleteItem = () => {
    const newDrafts = drafts.filter(x => x.id !== deleteDraft)
    dispatch(setDrafts(newDrafts))
    setDeleteModal(false)
  }

  return (
    <>
      <FullTable
        title='Borradores'
        buttonTitle='Solicitud'
        onClickButton={handleAdd}
        entities={drafts}
        columns={request_drafts_columns(onDetail, onTrashDraft)}
        page={1}
        sizePerPage={100}
        count={drafts.length}
        search_button={false}
        hasReportIcons={false}
        // SELECTED REQUESTS ACTIONS
        // NONE SELECTABLE
        nonSelectableRows={drafts.map(x => x.id)}
        has_button2={false}
        buttonDisabled2={selected.length < 1}
        buttonTitle2={`Consultar Todos${selected.length > 1 ? `(${selected.length.toString()})` : ''}`}
        onSelectRow={handleSelectRow}
			  onSelectAllRows={handleSelectAllRows}
			  onClickButton2={handleReqSelected}
      />
      <BaseModal
        open={deleteModal}
        title='¿Borrar Borrador?'
        onClose={() => setDeleteModal(false)}
        onCancel={() => setDeleteModal(false)}
        onConfirm={handleDeleteItem}
        confirmLabel='Si, eliminar la solicitud'
        cancelLabel='No'
        maxWidth='sm'
        color='secondary'
      ><p>¿Estás seguro de que deseas borrar el Borrador</p></BaseModal>
    </>
  )
}

export default RequestDraftPage
