import React from 'react';
import { makeStyles, Typography, lighten, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root: {
			backgroundColor: ({ color, lighten: lightenCoef }) => lighten(color, lightenCoef),
			padding: "10px 15px",
			borderRadius: 3,
			display: 'inline-block',
			minWidth: ({ minWidth }) => minWidth && minWidth,
			cursor: ({ cursor }) => cursor
    },
    label: {
      color: props => props.color,
      fontWeight: props => props.bold ? 'bold' : 'normal',
			fontSize: props => props.fontSize,
			textAlign: 'center'
    }
});

const Chip = ({ color="#ffffff", label="", bold=false, lighten=0.8, fontSize="1rem", onClick=()=>{}, minWidth=false, cursor="auto", style={} }) => {
	// lighten prop defines how background is lighter than label
    const classes = useStyles({ color, bold, lighten, fontSize, minWidth, cursor });
    return (
			<Box className={classes.root} display="flex" alignItems='center' justifyContent='center' onClick={onClick} data-testid="chip-button" style={style}>
				<Typography className={classes.label}>{label}</Typography>
			</Box>
		);
};

Chip.propTypes = {
	color: PropTypes.string,
	label: PropTypes.node,
	bold: PropTypes.bool,
	lighten: (props, propName, componentName) => {
		if (
			props[propName] &&
			(props[propName] < 0 ||
				props[propName] > 1 ||
				typeof props[propName] !== 'number')
		) {
			return new Error(
				'Invalid prop `' +
					propName +
					'` supplied to' +
					' `' +
					componentName +
					'`. Must be a number from 0 to 1.'
			);
		}
	},
	fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Chip;