/* eslint-disable jsx-a11y/anchor-is-valid */
import { makeStyles, Typography, Box, Badge, Modal } from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import uuid from "react-uuid";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {
  removeInventoryPDF,
  updateInventoryPDF,
  updateMultipleInventoryPDF,
} from "../crud/stockCrud";
import BaseModal from "./BaseModal";
import CustomButton from "./CustomButton";
import FileButton from "./FileButton";
import notice from "./Notice";
import SimpleInput from "./SimpleInput";
import { downLoaderWithURL } from "../utils/downloaderWithURL";
import SimpleDropdown from "./SimpleDropdown";
import { formatDateDDMMYYYY } from "../utils/dates";
import { Visibility } from "@material-ui/icons";

const useStyles = makeStyles({
  mr: {
    marginRight: 10,
  },
  flex: {
    display: "flex",
  },
  justifyEnd: {
    justifyContent: "flex-end",
  },
  filesDiv: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 3,
  },
  fileTitle: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 10,
  },
});

const PDFUploaderTab = ({
  attach_model,
  field,
  onSuccessUpload = () => {},
  onFailedUpload = () => {},
  attachment_pdfs = [],
  id,
  idList,
  onSucessRemove = () => {},
  onFailedRemove = () => {},
  pdfTitleName = "inventario",
  hideTitle = false,
  enableSelectType = false,
  limitFiles = false,
  disabled = false,
}) => {
  const initialDeleteModalValues = {
    status: false,
    idPDF: null,
    deletingLoading: false,
  };
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [url, setURL] = useState([]);

  const [openPDF, setOpenPDF] = useState(false);

  // If more states are needed then should be good to refactor into one object instead multiple state hooks
  const [fileNames, setFileNames] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(initialDeleteModalValues);

  //TODO: This was a change for task NWMS-492, before changes this component only uploaded files on edit a resource, because is necessary  provides resources id.
  // useEffect(() => {
  // 	if (id === undefined){
  //     onSuccessUpload(files)
  // 	}
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [files, id]);
  const handleFiles = (inputFiles) => {
    if (
      limitFiles &&
      !isNaN(Number(limitFiles)) &&
      inputFiles.length + files.length + attachment_pdfs.length > limitFiles
    ) {
      const leftPDFs = limitFiles - files.length - attachment_pdfs.length;
      notice(
        `No se han podido elegir los archivos PDF's. Recuerda que puedes subir máximo ${limitFiles} archivos en total, ${leftPDFs} restantes.`,
        "error"
      );
      return;
    }
    setFiles([...files, ...inputFiles]);
    let newFileNames = [];
    let newFileTypes = [];
    for (let i = 0; i < inputFiles.length; i++) {
      newFileNames = [...newFileNames, inputFiles[i].name];
      newFileTypes = [...newFileTypes, "factura"];
    }
    setFileTypes([...fileTypes, ...newFileTypes]);
    setFileNames([...fileNames, ...newFileNames]);
  };

  const handleUploadPDFs = async (name) => {
    setLoading(true);
    let promisesPDF = [];
    files.forEach((file, index) => {
      if (name === "aceptar") {
        const uploadPDFPromise = updateInventoryPDF(
          file,
          id,
          attach_model,
          `${fileNames[index].replace(/\.[^/.]+$/, "")}`,
          enableSelectType ? fileTypes[index] : field
        );
        promisesPDF = [...promisesPDF, uploadPDFPromise];
      } else {
        const listIds = idList.map((id) => id.id).toString();

        const uploadPDFPromise = updateMultipleInventoryPDF(
          file,
          listIds,
          attach_model,
          `${fileNames[index].replace(/\.[^/.]+$/, "")}`,
          enableSelectType ? fileTypes[index] : field
        );
        promisesPDF = [...promisesPDF, uploadPDFPromise];
      }
    });
    Promise.all(promisesPDF)
      .then((responses) => {
        let failedResponses = responses.map((res, index) =>
          ![200, 201].includes(res.status) ? index + 1 : null
        );
        failedResponses = failedResponses.filter((response) => response !== null);
        failedResponses = failedResponses.join(", ");

        if (failedResponses.length > 0) {
          notice(
            `Ha ocurrido un error con la subida de los archivos ${failedResponses} al servidor`,
            "error"
          );
        } else {
          notice("¡Los archivos se han subido exitosamente!", "success");
        }
        setFiles([]);
        setFileNames([]);
        setFileTypes([]);
        onSuccessUpload(responses);
      })
      .catch((error) => {
        console.log(error);
        notice("Ha ocurrido un error al subir los archivos", "error");
        onFailedUpload();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveFile = (index) => {
    const newFiles = files.filter((file, fileIndex) => fileIndex !== index);
    setFiles(newFiles);
    const newFileNames = fileNames.filter((fileName, fileIndex) => fileIndex !== index);
    setFileNames(newFileNames);
    const newFileTypes = fileTypes.filter((fileType, fileIndex) => fileIndex !== index);
    setFileTypes(newFileTypes);
  };

  const handleRemoveFiles = () => {
    setFiles([]);
    setFileNames([]);
    setFileTypes([]);
  };

  const handleDeleteModal = (id) => {
    setOpenDeleteModal({ status: true, idPDF: id });
  };

  const handleDeleteUploadedPDF = async (id) => {
    setOpenDeleteModal({ ...openDeleteModal, deletingLoading: true });
    try {
      await removeInventoryPDF(id);
      onSucessRemove(id);
      setOpenDeleteModal(initialDeleteModalValues);
      notice("¡Se ha eliminado el PDF exitosamente!", "success");
    } catch (error) {
      setOpenDeleteModal(initialDeleteModalValues);
      notice("No se ha podido eliminar el archivo pdf", "error");
      onFailedRemove();
    }
  };

  const handleFileName = (e, index) => {
    const value = e.target.value.replace(".pdf", "");
    // Changes name of id file
    const newFileNames = fileNames.map((fileName, fileNameIndex) =>
      fileNameIndex === index ? value : fileName
    );
    setFileNames(newFileNames);
  };

  const handleFileType = (e, index) => {
    if (e && e.target.value) {
      const { value } = e.target;
      const newFileTypes = fileTypes.map((fileType, fileTypeIndex) =>
        fileTypeIndex === index ? value : fileType
      );
      setFileTypes(newFileTypes);
    }
  };

  const download = (url, title) => {
    const filename = `${pdfTitleName}-${title
      .replaceAll(" ", "_")
      .replaceAll(".pdf", "")}-${id}-${formatDateDDMMYYYY().replaceAll("/", "")}.pdf`;
    downLoaderWithURL(url, filename);
  };
  const handleDisplay = (url, title) => {
    setOpenPDF(true);
    setURL(url);
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        mb={2}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography className={classes.mr} variant="subtitle1">
            <PictureAsPdfIcon /> Documentos{" "}
          </Typography>
          {!hideTitle && (
            <Typography variant="subtitle2">
              <span className="badge badge-secondary font-weight-bold text-capitalize">
                {pdfTitleName} {id}
              </span>
            </Typography>
          )}
        </Box>
        <FileButton
          accept="application/pdf"
          buttonTitle="Seleccionar archivos"
          value={null}
          disabled={
            (limitFiles && attachment_pdfs.length + files.length >= limitFiles) ||
            disabled
          }
          id={uuid()}
          handleChange={handleFiles}
          multiple={true}
        />
      </Box>
      <hr />

      {fileNames.length > 0 &&
        fileTypes.length === files.length &&
        files.map((file, index) => (
          <div className={classes.filesDiv} key={index}>
            <div className="d-flex align-items-center">
              <SimpleInput
                value={fileNames[index].replace(".pdf", "")}
                onChange={(e) => handleFileName(e, index)}
                width={500}
                isPdf={true}
                inputProps={{ maxLength: 56 }}
              />
            </div>

            <div className="d-flex justify-content-around align-items-center">
              {enableSelectType && (
                <SimpleDropdown
                  label="Tipo de archivo"
                  placeholder="Tipo de archivo"
                  options={[
                    { value: "factura", text: "Factura" },
                    { value: "remision", text: "Remisión" },
                    { value: "pedido", text: "Pedido" },
                    { value: "contrato", text: "Contrato" },
                    { value: "acta", text: "Acta" },
                    { value: "resguardo", text: "Resguardo" },
                    { value: "otros", text: "Otros" },
                  ]}
                  value={fileTypes[index]}
                  onChange={(e) => handleFileType(e, index)}
                />
              )}
              <OverlayTrigger overlay={<Tooltip>Eliminar</Tooltip>}>
                <button
                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                  onClick={() => handleRemoveFile(index)}
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                  </span>
                </button>
              </OverlayTrigger>
            </div>
          </div>
        ))}
      <div style={{ minHeight: 20 }}></div>
      {id !== undefined && (
        <React.Fragment>
          <div className={`${classes.justifyEnd} ${classes.flex}`}>
            <CustomButton
              color="warning"
              title={`Cancelar`}
              disabled={files.length === 0 || loading}
              onClick={handleRemoveFiles}
              loading={loading}
            />
            <CustomButton
              color="success"
              title={`Aceptar`}
              disabled={files.length === 0 || loading}
              onClick={() => handleUploadPDFs("aceptar")}
              loading={loading}
            />
            {attach_model !== "contract" && attach_model !== "requisition" && (
              <CustomButton
                color="success"
                title={`Copiar`}
                disabled={files.length === 0 || loading}
                onClick={() => handleUploadPDFs("copiar")}
                loading={loading}
              />
            )}
          </div>
          {loading && (
            <div className="alert alert-warning my-5" rolse="alert">
              Subiendo archivos... Esto puede demorar algunos minutos
            </div>
          )}
          <span data-testid="attachments_pdfs_span">
            {attachment_pdfs.length === 0 ? (
              <div className="alert alert-light my-5" rolse="alert">
                Sin archivos adjuntos
              </div>
            ) : (
              <>
                <Badge badgeContent={attachment_pdfs.length} max={100} color="primary">
                  <Typography variant="subtitle1">Adjuntos</Typography>
                </Badge>
                {attachment_pdfs.map(({ title, url, id, field, created_at }) => (
                  <div key={id} className={classes.filesDiv}>
                    <span className="d-flex">
                      <p>{title}</p>
                      <Typography variant="caption" className="ml-5 text-muted">
                        {created_at}
                      </Typography>
                    </span>
                    <div>
                      <OverlayTrigger overlay={<Tooltip>Ver PDF</Tooltip>}>
                        <a
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                          onClick={() => handleDisplay(url, id, field)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <Visibility fontSize="small" />
                          </span>
                        </a>
                      </OverlayTrigger>

                      <OverlayTrigger overlay={<Tooltip>Eliminar</Tooltip>}>
                        <a
                          className={`btn btn-icon btn-light btn-hover-danger btn-sm ${
                            disabled ? "disabled" : ""
                          }`}
                          onClick={() => (disabled ? null : handleDeleteModal(id))}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                            />
                          </span>
                        </a>
                      </OverlayTrigger>
                    </div>
                  </div>
                ))}
              </>
            )}
          </span>
          <BaseModal open={openPDF} onClose={setOpenPDF}>
            <object
              data={url}
              type="application/pdf"
              width="100%"
              style={{ height: "600px" }}
            />
          </BaseModal>

          <BaseModal
            open={openDeleteModal.status}
            title="¿Estás seguro de que deseas eliminar este archivo PDF?"
            onClose={() => setOpenDeleteModal(initialDeleteModalValues)}
            onConfirm={() => handleDeleteUploadedPDF(openDeleteModal.idPDF)}
            confirmLabel="Si, eliminar"
            onCancel={() => setOpenDeleteModal(initialDeleteModalValues)}
            cancelLabel="Cancelar"
          />
        </React.Fragment>
      )}
      <hr />
    </div>
  );
};

export default PDFUploaderTab;
