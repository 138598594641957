import React, { Fragment, useState, useEffect } from "react";
import {
  makeStyles,
  Paper,
  TextField,
  InputAdornment,
  lighten,
} from "@material-ui/core";
import { formatMoney } from "../../utils/expressions";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
    "&&:hover": {
      borderBottom: "none",
    },
  },
});

const TableHeaders = ({ columns }) => {
  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <th
            scope="col"
            style={{
              border: "1px solid #D3D2D3",
              background: "#FFFFFF",
            }}
          >
            {column.text}
          </th>
        ))}
      </tr>
    </thead>
  );
};

const TableBody = ({
  data,
  columns,
  onChangeValue,
  numberTable,
  focus = false,
}) => {
  const classes = useStyles();
  // useEffect(() => {
  //   console.log("data => ", data);
  //   console.log("columns => ", columns);
  // }, []);

  function ValueFormat(value, isPrice) {
    if (!isPrice) {
      return value;
    }
    if (!isNaN(value) && parseFloat(value) > -1) {
      return formatMoney(value);
    }

    return value;
  }
  const seleccionarTodo = (event) => {
    if(focus){

      event.target.select();
    }
  };
  return (
    <tbody>
      {data.map((element, index) => (
        <tr>
          {columns.map((columna) => (
            <td
              style={{
                border: "1px solid #D3D2D3",
                justifyContent: "center",
                alignContent: "center",
                whiteSpace: "normal",
              }}
            >
              {/* {columna?.isPrice ? `$ ` : ""} */}
              {/* Input habilitado */}
              {columna.type === "text" && !columna.disabled && (
                <TextField
                  fullWidth
                  id="fullWidth"
                  name={`${columna.value}__${index}`}
                  InputProps={{ classes }}
                  value={ValueFormat(
                    element[columna.value],
                    columna?.isPrice ? columna?.isPrice : false
                  )}
                  margin="dense"
                  size="small"
                  multiline
                  onFocus={seleccionarTodo}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const infoCell = e.target.name.split("__");
                    const Field = infoCell[0];
                    const numberRow = parseInt(infoCell[1]);

                    onChangeValue(Field, numberRow, newValue, numberTable);
                  }}
                  disabled={columna.disabled}
                />
              )}
              {/* Input deshabilitado */}
              {columna.type === "text" &&
                columna.disabled &&
                ValueFormat(
                  element[columna.value],
                  columna?.isPrice ? columna?.isPrice : false
                )}
              {/* Default */}
              {columna.type !== "text" &&
                ValueFormat(
                  element[columna.value],
                  columna?.isPrice ? columna?.isPrice : false
                )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export const NewRequirementTable = ({
  columns,
  items,
  onChangeValue,
  index,
  focus = false,
}) => {
  return (
    <table
      className="table table-bordered"
      style={{ borderSpacing: "0px", background: "#DEDEDE" }}
    >
      <TableHeaders columns={columns}></TableHeaders>
      <TableBody
        data={items}
        columns={columns}
        onChangeValue={onChangeValue}
        numberTable={index}
        focus={focus}
      ></TableBody>
    </table>
  );
};

export default NewRequirementTable;
