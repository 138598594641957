import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultDepartureForm, defaultFilter, defaultOdinaryDeparture, defaultRecurrentDeparture } from "../../app/utils/departureData";

const actionType = {
  SetDepartures: '[DEPARTURE] SET_DEPARTURES',
  SetDeparture: '[DEPARTURE] SET_DEPARTURE',
  SetRecurrent: '[DEPARTURE] SET_RECURRENT',
  SetOrdinary: '[DEPARTURE] SET_ORDINARY',
  SetSearchDeparture: '[DEPARTURE] SET_SEARCH_DEPARTURE',
  SetSelectedDepartures: '[DEPARTURE] SET_SELECTED_DEPARTURES',
  ClearState: '[DEPARTURE] CLEAR_STATE',
};

const initialState = {
  list: {
    results: [],
    count: 0
  },
  departure: defaultDepartureForm,
  selectedIds: [],
  searchDeparture: defaultFilter,
  recurrent: defaultRecurrentDeparture,
  ordinary: defaultOdinaryDeparture,
};

export const reducer = persistReducer(
  { storage, key: 'departure', whitelist: ["departure", "selectedIds"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetDepartures:
        return { ...state, list: action.payload }
      
      case actionType.SetDeparture:
        return { ...state, departure: action.payload }
      
      case actionType.SetRecurrent:
        return { ...state, recurrent: action.payload }
      
      case actionType.SetOrdinary:
        return { ...state, ordinary: action.payload }
      
      case actionType.SetSearchDeparture:
        return { ...state, searchDeparture: action.payload }
      
      case actionType.SetSelectedDepartures:
        return { ...state, selectedIds: action.payload }
      
      case actionType.ClearState:
        return state = initialState
      
      default:
        return state
    }
  }
)

export const setDepartures = data => ({ type: actionType.SetDepartures, payload: data })

export const setDeparture = data => ({ type: actionType.SetDeparture, payload: data })

export const setRecurrent = data => ({ type: actionType.SetRecurrent, payload: data })

export const setOrdinary = data => ({ type: actionType.SetOrdinary, payload: data })

export const setSearchDeparture = data => ({ type: actionType.SetSearchDeparture, payload: data })

export const setSelectedDepartures = data => ({ type: actionType.SetSelectedDepartures, payload: data })

export const setClearState = data => ({ type: actionType.ClearState, payload: data })