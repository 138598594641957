import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import FullTable from "../../components/FullTable";
import notice from "../../components/Notice";
import {
  defaultFilter,
  handleResponseContract,
  requirementsFilters,
  requirements_columns,
} from "../../utils/requirementsData";
import {
  requirementSelector,
  setPage,
  setSizePerPage,
  clearRequirement,
  setSelectedRequirementIds,
  setSelectedRequirement,
  setRequirements,
} from "../../../redux/ducks/requirement.duck";
import { useSearchMode } from "../../hooks/useSearchMode";
import {
  getRequirementFile,
  getSingleReqirement,
  listRequirements,
} from "../../crud/requirementCrud";
import { getSingleContract } from "../../crud/contractsCrud";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";
import { setLoader } from "../../../redux/ducks/loader.duck";

const RequirementsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    requirement: { results, page, per_page, count },
  } = useSelector(requirementSelector);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(defaultFilter);

  const handleFilterChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const onConfirmFilter = () => {
    setLoading(true);
  };

  const onClearFilter = () => {
    setFilter(defaultFilter);
    onConfirmFilter();
  };

  useSearchMode("/inventario-bienes-almacen-general/requerimiento/busqueda");

  useEffect(() => {
    if (loading)
      listRequirements(
        page,
        per_page,
        filterParams(
          clearEmptyStringsObject(
            cleanObject({
              ...filter,
              supplier_number: filter.supplier?.text2,
              contract: filter.contract?.id,
            })
          )
        )
      )
        .then((res) => {
          if (res.status === 200) dispatch(setRequirements({ ...res.data, page }));
        })
        .catch(console.log)
        .finally(() => setLoading(false));
  }, [per_page, page, dispatch, filter, loading]);

  const onDetail = (id) => {
    dispatch(clearRequirement());
    dispatch(setLoader(true));
    getSingleReqirement(id)
      .then((res) => {
        if (res.contrato)
          getSingleContract(res.contrato)
            .then(({ data, status }) => {
              if (status === 200) {
                let payload = handleResponseContract(data, res);
                dispatch(setSelectedRequirement(payload));
                history.push(
                  `/inventario-bienes-almacen-general/requerimientos/resultados/${id}`
                );
              }
              dispatch(setLoader(false));
            })
            .catch((e) => {
              console.log(e);
              dispatch(setLoader(false));
            });
        else {
          dispatch(setSelectedRequirement(res));
          history.push(
            `/inventario-bienes-almacen-general/requerimientos/resultados/${id}`
          );
        }
      })
      .catch((e) => notice("Error cargando el requerimiento"));
  };

  const handleAdd = () => {
    dispatch(clearRequirement());
    history.push("/inventario-bienes-almacen-general/requerimiento/nuevo");
  };

  const handleSelectAllRows = (isSelect, rows) =>
    isSelect ? setSelectedIds(rows.map((r) => r.id)) : setSelectedIds([]);

  const handleSelectRow = (row, isSelect) => {
    if (row && row.id) {
      if (!isSelect) {
        const newSelectedEntries = selectedIds.filter((id) => id !== row.id);
        setSelectedIds(newSelectedEntries);
      } else {
        setSelectedIds([...selectedIds, row.id]);
      }
    }
  };

  const handleReqSelected = () => {
    dispatch(setSelectedRequirementIds(selectedIds));
    history.push(
      `/inventario-bienes-almacen-general/requerimientos/resultados/${selectedIds[0]}`
    );
  };

  const handleonClickSearch = () => {
    dispatch(clearRequirement());
    history.push("/inventario-bienes-almacen-general/requerimiento/busqueda");
  };

  const onCancel = () => {};

  const onFile = (id) => {
    getRequirementFile(id)
      .then((res) => window.open(res.attachment_url, "_blank"))
      .catch(console.log);
  };

  const onEditDraft = () => {};

  const onTrashDraft = () => {};

  const sendMail = () => {};

  return (
    <>
      <FullTable
        loading={loading}
        title="Requerimientos"
        entities={results}
        columns={requirements_columns({
          sendMail,
          onDetail,
          onCancel,
          onFile,
          onEditDraft,
          onTrashDraft,
        })}
        setPage={(page) => page > 0 && dispatch(setPage(page))}
        setSizePerPage={(pageSize) => dispatch(setSizePerPage(pageSize))}
        sizePerPage={per_page}
        count={count}
        page={page}
        buttonTitle="Nuevo requerimiento"
        onClickButton={handleAdd}
        onClickSearch={handleonClickSearch}
        has_button2
        buttonDisabled2={selectedIds.length < 1}
        buttonTitle2={`Consultar Todos${
          selectedIds.length > 1 ? `(${selectedIds.length.toString()})` : ""
        }`}
        onSelectRow={handleSelectRow}
        onSelectAllRows={handleSelectAllRows}
        onClickButton2={handleReqSelected}
        hideCheckbox
        // FILTERS
        has_filter
        formFilters={filter}
        onFilterChange={handleFilterChange}
        filterInputs={requirementsFilters}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
      />
    </>
  );
};

export default RequirementsPage;
