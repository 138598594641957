import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setCurrentMode } from "../../../redux/ducks/subHeaderActions.duck";
import {
  setFilteredVehicule,
  setSelectedVehicule,
  setVehiculeDrops,
  setVehicules,
  setVehiculesIds,
} from "../../../redux/ducks/vehicules.duck";
import DropModal from "../../components/DropModal";
import FullTable from "../../components/FullTable";
import notice from "../../components/Notice";
import {
  createVehiculeDrop,
  getVehiculesSubBrands,
  listVehicleDrops,
  listVehicules,
} from "../../crud/vehiculesCrud";
import { defaultDrop } from "../../utils/catalogsData";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";
import { modeOptions } from "../../utils/subheaderIconsData";
import {
  columns,
  defaultFilter,
  defaultVehicule,
  filterInputs,
} from "../../utils/vehiculesData";

const VehiculesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { list, dropList } = useSelector((state) => state.vehicules);
  const { permisos } = useSelector((store) => store.auth);

  const [loading, setLoading] = useState(false);
  const [selectedVehicules, setSelectedVehicules] = useState([]);
  const [dropVH, setDropVH] = useState(defaultDrop);
  const isDropPage = pathname.includes("bajas");
  const [filter, setFilter] = useState(defaultFilter);
  const [subbrands, setSubbrands] = useState([]);

  const handleFilterChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const getFilterParams = useCallback(
    () =>
      filterParams(
        clearEmptyStringsObject(
          cleanObject({
            ...filter,
            cf_cve_area: filter.area?.text2,
            cf_marca: filter.cf_marca?.id,
          })
        )
      ),
    [filter]
  );

  const onConfirmFilter = () => {
    setLoading(true);
    listVehicules(list.page, list.per_page, getFilterParams())
      .then(({ results, count, per_page }) =>
        dispatch(setVehicules({ results, count, per_page, page: list.page }))
      )
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const onClearFilter = () => {
    setFilter(defaultFilter);
    setLoading(true);
    listVehicules(list.page, list.per_page)
      .then(({ results, count, per_page }) =>
        dispatch(setVehicules({ results, count, per_page, page: list.page }))
      )
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    if (isDropPage)
      listVehicleDrops(dropList.page, dropList.per_page)
        .then((res) => dispatch(setVehiculeDrops(res)))
        .catch(console.log)
        .finally(() => setLoading(false));
    else
      listVehicules(list.page, list.per_page, getFilterParams())
        .then(({ results, count, per_page }) =>
          dispatch(setVehicules({ results, count, per_page, page: list.page }))
        )
        .catch(console.log)
        .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dropList.page, dropList.per_page, isDropPage, list.page, list.per_page]);

  useEffect(() => {
    getVehiculesSubBrands(filter.cf_marca?.id)
      .then(setSubbrands)
      .catch(console.log);
  }, [filter.cf_marca?.id]);

  const handleonClickSearch = () => {
    dispatch(setCurrentMode(modeOptions.search));

    dispatch(setSelectedVehicule(defaultVehicule));
    dispatch(setFilteredVehicule(defaultVehicule));
    dispatch(setVehiculesIds([]));
    if (pathname.includes("bajas")) {
      history.push("/bienes-instrumentales/vehiculos/bajas/busqueda");
    } else {
      history.push("/bienes-instrumentales/vehiculos/busqueda");
    }
  };

  const handleNewVehicle = () => {
    dispatch(setSelectedVehicule(defaultVehicule));
    dispatch(setCurrentMode(modeOptions.new));
    history.push("/bienes-instrumentales/vehiculos/nuevo");
  };

  const onEye = (id) => {
    dispatch(setCurrentMode(null));
    history.push(
      `/bienes-instrumentales/vehiculos/${isDropPage ? "bajas/" : ""}resultados/${id}`
    );
  };

  const onDrop = (id) => {
    setDropVH({
      vehicle: id,
      motivo_baja: "",
      tipo_baja: "",
    });
  };

  const onConfirmDrop = () => {
    setLoading(true);
    createVehiculeDrop(dropVH)
      .then((res) => {
        notice("Vehículo fue dado de baja", "success");
        listVehicules(list.page, list.per_page, getFilterParams())
          .then(({ results, count, per_page }) => {
            dispatch(setVehicules({ results, count, per_page, page: list.page }));
            setLoading(false);
          })
          .catch(console.log)
          .finally(() => {
            setDropVH(defaultDrop);
          });
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  };

  const onDropValuesChange = ({ target }) => {
    const { name, value } = target;
    setDropVH({ ...dropVH, [name]: value });
  };

  const handleSelectAllRows = (isSelect, rows) =>
    isSelect ? setSelectedVehicules(rows.map((r) => r.id)) : setSelectedVehicules([]);

  const handleSelectRow = (row, isSelect) => {
    if (row && row.id) {
      if (!isSelect) {
        const newSelectedEntries = selectedVehicules.filter((id) => id !== row.id);
        setSelectedVehicules(newSelectedEntries);
      } else {
        setSelectedVehicules([...selectedVehicules, row.id]);
      }
    }
  };

  const handleReqSelected = () => {
    dispatch(setCurrentMode(modeOptions.search));

    dispatch(setVehiculesIds(selectedVehicules));
    history.push(`/bienes-instrumentales/vehiculos/resultados/${selectedVehicules[0]}`);
  };

  const setPage = (page) => {
    setLoading(true);
    return (
      page > 0 &&
      dispatch(
        isDropPage
          ? setVehiculeDrops({ ...dropList, page: page })
          : setVehicules({ ...list, page: page })
      )
    );
  };

  const setSizePage = (size) => {
    setLoading(true);
    dispatch(
      isDropPage
        ? setVehiculeDrops({ ...dropList, per_page: size })
        : setVehicules({ ...list, per_page: size })
    );
  };

  return (
    <>
      <FullTable
        title="Vehículos"
        // NEW VEHICULE TRIGGER
        has_button={!isDropPage}
        buttonTitle="Nuevo Vehículo"
        onClickButton={handleNewVehicle}
        // TABLE ENTITIES
        entities={isDropPage ? dropList.results : list.results}
        columns={columns(onEye, onDrop, isDropPage, permisos.permisos)}
        page={isDropPage ? dropList.page : list.page}
        setPage={setPage}
        sizePerPage={isDropPage ? dropList.per_page : list.per_page}
        setSizePerPage={setSizePage}
        count={isDropPage ? dropList.count : list.count}
        loading={loading}
        // FILTERS ACTIONS
        onClickSearch={handleonClickSearch}
        // SELECTED VEHICULES ACTIONS
        has_button2
        buttonDisabled2={selectedVehicules.length < 1}
        buttonTitle2={`Consultar Todos${
          selectedVehicules.length > 1 ? `(${selectedVehicules.length.toString()})` : ""
        }`}
        onSelectRow={handleSelectRow}
        onSelectAllRows={handleSelectAllRows}
        onClickButton2={handleReqSelected}
        // FILTERS
        has_filter={!isDropPage}
        formFilters={filter}
        onFilterChange={handleFilterChange}
        filterInputs={filterInputs(!filter.cf_marca?.id, subbrands)}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
      />
      <DropModal
        open={Boolean(dropVH.vehicle)}
        onConfirm={onConfirmDrop}
        onCancel={() => setDropVH(defaultDrop)}
        title="Dar de baja el vehículo"
        onValuesChange={onDropValuesChange}
        formValues={dropVH}
      />
    </>
  );
};

export default VehiculesPage;
