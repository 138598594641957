import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import notice from "../../components/Notice";
import ModeTabs from "../../components/ModeTabs";
import EntryActionIcons from "../../components/EntryActionIcons";
import {
  addRequirementFile,
  createRequirement,
  listRequirements,
  updateRequirement,
} from "../../crud/requirementCrud";
import {
  setFilterRequirement,
  setSelectedRequirementIds,
} from "../../../redux/ducks/requirement.duck";
import {
  cleanObject,
  clearEmptyStringsObject,
  filterParams,
} from "../../utils/objectsFunctions";
import { defaultRequirementFilter } from "../../utils/requirementsData";
import { setLoader } from "../../../redux/ducks/loader.duck";
import { useEnterSearch } from "../../hooks/useEnterSearch";
import { requestFormatDate } from "../../utils/dates";

const RequisitionActions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    selectedRequirement,
    selectedIds,
    searchRequirement,
    newRequirement,
    isSearchTab,
    isResultsTab,
    isNewTab,
  } = useSelector((store) => store.requirement);
  const { files } = useSelector((store) => store.files);

  const handleCreateRequirement = () => {
    const payload = {
      supplier_number: newRequirement.supplier?.text2,
      contract: newRequirement.contrato,
      date_requisition: newRequirement.date_requisition,
      max_delivery_date: newRequirement.max_delivery_date,
      observations: newRequirement.observations,
      name_off: newRequirement.name_off,
      num_req: newRequirement.num_req,
      requisition_item_details: newRequirement.contract_set
        .flatMap((contract) =>
          contract.set_item.map((item) => ({
            contract_items_set: item.id,
            requested_amount: item.requested_amount,
            total_price: item.total_price?.toFixed(4),
          }))
        )
        .filter((item) => item.requested_amount),
      contract_set: newRequirement.contract_set
        .map(
          ({ id, date_end, set_item }) =>
            set_item.some((item) => item.requested_amount) && {
              id,
              delivery_date: date_end?.includes("/")
                ? requestFormatDate(date_end)
                : date_end,
            }
        )
        .filter((item) => item),
    };
    if (!payload.supplier_number) {
      notice("Debes indicar el Proveedor", "error");
      return;
    } else if (!payload.contract) {
      notice("Debes indicar el Contrato", "error");
      return;
    } else if (!payload.date_requisition) {
      notice("Debes indicar la Fecha de requerimiento", "error");
      return;
    } else if (!payload.max_delivery_date) {
      notice("Debes indicar la Fecha máxima de entrega", "error");
      return;
    } else {
      dispatch(setLoader(true));
      createRequirement(payload)
        .then(async (res) => {
          if (res.status === 200 || res.status === 201) {
            // IF THERE'S FILES TO SAVE
            if (files.length > 0) {
              const uploadFile = await Promise.all(
                files.map((file) => addRequirementFile(file, res.data.id, file.name))
              );
              if (
                uploadFile.some((response) =>
                  [500, 422].includes(response?.response?.status)
                )
              )
                notice("Alguno de los los archivos no se pudo subir");
              else {
                history.push("/inventario-bienes-almacen-general/requerimientos");
                notice("Requerimiento guardado correctamente", "success");
              }
            } else {
              history.push("/inventario-bienes-almacen-general/requerimientos");
              notice("Requerimiento guardado correctamente", "success");
            }
          } else notice("Error creando el requerimiento");
        })
        .catch((e) => {
          console.log(e);
          notice("Error creando el requerimiento");
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };

  const handleEditRequirement = () => {
    const payload = {
      id: selectedRequirement?.id,
      supplier_number: selectedRequirement.supplier?.text2,
      contract: selectedRequirement.contrato,
      date_requisition: selectedRequirement.date_requisition,
      max_delivery_date: selectedRequirement.max_delivery_date,
      observations: selectedRequirement.observations,
      name_off: selectedRequirement.name_off,
      num_req: selectedRequirement.num_req,
      requisition_item_details: selectedRequirement.contract_set.flatMap((contract) =>
        contract.set_item.map((item) => ({
          id: item.id,
          requisition: selectedRequirement.id,
          contract_items_set: item.contract_items_set,
          requested_amount: item.requested_amount,
          total_price: item.total_price?.toFixed(4),
        }))
      ),
      contract_set: selectedRequirement.contract_set
        .map((contract) => ({
          id: contract.id,
          delivery_date: contract.date_end?.includes("/")
            ? requestFormatDate(contract.date_end)
            : contract.date_end,
        }))
        .filter((item) => item),
    };

    if (!payload.supplier_number) {
      notice("Debes indicar el Proveedor", "error");
      return;
    } else if (!payload.contract) {
      notice("Debes indicar el Contrato", "error");
      return;
    } else if (!payload.date_requisition) {
      notice("Debes indicar la Fecha de requerimiento", "error");
      return;
    } else if (!payload.max_delivery_date) {
      notice("Debes indicar la Fecha máxima de entrega", "error");
      return;
    } else {
      dispatch(setLoader(true));
      updateRequirement(payload)
        .then(({ status, data }) => {
          if (status === 200 || status === 201) {
            notice("Requerimiento guardado correctamente", "success");
            history.push("/inventario-bienes-almacen-general/requerimientos");
          }
        })
        .catch(console.log)
        .finally(() => dispatch(setLoader(false)));
    }
  };

  const handleSearch = () => {
    dispatch(setLoader(true));
    const payload = {
      ...searchRequirement,
      supplier_number: searchRequirement.supplier?.text2,
      contract: searchRequirement.contract?.id,
    };
    listRequirements(1, 100, filterParams(cleanObject(clearEmptyStringsObject(payload))))
      .then((res) => {
        if (res.data?.results[0]?.id) {
          dispatch(setSelectedRequirementIds(res.data.results.map((x) => x.id)));
          history.push(
            `/inventario-bienes-almacen-general/requerimientos/resultados/${res.data?.results[0]?.id}`
          );
        } else notice("Error actualizando el requerimiento");
      })
      .catch((e) => {
        notice("Error actualizando el requerimiento");
        console.log(e);
      })
      .finally(() => dispatch(setLoader(false)));
  };

  const handleResults = () => {
    if (selectedRequirement.id) {
      history.push(
        `/inventario-bienes-almacen-general/requerimientos/resultados/${selectedRequirement.id}`
      );
    } else if (selectedIds[0]) {
      history.push(
        `/inventario-bienes-almacen-general/requerimientos/resultados/${selectedIds[0]}`
      );
    } else {
      notice(
        "No se encontraron registros guardados, pruebe realizando una búsqueda",
        "info"
      );
      dispatch(setFilterRequirement(defaultRequirementFilter));
      history.push("/inventario-bienes-almacen-general/requerimiento/busqueda");
    }
  };

  const onNewRegister = () => {
    history.push("/inventario-bienes-almacen-general/requerimiento/nuevo");
  };

  useEnterSearch(handleSearch);

  return (
    <>
      <ModeTabs
        onSearch={() =>
          history.push("/inventario-bienes-almacen-general/requerimiento/busqueda")
        }
        onNew={onNewRegister}
        onResults={handleResults}
      />
      <EntryActionIcons
        showIcons={{
          save: isNewTab,
          edit: isResultsTab,
          search: isSearchTab,
        }}
        labels={{
          save: "Crear requerimiento",
          edit: "Editar requerimiento",
        }}
        handleSubmit={handleCreateRequirement}
        handleEdit={handleEditRequirement}
        handleSearch={handleSearch}
      />
    </>
  );
};

export default RequisitionActions;
