import React from 'react';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import IconTab from './IconTab';
import {useLocation} from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import Icon from './Icon';
    //@formatter:on
const ModeTabs = ({ editPassword,onSearch, onResults, onNew, showIcons={ newPassword:false ,search: true, results: true, newIcon: true } }) => {
	const isMobile = useMediaQuery('(max-width: 768px)');
    const { pathname } = useLocation();
    //@formatter:off
    const StyledIcon = ({ icon, name, onClick, active }) => {
        return (
            <div
                className='d-flex flex-row justify-content-center align-items-center ml-2 mr-2'
                style={{ margin: isMobile && '5px auto' }}
            >
                <IconTab
                    selected={active}
                    src={toAbsoluteUrl(`/media/svg/icons/${icon}`)}
                    color='secondary'
                    onClick={onClick}
                    label={name}
                />
            </div>
        );
    };

    const { search, results, newIcon,newPassword } = showIcons;

    return (
        <div className="d-flex justify-content-start flex-wrap" style={{ margin: isMobile ? 'auto' : 'auto auto auto 16px'}}>
            {newPassword && <Icon src={toAbsoluteUrl('/media/svg/icons/General/Save.svg')} color='success' label='Cambiar Contraseña' disabled={false} 	style={{minHeight: 36}}
				onClick={(e) => {
                    editPassword()
				}}
				/>}
            {search && <StyledIcon active={pathname.includes('busqueda')} name="Búsqueda" icon="General/Search.svg" onClick={onSearch}/>}
            {results && <StyledIcon active={pathname.includes('resultados')} name="Resultados" icon="Text/Article.svg" onClick={onResults}/>}
            {newIcon && <StyledIcon
              active={pathname.includes('nuevo') || pathname.includes('nueva')}
              name="Nuevo" icon="Design/Eraser.svg"
              onClick={onNew}
            />}
        </div>
    );
};

export default ModeTabs;
