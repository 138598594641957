import React, { useCallback, useEffect, useState, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  setNewEntryResultsForm,
  clearNewEntrySearchForm,
  selectEntry,
  setSelectedEntries,
} from "../../../redux/ducks/entry.duck";
import notice from "../../components/Notice";
import {
  initialInventoryForm,
  initialNewEntryForm,
  initialNewEntrySearchForm,
} from "../../utils/newEntryInitialValues";
import { useSearchMode } from "../../hooks/useSearchMode";
import {
  getFixedInventory,
  getFixedPayload,
} from "../../utils/newEntryPayload";
import {
  createAssetAdq,
  addEntryFile,
  searchEntriesFilter,
  updateAsset,
  getSingleAsset,
  closeAsset,
  downloadReport,
} from "../../crud/entryCrud";
import {
  setCurrentMode,
  setIsEditDisabled,
  setLabel,
  setOnLockClick,
  setOnFinalize,
  setDisabledSaveFalse,
  setIsFinalizeDisabled,
} from "../../../redux/ducks/subHeaderActions.duck";
import { setLoader } from "../../../redux/ducks/loader.duck";
import { useEnterSearch } from "../../hooks/useEnterSearch";
import useSubheaderAction from "../../hooks/useSubheaderAction";
import { modeOptions } from "../../utils/subheaderIconsData";
import BaseModal from "../../components/BaseModal";
import { NewEntryFormFirst } from "./Forms/NewEntryFormFirst";
import NewEntryFormSec from "./Forms/NewEntryFormSec";
import { NewEntryFormBienes } from "./Forms/NewEntryFormBienes";
import TablaBienes from "./Forms/TablaBienes";
import { NewEntryImport } from "./Forms/NewEntryImport";
import { EntryCaptureDate } from "./Forms/EntryCaptureDate";
import PageLayout from "../../components/PageLayout";
import { makeStyles, Typography, useMediaQuery, Box } from "@material-ui/core";
import SimplePagination from "../../components/SimplePagination";
import DigitalizationListDocs from "../DigitalVault/components/common/DigitalizationListDocs";
import { setIdsFilterResult } from "../../../redux/ducks/stock.duck";

const useStyles = makeStyles(() => ({
  contentLayout: {
    overflowX: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
  },
  title: {
    display: "flex",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "wrap",
  },
  headerInputs: {
    maxWidth: "100%",
    display: "flex",
    "&>*": {
      marginRight: 10,
    },
    flexWrap: "wrap",
    justifyContent: "end",
    minHeight: 56,
  },
  topInputs: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    "&>*": {
      marginTop: 20,
    },
  },
}));
const NewEntryPage = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const classes = useStyles({ isMobile });
  const { id } = useParams();
  const history = useHistory();
  // Recommended initialize pathname when using useSubheaderAction custom hook
  const { pathname = "" } = useLocation();

  const dispatch = useDispatch();
  const {
    onReportClick,
    onSearchClick2,
    onNewClick2,
    onCancelClick2,
    onEditClick2,
    onSaveClick2,
    onSearchClick3,
    onNewClick3,
    onCancelClick3,
    onEditClick3,
    currentMode,
    onLockClick,
    onFinalizeClick,
  } = useSelector((store) => store.subHeaderActions);
  const { newEntryForm: formValues, inventory, selectedEntry } = useSelector(
    (store) => store.entry
  );
  const [tablaNew, setTablaNew] = useState([]);
  const [tablaResult, setTablaResults] = useState([]);
  const [allAttachments, setAllAttachments] = useState([]);

  const [dataBienes, setDataBienes] = useState(initialInventoryForm);
  const [formEntryData, setFormEntryData] = useState(
    isSearchPage ? initialNewEntrySearchForm : initialNewEntryForm
  );
  const [formEntryResult, setFormEntryResult] = useState(
    initialNewEntrySearchForm
  );
  const [dataImport, setDataImport] = useState(initialNewEntryForm);
  const { isResultsPage, isSearchPage, isNewPage, isDetailsPage } = useSelector(
    (store) => store.pathname
  );
  const [lockConfirmModal, setLockConfirmModal] = useState(false);
  const [listIds, setListIds] = useState([]);
  const [selectedResults, setSelectedResults] = useState([]);

  //?--buscar
  const searchEntries = () => {
    dispatch(setLoader(true));
    dispatch(setIsEditDisabled(true));

    const newParams = {
      ...formEntryData,
    };
    delete newParams.formatos_f;
    delete newParams.formatos_r;
    dispatch(setIdsFilterResult(formEntryData.id.split("|")));
    searchEntriesFilter(newParams)
      .then((res) => {
        const ids = res.data.map((x) => x.id);
        if (ids.length === 0) {
          dispatch(setLoader(false));
          return notice(
            "No se han encontrado resultados para la búsqueda solicitada"
          );
        } else {
          setListIds(ids);
          dispatch(setSelectedEntries(ids));
          history.push(`/bienes-instrumentales/entradas/resultados/${ids[0]}`);
        }
      })
      .catch(() => {
        dispatch(setCurrentMode(modeOptions.search));
        notice("No se han encontrado resultados para la búsqueda solicitada");
      })
      .finally(() => dispatch(setLoader(false)));
  };

  useEnterSearch(searchEntries);

  const editEntry = useCallback(() => {
    dispatch(setLoader(true));
    const payload = getFixedPayload([], formEntryData);
    const { isValid, message } = validateNewEntry(formEntryData);
    if (!isValid) {
      notice(message, "error");
      dispatch(setLoader(false));
      return;
    }
    dispatch(setLoader(true));
    updateAsset(payload, formEntryResult.id)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          notice("¡La entrada se ha actualizado de manera exitosa!", "success");
        }
        dispatch(setIsFinalizeDisabled(false));
        dispatch(setCurrentMode(null));
      })

      .catch(() => {
        notice("Ha ocurrido un error", "error");
        dispatch(setCurrentMode(modeOptions.edit));
      })
      .finally(() => dispatch(setLoader(false)));
  }, [formEntryData]);
  //obtener el id
  const getCurrentEntry = useCallback(() => {
    if (id) {
      dispatch(setLoader(true));
      getSingleAsset(id)
        .then((res) => {
          if (res) {
            setFormEntryResult(res);
            setDataImport(res);
            setSelectedResults(res);
            setFormEntryData(res);
            setTablaResults(res.inventory);
            setAllAttachments(res.all_attachments);

            dispatch(setNewEntryResultsForm(res));
            res?.id &&
              history.push(
                `/bienes-instrumentales/entradas/resultados/${res.id}`
              );
          } else {
            notice("Error cargando la entrada, favor de volver a intentar1");
          }
        })
        .catch(() => {
          notice("Error cargando la entrada, favor de volver a intentar");
        })
        .finally(() => dispatch(setLoader(false)));
    }
  }, [id, dispatch, history]);

  const saveEntry = () => {
    dispatch(setLoader(true));
    const formAll = { ...formEntryData, ...dataImport };
    const { isValid, message } = validateNewEntry(formAll);
    if (!isValid) {
      notice(message, "error");
      dispatch(setLoader(false));
      return;
    }
    const fixedInventory = getFixedInventory(tablaNew);
    const payload = getFixedPayload(fixedInventory, formAll);
    createAssetAdq(payload)
      .then(async (res) => {
        if (formEntryData?.formatos_f instanceof File && res?.id)
          await addEntryFile(
            formEntryData.formatos_f,
            res.id,
            "Formato Almacen F.pdf"
          );
        if (formEntryData?.formatos_r instanceof File && res?.id)
          await addEntryFile(
            formEntryData.formatos_r,
            res.id,
            "Formato Almacen R.pdf"
          );
        history.push(`/bienes-instrumentales/entradas/resultados/${res.id}`);
        notice("La entrada se ha creado exitosamente!", "success");
        getCurrentEntry();
        dispatch(setIsFinalizeDisabled(false));
        dispatch(setCurrentMode(null));
      })
      .catch((e) => {
        if (e && typeof e === "object") {
          Object.keys(e).forEach((error) =>
            notice(`${error} ${e[error].join("; ")}`)
          );
        } else notice("Ha ocurrido un error inesperado", "error");
      })
      .finally(() => dispatch(setLoader(false)));
  };

  useEffect(() => {
    getCurrentEntry();
  }, [getCurrentEntry, id]);

  useEffect(() => {
    if (selectedResults.id) {
      dispatch(setCurrentMode(null));
      if (selectedResults.is_closed) {
        dispatch(setIsEditDisabled(true));
      } else {
        dispatch(setIsEditDisabled(false));
      }
    }
  }, [selectedResults.id, dispatch]);

  const validateNewEntry = (form) => {
    let result = { isValid: true, message: "" };
    const { cf_medioadquisicion } = form;

    if (cf_medioadquisicion === "")
      result = {
        isValid: false,
        message: "Es necesario seleccionar un medio de adquisición",
      };
    dispatch(setDisabledSaveFalse());

    return result;
  };

  useSearchMode("/bienes-instrumentales/entradas/busqueda");

  useEffect(() => {
    if (pathname.includes("busqueda")) {
      setFormEntryData(initialNewEntrySearchForm);
      setAllAttachments([]);
      //--nuevo
      setTablaNew([]);
      setTablaResults([]);
      setDataImport([]);
    } else if (pathname.includes("nueva")) {
      setFormEntryData(initialNewEntryForm);
      setAllAttachments([]);
      //--nuevo
      setTablaNew([]);
      setTablaResults([]);
      setDataImport([]);
    }
  }, [pathname]);

  const clearForms = () => {
    if (pathname.includes("/busqueda")) {
      dispatch(clearNewEntrySearchForm());
      setAllAttachments([]);
      //--nuevo
      setTablaNew([]);
      setTablaResults([]);
      setFormEntryResult(initialNewEntrySearchForm);
      setSelectedResults([]);

      setFormEntryData(initialNewEntrySearchForm);
    } else if (pathname.includes("/nueva")) {
      setSelectedResults([]);
      setTablaNew([]);
      setTablaResults([]);
      setAllAttachments([]);
      setFormEntryResult(initialNewEntrySearchForm);
      setFormEntryData(initialNewEntrySearchForm);
      dispatch(clearNewEntrySearchForm());
      dispatch(setNewEntryResultsForm(initialNewEntryForm));
    } else if (pathname.includes("/resultados")) {
      dispatch(setIsEditDisabled(false));
      if (!!selectedResults.id) {
        setSelectedResults(initialNewEntryForm);
      }
    }
  };

  const onConfirmLock = () => {
    setLockConfirmModal(false);
    dispatch(setLoader(true));
    closeAsset(id)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          notice(
            res.data.message || "La entrada de cerró satisfactoriamente",
            "success"
          );
          const newSelectedEntry = { ...selectedEntry, is_closed: true };
          dispatch(selectEntry({ ...newSelectedEntry, inventory }));
          setSelectedResults({ ...newSelectedEntry, inventory });
          dispatch(setCurrentMode(null));
        }
      })
      .catch((err) => {
        console.log(err);
        notice("Ha ocurrido un error con el servidor", "error");
        dispatch(setCurrentMode(modeOptions.edit));
      })
      .finally(() => {
        dispatch(setOnLockClick(null));
        dispatch(setLoader(false));
      });
  };

  const handleReport = () => {
    dispatch(setLoader(true));
    let ids = listIds;
    downloadReport(ids)
      .then((res) => window.open(res.attachment_url, "_blank"))
      .finally(() => dispatch(setLoader(false)));
  };

  useEffect(() => {
    if (pathname.includes("/nueva")) {
      setSelectedResults([]);
      dispatch(setCurrentMode(modeOptions.new));
      setFormEntryResult(initialNewEntrySearchForm);
    } else if (pathname.includes("/busqueda")) {
      dispatch(setCurrentMode(modeOptions.search));
      setFormEntryResult(initialNewEntrySearchForm);
    } else {
      dispatch(setCurrentMode(modeOptions.search));
    }
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (onReportClick && listIds.length !== 0) {
      handleReport();
    }
  }, [onReportClick]);

  useEffect(() => {
    if (onLockClick) {
      setLockConfirmModal(true);
    }
  }, [onLockClick]);

  useEffect(() => {
    if (onFinalizeClick !== null) {
      history.push("/bienes-instrumentales/entradas/");
      dispatch(setOnFinalize(null));
      dispatch(setIsFinalizeDisabled(false));
    } else {
      dispatch(setIsFinalizeDisabled(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onFinalizeClick]);

  useEffect(() => {
    if (isResultsPage && id) dispatch(setLabel(`Entrada ${id}`));
  }, [dispatch, isResultsPage, id]);

  useSubheaderAction({
    action: onSearchClick2,
    func: () => setFormEntryData(initialNewEntrySearchForm),
    isInPage: pathname.includes("/busqueda"),
    path: "/bienes-instrumentales/entradas/busqueda",
  });

  useSubheaderAction({
    action: onSearchClick3,
    func: searchEntries,
    isInPage: pathname.includes("/busqueda"),
    path: "/bienes-instrumentales/entradas/busqueda",
  });

  useSubheaderAction({
    action: onNewClick2,
    isInPage: pathname.includes("/nueva"),
    path: "/bienes-instrumentales/entradas/nueva",
  });

  useSubheaderAction({
    action: onNewClick3,
    isInPage: pathname.includes("/nueva"),
    path: "/bienes-instrumentales/entradas/nueva",
  });

  useSubheaderAction({
    action: onEditClick2,
    isInPage: pathname.includes("/resultados"),
    path: formEntryResult?.id
      ? `/bienes-instrumentales/entradas/resultados/${formEntryResult.id}`
      : false,
  });

  useSubheaderAction({
    action: onEditClick3,
    func: editEntry,
    isInPage: pathname.includes("/resultados"),
    path: formEntryResult?.id
      ? `/bienes-instrumentales/entradas/resultados/${formEntryResult.id}`
      : false,
  });

  useSubheaderAction({
    action: onCancelClick2,
    func: clearForms,
    isInPage: isDetailsPage,
  });

  useSubheaderAction({
    action: onCancelClick3,
    func: clearForms,
    isInPage: isDetailsPage,
  });

  useSubheaderAction({
    action: onSaveClick2,
    func:
      currentMode === modeOptions.edit
        ? editEntry
        : currentMode === modeOptions.new
        ? saveEntry
        : () => {},
    isInPage: true,
  });

  const closeModalLockEntry = () => {
    dispatch(setOnLockClick(null));
    setLockConfirmModal(false);
  };

  const handleClickTab = (index) =>
    history.push(
      `/bienes-instrumentales/entradas/resultados/${listIds[index - 1]}`
    );

  useEffect(() => {
    if (tablaNew.length === 0) {
      setDataImport(initialNewEntryForm);
    } else {
      const IVA = 16;

      const cf_sub_total = tablaNew.reduce(
        (acc, value) =>
          value.inventory_detail_cf_cantidad > 0
            ? acc +
              (value.inventory_detail_cf_cantidad *
                value.inventory_detail_cf_precio_unitario -
                value.inventory_detail_cf_cantidad_descuento)
            : acc,
        0
      );
      const cf_importe_neto = cf_sub_total + cf_sub_total * (IVA / 100);
      const cf_iva_factura = cf_sub_total * (IVA / 100);

      setDataImport({
        cf_sub_total: cf_sub_total.toFixed(2),
        cf_iva_factura: cf_iva_factura.toFixed(2),
        cf_importe_neto: cf_importe_neto.toFixed(2),
      });
    }
  }, [tablaNew]);

  return (
    <PageLayout
      title={
        <Box className={classes.header}>
          <Typography variant="h6" className={classes.title}>
            {isSearchPage
              ? "Buscar entrada(s)"
              : isResultsPage
              ? "Ver/Editar entrada"
              : "Nueva entrada"}
          </Typography>
        </Box>
      }
      header={
        isResultsPage &&
        listIds.length > 1 && (
          <SimplePagination
            current={selectedResults.id}
            data={listIds}
            handleClickItem={handleClickTab}
          />
        )
      }
      contentClassName={classes.contentLayout}
    >
      {!isSearchPage && <EntryCaptureDate formEntryData={formEntryData} />}
      <NewEntryFormFirst
        formEntryData={formEntryData}
        setFormEntryData={setFormEntryData}
        selectedResults={selectedResults}
        setFormEntryResult={setFormEntryResult}
        formEntryResult={formEntryResult}
      />
      <NewEntryFormSec selectedResults={selectedResults} />

      {!isSearchPage && (
        <>
          {isNewPage && (
            <NewEntryFormBienes
              tablaNew={tablaNew}
              setTablaNew={setTablaNew}
              formEntryData={formEntryData}
              dataBienes={dataBienes}
              setDataBienes={setDataBienes}
              setDataImport={setDataImport}
              dataImport={dataImport}
            />
          )}
          <TablaBienes
            tablaNew={tablaNew}
            setTablaNew={setTablaNew}
            tablaResult={tablaResult}
            setTablaResults={setTablaResults}
            setDataImport={setDataImport}
            getData={getCurrentEntry}
            id={id}
          />
          <NewEntryImport dataImport={dataImport} />
          {formEntryData.all_attachments && (
            <DigitalizationListDocs
              data={formEntryData.all_attachments}
              newDigitalization
              module={"asset_acquisition"}
              attachTo={selectedResults.id}
            />
          )}
          {/* {isResultsPage && selectedResults && (
            <UploadPDFNew
              formValues={formEntryData}
              selectedResults={selectedResults}
              setSelectedResults={setSelectedResults}
            />
          )} */}
        </>
      )}
      <BaseModal
        title="¿Estás seguro de que deseas cerrar esta entrada?"
        open={lockConfirmModal}
        onClose={() => closeModalLockEntry()}
        onConfirm={onConfirmLock}
        confirmLabel="Si, cerrar entrada"
        onCancel={() => closeModalLockEntry()}
        cancelLabel="No"
        color="secondary"
      />
    </PageLayout>
  );
};

export default NewEntryPage;
