import { CATALOGS_URL, TASK_MANAGEMENT } from "./helpers/routes";
import { getParams, handleError, handleResponse } from "./helpers/validate";
const VARIANTS_URL = `${CATALOGS_URL}/variantes`;

// VARIANTS CRUD
export const getVariantsList = (page, limit) => {
  return fetch(`${VARIANTS_URL}?page=${page}&limit=${limit}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const getList = async (page, limit) => {
  return fetch(`${TASK_MANAGEMENT}/other-documents`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const getSingleVariant = async (id) => {
  return fetch(`${TASK_MANAGEMENT}/other-documents/${id}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const createVariant = (data) => {
  return fetch(VARIANTS_URL, getParams("POST", data))
    .then(handleResponse)
    .catch(handleError);
};

export const updateVariant = (data) => {
  return fetch(`${VARIANTS_URL}/${data.id}`, getParams("PUT", data))
    .then(handleResponse)
    .catch(handleError);
};

export const deleteVariant = (id) => {
  return fetch(`${VARIANTS_URL}/${id}`, getParams("DELETE"))
    .then(handleResponse)
    .catch(handleError);
};
