import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core";
import { artWorkFinantialTabInputs, reportInputs } from "../utils/artWorksData";
import SimpleInput from "./SimpleInput";
import UtilInputs from "./UtilInputs";
import { useSelector } from "react-redux";
import { fieldsToTypeText } from "../utils/objectsFunctions";
import { toggleEnableInputs } from "../utils/newEntryData";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  textFields: {
    display: "flex",
    flexWrap: "wrap",
    "&>*": {
      margin: "10px 15px 0px 0px",
    },
  },
});

const ArtWorkFinantialDataTab = ({ formValues, onValuesChange }) => {
  const { pathname } = useLocation();

  const classes = useStyles();
  const { isSearchTab } = useSelector((store) => store.artWork);
  const { currentMode } = useSelector((store) => store.subHeaderActions);
  const { isResultsPage } = useSelector((store) => store.pathname);

  let inputValues = artWorkFinantialTabInputs;
  inputValues = !currentMode ? toggleEnableInputs(inputValues, false) : inputValues;

  const textareaInputs = !currentMode
    ? toggleEnableInputs(reportInputs, false)
    : reportInputs;

  const partida = () => {
    if (isResultsPage) {
      if (
        formValues?.num_partida &&
        formValues.partida.no_cuenta !== undefined &&
        formValues.partida.no_scuenta !== undefined
      ) {
        return {
          ...formValues,
          num_partida: formValues?.num_partida,
          cf_cve_partida: formValues["cf_cve_partida"].text2,
        };
      } else {
        return formValues;
      }
    } else {
      return formValues;
    }
  };
  return (
    <Fragment>
      <div className={classes.textFields}>
        {textareaInputs.map((input) => (
          <SimpleInput
            disabled={input.disabled}
            key={input.name}
            label={input.label}
            placeholder={input.placeholder}
            name={input.name}
            multiline={true}
            rows={3}
            width={250}
            value={formValues[input.name]}
            onChange={onValuesChange}
            inputProps={{ maxLength: input.maxLength }}
          />
        ))}
      </div>
      <div style={{ minHeight: 20 }}></div>

      <UtilInputs
        formValues={partida()}
        inputValues={inputValues}
        onValuesChange={onValuesChange}
      />
    </Fragment>
  );
};

export default ArtWorkFinantialDataTab;
