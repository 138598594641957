import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CONSULTA_QR_ARTWORK, qrViewArtworkSelector } from '../../../redux/ducks/qrviewArtwork.duck';
import QRView from '../../components/QRView';

const QRViewArtworkPage = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const {
        qrArtworkData
    } = useSelector(qrViewArtworkSelector);
    useEffect(() => {
      dispatch(CONSULTA_QR_ARTWORK(id));
    }, [dispatch, id]);

    // Props that ARView accepts
    const fixedQrArtworkData = {
			cf_articulo: qrArtworkData.articulo,
			inventory: qrArtworkData.no_inventario,
			cve_area: qrArtworkData.area.id,
			area: qrArtworkData.area.num_area,
			sarea: qrArtworkData.area.sarea,
			sssarea: qrArtworkData.area.ssrea,
			edificio: qrArtworkData.area.nom_edificio,
			cabms: qrArtworkData.cabms,
			ingreso: qrArtworkData.fecha_ingreso,
			partida: qrArtworkData.num_partida,
		};

    return (
        <QRView qr={fixedQrArtworkData}/>
    );
};

export default QRViewArtworkPage;