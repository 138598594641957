import React from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles({
	root: {
		padding: 15,
		display: ({ flex }) => flex && 'flex',
		flexWrap: 'wrap',
		background: "#efefef",
		height: "100%",
	},
	header: {
		display: 'flex',
		justifyContent: 'flex-start',
		marginBottom: 10,
		flexWrap: 'wrap',
		overflowX: "auto"
	},
	pageContent: {
		paddingRight: 5,
		overflow: "auto",
  	maxHeight: ({ header }) => header ? 'calc(100vh - 330px)' : 'calc(100vh - 245px)',
  	minHeight: ({ header }) => header ? 'calc(100% - 300px)' : 'calc(100% - 250px)',
		
		'@media (max-width: 768px)': {
			maxHeight: ({ header }) => header ? 'calc(100% - 210px)' : 'calc(100% - 160px)',
		},

  	'@media (max-width: 1201px)': {
  	  maxHeight: ({ header }) => header ? 'calc(100% - 180px)' : 'calc(100% - 130px)',
  	},
	}
});

const PageLayout = ({ children, header, title, flex, minHeight, className, height, contentClassName }) => {
    const classes = useStyles({ flex, minHeight, height, header });
    return (
			<Paper className={`${classes.root} ${className}`} elevation={0} variant='outlined'>
				{title && <Typography variant='h6'>{title}</Typography>}
				{header && <div className={classes.header}>
					{header}
				</div>}
				<div className={`${classes.pageContent} ${contentClassName}`}>
        	{children}
				</div>
			</Paper>
		);
};

export default PageLayout;