import React, { useState } from "react";
import SimpleDate from "../../../components/SimpleDate";
import { formatDateDDMMYYYY } from "../../../utils/dates";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  headerInputs: {
    maxWidth: "100%",
    display: "flex",
    "&>*": {
      marginRight: 10,
    },
    flexWrap: "wrap",
    justifyContent: "end",
    minHeight: 56,
  },
}));
export const EntryCaptureDate = ({ formEntryData }) => {
  const { currentMode } = useSelector((store) => store.subHeaderActions);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const classes = useStyles({ isMobile });

  return (
    <div className={classes.headerInputs}>
      <SimpleDate
        label="Fecha de captura"
        placeholder="Dd/mm/aaaa"
        name="created_at"
        value={
          formEntryData.created_at
            ? formEntryData.created_at
            : formatDateDDMMYYYY(new Date())
        }
        disabled={currentMode === "search" ? false : true}
        bgColor="#bbdefb"
      />
    </div>
  );
};
