/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import objectPath from "object-path";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import RequisitionActions from "../../../../app/pages/Requirements/RequisitionActions";
import ContractActions from "../../../../app/pages/Contracts/ContractActions";
import RequestActions from "../../../../app/pages/Requests/RequestActions";
import UserInfoChip from "../../../../app/pages/Requests/UserInfoChip";
import DepartureActions from "../../../../app/pages/Departures/DepartureActions";
import ArticlesActions from "../../../../app/pages/Articles/ArticlesActions";
import UsersActions from "../../../../app/pages/Users/UsersActions";
import EntranceActions from "../../../../app/pages/Entrances/EntranceActions";
import EntranceRefundActions from "../../../../app/pages/Entrances/EntranceRefundActions";
import ConfigVariantActions from "../../../../app/components/ConfigVariantActions";
import ConfigPresentationsActions from "../../../../app/components/ConfigPresentationsActions";
import ConfigGroupActions from "../../../../app/components/ConfigGroupActions";
import ConfigPermitsActions from "../../../../app/components/ConfigPermitsActions";
import ConfigRequestActions from "../../../../app/pages/Configuration/Warehouse/ConfigRequestActions";
import SubheaderIcons from "../../../../app/components/SubheaderIcons";
import MeUserAction from "../../../../app/pages/Users/MeUserAction";
import ToDoSubHeader from "../../../../app/pages/Todo/components/ToDoSubHeader";
import DigitalVaultSubHeader from "../../../../app/pages/DigitalVault/components/DigitalVaultSubHeader";
import { isAValidInteger } from "../../../../app/utils/inputValidations";
import { clearActions } from "../../../../redux/ducks/subHeaderActions.duck";
import EntranceConcentrateActions from "../../../../app/pages/EntranceConcentrate/EntranceConcentrateActions";

export function SubHeader({ hideSidebar }) {
  const dispatch = useDispatch();
  const uiService = useHtmlClassService();
  const { pathname } = useLocation();
  const subheader = useSubheader();
  const {
    isDetailsPage,
    isInstrumentalGoodsPage,
    isEntryDetailsPage,
    isStockDetailsPage,
    isArtWorkDetailsPage,
    isVehicleDetailsPage,
    isEntranceDetailsPage,
    isUserDetailsPage,
    isStockSurveyDetailsPage,
    isEntranceRefundDetailsPage,
    isWarehousePage,
    isDepartureDetailsPage,
    isConfigWarehouseDetailsPage,
  } = useSelector((store) => store.pathname);
  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(uiService.config, "subheader.mobile-toggle"),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses("subheader_container", true),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0 ? aside.title : header.title
    );
    // eslint-disable-next-line
  }, [pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  const EditarPerfil = pathname.includes("/usuarios/EditarPerfil");
  const isBajasDetailsPage =
    pathname.includes("bajas/resultados") || pathname.includes("bajas/busqueda");
  const isRequisitionPage =
    pathname.includes("inventario-bienes-almacen-general/requerimiento/nuevo") ||
    pathname.includes("inventario-bienes-almacen-general/requerimiento/busqueda") ||
    (pathname.includes("inventario-bienes-almacen-general/requerimiento") &&
      isAValidInteger(pathname.split("/")[pathname.split("/").length - 1]));

  const isContractPage =
    pathname.includes("inventario-bienes-almacen-general/contratos/nuevo") ||
    (pathname.includes("inventario-bienes-almacen-general/contratos") &&
      isAValidInteger(pathname.split("/")[pathname.split("/").length - 1])) ||
    pathname.includes("inventario-bienes-almacen-general/contratos/busqueda");

  const isRequestPage = pathname.includes("/solicitudes");

  const isArticlesPage =
    pathname.includes("/inventario-bienes-almacen-general/articulos/nuevo") ||
    pathname.includes("/inventario-bienes-almacen-general/articulos/busqueda") ||
    pathname.includes("/inventario-bienes-almacen-general/articulos/");

  const isConfigPage = pathname.includes(
    "inventario-bienes-almacen-general/configuracion"
  );
  const isConfigVariants =
    isConfigPage &&
    (pathname.includes("/variantes/nuevo") || pathname.includes("/variantes/resultados"));
  const isConfigPresentations =
    isConfigPage &&
    (pathname.includes("presentacion/nuevo") ||
      pathname.includes("presentacion/resultados"));
  const isConfigGroups =
    isConfigPage &&
    (pathname.includes("grupos/nuevo") || pathname.includes("grupos/resultados"));
  const isConfigPermissions =
    isConfigPage &&
    (pathname.includes("permisos/nuevo") || pathname.includes("permisos/resultados"));

  const isDigitalVaultPage = pathname.includes("/boveda-digital/carpetas");

  const isMonitoringPage = pathname.includes("/monitoreo");
  const isToDoPage =
    pathname.includes("/administracion-tareas") ||
    pathname.includes("/administrador-tareas");
  const isEntranceConcentratePage =
    pathname.includes("/inventario-bienes-almacen-general/concentrado-entradas/nuevo") ||
    pathname.includes("/inventario-bienes-almacen-general/concentrado-entradas/editar");

  useEffect(() => {
    if (!isInstrumentalGoodsPage) {
      dispatch(clearActions());
    }
  }, [isInstrumentalGoodsPage, dispatch]);
  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses} ${
        hideSidebar ? "hidden-sidebar" : ""
      }`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
        </div>
        {EditarPerfil && (
          <div className="d-flex justify-content-end w-100">
            {EditarPerfil && <MeUserAction />}
          </div>
        )}
        {isInstrumentalGoodsPage ? (
          <>
            {(isEntryDetailsPage ||
              isStockDetailsPage ||
              isArtWorkDetailsPage ||
              isVehicleDetailsPage ||
              isStockSurveyDetailsPage ||
              isBajasDetailsPage ||
              pathname.includes("/configuracion/catalogos/tipo-de-documento/")) && (
              <SubheaderIcons />
            )}
            {isDigitalVaultPage && <DigitalVaultSubHeader />}
            {isToDoPage && <ToDoSubHeader />}
            {(isUserDetailsPage || isStockSurveyDetailsPage) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flex: 1,
                }}
              >
                {isUserDetailsPage && <UsersActions />}
              </div>
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            {isRequisitionPage && <RequisitionActions />}
            {isEntranceConcentratePage && <EntranceConcentrateActions />}
            {isContractPage && <ContractActions />}
            {isRequestPage && !isMonitoringPage && (
              <>
                {!isWarehousePage && <UserInfoChip />}
                {isDetailsPage && <RequestActions />}
              </>
            )}
            {isDepartureDetailsPage && <DepartureActions />}
            {isArticlesPage && <ArticlesActions />}
            {isUserDetailsPage && <UsersActions />}
            {isConfigVariants && <ConfigVariantActions />}
            {isConfigPresentations && <ConfigPresentationsActions />}
            {isConfigGroups && <ConfigGroupActions />}
            {isConfigPermissions && <ConfigPermitsActions />}
            {isEntranceDetailsPage && <EntranceActions />}
            {isEntranceRefundDetailsPage && <EntranceRefundActions />}
            {isConfigWarehouseDetailsPage && <ConfigRequestActions />}
            {isToDoPage && <ToDoSubHeader />}
            {isDigitalVaultPage && <DigitalVaultSubHeader />}
          </div>
        )}
      </div>
    </div>
  );
}
