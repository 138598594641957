import axios from "axios";
import { isAValidInteger } from "../utils/inputValidations";
import {
  CATALOGS_URL,
  ASSTETS_STOCK,
  UPLOAD_FILE_URL,
  INSTRUMENTAL_GOODS,
  CONSULTA_QR_ARTWORK,
  OPERATIVE_USERS_URL,
  TASK_MANAGEMENT_FOLIOS,
  TASK_MANAGEMENT_SHIFTS,
  TASK_MANAGEMENT_PRIORITIES,
  TASK_MANAGEMENT,
  DIGITAL_VAULT_URL,
  SIGNATURE_URL,
  PARTIDA_URL,
} from "./helpers/routes";
import { getCABMSKey } from "../utils/cabmsAndKey";
import uuid from "react-uuid";
import _ from "lodash";
import { PAGINATION_LIMIT } from "../utils/configData";
import { getParams, handleError, handleResponse } from "./helpers/validate";
import { cleanObject, clearEmptyStringsObject } from "../utils/objectsFunctions";
import { getBase64 } from "../utils/files";

const CATALOG_CABMS_URL = INSTRUMENTAL_GOODS + "/catalog-cabm";
const CATALOG_CABMS_CLAVE_URL = INSTRUMENTAL_GOODS + "/catalog-cabm-clave";
const PRESENTATIONS_URL = `${CATALOGS_URL}/presentacion-articulo`;
const VARIANTS_URL = `${CATALOGS_URL}/variantes`;
const SUPPLIER_URL = `${CATALOGS_URL}/proveedores`;

const paginateBody = (response, page) => {
  return {
    count: response.data.count,
    links: response.data.links,
    page: page,
    per_page: response.data.per_page,
    total_pages: response.data.total_pages,
  };
};

export const getArticles = (query) => {
  const params = new URLSearchParams();
  const parseQuery = _.parseInt(query);
  _.isNaN(parseQuery)
    ? params.append("desc", query)
    : params.append("clave", `${parseQuery}`);
  return axios
    .get(`${CATALOGS_URL}/search/articulos?${params.toString()}`)
    .then(
      (res) =>
        res?.status &&
        res.status === 200 &&
        res.data.map((option) => ({
          ...option,
          id: option.id,
          text: option.desc_corta.trim(),
          text2: option.cve_articulo,
          unit: option.uni_distribuye,
          cve_articulo: option.cve_articulo,
        }))
    )
    .catch((err) => console.log("error" + err));
};

export const getArticlesStock = (query) => {
  const params = new URLSearchParams();
  const parseQuery = _.parseInt(query);
  _.isNaN(parseQuery)
    ? params.append("desc", query)
    : params.append("clave", `${parseQuery}`);
  return axios
    .get(`${CATALOGS_URL}/search/articulos?${params.toString()}&tipo=S`)
    .then(
      (res) =>
        res?.status &&
        res.status === 200 &&
        res.data.map((option) => ({
          ...option,
          id: option.id,
          text: option.desc_corta.trim(),
          text2: option.cve_articulo,
          unit: option.uni_distribuye,
          cve_articulo: option.cve_articulo,
        }))
    )
    .catch((err) => console.log("error" + err));
};

export const getArticlesClean = (query, type = "") => {
  const params = new URLSearchParams();
  const parseQuery = _.parseInt(query);
  _.isNaN(parseQuery)
    ? params.append("desc", query)
    : params.append("clave", `${parseQuery}`);
  return axios
    .get(
      `${CATALOGS_URL}/search/articulos?${params.toString()}${
        type === "return" ? "" : "&tipo=L"
      }`
    )
    .then(
      (res) =>
        res?.status &&
        res.status === 200 &&
        res.data.map((option) => ({
          ...option,
          id: option.id,
          text: option.desc_corta.trim(),
          text2: option.cve_articulo,
          unit: option.uni_distribuye,
          cve_articulo: option.cve_articulo,
        }))
    )
    .catch((err) => console.log("error" + err));
};

export const getArticlesRecurrent = (query) => {
  const params = new URLSearchParams();
  const parseQuery = _.parseInt(query);
  _.isNaN(parseQuery)
    ? params.append("desc", query)
    : params.append("clave", `${parseQuery}`);
  return axios
    .get(`${CATALOGS_URL}/search/articulos?${params.toString()}&tipo=R`)
    .then(
      (res) =>
        res?.status &&
        res.status === 200 &&
        res.data.map((option) => ({
          ...option,
          id: option.id,
          text: option.desc_corta.trim(),
          text2: option.cve_articulo,
          unit: option.uni_distribuye,
          cve_articulo: option.cve_articulo,
        }))
    )
    .catch((err) => console.log("error" + err));
};

export const getArticlesWithVariants = (query) => {
  const params = new URLSearchParams();
  const parseQuery = _.parseInt(query);
  _.isNaN(parseQuery)
    ? params.append("desc", query)
    : params.append("clave", `${parseQuery}`);
  return axios
    .get(`${CATALOGS_URL}/search/articulos?${params.toString()}`)
    .then((res) => {
      if (res?.status === 200) {
        let options = [];
        for (let i = 0; i < res.data.length; i++) {
          const option = res.data[i];
          if (option.variant_data?.length > 0) {
            // eslint-disable-next-line no-mixed-operators
            for (let j = 0; j < option.variant_data.length > 0; j++) {
              const variant = option.variant_data[j];
              const { variant_text, variant_asset_id_1, variant_asset_id_2 } = variant;
              options = [
                ...options,
                {
                  ...option,
                  id: option.id,
                  text: `${option.desc_corta} ${variant_text}`,
                  text2: `${variant_asset_id_1} ${variant_asset_id_2}`,
                  unit: option.uni_distribuye,
                  cve_articulo: option.cve_articulo,
                  variant_text,
                  variant_asset_id_1,
                  variant_asset_id_2,
                },
              ];
            }
          } else {
            options = [
              ...options,
              {
                ...option,
                id: option.id,
                text: option.desc_corta.trim(),
                text2: option.cve_articulo,
                unit: option.uni_distribuye,
                cve_articulo: option.cve_articulo,
              },
            ];
          }
        }
        return options;
      }
    })
    .catch((err) => console.log("error" + err));
};

export const getAreas = (query) => {
  let url = `${CATALOGS_URL}/areas/`;
  // if (isAValidInteger(query)) url = `${url}?numero=${query}`
  // else url = `${url}?nombre=${query}`
  url = `${url}?numero=${query}`;
  return axios
    .get(url)
    .then(
      (res) =>
        res?.status &&
        res.status === 200 &&
        res.data.map((option) => ({
          ...option,
          id: option.num_area,
          text: option.nom_area?.trim(),
          text2: option.num_area?.trim(),
        }))
    )
    .catch((err) => console.log("error" + err));
};

export const getAllProviders = () => {
  let url = `${CATALOGS_URL}/proveedores-catalogo?limit=5000`;
  return axios
    .get(url)
    .then(
      (res) =>
        res?.status &&
        res.status === 200 &&
        res.data.results.map((option) => ({
          value: option.num_prove,
          text: option.razon_social.trim(),
        }))
    )
    .catch((err) => console.log("error" + err));
};

export const getProviders = (query) => {
  let url = `${CATALOGS_URL}/proveedores-instrumental/filter/`;
  // Neto asked to change from rfc to clave on 12/jul/21 meeting
  if (isAValidInteger(query)) {
    url = `${url}?clave=${query}`;
  } else {
    url = `${url}?razon=${query}`;
  }

  return axios
    .get(url)
    .then(
      (res) =>
        res?.status &&
        res.status === 200 &&
        res.data.map((option) => ({
          value: option.id,
          text: option.razon_social.trim(),
          text2: option.num_prove,
        }))
    )
    .catch((err) => console.log("error" + err));
};

export const getProvidersMultiple = () => {
  let url = `${CATALOGS_URL}/proveedores-instrumental?limit=10000`;

  return axios
    .get(url)
    .then(
      (res) =>
        res?.status &&
        res.status === 200 &&
        res.data.results.map((option) => ({
          value: option.num_prove,
          text: option.razon_social.trim() + " - " + option.num_prove,
        }))
    )
    .catch((err) => console.log("error" + err));
};

export const getProvidersAlmacen = (query) => {
  let url = `${CATALOGS_URL}/proveedores/`;
  // Neto asked to change from rfc to clave on 12/jul/21 meeting
  if (isAValidInteger(query)) url = `${url}?clave=${query}`;
  else url = `${url}?razon=${query}`;

  return axios
    .get(url)
    .then(
      (res) =>
        res?.status &&
        res.status === 200 &&
        res.data.map((option) => ({
          id: option.id,
          text: option.razon_social.trim(),
          text2: option.num_prove,
        }))
    )
    .catch((err) => console.log("error" + err));
};

export const getOptionsLevamtamiento = (query) => {
  let url = `${ASSTETS_STOCK}/inventory-take-catalog`;
  // Neto asked to change from rfc to clave on 12/jul/21 meeting
  url = `${url}?inventory=${query}`;

  return axios
    .get(url)
    .then(
      (res) =>
        res?.status &&
        res.status === 200 &&
        res.data.map((option) => ({
          id: option.observaciones,
          text: option.observaciones,
        }))
    )
    .catch((err) => console.log("error" + err));
};

export const getBatches = (query) => {
  let url = `${CATALOGS_URL}/partida/`;
  // if (isAValidInteger(query)) url = `${url}?numero=${query}`
  // else url = `${url}?desc=${query}`
  url = `${url}?num_partida=${query}*`;
  return axios
    .get(url)
    .then((res) => {
      return (
        res?.status &&
        res.status === 200 &&
        res.data.results.map((option) => ({
          id: option.id,
          text: option.desc_partida.trim(),
          text2: option.num_partida,
          no_cuenta: option.no_cuenta,
          no_scuenta: option.no_scuenta,
        }))
      );
    })
    .catch((err) => console.log("error" + err));
};

export const getBatchesMultiple = (query) => {
  let url = `${CATALOGS_URL}/partida?limit=10000`;

  return axios
    .get(url)
    .then((res) => {
      return (
        res?.status &&
        res.status === 200 &&
        res.data.map((option) => ({
          value: option.num_partida,
          text: option.num_partida,
        }))
      );
    })
    .catch((err) => console.log("error" + err));
};

export const getItemCABMSAndKey = (query) => {
  const url = `${CATALOG_CABMS_URL}/search/${query}`;
  return axios.get(url).then((res) => {
    return (
      res.status &&
      res.status === 200 &&
      res.data.map((option) => ({
        id: option.id,
        text: option.cf_denominacion?.trim(),
        text2: getCABMSKey(option),
        cf_grupo: option.cf_grupo,
        cf_subgrupo: option.cf_subgrupo,
        cf_clase: option.cf_clase,
        cf_subclase: option.cf_subclase,
        cf_tipo: option.cf_tipo,
      }))
    );
  });
};

export const crearCatalogoCabms = (body) => {
  return axios.post(`${CATALOG_CABMS_URL}`, body);
};

export const editCatalogoCabms = (id, body) => {
  return axios.put(`${CATALOG_CABMS_URL}/${id}/`, body);
};

export const eliminarCatalogoCabms = (id) => {
  return axios.delete(`${CATALOG_CABMS_URL}/${id}`);
};

export const formatResponsableObject = (data) => {
  const responsables = data.map((responsable) => ({
    persona_id: responsable.id,
    num_empleado: responsable.id,
  }));
  return responsables;
};

export const crearCatalogoArea = (body) => {
  const edificioId = body.edificio_nombre.id; //body.edificio.id
  body = {
    ...body,
    edificio: edificioId,
    responsables: formatResponsableObject(body.responsables),
  };

  return axios.post(`${CATALOGS_URL}/crud-areas`, body);
};

export const editCatalogoArea = (id, body) => {
  let edificioId = body.edificio_nombre.id;
  body = {
    ...body,
    edificio: edificioId,
    // suboficina: body.soficina,
    suboficina: body.suboficina,

    responsables: formatResponsableObject(body.responsables),
  };
  // delete body.soficina;

  return axios.put(`${CATALOGS_URL}/crud-areas/${id}/`, body);
};

export const eliminarCatalogoArea = (id) => {
  return axios.delete(`${CATALOGS_URL}/crud-areas/${id}`);
};

// Crud Edificios
export const crearEdificio = (body) => {
  return axios.post(`${CATALOGS_URL}/crud-edificios`, body);
};

export const getEdificiosMultiple = (body) => {
  let url = `${CATALOGS_URL}/crud-edificios?limit=10000`;

  return axios
    .get(url)
    .then((res) => {
      return (
        res?.status &&
        res.status === 200 &&
        res.data.results.map((option) => ({
          value: option.cve_edificio,
          text: option.nom_edificio,
        }))
      );
    })
    .catch((err) => console.log("error" + err));
};

export const editarEdificio = (id, body) => {
  return axios.put(`${CATALOGS_URL}/crud-edificios/${id}/`, body);
};

export const eliminarEdificio = (id) => {
  return axios.delete(`${CATALOGS_URL}/crud-edificios/${id}`);
};

const formatResponsables = (body) => {
  let formatedBody = { ...body };
  formatedBody = cleanObject(formatedBody);
  formatedBody = clearEmptyStringsObject(formatedBody);
  return formatedBody;
};

// Crud Responsables

export const getAllResponsables = () => {
  let url = `${CATALOGS_URL}/responsables-crud/?limit=5000`;
  return axios
    .get(url)
    .then(
      (res) =>
        res?.status &&
        res.status === 200 &&
        res.data.results.map((option) => ({
          value: option.idpersona,
          text: option.nombre,
        }))
    )
    .catch((err) => console.log("error" + err));
};
export const getResposableByName = async (name) => {
  console.log(name);
  let url = `${CATALOGS_URL}/personas-search?all_fields`;
  const body = {
    search_params: {
      nombre: `*${name.replaceAll(" ", "*")}*` || "",
    },
    all_fields: true,
  };
  return axios
    .post(url, body)
    .then(
      (res) =>
        res?.status &&
        res.status === 200 &&
        res.data.map((option) => ({
          value: option.id,
          text: option.nombre,
        }))
    )
    .catch((err) => console.log("error" + err));
};

export const crearResponsable = (body) => {
  return axios.post(`${CATALOGS_URL}/responsables-crud/`, formatResponsables(body));
};

export const editarResponsable = (id, body) => {
  return axios.put(`${CATALOGS_URL}/responsables-crud/${id}/`, formatResponsables(body));
};

export const eliminarResponsable = (id) => {
  return axios.delete(`${CATALOGS_URL}/responsables-crud/${id}`);
};

// Crud Marcas
export const crearMarca = (body) => {
  return axios.post(`${CATALOGS_URL}/autos/marcas`, body);
};

export const editarMarca = (id, body) => {
  return axios.put(`${CATALOGS_URL}/autos/marcas/${id}/`, body);
};

export const eliminarMarca = (id) => {
  return axios.delete(`${CATALOGS_URL}/autos/marcas/${id}`);
};

// Crud SubMarcas
export const crearSubmarca = (body) => {
  return axios.post(`${CATALOGS_URL}/autos/submarcas`, {
    ...body,
    marca: body.id_marca.id,
  });
};

export const editarSubmarca = (id, body) => {
  return axios.put(`${CATALOGS_URL}/autos/submarcas/${id}/`, {
    ...body,
    marca: body.id_marca.id,
  });
};

export const eliminarSubmarca = (id) => {
  return axios.delete(`${CATALOGS_URL}/autos/submarcas/${id}`);
};

export const getItemAndKey = (query) => {
  const url = `${CATALOG_CABMS_CLAVE_URL}/search/${query}`;
  return axios.get(url).then((res) => {
    return (
      res.status &&
      res.status === 200 &&
      res.data.map((option) => ({
        id: option.id,
        text: option.cf_denominacion,
        text2: getCABMSKey(option),
        cf_grupo: option.cf_grupo,
        cf_subgrupo: option.cf_subgrupo,
        cf_clase: option.cf_clase,
        cf_subclase: option.cf_subclase,
        cf_tipo: option.cf_tipo,
      }))
    );
  });
};

export const getContracts = (query) => {
  const url = `${ASSTETS_STOCK}/contratos/search?numero=${query}`;
  return axios.get(url).then((res) => {
    return res.data.map((contract) => ({
      id: contract.id,
      text: contract.contract_number,
      text2: "",
      contract_set: contract.contract_set,
      ...contract,
    }));
  });
};

export const addEntryFilePDF = (
  file = null,
  attach_id,
  title = "",
  attach_model = "asset_acquisition",
  field
) => {
  const body = {
    attach_id,
    attach_model,
    image_data: file,
    field,
    title,
  };

  const formData = new FormData();
  for (const name in body) {
    formData.append(name, body[name]);
  }
  return axios.post(UPLOAD_FILE_URL, formData);
};

export const deleteEntryFilePDF = (id) => {
  return axios.delete(`${UPLOAD_FILE_URL}/${id}`);
};

export const downloadFile = (url, name) => {
  return fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const uri = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = uri;
      // the filename you want
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(uri);
    })
    .catch(console.log);
};

export const getArticleFilter = (params = "", limit = PAGINATION_LIMIT) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.get(`${CATALOG_CABMS_URL}?${params}&limit=${limit}`);
    if (res?.status && res.status === 200) {
      resolve([
        res.data.results.map((option) => ({
          ...option,
          id: option.id,
          text: option.cf_denominacion,
        })),
        paginateBody(res),
        res.data.ids,
      ]);
    } else reject({ error: "error" });
  });
};

export const getCenterCostsFilter = (params = "", page = 1, limit = 10) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.get(
      `${CATALOGS_URL}/centros-costos?${params}&page=${page}&limit=${limit}`
    );
    if (res?.status && res.status === 200) {
      resolve([
        res.data.results.map((option) => ({
          ...option,
          id: option.id,
          text: option.cos_nombre,
        })),
        paginateBody(res, page),
      ]);
    } else reject({ error: "error" });
  });
};

export const getEdificiesFilter = (params = "", page = 1, limit = PAGINATION_LIMIT) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.get(
      `${CATALOGS_URL}/crud-edificios?${params}&page=${page}&limit=${limit}`
    );
    if (res?.status && res.status === 200) {
      resolve([
        res.data.results.map((option) => ({
          id: option.id,
          cve_edificio: option.cve_edificio,
          nom_edificio: option.nom_edificio,
          direccion: option.direccion,
          colonia: option.colonia,
          delegacion: option.delegacion,
          cp: option.cp,
          observ: option.observ,
          ciudad: option.ciudad,
          estado: option.estado,
        })),
        paginateBody(res, page),
        res.data.ids,
      ]);
    } else reject({ error: "Error" });
  });
};

export const getCABMSFilter = (params = "", page = 1, limit = PAGINATION_LIMIT) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.get(
      `${CATALOGS_URL}/cabms-5?${params}&page=${page}&limit=${limit}`
    );
    if (res?.status && res.status === 200) {
      resolve([
        res.data.results.map((option) => ({
          ...option,
          id: option.id,
          text: option.cf_denominacion,
        })),
        paginateBody(res, page),
        res.data.ids,
      ]);
    } else reject({ error: "Error" });
  });
};

export const getDropTypes = () => {
  return axios
    .get(`${INSTRUMENTAL_GOODS}/catalog-baja`)
    .then((res) =>
      res?.data?.results.map((dt) => ({
        value: dt.id,
        text: dt.nombre,
      }))
    )
    .catch((e) => e);
};

export const getPresentationsList = (page, limit) => {
  return axios
    .get(`${PRESENTATIONS_URL}?page=${page}&limit=${limit}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data?.results;
      } else throw new Error(res.statusText);
    })
    .catch((e) => e);
};

export const getVariantsList = (page, limit) => {
  return axios
    .get(`${VARIANTS_URL}?page=${page}&limit=${limit}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data?.results.map((x) => ({
          ...x,
          value: x.id,
          text: x.nombre,
        }));
      } else throw new Error(res.statusText);
    })
    .catch((e) => e);
};

export const getArtworksByInv = (title) => {
  return axios
    .get(`${CONSULTA_QR_ARTWORK}?&page=1&limit=20&cf_no_inventa=${title}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data?.results.map((x) => ({
          ...x,
          value: x.id,
          text: x.id?.toString(),
          text2: x.cf_titulo?.trim(),
        }));
      } else throw new Error(res.statusText);
    })
    .catch((e) => e);
};

export const getArtworkTypes = () => {
  return axios
    .get(`${CATALOGS_URL}/obras-de-arte/tipos?limit=500&page=1`)
    .then((res) => {
      if (res.status === 200) {
        return res.data?.results.map((x) => ({
          value: x.id,
          text: x.desc_tipoobra.trim(),
        }));
      } else throw new Error(res.statusText);
    })
    .catch((e) => e);
};

export const getGroups = () => {
  return axios
    .get(`${CATALOGS_URL}/grupos`)
    .then((response) => {
      if (response.status === 200) {
        return response.data?.results.map((result) => ({
          value: result.id_grupo,
          text: result.area_grupo,
        }));
      }
    })
    .catch((e) => console.log(e));
};

export const getSubGroups = (selectedGroup) => {
  return axios
    .get(`${CATALOGS_URL}/subgrupos/${selectedGroup}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data?.map((result) => ({
          value: result.id_subgrupo,
          text: result.area_subgrupo,
        }));
      }
    })
    .catch((e) => console.log(e));
};

export const getSubSubGroups = (selectedSubgroup, selected) => {
  return axios
    .get(`${CATALOGS_URL}/subsubgrupos/${selectedSubgroup}/${selected}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data?.map((result) => ({
          value: result.id_subsubgrupo,
          text: result.area_subsubgrupo,
        }));
      }
    })
    .catch((e) => console.log(e));
};

export const getPersons = (name) => {
  return axios
    .get(`${CATALOGS_URL}/personas?nombre=${name}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data?.map((result) => ({
          id: result.idpersona,
          text: result.nombre.trim(),
        }));
      }
    })
    .catch((e) => console.log(e));
};

export const getEdificios = (name) => {
  return axios
    .get(`${CATALOGS_URL}/edificios?edificio=${name}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data?.map((result) => ({
          id: result.cve_edificio,
          text: result.nom_edificio.trim(),
        }));
      }
    })
    .catch((e) => console.log(e));
};

export const getMarcaByName = (name) => {
  return axios
    .get(`${CATALOGS_URL}/autos/marcas/buscar?nombre=${name}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data?.map((result) => ({
          id: result.id,
          text: result.desc_marca.trim(),
        }));
      }
    })
    .catch((e) => console.log(e));
};

export const getVehicleBrandFilter = (
  params = "",
  page = 1,
  limit = PAGINATION_LIMIT
) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.get(
      `${CATALOGS_URL}/autos/marcas?${params}&page=${page}&limit=${limit}`
    );
    if (res?.status && res.status === 200) {
      resolve([
        res.data.results.map((option) => ({
          id: option.id,
          text: option.desc_marca.trim(),
          desc_marca: option.desc_marca.trim(),
        })),
        paginateBody(res),
        res.data.ids,
      ]);
    } else reject({ error: "Error" });
  });
};

export const getVehicleSubBrandFilter = (
  params = "",
  page = 1,
  limit = PAGINATION_LIMIT
) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.get(
      `${CATALOGS_URL}/autos/submarcas?${params}&page=${page}&limit=${limit}`
    );
    if (res?.status && res.status === 200) {
      resolve([
        res.data.results.map((option) => ({
          id: option.id,
          desc_smarca: option.desc_smarca,
          id_marca: {
            id: option.marca.id,
            text: option.marca.desc_marca,
          },
        })),
        paginateBody(res, page),
        res.data.ids,
      ]);
    } else reject({ error: "Error" });
  });
};
export const getResponsableFilterID = (
  params = "",
  page = 1,
  limit = PAGINATION_LIMIT
) => {
  let searchParams = {};
  params.split("&").map((areaParam) => {
    let [keyParam, valueParam] = areaParam.split("=");
    searchParams[keyParam] = valueParam;
  });
  let filterParams = {
    search_params: searchParams,
  };

  return new Promise(async (resolve, reject) => {
    const ids = await axios.post(`${CATALOGS_URL}/personas-search`, filterParams);
    if (ids.data.length > 0) {
      const idData = await axios.get(
        `${CATALOGS_URL}/responsables-crud/${ids.data[page - 1].id}`
      );

      let res = {
        data: {
          count: ids.data.length,
          ids: ids.data.map((idElment) => idElment.id),
          per_page: 1,
          results: [idData.data],
          total_pages: ids.data.length,
        },
        status: 200,
      };
      if (res.status && res.status === 200) {
        const resultsData = [
          res.data.results.map((option) => ({
            all_attachments: option.all_attachments,
            attachment_pdfs: option.attachment_pdfs,
            num_empleado: option.num_empleado,
            nombre: option.nombre,
            rfc: option.rfc,
            curp: option.curp,
            fenacim: option.fenacim,
            genero: option.genero,
            adscripcion: option.adscripcion,
            tiponomina: option.tiponomina,
            id: option?.id ?? uuid(),
          })),
          paginateBody(res, page),
          res.data.ids,
        ];
        resolve(resultsData);
      } else reject({ error: "Error" });
    } else {
      if (ids.data.length === 0) {
        reject({ error: "warning" });
      } else {
        reject({ error: "Error" });
      }
    }
  });
};

// export const getResponsibleFilter = (params = "", page = 1, limit = PAGINATION_LIMIT) => {
//   return new Promise(async (resolve, reject) => {
//     const res = await axios.get(
//       `${CATALOGS_URL}/responsables-crud?${params}&page=${page}&limit=${limit}`
//     );
//     if (res?.status === 200) {
//       resolve([
//         res.data.results.map((option) => ({
//           num_empleado: option.num_empleado,
//           nombre: option.nombre,
//           rfc: option.rfc,
//           curp: option.curp,
//           fenacim: option.fenacim,
//           genero: option.genero,
//           adscripcion: option.adscripcion,
//           tiponomina: option.tiponomina,
//           //@formatter:off
//           id: option?.id ?? uuid(),
//           //@formatter:onn
//         })),
//         paginateBody(res, page),
//         res.data.ids,
//       ]);
//     } else reject({ error: "Error" });
//   });
// };

export const getAlmacenes = () => {
  return axios.get(`${CATALOGS_URL}/almacenes?limit=100`).then((response) => {
    if (response.status === 200) {
      return response.data?.results?.map((result) => ({
        ...result,
        value: result.id,
        text: result.cf_nombre?.trim(),
      }));
    }
  });
};

export const getSecciones = (no_almacen = 0) => {
  return axios
    .get(`${CATALOGS_URL}/secciones?limit=100&num_almacen=${no_almacen}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data?.results?.map((result) => ({
          ...result,
          id: result.num_seccion,
          value: result.num_seccion,
          text: result.nom_seccion?.trim(),
        }));
      }
    })
    .catch((e) => console.log(e));
};

export const getCostCenterList = (params = "", page = 1, limit = PAGINATION_LIMIT) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.get(
      `${CATALOGS_URL}/almacen-centro-costos?${params}&page=${page}&limit=${limit}`
    );
    if (res?.status && res.status === 200) {
      resolve([res.data.results, paginateBody(res, page)]);
    } else reject({ error: "Error" });
  });
};

export const getSecondmentsList = (params = "", page = 1, limit = PAGINATION_LIMIT) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.get(
      `${CATALOGS_URL}/almacen-adscripciones?${params}&page=${page}&limit=${limit}`
    );
    if (res?.status && res.status === 200) {
      resolve([
        res.data.results.map((item) => ({
          ...item,
          persona: item.cf_idpersona?.cf_nombre_com,
        })),
        paginateBody(res, page),
      ]);
    } else reject({ error: "Error" });
  });
};
export const getSupplierPDF = (type = "") => {
  return axios.post(`${INSTRUMENTAL_GOODS}/reports/catalogs/proveedor/pdf`, {
    formato: type,
  });
};
export const getSupplierExcel = (type = "") => {
  return axios.post(`${INSTRUMENTAL_GOODS}/reports/catalogs/proveedor/`, {
    formato: type,
  });
};
export const getMultiplePDF = (ids = [], year) => {
  return axios.post(`${INSTRUMENTAL_GOODS}/reports/catalogs/areas/pdf-multiple`, {
    ids: ids,
    anio_resguardo: year,
  });
};
export const getCostCenterReport = (type = "") => {
  return axios.post(`${ASSTETS_STOCK}/centro-de-costos`, {
    formato: type,
  });
};

export const getSecondmentReport = (type = "") => {
  return axios.post(`${ASSTETS_STOCK}/reporte-adscripciones`, {
    formato: type,
  });
};

export const downloadCatalogReport = (report, ids) => {
  if (report === "partida") {
    return axios.post(`${CATALOGS_URL}/${report}/pdf`, {
      ids,
    });
  } else {
    return axios.post(`${INSTRUMENTAL_GOODS}/reports/catalogs/${report}/`, {
      ids,
    });
  }
};

export const downloadCatalogPdfReport = (section, ids) => {
  return axios.post(`${INSTRUMENTAL_GOODS}/reports/catalogs/${section}/pdf`, {
    ids,
  });
};

export const getOperativeUsersCatalogs = () => {
  return axios
    .get(`${OPERATIVE_USERS_URL}?limit=500`)
    .then((response) => {
      if (response.status === 200) {
        return response.data?.results?.map((result) => ({
          id: result.id,
          value: result.id,
          text: result.first_name
            ? `${result.first_name} ${result.last_name}`
            : result.username,
        }));
      }
    })
    .catch((e) => console.log(e));
};

export const getResponsibles = (query) => {
  return fetch(`${CATALOGS_URL}/responsables?nombre=${query}`, getParams("GET"))
    .then(handleResponse)
    .then((res) =>
      res.results.map((x) => ({
        value: x.persona?.id || x.num_empleado,
        text: x.persona?.nombre || x.num_empleado,
      }))
    )
    .catch(handleError);
};

export const getFolios = () => {
  return fetch(TASK_MANAGEMENT_FOLIOS, getParams("GET"))
    .then(handleResponse)
    .then((res) => res.results.map((x) => ({ value: x.id, text: x.name })))
    .catch(handleError);
};

export const getShifts = () => {
  return fetch(TASK_MANAGEMENT_SHIFTS, getParams("GET"))
    .then(handleResponse)
    .then((res) => res.results.map((x) => ({ value: x.id, text: x.name })))
    .catch(handleError);
};

export const getPriorities = () => {
  return fetch(TASK_MANAGEMENT_PRIORITIES, getParams("GET"))
    .then(handleResponse)
    .then((res) => res.results.map((x) => ({ value: x.id, text: x.name })))
    .catch(handleError);
};

export const getOtherDocuments = () => {
  return fetch(`${TASK_MANAGEMENT}/other-documents/`, getParams("GET"))
    .then(handleResponse)
    .then((res) => res.results.map((x) => ({ value: x.id, text: x.name })))
    .catch(handleError);
};

export const getFolderTypes = () => {
  return fetch(`${DIGITAL_VAULT_URL}/folders-type/`, getParams("GET"))
    .then(handleResponse)
    .then((res) => res.results.map((x) => ({ value: x.id, text: x.name })))
    .catch(handleError);
};

export const supplierCRUD = {
  getFilterProveedoresId: (params = "", page = 1, limit) => {
    let searchParams = {};
    params.split("&").map((areaParam) => {
      let [keyParam, valueParam] = areaParam.split("=");
      searchParams[keyParam] = valueParam;
    });
    let filterParams = {
      search_params: searchParams,
    };

    return new Promise(async (resolve, reject) => {
      const ids = await axios.post(`${CATALOGS_URL}/proveedores-search`, filterParams);
      if (ids?.data?.length > 0) {
        const idData = await axios.get(
          `${CATALOGS_URL}/proveedores-instrumental/${ids.data[page - 1].id}`
        );
        let res = {
          data: {
            count: ids.data.length,
            ids: ids.data.map((idElment) => idElment.id),
            per_page: 1,
            results: [idData.data],
            total_pages: ids.data.length,
          },
          status: 200,
        };
        if (res.status && res.status === 200) {
          const resultsData = [
            res.data.results.map((option) => ({
              num_prove: option.num_prove,
              numrefrendo: option.numrefrendo,
              razon_social: option.razon_social,
              representante: option.representante,
              domicilio: option.domicilio, // direccion completa
              dom_prov: option.dom_prov, // CALLE
              colonia_prov: option.colonia_prov, // COLONIA
              cp_prov: option.cp_prov, // CP
              telefonos: option.telefonos,
              celular: option.celular,
              mailprov: option.mailprov,
              nota: option.nota,
              fecact: option.fecact,
              fecalta: option.fecalta,
              id: option.id,
            })),
            paginateBody(res, page),
            res.data.ids,
          ];
          resolve(resultsData);
        } else {
          reject({ error: "Error" });
        }
      } else {
        if (ids.data.length === 0) {
          reject({ error: "warning" });
        } else {
          reject({ error: "Error" });
        }
      }
    });
  },
  // fetchSupplierList: (params = "", page, limit) => {
  //   return new Promise(async (resolve, reject) => {
  //     const res = await axios.get(
  //       `${SUPPLIER_URL}-catalogo?page=${page}&limit=${limit}&${params}`
  //     );
  //     if (res?.status && res.status === 200) {
  //       resolve([
  //         res.data.results.map((option) => ({
  //           num_prove: option.num_prove,
  //           numrefrendo: option.numrefrendo,
  //           razon_social: option.razon_social,
  //           representante: option.representante,
  //           domicilio: option.domicilio, // direccion completa
  //           dom_prov: option.dom_prov, // CALLE
  //           colonia_prov: option.colonia_prov, // COLONIA
  //           cp_prov: option.cp_prov, // CP
  //           telefonos: option.telefonos,
  //           celular: option.celular,
  //           mailprov: option.mailprov,
  //           nota: option.nota,
  //           fecact: option.fecact,
  //           fecalta: option.fecalta,
  //           id: option.id,
  //         })),
  //         paginateBody(res, page),
  //         res.data.ids,
  //       ]);
  //     } else reject({ error: "Error" });
  //   });
  // },
  createSupplier: (data) => {
    return axios.post(`${SUPPLIER_URL}-instrumental`, data);
  },
  updateSupplier: (id, data) => {
    return axios.put(`${SUPPLIER_URL}-instrumental/${id}/`, data);
  },
  deleteSupplier: (id) => {
    return axios.delete(`${SUPPLIER_URL}-instrumental/${id}`);
  },
};

export const partidaCrud = {
  getFilterPartida: (params = "", page = 1, limit = PAGINATION_LIMIT) => {
    return new Promise(async (resolve, reject) => {
      const res = await axios.get(`${PARTIDA_URL}/?${params}&page=${page}&limit=5`);
      if (res?.status && res.status === 200) {
        resolve([res.data.results, paginateBody(res, page), res.data.ids]);
      } else reject({ error: "Error" });
    });
  },

  createPartida: async (data) => {
    return axios.post(`${PARTIDA_URL}/`, data);
  },
  updatePartida: async (id, data) => {
    return axios.put(`${PARTIDA_URL}/${id}/`, data);
  },
  deletePartida: (id) => {
    return axios.delete(`${PARTIDA_URL}/${id}/`);
  },
};
export const signatureCRUD = {
  fetchSigntures: (params, page = 1, limit = PAGINATION_LIMIT) => {
    return new Promise(async (resolve, reject) => {
      const res = await axios.get(
        `${SIGNATURE_URL}/?${params}&page=${page}&limit=${limit}`
      );
      if (res?.status && res.status === 200) {
        resolve([
          res.data.results.map((option) => ({
            ...option,
            area: option.area,
            titular: option.area_titular,
            adscripcion: option.area_adscripcion,
            firma_pdf: option.attachment?.url,
          })),
          paginateBody(res, page),
          res.data.ids,
        ]);
      } else reject({ error: "Error" });
    });
  },
  createSignature: async (data) => {
    const file = data.firma_pdf ? await getBase64(data.firma_pdf) : "";
    let payload = {
      clave_firma: data.clave_firma,
      area: data.area,
      titular: data.titular,
      prefijo: data.prefijo,
      estatus: data.estatus,
      nombre_firma_1: data.nombre_firma_1,
      nombre_firma_2: data.nombre_firma_2,
      nombre_firma_3: data.nombre_firma_3,
      firma_pdf: file?.replace("data:application/pdf;base64,", ""),
    };
    if (data.fecha_actualizacion_firma)
      payload = {
        ...payload,
        fecha_actualizacion_firma: data.fecha_actualizacion_firma,
      };

    return fetch(`${SIGNATURE_URL}/`, getParams("POST", payload))
      .then(handleResponse)
      .then((res) => ({ data: res }))
      .catch(handleError);
  },
  updateSignature: async (id, data) => {
    const file =
      data.firma_pdf instanceof File ? await getBase64(data.firma_pdf) : data.firma_pdf;
    let payload = {
      clave_firma: data.clave_firma,
      area: data.area,
      titular: data.titular,
      prefijo: data.prefijo,
      estatus: data.estatus,
      nombre_firma_1: data.nombre_firma_1,
      nombre_firma_2: data.nombre_firma_2,
      nombre_firma_3: data.nombre_firma_3,
      firma_pdf: file?.replace("data:application/pdf;base64,", ""),
    };
    if (data.fecha_actualizacion_firma)
      payload = {
        ...payload,
        fecha_actualizacion_firma: data.fecha_actualizacion_firma,
      };

    return fetch(`${SIGNATURE_URL}/${id}/`, getParams("PUT", payload))
      .then(handleResponse)
      .then((res) => ({ data: res }))
      .catch(handleError);
  },
  deleteSignature: (id) => {
    return axios.delete(`${SIGNATURE_URL}/${id}`);
  },

  bajaFunction: async (id) => {
    const payload = { estatus: false };
    return fetch(`${SIGNATURE_URL}/${id}/`, getParams("PATCH", payload))
      .then(handleResponse)
      .catch(handleError);
  },
};
