/* eslint-disable no-restricted-globals */
import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

/* eslint-disable react-hooks/exhaustive-deps */

//Assets

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Toolbar
            style={{
              color: "#FFF",
              backgroundColor: "transparent",
            }}
          >
            <Box>
              <img
                src={toAbsoluteUrl("/media/logos/logo-senado.png")}
                alt="logo"
                style={{
                  width: 100,
                  cursor: "pointer",
                }}
                onClick={() => window.location.assign("/")}
              />
            </Box>
          </Toolbar>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "70vh",
              overflow: "auto",
              marginTop: "20px",
            }}
          >
            <Container
              style={{
                maxWidth: "100%",
              }}
              sx={{ mt: 15, mb: 4 }}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box
                  display="flex"
                  width="100%"
                  border="1px solid #E8E8E8"
                  borderRadius="4px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    padding="50px 150px"
                    textAlign="center"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="h3"
                      sx={{ marginTop: 10, fontWeight: "bold" }}
                      fontSize={2}
                      fontWeight="bold"
                    >
                      {" "}
                      SISTEMA DE CONTROL DE BIENES INSTRUMENTALES E INVENTARIO
                      DE BIENES CONTROLADOS POR EL ALMACÉN GENERAL
                    </Typography>
                    <br />
                    <img
                      src={toAbsoluteUrl("/media/logos/logo-senado.png")}
                      alt="error-img"
                      style={{
                        width: "15%",
                        cursor: "pointer",
                      }}
                    />
                    <br />
                    <Typography
                      variant="h5"
                      sx={{
                        color: "secondary.light",
                        fontSize: 10,
                        marginTop: "100px",
                        margin: "10px 0px",
                      }}
                    >
                      Ha ocurrido un error, da clic en el botón de regresar.
                    </Typography>
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => window.location.reload()}
                    >
                      Regresar
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
        </div>
      );
    }

    return this.props.children;
  }
}
