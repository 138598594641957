import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setLoader } from "../../../../redux/ducks/loader.duck";
import BaseModal from "../../../components/BaseModal";
import FileButton from "../../../components/FileButton";
import notice from "../../../components/Notice";
import UtilInputs from "../../../components/UtilInputs";
import AttachmentsList from "../../../components/AttachmentsList";
import { createTask } from "../../../crud/taskManagementCrud";
import { getOperativeUsers } from "../../../crud/usersCrud";
import { defaultTask, taskForm } from "../utils";

const ToDoNewTaskModal = ({ open, setOpen, fetchTasksByBoard }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [task, setTask] = useState(defaultTask);
  const [operativeUsers, setOperativeUser] = useState([]);

  const handleChange = ({ target }) =>
    setTask({ ...task, [target.name]: target.value });

  const handleAttachments = (files) => setTask({ ...task, attachments: files });

  const handleRemoveAttachment = (index) => {
    const updatedAtt = task.attachments;
    updatedAtt.splice(index, 1);
    setTask({ ...task, attachments: updatedAtt });
  };

  const formatInChargeArea = (area) => {
    switch (area) {
      case "Inventarios":
        return "INSTRUMENTAL";
      case "Almacen":
        return "ALMACEN";
      default:
        return;
    }
  };

  useEffect(() => {
    getOperativeUsers(formatInChargeArea(task.in_charge_area))
      .then((res) =>
        setOperativeUser(
          res.results.map((user) => ({
            value: user.id,
            text: `${user.first_name} ${user.last_name}`,
          }))
        )
      )
      .catch(console.log);
  }, [task.in_charge_area]);

  const handleCreateTask = () => {
    dispatch(setLoader(true));
    createTask({
      ...task,
      users: task.users.map((us) => us.value),
      users_collaborators: task.users_collaborators.map((us) => us.value),
      board: id,
    })
      .then((res) => {
        setOpen(false);
        fetchTasksByBoard();
        setTask(defaultTask);
        notice("Tarea creada", "success");
      })
      .catch((e) => {
        if (
          e?.users?.length > 0 &&
          e.users[0] === "Esta lista no puede estar vacía."
        ) {
          return notice("Debes agregar responsable(s)", "warning");
        }
        notice("Ha ocurrido un error con su solicitud");
      })
      .finally(() => dispatch(setLoader(false)));
  };

  const handleCancelTask = () => {
    setOpen(false);
    setTask(defaultTask);
  };

  return (
    <BaseModal
      maxWidth="md"
      title="Nueva tarea"
      open={open}
      onClose={handleCancelTask}
      onCancel={handleCancelTask}
      onConfirm={handleCreateTask}
      disableOnConfirm={
        !task.name ||
        !task.folio ||
        !task.folio_number ||
        !task.reception_date ||
        !task.request_area ||
        !task.signed ||
        !task.shift ||
        !task.users ||
        !task.priority ||
        !task.date_input
      }
    >
      <UtilInputs
        formValues={task}
        onValuesChange={handleChange}
        inputValues={taskForm(operativeUsers)}
      />
      <br />
      <div className="d-flex mb-2 mb-5">
        <FileButton
          multiple
          accept="*"
          buttonTitle="Cargar archivos"
          handleChange={handleAttachments}
        />
      </div>
      <div className="d-flex mb-5">
        <AttachmentsList
          attachments={task.attachments}
          onClick={(e, i) => handleRemoveAttachment(i)}
        />
      </div>
    </BaseModal>
  );
};

export default ToDoNewTaskModal;
