import axios from "axios";
import { GROUPS_URL } from "./helpers/routes";

export const getGroups = (page = 1, limit = 100) => {
  return axios.get(`${GROUPS_URL}?page=${page}&limit=${limit}`);
};

export const getGroup = (id) => {
  return axios.get(`${GROUPS_URL}/${id}`);
};

export const createGroup = (data) => {
  return axios.post(GROUPS_URL, data);
}

export const updateGroup = (data) => {
  return axios.put(`${GROUPS_URL}/${data.id}`, data);
}

export const deeleteGroup = (id) => {
  return axios.delete(`${GROUPS_URL}/${id}`);
}