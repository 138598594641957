import { CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { setClearState, setGroup } from '../../../../redux/ducks/groups.duck'
import { setPermits } from '../../../../redux/ducks/permits.duck'
import notice from '../../../components/Notice'
import PageLayout from '../../../components/PageLayout'
import SimpleCheckbox from '../../../components/SimpleCheckbox'
import TabsButton from '../../../components/TabsButton'
import UtilInputs from '../../../components/UtilInputs'
import { getGroup } from '../../../crud/groupsCrud'
import { getPermissions } from '../../../crud/permitsCrud'
import { groupsForm } from '../../../utils/configData'

const ConfigGroupDetail = () => {
  const { group, selectedGroups } = useSelector(state => state.groups)
  const { results } = useSelector(state => state.permits.permits)
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setLoader(true)
    getPermissions()
    .then(res => {
      if (res.status === 200)
        dispatch(setPermits(res.data))
    })
    .catch(e => {
      console.log(e)
      notice("No se pudo cargar la lista de permisos")
    }).finally(() => setLoader(false))
  }, [dispatch])

  const onValuesChange = (e) => {
    if(e && e.target) {
        const { value, name } = e.target;
        
        dispatch(setGroup({...group, [name]: value}))
    };
  };

  const handlePermissions = ({ target }) => {
    const { name } = target
    let newPermissions = []
    if (group.permissions.find(x => Number(x) === Number(name)))
      newPermissions = group.permissions.filter(x => Number(x) !== Number(name))
    
    else newPermissions = [...group.permissions, Number(name)]
    
    onValuesChange({target: {name: "permissions", value: newPermissions}})
  }

  const handleClickTab = (id) => {
    getGroup(id)
      .then(res => {
        if (res.status === 200) {
          dispatch(setGroup(res.data))
          history.push(`/inventario-bienes-almacen-general/configuracion/usuarios/grupos/resultados/${id}`)
        }
      }).catch(e => {
        dispatch(setClearState())
        notice("Error al mostrar el grupo")
        history.push('/inventario-bienes-almacen-general/configuracion/usuarios/grupos/nuevo')
      })
  }

  return (
    <PageLayout
      minHeight="100%"
      title={id ? "Grupo" : "Nuevo Grupo"}
      header={
        id && selectedGroups.length > 0 && <TabsButton
          list={selectedGroups} selected={id} handleClickTab={handleClickTab}
        />
      }
    >
      <UtilInputs
        inputValues={groupsForm}
        formValues={group}
        onValuesChange={onValuesChange}
      />
      <h6 className="text-muted mt-5">Selección de permisos</h6>
      {loader
      ? <div style={{ height: 600 }} className="d-flex justify-content-center align-items-center">
          <CircularProgress />
        </div>
        :  <div style={{ maxHeight: 600, overflow: "auto" }}>
          {results.map(permit => (
            <div key={permit.id}>
              <SimpleCheckbox
                onChange={handlePermissions}
                value={Boolean(group?.permissions?.find(x => Number(x) === (permit.id)))}
                label={`${permit.codename}/${permit.name}`}
                name={`${permit.id}`}
                labelPos= "end"
              />
              <br />
            </div>))}
        </div>}
    </PageLayout>
  )
}

export default ConfigGroupDetail