import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const actionType = {
  SetActionForm: "[CONCENTRATES] SET SetActionForm",
  SetIdConcentrate: "[CONCENTRATES] SET SetIdConcentrate",
  SetConcentrateSelected: "[CONCENTRATES] SET SetConcentrateSelected",
  SetListEntriesForConcentrate:
    "[CONCENTRATES] SET SetListEntriesForConcentrate",
  ListConcentrates: "[CONCENTRATES] SET ListConcentrates",
  SetInitialState: "[CONCENTRATES] SET SetInitialState",
  SetCancel_New_Edit: "[CONCENTRATES] SER SetCancel_New_Edit",
};

//  ActionForm: 'isNew'| 'isEdit' | '';

const initialState = {
  actionForm: "",
  idConcentrate: -1,
  listEntriesForConcentrate: [],
  listConcentrates: [],
  concentrateSelected: undefined,
};

export const reducer = persistReducer(
  { storage, key: "entranceConcentrate", whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetConcentrateSelected:
        return { ...state, concentrateSelected: action.payload };
      case actionType.SetActionForm:
        return { ...state, actionForm: action.payload };
      case actionType.SetIdConcentrate:
        return {
          ...state,
          idConcentrate: action.payload,
        };
      case actionType.SetListEntriesForConcentrate:
        return {
          ...state,
          listEntriesForConcentrate: action.payload,
        };
      case actionType.ListConcentrates:
        return {
          ...state,
          listConcentrates: action.payload,
        };
      case actionType.SetInitialState:
        return { ...initialState };
      case actionType.SetCancel_New_Edit:
        return {
          ...state,
          actionForm: "",
          idConcentrate: -1,
          listEntriesForConcentrate: [],
        };
      default:
        return state;
    }
  }
);

export const SetConcentrateSelected = (action) => ({
  type: actionType.SetConcentrateSelected,
  payload: action,
});
export const SetActionForm = (action) => ({
  type: actionType.SetActionForm,
  payload: action,
});
export const SetIdConcentrate = (idConcentrate) => ({
  type: actionType.SetIdConcentrate,
  payload: idConcentrate,
});
export const SetListEntriesForConcentrate = (data) => ({
  type: actionType.SetListEntriesForConcentrate,
  payload: data,
});
export const ListConcentrates = (data) => ({
  type: actionType.ListConcentrates,
  payload: data,
});
export const SetInitialState = () => ({ type: actionType.SetInitialState });
export const SetCancel_New_Edit = () => ({
  type: actionType.SetCancel_New_Edit,
});
