import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultGroup } from "../../app/utils/configData"

const actionType = {
  SetGroups: '[CONFIG] SET_GROUPS',
  SetGroup: '[CONFIG] SET_GROUP',
  SetSelectedGroups: '[CONFIG] SET_SELECTED_GROUPS',
  ClearState: '[CONFIG] CLEAR_STATE',
};

const initialState = {
  groups: {
    results: [],
    count: 0
  },
  group: defaultGroup,
  selectedGroups: [],
};

export const reducer = persistReducer(
  { storage, key: 'config', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetGroups:
        return { ...state, groups: action.payload }
      
      case actionType.SetGroup:
        return { ...state, group: action.payload }
      
      case actionType.SetSelectedGroups:
        return { ...state, selectedGroups: action.payload }

      case actionType.ClearState:
        return state = initialState
      
      default:
        return state
    }
  }
)

export const setGroups = data => ({ type: actionType.SetGroups, payload: data })

export const setGroup = data => ({ type: actionType.SetGroup, payload: data })

export const setSelectedGroups = data => ({ type: actionType.SetSelectedGroups, payload: data })

export const setClearState = data => ({ type: actionType.ClearState, payload: data })
