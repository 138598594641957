import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory  } from 'react-router-dom'
import { setPermit } from '../../redux/ducks/permits.duck'
import { createtPermission, getSinglePermission, updatePermission } from '../crud/permitsCrud'
import { defaultPermit } from '../utils/configData'
import EntryActionIcons from './EntryActionIcons'
import ModeTabs from './ModeTabs'
import notice from './Notice'

const ConfigPermitsActions = () => {
  const { permit, selectedPermits } = useSelector(state => state.permits)
  const history = useHistory()
  const dispatch = useDispatch()
  const [actionDisable, setActionDisable] = useState(false)

  const validations = () => {
    if (!permit.name) notice("Es necesario ingresar un nombre al permiso")
    if (!permit.codename) notice("Es necesario ingresar un codigo de nombre al permiso")
    else if (!permit.description) notice("Es necesario ingresar una descripción al permiso")
    else return true
  }

  const handleSubmit = (requestFunciton) => {
    if (validations()) {
      setActionDisable(true)
      requestFunciton(permit)
      .then(res => {
        if (res.status >= 200 && res.status < 300){
          console.log(res)
          history.push("/inventario-bienes-almacen-general/configuracion/usuarios/permisos")
        }
      })
      .catch(e => notice("Error al guardar el permiso"))
      .finally(() => setActionDisable(false))
    }
  }

  const handleResults = () => {
    if (permit.id) {
      history.push(`/inventario-bienes-almacen-general/configuracion/usuarios/permisos/resultados/${permit.id}`)
    } else if (selectedPermits[0]) {
      getSinglePermission(selectedPermits[0])
      .then(res => {
        if (res.status === 200){
          dispatch(setPermit((res.data)))
          history.push(`/inventario-bienes-almacen-general/configuracion/usuarios/permisos/resultados/${res.id}`)
        }
      })
      .catch(console.log)
    } else {
      notice("No se encontraron registros guardados", "info")
      onNewRegister()
    }
  }

  const onNewRegister = () => {
    history.push("/inventario-bienes-almacen-general/configuracion/usuarios/permisos/nuevo")
    if (permit.id) dispatch(setPermit((defaultPermit)));
  };

  return (
    <>
      <ModeTabs onNew={onNewRegister} onResults={handleResults} showIcons={{search: false, results: true, newIcon: true}} />
      <EntryActionIcons
        showIcons={{
          save: !permit.id,
          edit: permit.id,
        }}
        handleSubmit={() => handleSubmit(createtPermission)}
        handleEdit={() => handleSubmit(updatePermission)}
        disabledIcons={{
          save: actionDisable,
          edit: actionDisable,
        }}
      /> 
    </>
  )
}

export default ConfigPermitsActions
