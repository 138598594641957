import React from "react";
import {
  Grid,
  withStyles,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import useWidth from "../utils/useWidth";
import styles from "../modules/QRView/style";

const QRView = ({ classes, qr }) => {
  const width = useWidth();
  return (
    <Grid container className={classes.container}>
      <Grid item container md={12} xs={12} className={classes.subContainer}>
        <Grid item container justify={"center"} alignItems={"flex-start"} md={3}>
          <img src="/media/logos/logo-senado.png" className={classes.logo} alt="" />
        </Grid>
        <Grid item md={3} />
        <Grid
          item
          md={6}
          container
          direction={"column"}
          alignItems={"center"}
          className={classes.paperHeader}
        >
          <Grid item>
            <Typography
              variant={width === "sm" ? "h6" : width === "xs" ? "h6" : "h4"}
              className={classes.headerFont}
            >
              Este bien es propiedad del
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={width === "sm" ? "h3" : width === "xs" ? "h4" : "h3"}
              className={classes.headerFont}
            >
              Senado
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={width === "sm" ? "h3" : width === "xs" ? "h4" : "h3"}
              className={classes.headerFont}
            >
              de la República
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container md={12} justify={"center"} className={classes.subContainer}>
        <Grid item md={10} sm={10} xs={10}>
          <Grid className={classes.paper}>
            {qr?.inventory ? (
              <Grid item container md={12} justify={"center"}>
                <Grid
                  item
                  md={10}
                  container
                  alignItems={"center"}
                  direction={"column"}
                  className={classes.grid}
                >
                  <Grid item>
                    <Grid item>
                      <Typography variant={"h4"}>Artículo</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={"h6"} align={"center"} className="text-muted">
                        {qr.cf_articulo ? qr.cf_articulo : "Sin artículo"}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/**/}
                  <Grid item>
                    <Grid item>
                      <Typography variant={"h4"}>No. de Inventario</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={"h6"} align={"center"} className="text-muted">
                        {qr?.inventory}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* Ernesto asked to hide responsable value 31/may/21 */}
                  {qr.responsables && (
                    <>
                      <Grid item>
                        <Grid item>
                          <Typography variant={"h4"}>
                            {qr.responsables.length > 1 ? "Responsables" : "Responsable"}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <List component="nav" aria-label="main mailbox folders">
                            {qr.responsables.map((responsable, key) => (
                              <ListItem>
                                <ListItemText
                                  primary={
                                    <Typography
                                      item={key}
                                      variant={"h6"}
                                      align={"center"}
                                      className="text-muted"
                                    >
                                      {responsable.nombre}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Grid item>
                    <Grid item>
                      <Typography variant={"h4"}>Clave de Área</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={"h6"} align={"center"} className="text-muted">
                        {qr?.cve_area}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item>
                      <Typography variant={"h4"}>Área</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={"h6"} align={"center"} className="text-muted">
                        {qr?.area}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item>
                      <Typography variant={"h4"}>Subárea</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={"h6"} align={"center"} className="text-muted">
                        {qr?.sarea}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item>
                      <Typography variant={"h4"}>Sub-subárea</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={"h6"} align={"center"} className="text-muted">
                        {qr?.sssarea}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item>
                      <Typography variant={"h4"}>Edificio</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={"h6"} align={"center"} className="text-muted">
                        {qr?.edificio}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div style={{ height: "20px" }} />
                </Grid>
                <Grid
                  item
                  md={12}
                  container
                  alignItems={"center"}
                  direction={"column"}
                  className="text-muted"
                >
                  <Grid item>
                    <Typography
                      variant={width === "sm" ? "h3" : width === "xs" ? "h5" : "h3"}
                    >
                      {qr?.entrada ? `${qr.entrada}-` : ""}
                      {qr?.cabms ? `${qr.cabms}-` : ""}
                      {qr?.ingreso ? `${qr.ingreso}` : ""}
                    </Typography>
                  </Grid>
                  <Grid item className="mt-5">
                    <Typography
                      variant={width === "sm" ? "h3" : width === "xs" ? "h5" : "h3"}
                      style={{ fontWeight: "bold" }}
                    >
                      {qr?.partida}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item container md={12} justify={"center"}>
                <Typography variant={"h5"}>No data</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <div style={{ height: "50px" }} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(QRView);
