import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setGroup } from '../../redux/ducks/groups.duck'
import { createGroup, getGroup, updateGroup } from '../crud/groupsCrud'
import { defaultGroup } from '../utils/configData'
import EntryActionIcons from './EntryActionIcons'
import ModeTabs from './ModeTabs'
import notice from './Notice'

const ConfigGroupActions = () => {
  const { group, selectedGroups } = useSelector(state => state.groups)
  const history = useHistory()
  const dispatch = useDispatch()
  const [actionDisable, setActionDisable] = useState(false)

  const validations = () => {
    if (!group.name) notice("Es necesario ingresar un nombre al grupo")
    else if (!group.description) notice("Es necesario ingresar una descripción al grupo")
    else if (group.permissions.length === 0) notice("Es necesario agregar por lo menos un permiso")
    else return true
  }

  const handleSubmit = (requestFunciton) => {
    if (validations()) {
      setActionDisable(true)
      requestFunciton(group)
      .then(res => {
        if (res.status >= 200 && res.status < 300){
          console.log(res)
          history.push("/inventario-bienes-almacen-general/configuracion/usuarios/grupos")
        }
      })
      .catch(e => notice("Error al guardar el grupo"))
      .finally(() => setActionDisable(false))
    }
  }

  const handleResults = () => {
    if (group.id) {
      history.push(`/inventario-bienes-almacen-general/configuracion/usuarios/grupos/resultados/${group.id}`)
    } else if (selectedGroups[0]) {
      getGroup(selectedGroups[0])
      .then(res => {
        if (res.status === 200){
          dispatch(setGroup((res.data)))
          history.push(`/inventario-bienes-almacen-general/configuracion/usuarios/grupos/resultados/${res.id}`)
        }
      })
      .catch(console.log)
    } else {
      notice("No se encontraron registros guardados", "info")
      onNewRegister()
    }
  }

  const onNewRegister = () => {
    history.push("/inventario-bienes-almacen-general/configuracion/usuarios/grupos/nuevo")
    if (group.id) dispatch(setGroup((defaultGroup)));
  };

  return (
    <>
      <ModeTabs onNew={onNewRegister} onResults={handleResults} showIcons={{search: false, results: true, newIcon: true}} />
      <EntryActionIcons
        showIcons={{
          save: !group.id,
          edit: group.id,
        }}
        handleSubmit={() => handleSubmit(createGroup)}
        handleEdit={() => handleSubmit(updateGroup)}
        disabledIcons={{
          save: actionDisable,
          edit: actionDisable,
        }}
      /> 
    </>
  )
}

export default ConfigGroupActions
