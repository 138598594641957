/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import CloseColumnIcon from "./list-icons/CloseColumnIcon";
import EyeColumnIcon from './list-icons/EyeColumnIcon';

export const ArtWorksColumnFormatter = (cellContent,row,rowIndex,{ onEye, onDrop ,Permisos}) => (
	<div className="d-flex justify-content-between">
	{Permisos.includes("i-ver-obraarte") && <EyeColumnIcon onClick={() => onEye(row.id)}/>}
	{Permisos.includes("i-crear-baja-obraarte") &&	<CloseColumnIcon onClick={() => onDrop(row.id)} tooltip="Dar de baja" />}
	</div>
);
