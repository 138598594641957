import React from 'react';
import { Table } from 'react-bootstrap';


// This component accepts columns array with object with keys field and name. Name will be displayed in the column
// Accepts also data, which must be an array of objects with id and pair of key-value for each field column

const HistorialTable = ({ columns=[], data=[] }) => {

  return (
    <Table responsive striped size="sm" style={{ marginBottom: 30 }}>
      <thead>
        <tr>
          {columns.map(column => <th key={column.field}>{column.name}</th>)}
        </tr>
      </thead>
      <tbody>
        {data.map(item => <tr key={item.id}>{columns.map(column => <th style={{ fontWeight: 'normal'}} key={column.field}>{item[column.field]}</th>)}</tr>)}
      </tbody>
    </Table>
  );
};

export default HistorialTable;