import React from "react";
import { Typography, Box } from "@material-ui/core";
import UtilInputs from "../../../components/UtilInputs";
import { entryData6, toggleEnableInputs } from "../../../utils/newEntryData";
import { useSelector } from "react-redux";
import DocumentIcon from "@material-ui/icons/AttachMoney";
import { fieldsToTypeText } from "../../../utils/objectsFunctions";

export const NewEntryImport = React.memo(({ dataImport }) => {
  const { isSearchPage } = useSelector((store) => store.pathname);
  const { currentMode } = useSelector((store) => store.subHeaderActions);
  const inputsImport = isSearchPage ? fieldsToTypeText(entryData6) : entryData6;
  const handleChange = () => {};
  return (
    <Box mt={4}>
      <Box mb={2}>
        <Typography variant="subtitle1">
          <DocumentIcon /> Importe
        </Typography>
      </Box>
      <UtilInputs
        formValues={dataImport}
        onValuesChange={handleChange}
        inputValues={
          !currentMode ? toggleEnableInputs(inputsImport, false) : inputsImport
        }
        width={200}
        disabled={true}
      />
    </Box>
  );
});
