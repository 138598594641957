import React, { useEffect, useState, useMemo } from "react";
import { Autocomplete } from "@material-ui/lab";
import {
  ClickAwayListener,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import "./styles/_autocompleteInput.scss";
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 150,
    borderRadius: 4,
    padding: "5px 0px",
  },
  label: {
    marginLeft: 5,
    marginBottom: -5,
    fontWeight: 500,
    fontSize: 14,
    color: ({ disabled, touched, hover }) =>
      (touched || hover) && !disabled ? theme.palette.secondary.main : "black",
  },
}));

const AutocompleteInput = ({
  onChange,
  name,
  value = null,
  label = "",
  placeholder,
  searchFunction,
  onKeyDown = () => {},
  required,
  hideText = false,
  uppercase,
  width,
  disabled,
  onlyResult = false,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [touched, setTouched] = useState(false);
  const [hover, setHover] = useState(false);
  const classes = useStyles({ disabled, touched, hover });
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) setOptions([]);
  }, [open]);

  const fetch = useMemo(
    () =>
      throttle(async (request, callback) => {
        setLoading(true);
        const res = await searchFunction(request.toUpperCase());
        setLoading(false);
        return callback(res);
      }, 400),
    [searchFunction]
  );

  useEffect(() => {
    let active = true;
    if (query === "") {
      let val = null;

      if (typeof value === "object" && value?.text) {
        val = value;
      } else if (typeof value === "string") {
        val = value;
      }
      setOptions(val ? [val] : []);
      return undefined;
    }
    touched &&
      searchFunction &&
      fetch(query, (results) => {
        if (active) {
          let opt = [];
          let val = null;
          if (typeof value === "object" && value?.text) val = value;
          if (typeof value === "string") val = value;
          if (val) opt = [val];
          if (results && onlyResult) {
            opt = [...results];
          } else if (results && !onlyResult) {
            opt = [...opt, ...results];
          }
          setOptions(opt);
        }
      });

    return () => {
      active = false;
    };
  }, [query, value, fetch, searchFunction]);

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleInputChange = (e, newInputValue) => {
    const values = newInputValue.split("|");
    const newValue = hideText
      ? values.length > 1
        ? values[1]
        : values[0] || ""
      : newInputValue;
    setQuery(newValue);
  };
  // if (typeof value === "string") {
  //   value = { text: value };
  // }

  return (
    <div
      style={{
        width: width ? width : 200,
        maxWidth: isMobile && 200,
      }}
    >
      <Typography className={classes.label}>{label}</Typography>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Autocomplete
          autoComplete
          includeInputInList
          autoHighlight={true}
          fullWidth
          className={classes.root}
          loading={loading}
          loadingText={
            query && loading
              ? "Cargando"
              : `Ingresa búsqueda de ${label?.toLowerCase()}`
          }
          noOptionsText="Sin resultados "
          options={options}
          value={(() => {
            let val = null;
            if (typeof value === "object" && value?.text) {
              val = value;
            }
            if (typeof value === "string") {
              val = value;
            }
            return val;
          })()}
          inputValue={query}
          onChange={(e, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            onChange({ target: { name, value: newValue || "" } });
            setQuery("");
          }}
          onInputChange={handleInputChange}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => {
            if (typeof option === "string" && option.length > 0) {
              return option;
            }
            return option
              ? `${option.text ? option.text : ""} ${
                  option.text2 ? "| " + option.text2 : ""
                }`
              : "";
          }}
          // onBlur={e => !query ? setTouched(true) : setTouched(false)}
          onBlur={(e) => setTouched(false)}
          onFocus={(e) => setTouched(true)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          popupIcon={<></>}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              multiline
              fullWidth
              size="small"
              variant="outlined"
              color="secondary"
              required={required}
              error={touched && required}
              placeholder={
                placeholder ? placeholder.toUpperCase() : label?.toUpperCase()
              }
              onKeyDown={(e) => e.key === "Enter" && value?.id && onKeyDown()}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color={"inherit"} size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              disabled={disabled}
            />
          )}
          renderOption={(option, { inputValue }) => {
            let val = null;
            if (typeof option === "object" && option?.text) val = option.text;
            if (typeof option === "string") val = option;
            const matches = match(val, inputValue);
            const parts = parse(val, matches);

            let val2 = null;
            if (typeof option === "object" && option?.text2)
              val2 = option.text2;
            if (typeof option === "string") val2 = option;
            const matches2 = match(`${val2}`, `${inputValue}`);
            const parts2 = parse(`${val2 ?? ""}`, matches2);

            return (
              <div>
                {!hideText && (
                  <Typography>
                    {parts.map((part, index) => {
                      return (
                        <span
                          key={index}
                          style={{ fontWeight: part.highlight ? 700 : 400 }}
                        >
                          {(part?.text).toString().trim()}
                        </span>
                      );
                    })}
                  </Typography>
                )}
                {option.text2 && (
                  <Typography className={classes.muted}>
                    {parts2.map((part, index) => (
                      <span
                        key={`text2-${index}`}
                        className="text-muted"
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part?.text}
                      </span>
                    ))}
                  </Typography>
                )}
              </div>
            );
          }}
        />
      </ClickAwayListener>
    </div>
  );
};

export default AutocompleteInput;
