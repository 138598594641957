import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

const MonitoringTable = ({ rows }) => {

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Número de solicitud</TableCell>
            <TableCell align="right">Área</TableCell>
            <TableCell align="right">Fecha de solicitud</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="right">{row?.area?.cf_adscri6 ? row?.area?.cf_adscri6 : 'Sin información' }</TableCell>
              <TableCell align="right">{row.cf_fecha_solicitud}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MonitoringTable;
