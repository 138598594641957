import React from 'react';
import './styles/_tableLoader.scss'
import { Spinner } from 'react-bootstrap';

const TableLoader = () => {
    return (
			<>
				<p className="loader_text">Cargando...</p>
				<Spinner animation='border' role='status' className="loader_text">
					<span className='sr-only'>Loading...</span>
				</Spinner>
			</>
		);
};

export default TableLoader;