import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EntryActionIcons from "../../components/EntryActionIcons";
import ModeTabs from "../../components/ModeTabs";
import { initialSelectedUser, setUser } from "../../../redux/ducks/users.duck";
import { setLoader } from "../../../redux/ducks/loader.duck";
import { createUser, editUser } from "../../crud/usersCrud";
import notice from "../../components/Notice";

const UserActions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { isInstrumentalGoodsPage } = useSelector(store => store.pathname);
  const { selectedUser, selectedIds } = useSelector(state => state.users);
  
  const onSearch = () => {
    history.push((isInstrumentalGoodsPage ? "/bienes-instrumentales" : "/inventario-bienes-almacen-general") + "/usuarios/busqueda");
  };

  const onNew = () => {
    dispatch(setUser(initialSelectedUser))
    history.push((isInstrumentalGoodsPage ? "/bienes-instrumentales" : "/inventario-bienes-almacen-general") + "/usuarios/nuevo");
  };

  const onResults = () => {
    if (selectedIds[0]) {
      history.push((isInstrumentalGoodsPage ? "/bienes-instrumentales" : "/inventario-bienes-almacen-general") + "/usuarios/resultados/" + selectedIds[0]);
    }
  };

  const handleSubmit = () => {
    if (!selectedUser.password)
      notice("Se requiere una contraseña")
    else if (selectedUser.password !== selectedUser.password_confirm)
      notice("Las contraseñas deben ser iguales")
    else {
      dispatch(setLoader(true))
      createUser(selectedUser)
      .then(res => {
        history.push((isInstrumentalGoodsPage ? "/bienes-instrumentales" : "/inventario-bienes-almacen-general") + "/usuarios/resultados/" + res.id);
        notice("Usuario creado correctamente", "success")
      })
      .catch(e => {
        if (typeof e === 'object')
          notice(Object.values(e))
        else
          notice('Error en el servicio')
      })
      .finally(() => dispatch(setLoader(false)))
    }
  };

  const handleEdit = () => {
    if (selectedUser.password && selectedUser.password !== selectedUser.password_confirm)
      notice("Las contraseñas deben ser iguales")
      else{
      dispatch(setLoader(true))
      editUser(selectedUser)
      .then(res => {
        console.log(res);
        notice("Usuario actualizado correctamente", "success")
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)))
    }
  };


  return (
    <>
      <ModeTabs
        showIcons={{search: false, results: true, newIcon: true}}
        onSearch={onSearch}
        onNew={onNew}
        onResults={onResults}
      />
      <EntryActionIcons
        showIcons={{
          save: pathname.includes("nuevo"),
          edit: selectedUser.id,
        }}
        labels={{
          save: "Crear usuario",
          edit: "Editar usuario"
        }}
        handleSubmit={handleSubmit}
        handleEdit={handleEdit}
      />
    </>
  );
};
export default UserActions;
