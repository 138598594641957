import * as columnFormatters from "../components/column-formatters";
import { getArticles } from "../crud/catalogsCrud";
import { inputFormatDate } from "./dates";

export const columns = (onFile, onEye, onCross) => [
  {
    dataField: "id",
    text: "No. de solicitud",
    sort: true,
  },
  // {
  //   dataField: "warehouse_request_details",
  //   text: "Materiales solicitados",
  //   sort: true,
  //   formatter: (cell) => cell?.map(x => x.asset_category.desc_corta).join(", "),
  //   style: { minWidth: 250 }
  // },
  {
    dataField: "cf_fecha_solicitud",
    text: "Fecha de solicitud",
    sort: true,
    style: { minWidth: 100 },
    formatter: columnFormatters.DeparturesDateColumnFormatter,
  },
  {
    dataField: "cf_fecha_entrega",
    text: "Fecha de entrega",
    sort: true,
    style: { minWidth: 100 },
    formatter: (value) => inputFormatDate(value),
  },
  {
    dataField: "cf_fecha_cancelacion",
    text: "Fecha de cancelación",
    sort: true,
    style: { minWidth: 100 },
    formatter: (value) => inputFormatDate(value),
  },
  {
    dataField: "area",
    text: "Área",
    sort: true,
    formatter: (cell) => cell?.cf_adscri6,
  },
  {
    dataField: "area",
    text: "Responsable",
    sort: true,
    formatter: (cell) => cell?.cf_adscri7,
  },
  {
    dataField: "collect_email",
    text: "Correo electrónico",
    sort: true,
  },
  {
    dataField: "id", // change to type when the request change to get departures
    text: "Tipo de salida",
    sort: true,
    formatter: columnFormatters.DeparturesTypesColumnFormatter,
    classes: "text-center",
    headerClasses: "text-center",
  },
  {
    dataField: "cf_estatus",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.RequestStatusColumnFormatter,
    classes: "text-center",
    headerClasses: "text-center",
  },
  {
    dataField: "id",
    text: "Acciones",
    formatter: columnFormatters.DeparturesActionsColumnFormatter,
    formatExtraData: {
      onFile,
      onEye,
      onCross,
    },
    classes: "d-flex justify-content-between",
    headerClasses: "text-center",
    csvExport: false,
  },
];

export const defaultFilter = {
  cf_estatus: "T,B",
  fecha_solicitud: "",
  solicitud: "",
  cve_area: "",
  responsable: "",
  cf_fecha_entrega_cancelado_inicio: "",
  cf_fecha_entrega_cancelado_fin: "",
};

export const departureFilterInputs = [
  {
    label: "No de solicitud",
    name: "solicitud",
    type: "text",
  },
  {
    label: "Adscripción",
    name: "adscripcion",
    type: "text",
  },
  {
    label: "Área",
    name: "cve_area",
    type: "text",
  },
  {
    label: "Titular",
    name: "responsable",
    type: "text",
  },
  {
    label: "Estado",
    name: "cf_estatus",
    type: "dropdown-chip",
    width: 200,
    options: [
      { value: "T,B", text: "Seleccione un estado", color: "dark" },
      { value: "B", text: "Cancelada", color: "danger" },
      { value: "T", text: "Entregado", color: "success" },
    ],
  },
  {
    label: "Fecha inicio",
    name: "cf_fecha_entrega_cancelado_inicio",
    placeholder: "dd/mm/yy",
    type: "date",
    width: 200,
  },
  {
    label: "Fecha fin",
    name: "cf_fecha_entrega_cancelado_fin",
    placeholder: "dd/mm/yy",
    type: "date",
    width: 200,
  },
  {
    label: "Fecha de solicitud",
    name: "fecha_solicitud",
    placeholder: "dd/mm/yy",
    type: "date",
  },
];

export const formInputs = (disabled, isCanceled) => [
  {
    label: "No. de solicitud",
    name: "id",
    type: "text",
    disabled,
  },
  {
    label: "Fecha de solicitud",
    name: "cf_fecha_solicitud",
    type: "date",
    required: true,
    disabled,
  },
  isCanceled && {
    label: "Fecha de cancelación",
    name: "cf_fecha_cancelacion",
    type: "date",
    required: true,
    disabled: true,
  },
  {
    label: "Área",
    name: "area",
    type: "text",
    disabled,
  },
  {
    label: "Responsable",
    name: "responsable",
    type: "text",
    disabled,
    width: 400,
  },
  {
    label: "Estado",
    name: "cf_estatus",
    type: "dropdown-chip",
    required: true,
    disabled,
    options: [
      { value: "D", text: "Despachada", color: "primary" },
      { value: "B", text: "Cancelada", color: "danger" },
      { value: "T", text: "Entregado", color: "warning" },
    ],
  },
  {
    label: "No. de Salida(Folio)",
    name: "cf_no_folio",
    type: "text",
    disabled: true,
  },
];

export const collectInformationFormInputs = (disabled) => [
  {
    label: "Nombre de quién recoge",
    name: "collect_name",
    type: "text",
    required: true,
    disabled,
    width: 400,
  },
  {
    label: "Extensión de quién recoge",
    placeholder: "Extensión",
    name: "collect_extension",
    type: "text",
    required: true,
    disabled,
  },
  {
    label: "Correo electrónico de quién recoge",
    name: "collect_email",
    type: "text",
    required: true,
    disabled,
    width: 400,
  },
];

export const material_columns = [
  {
    text: "Articulo",
    value: "cf_cve_articulo",
    type: "text",
  },
  {
    text: "Nombre del artículo",
    value: "desc_corta",
    type: "text",
    width: 300,
  },
  {
    text: "Unidad",
    value: "uni_distribuye",
    type: "text",
    width: 180,
  },
  {
    text: "Solicitud",
    value: "cf_cantidad_solicitada",
    type: "text",
    width: 180,
  },
  {
    text: "Surtido",
    value: "cf_cantidad_surtida",
    type: "text",
    width: 180,
  },
  {
    text: "Negada",
    value: "cf_cantidad_negada",
    type: "text",
    width: 180,
  },
];

export const defaultDepartureForm = {
  area: null,
  responsable: "",
  collect_email: "",
  collect_extension: "",
  collect_name: "",
  cf_fecha_solicitud: "",
  cf_estatus: "",
  observaciones: "",
  warehouse_request_details: [],
  cf_no_folio: "",
  attachment_pdfs: [],
};

export const iconsActions = (onFile) => [
  {
    image: "/media/svg/icons/Files/File.svg",
    tooltip: "Ver PDF",
    onClick: onFile,
    color: "success",
  },
];

// SALIDAS RECURRENTES
export const recurrent_columns = (onFile) => [
  {
    dataField: "folio_recurrent_ordinaria",
    text: "Folio",
    sort: true,
    classes: "text-center",
    headerClasses: "text-center",
  },
  {
    dataField: "usuario_salida",
    text: "Usuario",
    sort: true,
    classes: "text-center",
    headerClasses: "text-center",
  },
  {
    dataField: "fecha_salida",
    text: "Fecha de salida",
    classes: "text-center",
    headerClasses: "text-center",
    formatter: (value) => inputFormatDate(value),
  },
  {
    dataField: "tipo_salida",
    text: "Tipo de salida",
    classes: "text-center",
    headerClasses: "text-center",
    formatter: columnFormatters.DepartureRecurrentTypeColumnFormatter,
  },
  {
    dataField: "estado",
    text: "Estado",
    classes: "text-center",
    headerClasses: "text-center",
    formatter: columnFormatters.DepartureRecurrentStateColumnFormatter,
  },
  {
    dataField: "id",
    text: "Acciones",
    formatter: columnFormatters.DepartureRecurrentActionsColumnFormatter,
    formatExtraData: { onFile },
    classes: "text-center",
    headerClasses: "text-center",
    csvExport: false,
  },
];

export const defaultRecurrentDeparture = {
  cf_fol_bita: "",
  oficina: "",
  inmueble: "",
  ubicacion: "",
  responsable: "",
  cf_observaciones: "",
  outbound_recurrent_details: [],
  atiende_folio_dgrmsg: false,
  ccp_instrumentales: false,
  contrato: "",
  proveedor: "",
  factura: "",
};

export const recurrentForm = (value_entrega, value_recibe, folio) => {
  const defaultResponsableDropdownOptions = [
    "Lic. Karla Nathalla Gayosso Cruz",
    "Lic. Daniela Gomez",
  ];

  let updatedForm = [
    {
      label: "Responsable de entrega",
      name: "responsable_entrega_drop",
      type: "text-dropdown",
      options: defaultResponsableDropdownOptions,
    },
    {
      label: "Nombre del responsable de entrega",
      name: "responsable_entrega",
      type: "text",
      disabled: true,
    },
    {
      label: "Puesto del responsable de entrega",
      name: "puesto_responsable_entrega",
      type: "text",
    },
    {
      label: "Responsable recibe",
      name: "responsable_recibe_drop",
      type: "text-dropdown",
      options: defaultResponsableDropdownOptions,
      hide: true,
    },
    {
      label: "Nombre del responsable que recibe",
      name: "responsable_recibe",
      type: "text",
    },
    {
      label: "Puesto del responsable que recibe",
      name: "puesto_responsable_recibe",
      type: "text",
    },
  ];

  if (!value_entrega) {
    updatedForm = updatedForm.filter(
      (element) =>
        element.name !== "responsable_entrega" &&
        element.name !== "puesto_responsable_entrega"
    );
  }
  if (!value_recibe) {
    updatedForm = updatedForm.filter(
      (element) =>
        element.name !== "responsable_recibe" &&
        element.name !== "puesto_responsable_recibe"
    );
  }

  updatedForm = updatedForm.concat([
    {
      label: "Contrato",
      name: "contrato",
      type: "text",
    },
    {
      label: "Proveedor",
      name: "proveedor",
      type: "text",
    },
    {
      label: "Factura",
      name: "factura",
      type: "text",
    },
    {
      type: "line-break",
    },
    {
      label: "Oficina",
      name: "oficina",
      type: "text",
      multiline: true,
      rows: 4,
    },
    {
      label: "Inmueble",
      name: "inmueble",
      type: "text",
      multiline: true,
      rows: 4,
    },
    {
      label: "Ubicación",
      name: "ubicacion",
      type: "text",
      multiline: true,
      rows: 4,
    },
    {
      type: "line-break",
    },
    {
      label: "Observaciones",
      name: "cf_observaciones",
      type: "text",
      width: 930,
    },
  ]);
  if (folio) {
    updatedForm = updatedForm.concat([
      {
        label: "Ingresa folio",
        name: "folio_dgrmsg",
        type: "text",
        width: 930,
        hide: true,
      },
    ]);
  }
  return updatedForm;
};

export const defaultBatchRecurrent = {
  cf_cve_articulo: "",
  articulo: null,
  articulo_recurrente_id: "",
  cantidad: "",
  descripcion: "",
  marca: "",
  modelo: "",
  serie: "",
  nro_inventario: "",
  inventariable: { color: "dark", text: "S/E" },
};

export const recurrentBatchColumns = (id, options = []) => [
  {
    label: "Artículo",
    name: "articulo",
    type: "search",
    searchFunction: getArticles,
  },
  {
    label: "Artículo recurrente",
    name: "articulo_recurrente_id",
    type: "dropdown",
    options,
  },
  {
    label: "Cantidad",
    name: "cantidad",
    type: "text",
    width: 80,
  },
  {
    label: "Marca",
    name: "marca",
    type: "text",
    width: 80,
    disabled: true,
  },
  {
    type: "line-break",
  },
  {
    label: "Modelo",
    name: "modelo",
    type: "text",
    width: 80,
    disabled: true,
  },
  {
    label: "Serie",
    name: "serie",
    type: "text",
    width: 80,
    disabled: true,
  },
  {
    label: "No de inventario",
    name: "nro_inventario",
    type: "text",
    disabled: true,
  },
  {
    label: "Inventariable",
    name: "inventariable",
    type: "status-label",
    disabled: true,
  },
];

// ORDINARIA

export const defaultOdinaryDeparture = {
  oficina: "",
  inmueble: "",
  ubicacion: "",
  responsable: "",
  cf_observaciones: "",
  responsable_entrega: "",
  puesto_responsable_entrega: "",
  responsable_recibe: "",
  puesto_responsable_recibe: "",
  items: [{ description: "", measure_unit: "", delivery_quantity: "" }],
  atiende_folio_dgrmsg: false,
  ccp_instrumentales: false,
  contrato: "",
  proveedor: "",
  factura: "",
};

export const odinaryForm = (value_entrega, value_recibe, folio) => {
  const defaultResponsableDropdownOptions = [
    "Lic. Karla Nathalla Gayosso Cruz",
    "Lic. Daniela Gomez",
  ];

  let updatedForm = [
    {
      label: "Responsable de entrega",
      name: "responsable_entrega_drop",
      type: "text-dropdown",
      options: defaultResponsableDropdownOptions,
    },
    {
      label: "Nombre del responsable de entrega",
      name: "responsable_entrega",
      type: "text",
      disabled: true,
    },
    {
      label: "Puesto del responsable de entrega",
      name: "puesto_responsable_entrega",
      type: "text",
    },
    {
      label: "Responsable recibe",
      name: "responsable_recibe_drop",
      type: "text-dropdown",
      options: defaultResponsableDropdownOptions,
      hide: true,
    },
    {
      label: "Nombre del responsable que recibe",
      name: "responsable_recibe",
      type: "text",
      disabled: true,
    },
    {
      label: "Puesto del responsable que recibe",
      name: "puesto_responsable_recibe",
      type: "text",
    },
  ];

  if (!value_entrega) {
    updatedForm = updatedForm.filter(
      (element) =>
        element.name !== "responsable_entrega" &&
        element.name !== "puesto_responsable_entrega"
    );
  }
  if (!value_recibe) {
    updatedForm = updatedForm.filter(
      (element) =>
        element.name !== "responsable_recibe" &&
        element.name !== "puesto_responsable_recibe"
    );
  }

  updatedForm = updatedForm.concat([
    {
      label: "Contrato",
      name: "contrato",
      type: "text",
    },
    {
      label: "Proveedor",
      name: "proveedor",
      type: "text",
    },
    {
      label: "Factura",
      name: "factura",
      type: "text",
    },
    {
      type: "line-break",
    },
    {
      label: "Oficina",
      name: "oficina",
      type: "text",
      multiline: true,
      rows: 4,
    },
    {
      label: "Inmueble",
      name: "inmueble",
      type: "text",
      multiline: true,
      rows: 4,
    },
    {
      label: "Ubicación",
      name: "ubicacion",
      type: "text",
      multiline: true,
      rows: 4,
    },
    {
      type: "line-break",
    },
    {
      label: "Observaciones",
      name: "cf_observaciones",
      type: "text",
      width: 930,
    },
  ]);
  if (folio) {
    updatedForm = updatedForm.concat([
      {
        label: "Ingresa folio",
        name: "folio_dgrmsg",
        type: "text",
        width: 930,
        hide: true,
      },
    ]);
  }

  return updatedForm;
};
export const ordinaryDepartureColumns = [
  {
    text: "Descripción de bienes",
    value: "description",
    type: "text",
    width: 870,
  },
  {
    text: "Unidad de medida",
    value: "measure_unit",
    type: "text",
    width: 290,
  },
  {
    text: "Cantidad entregada",
    value: "delivery_quantity",
    type: "text",
    width: 290,
  },
  {
    text: "",
    value: "delete",
    type: "icon",
  },
];

export const recurrentDepartureColumns = [
  {
    text: "Artículo",
    value: "articulo",
    type: "text",
    searchFunction: getArticles,
    disabled: true,
    width: 350,
  },
  {
    text: "Artículo recurrente",
    value: "articulo_recurrente_id",
    type: "dropdown",
  },
  {
    text: "Cantidad",
    value: "cantidad",
    type: "text",
    width: 100,
  },
  {
    text: "Marca",
    value: "marca",
    type: "text",
    width: 200,
    disabled: true,
  },
  {
    text: "Modelo",
    value: "modelo",
    type: "text",
    width: 200,
    disabled: true,
  },
  {
    text: "Serie",
    value: "serie",
    type: "text",
    width: 325,
    disabled: true,
  },
  {
    text: "No de inventario",
    value: "nro_inventario",
    type: "text",
    width: 150,
    disabled: true,
  },
  {
    text: "Inventariable",
    value: "inventariable",
    type: "status-label",
    width: 200,
    disabled: true,
  },
  {
    text: "",
    value: "delete",
    type: "icon",
  },
];
