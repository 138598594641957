import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './styles/_notice.scss';
import CancelIcon from '@material-ui/icons/Cancel';

let timeoutHandle = null

export default function notice(message, status = "error", timeout = 4000, position = 'top') {

  clearTimeout(timeoutHandle);
  ReactDOM.unmountComponentAtNode(document.getElementById('notice'));
  ReactDOM.render(<Notice message={message} status={status} timeout={timeout} position={position} />, document.getElementById('notice'));
  timeoutHandle = setTimeout(() => {
    ReactDOM.unmountComponentAtNode(document.getElementById('notice'))
  }, timeout);

}

function Notice(props) {
  const [display, setDisplay] = useState('')

  let style = {}

  switch (props.status) {
    case "error":
      style = { display: display, backgroundColor: '#d32f2f' };
      break;
    case "info":
      style = { display: display, backgroundColor: '#859de0' };
      break;
    case "success":
      style = { display: display, backgroundColor: '#319631' };
      break;
    case "warning":
      style = { display: display, backgroundColor: '#ffa500' };
      break;
    default:
      style = { display: display, backgroundColor: '#d32f2f' };
  }

  switch(props.position){
    case 'bottom':
      style = { ...style, top: 'auto', bottom: '15px' };
      break;

    default: 
      break;
  };

  const message = () => {
    if (Array.isArray(props.message)) {
      return props.message.map((message, i) => <li key={i} style={{ listStyle: "none" }}>{message}</li>)
    } else {
      return <li style={{ listStyle: "none" }}>{props.message}</li>
    }
  }

  return (
    <div
      className={'notice'}
      style={style}>
      <ul>
        {message()}
      </ul>
      <button className='notice-button' onClick={() => setDisplay('none')}>
        <CancelIcon />
      </button>
    </div>
  )
}