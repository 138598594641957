import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import { setEntrance } from "../../../../redux/ducks/entrance.duck";
import BatchTable from "../../../components/BatchTable";
import notice from "../../../components/Notice";
import { defaultCleanArticle } from "../../../utils/entranceData";
import { isAValidInteger } from "../../../utils/inputValidations";
import { Grid, TextField } from "@material-ui/core";
import SimpleInput from "../../../components/SimpleInput";
import { getArticlesClean } from "../../../crud/catalogsCrud";

const StockMaterialAccordion = ({ items, columns, handleChange, disabled }) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [resultAutocomplete, setResultAutocomplete] = React.useState([]);
  const { entrance } = useSelector((state) => state.entrance);
  const dispatch = useDispatch();
  console.log(columns);
  const handleBatch = (i, field, value, aIndex = "") => {
    if (field === "cf_cantidad" && isAValidInteger(value)) {
      let updatedState = items;
      updatedState[aIndex][field] = value;
      if (
        updatedState[aIndex]["cf_cantidad"] &&
        updatedState[aIndex]["cf_precio_unit"] &&
        updatedState[aIndex]["cf_precio_unit"].length > 8
      ) {
        updatedState[aIndex]["subtotal"] = Number(
          Number(value?.replace(",", "")) *
            Number(updatedState[aIndex]["cf_cantidad"])
        );
      } else {
        updatedState[aIndex]["subtotal"] = Number(
          Number(value) * Number(updatedState[aIndex]["cf_cantidad"])
        ).toFixed(4);
      }
      handleChange(
        { target: { name: "requisition_item_details", value: updatedState } },
        i
      );
      return;
    } else if (field === "cf_precio_unit") {
      let updatedState = items;
      updatedState[aIndex][field] = Number(value.replace(",", ""));
      if (
        updatedState[aIndex]["cf_precio_unit"] &&
        updatedState[aIndex]["cf_cantidad"] &&
        value.length > 8
      ) {
        updatedState[aIndex]["subtotal"] = Number(
          Number(value?.replace(",", "")) *
            Number(updatedState[aIndex]["cf_cantidad"])
        );
      } else {
        updatedState[aIndex]["subtotal"] = Number(
          Number(value) * Number(updatedState[aIndex]["cf_cantidad"])
        ).toFixed(4);
      }
      handleChange(
        { target: { name: "requisition_item_details", value: updatedState } },
        i
      );
      return;
    } else if (field === "cf_articulo" || field === "cf_cve_articulo") {
      if (value.cve_articulo < 7000 || value.cve_articulo > 7999) {
        notice("El articulo no pertence al grupo de limpieza");
        return;
      } else if (
        items.find(
          (it) =>
            it.cf_cve_articulo?.toString() === value?.cve_articulo?.toString()
        )
      ) {
        notice("El articulo ya ha sido agregado");
        return;
      } else {
        let updatedState = items;

        updatedState[aIndex]["cf_articulo"] = value?.desc_corta?.trim();
        updatedState[aIndex]["cf_cve_articulo"] = value?.cve_articulo;
        updatedState[aIndex]["iva"] = value?.iva;
        updatedState[aIndex]["ivaDisplay"] = value?.iva ? "Sí" : "No";
        updatedState[aIndex]["uni_distribuye"] = value?.uni_distribuye;
        handleChange(
          { target: { name: "requisition_item_details", value: updatedState } },
          aIndex
        );
        return;
      }
    } else return;
  };

  const onSearchTextChange = (i, field, value, aIndex) => {
    let state = items;
    if (!value?.text) {
      if (field === "cf_articulo") {
        state[aIndex]["cf_articulo"] = value;
        return dispatch(
          setEntrance({ ...entrance, requisition_item_details: state })
        );
      } else if (field === "cf_cve_articulo" && isAValidInteger(value)) {
        state[aIndex]["cf_cve_articulo"] = value;
        return dispatch(
          setEntrance({ ...entrance, requisition_item_details: state })
        );
      } else
        dispatch(setEntrance({ ...entrance, requisition_item_details: state }));
    }
  };

  const addArticle = (i) => {
    dispatch(
      setEntrance({
        ...entrance,
        requisition_item_details: [
          ...items,
          { ...defaultCleanArticle, id: uuid() },
        ],
      })
    );
  };

  const deleteArticle = (item, index) => {
    let updatedState = items;
    updatedState.splice(index, 1);
    dispatch(
      setEntrance({ ...entrance, requisition_item_details: updatedState })
    );
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      const getData = setTimeout(async () => {
        const result = await getArticlesClean(searchTerm);
        setResultAutocomplete(result);
      }, 2000);

      return () => clearTimeout(getData);
    }
  }, [searchTerm]);

  console.log(entrance);

  const onGetResult = (result) => {
    const aIndex = entrance.requisition_item_details.length;
    console.log(aIndex);
    setResultAutocomplete([]);

    if (result.cve_articulo < 7000 || result.cve_articulo > 7999) {
      notice("El artículo no pertenece al grupo de limpieza");
      return;
    }
    const itemExists = entrance.requisition_item_details.some(
      (it) => it.cf_cve_articulo?.toString() === result.cve_articulo?.toString()
    );

    if (itemExists) {
      notice("El artículo ya ha sido agregado");
      return;
    }

    const updatedItems = [...items];
    updatedItems[aIndex] = {
      cf_articulo: result.desc_corta?.trim(),
      cf_cve_articulo: result.cve_articulo,
      iva: result.iva,
      ivaDisplay: result.iva ? "Sí" : "No",
      uni_distribuye: result.uni_distribuye,
    };

    handleChange(
      {
        target: { name: "requisition_item_details", value: updatedItems },
      },
      aIndex
    );
  };

  return (
    <Grid container>
      <Grid item xs={5} mt={2} mb={2}>
        <SimpleInput
          label="Buscar por clave o nombre"
          width={"100%"}
          showAutocomplete
          onChange={({ target }) => setSearchTerm(target.value)}
          autocompleteResults={resultAutocomplete}
          onGetResult={onGetResult}
          value={searchTerm}
          disabled={disabled}
        />
      </Grid>
      <br />
      <br />
      <br />
      <br />
      <Grid item xs={12} mt={100} sx={{ marginTop: "10px !important" }}>
        <BatchTable
          i={0}
          items={items}
          columns={columns}
          disabled={disabled}
          handleChange={handleBatch}
          addRow={addArticle}
          deleteRow={deleteArticle}
          onSearchTextChange={onSearchTextChange}
          disableAddItem
        />
      </Grid>
    </Grid>
  );
};

export default StockMaterialAccordion;
