import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultPresentation } from "../../app/utils/configData"

const actionType = {
  SetPresentations: '[CONFIG] SET_PRESENTATIONS',
  SetPresentation: '[CONFIG] SET_PRESENTATION',
  SetSelectedPresentations: '[CONFIG] SET_SELECTED_PRESENTATION',
  ClearState: '[CONFIG] CLEAR_STATE',
};

const initialState = {
  presentations: {
    results: [],
    count: 0
  },
  presentation: defaultPresentation,
  selectedPresentations: [],
};

export const reducer = persistReducer(
  { storage, key: 'config', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetPresentations:
        return { ...state, presentations: action.payload }
      
      case actionType.SetPresentation:
        return { ...state, presentation: action.payload }
      
      case actionType.SetSelectedPresentations:
        return { ...state, selectedPresentations: action.payload }

      case actionType.ClearState:
        return state = initialState
      
      default:
        return state
    }
  }
)

export const setPresentations = data => ({ type: actionType.SetPresentations, payload: data })

export const setPresentation = data => ({ type: actionType.SetPresentation, payload: data })

export const setSelectedPresentations = data => ({ type: actionType.SetSelectedPresentations, payload: data })

export const setClearState = data => ({ type: actionType.ClearState, payload: data })
