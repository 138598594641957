import * as columnFormatters from "../components/column-formatters";
import sort from "../../assets/sort.png";
export const GRID_VIEW = "GRID VIEW";
export const LIST_VIEW = "LIST VIEW";
export const DIGITAL_VAULT_FOLDERS_ROUTE = "/boveda-digital/carpetas";
export const ONDRAG_BG_COLOR = "bg-secondary";
function sortFormatter(column, colIndex) {
  return (
    <p>
      {" "}
      {column.text} <img src={sort} alt="img" />
    </p>
  );
}
export const foldersListColumns = [
  {
    dataField: "icon",
    text: "",
    sort: true,
    formatter: columnFormatters.DigitalVaultIconColumnFormatter,
    headerStyle: {
      width: 40,
    },
  },
  {
    dataField: "name",
    text: "Nombre",
    sort: true,
    headerFormatter: sortFormatter,
    style: (cell, row, rowIndex, colIndex) => {
      if (!row.hasOwnProperty("folder")) {
        return {
          textDecoration: "underline",
          cursor: "pointer",
        };
      }
      return {
        cursor: "pointer",
      };
    },
  },
  {
    dataField: "updated_at",
    text: "Última modificación",
    sort: true,
    headerFormatter: sortFormatter,
  },
];

export const newFolderInputs = (users) => [
  {
    label: "Nombre de la carpeta",
    name: "name",
    type: "text",
    width: "100%",
  },

  {
    label: "Usuarios que editan carpeta",
    name: "users",
    width: "100%",
    type: "search-multiple",
    options: users,
  },
];
