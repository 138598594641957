import { useState, useEffect, useRef } from 'react';

export const useComponentVisible = (initialIsVisible, mouseEvent="click") => {
	const [isComponentVisible, setIsComponentVisible] =
		useState(initialIsVisible);
	const ref = useRef(null);

	const handleClickOutside = event => {
		if (ref.current && ref.current.contains(event.target)) {
			setIsComponentVisible(true);
		};
	};

	useEffect(() => {
		document.addEventListener(mouseEvent, handleClickOutside, true);
		return () => {
			document.removeEventListener(mouseEvent, handleClickOutside, true);
		};
	});

	return { ref, isComponentVisible, setIsComponentVisible };
};
