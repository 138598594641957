import React, { useEffect } from "react";
import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import ModuleCard from "../../components/ModuleCard";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as auth from "../../modules/Auth/_redux/authRedux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 40,
  },
  alert: {
    width: "100%",
    fontSize: 22,
  },
  alertTitle: {
    fontSize: 25,
  },
  titles: {
    textAlign: "center",
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.7rem",
  },
  subtitle: {
    color: theme.palette.gray.main,
  },
  modules: {
    display: "flex",
    justifyContent: "center",
    "&>*": {
      margin: ({ isMobile }) => (isMobile ? "30px 0px" : "40px"),
    },
    flexWrap: "wrap",
    alignItems: "center",
  },
}));

const MAX_DAY_TO_SHOW = new Date(2022, 12, 12);

const ModulesPage = () => {
  const currentDay = new Date();
  const {
    user: { app_modules = [] },
    user,
  } = useSelector((store) => store.auth);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const classes = useStyles({ isMobile });
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSelectModule = (module) => {
    dispatch(auth.actions.setModule(module));
    history.push(`/${module.slug}`);
  };
  const haveOnlyRequestsModule =
    app_modules.length === 1 && app_modules[0].slug === "solicitudes";
  useEffect(() => {
    const ArrayDePermisos = [];
    user?.groups.map((element) => {
      return ArrayDePermisos.push(element.name);
    });
    dispatch(auth.actions.setPermisos(ArrayDePermisos));
  }, [dispatch, user?.groups]);

  useEffect(() => {
    dispatch(auth.actions.clearModule());
  }, [dispatch]);

  return (
    <>
      {currentDay < MAX_DAY_TO_SHOW && (
        <Alert className={classes.alert} variant="filled" severity="warning">
          <AlertTitle className={classes.alertTitle}>
            <strong>AVISO</strong>
          </AlertTitle>
          Con Motivo del levantamiento de <strong>inventario</strong> de bienes de consumo
          generalizado (stock), el almacén general permancerá cerrado del 19 al 23 de
          Diciembre del 2022, por tal motivo el sistema de almacén estará abierto hasta el
          día <strong>12 de Diciembre </strong>
          del presente para recibir y suministrar solicitudes.
        </Alert>
      )}
      <div className={classes.root}>
        <div className={classes.titles}>
          <Typography className={classes.title} variant="h5">
            {haveOnlyRequestsModule
              ? "Dirección General de Recursos Materiales y Servicios Generales"
              : "SISTEMA DE CONTROL DE BIENES INSTRUMENTALES E INVENTARIO DE BIENES CONTROLADOS POR EL ALMACÉN GENERAL"}
          </Typography>
          <Typography className={classes.subtitle} variant="h6">
            {haveOnlyRequestsModule ? "Sistema de solicitudes de bienes de consumo" : ""}
          </Typography>
        </div>
        <div className={classes.modules}>
          {app_modules &&
            app_modules
              .sort((a, b) => a.seq - b.seq)
              .map((module) => (
                <ModuleCard
                  key={module.id}
                  title={module.display_text}
                  description={module.display_description}
                  image={module.image}
                  onClick={() => handleSelectModule(module)}
                />
              ))}
        </div>
      </div>
    </>
  );
};

export default ModulesPage;
