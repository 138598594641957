import React, { Suspense, useEffect, useState, useCallback } from "react";
import ItemsList from "./ItemsList";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector, useDispatch } from "react-redux";
import { inventoryColumns } from "../../../utils/newEntryData";
import CustomButton from "../../../components/CustomButton";
import { setLoading } from "../../../../redux/ducks/entry.duck";
import notice from "../../../components/Notice";
import {
  getSingleAsset,
  updateAssetAcquisition,
} from "../../../crud/entryCrud";
import AssetsIcon from "@material-ui/icons/Assignment";

import { Typography, Box } from "@material-ui/core";
import { setLoader } from "../../../../redux/ducks/loader.duck";

const TablaBienes = React.memo(
  ({
    tablaNew,
    setTablaNew,
    tablaResult,
    setTablaResults,
    setDataImport,
    id,
  }) => {
    const dispatch = useDispatch();

    const { isResultsPage, isNewPage } = useSelector((store) => store.pathname);
    const { currentMode } = useSelector((store) => store.subHeaderActions);
    const [currentFields, setCurrentFields] = useState({
      column: "",
      index: -1,
    });
    const [columnsBienes, setColumnsBienes] = useState([]);
    const [inventoriesColumn, setInventoriesColumn] = useState([]);

    useEffect(() => {
      const columns = isResultsPage
        ? inventoryColumns("edit", currentMode)
        : inventoryColumns("create", currentMode);
      if (!isResultsPage) columns.shift();
      const columnsMod = columns.map((column) => column.value);
      setInventoriesColumn(columnsMod);
      setColumnsBienes(columns);
    }, [currentMode]);

    const handleChangeTable = (column, index, e) => {
      const { value, name } = e.target;
      let newInventory = currentMode === "edit" ? tablaResult : tablaNew;
      if (column === "cabmsItem") {
        const { cabms } = e.target;
        const { cf_grupo, cf_subgrupo, cf_clase, cf_subclase, cf_tipo } = cabms;
        newInventory[index].inventory_cabms_grupo = cf_grupo;
        newInventory[index].inventory_cabms_subgrupo = cf_subgrupo;
        newInventory[index].inventory_cabms_clase = cf_clase;
        newInventory[index].inventory_cabms_subclase = cf_subclase;
        newInventory[index].inventory_cabms_tipo = cf_tipo;
      } else if (column === "item") {
        const { cabms } = e.target;
        const { cf_grupo, cf_subgrupo, cf_clase, cf_subclase, cf_tipo } = cabms;
        newInventory[index].inventory_cf_grupo = cf_grupo;
        newInventory[index].inventory_cf_subgrupo = cf_subgrupo;
        newInventory[index].inventory_cf_clase = cf_clase;
        newInventory[index].inventory_cf_subclase = cf_subclase;
        newInventory[index].inventory_cf_tipo = cf_tipo;
      } else if (column === "inventory_detail_cf_precio_unitario") {
        newInventory[index].inventory_detail_cf_precio_unitario = value;
        newInventory[index].inventory_detail_cf_cantidad_descuento = 0;
        newInventory[index].inventory_detail_cf_descuento = 0;
      } else if (column === "inventory_detail_cf_cantidad_descuento") {
        const newDiscount = (
          (Number(newInventory[index].inventory_detail_cf_cantidad_descuento) *
            100) /
          Number(newInventory[index].inventory_detail_cf_precio_unitario)
        ).toFixed(2);

        newInventory[index].inventory_detail_cf_cantidad_descuento = value || 0;
        newInventory[index].inventory_detail_cf_descuento = isNaN(newDiscount)
          ? 0
          : newDiscount || 0;
      } else if (currentFields.column === "inventory_detail_cf_descuento") {
        const newDiscount = (
          (Number(
            newInventory[index].inventory_detail_cf_precio_unitario.replace(
              /,/g,
              ""
            )
          ) *
            Number(value)) /
          100
        ).toFixed(2);
        newInventory[index].inventory_detail_cf_cantidad_descuento = isNaN(
          newDiscount
        )
          ? 0
          : newDiscount || 0;
        newInventory[index].inventory_detail_cf_descuento = value || 0;
      } else if (column.includes("inventory_")) {
        typeof value === "string"
          ? (newInventory[index][name] = value)
          : (newInventory[index][column] = value.id);
      } else {
        newInventory[index][column] = value;
      }

      const IVA = 16;
      const cf_sub_total = newInventory.reduce(
        (acc, value) =>
          value.inventory_detail_cf_cantidad > 0
            ? acc +
              (value.inventory_detail_cf_cantidad *
                value.inventory_detail_cf_precio_unitario -
                value.inventory_detail_cf_cantidad_descuento)
            : acc,
        0
      );
      const cf_importe_neto = cf_sub_total + cf_sub_total * (IVA / 100);
      const cf_iva_factura = cf_sub_total * (IVA / 100);

      setDataImport({
        cf_sub_total: cf_sub_total.toFixed(2),
        cf_iva_factura: cf_iva_factura.toFixed(2),
        cf_importe_neto: cf_importe_neto.toFixed(2),
      });

      setTablaResults(newInventory);
      setCurrentFields({ column, index });
    };

    const handleRemoveItem = useCallback(
      (item) => {
        const newInventory = tablaNew.filter(
          (item_inventory) => item_inventory.uuid !== item.uuid
        );
        setTablaNew(newInventory);
      },
      [tablaNew]
    );
    const handleGoodPhoto = useCallback(
      (file, index) => {
        let newInventory =
          currentMode === "edit" ? [...tablaResult] : [...tablaNew];
        newInventory[index]["foto_data"] = file;
        currentMode === "edit"
          ? setTablaResults(newInventory)
          : setTablaNew(newInventory);
      },
      [tablaNew]
    );

    const updateAssetsAcqImport = () => {
      let newInventory = currentMode === "edit" ? tablaResult : tablaNew;
      const imports = newInventory.map((inv) => {
        return {
          inventory_detail_cf_cantidad_descuento:
            inv.inventory_detail_cf_cantidad_descuento,
          inventory_detail_cf_descuento: inv.inventory_detail_cf_descuento,
          inventory_detail_cf_precio_unitario:
            inv.inventory_detail_cf_precio_unitario,
        };
      });

      const totalPrice = imports.reduce((accumulator, price) => {
        return (
          Number(accumulator) +
          Number(price.inventory_detail_cf_precio_unitario)
        );
      }, 0);
      return totalPrice;
      // return Number(totalPrice.replace(/,/g, ""));
    };

    const modifyImportsInput = () => {
      const newImports = updateAssetsAcqImport();
      let importInputs = {};

      importInputs["cf_sub_total"] = newImports;
      importInputs["cf_iva_factura"] = newImports * 0.16;
      importInputs["cf_importe_neto"] = newImports * 0.16 + newImports;

      return importInputs;
    };
    const handleSaveInventory = (data) => {
      if (
        Number(data.inventory_detail_cf_cantidad_descuento) >
        Number(data.inventory_detail_cf_precio_unitario)
      ) {
        return notice("Ingrese una cantidad descuento menor", "warning");
      }

      const { asset_acq_detail_id } = data;
      dispatch(setLoader(true));

      updateAssetAcquisition(asset_acq_detail_id, data)
        .then((res) => {
          if (res.status === 200) {
            const { data } = res;
            const imports = modifyImportsInput();
            setDataImport({ ...imports });
            return notice(`El bien ${data.id} ha sido editado`, "success");
          } else {
            return notice(
              `Datos incompletos en la solicitud, favor de revisar.`,
              "info"
            );
          }
        })
        .catch((e) => {
          console.log(e);
          notice("Algo ha salido mal en el servidor, intente de nuevo.");
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    };
    return (
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        <Box mb={2}>
          <Typography variant="subtitle1">
            <AssetsIcon /> Bienes{" "}
            {tablaResult.length > 0 && <span>({tablaResult.length})</span>}
          </Typography>
        </Box>
        <div className="d-flex">
          <div>
            <div
              className="border border-secondary bg-white"
              style={{ paddingTop: 30, minHeight: 35 }}
            />
            {isResultsPage &&
              tablaResult.map((item) => (
                <div
                  key={item.id}
                  className="d-flex align-items p-1"
                  style={{ background: "#dedede", minHeight: 65 }}
                >
                  <CustomButton
                    className="m-auto"
                    style={{ height: 35 }}
                    title="Guardar"
                    color="primary"
                    onClick={() => handleSaveInventory(item)}
                    disabled={!currentMode}
                  />
                </div>
              ))}
          </div>
          <Suspense
            fallback={<Skeleton animation="wave" width={160} height={70} />}
          >
            {(tablaNew.length > 0 || tablaResult.length > 0) && (
              <ItemsList
                items={isNewPage ? tablaNew : tablaResult}
                columns={columnsBienes}
                handlePhoto={handleGoodPhoto}
                handleChange={handleChangeTable}
                handleRemoveItem={handleRemoveItem}
                disableIndex={[]}
                disableColumns={inventoriesColumn}
              />
            )}
          </Suspense>
        </div>
      </div>
    );
  }
);

export default TablaBienes;
