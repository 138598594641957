import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModeTabs from "../../components/ModeTabs";
import { setLoader } from "../../../redux/ducks/loader.duck";
import notice from "../../components/Notice";
import { NewPassword } from "../../crud/usersCrud";

const MeUserAction = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedUser } = useSelector((state) => state.users);

  const editPassword = () => {
    if (selectedUser.new_password1 !== selectedUser.new_password2) {
      notice("Las contraseñas deben ser iguales");
    } else if (
      selectedUser.new_password1 === undefined ||
      selectedUser.new_password2 === undefined
    ) {
      notice("Ingrese su nueva contraseña porfavor");
    } else if (!selectedUser.new_password1) {
      notice("Ingrese nueva contraseña porfavor");
    } else if (!selectedUser.new_password2) {
      notice("ingrese su confirmacion de contraseña porfavor");
    } else if (selectedUser.old_password === "") {
      notice("Ingrese su contraseña porfavor");
    } else if (selectedUser.new_password1.length < 4) {
      notice("Su contraseña debe ser mayor o igual a 4 letras");
    } else {
      dispatch(setLoader(true));
      NewPassword(selectedUser)
        .then((res) => {
          notice("Usuario actualizado correctamente", "success");
        })
        .then(() => {
          history.push("/logout");
        })
        .catch((err) => {
          notice(`${err.old_password.old_password}`);
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };

  return (
    <Fragment>
      <div style={{ float: "left", marginRight: 20 }}>
        <ModeTabs
          showIcons={{
            search: false,
            results: false,
            newIcon: false,
            newPassword: true,
          }}
          editPassword={editPassword}
        />
      </div>
    </Fragment>
  );
};
export default MeUserAction;
