import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import SimpleCard from "../../components/SimpleCard";
import MonitoringTable from "./components/MonitoringTable";

const MonitoringRFID = ({ requests, loadRequests }) => {
  return (
    <Box sx={{ height: "calc(100vh - 180px)" }}>
      <Grid style={{ height: "100%" }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <SimpleCard
            title="Última solicitud"
            headerClasses="bg-success text-white"
            load={loadRequests}
            style={{ height: "100%" }}
          >
            <List>
              <ListItem>
                <ListItemAvatar>
                  <AutorenewIcon color="action" style={{ fontSize: 50 }} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h1" color="secondary">
                      {requests[0]?.id}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle1" color="primary">
                      Área:{" "}
                      {requests[0]?.area?.cf_adscri6
                        ? requests[0]?.area?.cf_adscri6
                        : "Sin información"}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </SimpleCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <SimpleCard
            title="Solicitudes anteriores"
            headerClasses="bg-warning text-white"
            load={loadRequests}
            style={{ height: "100%" }}
          >
            <MonitoringTable rows={requests.slice(1)} />
          </SimpleCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MonitoringRFID;
