import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionsContainer: {
    paddingTop: theme.spacing(3),
  },
}));

/**
 * Basic modal with prebuilt UI/UX features.
 * @param {sting} title - Title string. It will be displayed on the left corner of the modal.
 * @param {Boolean} open - Boolean that indicates if the modal must be visible.
 * @param {*} onClose - Function that will be executed when an action that closes the modal is made.
 * @param {*} onConfirm - [OPTIONAL] Function that will be executed with the confirm button.
 * @param {string} confrimLabel - [OPTIONAL] Confirm button label.
 * @param {*} onCancel - [OPTIONAL] Function that will be executed with the cancel button.
 * @param {string} cancelLabel - [OPTIONAL] Cancel button label.
 * @param {string} maxWidth - [OPTIONAL] Max width that the modal can reach. Enum: xs, sm, md, lg, xl. (Default = sm).
 */
const BaseModal = ({
  title,
  open,
  onClose,
  onConfirm,
  confirmLabel = "Aceptar",
  onCancel,
  cancelLabel = "Cancelar",
  maxWidth = "sm",
  children,
  color = "primary",
  cancelColor = "inherit",
  confirmVariant = "contained",
  cancelVariant = "text",
  disableOnConfirm = false,
  loading = false,
  dividers = false,
  onClickAditionalButton,
  aditionalButtonVariant = "outlined",
  aditionalButtonColor = "secondary",
  aditionalButtonLabel = "",
}) => {
  const classes = useStyles();

  if (!open) return <></>;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onClose}
      maxWidth={maxWidth}
      PaperProps={{
        style: {
          position: "static",
        },
      }}
    >
      <DialogTitle className={classes.header} disableTypography={true}>
        <Typography variant="h5">{title}</Typography>
        <IconButton
          style={{ display: Boolean(children) ? "flex" : "none" }}
          className={classes.closeButton}
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={dividers}>{children}</DialogContent>
      {onCancel || onConfirm ? (
        <DialogActions className={classes.actionsContainer}>
          {onClickAditionalButton ? (
            <Button
              disableElevation
              color={aditionalButtonColor}
              variant={aditionalButtonVariant}
              onClick={() => {
                onClickAditionalButton();
              }}
            >
              {aditionalButtonLabel}
            </Button>
          ) : null}
          {onCancel ? (
            <Button
              disableElevation
              color={cancelColor}
              variant={cancelVariant}
              onClick={() => {
                onCancel();
              }}
            >
              {cancelLabel}
            </Button>
          ) : null}
          {onConfirm ? (
            <Button
              disabled={disableOnConfirm}
              color={color}
              disableElevation
              variant={confirmVariant}
              className="action__button"
              onClick={() => {
                onConfirm();
              }}
              endIcon={loading && <CircularProgress size={18} />}
            >
              {confirmLabel}
            </Button>
          ) : null}
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

export default BaseModal;
