import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import uuid from "react-uuid";
import { clearSelectedEntry } from "../../redux/ducks/entry.duck";
import { downLoaderWithURL } from "../utils/downloaderWithURL";
import notice from "./Notice";

import {
  setOnFormatGuardClick,
  setOnLabelClick,
  setOnLockClick,
  setOnReportClickFunction,
  setCurrentMode,
  setOnSearch2,
  setOnNew2,
  setOnSave2,
  setOnEdit2,
  setOnCancel2,
  setOnSearch3,
  setOnNew3,
  setOnSave3,
  setOnEdit3,
  setOnCancel3,
  setOnGetFormatA,
  setOnGetFormatB,
  setOnGetFormatC,
  setOnGetFormatD,
  setDisabledSaveFalse,
  setOnFinalize,
  setUnSuscribe,
  setIsEditDisabled,
} from "../../redux/ducks/subHeaderActions.duck";
import { useLocation } from "react-router";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { modeOptions } from "../utils/subheaderIconsData";
import Chip from "./Chip";
import BaseModal from "./BaseModal";
import UtilInputs from "./UtilInputs";
import {
  setCurrentReport,
  setModal,
  resetCurrentReport,
  handleFormChange,
  setLoading,
} from "../../redux/ducks/reports.duck";

const actionsFunctions = {
  search2: setOnSearch2,
  new2: setOnNew2,
  save2: setOnSave2,
  edit2: setOnEdit2,
  cancel2: setOnCancel2,
  search3: setOnSearch3,
  new3: setOnNew3,
  save3: setOnSave3,
  edit3: setOnEdit3,
  cancel3: setOnCancel3,
};
const SubheaderIcons = () => {
  const dispatch = useDispatch();
  const {
    availableReports,
    modalOpen,
    formValues,
    formInputs,
    currentReport,
  } = useSelector((store) => store.reports);
  const { user } = useSelector((store) => store.auth);
  const {
    isEditDisabled,
    isFinalizeDisabled,
    currentMode,
    label,
    enableFormats,
    disabledSave,
  } = useSelector((store) => store.subHeaderActions);
  const { newEntryResultsForm, selectedEntries } = useSelector((store) => store.entry);
  const { selectedStocks, idsFilterResult } = useSelector((store) => store.stock);
  const { selectedInventory } = useSelector((store) => store.inventory);
  const { selectedArtWork } = useSelector((store) => store.artWork);
  const { selectedVehicule } = useSelector((store) => store.vehicules);
  const { permisos } = useSelector((store) => store.auth);
  const { pathname } = useLocation();
  const [date, setDate] = useState(true);

  const {
    isStockDetailsPage,
    isNewPage,
    isDetailsPage,
    isResultsPage,
    isEntryDetailsPage,
    isArtWorkDetailsPage,
    isVehicleDetailsPage,
    isWarehousePage,
    isEntranceDetailsPage,
    isStockSurveyDetailsPage,
  } = useSelector((store) => store.pathname);
  const isDropPage = pathname.includes("bajas");
  const icons = ({
    onReportsClick,
    onFormatGuardClick,
    onLabelClick,
    getFormatA,
    getFormatB,
    getFormatC,
    getFormatD,
    onSearch,
    onNew,
    onSave,
    onEdit,
    onCancel,

    onLockClick,
  }) => {
    return [
      {
        label: "Reportes",
        onClick: onReportsClick,
        image: "/media/svg/icons/Files/DownloadedFile.svg",
        style: { margin: "0 30px" },
        disabled:
          (!isEntryDetailsPage || selectedEntries.length === 0) &&
          !isStockDetailsPage &&
          !isArtWorkDetailsPage &&
          !isVehicleDetailsPage &&
          !isDropPage,
        hide:
          isWarehousePage ||
          isStockSurveyDetailsPage ||
          pathname.includes("catalogos/tipo-de-documento"),
        color: "success",
      },
      {
        label: "Resguardo",
        onClick: onFormatGuardClick,
        image: "/media/svg/icons/Files/File.svg",
        disabled:
          !selectedStocks.length &&
          !selectedInventory?.id &&
          !selectedArtWork?.id &&
          !selectedVehicule?.id,

        hide:
          isEntryDetailsPage ||
          isWarehousePage ||
          isDropPage ||
          pathname.includes("catalogos/tipo-de-documento"),
        color: "warning",
      },
      {
        label: "Etiqueta",
        onClick: onLabelClick,
        style: { margin: "0 30px" },
        image: "/media/svg/icons/Shopping/Barcode.svg",
        disabled: !isDetailsPage || !modeOptions === "edit" || !isResultsPage,
        hide:
          isEntryDetailsPage ||
          isWarehousePage ||
          isStockSurveyDetailsPage ||
          isDropPage ||
          pathname.includes("catalogos/tipo-de-documento"),
        color: "dark",
      },
      {
        label: "Formato A",
        onClick: getFormatA,
        image: "/media/svg/icons/Files/Selected-file.svg",
        disabled: isNewPage || !enableFormats.A,
        hide:
          isEntryDetailsPage ||
          isArtWorkDetailsPage ||
          isVehicleDetailsPage ||
          isWarehousePage ||
          isDropPage ||
          pathname.includes("catalogos/tipo-de-documento"),
        color: "success",
      },
      {
        label: "Formato B",
        onClick: getFormatB,
        style: { margin: "0 30px" },
        image: "/media/svg/icons/Files/Selected-file.svg",
        disabled: isNewPage || !enableFormats.B,
        hide:
          isEntryDetailsPage ||
          isArtWorkDetailsPage ||
          isVehicleDetailsPage ||
          isWarehousePage ||
          isDropPage ||
          pathname.includes("catalogos/tipo-de-documento"),
        color: "success",
      },
      {
        label: "Formato C",
        onClick: getFormatC,
        image: "/media/svg/icons/Files/Selected-file.svg",
        disabled: isNewPage || !enableFormats.C,
        hide:
          isEntryDetailsPage ||
          isArtWorkDetailsPage ||
          isVehicleDetailsPage ||
          isWarehousePage ||
          isDropPage ||
          pathname.includes("catalogos/tipo-de-documento"),
        color: "success",
      },
      {
        label: "Formato D",
        onClick: getFormatD,
        style: { margin: "0 30px" },
        image: "/media/svg/icons/Files/Selected-file.svg",
        disabled: isNewPage || !enableFormats.D,
        hide:
          isEntryDetailsPage ||
          isArtWorkDetailsPage ||
          isVehicleDetailsPage ||
          isWarehousePage ||
          isDropPage ||
          pathname.includes("catalogos/tipo-de-documento"),
        color: "success",
      },
      {
        label: "Buscar",
        onClick: onSearch,
        style: { margin: "0 30px" },
        image: "/media/svg/icons/General/Search.svg",
        disabled: currentMode === "edit" || currentMode === "new",
        hide:
          isEntranceDetailsPage ||
          isStockSurveyDetailsPage ||
          pathname.includes("configuracion/catalogos"),
        color: "info",
      },
      {
        label: "Nuevo",
        onClick: onNew,
        style: { margin: "0 30px" },
        image: "/media/svg/icons/Files/File.svg",
        disabled:
          !isDetailsPage ||
          (currentMode === modeOptions.edit && isResultsPage) ||
          currentMode === modeOptions.new,
        hide: isStockDetailsPage || isDropPage,
        color: "info",
      },
      pathname.includes("bienes-instrumentales/entradas/resultados")
        ? {
            label: "Editar",
            onClick: onEdit,
            style: { margin: "0 30px" },
            image: "/media/svg/icons/General/Edit.svg",
            disabled: newEntryResultsForm.is_closed,
            hide: false,
            color: "info",
          }
        : {
            label: "Editar",
            onClick: onEdit,
            style: { margin: "0 30px" },
            image: "/media/svg/icons/General/Edit.svg",
            disabled: isEditDisabled || currentMode === "edit" || currentMode === "new",
            hide:
              isEntranceDetailsPage ||
              isDropPage ||
              pathname.includes("configuracion/catalogos"),
            color: "info",
          },
      {
        label: "Guardar",
        onClick: onSave,
        style: { margin: "0 30px" },
        image: "/media/svg/icons/General/Save.svg",
        disabled: disabledSave || !currentMode || currentMode === "search",
        hide:
          isDropPage ||
          pathname.includes("configuracion/catalogos/tipo-de-documento/resultados"),
        color: "primary",
      },
      {
        label: "Cancelar",
        onClick: onCancel,
        style: { margin: "0 30px" },
        image: "/media/svg/icons/Navigation/Close.svg",
        disabled: !isDetailsPage || !currentMode || isDropPage,
        hide: pathname.includes("catalogos/tipo-de-documento"),
        color: "danger",
      },

      {
        label: "Cerrar",
        onClick: onLockClick,
        style: { margin: "0 30px" },
        image: "/media/svg/icons/General/Lock.svg",
        disabled: isNewPage || !!newEntryResultsForm?.is_closed,
        hide:
          isStockDetailsPage ||
          isArtWorkDetailsPage ||
          isVehicleDetailsPage ||
          isWarehousePage ||
          isStockSurveyDetailsPage ||
          isDropPage ||
          pathname.includes("catalogos/tipo-de-documento"),
        color: "danger",
      },
      {
        label: "Finalizar",
        onClick: onFinalizeClick,
        style: { margin: "0 30px" },
        image: "/media/svg/icons/Code/Done-circle.svg",
        disabled: isFinalizeDisabled,
        hide:
          isStockDetailsPage ||
          isArtWorkDetailsPage ||
          isVehicleDetailsPage ||
          isWarehousePage ||
          isStockSurveyDetailsPage ||
          isDropPage ||
          pathname.includes("catalogos/tipo-de-documento"),
        color: "primary",
      },
      pathname.includes("catalogos/tipo-de-documento") && {
        label: "Eliminar",
        onClick: unSuscribe,
        style: { margin: "0 30px" },
        image: pathname.includes("catalogos/tipo-de-documento")
          ? "/media/svg/icons/General/Trash.svg"
          : "/media/svg/icons/Files/Download.svg",
        disabled: !isDetailsPage,
        hide:
          isWarehousePage ||
          isStockSurveyDetailsPage ||
          isEntryDetailsPage ||
          pathname.includes("nuevo"),
        color: "danger",
      },
      {
        label: pathname.includes("baja") ? "Editar baja" : "Baja",
        onClick: unSuscribe,
        style: { margin: "0 30px" },
        image: "/media/svg/icons/Files/Download.svg",
        disabled: pathname.includes("baja")
          ? !isDetailsPage
          : !isDetailsPage || !currentMode,
        hide:
          currentMode === "new" ||
          currentMode === "search" ||
          // currentMode === null ||
          isWarehousePage ||
          isStockSurveyDetailsPage ||
          isEntryDetailsPage ||
          pathname.includes("/catalogos/tipo-de-documento"),
        color: "danger",
      },
    ];
  };

  const onReportsClick = () => {
    dispatch(setOnReportClickFunction(uuid()));
  };

  const handleChange = (e) => {
    const { target } = e;
    if (target) {
      let { name, value } = target;
      dispatch(handleFormChange(name, value));
      let validNumLengt =
        value.replaceAll("_", "").replaceAll("/", "").length === 6 ||
        value.replaceAll("_", "").replaceAll("/", "").length === 8;

      if (validNumLengt) {
        setDate(false);
      } else {
        setDate(true);
      }
    }
  };
  const handleCloseModal = () => {
    dispatch(resetCurrentReport());
    dispatch(setModal(false));
  };
  const handleConfirm = async () => {
    dispatch(setLoading(true));
    try {
      let formattedFormValues = {};
      //Change format of inputs type date, to format YYYY-MM-DD
      for (let [key, value] of Object.entries(formValues)) {
        if (value) {
          switch (key) {
            case "fecha_inicio":
            case "fecha_fin": {
              formattedFormValues = {
                ...formattedFormValues,
                [key]: value,
                // .split("/")
                // .reverse()
                // .join("-"),
              };
              break;
            }
            default: {
              formattedFormValues = {
                ...formattedFormValues,
                [key]: value,
              };
            }
          }
        }
      }

      const filename = `Resguardo-${
        idsFilterResult.length === 1 ? idsFilterResult.join() : "multiple"
      }`;
      const results = await currentReport?.requestFunction(
        idsFilterResult,
        filename,
        formattedFormValues
      );

      if (results?.attachment_url) downLoaderWithURL(results?.attachment_url);
    } catch (e) {
      notice(`Ocurrió un problema durante la operación${e ? `: ${e}` : ""}`);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onFormatGuardClick = () => {
    let hadPropertie = false;
    user.groups.map((elem) => {
      if (elem.description.includes("i-resguardo-imprimir-fecha")) {
        return (hadPropertie = true);
      }
      return (hadPropertie = false);
    });
    if (hadPropertie) {
      const reports = availableReports();
      const current = reports.find((report) => parseInt(report.id) === 14);
      const { formValues, formInputs, ...rest } = current;
      dispatch(setCurrentReport(formValues, formInputs, rest));
      dispatch(setModal(true));
    } else {
      dispatch(setOnFormatGuardClick(uuid()));
    }
  };

  const onLabelClick = () => {
    dispatch(setOnLabelClick(uuid()));
  };

  const onFinalizeClick = () => {
    dispatch(setOnFinalize(uuid()));
  };

  const unSuscribe = () => {
    dispatch(setUnSuscribe(uuid()));
  };
  const onLockClick = () => {
    dispatch(setOnLockClick(uuid()));
  };

  const getFormatA = () => {
    dispatch(setOnGetFormatA(uuid()));
  };

  const getFormatB = () => {
    dispatch(setOnGetFormatB(uuid()));
  };

  const getFormatC = () => {
    dispatch(setOnGetFormatC(uuid()));
  };

  const getFormatD = () => {
    dispatch(setOnGetFormatD(uuid()));
  };

  // Have 3 ways to use.
  // When clicking from another form (to push to page if is not in that page) NOT INCLUDED ON THIS MODE, IS NECESSARY TO USE A "ISASPECIFICPAGE" VARIABLE FROM REDUX STORE
  // When clicking in that node (used to put form editable) IS NECESSARY TO USE A "ISASPECIFICPAGE" VARIABLE FROM REDUX STORE
  // When clicking for second time (used to submit after second way to use)

  const actionIconsAction = (mode) => {
    const option = modeOptions[mode];

    if (currentMode !== option) {
      mode !== "cancel" &&
        setTimeout(() => {
          if (mode !== "save") dispatch(setCurrentMode(option));
        }, 0);
      dispatch(actionsFunctions[`${mode}2`](uuid()));
      dispatch(actionsFunctions[`${mode}3`](null));
    } else if (currentMode === mode) {
      dispatch(actionsFunctions[`${mode}2`](null));
      dispatch(actionsFunctions[`${mode}3`](uuid()));
      mode !== "cancel" &&
        setTimeout(() => {
          dispatch(setCurrentMode(null));
        }, 0);
    }
  };

  const onSearch = () => actionIconsAction("search");
  const onNew = async () => {
    await dispatch(clearSelectedEntry());
    actionIconsAction("new");
  };
  const onSave = async () => {
    await dispatch(setDisabledSaveFalse());
    actionIconsAction("save");
  };
  const onEdit = async () => {
    await dispatch(setDisabledSaveFalse());

    actionIconsAction("edit");
  };

  const onCancel = () => actionIconsAction("cancel");

  const Buttons = (Label) => {
    if (Label === "Editar") {
      if (
        pathname.includes("/bienes-instrumentales/obras-arte/resultados") &&
        permisos.permisos.includes("i-editar-obraarte")
      ) {
        return true;
      } else if (
        pathname.includes("/bienes-instrumentales/inventario-detalles/resultados/") &&
        permisos.permisos.includes("i-editar-inventario")
      ) {
        return true;
      } else if (
        pathname.includes("/bienes-instrumentales/vehiculos/resultados") &&
        permisos.permisos.includes("i-editar-vehiculo")
      ) {
        return true;
      } else if (
        pathname.includes("/bienes-instrumentales/levantamiento-inventario") &&
        permisos.permisos.includes("i-editar-levantamiento")
      ) {
        return true;
      } else if (
        pathname.includes("/bienes-instrumentales/entradas") &&
        (permisos.permisos.includes("i-editar-entrada") ||
          pathname.includes("/bienes-instrumentales/entradas")) &&
        permisos.permisos.includes("i-editar-entrada-cerrada")
      ) {
        return true;
      } else {
        return false;
      }
    } else if (Label === "Nuevo") {
      if (
        pathname.includes("/bienes-instrumentales/entradas/resultados/") &&
        permisos.permisos.includes("i-crear-entrada")
      ) {
        return true;
      } else if (
        pathname.includes("/bienes-instrumentales/levantamiento-inventario") &&
        permisos.permisos.includes("i-crear-levantamiento")
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (
      newEntryResultsForm?.is_closed &&
      permisos.permisos.includes("i-editar-entrada-cerrada")
    ) {
      dispatch(setIsEditDisabled(false));
    }
  }, [dispatch, newEntryResultsForm?.is_closed, permisos.permisos]);
  return (
    <div className="d-flex flex-wrap align-items-center w-100">
      {isResultsPage && (
        <Chip label={label} color="#E52441" style={{ marginRight: 10 }} />
      )}
      {icons({
        onReportsClick,
        onFormatGuardClick,
        onLabelClick,
        onLockClick,
        unSuscribe,
        getFormatA,
        getFormatB,
        getFormatC,
        getFormatD,
        onSearch,
        onNew,
        onSave,
        onEdit,
        onCancel,
      }).map((icon) =>
        icon.hide ? null : (icon.label === "Nuevo" && Buttons("Nuevo") === false) ||
          (icon.label === "Editar" && Buttons("Editar") === false) ? (
          <></>
        ) : (
          <div
            key={icon.label || icon.tooltip}
            className={`d-flex flex-column align-items-center cursor-pointer flex-nowrap`}
          >
            <button
              className={`btn btn-icon btn-${icon.color} btn-sm`}
              onClick={icon.onClick}
              disabled={icon.disabled}
              style={{
                cursor: icon.disabled && "not-allowed",
                width: 55,
                height: 50,
                margin: "0px 2px",
                fontSize: 10,
              }}
            >
              <span className={`svg-icon svg-icon-md text-nowrap font-weight-bold`}>
                <SVG src={toAbsoluteUrl(icon.image)} width={500} height="auto" />
                <br />
                {icon.label}
              </span>
            </button>
          </div>
        )
      )}
      <BaseModal
        title={"Inventario de bienes instrumentales "}
        open={modalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        confirmLabel={"Descargar"}
        onCancel={handleCloseModal}
        cancelLabel={"Cancelar"}
        color={"secondary"}
        disableOnConfirm={date}
      >
        <UtilInputs
          width={261}
          inputValues={formInputs}
          formValues={formValues}
          onValuesChange={handleChange}
        />
      </BaseModal>
    </div>
  );
};

export default SubheaderIcons;
