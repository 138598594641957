import { makeStyles, Paper, Typography, lighten } from '@material-ui/core';
import React from 'react';
import MaterialItem from './MaterialItem';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		whiteSpace: 'nowrap',
		overflowX: 'auto',
		overflowY: 'hidden',
		width: '100%',
	},
	mainCard: {
		display: 'flex',
		flexDirection: 'column',
	},
  paperRoot: {
		display: 'inline-flex',
  },
	card1: {
		padding: '5px 15px',
		boxShadow: 'none',
		border: 'solid 2px transparent',
		borderRight: `solid 1px ${lighten(theme.palette?.gray?.main || '#9A9A9A', 0.8)}`,
		borderRadius: 0,
		transition: 'border 0.1s ease',
		'&>.MuiCardContent-root:last-child': {
			padding: 0,
		},
		height: 45,
		maxHeight: 45,
		display: 'flex',
		alignItems: 'center'
	},
	head: {
    borderRight: 'solid 1px #d9d9d9',
    borderBottom: 'solid 1px #d9d9d9',
    padding: '0.5em 0.5em',
	}
}));

const RequestMaterialList = ({ columns=[], items=[], onAddItem, onQuantityChange, onEditItem, onDeleteItem, onCheckItem, onValuesChange }) => {
	const classes = useStyles({columns, items});
   return (
         <div style={{
         	overflowY: 'auto',
					width: '100%'
				 }}>
           <Paper elevation={2} classes={{
             root: classes.paperRoot
           }}>
             {
               columns.map((column) => {
                 return (
                   <div style={{
                     display: 'grid',
                     gridTemplateColumns: column?.width ?? '100px',
                     gridTemplateRows: `auto repeat(${items.length}, minmax(50px, 70px))`
									 }} key={column.value}>
                     <div className={classes.head}>
                       <Typography>{column.text}</Typography>
                     </div>
                      {
                       items.map((item, index) => (
                         <MaterialItem
                           value={item[column.value]}
                           index={index}
                           column={column.value}
                           type={column.type}
                           item={item}
                           options={column.options ? column.options : []}
                           key={item.id}
                           onAddItem={onAddItem}
                           onEditItem={(...args) => onEditItem(...args, index)}
                           onDeleteItem={onDeleteItem}
                           onCheckItem={(...args) => onCheckItem(...args, index)}
                           onQuantityChange={onQuantityChange}
                           width={column.width}
                           disabled={column.disabled}
                           onValuesChange={onValuesChange}
                         />
                       ))
                      }
                   </div>
                 )
               })
             }
           </Paper>
				 </div>
	 )
};

export default RequestMaterialList;