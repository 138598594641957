import React from "react";
import { useSelector } from "react-redux";
import { guardTabForm } from "../utils/vehiculesData";
import UtilInputs from "./UtilInputs";
import { toggleEnableInputs } from "../utils/newEntryData";

const VehiculesGuardTab = ({ formValues, handleChange }) => {
  const { enableInputs } = useSelector((store) => store.vehicules);
  const { isResultsPage } = useSelector((store) => store.pathname);
  const { currentMode } = useSelector((store) => store.subHeaderActions);

  const formValues2 = () => {
    if (isResultsPage) {
      if (formValues["cf_area"]?.id === undefined) {
        return { ...formValues, cf_area: `${formValues["cf_area"]}` };
      } else {
        return { ...formValues, cf_area: `${formValues["cf_area"].id}` };
      }
    } else {
      return formValues;
    }
  };

  return (
    <div style={{ maxWidth: 700 }}>
      <UtilInputs
        formValues={formValues2()}
        inputValues={
          !enableInputs
            ? toggleEnableInputs(guardTabForm(currentMode), enableInputs, "obras-arte")
            : guardTabForm(currentMode)
        }
        onValuesChange={handleChange}
      />
    </div>
  );
};

export default VehiculesGuardTab;
