import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setClearState, setPermit, setPermits, setSelectedPermits } from '../../../../redux/ducks/permits.duck'
import FullTable from '../../../components/FullTable'
import notice from '../../../components/Notice'
import { getPermissions, getSinglePermission } from '../../../crud/permitsCrud'
import { permitsTableColumns } from '../../../utils/configData'

const ConfigPermits = () => {
  const { results, count } = useSelector(state => state.permits.permits)
  const dispatch = useDispatch();
  const history = useHistory()
  const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(20);
  const [selectedIds, setSelectedIds] = useState([])
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    setLoading(true)
    getPermissions(page, sizePerPage)
    .then(res => {
      if (res.status === 200)
        dispatch(setPermits(res.data))
    })
    .catch(e => {
      console.log(e)
      notice("Error cargando la lista de permisos")
    })
    .finally(() => setLoading(false))
  }, [dispatch, page, sizePerPage])

  const handleNew = () => {
    dispatch(setClearState())
    history.push('/inventario-bienes-almacen-general/configuracion/usuarios/permisos/nuevo')
  }
  
  const onEye = (id) => {
    dispatch(setClearState())
    getSinglePermission(id)
    .then(res => {
      if (res.status === 200) {
        dispatch(setPermit(res.data))
        history.push(`/inventario-bienes-almacen-general/configuracion/usuarios/permisos/resultados/${id}`)
      }
    }).catch(e => {
      console.log(e)
      notice("Error cargando el permiso")
    })
  }

  const handleSelectAllRows = (isSelect, rows) => isSelect ? setSelectedIds(rows.map(r => r.id)) : setSelectedIds([])

  const handleSelectRow = (row, isSelect) => {
		if(row && row.id){
			if(!isSelect){
				const newSelectedEntries = selectedIds.filter(id => id !== row.id);
				setSelectedIds(newSelectedEntries);
			}else{
				setSelectedIds([...selectedIds, row.id]);
			};
		};	
	};

  const handleReqSelected = () => {
    dispatch(setClearState())
    dispatch(setSelectedPermits(selectedIds))
    getSinglePermission(selectedIds[0])
    .then(res => {
      if (res.status === 200) {
        dispatch(setPermit(res.data))
        history.push(`/inventario-bienes-almacen-general/configuracion/usuarios/permisos/resultados/${selectedIds[0]}`)
      }
    }).catch(e => {
      console.log(e)
      notice("Error cargando el permiso")
    })
  }

  return (
    <FullTable
      title='Permisos'
      // NEW VEHICULE TRIGGER
      buttonTitle='Nuevo Permiso'
      onClickButton={handleNew}
      // TABLE ENTITIES
      entities={results}
      columns={permitsTableColumns(onEye)}
      page={page}
      setPage={page => page > 0 && setPage(page)}
      sizePerPage={sizePerPage}
      setSizePerPage={setSizePerPage}
      count={count}
      loading={loading}
      // FILTERS ACTIONS
      search_button={false}
      // SELECTED VEHICULES ACTIONS
      has_button2
      buttonDisabled2={selectedIds.length < 1}
      buttonTitle2={`Consultar Todos${selectedIds.length > 1 ? `(${selectedIds.length.toString()})` : ''}`}
      onSelectRow={handleSelectRow}
			onSelectAllRows={handleSelectAllRows}
			onClickButton2={handleReqSelected}
		/>
  )
}

export default ConfigPermits
