import { setLoader } from "../../redux/ducks/loader.duck";

export const getCABMSKey = ({
  cf_grupo = "",
  cf_subgrupo = "",
  cf_clase = "",
  cf_subclase = "",
  cf_tipo = "",
}) => `${cf_grupo}·${cf_subgrupo}·${cf_clase}·${cf_subclase}·${cf_tipo}`;

export const getKeyData = (key) => {
  const [cf_grupo, cf_subgrupo, cf_clase, cf_subclase, cf_tipo] = key?.split("·");
  return {
    cf_grupo,
    cf_subgrupo,
    cf_clase,
    cf_subclase,
    cf_tipo,
  };
};
