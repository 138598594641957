import React from "react";
import { makeStyles } from "@material-ui/core";
import Icon from "./Icon";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

const useStyles = makeStyles({
  icons: {
    display: "flex",
    marginLeft: 10,
    alignItems: "center",
    flexWrap: "wrap",
    "&>*": {
      marginRight: 10,
    },
  },
});

const EntryActionIcons = ({
  handleSubmit = () => {},
  handleEdit = () => {},
  handleReset = () => {},
  handleLock = () => {},
  handleDraft = () => {},
  handleSearch = () => {},
  handleTrash = () => {},
  disabledIcons = {
    save: false,
    edit: false,
    lock: false,
    draft: false,
    search: false,
    trash: false,
    reset: false,
  },
  showIcons = {
    save: true,
    edit: false,
    lock: false,
    draft: false,
    search: false,
    trash: false,
    reset: false,
  },
  labels,
}) => {
  const classes = useStyles();
  const { save, edit, lock, draft, search, trash, reset } = disabledIcons;
  return (
    <div className={classes.icons}>
      {showIcons.draft && (
        <Icon
          src={toAbsoluteUrl("/media/svg/icons/Design/Eraser.svg")}
          color="inherit"
          onClick={handleDraft}
          label={labels?.draft ?? "Borrador"}
          //disabled={!selectedEntry || selectedEntry.is_closed}
          disabled={draft}
        />
      )}
      {showIcons.save && (
        <Icon
          src={toAbsoluteUrl("/media/svg/icons/General/Save.svg")}
          color="primary"
          onClick={handleSubmit}
          label={labels?.save ?? "Guardar"}
          //disabled={!!selectedEntry || loading}
          disabled={save}
        />
      )}
      {showIcons.edit && (
        <Icon
          src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
          color="success"
          onClick={handleEdit}
          label={labels?.edit ?? "Editar"}
          //disabled={!selectedEntry || selectedEntry.is_closed || loading}
          disabled={edit}
        />
      )}
      {showIcons.reset && (
        <Icon
          src={toAbsoluteUrl("/media/svg/icons/General/Update.svg")}
          color="info"
          onClick={handleReset}
          label={labels?.reset ?? "Reiniciar"}
          //disabled={!selectedEntry || selectedEntry.is_closed || loading}
          disabled={reset}
        />
      )}
      {showIcons.lock &&
        !window.location.pathname.includes("entradas/resultados") && (
          <Icon
            src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")}
            color="success"
            onClick={handleLock}
            label={labels?.lock ?? "Cerrar"}
            //disabled={!selectedEntry || selectedEntry.is_closed}
            disabled={lock}
            hide={true}
          />
        )}
      {showIcons.search && (
        <Icon
          src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
          color="info"
          onClick={handleSearch}
          label={labels?.search ?? "Buscar"}
          disabled={search}
        />
      )}
      {showIcons.trash && (
        <Icon
          src={toAbsoluteUrl("/media/svg/icons/Home/Trash.svg")}
          color="info"
          onClick={handleTrash}
          label={labels?.trash ?? "Borrar"}
          disabled={trash}
        />
      )}
    </div>
  );
};

export default EntryActionIcons;
