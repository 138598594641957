import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import FullTable from "../../components/FullTable";
import BaseModal from "../../components/BaseModal";
import notice from "../../components/Notice";
import {
  clearSelectedEntry,
  setEntries,
  setEnableInputs,
  selectEntries,
} from "../../../redux/ducks/entry.duck";
import {
  closeAsset,
  defaultEntriesFilter,
  getAssetAdq,
  getEntriesFilter,
} from "../../crud/entryCrud";
import { setCurrentMode } from "../../../redux/ducks/subHeaderActions.duck";
import { modeOptions } from "../../utils/subheaderIconsData";
import { filterInputs } from "../../utils/fullTableData";
import { columns } from "../../utils/tableData";

const EntriesPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { permisos } = useSelector((store) => store.auth);
  const { pathname } = useLocation();
  const { entries: data, reload } = useSelector((store) => store.entry);
  const [formFilters, setFormFilters] = useState(defaultEntriesFilter);
  const [loading, setLoading] = useState(true);
  const [lockConfirmModal, setLockConfirmModal] = useState(false);
  const [selectedLock, setSelectedLock] = useState(null);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [selectedEntriesIds, setSelectedEntriesIds] = useState([]);

  const handleonClickSearch = () => {
    history.push("/bienes-instrumentales/entradas/busqueda");
    dispatch(setCurrentMode(modeOptions.search));
    //setFilterSelected(filterSelected => !filterSelected)
  };

  const handleFilters = (e) => {
    const { name, value } = e.target;
    setFormFilters((formFilters) => ({ ...formFilters, [name]: value }));
  };

  const fixNewEntries = (res) => {
    const newEntries = {
      count: res.count,
      results: res.results.map(({ user, ...rest }) => ({
        ...rest,
        user: `${user.first_name} ${user.last_name}`,
        // De momento es así, luego se cambiará en el backend la forma de guardar/recibir el campo "Recibido en"
        cf_no_almacen: rest.cf_no_almacen === 1 ? "Área" : "Almacén",
      })),
    };
    return newEntries;
  };

  const onConfirmFilter = () => {
    setLoading(true);
    getAssetAdq(sizePerPage, page, formFilters)
      .then((res) => {
        dispatch(setEntries(fixNewEntries(res)));
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const onClearFilter = () => {
    setLoading(true);
    setFormFilters(defaultEntriesFilter);
    return getAssetAdq(sizePerPage, page)
      .then((res) => {
        dispatch(setEntries(fixNewEntries(res)));
        setLoading(false);
      })
      .catch((err) => console.log("err", err));
  };

  const handleAdd = () => {
    dispatch(clearSelectedEntry());
    dispatch(setCurrentMode(modeOptions.new));
    history.push("/bienes-instrumentales/entradas/nueva");
  };

  useEffect(() => {
    setLoading(true);
    getAssetAdq(sizePerPage, page, formFilters)
      .then((res) => {
        dispatch(setEntries(fixNewEntries(res)));
        setLoading(false);
      })
      .catch((err) => console.log("err", err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sizePerPage, reload, dispatch]);

  const onEye = (id) => {
    history.push(`/bienes-instrumentales/entradas/resultados/${id}`);
  };

  const onLock = (id) => {
    setLockConfirmModal(true);
    setSelectedLock(id);
  };

  const onConfirmLock = () => {
    setLockConfirmModal(false);
    setLoading(true);
    closeAsset(selectedLock)
      .then((res) => {
        if (res && res.status === 200) {
          notice(res.data.message || "La entrada de cerró satisfactoriamente", "success");
          const newEntries = data.results.map((entry) =>
            entry.id === selectedLock ? { ...entry, is_closed: true } : { ...entry }
          );
          dispatch(setEntries({ ...data, results: newEntries }));
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        notice("Ha ocurrido un error con el servidor", "error");
        setLoading(false);
      });
  };

  const handleSelectRow = (row, isSelect) => {
    if (row && row.id) {
      if (!isSelect) {
        const newSelectedEntry = selectedEntriesIds.filter((id) => id !== row.id);
        setSelectedEntriesIds(newSelectedEntry);
      } else setSelectedEntriesIds([...selectedEntriesIds, row.id]);
    }
  };

  const onClickQuerySelected = () => {
    let filteredEntries = data.results
      .filter((entry) => selectedEntriesIds.includes(entry.id))
      .map((x) => x.id);
    dispatch(clearSelectedEntry());
    setLoading(true);
    if (filteredEntries) {
      getEntriesFilter(page, filteredEntries.length, filteredEntries)
        .then((res) => {
          const newEntries = res.data.results;
          dispatch(selectEntries(newEntries));
          dispatch(setEnableInputs(false));
          dispatch(setCurrentMode(null));

          history.push(`/bienes-instrumentales/entradas/resultados/${newEntries[0].id}`);
        })
        .catch(() => {
          dispatch(setCurrentMode(modeOptions.search));
        });
    }
    setLoading(false);
  };

  const handleSelectAllRows = (isSelect, rows) => {
    if (!isSelect) {
      setSelectedEntriesIds([]);
    } else {
      const selectedRowsIds = rows.map((row) => row.id);
      setSelectedEntriesIds(selectedRowsIds);
    }
  };

  return (
    <>
      <FullTable
        Permisos={permisos.permisos}
        pathname={pathname}
        title="Entradas"
        buttonTitle="Nueva entrada"
        onClickButton={handleAdd}
        onClickSearch={handleonClickSearch}
        entities={data.results}
        columns={columns(onEye, onLock, permisos.permisos)}
        page={page}
        setPage={(page) => page > 0 && setPage(page)}
        sizePerPage={sizePerPage}
        setSizePerPage={setSizePerPage}
        count={data.count}
        loading={loading}
        onSelectRow={handleSelectRow}
        onSelectAllRows={handleSelectAllRows}
        has_button2
        buttonDisabled2={selectedEntriesIds.length < 1}
        buttonTitle2={`Consultar Todos${
          selectedEntriesIds.length > 1 ? `(${selectedEntriesIds.length.toString()})` : ""
        }`}
        onClickButton2={onClickQuerySelected}
        // FILTERS
        has_filter
        formFilters={formFilters}
        onFilterChange={handleFilters}
        filterInputs={filterInputs}
        onConfirmFilter={onConfirmFilter}
        onClearFilter={onClearFilter}
      />
      <BaseModal
        title="¿Estás seguro de que deseas cerrar esta entrada?"
        open={lockConfirmModal}
        onClose={() => setLockConfirmModal(false)}
        onConfirm={onConfirmLock}
        confirmLabel="Si, cerrar entrada"
        onCancel={() => setLockConfirmModal(false)}
        cancelLabel="No"
        color="secondary"
      />
    </>
  );
};

export default EntriesPage;
