import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setClearState, setPresentation, setPresentations, setSelectedPresentations } from '../../../../redux/ducks/presentations.duck'
import { getPresentationsList, getSinglePresentation } from '../../../crud/presentationsCrud'
import FullTable from '../../../components/FullTable'
import notice from '../../../components/Notice'
import { presentTypesTableColumns } from '../../../utils/configData'

const ConfigPresentationTypes = () => {
  const { results, count } = useSelector(state => state.presentations.presentations)
  const dispatch = useDispatch();
  const history = useHistory()
  const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(20);
  const [selectedIds, setSelectedIds] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    getPresentationsList(page, sizePerPage)
    .then(res => dispatch(setPresentations(res)))
    .catch(e => notice("Error cargando la lista de tipos de presentación"))
    .finally(() => setLoading(false))

  }, [dispatch, page, sizePerPage])

  const handleNew = () => {
    dispatch(setClearState())
    history.push('/inventario-bienes-almacen-general/configuracion/articulos/tipos-presentacion/nuevo')
  }

  const onEye = (id) => {
    getSinglePresentation(id)
    .then(res => {
      dispatch(setPresentation(res))
      history.push(`/inventario-bienes-almacen-general/configuracion/articulos/tipos-presentacion/resultados/${id}`)
    })
    .catch(e => notice("Error cargando el tipo de presentación"))
  }

  const handleSelectAllRows = (isSelect, rows) => isSelect ? setSelectedIds(rows.map(r => r.id)) : setSelectedIds([])

  const handleSelectRow = (row, isSelect) => {
		if(row && row.id){
			if(!isSelect){
				const newSelectedEntries = selectedIds.filter(id => id !== row.id);
				setSelectedIds(newSelectedEntries);
			}else{
				setSelectedIds([...selectedIds, row.id]);
			};
		};
	};

  const handleReqSelected = () => {
    dispatch(setClearState())
    dispatch(setSelectedPresentations(selectedIds))
    getSinglePresentation(selectedIds[0])
    .then(res => {
      dispatch(setPresentation(res))
      history.push(`/inventario-bienes-almacen-general/configuracion/articulos/tipos-presentacion/resultados/${res.id}`)
    })
    .catch(e => notice("Error cargando el tipo de presentación"))
  }

  return (
    <FullTable
      title='Presentaciones de proveedores'
      // NEW VEHICULE TRIGGER
      buttonTitle='Nueva presentación'
      onClickButton={handleNew}
      // TABLE ENTITIES
      entities={results}
      columns={presentTypesTableColumns(onEye)}
      page={page}
      setPage={page => page > 0 && setPage(page)}
      sizePerPage={sizePerPage}
      setSizePerPage={setSizePerPage}
      count={count}
      loading={loading}
      // FILTERS ACTIONS
      search_button={false}
      // SELECTED VEHICULES ACTIONS
      has_button2
      buttonDisabled2={selectedIds.length < 1}
      buttonTitle2={`Consultar Todos${selectedIds.length > 1 ? `(${selectedIds.length.toString()})` : ''}`}
      onSelectRow={handleSelectRow}
			onSelectAllRows={handleSelectAllRows}
			onClickButton2={handleReqSelected}
		/>
  )
}

export default ConfigPresentationTypes
