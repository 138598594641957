import React, { Fragment, useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import ItemsList from "./ItemsList";
import IconButton from "./IconButton";
import { Pagination } from "@material-ui/lab";
import TableLoader from "./TableLoader";

export const arrayOfArrays = (oArray, cantidadPaginas) => {
  const size = Math.round(oArray.length / cantidadPaginas);
  let chunk = [];
  for (var i = 0; i < oArray.length; i += size) {
    chunk.push(oArray.slice(i, i + size));
  }
  return chunk;
};

const BatchTable = ({
  i,
  items,
  columns,
  handleChange,
  addRow,
  disableAddItem = false,
  disabled = false,
  onSearchTextChange = () => {},
  footer = [],
  deleteRow = () => {},
  handleSave,
  handleEdit,
  disableIndex = [],
  disableColumns = [],
  editOption = false,
  itemsOptions,
  checkedEncontrado,
  setCheckedEncontrado,
  checkedLocalizado,
  setCheckedLocalizado,
  handleCheckEncontrado,
  handleCheckLocalizado,
  currentMode,
}) => {
  const [Page, SetPage] = useState(1);
  const [Loading, SetLoading] = useState(false);
  const largo = items.length;
  const allItems = items;

  useEffect(() => {
    SetLoading(false);
  }, [Page]);

  if (largo > 200) {
    const cantidadPaginas = 15;
    const ArrayNuevo = arrayOfArrays(items, cantidadPaginas);

    const handleChangePaginate = (event, value) => {
      SetLoading(true);
      return SetPage(value);
    };

    if (Loading === true) {
      return (
        <div style={{ textAlign: "center" }}>
          <TableLoader />
        </div>
      );
    } else {
      return (
        <div className="mb-5 position-relative">
          <Fragment>
            <ItemsList
              allItems={allItems}
              items={ArrayNuevo[Page - 1]}
              columns={columns}
              handleChange={(column, index, e) =>
                handleChange(
                  i,
                  column,
                  e.target.value,
                  index,
                  Page - 1,
                  cantidadPaginas,
                  largo
                )
              }
              disabled={disabled}
              onSearchTextChange={(column, index, e) =>
                onSearchTextChange(i, column, e.target.value, index)
              }
              footer={
                footer?.some((f) => Boolean(f.value)) ? footer : undefined
              }
              handleRemoveItem={deleteRow}
              handleSaveInventory={handleSave}
              disableIndex={disableIndex}
              disableColumns={disableColumns}
              editOption={editOption}
              handleEditAction={handleEdit}
              itemsOptions={itemsOptions}
            />
            <div style={{ padding: 5, width: 130 }}>
              {!disableAddItem && (
                <IconButton
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Plus.svg")}
                  label="Agregar item"
                  onClick={() => addRow(i)}
                />
              )}
            </div>
            <Pagination
              count={cantidadPaginas}
              page={Page}
              onChange={handleChangePaginate}
              color="primary"
            />
          </Fragment>
        </div>
      );
    }
  } else {
    return (
      <div className="mb-5 position-relative">
        <Fragment>
          <ItemsList
            allItems={allItems}
            items={items}
            columns={columns}
            handleChange={(column, index, e) =>
              handleChange(i, column, e.target.value, index)
            }
            disabled={disabled}
            onSearchTextChange={(column, index, e) =>
              onSearchTextChange(i, column, e.target.value, index)
            }
            footer={footer?.some((f) => Boolean(f.value)) ? footer : undefined}
            handleRemoveItem={deleteRow}
            handleSaveInventory={handleSave}
            disableIndex={disableIndex}
            disableColumns={disableColumns}
            editOption={editOption}
            handleEditAction={handleEdit}
            itemsOptions={itemsOptions}
            checkedEncontrado={checkedEncontrado}
            setCheckedEncontrado={setCheckedEncontrado}
            checkedLocalizado={checkedLocalizado}
            setCheckedLocalizado={setCheckedLocalizado}
            handleCheckEncontrado={handleCheckEncontrado}
            handleCheckLocalizado={handleCheckLocalizado}
            currentMode={currentMode}
          />
          <div style={{ padding: 5, width: 130 }}>
            {!disableAddItem && (
              <IconButton
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Plus.svg")}
                label="Agregar item"
                onClick={() => addRow(i)}
              />
            )}
          </div>
        </Fragment>
      </div>
    );
  }
};
export default BatchTable;
