import React, { useState } from 'react'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, makeStyles } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
	content: {
    display: "block",
    cursor: ({ disableOpenHeader }) => disableOpenHeader ? "default" : "pointer"
	},
}));

const Accordion = ({ header, children, disableOpenHeader=false }) => {
  const classes = useStyles({ disableOpenHeader })
  const [expanded, setExpanded] = useState(false)

  return (
      <ExpansionPanel expanded={expanded}>
        <ExpansionPanelSummary
          classes={classes}
          onClick={() => !disableOpenHeader && setExpanded(!expanded)}
          expandIcon={<span onClick={() => setExpanded(!expanded)}><ExpandMore /></span>}
          >
          {header}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="flex-column">
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
  )
}

export default Accordion
