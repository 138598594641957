import React, { useState, useEffect } from "react";

import CustomButton from "../../../components/CustomButton";
import SimpleInput from "../../../components/SimpleInput";
import UtilInputs from "../../../components/UtilInputs";
import { finantialInputs, finantialInputsSearch } from "../../../utils/stockData";
import { fieldsToTypeText } from "../../../utils/objectsFunctions";
import { getProvidersMultiple, getBatchesMultiple } from "../../../crud/catalogsCrud";

export const FinantialTab = ({ selectedStock, onChange, currentMode }) => {
  const [providers, setProviders] = useState([]);
  const [partidas, setPartidas] = useState([]);
  useEffect(() => {
    getProvidersMultiple()
      .then((res) => {
        res.length > 0 && setProviders(res);
      })
      .catch(() => setProviders([]));

    getBatchesMultiple()
      .then((res) => res.length > 0 && setPartidas(res))
      .catch(() => setPartidas([]));
  }, []);

  let inputValues =
    currentMode === "search"
      ? finantialInputsSearch(!currentMode, providers, partidas)
      : finantialInputs(!currentMode);

  const handleGetEntryInfoButton = () =>
    window.open(
      `/bienes-instrumentales/entradas/resultados/${selectedStock.asset_acquisition_id}`,
      "_blank"
    );

  return (
    <>
      <UtilInputs
        inputValues={inputValues}
        onValuesChange={onChange}
        formValues={selectedStock}
        width={200}
      />
      {selectedStock.id && selectedStock.asset_acquisition_details && (
        <div className="d-flex align-items-end">
          <CustomButton
            color="primary"
            title="Ver datos de entrada"
            style={{
              marginRight: 15,
              height: 40,
              marginTop: 30,
            }}
            onClick={handleGetEntryInfoButton}
          />
          <SimpleInput
            disabled
            label="No. de entrada"
            value={selectedStock.asset_acquisition_id}
          />
        </div>
      )}
    </>
  );
};
