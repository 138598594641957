import React from "react";
import { columnStatusCssClasses } from "../../utils/columnFormattersHelpers";

export const UserStatusColumnFormatter = (cellContent, row) => {
  return (
    <span
      className={`badge badge-pill badge-${columnStatusCssClasses[row.is_active ? 1 : 0]} label-inline`}
    >
			{row.is_active ? "Activo" : "Baja"}
		</span>
  );
};
