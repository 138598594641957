import React from 'react'
import EyeColumnIcon from './list-icons/EyeColumnIcon';

export const DropActionColumn = (cellContent, row, rowIndex, { onEye }) => {
  return (
    <>
      <EyeColumnIcon onClick={() => onEye(cellContent)} />
    </>
  )
}
