import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Switch } from 'react-router-dom'
import { ContentRoute } from '../../../_metronic/layout'
import DigitalizationDocs from './DigitalizationDocs'
import DigitalVaultPage from './DigitalVaultPage'

const DigitalVaultWrapper = () => {
  const { isWarehousePage, isInstrumentalGoodsPage } = useSelector(store => store.pathname);
  if (isInstrumentalGoodsPage)
  return (
    <Switch>
      <Redirect exact from="/bienes-instrumentales/boveda-digital" to="/bienes-instrumentales/boveda-digital/carpetas/" />
      <ContentRoute path="/bienes-instrumentales/boveda-digital/carpetas" component={DigitalVaultPage} />
      <ContentRoute path="/bienes-instrumentales/boveda-digital/digitalizacion" component={DigitalizationDocs} />
    </Switch>
  )
  if (isWarehousePage)
  return (
    <Switch>
      <Redirect exact from="/inventario-bienes-almacen-general/boveda-digital" to="/inventario-bienes-almacen-general/boveda-digital/carpetas/" />
      <ContentRoute path="/inventario-bienes-almacen-general/boveda-digital/carpetas" component={DigitalVaultPage} />
      <ContentRoute path="/inventario-bienes-almacen-general/boveda-digital/digitalizacion" component={DigitalizationDocs} />
    </Switch>
  )
  
  return (
    <Switch>
      <Redirect exact from="/boveda-digital" to="/boveda-digital/carpetas/" />
      <ContentRoute path="/boveda-digital/carpetas" component={DigitalVaultPage} />
      <ContentRoute path="/boveda-digital/digitalizacion" component={DigitalizationDocs} />
    </Switch>
  )
}

export default DigitalVaultWrapper
