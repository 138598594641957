import React from 'react';
import { makeStyles, CardMedia, Card, CardContent, Typography, CardActions } from '@material-ui/core';
import CustomButton from './CustomButton';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme =>({  
    card: {
        width: 310,
        minHeight: 380,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    headerImage: {
        height: 150
    },
    moduleTitle: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        lineHeight: '1.5rem'
    },
    moduleDescription: {
        lineHeight: '1rem',
        marginTop: 5
    },
    buttonContainer: {
        margin: "0px 0px 10px 0px",
        justifyContent: 'flex-end'
    }
}));

const ModuleCard = ({ 
    title="", 
    description="", 
    image="" ,
    buttonTitle="Administrar",
    onClick=() => {}
}) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
                <CardMedia 
                    className={classes.headerImage}
                    image={image}
                    data-testid="card-media"
                />
                <CardContent>
                    <Typography variant="h5" className={classes.moduleTitle}>{title}</Typography>
                    <Typography className={classes.moduleDescription}>{description}</Typography>
                </CardContent>
            </div>
                <CardActions className={classes.buttonContainer}>
                    <CustomButton onClick={onClick} title={buttonTitle} color="primary"/>
                </CardActions>
        </Card>
    );
};

ModuleCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    buttonTitle: PropTypes.string,
    onClick: PropTypes.func
};

export default ModuleCard;