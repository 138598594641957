import { axios } from "../../redux/setupAxios";
import { formatDate } from "../utils/dates";
import {
  getMultipartParams,
  handleResponse,
  handleFormData,
  getParams,
  handleError,
} from "./helpers/validate";
import {
  AQUISITIONS_API,
  INVENTORY_API,
  UPLOAD_FILE_URL,
  BASE_URL,
  INSTRUMENTAL_GOODS,
  MULTIPLE_UPLOAD_FILE_URL,
} from "./helpers/routes";
import notice from "../components/Notice";

export const defaultStockFilter = {
  no_inventario: "",
  no_inventa: "",
  key: "",
  cabms: "",
  cf_marca: "",
  cf_serie: "",
  cve_area: null,
  descripcion: "",
  ingreso: "",
  seguros: "",
  clasificacion_sicaf: "",
  tipo_registro: "",
  acontabilidad: "",
  bcontabilidad: "",
  eninventario: "",
  rpnt: "",
  bpnt: "",
  partida_presupuestal: null,
};

export const formData = (stock) => ({
  ...stock,
  cabms: getKey(stock.catalog_cabms),
  key: getKey(stock),
  cabms_cf_denominacion: stock.catalog_cabms ? stock.catalog_cabms.cf_denominacion : null,
  item_cf_denominacion: stock.catalog_clave ? stock.catalog_clave.cf_denominacion : null,
  cabms_key: `${
    stock.catalog_cabms?.cf_grupo || stock.catalog_cabms?.cf_grupo === 0
      ? stock.catalog_cabms?.cf_grupo
      : ""
  }·${
    stock.catalog_cabms?.cf_subgrupo || stock.catalog_cabms?.cf_subgrupo === 0
      ? stock.catalog_cabms?.cf_subgrupo
      : ""
  }·${
    stock.catalog_cabms?.cf_clase || stock.catalog_cabms?.cf_clase === 0
      ? stock.catalog_cabms?.cf_clase
      : ""
  }·${
    stock.catalog_cabms?.cf_subclase || stock.catalog_cabms?.cf_subclase === 0
      ? stock.catalog_cabms?.cf_subclase
      : ""
  }·${
    stock.catalog_cabms?.cf_tipo || stock.catalog_cabms?.cf_tipo === 0
      ? stock.catalog_cabms?.cf_tipo
      : ""
  }`,
  cabms_item:
    stock.catalog_cabms?.cf_denominacion || stock.catalog_cabms?.cf_denominacion === 0
      ? stock.catalog_cabms?.cf_denominacion?.trim()
      : "",
  item:
    stock.catalog_clave?.cf_denominacion || stock.catalog_clave?.cf_denominacion === 0
      ? stock.catalog_clave?.cf_denominacion
      : "",
  cf_articulo:
    stock.cf_articulo != null
      ? stock.cf_articulo
      : stock.catalog_clave?.cf_denominacion.trim(),
  cf_centros_costos: stock.area_information?.centros_costos?.cos_nombre,
  cf_responsables: getNames(stock.area_information?.area_attendant),
  cf_ubicacion: stock.area_information?.num_ubica,
  cve_partida: {
    id: stock.partida?.id,
    text: stock.partida?.num_partida,
    text2: stock.partida?.desc_partida,
  },
  cf_cve_partida: stock.partida?.num_partida,
  cf_condicion_uso: Number(stock.cf_condicion_uso),
  cf_descripcion_uso: Number(stock.cf_descripcion_uso),
  cf_clasificacion: Number(stock.cf_clasificacion),
  cf_no_cuenta:
    stock?.partida?.no_cuenta !== null ? stock?.partida?.no_cuenta : stock.cf_no_cuenta,
  cf_no_scuenta:
    stock?.partida?.no_scuenta !== null
      ? stock?.partida?.no_scuenta
      : stock.cf_no_scuenta,
});

export const getKey = (data) => {
  if (data) {
    const { cf_grupo, cf_subgrupo, cf_clase, cf_subclase, cf_tipo } = data;
    return `${cf_grupo === null ? "" : cf_grupo}·${
      cf_subgrupo === null ? "" : cf_subgrupo
    }·${cf_clase === null ? "" : cf_clase}·${cf_subclase === null ? "" : cf_subclase}·${
      cf_tipo === null ? "" : cf_tipo
    }`;
  }
};

export const getNames = (area_attendant) => {
  let names = area_attendant
    ?.filter((area) => area?.person?.nombre)
    ?.map((area) => area.person?.nombre?.trim())
    .join("\n");
  return names;
};

export function fetchStockParams(
  page = 1,
  limit = 50,
  params = "",
  order = "desc",
  isMin = false
) {
  return fetch(
    isMin
      ? `${INVENTORY_API}?min&page=${page}&limit=${limit}&order=${order}&${params}`
      : `${INVENTORY_API}?page=${page}&limit=${limit}&order=${order}&${params}`,
    getParams("GET")
  )
    .then(handleResponse)
    .then((res) => {
      const { count, per_page, results, ids } = res;
      const newResults = results.map(formData);
      return { ...res, results: newResults, count, per_page, page, ids };
    })
    .catch((err) => {
      throw err;
    });
}
export function getTipoBaja() {
  return fetch(`${INSTRUMENTAL_GOODS}/catalog-baja`, getParams("GET"))
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
}
// V2 Endpoint busquedas Smart filter
export const searchInvetoryFilter = (params) => {
  const search_params = params;
  if (params.hasOwnProperty("no_inventa")) {
    search_params.id = params.no_inventa;
    delete search_params.no_inventa;
  }
  if (params.hasOwnProperty("cve_partida")) {
    search_params.cf_cve_partida = params.cve_partida;
    delete search_params.cve_partida;
  }
  Object.keys(search_params).forEach((key) => {
    search_params[key] === "" && delete search_params[key];
  });

  return axios.post(`${INVENTORY_API}-search`, { search_params });
};

export const searchDropedInvetoryFilter = (params) => {
  const search_params = params;
  if (params.hasOwnProperty("no_inventa")) {
    search_params.id = params.no_inventa;
    delete search_params.no_inventa;
  }
  if (params.hasOwnProperty("cve_partida")) {
    search_params.cf_cve_partida = params.cve_partida;
    delete search_params.cve_partida;
  }
  Object.keys(search_params).forEach((key) => {
    search_params[key] === "" && delete search_params[key];
  });

  return axios.post(`${INVENTORY_API}-search`, {
    search_params,
    estatus: false,
  });
};

export function fetchStock(id, isDrop = false) {
  return isDrop
    ? axios.get(`${INVENTORY_API}-baja/${id}`)
    : axios.get(`${INVENTORY_API}/${id}`);
}

//dar de baja

export const unSuscribeId = async (id, data) => {
  const bajaData = {
    inventory: id,
    motivo_baja: data.motivo_baja,
    tipo_baja: data.tipo_baja,
  };
  return axios.post(`${INVENTORY_API}-baja`, bajaData);
};

export const unSuscribeReason = async (id, data) => {
  return axios.patch(`${INVENTORY_API}-baja/${id}/`, data);
};
export const guardStock = (id, cf_cve_area) => {
  return axios
    .put(`${AQUISITIONS_API}/${id}/shetler`, {
      cf_fecha_resguardo: formatDate("yyyy-MM-dd"),
      cf_anio_resguardo: formatDate("yyyy"),
      cf_cve_area,
    })
    .then((res) => res && res.status === 200 && res.data)
    .catch((err) => console.log("error" + err));
};

export const updateMultipleStockFields = (data) => {
  let newInventory = data.map(({ inventory_cabms, ...item }) =>
    !inventory_cabms?.inventory_cabms_grupo ? item : { ...item, inventory_cabms }
  );

  newInventory = newInventory.map((inventory) => {
    Object.entries(inventory).forEach(([field, value]) => {
      if (field === "cf_anio_resguardo" && value === "") {
        inventory["cf_anio_resguardo"] = null;
      }
      if (
        (field.includes("date") || field.includes("fecha")) &&
        value?.length === 8 &&
        value?.includes("/")
      ) {
        inventory[field] = value.slice(0, 6) + "20" + value.slice(6 + Math.abs(0));
      }
      if (field === "cf_cve_prove") inventory[field] = value?.text2;
      if (field === "cve_partida") {
        inventory["cf_cve_partida"] = value?.text2;
        delete inventory.cve_partida;
      }
      if (field === "cabms_grupo") {
        inventory["inventory_cabms"] = {
          ...inventory["inventory_cabms"],
          inventory_cabms_grupo: inventory[field],
        };
        delete inventory[field];
      }
      if (field === "cabms_subgrupo") {
        inventory["inventory_cabms"] = {
          ...inventory["inventory_cabms"],
          inventory_cabms_subgrupo: inventory[field]
            ? inventory[field].toString()?.replaceAll("_", "")
            : "",
        };
        delete inventory[field];
      }
      if (field === "cabms_clase") {
        inventory["inventory_cabms"] = {
          ...inventory["inventory_cabms"],
          inventory_cabms_clase: inventory[field]
            ? inventory[field].toString()?.replaceAll("_", "")
            : "",
        };
        delete inventory[field];
      }
      if (field === "cabms_subclase") {
        inventory["inventory_cabms"] = {
          ...inventory["inventory_cabms"],
          inventory_cabms_subclase: inventory[field]
            ? inventory[field].toString()?.replaceAll("_", "")
            : "",
        };
        delete inventory[field];
      }
      if (field === "cabms_tipo") {
        inventory["inventory_cabms"] = {
          ...inventory["inventory_cabms"],
          inventory_cabms_tipo: inventory[field]
            ? inventory[field].toString()?.replaceAll("_", "")
            : "",
        };
        delete inventory[field];
      }
      // if (field === "cabms_key") {
      //   const { cabms_grupo, cabms_subgrupo, cabms_clase, cabms_subclase, cabms_tipo } = getCabmsKey(inventory[field])
      //   inventory["inventory_cabms"] = {
      //     inventory_cabms_grupo: cabms_grupo,
      //     inventory_cabms_subgrupo: cabms_subgrupo,
      //     inventory_cabms_clase: cabms_clase,
      //     inventory_cabms_subclase: cabms_subclase,
      //     inventory_cabms_tipo: cabms_tipo,
      //   }
      //   delete inventory.cabms_key;
      // }
    });
    return inventory;
  });
  return fetch(
    `${INVENTORY_API}/multiple-edit/`,
    getParams("PUT", { inventories: newInventory })
  )
    .then(handleResponse)
    .catch(handleError);
};

export const fetchLabel = (id) => {
  return axios.get(`${INVENTORY_API}/${id}/zpl_code`);
};

export const postZpl = (id) => {
  return axios.post(`${INVENTORY_API}/zpl_codes_post/`, { ids: [id] });
};

export const updateFile = (
  file = null,
  attach_id,
  attach_model,
  title = "",
  field = "img"
) => {
  const body = {
    attach_model: attach_model,
    attach_id: attach_id,
    image_data: file,
    field: field,
    title: title,
  };
  return fetch(UPLOAD_FILE_URL, getMultipartParams("POST", handleFormData(body)))
    .then(handleResponse)
    .catch((e) => {
      throw e;
    });
};

export const stockReports = (report, ids, type, isDropPage) => {
  let body;
  if (isDropPage) {
    body = { ids, type, report_type: "baja" };
  } else {
    body = { ids, type };
  }

  return fetch(
    `${BASE_URL}/api/bienes-instrumentales/reports_catalogs/`,
    getParams("POST", body)
  )
    .then(handleResponse)
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};
export const stockNew = async (idList, type, type2) => {
  let body =
    type2 !== "" ? { ids: idList, type: type, type2 } : { ids: idList, type: type };

  return fetch(
    `${BASE_URL}/api/bienes-instrumentales/reports_catalogs/`,
    getParams("POST", body)
  )
    .then(handleResponse)
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};
// Re-made function with axios

export const updateInventoryPDF = (
  file = null,
  attach_id,
  attach_model = "inventory",
  title = "",
  field = "pdfs"
) => {
  const body = {
    attach_id,
    attach_model,
    image_data: file,
    field,
    title,
  };
  return axios.post(UPLOAD_FILE_URL, handleFormData(body));
};
export const updateMultipleInventoryPDF = (
  file = null,
  attach_id,
  attach_model = "inventory",
  title = "",
  field = "pdfs"
) => {
  const formData = new FormData();
  formData.append("attach_model", attach_model);
  formData.append("attach_id", attach_id);
  formData.append("attachments[]title", title);
  formData.append("attachments[]file", file);
  formData.append("attachments[]field", field);

  return axios.post(MULTIPLE_UPLOAD_FILE_URL, formData);
};
export const removeInventoryPDF = (id) => {
  return axios.delete(`${UPLOAD_FILE_URL}/${id}/`);
};

export const fetchFormat = (format, ids) => {
  const url = `${INVENTORY_API}/a-format-by-inventories`;
  const body = {
    format_type: format,
    inventories_ids: ids,
  };
  return axios.post(url, body);
};

export const fetchGuardFormat = (ids, filename) => {
  return axios.post(`${INVENTORY_API}/resguardo-format`, {
    ids: ids,
    filename: filename,
  });
};

export const fetchSurveyGuard = (id, filename) => {
  const url = `${INSTRUMENTAL_GOODS}/levantamiento/${id}/resguardo-format`;
  return axios.get(url);
};
export const fetchSurveyArtWork = (id, filename) => {
  const url = `${INSTRUMENTAL_GOODS}/levantamiento-obra-arte/${id}/resguardo-format`;
  return axios.get(url);
};

export const getGuardHistory = (id) => {
  const url = `${INVENTORY_API}/${id}/area-history`;
  return axios.get(url);
};

export const getZplMultiple = (ids) => {
  const inventoryIds = ids.join(",");
  return axios.get(`${INVENTORY_API}/zpl_codes/?ids=${inventoryIds}`);
};

export const getDropStockList = (page = 1, limit = 50) => {
  return fetch(
    `${INVENTORY_API}-baja?page=${page}&limit=${limit}`,
    getMultipartParams("GET")
  )
    .then(handleResponse)
    .then((res) => {
      const { count, per_page, results } = res;
      const newData = results
        .map((vh) => ({ ...vh, ...vh.inventory_data }))
        .filter((vh) => vh.inventory_data)
        .map(formData);

      return { results: newData, count, per_page, page };
    })
    .catch((e) => e);
};

export const getSingleDropStock = (id) => {
  return fetch(`${INVENTORY_API}-baja/${id}`, getParams("GET"))
    .then(handleResponse)
    .catch((e) => e);
};

export const createDropStock = (data) => {
  return fetch(`${INVENTORY_API}-baja`, getParams("POST", data))
    .then(handleResponse)
    .catch((e) => {
      notice("Ha ocurrido un error, favor de contactar con el administrador");
      throw e;
    });
};
