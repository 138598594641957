import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Typography, InputAdornment } from "@material-ui/core";
import { useParams } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import update from "immutability-helper";
import uuid from "react-uuid";
import _ from "lodash";
import PDFUploaderTab from "../../components/PDFUploaderTab";
import CustomButton from "../../components/CustomButton";
import SimpleInput from "../../components/SimpleInput";
import PageLayout from "../../components/PageLayout";
import UtilInputs from "../../components/UtilInputs";
import BatchTable from "../../components/BatchTable";
import TabsButton from "../../components/TabsButton";
import AntTabs from "../../components/AntTabs";
import notice from "../../components/Notice";
import Icon from "../../components/Icon";
import { isAValidAmount } from "../../utils/inputValidations";
import { getSingleContract } from "../../crud/contractsCrud";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  setContract,
  setFilteredContract,
  setFilesToUpload,
} from "../../../redux/ducks/contracts.duck";
import {
  articlesColumns,
  batchInputs,
  filterDetailContractInputs,
  amountInputs,
} from "../../utils/contractsData";
import { setLoader } from "../../../redux/ducks/loader.duck";

const DetailContractsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { contract, filteredContract, selectedContractIds } = useSelector(
    (store) => store.contracts
  );
  const formValues = location.pathname.includes("busqueda") ? filteredContract : contract;
  const [ContractoActual] = useState(contract);
  const [VersionSelected, setVersionSelected] = useState(!formValues.id ? null : 1);
  let min_import = {
    subtotal: contract.contract_set.reduce((acc, item) => acc + item.minimum_amount, 0),
    iva: contract.contract_set.reduce((acc, item) => acc + item.min_import, 0),
    total:
      contract.contract_set.reduce((acc, item) => acc + item.minimum_amount, 0) +
      contract.contract_set.reduce((acc, item) => acc + item.min_import, 0),
  };
  let max_import = {
    subtotal: contract.contract_set.reduce((acc, item) => acc + item.maximum_amount, 0),
    iva: contract.contract_set.reduce((acc, item) => acc + item.max_import, 0),
    total:
      contract.contract_set.reduce((acc, item) => acc + item.maximum_amount, 0) +
      contract.contract_set.reduce((acc, item) => acc + item.max_import, 0),
  };

  useEffect(() => {
    if (id) {
      dispatch(setLoader(true));
      getSingleContract(id)
        .then((res) => {
          const { supplier, contract_set } = res.data;
          const selectedContract = {
            ...res.data,
            supplier: {
              id: supplier?.id,
              text: supplier?.razon_social?.trim(),
              text2: supplier?.num_prove,
            },

            contract_set: contract_set.map((contr) => {
              let unitPricesSum = 0;
              for (let i = 0; i < contr.set_item.length; i++) {
                unitPricesSum += contr.set_item[i].unit_price;
              }
              //-----------------------------------------
              /* Se aplica el cambio de variable por available_quantity */
              // const available_quantity = Number(
              //   contr.maximum_amount - unitPricesSum
              // ).toFixed(2);
              const available_quantity = Number(contr.available_amount).toFixed(2);
              //-----------------------------------------
              let iva = contr.set_item.some((art) => art.iva) ? 0.16 : 0;
              const min_import = Number(Number(contr.minimum_amount) * iva);
              const max_import = Number(Number(contr.maximum_amount) * iva);
              return {
                ...contr,
                //TODO: field budget_start pending match with request real data
                budget_start: contr.partida_number,
                progress:
                  (unitPricesSum / contr.maximum_amount) * 100 > 1
                    ? (unitPricesSum / contr.maximum_amount) * 100
                    : 1,
                available_quantity,
                min_import,
                max_import,
                maximum_amount: Number(contr.maximum_amount.toFixed(2)),
                minimum_amount: Number(contr.minimum_amount.toFixed(2)),
                set_item: contr.set_item
                  .map((item) => {
                    return {
                      // ...item,
                      unit_price: item.unit_price,
                      cve_article: item.cve_articulo,
                      article: item.name_assets_category,
                      articulo_marca: item.articulo_marca?.trim(),
                      articulo_modelo: item.articulo_modelo?.trim() || "",
                      articulo_descripcion: item.articulo_descripcion?.trim(),
                      unit_measurement: item.unit_measurement,
                      articulo: item?.articulo?.id,
                      cons: item.contract_consecutive,
                      presentacion:
                        {
                          name: item?.presentacion?.descripcion,
                          id: item?.presentacion?.id,
                        } ?? null,
                      stock_available: `${item?.presentacion?.equivalencia ?? ""}`,
                      iva: item?.articulo?.iva,
                      contract_item_set_versions: item?.contract_item_set_versions,
                    };
                  })
                  .slice() //ordenar por consecutivo
                  .sort(function(a, b) {
                    return a.cons - b.cons;
                  }),
              };
            }),
          };
          dispatch(setContract(selectedContract));
        })
        .catch((err) => {
          console.log(err);
          notice("No se ha podido cargar el Contrato", "error");
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    }
    setVersionSelected(1);
  }, [dispatch, id]);

  const handleForm = async (e) => {
    const { name, value } = e.target;
    if (name === "supplier" && value.text2) {
      dispatch(
        location.pathname.includes("busqueda")
          ? setFilteredContract({
              ...formValues,
              [name]: value,
              supplier_number: value.text2,
            })
          : setContract({
              ...formValues,
              [name]: value,
              supplier_number: value.text2,
            })
      );
    } else if (name === "version") {
      setVersionSelected(value);
      if (value !== 1) {
        const Search =
          formValues.contract_set[0].set_item[0].contract_item_set_versions.find(
            (dato) => dato.version === value
          ) === undefined
            ? formValues.contract_set[0].set_item[0]
            : formValues.contract_set[0].set_item[0].contract_item_set_versions.find(
                (dato) => dato.version === value
              );
        const Newcontract_set = await {
          ...formValues.contract_set[0],
          maximum_amount: formValues.contract_set[0].contract_set_versions.find(
            (dato) => dato.version === value
          ).maximum_amount,
          set_item: formValues.id
            ? formValues.contract_set[0].set_item
            : [
                {
                  ...formValues.contract_set[0].set_item[0],
                  articulo_marca: Search.articulo_marca,
                  articulo_modelo: Search.articulo_modelo,
                  articulo_descripcion: Search.articulo_descripcion,
                },
              ],
        };
        dispatch(
          setContract({
            ...formValues,
            legal_representative: formValues.contract_versions.find(
              (dato) => dato.version === value
            ).legal_representative,
            contract_set: [Newcontract_set],
          })
        );
      } else {
        await dispatch(
          setContract({
            ...formValues,
            legal_representative: ContractoActual.legal_representative,
            contract_set: ContractoActual.contract_set,
          })
        );
      }
    } else {
      dispatch(
        location.pathname.includes("busqueda")
          ? setFilteredContract({ ...formValues, [name]: value })
          : setContract({ ...formValues, [name]: value })
      );
    }
  };

  const handleBatches = (i, field, value, aIndex = "") => {
    //TODO: <-- This is a provisional line code, because when change the value of 'available_quantity' module crash
    if (field === "available_quantity") return;
    //:TODO: -->
    let state = formValues.contract_set;
    if (field === "minimum_amount" || field === "maximum_amount") {
      if (isAValidAmount(value)) {
        if (
          (["0", ""].includes(state[i][field].toString()) && value.startsWith("0")) ||
          value.includes("-")
        )
          return;
        state[i][field] = Number(value);

        const currentTotal =
          state[i].set_item.length > 0
            ? state[i].set_item.reduce((a, b) => ({
                unit_price: a.unit_price + b.unit_price,
              })).unit_price
            : 0;

        state[i]["available_quantity"] = state[i].maximum_amount - currentTotal;
        let newContract = { ...formValues, contract_set: state };

        if (field === "minimum_amount") {
          let iva = state[i].set_item.some((art) => art.iva) ? 0.16 : 0;
          state[i]["min_import"] = Number(value * iva);
        }
        if (field === "maximum_amount") {
          let iva = state[i].set_item.some((art) => art.iva) ? 0.16 : 0;
          state[i]["max_import"] = Number(value * iva);
        }
        dispatch(setContract(newContract));
        return;
      }
    } else if (
      [
        "cons",
        "cve_article",
        "article",
        "unit_measurement",
        "unit_price",
        "articulo_marca",
        "articulo_modelo",
        "articulo_descripcion",
      ]
    ) {
      if (field === "unit_price" && !isAValidAmount(value)) return;
      if (field === "unit_price") state[i].set_item[aIndex][field] = value;
      if (state[i].maximum_amount > 0) {
        const unitPricesSum = Number(
          state[i].set_item.reduce((a, b) => ({
            unit_price: Number(a.unit_price) + Number(b.unit_price),
          })).unit_price
        );
        state[i]["available_quantity"] = Number(state[i].maximum_amount) - unitPricesSum;
        state[i].progress =
          (unitPricesSum / Number(state[i].maximum_amount)) * 100 > 1
            ? (unitPricesSum / Number(state[i].maximum_amount)) * 100
            : 1;
      }
      if (field === "article" || field === "cve_article") {
        //Filter if new article exists on curernt batch table.

        const temporalValues = [...state[i].set_item, value];

        let ivaImport = temporalValues.some((art) => art.iva) ? 0.16 : 0;
        state[i]["min_import"] = Number(
          formValues.contract_set[i].minimum_amount * ivaImport
        );
        state[i]["max_import"] = Number(
          formValues.contract_set[i].maximum_amount * ivaImport
        );

        if (!formValues.contract_type) {
          notice("Debe seleccionar un tipo de contrato");
          return;
        } else if (
          state[i].set_item.some((item) => item.cve_article === value.cve_articulo)
        ) {
          dispatch(
            setContract(
              update(formValues, {
                contract_set: {
                  [i]: {
                    set_item: {
                      [aIndex]: {
                        $set: {
                          cons: state[i].set_item[aIndex].cons,
                          cve_article: "",
                          article: "",
                          unit_measurement: "",
                          unit_price: "",
                          articulo: "",
                          presentaciones: [],
                          presentacion: null,
                          articulo_marca: "",
                          articulo_modelo: "",
                          articulo_descripcion: "",
                          stock_available: null,
                        },
                      },
                    },
                  },
                },
              })
            )
          );
          return;
        } else {
          const presentations = value?.presentaciones ?? [];
          let defaultPresentation = null;
          if (presentations.length === 1) {
            defaultPresentation = {
              ...presentations[0],
              name: presentations[0]?.descripcion,
            };
          }

          if (formValues.contract_type === "RECURRENTE") {
            if (value?.cve_articulo >= 9000) {
              dispatch(
                setContract(
                  update(formValues, {
                    contract_set: {
                      [i]: {
                        set_item: {
                          [aIndex]: {
                            iva: {
                              $set: value.iva,
                            },
                            cve_article: {
                              $set: value?.cve_articulo?.toString(),
                            },
                            unit_measurement: {
                              $set: value.unit,
                            },
                            disabled: {
                              $set: false,
                            },
                            article: {
                              $set: value?.text,
                            },
                            articulo: {
                              $set: value.id,
                            },
                            presentacion: {
                              $set: defaultPresentation,
                            },
                            presentaciones: {
                              $set: value?.presentaciones ?? [],
                            },
                            articulo_marca: {
                              $set: value.articulo_marca,
                            },
                            articulo_modelo: {
                              $set: value.articulo_modelo,
                            },
                            articulo_descripcion: {
                              $set: value.articulo_descripcion,
                            },
                            stock_available: {
                              $set: defaultPresentation?.equivalencia ?? null,
                            },
                          },
                        },
                      },
                    },
                  })
                )
              );
            } else notice("Los artículos no pertenecen a este grupo");
          } else if (value?.cve_articulo < 9000) {
            dispatch(
              setContract(
                update(formValues, {
                  contract_set: {
                    [i]: {
                      set_item: {
                        [aIndex]: {
                          iva: {
                            $set: value.iva,
                          },
                          cve_article: {
                            $set: value?.cve_articulo?.toString(),
                          },
                          unit_measurement: {
                            $set: value.unit,
                          },
                          disabled: {
                            $set: false,
                          },
                          article: {
                            $set: value.text,
                          },
                          articulo: {
                            $set: value.id,
                          },
                          presentacion: {
                            $set: defaultPresentation,
                          },
                          presentaciones: {
                            $set: value?.presentaciones ?? [],
                          },
                          articulo_marca: {
                            $set: value.articulo_marca,
                          },
                          articulo_modelo: {
                            $set: value.articulo_modelo,
                          },
                          articulo_descripcion: {
                            $set: value.articulo_descripcion,
                          },
                          stock_available: {
                            $set: defaultPresentation?.equivalencia ?? null,
                          },
                        },
                      },
                    },
                  },
                })
              )
            );
          } else notice("Los artículos no pertenecen a este grupo");
          return;
        }
      }
      if (field === "presentacion") {
        //This is for prevent the typing changes value, because this is option is only by selection.
        if (typeof value === "string") return;
        const filter = _(state[i].set_item[aIndex]["presentaciones"]).find(
          (item) => item.id === value.id
        );
        //Checking if are the same selection, if true then un-select the current option, its like a toggle action.
        if (state[i].set_item[aIndex]["presentacion"]?.id === value?.id) {
          state[i].set_item[aIndex]["presentacion"] = null;
          state[i].set_item[aIndex]["stock_available"] = null;
        } else {
          state[i].set_item[aIndex]["stock_available"] = `${filter?.equivalencia ?? ""}`;
          state[i].set_item[aIndex]["presentacion"] = {
            ...filter,
            name: filter.descripcion,
          };
        }
        const newContract = { ...formValues, contract_set: state };
        dispatch(setContract(newContract));
        return;
      }
      if (
        field === "cons" &&
        isAValidAmount(value) &&
        !value.includes(".") &&
        !value.startsWith("0")
      ) {
        state[i].set_item[aIndex]["cons"] = value;
      }
      if (field === "articulo_marca") {
        state[i].set_item[aIndex][field] = value;
      }
      if (field === "articulo_modelo") {
        state[i].set_item[aIndex][field] = value;
      }
      if (field === "articulo_descripcion") {
        state[i].set_item[aIndex][field] = value;
      }
      const newContract = { ...formValues, contract_set: state };
      dispatch(setContract(newContract));
    } else {
      return;
    }
  };

  const addBatch = () => {
    const newContractSet = {
      id: uuid(),
      budget_start: "",
      minimum_amount: 0,
      maximum_amount: 0,
      min_import: 0,
      max_import: 0,
      progress: 0,
      available_quantity: 0,
      set_item: [
        {
          cons: 1,
          cve_article: "",
          article: "",
          unit_measurement: "",
          unit_price: "",
          presentaciones: [],
          presentacion: null,
          stock_available: "",
          articulo_marca: "",
          articulo_modelo: "",
          articulo_descripcion: "",
        },
      ],
    };
    const newContract = {
      ...formValues,
      contract_set: [...formValues.contract_set, newContractSet],
    };
    dispatch(setContract(newContract));
  };

  const removeBatch = (index) => {
    dispatch(
      setContract(
        update(formValues, {
          contract_set: {
            $splice: [[index, 1]],
          },
        })
      )
    );
  };

  const addArticle = (i) => {
    let state = formValues.contract_set;
    const new_set_item = {
      id: uuid(),
      cons: state[i].set_item.length + 1,
      cve_article: "",
      article: "",
      presentaciones: [],
      presentacion: null,
      stock_available: "",
      unit_measurement: "",
      unit_price: "",
      articulo_marca: "",
      articulo_modelo: "",
      articulo_descripcion: "",
    };
    state[i].set_item = [...state[i].set_item, new_set_item];
    const newContract = { ...formValues, contract_set: state };
    dispatch(setContract(newContract));
  };

  const deleteArticle = (index, item) => {
    if (item?.cons) {
      const itemIndex = _(formValues.contract_set[index].set_item).findIndex(
        (it) => it.cons === item.cons
      );

      let updateValues = update(formValues, {
        contract_set: {
          [index]: {
            id: { $set: uuid() },
            set_item: {
              $splice: [[itemIndex, 1]],
            },
          },
        },
      });

      //Restart cons counter on each set_item
      const updateSetItem = updateValues.contract_set[index].set_item.map((item, i) =>
        update(item, {
          cons: { $set: i + 1 },
        })
      );

      updateValues = update(updateValues, {
        contract_set: {
          [index]: {
            set_item: {
              $set: updateSetItem,
            },
          },
        },
      });

      let ivaImport = updateValues.contract_set[index].set_item.some((art) => art.iva)
        ? 0.16
        : 0;
      updateValues.contract_set[index]["min_import"] = Number(
        updateValues.contract_set[index].minimum_amount * ivaImport
      );
      updateValues.contract_set[index]["max_import"] = Number(
        updateValues.contract_set[index].maximum_amount * ivaImport
      );

      dispatch(setContract(updateValues));
    }
  };

  const onSearchTextChange = (i, field, value, aIndex) => {
    let state = formValues.contract_set;
    if (!value?.text) {
      switch (field) {
        case "article":
          state[i].set_item[aIndex]["article"] = value;
          break;
        case "cve_article":
          if (isAValidAmount(value) && !value.includes(".") && !value.includes("-"))
            state[i].set_item[aIndex]["cve_article"] = value;
          break;
        default:
          return;
      }
      dispatch(setContract({ ...formValues, contract_set: state }));
    }
  };

  const handleClickTab = (id) =>
    history.push(`/inventario-bienes-almacen-general/contratos/${id}`);

  const onSucessRemove = (id) => {
    const index = _(formValues.attachment_pdfs).findIndex((file) => file.id === id);
    dispatch(
      setContract(
        update(formValues, {
          attachment_pdfs: { $splice: [[index, 1]] },
        })
      )
    );
  };

  const onSuccessUpload = (responses) => {
    const newAttachs = responses.map((res) => res.data);
    dispatch(
      setContract(
        update(formValues, {
          attachment_pdfs: { $push: newAttachs },
        })
      )
    );
  };

  const onSuccessUploadWithotId = (files) => {
    dispatch(setFilesToUpload(files));
  };

  //validation if partida is already repeated
  const validateBudget = (i) => {
    const repeatedArray = formValues.contract_set
      .filter((set, idx) => {
        return (
          set.budget_start === formValues.contract_set[i].budget_start &&
          i !== idx &&
          set.budget_start !== ""
        );
      })
      .map((item) => _(formValues.contract_set).findIndex((dx) => dx.id === item.id));
    return repeatedArray.length > 0
      ? {
          message: "Partida duplicada.",
          repeated: repeatedArray.concat(i).some((r) => r === i),
        }
      : undefined;
  };

  const handleBudget = (index, value) =>
    isAValidAmount(value) &&
    !value.includes(".") &&
    dispatch(
      setContract(
        update(formValues, {
          contract_set: {
            [index]: {
              budget_start: { $set: value },
            },
          },
        })
      )
    );

  function romanize(num) {
    var lookup = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
      },
      roman = "",
      i;
    for (i in lookup) {
      while (num >= lookup[i]) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
  }
  const VersionesOrdenadas = useCallback(() => {
    let arraydeVersiones = [];
    const VersionActual = {
      value: 1,
      text: `Version Actual`,
    };
    arraydeVersiones.push(
      formValues.contract_versions?.map((datos) => {
        return {
          value: datos.version,
          text: `Version ${romanize(datos.version)}`,
        };
      })
    );
    arraydeVersiones[0]?.push(VersionActual);
    return arraydeVersiones;
  }, [formValues.contract_versions]);

  return (
    <PageLayout
      title={
        formValues.id
          ? "Contrato"
          : location.pathname.includes("busqueda")
          ? "Búsqueda de contrato"
          : "Nuevo contrato"
      }
      header={
        !location.pathname.includes("busqueda") &&
        !location.pathname.includes("nuevo") &&
        selectedContractIds.length > 1 && (
          <div className="d-flex flex-column">
            <TabsButton
              list={selectedContractIds}
              selected={formValues.id}
              handleClickTab={handleClickTab}
            />
            {formValues.id && <h6 className="text-muted">Informacion del contrato</h6>}
          </div>
        )
      }
    >
      <UtilInputs
        formValues={{ ...formValues, version: VersionSelected }}
        onValuesChange={handleForm}
        inputValues={filterDetailContractInputs(
          formValues.id,
          VersionSelected,
          formValues.id ? VersionesOrdenadas()[0].reverse() : false
        )}
        width={200}
      />

      <SimpleInput
        label="Observaciones"
        placeholder="Observaciones"
        name="observations"
        onChange={handleForm}
        width="100%"
        value={formValues.observations}
        disabled={formValues.id}
      />
      {!location.pathname.includes("busqueda") && (
        <>
          <Typography variant="h6" className="text-muted mb-3 mt-4">
            Artículos de acuerdo al contrato
          </Typography>

          {formValues.contract_set?.map((batch, i) => {
            return (
              <Fragment key={batch.id.toString()}>
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                  <Typography variant="h6">Partida</Typography>
                  <SimpleInput
                    disabled={formValues.id}
                    required={
                      validateBudget(i)?.repeated && Boolean(validateBudget(i)?.message)
                    }
                    errorMessage={
                      validateBudget(i)?.repeated && validateBudget(i)?.message
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={"start"}>#</InputAdornment>
                      ),
                    }}
                    name="budget_start"
                    onChange={(e) => handleBudget(i, e?.target?.value)}
                    width={120}
                    value={formValues.contract_set[i].budget_start}
                  />
                  {i !== 0 && !id && (
                    <Icon
                      src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                      label="Eliminar"
                      onClick={() => removeBatch(i)}
                    />
                  )}
                </div>

                <UtilInputs
                  width={200}
                  formValues={batch}
                  onValuesChange={({ target }) =>
                    handleBatches(i, target.name, target.value)
                  }
                  disabled={!!formValues.id}
                  inputValues={
                    !formValues.id
                      ? batchInputs(!formValues.id).splice(0, 2)
                      : batchInputs(!formValues.id, VersionSelected).filter(
                          (input) => input.name !== "progress"
                        )
                  }
                />
                <br />
                <div className="pr-4">
                  <BatchTable
                    i={i}
                    items={batch.set_item}
                    columns={articlesColumns(
                      !formValues.id,
                      formValues.contract_type,
                      VersionSelected
                    )}
                    handleChange={handleBatches}
                    addRow={addArticle}
                    disableAddItem={!!formValues.id}
                    onSearchTextChange={onSearchTextChange}
                    deleteRow={(item) => deleteArticle(i, item)}
                  />
                </div>
              </Fragment>
            );
          })}

          {!formValues.id && <CustomButton title="Agregar partida" onClick={addBatch} />}
        </>
      )}
      <Typography variant="h6" className="text-muted my-5">
        Importe mínimo
      </Typography>
      <UtilInputs
        formValues={min_import}
        onValuesChange={handleForm}
        inputValues={amountInputs(location.pathname.includes("busqueda"))}
        width={200}
      />
      <Typography variant="h6" className="text-muted my-5">
        Importe máximo
      </Typography>
      <UtilInputs
        formValues={max_import}
        onValuesChange={handleForm}
        inputValues={amountInputs(location.pathname.includes("busqueda"))}
        width={200}
      />
      <div className={"my-7"} />

      <AntTabs tabs={[{ label: "Documentos", value: 0 }]} selectedValue={0} />
      <PDFUploaderTab
        pdfTitleName={"contrato"}
        attachment_pdfs={formValues?.attachment_pdfs ?? []}
        attach_model={"contract"}
        field={"pdfs"}
        id={formValues.id}
        onSuccessUpload={
          formValues.id === undefined ? onSuccessUploadWithotId : onSuccessUpload
        }
        onSucessRemove={onSucessRemove}
      />
    </PageLayout>
  );
};

export default DetailContractsPage;
